import React, { useState } from "react";
import { Modal } from "bootstrap";

import { useDispatch, useSelector } from "react-redux";
import {
  resetFilter,
  selectSearch,
  setInbounddate,
  setOutbounddate,
  setSearchOption,
  setSelectedDestination,
} from "amadeusredux/lib/searchSlice";
import { getFilters, search } from "amadeusredux/lib/api";
import {
  setOpenSearch,
  setSearchEnded,
  setView,
} from "amadeusredux/lib/systemSlice";
import {
  adultsOptions,
  airportcity,
  childrenAgeOptions,
  childrenOptions,
  commonDivisors,
  daysInThisMonth,
} from "../utils";
import { views } from "amadeusredux/lib/utils";
import { durationValues } from "amadeusredux/lib/utils";
import { FormattedDate, useIntl } from "react-intl";
import { fetchSessionHistory } from "amadeusredux/lib/propertiesSlice";
import {
  setHotelOfferInfos,
  setHotelOffersFound,
  setSelectedHotel,
} from "amadeusredux/lib/hotelSlice";
import { useOrientation } from "@uidotdev/usehooks";

//import Select from "../components/Select";
import DestinationSelect from "../searchs/DestinationSelect";
import AirportSelect from "../searchs/AirportSelect";
import PriceFilter from "../filters/PriceFilter";
import HotelAttributeFilter from "../filters/HotelAttributeFilter";
import OfferFilter from "../filters/OfferFilter";
import BoardTypeFilter from "../filters/BoardTypeFilter";
import RoomTypeFilter from "../filters/RoomTypeFilter";
import FlightFilter from "../filters/FlightFilter";
import DurationSelect from "../searchs/DurationSelect";
import AirlineFilter from "../filters/AirlineFilter";
import Select from "../components/Select";

import FlighttimeFilter from "../filters/FlighttimeFilter";
import RoomViewFilter from "../filters/RoomViewFilter";
import AllTouroperatorsFilter from "../filters/AllTouroperatorsFilter";
import {
  setOfferInfos,
  setOfferPage,
  setOffersSearched,
} from "amadeusredux/lib/offersSlice";
import AirportsFilter from "../filters/AirportsFilter";
import { fetchFares } from "amadeusredux/lib/flightSlice";
import AirportIataSelect from "../searchs/AirportIataSelect";

function SearchFullView() {
  const dispatch = useDispatch();
  const regionGroups = useSelector((state) => state.properties.regionGroups);
  const airlines = useSelector((state) => state.properties.airlines);
  const sessionHistory = useSelector(
    (state) => state.properties.sessionHistory
  );

  const sessionId = useSelector((state) => state.system.sessionId);
  const holidayType = useSelector((state) => state.search.holidayType);
  const transportOperators = useSelector(
    (state) => state.filter.transportOperators
  );

  const [multiRooms, setMultiRooms] = React.useState([]);

  const intl = useIntl();

  let destinationAirlines = transportOperators.map((e) => {
    let a = airlines.find((f) => e.value === f.value);
    return { ...e, properties: a ? a.properties : {} };
  });

  const [panel, setPanel] = React.useState(1);
  const agencyproperties = useSelector(
    (state) => state.properties.agencyproperties
  );



  const durationkind = useSelector((state) => state.search.durationkind);
  const multiroomdivider = useSelector(
    (state) => state.search.multiroomdivider
  );
  const couponcode = useSelector((state) => state.search.couponcode);
  const childrenages = useSelector((state) => state.search.childrenages);
  const children = useSelector((state) => state.search.children);
  const adults = useSelector((state) => state.search.adults);
  //console.log(suggestions);
  const oneway = useSelector((state) => state.search.oneway);

  const [datChooseFrom, setDatChooseFrom] = React.useState(new Date());
  const [datChooseUntil, setDatChooseUntil] = React.useState(new Date());

  React.useEffect(() => {
    if (sessionId) dispatch(fetchSessionHistory({ sessionid: sessionId }));
  }, [sessionId]);

  const selectedDestination = useSelector(
    (state) => state.search.selectedDestination
  );

  const view = useSelector((state) => state.system.view);
  const openSearch = useSelector((state) => state.system.openSearch);
  const memos = useSelector((state) => state.offers.memo);
  //console.log(selectedDestination);
  const {
    duration,
    stations,
    travelDate,
    category,
    recommendationrate,
    ratingcount,
  } = useSelector(selectSearch);

  const departureStations = useSelector(
    (state) => state.properties.departureStations
  );

  const delay = useSelector((state) => state.system.delay);

  //console.log("TRAVELDATE", travelDate);

  React.useEffect(() => {
    getFilters(dispatch, false);

    setMultiRooms(
      commonDivisors(
        adultoption + 1,
        childrenoption === 0 ? adultoption + 1 : childrenoption
      )
    );
  }, []);

  React.useEffect(() => {
    let hit = false;
    /*
    if (regionGroups.length > 0 && selectedDestination.length === 0) {
      hit = true;
      dispatch(
        setSelectedDestination([
          { ...regionGroups[0], label: regionGroups[0].Name },
        ])
      );
    }
    if (!category) {
      hit = true;
      dispatch(
        setSearchOption({
          property: "category",
          data: "5",
        })
      );
    }
    */

    if (hit) {
      getFilters(dispatch, false);
    }
  }, [regionGroups]);

  React.useEffect(() => {
    if (openSearch) {
      setTimeout(() => {
        //console.log(document.getElementById("searchModal"));
        let ele = document.getElementById("searchModal");
        if (ele) {
          let searchModal = new Modal(document.getElementById("searchModal"));
          searchModal.show();
        }
      }, 200);
    }
    dispatch(setOpenSearch(false));
  }, [openSearch]);

  const [dropclassNameStations, setDropclassNameStations] =
    React.useState("dropdown-menu");
  const [dropclassNameAirlines, setDropclassNameAirlines] =
    React.useState("dropdown-menu");
  const [dropHistory, setDropHistory] = React.useState(false);
  let dateFrom = new Date(travelDate.from.split('"').join(""));
  let dateUntil = new Date(travelDate.to.split('"').join(""));
  let today = new Date();

  if (dateFrom < today) {
    dateFrom = new Date();
    dateFrom.setDate(today.getDate() + 7);
    dateUntil = new Date();
    dateUntil.setDate(today.getDate() + 14);
    let tradt = { ...travelDate };
    tradt.from = JSON.stringify(dateFrom);
    tradt.to = JSON.stringify(dateUntil);
    dispatch(
      setSearchOption({
        property: "travelDate",
        data: tradt,
      })
    );
  }

  if (dateUntil < dateFrom) {
    dateUntil.setTime(dateFrom.getTime);
    dateUntil.setDate(dateUntil.getDate() + 7);
  }

  let timeDifference = dateUntil.getTime() - dateFrom.getTime();

  let diffdays = Math.round(timeDifference / (1000 * 3600 * 24));

  //console.log(stations);

  let datFrom =
    dateFrom.getFullYear() +
    "-" +
    (dateFrom.getMonth() + 1 < 10 ? "0" : "") +
    (dateFrom.getMonth() + 1) +
    "-" +
    (dateFrom.getDate() < 10 ? "0" : "") +
    dateFrom.getDate();

  let datUntil =
    dateUntil.getFullYear() +
    "-" +
    (dateUntil.getMonth() + 1 < 10 ? "0" : "") +
    (dateUntil.getMonth() + 1) +
    "-" +
    (dateUntil.getDate() < 10 ? "0" : "") +
    dateUntil.getDate();

  //console.log(departureStations);

  let adultoption = 0;
  let iac = 0;
  adultsOptions.forEach((e) => {
    if (e.value === adults.value) adultoption = iac;
    iac++;
  });

  let childrenoption = 0;
  iac = 0;
  childrenOptions.forEach((e) => {
    if (e.value === children.value) childrenoption = iac;
    iac++;
  });
  let isnum = /^\d+$/.test(duration.value);

  if (isnum) {
    if (diffdays < duration.value * 1) {
      dispatch(
        setSearchOption({
          property: "duration",
          data: {
            value: "" + diffdays,
            label:
              diffdays +
              (durationkind?.value === "Trip"
                ? "Tage"
                : durationkind?.value === "Stay"
                ? "Nächte"
                : "Übernachtungen"),
          },
        })
      );
    }
  }

  const calenderLength = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  let calenderOnlyStart = false;
  if (oneway && !holidayType.accommodation) {
    calenderOnlyStart = true;
  }

  let calendarStartDay = new Date();
  calendarStartDay.setDate(1);
  calendarStartDay.setHours(0);
  calendarStartDay.setMinutes(0);
  calendarStartDay.setSeconds(0);
  calendarStartDay.setMilliseconds(0);
  calendarStartDay.setTime(
    calendarStartDay.getTime() - calendarStartDay.getTimezoneOffset() * 60000
  );

  const showCalendar = () => {
    let tmpDateFrom = new Date(dateFrom.getTime());

    tmpDateFrom.setHours(0);
    tmpDateFrom.setMinutes(0);
    tmpDateFrom.setSeconds(0);
    tmpDateFrom.setMilliseconds(0);
    tmpDateFrom.setTime(
      tmpDateFrom.getTime() - tmpDateFrom.getTimezoneOffset() * 60000
    );

    let tmpDateUntil = new Date(dateUntil.getTime());

    tmpDateUntil.setHours(0);
    tmpDateUntil.setMinutes(0);
    tmpDateUntil.setSeconds(0);
    tmpDateUntil.setMilliseconds(0);
    tmpDateUntil.setTime(
      tmpDateUntil.getTime() - tmpDateUntil.getTimezoneOffset() * 60000
    );

    setDatChooseFrom(tmpDateFrom);
    setDatChooseUntil(tmpDateUntil);
    setTimeout(() => {
      //console.log(document.getElementById("caldat"));
      document.getElementById("caldat")?.scrollIntoView();
    }, 200);
  };

  //var ratio = window.devicePixelRatio || 1;
  let w = window.screen.availWidth;
  let h = window.screen.availHeight;
  //console.log(duration);
  return (
    <>
      <div>
        {holidayType.accommodation && (
          <div className="col-12 mb-2">
            <div
              className="border border-1 ps-3 p-2 mb-1 rounded"
              style={{ paddingTop: 7, paddingBottom: 7 }}
            >
              Reiseziele
            </div>
            <DestinationSelect />
          </div>
        )}

        <div className="accordion" id="accordionSearch">
          <div className="accordion-item">
            <h2 className="accordion-header " id="header_reisedaten">
              <button
                style={{ paddingTop: 7, paddingBottom: 7 }}
                className={
                  "accordion-button " + (panel === 1 ? " " : " collapsed")
                }
                type="button"
                aria-expanded={panel === 1 ? "true" : false}
                aria-controls="panelsStayOpen-reisedaten"
                onClick={() => {
                  if (panel !== 1) setPanel(1);
                  else setPanel(-1);
                }}
              >
                Reisedaten
              </button>
            </h2>

            <div
              id="panelsStayOpen-reisedaten"
              className={
                "accordion-collapse collapse " + (panel === 1 ? " show " : "")
              }
              aria-labelledby="header_reisedaten"
            >
              <div className="accordion-body">
                <div className="row">
                  {!holidayType.accommodation && (
                    <div className="col-12 col-sm-4 mb-2">
                      <small>Flughäfen:</small>
                    </div>
                  )}
                  {!holidayType.accommodation && (
                    <div className="">
                      <AirportIataSelect isDisabled={false} stationindex={0} />
                    </div>
                  )}
                  {!holidayType.accommodation && (
                    <div className="mt-2">
                      <AirportIataSelect isDisabled={false} stationindex={1} />
                    </div>
                  )}
                  <div className="col-12 col-sm-4 mb-2">
                    <small>Reisezeitraum:</small>
                  </div>
                  <div className="col-12 col-sm-8 mb-2 text-end">
                    <div class="input-group mb-3">
                      <input
                        data-bs-toggle="modal"
                        data-bs-target="#timeModal"
                        readOnly={true}
                        onClick={() => {
                          showCalendar();
                        }}
                        className="form-control"
                        value={
                          intl.formatDate(dateFrom, {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          }) +
                          (!calenderOnlyStart ? " - " : "") +
                          (!calenderOnlyStart
                            ? intl.formatDate(dateUntil, {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              })
                            : "")
                        }
                        onChange={(evt) => {
                          dispatch(
                            setSearchOption({
                              property: "couponcode",
                              data: evt.target.value,
                            })
                          );
                        }}
                      ></input>
                      <span
                        data-bs-toggle="modal"
                        data-bs-target="#timeModal"
                        onClick={() => {
                          showCalendar();
                        }}
                        class="input-group-text"
                        id="cal-addon"
                      >
                        <i className="bi bi-calendar"></i>
                      </span>
                    </div>
                  </div>
                  {/*<div className="col-6 mb-1">
                    <input
                      style={{
                        border: "1px solid #dee2e6",
                        borderRadius: "0.375rem",
                        height: 38,
                      }}
                      value={datFrom}
                      onChange={(evt) => {
                        //setDat(evt.target.value);                      
                        let arrdat = evt.target.value.split("-");
                        /*try {
                                if (arrdat[2] * 1 < 2000) return;
                              } catch (e) {
                                return;
                              }*/
                  /*
                        let dt = new Date(
                          arrdat[0] * 1,
                          arrdat[1] * 1 - 1,
                          arrdat[2] * 1,
                          12
                        );
                        let tradt = { ...travelDate };
                        tradt.from = JSON.stringify(dt);
                        let tmpdat = new Date(dt);
                        tradt.to = JSON.stringify(
                          new Date(
                            tmpdat.setDate(
                              tmpdat.getDate() + durationValues[duration.value]
                            )
                          )
                        );
                        dispatch(
                          setSearchOption({
                            property: "travelDate",
                            data: tradt,
                          })
                        );
                        getFilters(dispatch, false);
                      }}
                      type="date"
                    />
                  </div>
                  <div className="col-6 text-end mb-1">
                    <input
                      style={{
                        border: "1px solid #dee2e6",
                        borderRadius: "0.375rem",
                        height: 38,
                      }}
                      value={datUntil}
                      onChange={(evt) => {
                        //setDat(evt.target.value);
                        let arrdat = evt.target.value.split("-");
                        /*try {
                                if (arrdat[2] * 1 < 2000) return;
                              } catch (e) {
                                return;
                              }*/
                  /*
                        let dt = new Date(
                          arrdat[0] * 1,
                          arrdat[1] * 1 - 1,
                          arrdat[2] * 1,
                          12
                        );
                        let tradt = { ...travelDate };
                        tradt.to = JSON.stringify(dt);
                        dispatch(
                          setSearchOption({
                            property: "travelDate",
                            data: tradt,
                          })
                        );
                        getFilters(dispatch, false);
                      }}
                      type="date"
                    />
                  </div>*/}
                  {holidayType.accommodation && (
                    <div className="col-4 mb-1">
                      <small>Reisedauer:</small>
                    </div>
                  )}{" "}
                  {holidayType.accommodation && (
                    <div className="col-8 text-end  mb-1">
                      <DurationSelect />
                    </div>
                  )}
                  {holidayType.flight && holidayType.accommodation && (
                    <div className="col-4"></div>
                  )}
                  {holidayType.flight && holidayType.accommodation && (
                    <div className="col-4">
                      <input
                        checked={durationkind?.value === "Trip"}
                        type="checkbox"
                        onChange={() => {
                          dispatch(
                            setSearchOption({
                              property: "durationkind",
                              data: {
                                value: "Trip",
                                label: "Reisedauer",
                              },
                            })
                          );
                        }}
                      ></input>
                      &nbsp;Tage
                    </div>
                  )}
                  {holidayType.flight && holidayType.accommodation && (
                    <div className="col-4">
                      <input
                        checked={durationkind?.value === "Stay"}
                        type="checkbox"
                        onChange={() => {
                          dispatch(
                            setSearchOption({
                              property: "durationkind",
                              data: {
                                value: "Stay",
                                label: "Aufenthalt",
                              },
                            })
                          );
                        }}
                      ></input>
                      &nbsp;Nächte
                    </div>
                  )}
                  <div className="col-2 " style={{ fontSize: "1.5rem" }}>
                    {adultoption == 0 && <i className="bi bi-dash-square"></i>}
                    {adultoption > 0 && (
                      <i
                        onClick={() => {
                          dispatch(
                            setSearchOption({
                              property: "multiroomdivider",
                              data: 1,
                            })
                          );
                          setMultiRooms(
                            commonDivisors(
                              adultoption,
                              childrenoption === 0
                                ? adultoption
                                : childrenoption
                            )
                          );
                          dispatch(
                            setSearchOption({
                              property: "adults",
                              data: adultsOptions[adultoption - 1],
                            })
                          );
                          getFilters(dispatch, false);
                        }}
                        className="bi bi-dash-square text-primary"
                      ></i>
                    )}
                  </div>
                  <div className="col-8  text-center pt-2">{adults.label}</div>
                  <div
                    className="col-2 mb-2 text-end"
                    style={{ fontSize: "1.5rem" }}
                  >
                    {adultoption === adultsOptions.length - 1 && (
                      <i className="bi bi-plus-square"></i>
                    )}
                    {adultoption < adultsOptions.length - 1 && (
                      <i
                        onClick={() => {
                          dispatch(
                            setSearchOption({
                              property: "multiroomdivider",
                              data: 1,
                            })
                          );

                          setMultiRooms(
                            commonDivisors(
                              adultoption + 2,
                              childrenoption === 0
                                ? adultoption + 2
                                : childrenoption
                            )
                          );
                          dispatch(
                            setSearchOption({
                              property: "adults",
                              data: adultsOptions[adultoption + 1],
                            })
                          );
                          getFilters(dispatch, false);
                        }}
                        className="bi bi-plus-square text-primary"
                      ></i>
                    )}
                  </div>
                  <div className="col-2" style={{ fontSize: "1.5rem" }}>
                    {childrenoption == 0 && (
                      <i className="bi bi-dash-square"></i>
                    )}
                    {childrenoption > 0 && (
                      <i
                        onClick={() => {
                          dispatch(
                            setSearchOption({
                              property: "multiroomdivider",
                              data: 1,
                            })
                          );

                          setMultiRooms(
                            commonDivisors(
                              adultoption + 1,
                              childrenoption - 1 === 0
                                ? adultoption + 1
                                : childrenoption - 1
                            )
                          );
                          dispatch(
                            setSearchOption({
                              property: "children",
                              data: childrenOptions[childrenoption - 1],
                            })
                          );
                          getFilters(dispatch, false);
                        }}
                        className="bi bi-dash-square text-primary"
                      ></i>
                    )}
                  </div>
                  <div className="col-8 text-center pt-2">{children.label}</div>
                  <div
                    className="col-2 mb-2 text-end"
                    style={{ fontSize: "1.5rem" }}
                  >
                    {childrenoption === childrenOptions.length - 1 && (
                      <i className="bi bi-plus-square"></i>
                    )}
                    {childrenoption < childrenOptions.length - 1 && (
                      <i
                        onClick={() => {
                          dispatch(
                            setSearchOption({
                              property: "multiroomdivider",
                              data: 1,
                            })
                          );

                          setMultiRooms(
                            commonDivisors(
                              adultoption + 1,
                              childrenoption + 1 === 0
                                ? adultoption + 1
                                : childrenoption + 1
                            )
                          );
                          dispatch(
                            setSearchOption({
                              property: "children",
                              data: childrenOptions[childrenoption + 1],
                            })
                          );
                          getFilters(dispatch, false);
                        }}
                        className="bi bi-plus-square text-primary"
                      ></i>
                    )}
                  </div>
                  {[...Array(childrenoption)].map((e, i) => {
                    return (
                      <div className="col-12  mb-2">
                        <Select
                          value={childrenages[i]}
                          options={childrenAgeOptions}
                          onChange={(val) => {
                            let temp = [...childrenages];
                            temp[i] = val;
                            dispatch(
                              setSearchOption({
                                property: "childrenages",
                                data: temp,
                              })
                            );
                            getFilters(dispatch, false);
                          }}
                        />
                      </div>
                    );
                  })}
                  {holidayType.accommodation && (
                    <div className="col-12  mb-2">
                      {multiRooms.map((e) => {
                        let adultsCount = adultoption + 1;
                        let childrenCount = childrenoption;
                        return (
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              checked={multiroomdivider === e}
                              name="multiroomcheck"
                              id={"multiroomradio" + e}
                              onClick={() => {
                                dispatch(
                                  setSearchOption({
                                    property: "multiroomdivider",
                                    data: e,
                                  })
                                );
                              }}
                            />{" "}
                            <label
                              class="form-check-label"
                              for={"multiroomradio" + e}
                            >
                              {e} Zimmer mit {e > 1 ? "je" : ""} &nbsp;
                              {adultsCount / e}{" "}
                              {adultsCount / e > 1
                                ? "Erwachsenen"
                                : "Erwachsener"}
                              {childrenCount > 0
                                ? " und " +
                                  childrenCount / e +
                                  (childrenCount / e > 1 ? " Kindern" : " Kind")
                                : ""}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {!holidayType.accommodation && (
                    <div className="col-12 mb-4">
                      <FlightFilter destinationAirlines={destinationAirlines} />
                    </div>
                  )}
                  {agencyproperties?.properties?.mobileproperties
                    ?.coupontext && (
                    <>
                      <div className="col-4 mt-2">
                        <small>
                          {
                            agencyproperties.properties.mobileproperties
                              .coupontext
                          }
                          :
                        </small>
                      </div>

                      <div className="col-8 mt-2">
                        <input
                          className="form-control"
                          value={couponcode}
                          onChange={(evt) => {
                            dispatch(
                              setSearchOption({
                                property: "couponcode",
                                data: evt.target.value,
                              })
                            );
                          }}
                        ></input>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {holidayType.accommodation && (
            <div className="accordion-item">
              <h2 className="accordion-header " id="header_hotelstandard">
                <button
                  style={{ paddingTop: 7, paddingBottom: 7 }}
                  className={
                    "accordion-button " + (panel === 2 ? " " : " collapsed")
                  }
                  type="button"
                  aria-expanded={panel === 2 ? "true" : false}
                  aria-controls="panelsStayOpen-hotelstandard"
                  onClick={() => {
                    if (panel !== 2) setPanel(2);
                    else setPanel(-1);
                  }}
                >
                  Hotelstandards
                </button>
              </h2>
              <div
                id="panelsStayOpen-hotelstandard"
                className={
                  "accordion-collapse collapse " + (panel === 2 ? " show " : "")
                }
                aria-labelledby="header_hotelstandard"
              >
                <div className="accordion-body">
                  <div className="col-12 mb-2">
                    <label htmlFor="customStars" className="form-label">
                      Sterne{" "}
                      {category * 1 > 0 ? (
                        <span className="small">
                          &nbsp;&nbsp;min. {category}{" "}
                          {category > 1 ? "Sterne" : "Stern"}
                        </span>
                      ) : (
                        ""
                      )}
                    </label>
                    <input
                      value={category ? category : "0"}
                      onChange={(evt) => {
                        dispatch(
                          setSearchOption({
                            property: "category",
                            data: evt.target.value + "",
                          })
                        );
                        getFilters(dispatch, false);
                      }}
                      type="range"
                      className="form-range"
                      min="0"
                      max="5"
                      step="1"
                      id="customStars"
                    />
                  </div>

                  <div className="col-12 mb-2">
                    <label htmlFor="customRR" className="form-label">
                      Weiterempfehlungsrate{" "}
                      {recommendationrate * 1 > 0 ? (
                        <span className="small">
                          &nbsp;&nbsp; min. {recommendationrate}%
                        </span>
                      ) : (
                        ""
                      )}
                    </label>
                    <input
                      value={recommendationrate ? recommendationrate : "0"}
                      onChange={(evt) => {
                        let val = evt.target.value * 1;
                        if (val < 10) val = "";
                        dispatch(
                          setSearchOption({
                            property: "recommendationrate",
                            data: val + "",
                          })
                        );
                        getFilters(dispatch, false);
                      }}
                      type="range"
                      className="form-range"
                      min="0"
                      max="100"
                      step="10"
                      id="customRR"
                    />
                  </div>
                  <div className="col-12 mb-2">
                    <label htmlFor="customRC" className="form-label">
                      Anzahl Bewertungen{" "}
                      {ratingcount * 1 > 0 ? (
                        <span className="small">
                          &nbsp;&nbsp; min. {ratingcount}
                        </span>
                      ) : (
                        ""
                      )}
                    </label>
                    <input
                      value={ratingcount ? ratingcount : "0"}
                      onChange={(evt) => {
                        let val = evt.target.value * 1;
                        if (val < 10) val = "";
                        dispatch(
                          setSearchOption({
                            property: "ratingcount",
                            data: val + "",
                          })
                        );
                        getFilters(dispatch, false);
                      }}
                      type="range"
                      className="form-range"
                      min="0"
                      max="500"
                      step="50"
                      id="customRC"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {holidayType.flight && holidayType.accommodation && (
            <div className="accordion-item">
              <h2 className="accordion-header" id="header_flights">
                <button
                  style={{ paddingTop: 7, paddingBottom: 7 }}
                  className={
                    "accordion-button " + (panel === 3 ? " " : " collapsed")
                  }
                  aria-expanded={panel === 3 ? "true" : false}
                  onClick={() => {
                    if (panel !== 3) setPanel(3);
                    else setPanel(-1);
                  }}
                  type="button"
                  aria-controls="panelsStayOpen-flights"
                >
                  Flughäfen und Gesellschaften
                </button>
              </h2>

              <div
                id="panelsStayOpen-flights"
                className={
                  "accordion-collapse collapse " + (panel === 3 ? " show " : "")
                }
                aria-labelledby="header_flights"
              >
                <div className="accordion-body">
                  {/*!holidayType.accommodation && (
                    <input
                      className="form-control"
                      value={stations.length > 0 ? stations[0].value : ""}
                      onChange={(evt) => {
                        let other = { value: "", label: "" };
                        if (stations.length > 1) {
                          other = stations[1];
                        }
                        dispatch(
                          setSearchOption({
                            property: "stations",
                            data: [
                              {
                                value: evt.target.value,
                                label: evt.target.value,
                              },
                              other,
                            ],
                          })
                        );
                      }}
                    ></input>
                  )*/}

                  {/*!holidayType.accommodation && (
                    <input
                      className="form-control"
                      value={stations.length > 1 ? stations[1].value : ""}
                      onChange={(evt) => {
                        let other = { value: "", label: "" };
                        if (stations.length > 0) {
                          other = stations[0];
                        }
                        dispatch(
                          setSearchOption({
                            property: "stations",
                            data: [
                              other,
                              {
                                value: evt.target.value,
                                label: evt.target.value,
                              },
                            ],
                          })
                        );
                      }}
                    ></input>
                  )*/}

                  <div className="col-12 mb-2">
                    {holidayType.flight && holidayType.accommodation && (
                      <AirportSelect />
                    )}
                  </div>
                  <div className="col-12 mb-2">
                    {holidayType.flight && holidayType.accommodation && (
                      <AirportsFilter />
                    )}
                  </div>
                  <div className="col-12 mb-2">
                    <FlightFilter destinationAirlines={destinationAirlines} />
                  </div>
                  <div className="col-12 mb-2">
                    {holidayType.flight && holidayType.accommodation && (
                      <FlighttimeFilter outbound={true} />
                    )}
                  </div>
                  <div className="col-12 mb-2">
                    {holidayType.flight && holidayType.accommodation && (
                      <FlighttimeFilter outbound={false} />
                    )}
                  </div>
                  <div className="col-12 mb-2">
                    {holidayType.flight && holidayType.accommodation && (
                      <AirlineFilter />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {holidayType.accommodation && (
            <div className="accordion-item">
              <h2 className="accordion-header collapsed" id="header_filter">
                <button
                  style={{ paddingTop: 7, paddingBottom: 7 }}
                  type="button"
                  className={
                    "accordion-button " + (panel === 4 ? " " : " collapsed")
                  }
                  aria-expanded={panel === 4 ? "true" : false}
                  onClick={() => {
                    if (panel !== 4) setPanel(4);
                    else setPanel(-1);
                  }}
                  aria-controls="panelsStayOpen-filter"
                >
                  Filter
                </button>
              </h2>
              <div
                id="panelsStayOpen-filter"
                className={
                  "accordion-collapse collapse " + (panel === 4 ? " show " : "")
                }
                aria-labelledby="header_filter"
              >
                <div className="accordion-body">
                  <PriceFilter />
                  <HotelAttributeFilter mostwanted={true} />
                  <br />
                  <OfferFilter />
                  <br />
                  <BoardTypeFilter />
                  <br />
                  <RoomTypeFilter />
                  <br />
                  <RoomViewFilter />
                  <br />
                  <AllTouroperatorsFilter />
                  <br />
                  <HotelAttributeFilter mostwanted={false} />
                </div>
              </div>
            </div>
          )}
          {holidayType.accommodation && (
            <div className="accordion-item">
              <h2 className="accordion-header collapsed" id="header_last12">
                <button
                  style={{ paddingTop: 7, paddingBottom: 7 }}
                  type="button"
                  className={
                    "accordion-button " + (panel === 5 ? " " : " collapsed")
                  }
                  aria-expanded={panel === 5 ? "true" : false}
                  onClick={() => {
                    if (panel !== 5) setPanel(5);
                    else setPanel(-1);
                  }}
                  aria-controls="panelsStayOpen-last12"
                >
                  zuletzt gesehene Angebote
                </button>
              </h2>
              <div
                id="panelsStayOpen-last12"
                className={
                  "accordion-collapse collapse " + (panel === 5 ? " show " : "")
                }
                aria-labelledby="header_last12"
              >
                <div className="accordion-body">
                  {sessionHistory.map((hist, i) => {
                    return (
                      <>
                        <div style={{ minWith: "400px", padding: "5px" }}>
                          <span
                            data-bs-dismiss="modal"
                            className={
                              "list-group-item border bg-warning  bg-opacity-10 "
                            }
                            onClick={() => {
                              let hotel = {
                                value: hist.HotelName,
                                label: hist.HotelName,
                                HotelCodes: hist.HotelCodes,
                                Name: hist.HotelName,
                              };
                              dispatch(resetFilter(views.searchview));
                              dispatch(setOutbounddate(""));
                              dispatch(setInbounddate(""));
                              if (hist.request?.stations) {
                                let reqstats = hist.request.stations.split(",");
                                let stats = [];
                                for (let i = 0; i < reqstats.length; i++) {
                                  stats.push({
                                    value: reqstats[i],
                                    label: airportcity(reqstats[i]),
                                  });
                                }
                                dispatch(
                                  setSearchOption({
                                    property: "stations",
                                    data: stats,
                                  })
                                );
                              }

                              dispatch(setSelectedDestination([hotel]));
                              dispatch(setSelectedHotel(hotel));
                              search(dispatch, true, false);
                              dispatch(setView(views.hotelcrossview));
                            }}
                          >
                            {hist.HotelName}{" "}
                            <small>
                              in {hist.HotelCity}
                              <br />
                              <em>{hist.Remark}</em>
                            </small>
                          </span>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          {1 == 0 && (
            <div className="accordion-item">
              <h2 className="accordion-header collapsed" id="header_last12">
                <button
                  style={{ paddingTop: 7, paddingBottom: 7 }}
                  type="button"
                  className={
                    "accordion-button " + (panel === 6 ? " " : " collapsed")
                  }
                  aria-expanded={panel === 6 ? "true" : false}
                  onClick={() => {
                    if (panel !== 6) setPanel(6);
                    else setPanel(-1);
                  }}
                  aria-controls="panelsStayOpen-last12"
                >
                  meine Merkliste
                </button>
              </h2>
              <div
                id="panelsStayOpen-last12"
                className={
                  "accordion-collapse collapse " + (panel === 6 ? " show " : "")
                }
                aria-labelledby="header_last12"
              >
                <div className="accordion-body">
                  {memos.map((memo, i) => {
                    return <>{JSON.stringify(memo)}</>;
                  })}
                </div>
              </div>
            </div>
          )}
          {/*<div className="accordion-item">
                  <h2
                    className="accordion-header collapsed"
                    id="header_topseller"
                  >
                    <button
                      style={{ paddingTop: 7, paddingBottom: 7 }}
                      type="button"
                      className={
                        "accordion-button " + (panel === 7 ? " " : " collapsed")
                      }
                      aria-expanded={panel === 7 ? "true" : false}
                      onClick={() => {
                        if (panel !== 7) setPanel(7);
                        else setPanel(-1);
                      }}
                      aria-controls="panelsStayOpen-topseller"
                    >
                      unsere Topseller
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-topseller"
                    aria-labelledby="header_topseller"
                    className={
                      "accordion-collapse collapse " +
                      (panel === 7 ? " show " : "")
                    }
                  >
                    <div className="accordion-body">
                      {bestplaces.map((e, i) => {
                        return (
                          <>
                            <div style={{ minWith: "400px", padding: "10px" }}>
                              <span
                                data-bs-dismiss="modal"
                                className={
                                  "list-group-item border bg-warning  bg-opacity-10 "
                                }
                              >
                                <img src={e.pictureurl} width="100%"></img>
                                <br />
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: e.description,
                                  }}
                                />
                              </span>
                              <button
                                className="button-success"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                  //console.log(e.linkid);
                                  try {
                                    fetch(
                                      getHost() + "/getlinkinfo?id=" + e.linkid
                                    )
                                      .then((response) => response.json())
                                      .then((data) => {
                                        //console.log("Success:", data);
                                        //console.log("Success:", data.ret[0]);
                                        dispatch(setMid(e.linkid));
                                        memotosearch(data, dispatch);
                                      })
                                      .catch((error) => {
                                        console.error("Error:", error);
                                      });
                                  } catch (e) {
                                    console.log(e);
                                  }
                                }}
                              >
                                <em>{e.properties.mouseover}</em>
                                <br />
                                {e.properties.pricetext}
                              </button>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>*/}
        </div>

        <div
          className="bg-body-tertiary "
          style={{
            position: "fixed",
            zIndex: 1000,
            bottom: 0,
            left: 0,
            height: 60,
            width: "100%",
          }}
        >
          <div className="container">
            <div
              className="row mt-2 ps-2 pe-2 pt-2 bg-body-tertiary"
              style={{ height: 60 }}
            >
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    dispatch(resetFilter("mobile"));
                    getFilters(dispatch, false);
                  }}
                >
                  Filter löschen
                </button>
              </div>
              <div className="col-6 text-end">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={selectedDestination.length === 0}
                  onClick={() => {
                    if (!holidayType.accommodation) {
                      dispatch(fetchFares());
                      dispatch(setView(views.flightsview));
                      return;
                    }
                    dispatch(setSearchEnded(false));
                    //console.log(selectedDestination);
                    dispatch(setHotelOffersFound(true));
                    dispatch(setOffersSearched(false));
                    if (selectedDestination.length > 0) {
                      if (
                        selectedDestination[0].HotelID ||
                        selectedDestination[0].HotelCodes?.HotelGiataID
                      ) {
                        dispatch(setOutbounddate(""));
                        dispatch(setInbounddate(""));

                        dispatch(setOfferInfos({}));

                        dispatch(setHotelOfferInfos({}));

                        dispatch(
                          setSearchOption({ property: "category", data: "" })
                        );
                        dispatch(
                          setSearchOption({
                            property: "recommendationrate",
                            data: "0",
                          })
                        );
                        dispatch(
                          setSearchOption({
                            property: "ratingcount",
                            data: "0",
                          })
                        );

                        dispatch(setSelectedHotel(selectedDestination[0]));
                        search(dispatch, true, false);
                        dispatch(setView(views.hotelcrossview));
                      } else {
                        dispatch(setOutbounddate(""));
                        dispatch(setInbounddate(""));
                        dispatch(setOfferInfos({}));

                        dispatch(setHotelOfferInfos({}));
                        dispatch(setOfferPage(1));
                        search(dispatch, false, false);
                        dispatch(setView(views.offerview));
                      }
                    }

                    // dispatch(setOutbounddate(""));
                    // search(dispatch, false, false);
                    // dispatch(setView(views.offerview));
                  }}
                >
                  Suchen
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal" id="timeModal" style={{ zIndex: 10000 }}>
        <div class="modal-dialog modal-fullscreen">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Reisezeitraum</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div class="modal-body">
              <div class="row ">
                <div class="col-1 flex-grow-1 text-center">Mo</div>
                <div class="col-1 flex-grow-1 text-center">Di</div>
                <div class="col-1  flex-grow-1 text-center">Mi</div>
                <div class="col-1 flex-grow-1 text-center">Do</div>
                <div class="col-1  flex-grow-1 text-center">Fr</div>
                <div class="col-1  flex-grow-1 text-center fw-bold">Sa</div>
                <div class="col-1  flex-grow-1 text-center fw-bold">So</div>
              </div>

              {calenderLength.map((e) => {
                let monthStart = new Date(calendarStartDay.getTime());

                monthStart = new Date(
                  monthStart.setMonth(monthStart.getMonth() + e)
                );
                let days = [];
                let daysInMonth = daysInThisMonth(monthStart);
                for (let i = 0; i < daysInMonth; i++) {
                  let theDate = new Date(monthStart.getTime());

                  theDate.setDate(i + 1);

                  days.push(theDate);
                }

                let weeks = [];

                let weekCount = 1;
                for (let i = 0; i < days.length; i++) {
                  let day = days[i].getDay();
                  if (day === 1 && i > 0) {
                    weekCount = weekCount + 1;
                  }
                }

                for (let i = 0; i < weekCount; i++) {
                  weeks.push(i);
                }
                let calelement = undefined;

                if (
                  monthStart.getMonth() === dateFrom.getMonth() &&
                  monthStart.getFullYear() === dateFrom.getFullYear()
                ) {
                  calelement = "caldat";
                }
                return (
                  <>
                    <div
                      id={calelement}
                      class="d-flex flex-row  justify-content-center mt-4"
                    >
                      <div class="d-inline-flex fw-bold text-center">
                        <FormattedDate
                          value={monthStart}
                          year="numeric"
                          month="long"
                        />
                      </div>
                      <div class="col-1 text-center"></div>
                    </div>
                    {weeks.map((week, idx) => {
                      let weekdays = [];

                      let firstday = days[0].getDay();
                      if (firstday === 0) firstday = 7;
                      firstday = firstday - 1;

                      for (let i = 0; i < 7; i++) {
                        if (i < firstday && idx === 0) {
                          weekdays.push(undefined);
                        } else {
                          if (idx * 7 + i - firstday > daysInMonth - 1) {
                            weekdays.push(undefined);
                          } else {
                            weekdays.push(days[idx * 7 + i - firstday]);
                          }
                        }
                      }

                      return (
                        <div class="row mt-1">
                          {weekdays.map((dat) => {
                            let fcls = "";
                            let limitcls = "";

                            if (dat) {
                              if (
                                !calenderOnlyStart &&
                                datChooseFrom &&
                                datChooseUntil &&
                                dat.getTime() > datChooseFrom.getTime() &&
                                dat.getTime() < datChooseUntil.getTime()
                              ) {
                                limitcls = " bg-info text-light ";
                              }

                              if (
                                datChooseFrom &&
                                dat.getTime() - 3 * 60 * 60 * 1000 <
                                  datChooseFrom.getTime() &&
                                dat.getTime() + 3 * 60 * 60 * 1000 >
                                  datChooseFrom.getTime()
                              ) {
                                limitcls = " bg-primary text-light ";
                              }

                              if (
                                !calenderOnlyStart &&
                                datChooseUntil &&
                                dat.getTime() - 3 * 60 * 60 * 1000 <
                                  datChooseUntil.getTime() &&
                                dat.getTime() + 3 * 60 * 60 * 1000 >
                                  datChooseUntil.getTime()
                              ) {
                                limitcls = " bg-primary text-light ";
                              }

                              if (dat.getDay() === 0 || dat.getDay() === 6) {
                                fcls = " fw-bold ";
                              }
                            }
                            return (
                              <div
                                class={
                                  "col-1 flex-grow-1 text-center " +
                                  fcls +
                                  limitcls
                                }
                                onClick={() => {
                                  if (dat) {
                                    if (dat.getTime() < new Date().getTime()) {
                                      return;
                                    }
                                    if (calenderOnlyStart) {
                                      setDatChooseUntil(
                                        new Date(
                                          dat.getTime() +
                                            7 * 24 * 60 * 60 * 1000
                                        )
                                      );
                                      setDatChooseFrom(dat);
                                      return;
                                    }

                                    if (
                                      datChooseFrom &&
                                      !datChooseUntil &&
                                      dat.getTime() < datChooseFrom.getTime() &&
                                      dat.getTime() >
                                        datChooseFrom.getTime() -
                                          30 * 24 * 60 * 60 * 1000
                                    ) {
                                      setDatChooseUntil(datChooseFrom);
                                      setDatChooseFrom(dat);
                                      return;
                                    }

                                    if (
                                      datChooseFrom &&
                                      !datChooseUntil &&
                                      dat.getTime() < datChooseFrom.getTime()
                                    ) {
                                      setDatChooseFrom(dat);
                                      setDatChooseUntil(undefined);
                                      return;
                                    }

                                    if (dat.getTime() < new Date().getTime())
                                      return;

                                    if (datChooseFrom && !datChooseUntil) {
                                      if (
                                        datChooseFrom.getTime() +
                                          30 * 24 * 60 * 60 * 1000 <
                                        dat.getTime()
                                      ) {
                                        setDatChooseFrom(dat);
                                        setDatChooseUntil(undefined);
                                        return;
                                      }
                                    }

                                    if (datChooseUntil) {
                                      setDatChooseFrom(dat);
                                      setDatChooseUntil(undefined);
                                    } else {
                                      if (
                                        datChooseFrom.getTime() < dat.getTime()
                                      )
                                        setDatChooseUntil(dat);
                                    }
                                  }
                                }}
                              >
                                {dat ? dat.getDate() : <>&nbsp;&nbsp;</>}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </>
                );
              })}
            </div>

            <div class="modal-footer">
              <button
                onClick={() => {
                  //setDat(evt.target.value);

                  let dtFrom = new Date(
                    datChooseFrom.getFullYear(),
                    datChooseFrom.getMonth(),
                    datChooseFrom.getDate(),
                    12
                  );

                  let dtUntil = new Date(
                    datChooseUntil.getFullYear(),
                    datChooseUntil.getMonth(),
                    datChooseUntil.getDate(),
                    12
                  );

                  let tradt = { ...travelDate };
                  tradt.from = JSON.stringify(dtFrom);
                  tradt.to = JSON.stringify(dtUntil);
                  dispatch(
                    setSearchOption({
                      property: "travelDate",
                      data: tradt,
                    })
                  );
                  getFilters(dispatch, false);
                }}
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
              >
                {calenderOnlyStart ? "Datum" : "Zeitraum"}&nbsp; übernehmen
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchFullView;
