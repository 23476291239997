import React, { useState } from "react";

import AsyncSelect from "react-select/async";
import { useSelector, useDispatch } from "react-redux";
import { selectSearch, setSearchOption } from "amadeusredux/lib/searchSlice";

export default function AirportIataSelect(props) {
  const { isDisabled, stationindex } = props;
  const { stations } = useSelector(selectSearch);
  const dispatch = useDispatch();

  const mapResponseToValuesAndLabels = (data) => ({
    value: data.iata,
    label: data.label,
  });

  async function callApi(value) {
    const data = await fetch(
      `https://www.tstsrv.de/ratehawkapi/api/airports?text=` +
        encodeURIComponent(value)
    )
      .then((response) => response.json())
      .then((response) => response.map(mapResponseToValuesAndLabels));
    /*.then((final) =>
        final.filter((i) => i.label.toLowerCase().includes(value.toLowerCase()))
      );*/

    return data;
  }

  return (
    <AsyncSelect
      value={
        stations.length > stationindex
          ? stations[stationindex]
          : { value: "", label: "Bitte Station wählen" }
      }
      loadOptions={callApi}
      onInputChange={(data) => {
        console.log(data);
      }}
      options={
        stations.length > stationindex
          ? [stations[stationindex]]
          : [{ value: "", label: "Bitte Station wählen" }]
      }
      onChange={(data) => {
        console.log(data);
        let other = { value: "", label: "" };
        let dat = [];
        if (stations.length > 1 && stationindex === 0) {
          other = stations[1];
        }
        if (stations.length > 0 && stationindex === 1) {
          other = stations[0];
        }

        if (stationindex === 0) {
          dat = [
            {
              value: data.value,
              label: data.label,
            },
            other,
          ];
        }
        if (stationindex === 1) {
          dat = [
            other,
            {
              value: data.value,
              label: data.label,
            },
          ];
        }

        dispatch(
          setSearchOption({
            property: "stations",
            data: dat,
          })
        );
      }}
    />
  );
}
