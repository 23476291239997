import React, { useState } from "react";
import InfoPopupHover from "./InfoPopupHover";
import { FormattedNumber } from "react-intl";
import { FaCheck, FaLongArrowAltDown, FaLongArrowAltUp } from "react-icons/fa";
import { currencySymbol } from "../utils";

export default function PriceChange(props) {
  const { price, cacheprice, checked, travellerfactor, currencyCode } = props;

  let travelprice = price * travellerfactor;
  let travelcacheprice = cacheprice * travellerfactor;

  let equalfactor = travelcacheprice * (0.5 / 100);

  return (
    <>
      {(travelprice === travelcacheprice ||
        (travelprice < travelcacheprice + equalfactor &&
          travelprice > travelcacheprice - equalfactor)) &&
        checked === 1 && (
          <>
            <InfoPopupHover
              trigger={
                <span>
                  <FaCheck className="text-success" />
                </span>
              }
              objectcontent={
                <>Der Preis wurde vom Reiseveranstalter bestätigt.</>
              }
              position={"right center"}
            ></InfoPopupHover>
          </>
        )}

      {travelprice > travelcacheprice + equalfactor && (
        <>
          <InfoPopupHover
            trigger={
              <span>
                <FaLongArrowAltUp className="text-danger" />
              </span>
            }
            objectcontent={
              <>
                Leider ist diese Reise aktuell um{" "}
                <FormattedNumber
                  value={travelprice - travelcacheprice}
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
                {currencySymbol(currencyCode)} teurer geworden.
              </>
            }
            position={"right center"}
          ></InfoPopupHover>
        </>
      )}
      {travelprice <= travelcacheprice - equalfactor && (
        <>
          <InfoPopupHover
            trigger={
              <span>
                <FaLongArrowAltDown className="text-success" />
              </span>
            }
            objectcontent={
              <>
                Diese Reise ist aktuell billiger geworden. Sie sparen{" "}
                <FormattedNumber
                  value={travelcacheprice - travelprice}
                  minimumFractionDigits={0}
                  maximumFractionDigits={0}
                />
                {currencySymbol(currencyCode)}.
              </>
            }
            position={"right center"}
          ></InfoPopupHover>
        </>
      )}
    </>
  );
}
