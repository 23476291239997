import { setView } from "amadeusredux/lib/systemSlice";
import { views } from "amadeusredux/lib/utils";
import React from "react";
import { FaChevronCircleLeft } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

export default function BackButton(props) {
  const view = useSelector((state) => state.system.view);
  const inboundView = useSelector((state) => state.booking.inboundView);
  const offerInfos = useSelector((state) => state.offers.offerInfos);
  const viewBeforeSearch = useSelector(
    (state) => state.system.viewBeforeSearch
  );
  const holidayType = useSelector((state) => state.search.holidayType);

  const dispatch = useDispatch();

  const showBack =
    view === views.hotelview ||
    view === views.bookingview ||
    ((view === views.hotelcrossview || view === views.searchview) &&
      Array.isArray(offerInfos?.Offers?.Offer) &&
      offerInfos.Offers.Offer.length > 0);

  return (
    showBack && (
      <>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (view === views.searchview) dispatch(setView(viewBeforeSearch));
            if (view === views.hotelcrossview)
              dispatch(setView(views.offerview));
            if (view === views.hotelview) dispatch(setView(inboundView));
            if (view === views.bookingview) {
              if (props.setBookedData) props.setBookedData();
              if (holidayType.accommodation) {
                dispatch(setView(views.hotelcrossview));
              } else {
                dispatch(setView(views.flightsview));
              }
            }
          }}
        >
          <i class="bi bi-chevron-left"></i>
        </span>
        &nbsp;&nbsp;
      </>
    )
  );
}
