import { OFFERSPERREQUEST } from "./config";
import { filteredProperties, setTouroperatorsForHotel } from "./filterSlice";
import { format } from "date-fns";

import {
  availabilityAndPriceCheck as availabilityAndPriceCheckSlice,
  availableOfferData,
  getCatalogData,
  searchHotelOffers,
  setHotelData,
  setHotelOfferInfos,
  setHotelPage,
  setOfferId,
  setSelectedHotel,
} from "./hotelSlice";
import {
  queryOrder,
  queryOrderPlus,
  searchGeo,
  searchOffers,
  setMemo,
  setSpecialRoomTypes,
} from "./offersSlice";
import { setSearchEnded, setView } from "./systemSlice";
import {
  adultsOptions,
  airportcity,
  childrenAgeOptions,
  childrenOptions,
  durationOptions,
  durationkindOptions,
  getHost,
  printError,
  views,
} from "./utils";

import { v4 as uuidv4 } from "uuid";
import {
  setInbounddate,
  setMid,
  setOutbounddate,
  setQueryid,
  setSearchOption,
  setSelectedDestination,
  setSelectedMarketingActions,
  setSpecialRoomType,
} from "./searchSlice";
import { isArray } from "lodash";

export const getLink2Share = (id, sessionId, hotelOfferInfos, offer) => {
  let hf = {};
  if (hotelOfferInfos && offer) {
    hf = {
      ...hotelOfferInfos,
      Offers: { ...hotelOfferInfos.Offers },
      HotelDictionary: { Hotel: [...hotelOfferInfos.HotelDictionary.Hotel] },
    };
    hf.Offers.OfferCount = 1;
    let off = { ...offer };
    delete off.bookinginfo;
    hf.Offers.Offer = [{ ...off }];
    let hotels = hf.HotelDictionary;

    const hotel = hotels
      ? hotels.Hotel.find(
          (e) =>
            e.HotelID ===
            offer?.OfferServices?.Package?.Accommodation?.HotelRef?.HotelID
        )
      : {};
    hf.HotelDictionary.Hotel = [{ ...hotel }];
  }

  return fetch(getHost() + "/getlink2share", {
    method: "post",
    headers: new Headers({
      "content-type": "application/json",
    }),
    body: JSON.stringify({
      sessionid: sessionId,
      offerid: id,
      url: window.location.href,
      infos: hf,
    }),
  });
};

export const setOfferMemo = (
  id,
  giataid,
  sessionId,
  dispatch,
  hotelOfferInfos,
  offer
) => {
  let hf = {};
  if (hotelOfferInfos && offer) {
    hf = {
      ...hotelOfferInfos,
      Offers: { ...hotelOfferInfos.Offers },
      HotelDictionary: { Hotel: [...hotelOfferInfos.HotelDictionary.Hotel] },
    };
    hf.Offers.OfferCount = 1;
    let off = { ...offer };
    delete off.bookinginfo;
    hf.Offers.Offer = [{ ...off }];
    let hotels = hf.HotelDictionary;

    const hotel = hotels
      ? hotels.Hotel.find(
          (e) =>
            e.HotelID ===
            offer?.OfferServices?.Package?.Accommodation?.HotelRef?.HotelID
        )
      : {};
    hf.HotelDictionary.Hotel = [{ ...hotel }];
  }

  try {
    fetch(getHost() + "/setoffermemo", {
      method: "post",
      headers: new Headers({
        "content-type": "application/json",
      }),
      body: JSON.stringify({
        sessionid: sessionId,
        offerid: id,
        giataid: giataid,
        infos: hf,
        url: window.location.href,
      }),
    })
      .then((response) => response.json())
      .then((data) => getSessionMemo(id, sessionId, dispatch));
  } catch (e) {
    console.log(e);
  }
};

export const getSessionMemo = (id, sessionId, dispatch) => {
  try {
    fetch(getHost() + "/getsessionmemo?sessionid=" + sessionId)
      .then((response) => response.json())
      .then((data) => {
        //console.log("Success:", data);
        if (Array.isArray(data.ret)) {
          dispatch(setMemo(data.ret));
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  } catch (e) {
    console.log(e);
  }
};

export const availabilityAndPriceCheck = (
  dispatch,
  sessionId,
  id,
  adults,
  children,
  parentid,
  flight
) => {
  let vals = { adults, children, parentid };
  if (flight) {
    vals.FlightID = flight;
    vals.OfferID = id;
  } else {
    vals.OfferID = id;
  }
  //console.log(vals);
  dispatch(availabilityAndPriceCheckSlice(vals)).then((data) => {
    /*console.log(data);
    if (data.payload === 'already checking') {
      console.log(data.payload);
    }*/
    if (data?.payload?.error) {
      printError(data.payload.error);
      return;
    }
    let d = data.payload["ns2:AvailabilityAndPriceCheckRS"];
    /*if (d.$.Success === "true") {
      try {
        fetch(
          getHost() +
          "/setoffer?sessionid=" +
          sessionId +
          "&offerid=" +
          id +
          (d?.PriceInformation?.TotalPrice?.Amount?._
            ? "&price=" + d?.PriceInformation?.TotalPrice?.Amount?._
            : "")
        );
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        fetch(
          getHost() + "/setoffer?sessionid=" + sessionId + "&offerid=" + id
        );
      } catch (e) {
        console.log(e);
      }
    }*/
  });
};

export const getFilters = (dispatch, hotel) => {
  dispatch(
    filteredProperties({
      hotel: hotel,
      property: "TourOperator",
      update: { tourOperators: [] },
    })
  );

  dispatch(
    filteredProperties({
      hotel: hotel,
      property: "DestinationStations",
      update: { destinationStations: [] },
    })
  );

  /*dispatch(
    filteredProperties({
      hotel: hotel,
      property: "All",
      update: { airlines: [] },
    })
  );*/

  dispatch(
    filteredProperties({
      hotel: hotel,
      property: "BoardTypes",
      update: { boardType: [] },
    })
  );

  dispatch(
    filteredProperties({
      hotel: hotel,
      property: "RoomView",
      update: { roomView: [] },
    })
  );

  dispatch(
    filteredProperties({
      hotel: hotel,
      property: "RoomType",
      update: { roomType: [] },
    })
  );

  dispatch(
    filteredProperties({
      hotel: hotel,
      property: "HotelAttributes",
      update: {},
    })
  );

  dispatch(
    filteredProperties({
      hotel: hotel,
      property: "TouristicRegions",
      update: {},
    })
  );

  try {
    return dispatch(
      filteredProperties({
        hotel: hotel,
        property: "TransportOperatingBrand",
        update: {
          operatingbrand: [],
          inbounddate: "",
          outbounddate: "",
        },
      })
    );
  } catch (e) {}
};

export const search = (dispatch, hotel, geosearch, offset, sameHotel) => {
  if (!offset) {
    offset = 0;
    dispatch(setQueryid(uuidv4()));
  }
  if (offset === 0 && !hotel) {
    dispatch(setTouroperatorsForHotel([]));
  }
  if (geosearch) {
    dispatch(setSelectedHotel({}));

    dispatch(searchGeo({ offset: offset })).then((data) => {
      if (data?.payload?.error) {
        printError(data.payload.error);
        return;
      }
      if (data?.payload?.Offers?.OfferCount > 0) {
        dispatch(setView(views.offerview));
      } else {
        printError("Kein passendes Angebot gefunden");
      }
    });
    return;
  }

  if (!offset > 0) {
    //dispatch(setSelectedMarketingActions([]));
    getFilters(dispatch, hotel);
    dispatch(setSearchEnded(false));
  }
  if (hotel) {
    //dispatch(setHotelOfferInfos({}));
    /*let start = new Date(dates[0]);
    let end = new Date(dates[1]);

    let loop = new Date(start);
    offset = 0;
    while (loop <= end) {
      alert(loop);
      dispatch(setOutbounddate(format(new Date(loop), "yyyy-MM-dd")));
      //dispatch(setHotelOfferInfosV2({ offset: offset }));
      var newDate = loop.setDate(loop.getDate() + 1);
      loop = new Date(newDate);
      offset++;
    }*/

    dispatch(searchHotelOffers({ offset: offset })).then((data) => {
      if (data?.payload?.error) {
        printError(data.payload.error);
        return;
      }
      //console.log(offset);
      //console.log(data.payload.Offers.MoreOffersAvailable);
      //if (offset < 600) {
      if (data?.payload?.Offers?.MoreOffersAvailable) {
        search(
          dispatch,
          hotel,
          geosearch,
          offset + data.payload.Offers.OfferCount
        );
      } else {
        if (offset === 0) {
          dispatch(setSearchEnded(true));
        }
      }

      if (data.payload.Offers.OfferCount > 0 && offset === 0) {
        if (!sameHotel) {
          dispatch(
            setHotelData({
              ...data.payload,
              ...{ Offers: { Offer: data.payload.Offers.Offer[0] } },
            })
          );
        }
        /*if (data.payload.version === 2)
            dispatch(setView(views.hotelcrossview));
          else dispatch(setView(views.hotelclassicview));*/
      } else {
        if (offset === 0) printError("Kein passendes Angebot gefunden");
      }
      //}
    });
  } else {
    dispatch(setSelectedHotel({}));

    dispatch(searchOffers({ offset: offset })).then((data) => {
      if (data?.payload?.error) {
        printError(data.payload.error);
        return;
      }
      if (data?.payload?.Offers?.MoreOffersAvailable) {
        search(
          dispatch,
          hotel,
          geosearch,
          offset + data.payload.Offers.OfferCount
        );
      } else {
        getTourOperatorsForHotel(hotel, dispatch, true);
        //dispatch(queryOrder());
        //dispatch(setSelectedMarketingActions([]));
        dispatch(setSpecialRoomType([]));
        dispatch(setSearchEnded(true));
      }
      if (data?.payload?.Offers?.OfferCount > 0 && offset === 0) {
        //dispatch(setView(views.offerview));
      } else {
        if (offset === 0) printError("Kein passendes Angebot gefunden");
      }
    });
  }
};

export const getTourOperatorsForHotel = (hotel, dispatch, firstcall) => {
  dispatch(
    filteredProperties({
      hotel: hotel,
      property: "TourOperatorsForHotel",
      firstcall: firstcall,
      update: {},
    })
  )
    .then((data) => {
      if (
        Array.isArray(
          data?.payload?.TourOperatorsForHotels?.TourOperatorsForHotel
        ) &&
        data.payload.TourOperatorsForHotels.TourOperatorsForHotel.length > 0
      ) {
        getTourOperatorsForHotel(hotel, dispatch, false);
      } else {
        dispatch(queryOrderPlus());
      }
    })
    .catch((e) => {
      console.log(e);
    });
};

export const memotosearch = (data, dispatch) => {
  let dat = Array.isArray(data.ret) && data.ret.length > 0 ? data.ret[0] : {};
  if (dat?.data) dat = dat.data;

  let hotelsearch = false;
  if (dat.HotelGiataID) {
    //console.log("HotelGiataID");
    dispatch(
      setSelectedHotel({
        label: dat.HotelName ? dat.HotelName : "Favorit",
        HotelCodes: {
          HotelGiataID: dat.HotelGiataID * 1,
          label: dat.HotelName ? dat.HotelName : "Favorit",
          value: dat.HotelName ? dat.HotelName : "Favorit",
          Name: dat.HotelName ? dat.HotelName : "Favorit",
        },
      })
    );
    dispatch(
      setSelectedDestination([
        {
          label: dat.HotelName ? dat.HotelName : "Favorit",
          HotelID: dat.HotelGiataID * 1,
          HotelCodes: {
            HotelGiataID: dat.HotelGiataID * 1,
            label: dat.HotelName ? dat.HotelName : "Favorit",
            value: dat.HotelName ? dat.HotelName : "Favorit",
            Name: dat.HotelName ? dat.HotelName : "Favorit",
          },
        },
      ])
    );
    hotelsearch = true;
  } else {
    if (
      Array.isArray(dat.request.HotelGiataID) &&
      dat.request.HotelGiataID.length > 1
    ) {
      dispatch(
        setSelectedDestination(
          dat.request.HotelGiataID.map((e, i) => {
            return {
              label:
                Array.isArray(dat.request.HotelGiataID) &&
                Array.isArray(dat.request.HotelName) &&
                dat.request.HotelName.length === dat.request.HotelGiataID.length
                  ? dat.request.HotelName[i]
                  : "Favorit",
              HotelCodes: {
                HotelGiataID: e * 1,
                label:
                  Array.isArray(dat.request.HotelGiataID) &&
                  Array.isArray(dat.request.HotelName) &&
                  dat.request.HotelName.length ===
                    dat.request.HotelGiataID.length
                    ? dat.request.HotelName[i]
                    : "Favorit",
                value:
                  Array.isArray(dat.request.HotelGiataID) &&
                  Array.isArray(dat.request.HotelName) &&
                  dat.request.HotelName.length ===
                    dat.request.HotelGiataID.length
                    ? dat.request.HotelName[i]
                    : "Favorit",
                Name:
                  Array.isArray(dat.request.HotelGiataID) &&
                  Array.isArray(dat.request.HotelName) &&
                  dat.request.HotelName.length ===
                    dat.request.HotelGiataID.length
                    ? dat.request.HotelName[i]
                    : "Favorit",
              },
            };
          })
        )
      );
    } else {
      if (Array.isArray(dat.request.CityID)) {
        dispatch(
          setSelectedDestination(
            dat.request.CityID.map((e, i) => {
              return {
                CityID: e * 1,
                label:
                  Array.isArray(dat.request.CityID) &&
                  Array.isArray(dat.request.CityName) &&
                  dat.request.CityName.length === dat.request.CityID.length
                    ? dat.request.CityName[i]
                    : "Favorit",
                value:
                  Array.isArray(dat.request.CityID) &&
                  Array.isArray(dat.request.CityName) &&
                  dat.request.CityName.length === dat.request.CityID.length
                    ? dat.request.CityName[i]
                    : "Favorit",
                Name:
                  Array.isArray(dat.request.CityID) &&
                  Array.isArray(dat.request.CityName) &&
                  dat.request.CityName.length === dat.request.CityID.length
                    ? dat.request.CityName[i]
                    : "Favorit",
              };
            })
          )
        );
      } else {
        if (dat.HotelCityID) {
          dispatch(
            setSelectedDestination([
              {
                CityID: dat.HotelCityID * 1,
                label: dat.CityName ? dat.CityName : "Favorit",
                value: dat.CityName ? dat.CityName : "Favorit",
                Name: dat.CityName ? dat.CityName : "Favorit",
              },
            ])
          );
        } else {
          if (Array.isArray(dat.request.RegionID)) {
            dispatch(
              setSelectedDestination(
                dat.request.RegionID.map((e, i) => {
                  return {
                    RegionID: e * 1,
                    label:
                      Array.isArray(dat.request.RegionID) &&
                      Array.isArray(dat.request.RegionName) &&
                      dat.request.RegionName.length ===
                        dat.request.RegionID.length
                        ? dat.request.RegionName[i]
                        : "Favorit",
                    value:
                      Array.isArray(dat.request.RegionID) &&
                      Array.isArray(dat.request.RegionName) &&
                      dat.request.RegionName.length ===
                        dat.request.RegionID.length
                        ? dat.request.RegionName[i]
                        : "Favorit",
                    Name:
                      Array.isArray(dat.request.RegionID) &&
                      Array.isArray(dat.request.RegionName) &&
                      dat.request.RegionName.length ===
                        dat.request.RegionID.length
                        ? dat.request.RegionName[i]
                        : "Favorit",
                  };
                })
              )
            );
          } else {
            if (dat.HotelRegionID) {
              dispatch(
                setSelectedDestination([
                  {
                    RegionID: dat.HotelRegionID * 1,
                    label: dat.RegionName ? dat.RegionName : "Favorit",
                    value: dat.RegionName ? dat.RegionName : "Favorit",
                    Name: dat.RegionName ? dat.RegionName : "Favorit",
                  },
                ])
              );
            } else {
              if (
                Array.isArray(dat.request.RegionGroupID) &&
                dat.request.RegionGroupID.length > 1
              ) {
                dispatch(
                  setSelectedDestination(
                    dat.request.RegionGroupID.map((e, i) => {
                      return {
                        RegionGroupID: e * 1,
                        label:
                          Array.isArray(dat.request.RegionGroupID) &&
                          Array.isArray(dat.request.RegionName) &&
                          dat.request.RegionName.length ===
                            dat.request.RegionGroupID.length
                            ? dat.request.RegionName[i]
                            : "Favorit",
                        value:
                          Array.isArray(dat.request.RegionGroupID) &&
                          Array.isArray(dat.request.RegionName) &&
                          dat.request.RegionName.length ===
                            dat.request.RegionGroupID.length
                            ? dat.request.RegionName[i]
                            : "Favorit",
                        Name:
                          Array.isArray(dat.request.RegionGroupID) &&
                          Array.isArray(dat.request.RegionName) &&
                          dat.request.RegionName.length ===
                            dat.request.RegionGroupID.length
                            ? dat.request.RegionName[i]
                            : "Favorit",
                      };
                    })
                  )
                );
              } else {
                dispatch(
                  setSelectedDestination([
                    {
                      RegionGroupID: dat.request.RegionGroupID * 1,
                      label: dat.RegionName ? dat.RegionName : "Favorit",
                      value: dat.RegionName ? dat.RegionName : "Favorit",
                      Name: dat.RegionName ? dat.RegionName : "Favorit",
                    },
                  ])
                );
              }
            }
          }
        }
      }
    }
  }
  if (dat.request.minDate) {
    let td = {
      from: JSON.stringify(new Date(dat.request.minDate)),
      to: JSON.stringify(new Date(dat.request.maxDate)),
    };
    dispatch(setSearchOption({ property: "travelDate", data: td }));
  }

  //console.log("REQUEST", dat.request);

  if (dat.request.outbounddate) {
    let td = {
      from: JSON.stringify(new Date(dat.request.outbounddate)),
      to: JSON.stringify(
        new Date(
          dat.request.inbounddate
            ? dat.request.inbounddate
            : dat.request.maxDate
        )
      ),
    };
    dispatch(setSearchOption({ property: "travelDate", data: td }));
  }

  if (dat.request.adults)
    dispatch(
      setSearchOption({
        property: "adults",
        data: adultsOptions.find((o) => o.value === dat.request.adults + ""),
      })
    );
  if (dat.request.children) {
    dispatch(
      setSearchOption({
        property: "children",
        data: childrenOptions.find(
          (o) => o.value === dat.request.children + ""
        ),
      })
    );

    if (dat.request.childrenages) {
      let childrenages = [
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
      ];

      let tmpages = [];
      if (Array.isArray(dat.request.childrenages)) {
        tmpages = [...dat.request.childrenages];
      } else {
        tmpages = dat.request.childrenages.split(",");
      }
      if (Array.isArray(tmpages)) {
        for (let i = 0; i < tmpages.length; i++) {
          childrenages[i] = childrenAgeOptions.find(
            (o) => o.value === tmpages[i] + ""
          );
        }
      }
      dispatch(
        setSearchOption({
          property: "childrenages",
          data: childrenages,
        })
      );
    }
  }

  if (dat.request.touroperators) {
    dispatch(
      setSearchOption({
        property: "tourOperators",
        data: dat.request.touroperators.split(","),
      })
    );
  }
  if (dat.request.hotelAttribute) {
    if (!Array.isArray(dat.request.hotelAttribute))
      dat.request.hotelAttribute = JSON.parse(dat.request.hotelAttribute);

    //console.log(dat.request.hotelAttribute);
    dispatch(
      setSearchOption({
        property: "hotelAttribute",
        data: dat.request.hotelAttribute,
      })
    );
  }
  if (dat.request.roomType) {
    //console.log(dat.request.roomType);
    dispatch(
      setSearchOption({
        property: "roomType",
        data: JSON.parse(dat.request.roomType),
      })
    );
  }
  if (dat.request.roomView) {
    // console.log(dat.request.roomView);
    dispatch(
      setSearchOption({
        property: "roomView",
        data: JSON.parse(dat.request.roomView),
      })
    );
  }
  if (dat.request.recommendationrate) {
    dispatch(
      setSearchOption({
        property: "recommendationrate",
        data: dat.request.recommendationrate,
      })
    );
  }
  if (dat.request.category) {
    dispatch(
      setSearchOption({
        property: "category",
        data: dat.request.category,
      })
    );
  }
  if (dat.request.ratingcount) {
    dispatch(
      setSearchOption({
        property: "ratingcount",
        data: dat.request.ratingcount,
      })
    );
  }
  if (dat.request.boardType) {
    //console.log(dat.request.boardType);
    dispatch(
      setSearchOption({
        property: "boardType",
        data: JSON.parse(dat.request.boardType),
      })
    );
  }
  if (dat.request.duration)
    dispatch(
      setSearchOption({
        property: "duration",
        data: durationOptions[dat.request.duration],
      })
    );
  if (dat.request.couponcode) {
    dispatch(
      setSearchOption({
        property: "couponcode",
        data: dat.request.couponcode,
      })
    );
  }
  if (
    dat.request.durationkind &&
    durationkindOptions.find((e) => e.value === dat.request.durationkind)
  )
    dispatch(
      setSearchOption({
        property: "durationkind",
        data: durationkindOptions.find(
          (o) => o.value === dat.request.durationkind + ""
        ),
      })
    );

  if (dat.request.stations) {
    let reqstats = dat.request.stations.split(",");
    let stats = [];
    for (let i = 0; i < reqstats.length; i++) {
      stats.push({
        value: reqstats[i],
        label: airportcity(reqstats[i]),
      });
    }
    dispatch(
      setSearchOption({
        property: "stations",
        data: stats,
      })
    );
  }
  dispatch(setHotelPage(1));
  if (dat.OfferID) dispatch(setOfferId(dat.OfferID));
  if (dat.DepartureDate) dispatch(setOutbounddate(dat.DepartureDate));
  if (dat.ReturnDate) dispatch(setInbounddate(dat.ReturnDate));

  if (dat.infos) {
    dispatch(setSelectedHotel(dat.infos.HotelDictionary.Hotel[0]));
    dispatch(
      setHotelData({
        ...dat.infos,
        ...{ Offers: { Offer: dat.infos.Offers.Offer[0] } },
      })
    );
    if (
      Array.isArray(dat?.infos?.Offers?.Offer) &&
      dat.infos.Offers.Offer.length > 0
    ) {
      dat.infos.Offers.Offer[0] = {
        ...dat.infos.Offers.Offer[0],
        dboffer: true,
      };
    }
    dispatch(setHotelOfferInfos(dat.infos));
    dispatch(setView(views.hotelcrossview));
  } else {
    search(dispatch, hotelsearch, false);
    if (!hotelsearch) dispatch(setView(views.offerview));
    else dispatch(setView(views.hotelcrossview));
    dispatch(setMid(""));
  }
  window.scrollTo(0, 0);
};

export const cookietosearch = (data, dispatch) => {
  let dat = Array.isArray(data.ret) && data.ret.length > 0 ? data.ret[0] : {};
  if (dat?.data) dat = dat.data;

  if (Array.isArray(dat.request.HotelGiataID)) {
    dispatch(
      setSelectedDestination(
        dat.request.HotelGiataID.map((e, i) => {
          return {
            label: dat.names[i],
            Name: dat.names[i],
            HotelCodes: {
              HotelGiataID: e * 1,
              label: dat.names[i],
              value: dat.names[i],
              Name: dat.names[i],
            },
          };
        })
      )
    );
  }

  if (Array.isArray(dat.request.CityID)) {
    dispatch(
      setSelectedDestination(
        dat.request.CityID.map((e, i) => {
          return {
            CityID: e * 1,
            label: dat.names[i],
            value: dat.names[i],
            Name: dat.names[i],
          };
        })
      )
    );
  }

  if (Array.isArray(dat.request.RegionID)) {
    dispatch(
      setSelectedDestination(
        dat.request.RegionID.map((e, i) => {
          return {
            RegionID: e * 1,
            label: dat.names[i],
            value: dat.names[i],
            Name: dat.names[i],
          };
        })
      )
    );
  }

  if (Array.isArray(dat.request.RegionGroupID)) {
    dispatch(
      setSelectedDestination(
        dat.request.RegionGroupID.map((e, i) => {
          return {
            RegionGroupID: e * 1,
            label: dat.names[i],
            value: dat.names[i],
            Name: dat.names[i],
          };
        })
      )
    );
  }

  if (
    Array.isArray(dat.setSelectedDestination) &&
    dat.setSelectedDestination.length > 0 &&
    dat.setSelectedDestination[0].HotelID
  ) {
    dispatch(setSelectedDestination(dat.setSelectedDestination));
  }

  let td = {
    from: JSON.stringify(new Date(dat.request.minDate)),
    to: JSON.stringify(new Date(dat.request.maxDate)),
  };

  dispatch(setSearchOption({ property: "travelDate", data: td }));
  if (dat.request.adults)
    dispatch(
      setSearchOption({
        property: "adults",
        data: adultsOptions.find((o) => o.value === dat.request.adults + ""),
      })
    );
  if (dat.request.children) {
    dispatch(
      setSearchOption({
        property: "children",
        data: childrenOptions.find(
          (o) => o.value === dat.request.children + ""
        ),
      })
    );

    if (dat.request.childrenages) {
      let childrenages = [
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
      ];
      let tmpages = dat.request.childrenages.split(",");
      if (Array.isArray(tmpages)) {
        for (let i = 0; i < tmpages.length; i++) {
          childrenages[i] = childrenAgeOptions.find(
            (o) => o.value === tmpages[i] + ""
          );
        }
      }
      dispatch(
        setSearchOption({
          property: "childrenages",
          data: childrenages,
        })
      );
    }
  }

  /*if (dat.request.touroperators) {
    dispatch(
      setSearchOption({
        property: "tourOperators",
        data: dat.request.touroperators.split(","),
      })
    );
  }
  if (dat.request.hotelAttribute) {
    console.log(dat.request.hotelAttribute)
    dispatch(
      setSearchOption({
        property: "hotelAttribute",
        data: JSON.parse(dat.request.hotelAttribute),
      })
    );
  }
  if (dat.request.roomType) {
    console.log(dat.request.roomType)
    dispatch(
      setSearchOption({
        property: "roomType",
        data: JSON.parse(dat.request.roomType),
      })
    );
  }
  if (dat.request.roomView) {
    console.log(dat.request.roomView)
    dispatch(
      setSearchOption({
        property: "roomView",
        data: JSON.parse(dat.request.roomView),
      })
    );
  }
  if (dat.request.boardType) {
    console.log(dat.request.boardType)
    dispatch(
      setSearchOption({
        property: "boardType",
        data: JSON.parse(dat.request.boardType),
      })
    );
  }*/
  if (dat.request.duration)
    dispatch(
      setSearchOption({
        property: "duration",
        data: durationOptions[dat.request.duration],
      })
    );
  if (dat.request.stations) {
    let reqstats = dat.request.stations.split(",");
    let stats = [];
    for (let i = 0; i < reqstats.length; i++) {
      stats.push({
        value: reqstats[i],
        label: airportcity(reqstats[i]),
      });
    }
    dispatch(
      setSearchOption({
        property: "stations",
        data: stats,
      })
    );
  }
  dispatch(setHotelPage(1));
  dispatch(setOfferId(dat.OfferID));
  dispatch(setOutbounddate(dat.DepartureDate));
  if (dat.ReturnDate) dispatch(setInbounddate(dat.ReturnDate));
};

export const suggestiontosearch = (dat, dispatch) => {
  if (Array.isArray(dat.request.HotelGiataID)) {
    dispatch(
      setSelectedDestination(
        dat.request.HotelGiataID.map((e, i) => {
          return {
            label: dat.names[i],
            HotelCodes: {
              HotelGiataID: e * 1,
              label: dat.label,
              value: dat.value,
              Name: dat.label,
            },
          };
        })
      )
    );
  }

  if (Array.isArray(dat.request.CityID)) {
    dispatch(
      setSelectedDestination(
        dat.request.CityID.map((e, i) => {
          return {
            CityID: e * 1,
            label:
              Array.isArray(dat?.request?.CityName) &&
              dat.request.CityName.length > i
                ? dat.request.CityName[i]
                : Array.isArray(dat.names) && dat.names.length > i
                ? dat.names[i]
                : dat.label,
            value: dat.value,
            Name: dat.label,
          };
        })
      )
    );
  }

  if (Array.isArray(dat.request.RegionID)) {
    dispatch(
      setSelectedDestination(
        dat.request.RegionID.map((e, i) => {
          return {
            RegionID: e * 1,
            label:
              Array.isArray(dat?.request?.RegionName) &&
              dat.request.RegionName.length > i
                ? dat.request.RegionName[i]
                : Array.isArray(dat.names) && dat.names.length > i
                ? dat.names[i]
                : dat.label,
            value: dat.value,
            Name: dat.label,
          };
        })
      )
    );
  }

  if (Array.isArray(dat.request.RegionGroupID)) {
    dispatch(
      setSelectedDestination(
        dat.request.RegionGroupID.map((e, i) => {
          return {
            RegionGroupID: e * 1,
            label:
              Array.isArray(dat?.request?.RegionName) &&
              dat.request.RegionName.length > i
                ? dat.request.RegionName[i]
                : Array.isArray(dat.names) && dat.names.length > i
                ? dat.names[i]
                : dat.label,
            value: dat.value,
            Name: dat.label,
          };
        })
      )
    );
  }

  /*if (dat.request.minDate && dat.request.maxDate) {
    let td = {
      from: JSON.stringify(new Date(dat.request.minDate)),
      to: JSON.stringify(new Date(dat.request.maxDate)),
    };
    dispatch(setSearchOption({ property: "travelDate", data: td }));
  }

  if (dat.request.adults)
    dispatch(
      setSearchOption({
        property: "adults",
        data: adultsOptions.find((o) => o.value === dat.request.adults + ""),
      })
    );
  if (dat.request.children) {
    dispatch(
      setSearchOption({
        property: "children",
        data: childrenOptions.find(
          (o) => o.value === dat.request.children + ""
        ),
      })
    );

    if (dat.request.childrenages) {
      let childrenages = [
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
      ];
      let tmpages = dat.request.childrenages.split(",");
      if (Array.isArray(tmpages)) {
        for (let i = 0; i < tmpages.length; i++) {
          childrenages[i] = childrenAgeOptions.find(
            (o) => o.value === tmpages[i] + ""
          );
        }
      }
      dispatch(
        setSearchOption({
          property: "childrenages",
          data: childrenages,
        })
      );
    }
  }

  if (dat.request.touroperators) {
    dispatch(
      setSearchOption({
        property: "tourOperators",
        data: dat.request.touroperators.split(","),
      })
    );
  }*/
  /*
  if (dat.request.hotelAttribute) {
    console.log(dat.request.hotelAttribute)
    dispatch(
      setSearchOption({
        property: "hotelAttribute",
        data: JSON.parse(dat.request.hotelAttribute),
      })
    );
  }
  if (dat.request.roomType) {
    console.log(dat.request.roomType)
    dispatch(
      setSearchOption({
        property: "roomType",
        data: JSON.parse(dat.request.roomType),
      })
    );
  }
  if (dat.request.roomView) {
    console.log(dat.request.roomView)
    dispatch(
      setSearchOption({
        property: "roomView",
        data: JSON.parse(dat.request.roomView),
      })
    );
  }
  if (dat.request.boardType) {
    console.log(dat.request.boardType)
    dispatch(
      setSearchOption({
        property: "boardType",
        data: JSON.parse(dat.request.boardType),
      })
    );
  }*/
  /*if (dat.request.duration && durationOptions[dat.request.duration])
    dispatch(
      setSearchOption({
        property: "duration",
        data: durationOptions[dat.request.duration],
      })
    );

  if (
    dat.request.durationkind &&
    durationkindOptions.find((e) => e.value === dat.request.durationkind)
  )
    dispatch(
      setSearchOption({
        property: "durationkind",
        data: durationkindOptions.find(
          (o) => o.value === dat.request.durationkind + ""
        ),
      })
    );

  if (dat.request.stations) {
    let reqstats = dat.request.stations.split(",");

    let stats = [];
    for (let i = 0; i < reqstats.length; i++) {
      reqstats[i] = reqstats[i].trim();
      console.log("<" + reqstats[i] + ">");
      stats.push({
        value: reqstats[i],
        label: airportcity(reqstats[i]),
      });
    }
    dispatch(
      setSearchOption({
        property: "stations",
        data: stats,
      })
    );
  }*/
  dispatch(setHotelPage(1));
};
