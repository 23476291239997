import React, { useState } from "react";
import { Modal } from "bootstrap";

import { useDispatch, useSelector } from "react-redux";
import {
  resetFilter,
  selectSearch,
  setInbounddate,
  setOutbounddate,
  setSearchOption,
  setSelectedDestination,
} from "amadeusredux/lib/searchSlice";
import { getFilters, search } from "amadeusredux/lib/api";
import {
  setOpenSearch,
  setSearchEnded,
  setView,
  setViewBeforeSearch,
} from "amadeusredux/lib/systemSlice";
import {
  adultsOptions,
  airportcity,
  childrenAgeOptions,
  childrenOptions,
} from "../utils";
import { views } from "amadeusredux/lib/utils";
import { durationValues } from "amadeusredux/lib/utils";
import { FormattedDate } from "react-intl";
import { fetchSessionHistory } from "amadeusredux/lib/propertiesSlice";
import {
  setHotelOffersFound,
  setSelectedHotel,
} from "amadeusredux/lib/hotelSlice";
import { useOrientation } from "@uidotdev/usehooks";

//import Select from "../components/Select";
import DestinationSelect from "../searchs/DestinationSelect";
import AirportSelect from "../searchs/AirportSelect";
import PriceFilter from "../filters/PriceFilter";
import HotelAttributeFilter from "../filters/HotelAttributeFilter";
import OfferFilter from "../filters/OfferFilter";
import BoardTypeFilter from "../filters/BoardTypeFilter";
import RoomTypeFilter from "../filters/RoomTypeFilter";
import FlightFilter from "../filters/FlightFilter";
import DurationSelect from "../searchs/DurationSelect";
import AirlineFilter from "../filters/AirlineFilter";
import Select from "../components/Select";

import FlighttimeFilter from "../filters/FlighttimeFilter";
import RoomViewFilter from "../filters/RoomViewFilter";
import AllTouroperatorsFilter from "../filters/AllTouroperatorsFilter";
import { setOfferPage } from "amadeusredux/lib/offersSlice";

function SearchInfoView() {
  const dispatch = useDispatch();
  const regionGroups = useSelector((state) => state.properties.regionGroups);
  const airlines = useSelector((state) => state.properties.airlines);
  const sessionHistory = useSelector(
    (state) => state.properties.sessionHistory
  );

  const sessionId = useSelector((state) => state.system.sessionId);
  const holidayType = useSelector((state) => state.search.holidayType);
  const transportOperators = useSelector(
    (state) => state.filter.transportOperators
  );

  let destinationAirlines = transportOperators.map((e) => {
    let a = airlines.find((f) => e.value === f.value);
    return { ...e, properties: a ? a.properties : {} };
  });

  const [panel, setPanel] = React.useState(-1);
  const durationkind = useSelector((state) => state.search.durationkind);
  const childrenages = useSelector((state) => state.search.childrenages);
  const children = useSelector((state) => state.search.children);
  const adults = useSelector((state) => state.search.adults);
  //console.log(suggestions);
  const oneway = useSelector((state) => state.search.oneway);

  const view = useSelector((state) => state.system.view);
  React.useEffect(() => {
    if (sessionId) dispatch(fetchSessionHistory({ sessionid: sessionId }));
  }, [sessionId]);

  const selectedDestination = useSelector(
    (state) => state.search.selectedDestination
  );

  const openSearch = useSelector((state) => state.system.openSearch);
  const memos = useSelector((state) => state.offers.memo);
  //console.log(selectedDestination);
  const {
    duration,
    stations,
    travelDate,
    category,
    recommendationrate,
    ratingcount,
  } = useSelector(selectSearch);

  const departureStations = useSelector(
    (state) => state.properties.departureStations
  );

  const delay = useSelector((state) => state.system.delay);

  //console.log("TRAVELDATE", travelDate);

  React.useEffect(() => {
    getFilters(dispatch, false);
  }, []);

  React.useEffect(() => {
    let hit = false;
    if (regionGroups.length > 0 && selectedDestination.length === 0) {
      hit = true;
      dispatch(
        setSelectedDestination([
          { ...regionGroups[0], label: regionGroups[0].Name },
        ])
      );
    }
    if (!category) {
      hit = true;
      dispatch(
        setSearchOption({
          property: "category",
          data: "5",
        })
      );
    }
    if (stations.length === 0) {
      hit = true;
      dispatch(
        setSearchOption({
          property: "stations",
          data: [
            { value: "BER", label: "BER (Berlin-Brandenburg)" },
            { value: "HAJ", label: "HAJ (Hannover)" },
          ],
        })
      );
    }
    if (hit) {
      getFilters(dispatch, false);
    }
  }, [regionGroups]);

  /*React.useEffect(() => {
    if (openSearch) {
      setTimeout(() => {
        //console.log(document.getElementById("searchModal"));
        let ele = document.getElementById("searchModal");
        if (ele) {
          let searchModal = new Modal(document.getElementById("searchModal"));
          searchModal.show();
        }
      }, 200);
    }
    dispatch(setOpenSearch(false));
  }, [openSearch]);*/

  const [dropclassNameStations, setDropclassNameStations] =
    React.useState("dropdown-menu");
  const [dropclassNameAirlines, setDropclassNameAirlines] =
    React.useState("dropdown-menu");
  const [dropHistory, setDropHistory] = React.useState(false);
  let dateFrom = new Date(travelDate.from.split('"').join(""));
  let datFrom =
    dateFrom.getFullYear() +
    "-" +
    (dateFrom.getMonth() + 1 < 10 ? "0" : "") +
    (dateFrom.getMonth() + 1) +
    "-" +
    (dateFrom.getDate() < 10 ? "0" : "") +
    dateFrom.getDate();
  let dateUntil = new Date(travelDate.to.split('"').join(""));
  let datUntil =
    dateUntil.getFullYear() +
    "-" +
    (dateUntil.getMonth() + 1 < 10 ? "0" : "") +
    (dateUntil.getMonth() + 1) +
    "-" +
    (dateUntil.getDate() + 1 < 10 ? "0" : "") +
    dateUntil.getDate();

  //console.log(departureStations);

  let adultoption = 0;
  let iac = 0;
  adultsOptions.forEach((e) => {
    if (e.value === adults.value) adultoption = iac;
    iac++;
  });

  let childrenoption = 0;
  iac = 0;
  childrenOptions.forEach((e) => {
    if (e.value === children.value) childrenoption = iac;
    iac++;
  });

  //console.log(panel);
  let calenderOnlyStart = false;
  if (oneway && !holidayType.accommodation) {
    calenderOnlyStart = true;
  }
  //var ratio = window.devicePixelRatio || 1;
  let w = window.screen.availWidth;
  let h = window.screen.availHeight;
  //console.log(duration);
  return (
    <>
      <div
        style={{ backgroundColor: "white", borderRadius: 10, maxHeight: 45 }}
        className="row border border-info m-0"
        //data-bs-toggle="modal"
        //data-bs-target="#searchModal"
        onClick={() => {
          dispatch(setViewBeforeSearch(view));

          dispatch(setView(views.searchview));
        }}
      >
        {holidayType.accommodation && (
          <div
            className="col-6 col-sm-8"
            style={{ maxHeight: 30, overflow: "hidden" }}
          >
            <small>
              {selectedDestination.length > 0
                ? selectedDestination[0].Name
                : ""}
            </small>
          </div>
        )}

        {!holidayType.accommodation && (
          <div
            className="col-6 col-sm-8"
            style={{ maxHeight: 30, overflow: "hidden" }}
          >
            <small>
              {stations.map((e) => (e.value ? e.value : "")).join(" - ")}
            </small>
          </div>
        )}

        <div
          className="col-6 col-sm-4 text-end"
          style={{ maxHeight: 30, overflow: "hidden" }}
        >
          <small>
            {calenderOnlyStart && (
              <>
                <FormattedDate
                  value={dateFrom}
                  month="2-digit"
                  day="2-digit"
                  year="numeric"
                />
              </>
            )}
            {!calenderOnlyStart && (
              <>
                <FormattedDate value={dateFrom} month="2-digit" day="2-digit" />
                -{" "}
                <FormattedDate
                  value={dateUntil}
                  year="numeric"
                  month="2-digit"
                  day="2-digit"
                />
              </>
            )}
          </small>
        </div>
      </div>
    </>
  );
}

export default SearchInfoView;
