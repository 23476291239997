import React from "react";
import InfoPopupHover from "../components/InfoPopupHover";
import {
  FaChevronCircleLeft,
  FaChevronCircleRight,
  FaInfoCircle,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import axios from "axios";
import { getHost } from "amadeusredux/lib/utils";

export default function MobileOfferImage(props) {
  const { offer, hotel, handleClick } = props;

  const [pictIdx, setPictIdx] = React.useState(0);
  const [galleryUrls, setGalleryUrls] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(
        getHost() +
          "/giatagallery/" +
          encodeURIComponent(hotel?.HotelCodes?.HotelGiataID)
      )
      .then((response) => {
        //console.log(response.data.result.data);

        let catidx = -1;
        let piccnt = 0;

        if (
          Array.isArray(response?.data?.result?.data) &&
          response?.data?.result?.data.length > 0
        ) {
          for (let i = 0; i < response.data.result.data.length; i++) {
            //console.log(response.data.result.data[0]);
            if (Array.isArray(response.data.result.data[i].Bildfile)) {
              if (response.data.result.data[i].Bildfile.length > piccnt) {
                piccnt = response.data.result.data[i].Bildfile.length;
                catidx = i;
              }
            }
          }
        }

        if (catidx > -1) {
          setGalleryUrls(
            response.data.result.data[0].Bildfile.map((e) => e["_"])
          );
        }
      });
  }, [hotel]);

  let imgCount = galleryUrls.length + 1;

  let imgurl =
    pictIdx === 0 ? hotel.HotelImages.LargeImage : galleryUrls[pictIdx - 1];
  return (
    <div className="col-auto">
      <div
        style={{
          float: "left",
          position: "absolute",
          left: "0px",
          top: "20px",
          zIndex: 100,
          padding: "5px",
          color: "#FFFFFF",
          minWidth: "100%",
          minHeight: "200px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{ flexGrow: 1, cursor: "pointer" }}
          onClick={() => handleClick()}
        ></div>
        <div style={{ display: "flex" }}>
          <div>
            {pictIdx > 0 && (
              <span onClick={() => setPictIdx(pictIdx - 1)}>
                &nbsp;
                <FaChevronCircleLeft
                  size={40}
                  style={{ cursor: "pointer", color: "rgba(255,255,255,0.75)" }}
                />
              </span>
            )}
          </div>

          <div
            style={{ flexGrow: 1, cursor: "pointer" }}
            onClick={() => handleClick()}
          ></div>
          <div>
            {pictIdx < imgCount - 1 && (
              <span onClick={() => setPictIdx(pictIdx + 1)}>
                <FaChevronCircleRight
                  size={40}
                  style={{ cursor: "pointer", color: "rgba(255,255,255,0.75)" }}
                />
                &nbsp;
              </span>
            )}
          </div>
        </div>
        <div
          style={{ flexGrow: 1, cursor: "pointer" }}
          onClick={() => handleClick()}
        ></div>
      </div>
      <img
        style={{
          borderRadius: "0px 0px 0px 0px",
          width: "100%",
          height: "200px",
          objectFit: "cover",
        }}
        src={imgurl}
        className="card-img-left"
        alt={hotel.Name}
      />
    </div>
  );
}
