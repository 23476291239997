import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import axios from "axios";
import { OFFERSATFIRSTREQUEST, OFFERSPERREQUEST } from "./config";
import {
  boardTypes,
  getHost,
  offerSortOptions,
  populatePost,
  views,
} from "./utils";
import { fetchRegionGroups } from "./propertiesSlice";

/*export const fetchRegionGroups = createAsyncThunk(
  "properties/fetchRegionGroups",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(
        getHost() + "/inventoryDataForPackage?parameter=TouristicRegions"
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);*/

axios.defaults.headers.common["AgencyID"] = window.configData.AGENCYID;
axios.defaults.headers.common["Version"] = process.env.REACT_APP_VERSION;
axios.defaults.headers.common["App"] = window.configData.APP;
export const searchGeo = createAsyncThunk(
  "offers/searchGeo",
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    try {
      const response = await axios.post(
        getHost() + "/bestPackageOfferForHotel",
        {
          ...populatePost(
            {},
            { ...payload.searchOptions, promosession: state.system.sessionId },
            payload.departureStations,
            payload.northeast,
            payload.southwest
          ),
          mode: state.system.mode,
          couponcode: state.search.couponcode,
        }
      );
      if (response.data.Offers.OfferCount > 0) {
        response.data.Offers.Offer = response.data.Offers.Offer.map((e) => {
          return {
            ...e,
            adults: state.search.adults.value,
            children: state.search.children.value,
            duration: state.search.duration.value,
            displaydestination: [...state.search.selectedDestination],
            destination: [...state.search.selectedDestination],
          };
        });
        for (let i = 0; i < response.data.Offers.Offer.length; i++) {
          let offer = response.data.Offers.Offer[i];
          if (offer?.OfferServices?.Package?.Accommodation?.BoardType) {
            let bt = boardTypes.find((e) =>
              e.searchKeys.includes(
                offer.OfferServices.Package.Accommodation.BoardType
              )
            );
            if (bt) {
              offer.OfferServices.Package.Accommodation.BoardType = bt.value;
            }
          }
        }
      }

      if (Array.isArray(response.data.Offers.Offer)) {
        if (
          state.search.durationkind &&
          state.search.durationkind.value === "Stay"
        ) {
          let dur = state.search.duration.value;

          if (dur === "0.1") {
            let differenceInTime =
              new Date(
                state.search.travelDate.to.split('"').join("")
              ).getTime() -
              new Date(
                state.search.travelDate.from.split('"').join("")
              ).getTime();

            dur = Math.floor(differenceInTime / (1000 * 3600 * 24)) + "";
            if (state.search.durationkind.value === "Stay")
              dur = Math.floor(differenceInTime / (1000 * 3600 * 24)) + "";
          }
          if ((dur + "").indexOf("-") > -1) {
            let durmin = dur.split("-")[0] * 1;
            let durmax = dur.split("-")[1] * 1;
            response.data.Offers.Offer = response.data.Offers.Offer.filter(
              (e) =>
                e.TravelDateInfo &&
                e.TravelDateInfo.OvernightStays >= durmin &&
                e.TravelDateInfo.OvernightStays <= durmax
            );
          } else {
            dur = dur * 1;
            response.data.Offers.Offer = response.data.Offers.Offer.filter(
              (e) => e.TravelDateInfo && e.TravelDateInfo.OvernightStays === dur
            );
          }
        }

        //response.data.Offers.OfferCount = response.data.Offers.Offer.length;
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const searchOffers = createAsyncThunk(
  "offers/searchOffers",
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    //console.log(JSON.stringify(state.search));
    let url = !window.tileView
      ? "/bestPackageOfferForHotel"
      : "/bestPackageOfferForRegion";
    if (
      state.search.holidayType.accommodation &&
      !state.search.holidayType.flight
    ) {
      url = !window.tileView
        ? "/bestAccommodationOfferForHotel"
        : "/bestAccommodationOfferForRegion";
    }

    if (state.system.saveSearch && window.setCookie) {
      window.setCookie(
        "lastsearch",
        JSON.stringify({
          names: state.search.selectedDestination.map((e) => e.label),
          request: populatePost(
            state.search.selectedDestination,
            {
              ...state.search,
              promosession: state.system.sessionId,
              offset: payload.offset,
              amount:
                payload.offset > 0 ? OFFERSPERREQUEST : OFFERSATFIRSTREQUEST,
            },
            state.properties.departureStations
          ),
        }),
        {
          expires: new Date(2099, 1, 1),
          path: "/",
        }
      );
    }

    try {
      const response = await axios.post(getHost() + url, {
        ...populatePost(
          state.search.selectedDestination,
          {
            ...state.search,

            promosession: state.system.sessionId,
            offset: payload.offset,
            amount:
              payload.offset > 0 ? OFFERSPERREQUEST : OFFERSATFIRSTREQUEST,
          },
          state.properties.departureStations
        ),
        mode: state.system.mode,
        couponcode: state.search.couponcode,
      });

      if (response.data.Offers.OfferCount > 0) {
        response.data.Offers.Offer = response.data.Offers.Offer.map((e) => {
          return {
            ...e,
            adults: state.search.adults.value,
            children: state.search.children.value,
            duration: state.search.duration.label,
            stations: state.search.stations,
            travelDate: state.search.travelDate.from,
            destination: [...state.search.selectedDestination],
            displaydestination: [...state.search.selectedDestination],
          };
        });
        response.data.Offers.Offer = response.data.Offers.Offer.map((e) => {
          return !e.OfferServices.Package
            ? {
                ...e,
                OfferServices: {
                  Package: { Accommodation: e.OfferServices.Accommodation },
                },
              }
            : e;
        });
        for (let i = 0; i < response.data.Offers.Offer.length; i++) {
          let offer = response.data.Offers.Offer[i];
          if (offer?.OfferServices?.Package?.Accommodation?.BoardType) {
            let bt = boardTypes.find((e) =>
              e.searchKeys.includes(
                offer.OfferServices.Package.Accommodation.BoardType
              )
            );
            if (bt) {
              offer.OfferServices.Package.Accommodation.BoardType = bt.value;
            }
          }
        }
      }

      if (Array.isArray(response.data.Offers.Offer)) {
        if (
          state.search.durationkind &&
          state.search.durationkind.value === "Stay"
        ) {
          let dur = state.search.duration.value;

          if (dur === "0.1") {
            let differenceInTime =
              new Date(
                state.search.travelDate.to.split('"').join("")
              ).getTime() -
              new Date(
                state.search.travelDate.from.split('"').join("")
              ).getTime();

            dur = Math.floor(differenceInTime / (1000 * 3600 * 24)) + "";
            if (state.search.durationkind.value === "Stay")
              dur = Math.floor(differenceInTime / (1000 * 3600 * 24)) + "";
          }

          if ((dur + "").indexOf("-") > -1) {
            let durmin = dur.split("-")[0] * 1;
            let durmax = dur.split("-")[1] * 1;
            response.data.Offers.Offer = response.data.Offers.Offer.filter(
              (e) =>
                e.TravelDateInfo &&
                e.TravelDateInfo.OvernightStays >= durmin &&
                e.TravelDateInfo.OvernightStays <= durmax
            );
          } else {
            dur = dur * 1;
            response.data.Offers.Offer = response.data.Offers.Offer.filter(
              (e) => e.TravelDateInfo && e.TravelDateInfo.OvernightStays === dur
            );
          }
        }

        //response.data.Offers.OfferCount = response.data.Offers.Offer.length;
      }
      //console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const queryOrder = createAsyncThunk(
  "offers/queryOrder",
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    try {
      const response = await axios.post(getHost() + "/getqueryorder", {
        queryid: state.search.queryid,
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const queryOrderPlus = createAsyncThunk(
  "offers/queryOrderPlus",
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();

    try {
      let hotels = [];

      for (
        let i = 0;
        i < state.offers.offerInfos.HotelDictionary.Hotel.length;
        i++
      ) {
        let hotel = state.offers.offerInfos.HotelDictionary.Hotel[i];
        let toh = state.filter.touroperatorsForHotel.filter(
          (e) => e.HotelIffCode === hotel.HotelCodes.HotelIffCode
        );

        if (toh && Array.isArray(toh) && toh.length > 0)
          hotels.push({
            HotelGiataID: hotel.HotelCodes.HotelGiataID,
            TourOperatorCodes: toh[0].TourOperatorCodes,
          });
      }

      const response = await axios.post(getHost() + "/getqueryorderplus", {
        queryid: state.search.queryid,
        hotels: hotels,
        couponcode: state.search.couponcode,
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const initialState = {
  offerInfos: {},
  offersSearched: false,
  queryorder: [],
  offerstopper: [],
  specialAmount: [],
  specialRoomTypes: [],
  responseInfo: {},
  memo: [],
  offerPage: 1,
  selectedOfferId: "",
  offerSort: offerSortOptions[0],
};

export const offersSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    setOfferInfos: (state, action) => {
      state.offerInfos = action.payload;
    },
    setOffersSearched: (state, action) => {
      state.offersSearched = action.payload;
    },
    setOfferPage: (state, action) => {
      state.offerPage = action.payload;
    },
    setOfferSort: (state, action) => {
      state.offerSort = action.payload;
    },
    setSpecialRoomTypes: (state, action) => {
      state.specialRoomTypes = action.payload;
    },

    setSelectedOfferId: (state, action) => {
      state.selectedOfferId = action.payload;
    },

    setMemo: (state, action) => {
      state.memo = action.payload;
    },

    setMarked: (state, action) => {
      let infos = { ...state.offerInfos };

      for (let i = 0; i < infos.Offers.Offer.length; i++) {
        //console.log(infos.Offers.Offer[i]);
        if (infos.Offers.Offer[i].OfferID === action.payload.offerId) {
          // console.log(infos.Offers.Offer[i].OfferID);
          if (
            Array.isArray(infos.Offers.Offer[i].MarketingActions) &&
            infos.Offers.Offer[i].MarketingActions.length > 0
          ) {
            infos.Offers.Offer[i].MarketingActions[0].marked =
              action.payload.marked;
          } else {
            infos.Offers.Offer[i].MarketingActions = [
              {
                marked: action.payload.marked,
              },
            ];
          }
        }
      }

      state.offerInfos = { ...infos };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchGeo.pending, (state) => {
      //state.offerInfos = {};
    });
    builder.addCase(searchGeo.fulfilled, (state, { payload }) => {
      if (payload.Offers.OfferCount > 0) {
        state.offerInfos = payload;
      } else {
        state.offerInfos = {};
      }
    });
    builder.addCase(searchGeo.rejected, (state) => {
      state.offerInfos = {};
    });

    builder.addCase(queryOrderPlus.pending, (state) => {
      //state.offerInfos = {};
    });

    builder.addCase(queryOrderPlus.fulfilled, (state, { payload }) => {
      let infos = { ...state.offerInfos };

      /*infos.Offers.Offer = infos.Offers.Offer.map((off) => {
        let hotel = infos.HotelDictionary.Hotel.find(
          (e) =>
            e.HotelID ===
            off?.OfferServices?.Package?.Accommodation?.HotelRef?.HotelID
        );
        return { ...off, giataid: hotel.HotelCodes.HotelGiataID };
      });*/

      //console.log(1);
      let offers = [];

      for (let i = 0; i < payload.data.length; i++) {
        let dat = payload.data[i];

        let off = infos.Offers.Offer.find((e) => e.OfferID === dat.offerid);

        if (!off) continue;
        if (!off.OfferID) {
          continue;
        }

        off.topseller = dat.topseller ? true : false;
        off.topsellertext = dat.topseller ? dat.topsellertext : "";
        off.topsellerlogo = dat.topseller ? dat.topsellerlogo : "";
        off.realprice = dat.realprice;
        off.checked = dat.checked;
        off.available = dat.available;
        off.searchtext = dat.searchtext;
        let ma = off.MarketingActions;
        if (
          Array.isArray(ma) &&
          ma.length === 1 &&
          Array.isArray(dat.MarketingProperties) &&
          dat.MarketingProperties.length > 0
        ) {
          let nma = [];
          for (let j = 0; j < dat.MarketingProperties.length; j++) {
            nma.push({
              ...ma[0],
              typ_id: dat.MarketingProperties[j].typ_id,
              onpage: dat.MarketingProperties[j].onpage,
              MarketingProperties: dat.MarketingProperties[j],
            });
          }
          off.MarketingActions = nma;
        }
        offers.push(off);
      }

      infos.Offers.Offer = offers;

      state.offerInfos = infos;
      state.queryorder = payload.data;
      state.responseInfo = payload.info;
      state.offerstopper = payload.stopper;
      //console.log(2);
    });

    builder.addCase(queryOrderPlus.rejected, (state) => {
      state.queryorder = [];
    });
    builder.addCase(queryOrder.pending, (state) => {
      //state.offerInfos = {};
    });
    builder.addCase(queryOrder.fulfilled, (state, { payload }) => {
      let infos = { ...state.offerInfos };
      for (let i = 0; i < payload.data.length; i++) {
        if (payload.data[i].topseller) {
          infos.Offers.Offer = infos.Offers.Offer.map((e) =>
            e.OfferID === payload.data[i].offerid
              ? {
                  ...e,
                  topseller: true,
                  topsellertext: payload.data[i].topsellertext,
                  topsellerlogo: payload.data[i].topsellerlogo,
                  realprice: payload.data[i].realprice,
                  checked: payload.data[i].checked,
                  available: payload.data[i].available,
                }
              : e
          );
        }
      }
      for (let i = 0; i < payload.data.length; i++) {
        if (!payload.data[i].topseller) {
          infos.Offers.Offer = infos.Offers.Offer.map((e) =>
            e.OfferID === payload.data[i].offerid
              ? {
                  ...e,
                  topseller: false,
                  topsellertext: "",
                  topsellerlogo: "",
                  realprice: payload.data[i].realprice,
                  checked: payload.data[i].checked,
                  available: payload.data[i].available,
                }
              : e
          );
        }
      }
      /*if (Array.isArray(infos?.Offers?.Offer))
        infos.Offers.Offer = infos.Offers.Offer.map((e) =>
          !e.topseller
            ? {
                ...e,
                topseller: false,
                topsellertext: "",
               
              }
            : e
        );*/

      state.offerInfos = infos;
      state.queryorder = payload.data;
      state.responseInfo = payload.info;
    });
    builder.addCase(queryOrder.rejected, (state) => {
      state.queryorder = [];
    });
    builder.addCase(searchOffers.pending, (state) => {
      //state.offerInfos = {};
    });
    builder.addCase(searchOffers.fulfilled, (state, { payload, meta }) => {
      state.offersSearched = true;
      if (meta.arg.offset === 0) {
        state.queryorder = [];
        if (payload.Offers.OfferCount > 0) {
          state.offerInfos = payload;
        } else {
          state.offerInfos = {};
        }
      } else {
        if (payload.Offers.OfferCount > 0) {
          let infos = { ...state.offerInfos };

          infos.Offers.OfferCount += payload.Offers.OfferCount;
          infos.Offers.Offer = [...infos.Offers.Offer, ...payload.Offers.Offer];
          if (infos.StationDictionary?.Station)
            infos.StationDictionary.Station = [
              ...infos.StationDictionary.Station,
              ...payload.StationDictionary.Station,
            ];

          infos.HotelDictionary.Hotel = [
            ...infos.HotelDictionary.Hotel,
            ...payload.HotelDictionary.Hotel,
          ];
          infos.GeoDictionary.Cities.City = [
            ...infos.GeoDictionary.Cities.City,
            ...payload.GeoDictionary.Cities.City,
          ];

          infos.GeoDictionary.Continents.Continent = [
            ...infos.GeoDictionary.Continents.Continent,
            ...payload.GeoDictionary.Continents.Continent,
          ];
          infos.GeoDictionary.Countries.Country = [
            ...infos.GeoDictionary.Countries.Country,
            ...payload.GeoDictionary.Countries.Country,
          ];
          infos.GeoDictionary.Provinces.Province = [
            ...infos.GeoDictionary.Provinces.Province,
            ...payload.GeoDictionary.Provinces.Province,
          ];

          infos.GeoDictionary.RegionGroups.RegionGroup = [
            ...infos.GeoDictionary.RegionGroups.RegionGroup,
            ...payload.GeoDictionary.RegionGroups.RegionGroup,
          ];
          infos.GeoDictionary.Regions.Region = [
            ...infos.GeoDictionary.Regions.Region,
            ...payload.GeoDictionary.Regions.Region,
          ];

          state.offerInfos = infos;
        }
      }
      let infos = { ...state.offerInfos };
      let roomtypes = [...state.specialRoomTypes];
      let spaArray = [];
      if (!payload.Offers.MoreOffersAvailable && infos?.Offers) {
        //console.log(infos.Offers.Offer);
        for (let i = 0; i < infos.Offers.Offer.length; i++) {
          if (
            Array.isArray(infos.Offers.Offer[i].MarketingActions) &&
            infos.Offers.Offer[i].MarketingActions.length > 0 &&
            infos.Offers.Offer[i].MarketingActions[0].roommouseovers
          ) {
            let roomnames =
              infos.Offers.Offer[i].MarketingActions[0].roommouseovers;
            for (let j = 0; j < roomtypes.length; j++) {
              if (roomnames.indexOf(roomtypes[j]) > -1) {
                let spa = spaArray.find((e) => {
                  return e.value === roomtypes[j];
                });
                if (spa) {
                  spa.amount = spa.amount + 1;
                } else {
                  spaArray.push({ value: roomtypes[j], amount: 1 });
                }
              }
            }
          }
        }
      }
      state.specialAmount = spaArray;
    });
    builder.addCase(searchOffers.rejected, (state) => {
      state.offerInfos = {};
    });
  },
});
export const {
  setOfferInfos,
  setOfferPage,
  setOfferSort,
  setMarked,
  setMemo,
  setSelectedOfferId,
  setSpecialRoomTypes,
  setOffersSearched
} = offersSlice.actions;

export default offersSlice.reducer;
