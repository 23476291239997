import React from "react";
import { hotelAttributes } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { MdCheck } from "react-icons/md";

import { FaInfoCircle } from "react-icons/fa";
import InfoPopupHover from "../components/InfoPopupHover";
import { getFilters } from "amadeusredux/lib/api";
import { setSearchOption } from "amadeusredux/lib/searchSlice";

export default function HotelAttributeFilter(props) {
  const { changeFilterAndSearch, mostwanted, changeClientFilter } = props;
  const filteredItems = useSelector((state) => state.filter.hotelAttributes);
  const hotelAttribute = useSelector((state) => state.search.hotelAttribute);
  const [showAll, setShowAll] = React.useState(false);
  //console.log(filteredItems);
  const specialRoomType = useSelector((state) => state.search.specialRoomType);
  const specialRoomTypes = useSelector(
    (state) => state.offers.specialRoomTypes
  );
  const dispatch = useDispatch();
  const specialAmount = useSelector((state) => state.offers.specialAmount);
  const debug = useSelector((state) => state.system.debug);
  const infos = useSelector((state) => state.properties.infos);

  let attributes = hotelAttributes.sort((a, b) =>
    a.order !== b.order ? a.order - b.order : a.group - b.group
  );

  if (mostwanted) {
    attributes = attributes.filter((e) => e.mostwanted === true);
    attributes = [...attributes];
    attributes = attributes.map((e) => {
      return { ...e, group: "Meistgesucht" };
    });
  } 
  let lastgrp = "";
  //const loading = useSelector((state) => state.system.loading);
  //console.log(attributes);

  let ct = 0;
  return (
    <div>
      {/*<span className="hotelattributefilter1" style={{ color: "#a0a0a0", fontSize: "0.7em" }}>Hotelmerkmale</span>*/}
      {attributes.map((itm, idx) => {
        let disabled = false;
        let groupdisplay = false;
        if (lastgrp !== itm.group) {
          groupdisplay = true;
        }
        lastgrp = itm.group;
        let f = filteredItems.find((a) => a.Label === itm.value);
        if (f) {
          if (f.HotelCount === 0) {
            disabled = true;
            //return <></>;
          }
        } else {
          disabled = true;
          f = { HotelCount: 0 };
        }
        if (hotelAttribute.includes(itm.value)) {
          disabled = false;
        }

        if (
          !mostwanted &&
          !itm.hide &&
          (f.HotelCount > 0 || hotelAttribute.includes(itm.value))
        )
          ct = ct + 1;

        if (ct > 5 && !showAll) return null;

        return (
          <React.Fragment key={idx}>
            {!itm.hide &&
              (f.HotelCount > 0 || hotelAttribute.includes(itm.value)) && (
                <>
                  {groupdisplay && idx > 0 && <br />}
                  {groupdisplay && (
                    <>
                      <div className="fw-semibold">{itm.group}</div>
                    </>
                  )}
                  <div key={itm.value}>
                    <div style={{ display: "flex" }}  className="mb-2">
                      &nbsp;
                      <input
                        checked={hotelAttribute.includes(itm.value)}
                        onChange={() => {
                          let changed = [...hotelAttribute];
                          if (changed.includes(itm.value)) {
                            changed = changed.filter((e) => e !== itm.value);
                          } else {
                            changed.push(itm.value);
                          }

                          dispatch(
                            setSearchOption({
                              property: "hotelAttribute",
                              data: changed,
                            })
                          );

                          getFilters(dispatch, false);
                        }}
                        disabled={disabled}
                        type="checkbox"
                      />
                      &nbsp;
                      <div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: "" + itm.label + " (" + f.HotelCount + ")",
                          }}
                        ></div>
                        {debug && (
                          <>
                            {hotelAttributes.find(
                              (a) => itm.value === a.value
                            ) ? (
                              hotelAttributes.find((a) => itm.value === a.value)
                                .icon ? (
                                hotelAttributes
                                  .find((a) => itm.value === a.value)
                                  .icon()
                              ) : (
                                <MdCheck style={{ verticalAlign: "bottom" }} />
                              )
                            ) : (
                              <MdCheck style={{ verticalAlign: "bottom" }} />
                            )}
                          </>
                        )}
                        {debug && <>({itm.value})</>}
                      </div>
                    </div>
                  </div>
                </>
              )}
          </React.Fragment>
        );
      })}

      {mostwanted && (
        <>
          {specialRoomTypes.map((srt) => {
            let amount = specialAmount.find((e) => srt === e.value);
            return (
              <>
                {amount?.amount && (
                  <div>
                    <div>
                      <input
                        checked={specialRoomType.includes(srt)}
                        onChange={() => {
                          let changed = [...specialRoomType];
                          if (changed.includes(srt)) {
                            changed = changed.filter((e) => e !== srt);
                          } else {
                            changed.push(srt);
                          }
                          changeClientFilter("specialRoomType", changed);
                        }}
                        type="checkbox"
                      />
                    </div>
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: "" + srt + " (" + amount?.amount + ")",
                        }}
                      ></div>
                      <InfoPopupHover
                        trigger={
                          <span>
                            <FaInfoCircle />
                          </span>
                        }
                        content={infos.roomtypinfo}
                        position={"right center"}
                      ></InfoPopupHover>
                    </div>
                  </div>
                )}
              </>
            );
          })}
        </>
      )}

      {!mostwanted && !showAll && ct > 5 && (
        <div style={{ cursor: "pointer" }} onClick={() => setShowAll(true)}>
          alle anzeigen
        </div>
      )}
      {!mostwanted && showAll && (
        <div style={{ cursor: "pointer" }} onClick={() => setShowAll(false)}>
          weniger anzeigen
        </div>
      )}
    </div>
  );
}
