import React from "react";
import { boardTypes } from "../utils";

import { useDispatch, useSelector } from "react-redux";

import { setSearchOption } from "amadeusredux/lib/searchSlice";
import { getFilters } from "amadeusredux/lib/api";
import { views } from "amadeusredux/lib/utils";

export default function BoardTypeFilter(props) {
  const { changeFilterAndSearch } = props;
  const [showAll, setShowAll] = React.useState(false);
  const view = useSelector((state) => state.system.view);
  const loading = useSelector((state) => state.system.loading);
  const filteredItems = useSelector((state) => state.filter.boardTypes);
  const boardType = useSelector((state) => state.search.boardType);
  let bt = boardTypes.filter((e) => e.filter);
  const dispatch = useDispatch();
  let ct = 0;

  return (
    <div>
      <span className="fw-semibold">Verpflegung</span>
      {bt.map((itm, kidx) => {
        let disabled = false;
        let f = filteredItems.filter((a) => itm.searchKeys.includes(a.Value));
        let hc = 0;
        for (let i = 0; i < f.length; i++) {
          hc += f[i].HotelCount;
        }
        if (hc) {
          disabled = false;
          //return <></>;
        } else {
          disabled = true;
        }
        if (boardType.includes(itm.value)) {
          disabled = false;
        }

        if (loading > 0) disabled = true;

        if (hc > 0 || boardType.includes(itm.value) || boardType.length === 0)
          ct = ct + 1;

        if (ct > 5 && !showAll) return null;
        if (boardType.length === 0) disabled = false;
        return (
          <React.Fragment key={kidx}>
            {(hc > 0 ||
              boardType.includes(itm.value) ||
              boardType.length === 0) && (
              <div key={itm.value}>
                <div style={{ display: "flex" }}  className="mb-2">
                  &nbsp;
                  <input
                    checked={boardType.includes(itm.value)}
                    onChange={() => {
                      let changed = [...boardType];
                      if (changed.includes(itm.value)) {
                        changed = changed.filter((e) => e !== itm.value);
                      } else {
                        changed.push(itm.value);
                      }

                      dispatch(
                        setSearchOption({
                          property: "boardType",
                          data: changed,
                        })
                      );

                      getFilters(dispatch, false);
                    }}
                    disabled={disabled}
                    type="checkbox"
                  />
                  &nbsp;
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        "" +
                        itm.label +
                        " (" +
                        itm.short +
                        ")" +
                        (view === views.offerview ? " (" + hc + ")" : ""),
                    }}
                  ></div>
                </div>
              </div>
            )}
          </React.Fragment>
        );
      })}
      {!showAll && ct > 5 && (
        <div style={{ cursor: "pointer" }} onClick={() => setShowAll(true)}>
          alle anzeigen
        </div>
      )}
      {showAll && (
        <div style={{ cursor: "pointer" }} onClick={() => setShowAll(false)}>
          weniger anzeigen
        </div>
      )}
    </div>
  );
}
