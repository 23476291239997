import React from "react";

//import "./custom-popup-overlay.css";

import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Modal } from "bootstrap";
import {
  ancillaries,
  getHost,
  nationalities,
  paymentOptions,
  propertyFromArray,
  roomTypes,
} from "../utils";
import { selectSearch, setSearchOption } from "amadeusredux/lib/searchSlice";
import { MdSettingsAccessibility } from "react-icons/md";
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  FormattedNumberParts,
  useIntl,
} from "react-intl";
import { setView } from "amadeusredux/lib/systemSlice";

import { giataOperators, stars, hotelAttributes, boardTypes } from "../utils";
import { views } from "amadeusredux/lib/utils";
import SLBCheckBox from "../components/CheckBox";
import { selectSystem } from "amadeusredux/lib/systemSlice";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";

import { AiOutlineCloseCircle } from "react-icons/ai";
import { MdCheck } from "react-icons/md";

import {
  comparableOffersForOffer,
  getOtherRailOffer,
  getOtherTransferOffer,
  roomTypesForTourOperator,
  sortOffersByFlight,
} from "../offerutils";

import {
  getCatalogData,
  searchAlternativeTransports,
  setOfferId,
} from "amadeusredux/lib/hotelSlice";
//import HotelOfferVoucher from "../components/HotelOfferVoucher";
import {
  checkCouponcode,
  getPassinfo,
  sendBookedData,
  setBookedData,
  setCouponvalue,
  setPassinfo,
  setTheOffer,
} from "amadeusredux/lib/bookingSlice";
//import Hotelinfos from "../hotel/HotelInfos";
import { GiConsoleController } from "react-icons/gi";
import {
  checkAddressOrEmail,
  checkIban,
} from "amadeusredux/lib/propertiesSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import InfoPopupHover from "../components/InfoPopupHover";
import { FaInfoCircle, FaMinusCircle, FaPlusCircle } from "react-icons/fa";

import MobileOfferVoucher from "../components/MobileOfferVoucher";
import MobileOfferFlight from "../components/MobileOfferFlight";

//https://demo.vivapayments.com/web/checkout?ref={OrderCode}

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

function getAgeOnReturn(dateString, offer) {
  var returnDate = new Date(offer.TravelDateInfo.ReturnDate);
  var birthDate = new Date(dateString);
  var ageOnReturn = returnDate.getFullYear() - birthDate.getFullYear();
  var m = returnDate.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && returnDate.getDate() < birthDate.getDate())) {
    ageOnReturn--;
  }
  return ageOnReturn;
}

const MobileBookingView = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const nf = new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2 });

  const sessionId = useSelector((state) => state.system.sessionId);

  const checkids = useSelector((state) => state.hotel.checkids);

  const [selectedAncillaries, setSelectedAncillaries] = React.useState([]);
  const [popupVisumOpen, setPopupVisumOpen] = React.useState(-1);
  const [popupOpen, setPopupOpen] = React.useState(false);

  //Verify
  const [olderthan18, setOlderthan18] = React.useState("");
  const [addressCorrect, setAddressCorrect] = React.useState("");
  const [emailCorrect, setEmailCorrect] = React.useState("");
  const [ibanCorrect, setIbanCorrect] = React.useState("");
  const [insuranceWanted, setInsuranceWanted] = React.useState("");
  const couponvalue = useSelector((state) => state.booking.couponvalue);

  const couponcode = useSelector((state) => state.search.couponcode);
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [previewPicture, setPreviewPicture] = React.useState(null);
  const [previewIndex, setPreviewIndex] = React.useState(0);
  const [images, setImages] = React.useState([]);
  const [images800, setImages800] = React.useState([]);
  const [catIdx, setCatIdx] = React.useState({ value: 0 });
  const [operators, setOperators] = React.useState([]);
  const [bookingPressed, setBookingPressed] = React.useState(false);
  const [logoImage, setLogoImage] = React.useState("");
  const [heroImage, setHeroImage] = React.useState("");
  const [tourOperators, setTouroperators] = React.useState([]);
  const [catalogText, setCatalogText] = React.useState("");
  const [couponiban, setCouponiban] = React.useState("");
  const [flightVerbose, setFlightVerbose] = React.useState(false);
  const socketid = useSelector((state) => state.system.socketid);

  const [payUrl, setPayUrl] = React.useState("");

  const [showErrors, setShowErrors] = React.useState(false);
  const [selectedTAMarketingAction, setSelectedTAMarketingAction] =
    React.useState([]);

  const [selectedTOMarketingAction, setSelectedTOMarketingAction] =
    React.useState([]);

  const multiroomdivider = useSelector(
    (state) => state.search.multiroomdivider
  );
  const [incentives, setIncentives] = React.useState([]);
  const [selectedPayment, setSelectedPayment] = React.useState(-1);
  const [thumbsSwiper, setThumbsSwiper] = React.useState(null);
  const hotelData = useSelector((state) => state.hotel.hotelData);
  const catalogData = useSelector((state) => state.hotel.catalogData);
  const [hotelRegion, setHotelRegion] = React.useState({});
  const ibanresult = useSelector((state) => state.properties.ibanresult);
  const [insuranceID, setInsuranceID] = React.useState("");
  const [agbText, setAgbText] = React.useState("");

  const selectedDestination = useSelector(
    (state) => state.search.selectedDestination
  );

  React.useImperativeHandle(ref, () => ({
    setBookedData() {
      //console.log("BD", bookingData);
      dispatch(
        setBookedData({
          bookingData: bookingData,
        })
      );
    },
  }));

  const [marketingActiongroups, setMarketingActiongroups] = React.useState([]);

  let adultCount = 0;
  let childCount = 0;
  const [roomTouroperators, setRoomTouroperators] = React.useState({
    value: "",
    tos: [],
  });

  const roomdivRef = React.useRef(null);
  React.useEffect(() => {
    /*console.log(
      "width",
      roomdivRef.current ? roomdivRef.current.offsetWidth : 0
    );*/
  }, [roomdivRef.current]);

  const scrollRef = React.useRef();
  const hotelOfferInfos = useSelector((state) => state.hotel.hotelOfferInfos);
  const stations = hotelOfferInfos.StationDictionary;
  const offerId = useSelector((state) => state.hotel.offerId);
  const insuranceData = useSelector((state) => state.booking.insuranceData);
  let hotels = hotelOfferInfos.HotelDictionary;
  const offers = hotelOfferInfos.Offers?.Offer
    ? [...hotelOfferInfos.Offers?.Offer]
    : [];

  let geodata = hotelOfferInfos.GeoDictionary;

  const offer = offers.find((e) => e.OfferID === offerId)
    ? offers.find((e) => e.OfferID === offerId)
    : {};
  //console.log(offer, offers);
  /*console.log(
    hotels,
    offer?.OfferServices?.Package?.Accommodation?.HotelRef?.HotelID
  );*/

  React.useEffect(() => {
    if (couponcode && offer.OfferID)
      dispatch(checkCouponcode({ offerid: offer.OfferID }));
  }, []);

  const hotel = hotels
    ? hotels.Hotel.find(
        (e) =>
          e.HotelID ===
          offer?.OfferServices?.Package?.Accommodation?.HotelRef?.HotelID
      )
    : {};

  const intl = useIntl();
  const df = new Intl.DateTimeFormat(intl.locale, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  React.useEffect(() => {
    let images800 = [];

    if (
      catalogData &&
      catalogData[catIdx.value] &&
      Array.isArray(catalogData[catIdx.value].Bildfile)
    ) {
      let size800 = catalogData[catIdx.value].Bildfile.filter(
        (e) => e.$.size === "800"
      );
      let amount = size800.length;
      if (amount > 10) amount = 10;

      for (let i = 0; i < amount; i++) {
        images800.push({
          original: size800[i]._,
          thumbnail: size800[i]._,
          originalWidth: 640,
          originalHeight: 480,
        });
      }
    }

    setImages800([...images800]);
  }, [catalogData, catIdx]);

  React.useEffect(() => {
    let images = [];

    if (
      catalogData &&
      catalogData[catIdx.value] &&
      Array.isArray(catalogData[catIdx.value].Bildfile)
    ) {
      let size800 = catalogData[catIdx.value].Bildfile.filter(
        (e) => e.$.size === "800"
      );
      let size150 = catalogData[catIdx.value].Bildfile.filter(
        (e) => e.$.size === "150"
      );

      for (let i = 0; i < size800.length && i < size150.length; i++) {
        images.push({
          original: size800[i]._,
          thumbnail: size150[i]._,
          originalWidth: 800,
          originalHeight: 600,
        });
      }
    }

    setImages([...images]);
  }, [catalogData, catIdx]);

  React.useEffect(() => {
    dispatch(getCatalogData({}));
  }, []);

  React.useEffect(() => {
    if (offer.OfferID) {
      let tmp = [];
      let acts = offer.MarketingActions.filter((e) => e.typ_id === "0018-0002");
      if (Array.isArray(acts)) {
        for (let i = 0; i < acts.length; i++) {
          let grp = acts[i].MarketingProperties.actiongroup;
          if (!grp) grp = "_";
          if (!tmp.includes(grp)) tmp.push(grp);
        }
        setMarketingActiongroups(tmp);
      }
      let ins = [];
      acts = offer.MarketingActions.filter((e) => e.typ_id === "0018-0001");
      if (Array.isArray(acts)) {
        for (let i = 0; i < acts.length; i++) {
          if (acts[i].MarketingProperties.typ === "0022-0004") {
            ins.push({ ...acts[i] });
          }
        }
        setIncentives(ins);
      }
    }
  }, [offer]);

  React.useEffect(() => {
    if (Array.isArray(catalogData) && catalogData.length > catIdx.value) {
      if (
        catalogData[catIdx.value] &&
        Array.isArray(catalogData[catIdx.value].Textfile) &&
        catalogData[catIdx.value].Textfile.length > 0
      ) {
        axios
          .post(getHost() + "/giatadata/", {
            url: catalogData[catIdx.value].Textfile[0],
          })
          .then((response) => {
            //console.log(response);
            setCatalogText(response.data);
          });
      } else {
        setCatalogText("");
      }
      if (
        catalogData[catIdx.value] &&
        Array.isArray(catalogData[catIdx.value].AGB) &&
        catalogData[catIdx.value].AGB.length > 0
      ) {
        axios
          .post(getHost() + "/giatadata/", {
            url: catalogData[catIdx.value].AGB[0],
          })
          .then((response) => {
            //console.log(response);
            setAgbText(response.data);
          });
      } else {
        setAgbText("");
      }
    } else {
      setCatalogText("");
      setAgbText("");
    }
  }, [catalogData, catIdx]);

  React.useEffect(() => {
    if (!holidayType.accommodation) {
      setImpInf(true);
    }
    dispatch(getCatalogData({}));
  }, []);

  React.useEffect(() => {
    if (
      holidayType.accommodation &&
      hotel &&
      hotel.HotelLocationRef &&
      hotel.HotelLocationRef.RegionID &&
      hotelOfferInfos &&
      hotelOfferInfos.GeoDictionary &&
      hotelOfferInfos.GeoDictionary.Regions &&
      Array.isArray(hotelOfferInfos.GeoDictionary.Regions.Region)
    ) {
      if (
        hotelOfferInfos.GeoDictionary.Regions.Region.find(
          (e) => hotel.HotelLocationRef.RegionID === e.RegionID
        )
      ) {
        setHotelRegion({
          ...hotelOfferInfos.GeoDictionary.Regions.Region.find(
            (e) => hotel.HotelLocationRef.RegionID === e.RegionID
          ),
        });
      }
    }
  }, [hotel, hotelOfferInfos]);

  React.useEffect(() => {
    let hero = "";
    let logo = "";

    if (
      Array.isArray(catalogData) &&
      catIdx &&
      catIdx.value &&
      catalogData[catIdx.value]
    ) {
      //console.log(catIdx.value);
      let catalogRow = catalogData[catIdx.value];
      if (Array.isArray(catalogRow.Bildfile)) {
        for (let j = 0; j < catalogRow.Bildfile.length; j++) {
          let file = catalogRow.Bildfile[j];
          //console.log(file.$.size + ":" + file.$.typ);
          if (!hero && file.$.size === "800" && file.$.typ === "MOD") {
            hero = file._;
          }
          if (!logo && file.$.typ === "LG") {
            logo = file._;
          }
          if (hero && logo) {
            break;
          }
        }
      }
    }

    if (Array.isArray(catalogData)) {
      for (let i = 0; i < catalogData.length; i++) {
        let catalogRow = catalogData[i];
        if (Array.isArray(catalogRow.Bildfile)) {
          for (let j = 0; j < catalogRow.Bildfile.length; j++) {
            let file = catalogRow.Bildfile[j];
            if (!hero && file.$.size === "800" && file.$.typ === "A") {
              hero = file._;
            }
            if (!logo && file.$.typ === "LG") {
              logo = file._;
            }
            if (hero && logo) {
              break;
            }
          }
        }
        if (hero && logo) {
          break;
        }
      }
    }
    if (!hero) {
      if (Array.isArray(catalogData)) {
        for (let i = 0; i < catalogData.length; i++) {
          let catalogRow = catalogData[i];
          if (Array.isArray(catalogRow.Bildfile)) {
            for (let j = 0; j < catalogRow.Bildfile.length; j++) {
              let file = catalogRow.Bildfile[j];
              if (!hero && file.$.size === "800" && file.$.typ === "W") {
                hero = file._;
              }
              if (hero) {
                break;
              }
            }
          }
          if (hero) {
            break;
          }
        }
      }
    }
    setLogoImage(logo);
    setHeroImage(hero);
  }, [catalogData, catIdx]);

  /*
    React.useEffect(() => {
      let to = [];
      if (Array.isArray(offers)) {
        for (let i = 0; i < offers.length; i++) {
          let bFound = false;
          for (let j = 0; j < to.length; j++) {
            if (
              to[j].TourOperatorCode === offers[i].TourOperator.TourOperatorCode
            ) {
              bFound = true;
              break;
            }
          }
          if (!bFound) to.push(offers[i].TourOperator);
        }
      }
      setTouroperators([...to]);
    }, [offers]);
  
    
  */
  React.useEffect(() => {
    let opps = [];
    if (Array.isArray(catalogData)) {
      for (let i = 0; i < catalogData.length; i++) {
        let bFound = false;
        let bOperator = false;
        for (let j = 0; j < opps.length; j++) {
          if (opps[j].id === catalogData[i].VeranstalterID[0]) {
            bFound = true;
            break;
          }
        }
        let giata = giataOperators.find(
          (e) => e.vi === catalogData[i].VeranstalterID[0]
        );
        //console.log(giata.vc);

        //if (!bFound && bOperator) {
        if (!bFound && !bOperator) {
          opps.push({
            value: i,
            id: catalogData[i].VeranstalterID[0],
            amadeusid: giata ? giata.vc : "",
            label: giata ? giata.vn : catalogData[i].VeranstalterID[0],
          });
        }
      }
    }
    if (Array.isArray(opps))
      opps = opps.sort((a, b) => ("" + a.label).localeCompare(b.label));
    setCatIdx(opps.length > 0 ? opps[0] : { value: 0 });
    setOperators([...opps]);
  }, [catalogData, tourOperators]);

  React.useEffect(() => {
    if (operators && operators.length > 0) {
      setCatIdx(operators[0]);
    }

    if (offer?.TourOperator?.TourOperatorCode)
      for (let i = 0; i < operators.length; i++) {
        if (operators[i].amadeusid == offer?.TourOperator?.TourOperatorCode) {
          setCatIdx(operators[i]);
          break;
        }
      }
  }, [operators]);

  let currentyear = new Date().getFullYear();
  let creditcardyears = [];
  for (let i = 0; i < 10; i++) {
    creditcardyears.push(currentyear + i);
  }

  const [bookResult, setBookResult] = React.useState({});
  const [isBooked, setIsBooked] = React.useState(false);
  const [onlyReservation, setOnlyReservation] = React.useState(false);

  const [selfCustomer, setSelfCustomer] = React.useState(false);
  const [agb, setAgb] = React.useState(false);
  const [agbdata, setAgbdata] = React.useState(false);
  const [agbmail, setAgbmail] = React.useState(false);
  const [agbmarketing, setAgbmarketing] = React.useState(false);
  const [payment, setPayment] = React.useState("");
  const [theContent, setTheContent] = React.useState("");
  const [siteView, setsiteView] = React.useState("sidebyside");
  const [contentView, setContentView] = React.useState("1");
  const [travellerView, setTravellerView] = React.useState(0);
  const [impInf, setImpInf] = React.useState(false);
  const [paymentInfo, setPaymentInfo] = React.useState({
    cardholder: "",
    cardnumber: "",
    month: "",
    year: "",
    cvc: "",
    verified: false,
  });
  const [paymentVersInfo, setPaymentVersInfo] = React.useState({
    cardholder: "",
    cardnumber: "",
    month: "",
    year: "",
    cvc: "",
    verified: false,
  });
  const [userContact, setUserContact] = React.useState({
    email: "",
    remark: "",
  });
  const bookedData = useSelector((state) => state.booking.bookedData);
  const holidayType = useSelector((state) => state.search.holidayType);
  let hotelAttributesFiltered = [];
  if (Array.isArray(hotelAttributes))
    hotelAttributesFiltered = hotelAttributes.filter((a) => a.hide !== true);

  let ct = 0;

  if (hotel && Array.isArray(hotel.HotelAttributes)) {
    hotel.HotelAttributes.map((e, i) => {
      let fnd = hotelAttributesFiltered.find((a) => i < 10 && e === a.value);
      if (fnd) ct = ct + 1;
    });
  }
  let attributesfill = [];
  if (Array.isArray(hotel.HotelAttributes))
    for (let i = 0; i < 10 - ct; i++) {
      attributesfill.push(0);
    }

  let oldgroup = "";

  const checkBook = () => {
    if (insuranceRequest && !insuranceID) {
      setInsuranceWanted("Keine Versicherung ausgewählt");
    }
    //console.log("bd", bookingData);
    dispatch(checkAddressOrEmail({ ...bookingData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        //console.log(originalPromiseResult);
        if (
          (originalPromiseResult?.response?.addresscheck?.status &&
            originalPromiseResult.response.addresscheck.status === "INVALID") ||
          originalPromiseResult.response.emailcheck.info !==
            "OK - Valid Address"
        ) {
          if (
            originalPromiseResult?.response?.addresscheck?.status &&
            originalPromiseResult.response.addresscheck.status === "INVALID"
          )
            setAddressCorrect("Bitte geben Sie ihre korrekte Adresse ein.");
          if (
            originalPromiseResult.response.emailcheck.info !==
            "OK - Valid Address"
          )
            setEmailCorrect("Bitte geben Sie ihre korrekte EMailadresse ein.");
        } else {
          //console.log(paymentInfo);
          setAddressCorrect("");
          setEmailCorrect("");
          if (paymentInfo.iban) {
            dispatch(checkIban({ iban: paymentInfo.iban }))
              .then(unwrapResult)
              .then((originalPromiseResult) => {
                //console.log(originalPromiseResult);
                setIbanCorrect("");
                book();
              })
              .catch((e) => {
                setIbanCorrect("Bitte geben Sie ihre korrekte IBAN ein.");
              });
          } else {
            book();
          }
        }
      })
      .catch((e) => {
        //set address error
      });
  };

  const book = () => {
    let bd = { ...bookingData };
    if (holidayType.accommodation) {
      bd.SessionID = theOffer.bookinginfo.$.SessionID;
    }
    bd.OfferID = theOffer.OfferID;
    bd.remark = userContact.remark;
    delete bd.customerNationality;
    if (holidayType.accommodation) {
      bd.payment = {
        ...paymentInfo,
        type: offerData.Offer.AvailablePayments.Payment[selectedPayment].$
          .PaymentType,
      };
      bd.insurance = insuranceID
        ? insuranceData.Offers.Offer.find((e) => e.OfferID === insuranceID)
        : {};
      bd.insuranceProvider = insuranceData?.ProviderDetails?.ProviderID;

      bd.onlyReservation = onlyReservation;
    }
    bd.flexbooked = theOffer.flexbooked;
    bd.flexprice = flexprice;
    bd.multiroomdivider = multiroomdivider;

    axios
      .post(
        getHost() + "/toicao",
        bd.travellers.map((e) => {
          return { lastname: e.lastName, firstname: e.firstName };
        })
      )
      .then((response) => {
        let nbd = { ...bookingData };
        for (let i = 0; i < bd.travellers.length; i++) {
          nbd.travellers[i].firstName = response.data.response[i].firstname;
          nbd.travellers[i].lastName = response.data.response[i].lastname;
          bd.travellers[i].firstName = response.data.response[i].firstname;
          bd.travellers[i].lastName = response.data.response[i].lastname;
        }
        setBookingPressed(true);
        dispatch(
          sendBookedData({
            selectedTAMarketingAction,
            selectedTOMarketingAction: [
              ...selectedTOMarketingAction,
              ...incentives,
            ],
            payment: paymentInfo,
            paymentrequest: holidayType.accommodation ? false : true,
            socketid: socketid,
            paymentInfo:
              selectedPayment > -1
                ? offerData.Offer.AvailablePayments.Payment[selectedPayment].$
                    .PaymentType
                : "",
            userContact,
            theOffer,
            agb,
            agbdata,
            impInf,
            miete,
            provider: holidayType.accommodation ? "amadeus" : "airtuerk",
            multiroom: bd.multiroomdivider,
            ownTransfer,
            bookingData: nbd,
            couponcode: couponcode,
            couponvalue: couponvalue,
            couponiban: couponiban,
            onlyReservation,
            flexbooked: theOffer.flexbooked,
            reservation,
            reservationtext,
            insuranceRequest,
            insurance: insuranceID
              ? insuranceData.Offers.Offer.find(
                  (e) => e.OfferID === insuranceID
                )
              : {},
            insurancePayment: paymentVersInfo,
            price,
            bookingpersonprice,
            binding,
          })
        )
          .then(unwrapResult)
          .then((originalPromiseResult) => {
            //console.log(originalPromiseResult);

            let pdf = originalPromiseResult.pdf;

            if (
              document.location.href.indexOf("themewordpress") > -1 ||
              document.location.href.indexOf("localhost") > -1
            ) {
              //return;
            } else {
              if (holidayType.accommodation) {
                axios
                  .post(getHost() + "/bookShoppingCart", bd)
                  .then((response) => {
                    //console.log(response);
                    setBookResult(response.data);
                    setIsBooked(true);
                  });
              } else {
                //bookflight
              }
            }
            dispatch(
              setBookedData({
                pdf,
                selectedTAMarketingAction,
                selectedTOMarketingAction: [
                  ...selectedTOMarketingAction,
                  ...incentives,
                ],
                paymentInfo,
                userContact,
                agb,
                agbdata,
                impInf,
                miete,
                ownTransfer,
                bookingData,
                couponcode: couponcode,
                couponvalue: couponvalue,
                onlyReservation,
                flexbooked: theOffer.flexbooked,
                reservation,
                reservationtext,
                insuranceRequest,
                insurance: insuranceID
                  ? insuranceData.Offers.Offer.find(
                      (e) => e.OfferID === insuranceID
                    )
                  : {},
                price,
                bookingpersonprice,
                binding,
              })
            );
            if (!holidayType.accommodation && originalPromiseResult.pdf) {
              let myModal = new Modal(document.getElementById("payModal"), {});

              myModal.show();
              setPayUrl(
                "https://demo.vivapayments.com/web/checkout?ref=" +
                  originalPromiseResult.pdf
              );
            }
            if (holidayType.accommodation) {
              dispatch(setView(views.confirmationview));
            }
          });
        //console.log(bd);
      });
  };
  let hotelText = catalogText;
  hotelText = hotelText.substring(0, 400);
  hotelText = hotelText.substring(0, hotelText.lastIndexOf(" ") + 1) + " ...";

  const months = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];

  let reservation = false;
  let reservationtext = "";

  let transferincl = false;
  if (offer?.OfferProperties?.TransferInfos) {
    if (
      Array.isArray(offer.OfferProperties.TransferInfos) &&
      offer.OfferProperties.TransferInfos.includes("TransferIncluded")
    ) {
      transferincl = true;
    }
  }

  if (
    Array.isArray(offer?.MarketingActions) &&
    offer?.MarketingActions.length > 0 &&
    offer?.MarketingActions[0].reservation
  ) {
    reservation = offer?.MarketingActions[0].reservation;
  }

  if (
    Array.isArray(offer?.MarketingActions) &&
    offer?.MarketingActions.length > 0 &&
    offer?.MarketingActions[0].reservationtext
  ) {
    reservationtext = offer?.MarketingActions[0].reservationtext;
  }

  const [binding, setBinding] = React.useState(false);

  const theOffer = useSelector((state) => state.booking.theOffer);

  const inboundView = useSelector((state) => state.booking.inboundView);

  const offerData = useSelector((state) => state.booking.offerData);
  const booking = useSelector((state) => state.booking);
  const termsAndConditions = useSelector(
    (state) => state.booking.termsAndConditions
  );
  const passinfo = useSelector((state) => state.booking.passinfo);
  const searchOptions = useSelector(selectSearch);
  let labelwidth = "150px";

  const [popupAgbOpen, setPopupAgbOpen] = React.useState(false);

  const [operatorInfo, setOperatorInfo] = React.useState(false);
  const [miete, setMiete] = React.useState(false);
  const [ownTransfer, setOwnTransfer] = React.useState(false);
  const [insuranceRequest, setInsuranceRequest] = React.useState(null);

  //console.log("MAS", offer.MarketingActions);

  let price = 0;
  if (!holidayType.accommodation) {
    price = theOffer?.verifiedFare?.availableFareTotal?.value * 1;
  } else {
    price = offerData.Offer.PriceInformation.Prices.TotalPrice._ * 1;
  }
  let flexprice = 0;
  let flexoption = false;

  if (
    Array.isArray(offer.MarketingActions) &&
    offer.MarketingActions.length > 0 &&
    !isNaN(
      offer.MarketingActions[0].flexconditions?.flexoptions?.flexoptionprice
    ) &&
    offer.MarketingActions[0].flexconditions?.flexoptions?.flexoption
  ) {
    flexoption = true;
  }
  if (
    theOffer.flexbooked &&
    Array.isArray(offer.MarketingActions) &&
    offer.MarketingActions.length > 0 &&
    !isNaN(
      offer.MarketingActions[0].flexconditions?.flexoptions?.flexoptionprice
    ) &&
    offer.MarketingActions[0].flexconditions?.flexoptions?.flexoptionprice
  )
    flexprice =
      offer.MarketingActions[0].flexconditions?.flexoptions?.flexoptionprice *
      1;

  price = price + flexprice;

  let bookingpersonprice = price;
  if (price && offer.adults * 1 + offer.children * 1 > 1) {
    bookingpersonprice = price / (offer.adults * 1.0 + offer.children * 1.0);
    bookingpersonprice = Math.ceil(bookingpersonprice * 100.0) / 100.0;
  }

  const mandatories = {
    customer: [
      "customerFirstName",
      "customerLastName",
      "customerGender",
      "customerBirthDate",
      "customerEmail",
      //"customerPhone",
      "customerMobile",
      "customerStreet",
      "customerPostalCode",
      "customerCity",
      "customerCountryCode",
      "customerCountry",
      "customerNationality",
    ],
    traveller: ["firstName", "lastName", "gender", "birthDate", "ageQualifier"],
  };

  React.useEffect(() => {
    dispatch(setPassinfo([]));
    window.scrollTo(0, 0);
  }, []);

  let travellerarr = [];

  console.log(theOffer);
  console.log(searchOptions.childrenages);
  console.log(theOffer.adults);
  console.log(theOffer.children);

  if (
    theOffer &&
    theOffer.adults &&
    theOffer.children &&
    searchOptions.childrenages
  ) {
    let rm = 1;

    for (let i = 0; i < parseInt(theOffer.adults); i++) {
      travellerarr.push({
        room: rm,
        firstName: "",
        lastName: "",
        title: "",
        gender: "",
        birthDate: "",
        ageQualifier: "ADULT",
        countryCode: "",
        properage: "",
      });

      if ((i + 1) % ((theOffer.adults * 1) / multiroomdivider) === 0) {
        rm = rm + 1;
      }
    }

    rm = 1;
    for (let i = 0; i < parseInt(theOffer.children); i++) {
      travellerarr.push({
        room: rm,
        firstName: "",
        lastName: "",
        title: "",
        gender: "",
        birthDate: "",
        ageQualifier:
          parseInt(searchOptions.childrenages[i].value) < 2
            ? "INFANT"
            : "CHILD",
        countryCode: "",
        properage: "",
      });
      if ((i + 1) % ((theOffer.children * 1) / multiroomdivider) === 0) {
        rm = rm + 1;
      }
    }
  }

  const [bookingData, setBookingData] = React.useState({
    OfferID: "",
    SessionID: "",
    travellers: travellerarr,
    customerFirstName: "",
    customerLastName: "",
    customerTitle: "",

    customerGender: "MALE",
    customerBirthDate: "",
    customerEmail: "",
    customerPhone: "",
    customerMobile: "",

    customerStreet: "",
    customerPostalCode: "",
    customerCity: "",
    customerCountryCode: "",
    customerCountry: "",
    customerNationality: "",
  });
  //console.log(bookingData);
  const customerComplete = () => {
    let result = true;
    if (bookingData) {
      for (let i = 0; i < mandatories.customer.length; i++) {
        if (!bookingData[mandatories.customer[i]]) {
          result = false;
        }
      }
    }
    return result;
  };

  const travellerComplete = (idx) => {
    let result = true;
    if (bookingData) {
      for (let i = 0; i < mandatories.traveller.length; i++) {
        if (!bookingData.travellers[idx][mandatories.traveller[i]]) {
          result = false;
        }
      }
    }
    return result;
  };

  const birthDatesComplete = () => {
    let result = true;
    if (bookingData && Array.isArray(bookingData.travellers)) {
      for (let i = 0; i < bookingData.travellers.length; i++) {
        //console.log(bookingData.travellers[i].birthDate);
        if (!bookingData.travellers[i].birthDate) {
          result = false;
          continue;
        }
        if (bookingData.travellers[i].birthDate.length < 10) {
          result = false;
          continue;
        }

        if (bookingData.travellers[i].birthDate.length > 2) {
          if (bookingData.travellers[i].birthDate.substring(0, 2) * 1 < 19) {
            result = false;
          }
        }
      }
    } else {
      result = false;
    }

    return result;
  };

  const paymentComplete = () => {
    let result = true;
    return result;
  };

  const travellersComplete = () => {
    let result = true;
    if (bookingData) {
      for (let i = 0; i < bookingData.travellers.length; i++) {
        for (let j = 0; j < mandatories.traveller.length; j++) {
          if (!bookingData.travellers[i][mandatories.traveller[j]]) {
            result = false;
          }
        }
      }
    }
    return result;
  };

  const informationComplete = () => {
    let result = true;

    if (bookingData) {
      if (!bookingData.customerEmail) return false;
      for (let i = 0; i < mandatories.customer.length; i++) {
        if (!bookingData[mandatories.customer[i]]) {
          result = false;
        }
      }
      for (let i = 0; i < bookingData.travellers.length; i++) {
        for (let j = 0; j < mandatories.traveller.length; j++) {
          if (!bookingData.travellers[i][mandatories.traveller[j]]) {
            result = false;
          }
        }
      }
    }
    return result;
  };

  /*console.log(
    binding,
    reservation,
    onlyReservation,
    informationComplete(),
    agb,
    agbdata,
    impInf,
    paymentComplete()
  );*/

  let firstcard = true;

  let childages = true;
  let checkages = [];
  checkages = searchOptions.childrenages.map((e) => e.value * 1);
  checkages = checkages.filter((e) => e !== 0);
  let displayages = [...checkages];
  displayages = displayages.map((e) => (e === 1 ? "< 2" : e + ""));
  let inputages = Array.isArray(bookingData?.travellers)
    ? bookingData.travellers
        .filter((e) => e.ageQualifier !== "ADULT")
        .map((e) => (e.birthDate ? getAgeOnReturn(e.birthDate, theOffer) : -1))
    : [];

  for (let i = 0; i < inputages.length; i++) {
    let ag = inputages[i];
    if (ag < 2) ag = 1;
    let idx = checkages.indexOf(ag);
    if (idx > -1) checkages.splice(idx, 1);
  }
  if (checkages.length > 0 && offer.children * 1.0 > 0) {
    childages = false;
  }

  React.useEffect(() => {
    if (bookedData?.bookingData?.customerGender) {
      let bd = { ...bookedData.bookingData };
      dispatch(setBookedData({}));

      if (
        Array.isArray(bd.travellers) &&
        bd.travellers.length != travellerarr.length
      ) {
        setBookingData({ ...bd, travellers: travellerarr });
      } else {
        setBookingData({
          ...bd,
          travellers: bd.travellers.map((e) => {
            return { ...e };
          }),
        });
      }
    }
  }, []);

  console.log(travellerarr);

  return (
    <>
      <div>
        <div className="row">
          <div className="col-12 mt-2 text-center fw-semibold text-success mb-3">
            Sie haben eine gute Wahl getroffen
          </div>
          <div className="column no-gutters">
            <p
              className="card-title fw-semibold overflow-hidden mb-0 fs-5 text"
              title={hotel?.Name}
            >
              {hotel?.Name}
            </p>
            <p className="text-warning mb-0">
              {stars(hotel.HotelCategory * 1)}
            </p>
            {holidayType.accommodation && (
              <p className="mb-3">
                <small>
                  in{" "}
                  {hotel &&
                    hotel.HotelLocationRef &&
                    Array.isArray(geodata?.Cities?.City) &&
                    geodata.Cities.City.find(
                      (c) => c.CityID === hotel.HotelLocationRef.CityID
                    ) !== undefined &&
                    geodata.Cities.City.find(
                      (c) => c.CityID === hotel.HotelLocationRef.CityID
                    ).Name}{" "}
                </small>
              </p>
            )}
            {holidayType.accommodation && (
              <MobileOfferVoucher
                offer={theOffer}
                mode={"booking"}
                stations={stations}
                selectedTAMarketingAction={selectedTAMarketingAction}
                selectedTOMarketingAction={[
                  ...selectedTOMarketingAction,
                  ...incentives,
                ]}
                reservationselected={
                  onlyReservation || (!reservation && reservationtext)
                }
                flexbooked={theOffer.flexbooked}
              />
            )}

            {!holidayType.accommodation && (
              <div
                className="card p-2 mb-2"
                onClick={() => {
                  console.log(offer);
                }}
              >
                {/*offer.OfferID*/}
                {/*{offer.adults + offer.children} Personen*/}
                <div className="text-end">
                  {!flightVerbose ? (
                    <FaPlusCircle
                      onClick={() => {
                        setFlightVerbose(true);
                      }}
                    />
                  ) : (
                    <FaMinusCircle
                      onClick={() => {
                        setFlightVerbose(false);
                      }}
                    />
                  )}
                </div>
                <div>
                  <MobileOfferFlight
                    offer={theOffer}
                    verbose={flightVerbose}
                  ></MobileOfferFlight>

                  <div className="text-end h3">
                    {theOffer?.verifiedFare?.availableFareTotal?.value && (
                      <FormattedNumber
                        value={
                          theOffer?.verifiedFare?.availableFareTotal?.value * 1
                        }
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    )}
                    &nbsp;
                    {theOffer?.PriceInfo?.Price?.CurrencyCode
                      ? theOffer.PriceInfo.Price.CurrencyCode
                      : ""}{" "}
                  </div>

                  {/*<AmadeusFlight
                      flight={offer.OfferServices.Package.Flight.OutboundFlight}
                      flightSegments={
                        offer?.bookinginfo?.Offer?.Package?.Flight
                          ?.OutboundFlightSegments
                      }
                    />*/}
                </div>
              </div>
            )}

            {theOffer.flexbooked && (
              <div className="row">
                <div className="col-5 fs-6">Angebotspreis</div>
                <div className="col-7 fs-6 text-end">
                  {nf.format(
                    offerData.Offer.PriceInformation.Prices.TotalPrice._ * 1
                  )}
                  &nbsp;
                  {theOffer.PriceInfo.Price.CurrencyCode}
                </div>

                <div className="col-5 fs-6 border-bottom  border-black">
                  + Flexoption
                </div>
                <div className="col-7 fs-6 text-end border-bottom  border-black">
                  {nf.format(flexprice)}
                  &nbsp;
                  {theOffer.PriceInfo.Price.CurrencyCode}
                </div>
              </div>
            )}
            {holidayType.accommodation && (
              <div className="row">
                <div className=" col-5 fs-5 fw-semibold ">mein Preis</div>
                <div className=" col-7 fs-5 fw-semibold  text-end">
                  {nf.format(price)}
                  &nbsp;
                  {theOffer.PriceInfo.Price.CurrencyCode}
                </div>
              </div>
            )}
          </div>
          {flexoption && (
            <>
              <div className="col-1 mt-2">
                <SLBCheckBox
                  checked={theOffer.flexbooked}
                  onChange={() => {
                    dispatch(
                      setTheOffer({
                        ...theOffer,
                        flexbooked: !theOffer.flexbooked,
                      })
                    );
                  }}
                />
              </div>
              <div className="col-11 mt-3">
                Ich möchte die Flexoption buchen:
                <p className="fw-semibold">
                  {
                    offer.MarketingActions[0].flexconditions?.flexoptions
                      ?.flexoptiontext
                  }
                </p>
              </div>
            </>
          )}
        </div>
        <div className="row">
          {holidayType.accommodation && (
            <div
              className="col-12 fw-semibold text-center text-info"
              style={{ cursor: "pointer" }}
              onClick={() => setOperatorInfo(!operatorInfo)}
            >
              Veranstalterinfos{" "}
              {operatorInfo ? (
                <i class="bi bi-chevron-down"></i>
              ) : (
                <i class="bi bi-chevron-up"></i>
              )}
            </div>
          )}
          {operatorInfo && (
            <>
              <div className="col-12 mt-2 mb-4 p-2 bg-primary-subtle">
                {offerData?.Offer?.PaymentModalities?.Deposit && (
                  <div>
                    {"Anzahlung "}
                    <FormattedNumber
                      value={
                        offerData?.Offer?.PaymentModalities?.Deposit?.Amount *
                          1 +
                        flexprice
                      }
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                    />
                    {" € bis "}
                    {offerData?.Offer?.PaymentModalities?.Deposit?.DueDate && (
                      <>
                        {df.format(
                          new Date(
                            offerData?.Offer?.PaymentModalities?.Deposit?.DueDate.split(
                              "+"
                            )[0]
                          )
                        )}
                      </>
                    )}{" "}
                  </div>
                )}
                {offerData?.Offer?.PaymentModalities?.FinalPayment && (
                  <div>
                    {"Restzahlung "}
                    <FormattedNumber
                      value={
                        offerData?.Offer?.PaymentModalities?.FinalPayment
                          ?.Amount *
                          1 +
                        (offerData?.Offer?.PaymentModalities?.Deposit
                          ? 0
                          : flexprice)
                      }
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                    />

                    {" € bis "}
                    {offerData?.Offer?.PaymentModalities?.FinalPayment
                      ?.DueDate && (
                      <>
                        {df.format(
                          new Date(
                            offerData?.Offer?.PaymentModalities?.FinalPayment?.DueDate.split(
                              "+"
                            )[0]
                          )
                        )}
                      </>
                    )}
                  </div>
                )}
                <br />
                {offerData?.Offer?.InsolvencyInsurer && (
                  <>
                    <div>Informationen Insolvenzversicherung</div>
                    <div>
                      {offerData?.Offer?.InsolvencyInsurer?.CompanyName}
                    </div>
                    <div>
                      {offerData?.Offer?.InsolvencyInsurer?.Address
                        ?.StreetNumber +
                        ", " +
                        offerData?.Offer?.InsolvencyInsurer?.Address?.CityName
                          ?.$?.PostalCode +
                        " " +
                        offerData?.Offer?.InsolvencyInsurer?.Address?.CityName
                          ._}
                    </div>
                    <div>
                      Tel.:{" "}
                      {
                        offerData?.Offer?.InsolvencyInsurer?.Contacts
                          ?.Contact?.[0]?._
                      }
                      , E-Mail:{" "}
                      {
                        offerData?.Offer?.InsolvencyInsurer?.Contacts
                          ?.Contact?.[1]?._
                      }
                    </div>
                    <div>
                      Barrierefrei:{" "}
                      {offerData?.Offer?.OfferForLimitedMobility === "true"
                        ? "ja"
                        : "nein"}
                    </div>
                    <div>
                      Flugklasse (Hinflug):{" "}
                      {
                        theOffer?.OfferServices?.Package?.Flight?.InboundFlight
                          ?.CabinClass?.value
                      }
                      , Flugklasse (Rückflug):{" "}
                      {
                        theOffer?.OfferServices?.Package?.Flight?.OutboundFlight
                          ?.CabinClass?.value
                      }
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>

        <div className="row">
          <div className="col-12 fw-semibold mt-4">Gutscheincode</div>

          <div className="col-12 mt-2">
            <input
              className="form-control"
              placeholder="Gutscheincode"
              value={couponcode}
              onChange={(evt) => {
                dispatch(
                  setSearchOption({
                    property: "couponcode",
                    data: evt.target.value,
                  })
                );
                dispatch(setCouponvalue({}));
              }}
            ></input>
          </div>
          <div className="col-12 mt-2 text-center">
            <button
              className="btn btn-info"
              style={{
                cursor: "pointer",
              }}
              onClick={(e) => {
                if (couponcode && offer.OfferID)
                  dispatch(checkCouponcode({ offerid: offer.OfferID }));
              }}
            >
              Gutscheincode prüfen
            </button>
          </div>

          {couponvalue?.text && (
            <div className="col-12" style={{ display: "flex" }}>
              <div dangerouslySetInnerHTML={{ __html: couponvalue.text }}></div>
            </div>
          )}
          {couponvalue?.cashback > 0 && (
            <div className="col-12 mt-2">
              <div>
                Ihr Cashback über {couponvalue?.cashback} Euro wird unmittelbar
                nach Ihrer Abreise an Sie ausgezahlt. Dafür brauchen wir Ihre
                Bankverbindung. Bitte tragen Sie hier Ihre IBAN ein.
              </div>
            </div>
          )}
          {couponvalue?.cashback > 0 && (
            <div className="col-12 mt-2">
              <input
                className="form-control"
                placeholder="IBAN"
                value={couponiban}
                onChange={(evt) => {
                  setCouponiban(evt.target.value);
                }}
              ></input>
            </div>
          )}
        </div>

        <div className="row">
          {Array.isArray(offer.MarketingActions) &&
            offer.MarketingActions.filter((e) => e.typ_id === "0018-0001")
              .length > 0 && (
              <>
                {offer.MarketingActions.filter(
                  (e) => e.typ_id === "0018-0001"
                ).map((o, i) => {
                  return (
                    <>
                      <div className="col-1 mt-2">
                        {o.MarketingProperties.typ === "0022-0002" && (
                          <SLBCheckBox
                            checked={
                              selectedTOMarketingAction.length > 0 &&
                              selectedTOMarketingAction[0].MarketingProperties
                                .actionid === o.MarketingProperties.actionid
                            }
                            onChange={(val) => {
                              if (val) setSelectedTOMarketingAction([{ ...o }]);
                              else setSelectedTOMarketingAction([]);
                            }}
                          />
                        )}
                      </div>

                      <div className="col-11 mt-3" id={o.MarketingID}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: "" + o.MarketingProperties.actiontext,
                          }}
                        />

                        {o.MarketingProperties.description !==
                          o.MarketingProperties.actiontext && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: "" + o.MarketingProperties.description,
                            }}
                          />
                        )}
                      </div>
                    </>
                  );
                })}
              </>
            )}
          {Array.isArray(offer.MarketingActions) &&
            offer.MarketingActions.filter((e) => e.typ_id === "0018-0002")
              .length > 0 && (
              <>
                {offer.MarketingActions.filter(
                  (e) => e.typ_id === "0018-0002"
                ).map((o, i) => {
                  let changeGroup = false;
                  if (marketingActiongroups.length > 1) {
                    if (
                      oldgroup !== "" &&
                      oldgroup !== o.MarketingProperties.actiongroup
                    )
                      changeGroup = true;
                  }
                  oldgroup = o.MarketingProperties.actiongroup;
                  return (
                    <>
                      {changeGroup && <div className="col-12 mt-2"></div>}
                      <div className="col-1 mt-2">
                        <SLBCheckBox
                          checked={
                            selectedTAMarketingAction.length > 0 &&
                            selectedTAMarketingAction
                              .map((tama) => tama.MarketingProperties.actionid)
                              .includes(o.MarketingProperties.actionid)
                          }
                          onChange={(val) => {
                            let grp = o.MarketingProperties.actiongroup;
                            if (!grp) grp = "_";
                            let tmp = [];
                            for (
                              let i = 0;
                              i < selectedTAMarketingAction.length;
                              i++
                            ) {
                              if (
                                selectedTAMarketingAction[i].MarketingProperties
                                  .actiongroup !== grp
                              ) {
                                tmp.push({ ...selectedTAMarketingAction[i] });
                              }
                            }
                            if (val) {
                              tmp.push({ ...o });
                            }

                            setSelectedTAMarketingAction(tmp);
                          }}
                        />
                      </div>
                      <div className="col-11 mt-3" id={o.MarketingID}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: "" + o.MarketingProperties.description,
                          }}
                        />
                      </div>
                    </>
                  );
                })}
              </>
            )}
        </div>
        <div className="row">
          <div className="col-12 fw-semibold mt-4">Reiseteilnehmer</div>

          <div className="col-1  mt-2">
            <SLBCheckBox
              checked={selfCustomer}
              onChange={() => {
                if (!selfCustomer) {
                  let tmp = bookingData.travellers;
                  tmp[0] = {
                    firstName: bookingData.customerFirstName,
                    lastName: bookingData.customerLastName,
                    title: bookingData.customerTitle,

                    gender: bookingData.customerGender,
                    birthDate: bookingData.customerBirthDate,
                    ageQualifier: "ADULT",
                  };
                  setBookingData({ ...bookingData, travellers: tmp });
                } else {
                  let tmp = bookingData.travellers;
                  tmp[0] = {
                    firstName: "",
                    lastName: "",
                    gender: "",
                    title: "",
                    birthDate: "",
                    ageQualifier: "ADULT",
                  };
                  setBookingData({ ...bookingData, travellers: tmp });
                }
                setSelfCustomer(!selfCustomer);
              }}
            />
          </div>
          <div className="col-11 mt-3">
            Ich reise selbst.
            <br />
            <small>(Diese Angaben für erste/-n Reisende/-n übernehmen.)</small>
          </div>

          <div className="col-12  mt-2">Reiseanmelder</div>
          <div className="col-12  mt-1">
            <select
              className="form-select"
              id="testid"
              syle={{ marginLeft: "9px" }}
              value={bookingData.customerGender}
              onChange={(evt) => {
                let tmp = bookingData.travellers;
                if (selfCustomer) {
                  tmp[0] = { ...tmp[0], gender: evt.target.value };
                }
                setBookingData({
                  ...bookingData,
                  ...{
                    customerGender: evt.target.value,
                    travellers: tmp,
                  },
                });
              }}
            >
              <option value="" hidden>
                Anrede
              </option>
              <option value="MALE">Herr</option>
              <option value="FEMALE">Frau</option>
              <option value="UNKNOWN">Divers</option>
            </select>
          </div>
          <div className="col-12  mt-1">
            <select
              className="form-select"
              id="testid"
              value={bookingData.customerTitle}
              onChange={(evt) => {
                let tmp = bookingData.travellers;
                if (selfCustomer) {
                  tmp[0] = { ...tmp[0], title: evt.target.value };
                }
                setBookingData({
                  ...bookingData,
                  ...{
                    customerTitle: evt.target.value,
                    travellers: tmp,
                  },
                });
              }}
            >
              <option value="" hidden>
                Titel
              </option>
              <option value="Dr,">Dr.</option>
            </select>
          </div>
          <div className="col-12  mt-1">
            <input
              className="form-control"
              placeholder="Vorname"
              value={bookingData.customerFirstName}
              onChange={(evt) => {
                let tmp = bookingData.travellers;
                if (selfCustomer) {
                  tmp[0] = {
                    ...tmp[0],
                    firstName: evt.target.value,
                  };
                }
                setBookingData({
                  ...bookingData,
                  ...{
                    customerFirstName: evt.target.value,
                    travellers: tmp,
                  },
                });
              }}
            ></input>
          </div>
          <div className="col-12  mt-1">
            <input
              className="form-control"
              placeholder="Nachname"
              value={bookingData.customerLastName}
              onChange={(evt) => {
                let tmp = bookingData.travellers;
                if (selfCustomer) {
                  tmp[0] = {
                    ...tmp[0],
                    lastName: evt.target.value,
                  };
                }
                setBookingData({
                  ...bookingData,
                  ...{
                    customerLastName: evt.target.value,
                    travellers: tmp,
                  },
                });
              }}
            ></input>
          </div>
          <div className="col-12  mt-1">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">Geburtsdatum</span>
              </div>
              <input
                className="form-control"
                type="date"
                placeholder="Geburtsdatum"
                value={bookingData.customerBirthDate}
                onChange={(evt) => {
                  let tmp = bookingData.travellers;
                  if (selfCustomer) {
                    tmp[0] = {
                      ...tmp[0],
                      birthDate: evt.target.value,
                    };
                  }
                  setBookingData({
                    ...bookingData,
                    ...{
                      customerBirthDate: evt.target.value,
                      travellers: tmp,
                    },
                  });
                }}
                onBlur={(evt) => {
                  let tmp_olderthan18 = false;
                  try {
                    let dt = new Date(evt.target.value);
                    let age = getAge(evt.target.value);
                    //console.log(age);
                    if (age >= 18) {
                      tmp_olderthan18 = true;
                    }
                    /*if (age >= 1000) {
                      tmp_olderthan18 = "";
                    }*/
                  } catch (e) {
                    tmp_olderthan18 = false;
                  }
                  setOlderthan18(tmp_olderthan18);
                  if (tmp_olderthan18) {
                    let tmp = bookingData.travellers;
                    if (selfCustomer) {
                      tmp[0] = {
                        ...tmp[0],
                        birthDate: evt.target.value,
                      };
                    }
                    setBookingData({
                      ...bookingData,
                      ...{
                        customerBirthDate: evt.target.value,
                        travellers: tmp,
                      },
                    });
                  }
                }}
              ></input>
            </div>
            {olderthan18 === false && (
              <span className="text-danger">
                Der Reiseanmelder muss 18 Jahre alt sein.
              </span>
            )}
          </div>
          <div className="col-12  mt-1">
            <select
              className="form-select"
              placeholder={"Nationalität*"}
              id="testid"
              value={bookingData.customerNationality}
              onChange={(evt) => {
                let tmp = bookingData.travellers;
                if (selfCustomer) {
                  tmp[0] = { ...tmp[0], countryCode: evt.target.value };
                  dispatch(
                    getPassinfo({
                      nat: evt.target.value,
                      countrycode: holidayType.accommodation
                        ? hotel.HotelLocationRef.CountryCode.toLowerCase()
                        : "de",
                      pos: 0,
                    })
                  );
                }
                setBookingData({
                  ...bookingData,
                  ...{
                    customerNationality: evt.target.value,
                    travellers: tmp,
                  },
                });
              }}
            >
              <option value="" hidden>
                Nationalität
              </option>
              {nationalities.map((e) =>
                e.active === 1 ? (
                  <option value={e.code} key={e.code}>
                    {e.name_de}
                  </option>
                ) : null
              )}
            </select>
          </div>

          <div className="col-12  mt-2">
            Adresse
            {addressCorrect !== "" && (
              <>
                <br />
                {addressCorrect}
              </>
            )}
          </div>

          <div className="col-12  mt-1">
            <input
              className="form-control"
              placeholder="Strasse"
              value={bookingData.customerStreet}
              onChange={(evt) => {
                setAddressCorrect("");
                setBookingData({
                  ...bookingData,
                  ...{ customerStreet: evt.target.value },
                });
              }}
            ></input>
          </div>
          <div className="col-3  mt-1">
            <input
              className="form-control"
              placeholder="PLZ"
              value={bookingData.customerPostalCode}
              onChange={(evt) => {
                setAddressCorrect("");
                setBookingData({
                  ...bookingData,
                  ...{ customerPostalCode: evt.target.value },
                });
              }}
            ></input>
          </div>
          <div className="col-9  mt-1">
            <input
              className="form-control"
              placeholder={"Ort"}
              value={bookingData.customerCity}
              onChange={(evt) => {
                setAddressCorrect("");
                setBookingData({
                  ...bookingData,
                  ...{ customerCity: evt.target.value },
                });
              }}
            ></input>
          </div>
          <div className="col-12  mt-1">
            <select
              className="form-select"
              placeholder={"Land*"}
              id="testid"
              value={bookingData.customerCountryCode}
              onChange={(evt) => {
                setBookingData({
                  ...bookingData,
                  customerCountryCode: evt.target.value,
                  customerCountry: nationalities.find(
                    (e) => e.code === evt.target.value
                  ).name_de,
                });
              }}
            >
              <option value="" hidden>
                Land
              </option>
              {nationalities.map((e) =>
                e.active === 1 && e.sale === 1 ? (
                  <option value={e.code} key={e.code}>
                    {e.name_de}
                  </option>
                ) : null
              )}
            </select>
          </div>

          <div className="col-12  mt-2">Kontakt</div>
          <div className="col-12  mt-1">
            <input
              className="form-control"
              placeholder="Festnetz"
              value={bookingData.customerPhone}
              onChange={(evt) => {
                setBookingData({
                  ...bookingData,
                  ...{ customerPhone: evt.target.value },
                });
              }}
            ></input>
          </div>

          <div className="col-12  mt-1">
            <input
              className="form-control"
              placeHolder={"Mobil*"}
              value={bookingData.customerMobile}
              onChange={(evt) => {
                setBookingData({
                  ...bookingData,
                  ...{ customerMobile: evt.target.value },
                });
              }}
            ></input>
          </div>
          <div className="col-12  mt-1">
            <input
              className="form-control"
              placeholder="Email"
              value={bookingData.customerEmail}
              onChange={(evt) => {
                setEmailCorrect("");
                setBookingData({
                  ...bookingData,
                  ...{ customerEmail: evt.target.value },
                });
              }}
            ></input>
          </div>

          {travellerarr.map((traveller, idx) => {
            adultCount =
              adultCount + (traveller.ageQualifier === "ADULT" ? 1 : 0);
            childCount =
              childCount + (traveller.ageQualifier === "ADULT" ? 0 : 1);
            //console.log(traveller);

            return (
              <>
                {multiroomdivider > 1 && (
                  <div className="col-12 mt-2">Zimmer {traveller.room}</div>
                )}
                <div className="col-12  mt-2">
                  {traveller.ageQualifier === "ADULT" ? (
                    <>
                      {adultCount}. {"Erwachsener"}
                    </>
                  ) : (
                    <>
                      {childCount}. {"Kind"}
                    </>
                  )}
                </div>

                <div className="col-12  mt-1">
                  <select
                    className="form-select"
                    disabled={selfCustomer && idx === 0}
                    id="testid"
                    value={
                      selfCustomer && idx === 0
                        ? bookingData.customerGender
                        : bookingData.travellers[idx].gender
                    }
                    onChange={(e) => {
                      let tmp = bookingData.travellers;
                      tmp[idx].gender = e.target.value;
                      setBookingData({ ...bookingData, travellers: tmp });
                    }}
                  >
                    <option value="" hidden>
                      Anrede
                    </option>
                    <option value="MALE">Herr</option>
                    <option value="FEMALE">Frau</option>
                    <option value="UNKNOWN">Divers</option>
                  </select>
                </div>

                <div className="col-12  mt-1">
                  <select
                    className="form-select"
                    disabled={selfCustomer && idx === 0}
                    id="testid"
                    value={
                      selfCustomer && idx === 0
                        ? bookingData.customerTitle
                        : bookingData.travellers[idx].title
                    }
                    onChange={(e) => {
                      let tmp = bookingData.travellers;
                      tmp[idx].title = e.target.value;
                      setBookingData({ ...bookingData, travellers: tmp });
                    }}
                  >
                    <option value="" hidden>
                      Titel
                    </option>
                    <option value="Dr,">Dr.</option>
                  </select>
                </div>
                <div className="col-12  mt-1">
                  <input
                    className="form-control"
                    disabled={selfCustomer && idx === 0}
                    placeholder={"Vorname*"}
                    value={
                      selfCustomer && idx === 0
                        ? bookingData.customerFirstName
                        : bookingData.travellers[idx].firstName
                    }
                    onChange={(e) => {
                      let tmp = bookingData.travellers;
                      tmp[idx].firstName = e.target.value;
                      setBookingData({
                        ...bookingData,
                        travellers: tmp,
                      });
                    }}
                  ></input>
                </div>
                <div className="col-12  mt-1">
                  <input
                    className="form-control"
                    placeholder="Nachname"
                    //disabled={selfCustomer && idx === 0}

                    value={
                      selfCustomer && idx === 0
                        ? bookingData.customerLastName
                        : bookingData.travellers[idx].lastName
                    }
                    onChange={(e) => {
                      let tmp = bookingData.travellers;
                      tmp[idx].lastName = e.target.value;
                      setBookingData({
                        ...bookingData,
                        travellers: tmp,
                      });
                    }}
                  ></input>
                </div>
                <div className="col-12  mt-1">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Geburtsdatum</span>
                    </div>
                    <input
                      className="form-control"
                      disabled={selfCustomer && idx === 0}
                      type="date"
                      placeholder={"Geburtsdatum"}
                      value={
                        selfCustomer && idx === 0
                          ? bookingData.customerBirthDate
                          : bookingData.travellers[idx].birthDate
                      }
                      onChange={(e) => {
                        let tmp = bookingData.travellers;
                        tmp[idx].birthDate = e.target.value;

                        setBookingData({
                          ...bookingData,
                          travellers: tmp,
                        });
                        setBookingData({
                          ...bookingData,
                          travellers: tmp,
                        });
                      }}
                      onBlur={(e) => {
                        let tmp_properage = false;
                        try {
                          let dt = new Date(e.target.value);
                          let age = getAge(e.target.value);
                          let ageonreturn = getAgeOnReturn(
                            e.target.value,
                            theOffer
                          );
                          let ages = searchOptions.childrenages.map(
                            (e) => e.value * 1
                          );

                          //console.log(age);
                          if (age >= 18 && traveller.ageQualifier === "ADULT") {
                            tmp_properage = true;
                          }
                          if (age < 18 && traveller.ageQualifier !== "ADULT") {
                            tmp_properage = true;
                          }

                          if (
                            traveller.ageQualifier !== "ADULT" &&
                            !ages.includes(ageonreturn) &&
                            ageonreturn > 1
                          ) {
                            tmp_properage = false;
                          }
                          if (
                            traveller.ageQualifier !== "ADULT" &&
                            !ages.includes(1) &&
                            ageonreturn <= 1
                          ) {
                            tmp_properage = false;
                          }
                          if (age >= 1000) {
                            tmp_properage = "";
                          }
                        } catch (e) {
                          tmp_properage = false;
                        }

                        //console.log(tmp_properage);
                        let tmp = bookingData.travellers;
                        tmp[idx].birthDate = e.target.value;
                        tmp[idx].properage = tmp_properage;
                        setBookingData({
                          ...bookingData,
                          travellers: tmp,
                        });
                      }}
                    ></input>{" "}
                  </div>
                  {bookingData.travellers[idx].properage === false && (
                    <>
                      <span className="text-danger">
                        {bookingData.travellers[idx].ageQualifier === "ADULT"
                          ? "Ein Erwachsener muss 18 Jahre oder älter sein."
                          : "Ein Kind muss unter 18 Jahre alt sein und das Alter muss der Sucheingabe entsprechen."}
                      </span>
                    </>
                  )}
                </div>

                <div className="col-12  mt-1">
                  <select
                    className="form-select"
                    id="testid"
                    value={
                      selfCustomer && idx === 0
                        ? bookingData.customerNationality
                        : bookingData.travellers[idx].countryCode
                    }
                    disabled={selfCustomer && idx === 0}
                    onChange={(evt) => {
                      let tmp = bookingData.travellers;
                      tmp[idx].countryCode = evt.target.value;
                      setBookingData({ ...bookingData, travellers: tmp });
                      dispatch(
                        getPassinfo({
                          nat: evt.target.value,
                          countrycode:
                            hotel.HotelLocationRef.CountryCode.toLowerCase(),
                          pos: idx,
                        })
                      );
                    }}
                  >
                    <option value="" hidden>
                      Nationalität*
                    </option>
                    {nationalities.map((e) =>
                      e.active === 1 ? (
                        <option value={e.code} key={e.code}>
                          {e.name_de}
                        </option>
                      ) : null
                    )}
                  </select>
                </div>

                {passinfo.length > idx &&
                  passinfo[idx] &&
                  Array.isArray(passinfo[idx].records) &&
                  passinfo[idx].records.length > 0 && (
                    <div
                      className="col-12 fw-semibold text-center text-info"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        if (popupVisumOpen === idx) setPopupVisumOpen(-1);
                        else setPopupVisumOpen(idx);
                      }}
                    >
                      Visuminfo{" "}
                      {popupVisumOpen === idx ? (
                        <i class="bi bi-chevron-down"></i>
                      ) : (
                        <i class="bi bi-chevron-up"></i>
                      )}
                    </div>
                  )}
                {popupVisumOpen === idx && (
                  <div className="col-12 mt-2 mb-4 p-2 bg-primary-subtle">
                    {passinfo.length > idx &&
                      passinfo[idx] &&
                      Array.isArray(passinfo[idx].records) &&
                      passinfo[idx].records.length > 0 && (
                        <div>{passinfo[idx].records[0].entry.title}</div>
                      )}
                    {passinfo.length > idx &&
                      passinfo[0] &&
                      Array.isArray(passinfo[idx].records) &&
                      passinfo[idx].records.length > 0 && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: passinfo[idx].records[0].entry.content,
                          }}
                        ></div>
                      )}
                  </div>
                )}
                {selfCustomer && idx === 0 ? (
                  <div className="col-12  mt-1">
                    (Angaben vom Anmelder übernommen.)
                  </div>
                ) : (
                  <div className="col-12  mt-1">&nbsp;</div>
                )}
              </>
            );
          })}

          <div className="col-12  mt-1">
            <textarea
              className="form-control"
              placeholder={"Bemerkungen"}
              rows={5}
              value={userContact.remark ? userContact.remark : ""}
              onChange={(e) =>
                setUserContact({ ...userContact, remark: e.target.value })
              }
            ></textarea>
          </div>
        </div>
        <div className="row">
          <div className="col-12  fw-semibold mt-4">Zahlungsmodalitäten</div>
          {!holidayType.accommodation && (
            <div className="col-12 row mt-2">
              <div className="col-1">
                <SLBCheckBox disabled={true} checked={true} />
              </div>
              <div className="col-11">Kreditkarte</div>
            </div>
          )}
          {Array.isArray(offerData?.Offer?.AvailablePayments?.Payment) &&
            offerData.Offer.AvailablePayments.Payment.map((pymnt, idx) => {
              let tmpcard = firstcard;

              if (pymnt.$.PaymentType === "CreditCard") {
                firstcard = false;
                //console.log(pymnt.CreditCardInfo);
                return (
                  <div className="col-12 row mt-2">
                    {paymentOptions.find(
                      (ec) => pymnt.CreditCardInfo.$.CreditCardType === ec.id
                    ) && (
                      <>
                        <div className="col-1">
                          {tmpcard && (
                            <SLBCheckBox
                              disabled={paymentInfo.verified}
                              checked={selectedPayment === idx}
                              onChange={(e) => {
                                setSelectedPayment(idx);
                                setPaymentInfo({
                                  ...paymentInfo,
                                  cardnumber: "-",
                                  cardholder: "-",
                                  month: "12",
                                  year: "2030",
                                  cvc: "-",
                                });
                              }}
                            />
                          )}
                        </div>
                        <div className="col-11">
                          {
                            paymentOptions.find(
                              (ec) =>
                                pymnt.CreditCardInfo.$.CreditCardType === ec.id
                            ).icon
                          }
                        </div>
                      </>
                    )}
                  </div>
                );
              }
            })}

          {Array.isArray(offerData?.Offer?.AvailablePayments?.Payment) &&
            offerData.Offer.AvailablePayments.Payment.map((pymnt, idx) => {
              if (
                pymnt.$.PaymentType === "DirectDebitInternational" ||
                pymnt.$.PaymentType === "BankTransfer" ||
                pymnt.$.PaymentType === "Phone"
              ) {
                //console.log(pymnt);
                return (
                  <div className="row">
                    {paymentOptions.find((ec) =>
                      paymentOptions.find((ec) => pymnt.$.PaymentType === ec.id)
                    ) && (
                      <>
                        <div className="col-1  mt-2">
                          <SLBCheckBox
                            disabled={paymentInfo.verified}
                            checked={selectedPayment === idx}
                            onChange={(e) => {
                              setSelectedPayment(idx);
                            }}
                          />
                        </div>
                        <div className="col-11 mt-3">
                          {paymentOptions.find(
                            (ec) => pymnt.$.PaymentType === ec.id
                          ) && (
                            <>
                              {pymnt.$.PaymentType === "Phone"
                                ? "Bitte um telefonische Abstimmung"
                                : ""}
                              {pymnt.$.PaymentType ===
                              "DirectDebitInternational"
                                ? "Lastschrift "
                                : ""}
                              {pymnt.$.PaymentType === "BankTransfer"
                                ? "Rechnung "
                                : ""}
                              {
                                paymentOptions.find(
                                  (ec) => pymnt.$.PaymentType === ec.id
                                ).icon
                              }
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                );
              }
            })}
        </div>
        <div className="row">
          {Array.isArray(offerData?.Offer?.AvailablePayments?.Payment) &&
            selectedPayment > -1 &&
            offerData.Offer.AvailablePayments.Payment[selectedPayment].$
              .PaymentType === "CreditCard" && (
              <>
                <div className="col-12 mt-4 fw-semibold">Kreditkarte</div>
                <div className="col-12  ">
                  Wir werden Ihre Kreditkartendaten telefonisch abfragen.
                </div>
              </>
            )}
          {Array.isArray(offerData?.Offer?.AvailablePayments?.Payment) &&
            selectedPayment > -1 &&
            offerData.Offer.AvailablePayments.Payment[selectedPayment].$
              .PaymentType === "DirectDebitInternational" && (
              <>
                <div className="col-12 mt-4 fw-semibold">SEPA Lastschrift</div>

                {offerData?.Offer?.PaymentModalities?.Deposit && (
                  <div className="col-12">
                    {"Anzahlung "}
                    <FormattedNumber
                      value={
                        offerData?.Offer?.PaymentModalities?.Deposit?.Amount *
                          1 +
                        flexprice
                      }
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                    />
                    {" € bis "}
                    {offerData?.Offer?.PaymentModalities?.Deposit?.DueDate && (
                      <>
                        {df.format(
                          new Date(
                            offerData?.Offer?.PaymentModalities?.Deposit?.DueDate.split(
                              "+"
                            )[0]
                          )
                        )}
                      </>
                    )}{" "}
                  </div>
                )}
                {offerData?.Offer?.PaymentModalities?.FinalPayment && (
                  <div className="col-12">
                    {"Restzahlung "}
                    <FormattedNumber
                      value={
                        offerData?.Offer?.PaymentModalities?.FinalPayment
                          ?.Amount *
                          1 +
                        (offerData?.Offer?.PaymentModalities?.Deposit
                          ? 0
                          : flexprice)
                      }
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                    />

                    {" € bis "}
                    {offerData?.Offer?.PaymentModalities?.FinalPayment
                      ?.DueDate && (
                      <>
                        {df.format(
                          new Date(
                            offerData?.Offer?.PaymentModalities?.FinalPayment?.DueDate.split(
                              "+"
                            )[0]
                          )
                        )}
                      </>
                    )}
                  </div>
                )}
                <div className="col-12">
                  {paymentInfo.verified ? (
                    "Zahlungsdaten übernommen"
                  ) : (
                    <>&nbsp;</>
                  )}
                </div>

                <div className="col-12 mt-1">
                  <input
                    className="form-control"
                    placeholder={"IBAN"}
                    disabled={paymentInfo.verified}
                    value={paymentInfo.iban ? paymentInfo.iban : ""}
                    onBlur={(evt) => {
                      dispatch(checkIban({ iban: evt.target.value }))
                        .then(unwrapResult)
                        .then((originalPromiseResult) => {
                          //console.log(originalPromiseResult);
                          let bic = "";

                          if (
                            Array.isArray(
                              originalPromiseResult.response.all_bic_candidates
                            ) &&
                            originalPromiseResult.response.all_bic_candidates
                              .length > 0
                          ) {
                            bic =
                              originalPromiseResult.response
                                .all_bic_candidates[0].bic;
                          }

                          if (
                            Array.isArray(
                              originalPromiseResult.response.all_bic_candidates
                            ) &&
                            originalPromiseResult.response.all_bic_candidates
                              .length > 0
                          ) {
                            bic =
                              originalPromiseResult.response
                                .all_bic_candidates[0].bic;
                          }

                          let bank = "";
                          if (originalPromiseResult.response.bank)
                            bank = originalPromiseResult.response.bank;
                          setPaymentInfo({
                            ...paymentInfo,
                            bank: bank,
                            bic: bic,
                          });
                        })
                        .catch((e) => {
                          setIbanCorrect(
                            "Bitte geben Sie ihre korrekte IBAN ein."
                          );
                          setPaymentInfo({
                            ...paymentInfo,
                            bank: "",
                            bic: "",
                          });
                        });
                    }}
                    onChange={(e) => {
                      setIbanCorrect("");
                      setPaymentInfo({
                        ...paymentInfo,
                        iban: e.target.value,
                      });
                    }}
                  ></input>
                  {ibanCorrect !== "" && (
                    <div className="col-12 text-danger">{ibanCorrect}</div>
                  )}
                  {ibanCorrect === "" && ibanresult?.bank && (
                    <div className="col-12">{/*ibanresult.bank*/}</div>
                  )}
                </div>
                <div className="col-12 mt-1">
                  <input
                    className="form-control"
                    placeholder={"Kontoinhaber"}
                    disabled={paymentInfo.verified}
                    value={
                      paymentInfo.accountowner ? paymentInfo.accountowner : ""
                    }
                    onChange={(e) => {
                      setPaymentInfo({
                        ...paymentInfo,
                        accountowner: e.target.value,
                      });
                    }}
                  ></input>
                </div>
                <div className="col-6">{paymentInfo.bank}</div>
                <div className="col-6">{paymentInfo.bic}</div>

                <div className="col-12 mt-2">
                  <button
                    className="btn text-secondary"
                    disabled={
                      !(
                        paymentInfo.bic &&
                        paymentInfo.iban &&
                        paymentInfo.accountowner &&
                        paymentInfo.bank
                      )
                    }
                    onClick={() => {
                      setPaymentInfo({
                        ...paymentInfo,
                        verified: !paymentInfo.verified,
                      });
                    }}
                  >
                    {paymentInfo.verified ? "Ändern" : "Übernehmen"}
                  </button>
                </div>
              </>
            )}
        </div>

        {holidayType.accommodation && (
          <div className="row">
            <div className="col-12  fw-semibold mt-4">
              Zusatzleistung Reiseversicherung (optional)
            </div>
            <div className="col-1 mt-2">
              <SLBCheckBox
                checked={insuranceRequest !== null && !insuranceRequest}
                onChange={() => {
                  setInsuranceRequest(false);
                  setInsuranceID("");
                  setInsuranceWanted("");
                }}
              />
            </div>{" "}
            <div className="col-11 mt-3">
              Ich möchte keine Reiseversicherung
            </div>
            <div className="col-1 mt-2">
              <SLBCheckBox
                checked={insuranceRequest !== null && insuranceRequest}
                onChange={() => {
                  setInsuranceRequest(true);
                  setInsuranceWanted(
                    insuranceID ? "" : "Keine Versicherung ausgewählt"
                  );
                }}
              />
            </div>
            <div className="col-11 mt-3">Ich möchte eine Reiseversicherung</div>
          </div>
        )}

        {insuranceRequest && (
          <div>
            {insuranceData?.ProviderDetails?.ProviderName}

            {!birthDatesComplete() && (
              <span>
                <br />
                Bitte geben sie zuerst alle Geburtdaten der Mitreisenden ein
              </span>
            )}
            <br />
            {birthDatesComplete() &&
              Array.isArray(insuranceData?.Offers?.Offer) &&
              insuranceData?.Offers?.Offer.map((ins) => (
                <div>
                  <div>
                    <SLBCheckBox
                      checked={insuranceID === ins.OfferID}
                      onChange={() => {
                        setInsuranceID(
                          insuranceID === ins.OfferID ? "" : ins.OfferID
                        );
                        setInsuranceWanted(
                          insuranceID === ins.OfferID
                            ? "Keine Versicherung ausgewählt"
                            : ""
                        );
                      }}
                    />
                  </div>
                  <div>{ins?.Description?.Title}</div>
                  <div>
                    {birthDatesComplete() && ins?.OfferCost?._}{" "}
                    {birthDatesComplete() && ins?.OfferCost?.$?.Currency}
                  </div>

                  {Array.isArray(ins?.InsuranceFeatures.InsuranceFeature) && (
                    <div></div>
                  )}
                  {ins?.Description?.DetailURL && (
                    <div>
                      <a target="_BLANK" href={ins?.Description?.DetailURL}>
                        Beschreibung
                      </a>
                    </div>
                  )}
                  {ins?.TermsAndConditionsURL && (
                    <div>
                      <a target="_BLANK" href={ins?.TermsAndConditionsURL}>
                        Kundeninformationen und Versicherungsbedingungen
                      </a>
                    </div>
                  )}
                </div>
              ))}
            {insuranceWanted}
          </div>
        )}

        {insuranceRequest && insuranceID && (
          <>
            <div>
              <input
                className="form-control"
                placeholder={"IBAN"}
                value={paymentVersInfo.iban ? paymentVersInfo.iban : ""}
                onBlur={(evt) => {
                  dispatch(checkIban({ iban: evt.target.value }))
                    .then(unwrapResult)
                    .then((originalPromiseResult) => {
                      //console.log(originalPromiseResult);
                      let bic = "";

                      if (
                        Array.isArray(
                          originalPromiseResult.response.all_bic_candidates
                        ) &&
                        originalPromiseResult.response.all_bic_candidates
                          .length > 0
                      ) {
                        bic =
                          originalPromiseResult.response.all_bic_candidates[0]
                            .bic;
                      }

                      if (
                        Array.isArray(
                          originalPromiseResult.response.all_bic_candidates
                        ) &&
                        originalPromiseResult.response.all_bic_candidates
                          .length > 0
                      ) {
                        bic =
                          originalPromiseResult.response.all_bic_candidates[0]
                            .bic;
                      }

                      let bank = "";
                      if (originalPromiseResult.response.bank)
                        bank = originalPromiseResult.response.bank;
                      setPaymentVersInfo({
                        ...paymentVersInfo,
                        bank: bank,
                        bic: bic,
                      });
                    })
                    .catch((e) => {});
                }}
                onChange={(e) => {
                  setPaymentVersInfo({
                    ...paymentVersInfo,
                    iban: e.target.value,
                  });
                }}
              ></input>
            </div>
            <div>
              <input
                className="form-control"
                placeholder={"Kontoinhaber"}
                value={
                  paymentVersInfo.accountowner
                    ? paymentVersInfo.accountowner
                    : ""
                }
                onChange={(e) => {
                  setPaymentVersInfo({
                    ...paymentVersInfo,
                    accountowner: e.target.value,
                  });
                }}
              ></input>
            </div>

            <div>{paymentVersInfo.bank}</div>
            <div>{paymentVersInfo.bic}</div>
          </>
        )}

        <div className="row">
          {holidayType.accommodation && (
            <div className="col-12 mt-4 fw-semibold">
              Weitere Zusatzleistungen (optional)
            </div>
          )}
          {holidayType.accommodation && (
            <div className="col-1 mt-2">
              <SLBCheckBox
                checked={miete}
                onChange={() => {
                  setMiete(!miete);
                }}
              />
            </div>
          )}
          {holidayType.accommodation && (
            <div className="col-11 mt-3">
              Bitte unterbreiten Sie mir ein Mietwagenangebot
            </div>
          )}
          {holidayType.accommodation && (
            <div className="col-1 mt-2">
              <SLBCheckBox
                checked={ownTransfer}
                onChange={() => {
                  setOwnTransfer(!ownTransfer);
                }}
              />
            </div>
          )}
          {holidayType.accommodation && (
            <div className="col-11 mt-3">
              Bitte unterbreiten Sie mir ein Angebot für einen Privattransfer
              (optional)
            </div>
          )}
          {holidayType.accommodation && <div className="col-12 mt-5"></div>}
          {holidayType.accommodation && (
            <div className="col-1 mt-2">
              <SLBCheckBox
                mandatory={true}
                checked={impInf}
                onChange={() => {
                  setImpInf(!impInf);
                }}
              />
            </div>
          )}
          {holidayType.accommodation && (
            <div className="col-11 mt-3">
              Formblatt zur Unterrichtung des Reisenden bei einer Pauschalreise
              nach §651a des Bürgerlichen Gesetzbuchs:
            </div>
          )}
          {holidayType.accommodation && (
            <div className="col-12 mt-2">
              <a
                target="_blank"
                href="https://www.bmj.de/SharedDocs/Gesetzgebungsverfahren/umsetzung-richtlinie-eu2015-2302/umsetzung-richtlinie-eu2015-2302_node.html"
              >
                Weitere Informationen zu Ihren Rechten
              </a>
            </div>
          )}
          <div className="col-12 mt-4 fw-semibold">Buchungsbedingungen</div>
          <div className="col-1 mt-2">
            <SLBCheckBox
              checked={agb && agbdata && agbmail && agbmarketing}
              onChange={() => {
                if (agb && agbdata && agbmail && agbmarketing) {
                  setAgb(false);
                  setAgbdata(false);
                  setAgbmail(false);
                  setAgbmarketing(false);
                } else {
                  setAgb(true);
                  setAgbdata(true);
                  setAgbmail(true);
                  setAgbmarketing(true);
                }
              }}
            />
          </div>
          <div className="col-11 mt-3">Alle wählen/abwählen</div>
          <div className="col-1 mt-2">
            <SLBCheckBox
              mandatory={true}
              checked={agb}
              onChange={() => {
                setAgb(!agb);
              }}
            />
          </div>
          <div className="col-11 mt-3" onClick={() => setPopupAgbOpen(true)}>
            Ich habe die AGB des Veranstalters gelesen und akzeptiert
          </div>
          <div className="col-1 mt-2">
            <SLBCheckBox
              mandatory={true}
              type="checkbox"
              checked={agbdata}
              onChange={() => {
                setAgbdata(!agbdata);
              }}
            />
          </div>
          <div className="col-11 mt-3">
            Ich bin mit der Verarbeitung meiner Daten (einschließlich der
            Übermittlung in ein Drittland wie die USA) zum Zwecke der
            vertragsgemäßen Erbringung von Dienstleistungen einverstanden.
          </div>
          <div className="col-1 mt-2">
            <SLBCheckBox
              checked={agbmail}
              onChange={() => {
                setAgbmail(!agbmail);
              }}
            />
          </div>
          <div className="col-11 mt-3">
            Ich bin damit einverstanden, auf elektronischem Wege an die von mir
            angegebene E-Mail-Adresse eine kommerzielle Mitteilung im Sinne von
            Artikel 10 Absatz 1 des Gesetzes vom 18. Juli 2002 über die
            Erbringung von Dienstleistungen auf elektronischem Wege zu erhalten.
            Ich erkläre, dass ich über mein Recht auf Zugang und Berichtigung
            meiner personenbezogenen Daten und deren Löschung sowie über mein
            Recht auf jederzeitigen Widerruf informiert wurde.
          </div>
          <div className="col-1 mt-2">
            <SLBCheckBox
              checked={agbmarketing}
              onChange={() => {
                setAgbmarketing(!agbmarketing);
              }}
            />
          </div>
          <div className="col-11 mt-3">
            Ich bin mit der Verarbeitung meiner personenbezogenen Daten im Sinne
            der Verordnung des Europarates über den Schutz personenbezogener
            Daten und des Telekommunikationsgesetzes vom 16. Juli 2004 zu
            Marketingzwecken einverstanden und erkläre mich damit einverstanden,
            dass meine Eingabe von personenbezogenen Daten freiwillig erfolgt.
            Ich erkläre, dass ich über mein Recht informiert wurde, auf den
            Inhalt meiner persönlichen Daten zuzugreifen und diese zu
            korrigieren und deren Löschung zu verlangen.
          </div>
        </div>

        <div className="row mt-4">
          {reservation && (
            <>
              <div className="col-1 mt-2">
                <SLBCheckBox
                  checked={onlyReservation}
                  onChange={(value) => {
                    setOnlyReservation(!onlyReservation);
                    if (value) {
                      setBinding(false);
                    }
                  }}
                />
              </div>
              <div className="col-11 mt-3">Ich möchte {reservationtext}</div>
            </>
          )}
          <>
            <div className="col-1 mt-2">
              <SLBCheckBox
                checked={binding}
                onChange={(value) => {
                  setBinding(!binding);
                  if (value) {
                    setOnlyReservation(false);
                  }
                }}
              />
            </div>
            <div className="col-11 mt-3">
              Ich möchte verbindlich und kostenpflichtig buchen
            </div>
          </>

          <div className="col-12 mt-2">
            Hinweis: Nach der Buchung erhalten Sie von uns Ihre
            Eingangsbestätigung per Mail.
          </div>
          <div className="col-6 mt-2">
            <button
              className="btn text-primary"
              onClick={() => {
                if (!holidayType.accommodation) {
                  dispatch(setView(views.flightsview));
                } else {
                  dispatch(setView(views.hotelcrossview));
                }
                dispatch(
                  setBookedData({
                    bookingData: bookingData,
                  })
                );
              }}
            >
              zurück
            </button>
          </div>

          <div className="col-6  mt-2 text-end">
            {!(
              (!binding && !reservation) ||
              (reservation && !onlyReservation && !binding) ||
              !informationComplete() ||
              !agb ||
              !childages ||
              !agbdata ||
              !impInf ||
              !paymentComplete()
            ) && (
              <button
                className="btn btn-success"
                style={{
                  cursor:
                    !(binding && !reservation) ||
                    !(reservation && !onlyReservation && !binding) ||
                    !informationComplete() ||
                    !agb ||
                    !agbdata ||
                    !childages ||
                    !impInf ||
                    !bookingData.customerMobile ||
                    !paymentComplete()
                      ? undefined
                      : "pointer",
                }}
                disabled={
                  (!binding && !reservation) ||
                  (reservation && !onlyReservation && !binding) ||
                  !informationComplete() ||
                  !agb ||
                  !childages ||
                  !agbdata ||
                  !impInf ||
                  !paymentComplete()
                }
                onClick={() => {
                  checkBook();
                }}
              >
                {(onlyReservation ? "Reservierung" : "Buchung") + " "}{" "}
                {!bookingPressed ? "abschicken" : "buchen..."}
              </button>
            )}
            {((!binding && !reservation) ||
              (reservation && !onlyReservation && !binding) ||
              !informationComplete() ||
              !agb ||
              !childages ||
              !agbdata ||
              !impInf ||
              !paymentComplete()) && (
              <>
                <button
                  className="btn btn-success"
                  style={{
                    cursor:
                      !(!binding && !reservation) ||
                      !(reservation && !onlyReservation && !binding) ||
                      !informationComplete() ||
                      !agb ||
                      !childages ||
                      !agbdata ||
                      !impInf ||
                      !paymentComplete()
                        ? undefined
                        : "pointer",
                  }}
                  onClick={() => {
                    //checkBook();
                    setShowErrors(!showErrors);
                    //console.log("insufficient data");
                  }}
                >
                  Fehlende Daten
                  {showErrors ? (
                    <i
                      onClick={() => {
                        //checkBook();
                        setShowErrors(!showErrors);
                      }}
                      class="bi bi-chevron-down"
                    ></i>
                  ) : (
                    <i
                      onClick={() => {
                        //checkBook();
                        setShowErrors(!showErrors);
                      }}
                      class="bi bi-chevron-up"
                    ></i>
                  )}
                </button>
              </>
            )}
          </div>

          {showErrors && (
            <div className="col-12 mt-2 mb-4 p-2 bg-primary-subtle">
              {!bookingData.customerMobile && (
                <span>
                  Bitte tragen Sie Ihre Mobilnummer ein. <br />
                  <br />
                </span>
              )}
              {!agb && (
                <span>
                  Bitte bestätigen Sie unsere AGBs.
                  <br />
                  <br />
                </span>
              )}
              {!agbdata && (
                <span>
                  Bitte bestätigen Sie die Weitergabe Ihrer Daten zur
                  vertragsgemäßen Leistungserbringung.
                  <br />
                  <br />
                </span>
              )}
              {!childages && (
                <span>
                  Das Alter des Kindes / der Kinder muss bei der Rückreise{" "}
                  {displayages.toString()} Jahre sein.
                  <br />
                  <br />
                </span>
              )}
              {!impInf && (
                <span>
                  Bitte bestätigen Sie, dass Sie das Formblatt zur Unterrichtung
                  des Reisenden bei einer Pauschalreise gelesen haben.
                  <br />
                  <br />
                </span>
              )}
              {!informationComplete() && (
                <span>
                  Bitte füllen Sie die Kontaktdaten des Kunden und aller
                  Mitreisenden aus.
                  <br />
                  <br />
                </span>
              )}
              {!paymentComplete() && (
                <span>
                  Bitte tragen Sie ihre Zahlungsdaten ein.
                  <br />
                  <br />
                </span>
              )}
              {!binding && !onlyReservation && !reservation && (
                <span>
                  Bitte bestätigen Sie die verbindliche Buchung.
                  <br />
                  <br />
                </span>
              )}
              {reservation && !onlyReservation && !binding && (
                <span>
                  Bitte wählen Sie aus, ob Sie verbindlich buchen oder nur
                  reservieren möchten.
                  <br />
                  <br />
                </span>
              )}
            </div>
          )}
        </div>
      </div>

      <div class="modal" id="payModal" style={{ zIndex: 10000 }}>
        <div class="modal-dialog modal-fullscreen">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Zahlung</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div class="modal-body">
              <iframe src={payUrl} width={"100%"} height={"100%"} />
            </div>

            <div class="modal-footer"></div>
          </div>
        </div>
      </div>
    </>
  );
});

export default MobileBookingView;
