import { getFilters } from "amadeusredux/lib/api";
import { setHolidayType, setSearchOption } from "amadeusredux/lib/searchSlice";
import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { reevaluateAirlines } from "../utils";

export default function FlightFilter(props) {
  const { destinationAirlines } = props;
  const loading = useSelector((state) => state.system.loading);

  const debug = useSelector((state) => state.system.debug);
  const transferincluded = useSelector(
    (state) => state.search.transferincluded
  );
  const railandfly = useSelector((state) => state.search.railandfly);

  const holidayType = useSelector((state) => state.search.holidayType);
  const directflight = useSelector((state) => state.search.directflight);

  const nolowcost = useSelector((state) => state.search.nolowcost);
  const nofrill = useSelector((state) => state.search.nofrill);
  const fsciata = useSelector((state) => state.search.fsciata);
  const fscnego = useSelector((state) => state.search.fscnego);
  const fscconso = useSelector((state) => state.search.fscconso);
  const fsccorp = useSelector((state) => state.search.fsccorp);

  const baggageonly = useSelector((state) => state.search.baggageonly);

  const verifiedfares = useSelector((state) => state.search.verifiedfares);
  const economyclass = useSelector((state) => state.search.economyclass);
  const businessclass = useSelector((state) => state.search.businessclass);
  const firstclass = useSelector((state) => state.search.firstclass);
  const oneway = useSelector((state) => state.search.oneway);

  const linie = useSelector((state) => state.search.linie);
  const airlinerank = useSelector((state) => state.search.airlinerank);
  const dispatch = useDispatch();
  const infos = useSelector((state) => state.properties.infos);

  return (
    <div>
      <div className="fw-semibold">Flug</div>
      <div className="row">
        {/*<div className="col-12">
          <div style={{ display: "flex" }}>
            &nbsp;
            <input
              disabled={loading > 0}
              type="checkbox"
              checked={!holidayType.flight}
              onChange={() => {
                dispatch(
                  setHolidayType({
                    flight: !holidayType.flight,
                    accommodation:true,
                  })
                );

               
              }}
            />
            &nbsp;
            <div
              dangerouslySetInnerHTML={{
                __html: "nur Unterkunft",
              }}
            ></div>
          </div>
            </div>*/}

        {holidayType.flight && (
          <div className="col-6">
            <div style={{ display: "flex" }}>
              &nbsp;
              <input
                disabled={loading > 0}
                type="checkbox"
                checked={directflight}
                onChange={() => {
                  dispatch(
                    setSearchOption({
                      property: "directflight",
                      data: !directflight,
                    })
                  );

                  getFilters(dispatch, false);
                }}
              />
              &nbsp;
              <div
                dangerouslySetInnerHTML={{
                  __html: "nur Direktflüge",
                }}
              ></div>
            </div>
          </div>
        )}

        {!holidayType.accommodation && (
          <div className="col-6">
            <div style={{ display: "flex" }}>
              &nbsp;
              <input
                disabled={loading > 0}
                type="checkbox"
                checked={oneway}
                onChange={() => {
                  dispatch(
                    setSearchOption({
                      property: "oneway",
                      data: !oneway,
                    })
                  );

                  getFilters(dispatch, false);
                }}
              />
              &nbsp;
              <div
                dangerouslySetInnerHTML={{
                  __html: "Oneway",
                }}
              ></div>
            </div>
          </div>
        )}

        {!holidayType.accommodation && (
          <div className="col-6">
            <div style={{ display: "flex" }}>
              &nbsp;
              <input
                disabled={loading > 0}
                type="checkbox"
                checked={nofrill}
                onChange={() => {
                  dispatch(
                    setSearchOption({
                      property: "nofrill",
                      data: !nofrill,
                    })
                  );

                  getFilters(dispatch, false);
                }}
              />
              &nbsp;
              <div
                dangerouslySetInnerHTML={{
                  __html: "Billigflieger",
                }}
              ></div>
            </div>
          </div>
        )}

        {!holidayType.accommodation && debug && (
          <div className="col-6">
            <div style={{ display: "flex" }}>
              &nbsp;
              <input
                disabled={loading > 0}
                type="checkbox"
                checked={fsciata}
                onChange={() => {
                  dispatch(
                    setSearchOption({
                      property: "fsciata",
                      data: !fsciata,
                    })
                  );

                  getFilters(dispatch, false);
                }}
              />
              &nbsp;
              <div
                dangerouslySetInnerHTML={{
                  __html: "IATA",
                }}
              ></div>
            </div>
          </div>
        )}

        {!holidayType.accommodation && debug && (
          <div className="col-6">
            <div style={{ display: "flex" }}>
              &nbsp;
              <input
                disabled={loading > 0}
                type="checkbox"
                checked={fsccorp}
                onChange={() => {
                  dispatch(
                    setSearchOption({
                      property: "fsccorp",
                      data: !fsccorp,
                    })
                  );

                  getFilters(dispatch, false);
                }}
              />
              &nbsp;
              <div
                dangerouslySetInnerHTML={{
                  __html: "Corp",
                }}
              ></div>
            </div>
          </div>
        )}

        {!holidayType.accommodation && debug && (
          <div className="col-6">
            <div style={{ display: "flex" }}>
              &nbsp;
              <input
                disabled={loading > 0}
                type="checkbox"
                checked={fscconso}
                onChange={() => {
                  dispatch(
                    setSearchOption({
                      property: "fscconso",
                      data: !fscconso,
                    })
                  );

                  getFilters(dispatch, false);
                }}
              />
              &nbsp;
              <div
                dangerouslySetInnerHTML={{
                  __html: "Conso",
                }}
              ></div>
            </div>
          </div>
        )}

        {!holidayType.accommodation && debug && (
          <div className="col-6">
            <div style={{ display: "flex" }}>
              &nbsp;
              <input
                disabled={loading > 0}
                type="checkbox"
                checked={fscnego}
                onChange={() => {
                  dispatch(
                    setSearchOption({
                      property: "fscnego",
                      data: !fscnego,
                    })
                  );

                  getFilters(dispatch, false);
                }}
              />
              &nbsp;
              <div
                dangerouslySetInnerHTML={{
                  __html: "Nego",
                }}
              ></div>
            </div>
          </div>
        )}
        {!holidayType.accommodation && debug && (
          <div className="col-6">
            <div style={{ display: "flex" }}>
              &nbsp;
              <input
                disabled={loading > 0}
                type="checkbox"
                checked={verifiedfares}
                onChange={() => {
                  dispatch(
                    setSearchOption({
                      property: "verifiedfares",
                      data: !verifiedfares,
                    })
                  );

                  getFilters(dispatch, false);
                }}
              />
              &nbsp;
              <div
                dangerouslySetInnerHTML={{
                  __html: "verified",
                }}
              ></div>
            </div>
          </div>
        )}

        {!holidayType.accommodation && (
          <div className="col-6">
            <div style={{ display: "flex" }}>
              &nbsp;
              <input
                disabled={loading > 0}
                type="checkbox"
                checked={baggageonly}
                onChange={() => {
                  dispatch(
                    setSearchOption({
                      property: "baggageonly",
                      data: !baggageonly,
                    })
                  );

                  getFilters(dispatch, false);
                }}
              />
              &nbsp;
              <div
                dangerouslySetInnerHTML={{
                  __html: "inkl. Gepäck",
                }}
              ></div>
            </div>
          </div>
        )}

        {holidayType.flight && holidayType.accommodation && (
          <div className="col-6">
            <div style={{ display: "flex" }}>
              &nbsp;
              <input
                disabled={loading > 0}
                type="checkbox"
                checked={airlinerank}
                onChange={() => {
                  reevaluateAirlines(
                    dispatch,
                    destinationAirlines,
                    !airlinerank,
                    nolowcost,
                    linie
                  );
                }}
              />
              &nbsp;
              <div
                dangerouslySetInnerHTML={{
                  __html: "beliebteste Airlines",
                }}
              ></div>
            </div>
          </div>
        )}
        <div>&nbsp;</div>
        {holidayType.flight && holidayType.accommodation && (
          <div className="col-6">
            <div style={{ display: "flex" }}>
              &nbsp;
              <input
                disabled={loading > 0}
                type="checkbox"
                checked={nolowcost}
                onChange={() => {
                  reevaluateAirlines(
                    dispatch,
                    destinationAirlines,
                    airlinerank,
                    !nolowcost,
                    linie
                  );
                }}
              />
              &nbsp;
              <div
                dangerouslySetInnerHTML={{
                  __html: "keine LowCost",
                }}
              ></div>
            </div>
          </div>
        )}
        {holidayType.flight && holidayType.accommodation && (
          <div className="col-6">
            <div style={{ display: "flex" }}>
              &nbsp;
              <input
                disabled={loading > 0}
                type="checkbox"
                checked={linie}
                onChange={() => {
                  reevaluateAirlines(
                    dispatch,
                    destinationAirlines,
                    airlinerank,
                    nolowcost,
                    !linie
                  );
                }}
              />
              &nbsp;
              <div
                dangerouslySetInnerHTML={{
                  __html: "nur Linienflüge",
                }}
              ></div>
            </div>
          </div>
        )}
        <div className="col-12">&nbsp;</div>
        {holidayType.flight && holidayType.accommodation && (
          <div className="col-6">
            <div style={{ display: "flex" }}>
              &nbsp;
              <input
                disabled={loading > 0}
                type="checkbox"
                checked={railandfly}
                onChange={() => {
                  dispatch(
                    setSearchOption({
                      property: "railandfly",
                      data: !railandfly,
                    })
                  );

                  getFilters(dispatch, false);
                }}
              />
              &nbsp;
              <div
                dangerouslySetInnerHTML={{
                  __html: "Zug zum Flug inklusive",
                }}
              ></div>
            </div>
          </div>
        )}

        {holidayType.flight && holidayType.accommodation && (
          <div className="col-6">
            <div style={{ display: "flex" }}>
              &nbsp;
              <input
                disabled={loading > 0}
                type="checkbox"
                checked={transferincluded}
                onChange={() => {
                  dispatch(
                    setSearchOption({
                      property: "transferincluded",
                      data: !transferincluded,
                    })
                  );

                  getFilters(dispatch, false);
                }}
              />
              &nbsp;
              <div
                dangerouslySetInnerHTML={{
                  __html: "inkl. Hoteltransfer im Zielgebiet",
                }}
              ></div>
            </div>
          </div>
        )}
      </div>
      {!holidayType.accommodation && <div className="fw-semibold">Klasse</div>}
      {!holidayType.accommodation && (
        <div className="row">
          {!holidayType.accommodation && (
            <div className="col-12">
              <div style={{ display: "flex" }}>
                &nbsp;
                <input
                  disabled={loading > 0}
                  type="checkbox"
                  checked={economyclass}
                  onChange={() => {
                    dispatch(
                      setSearchOption({
                        property: "economyclass",
                        data: !economyclass,
                      })
                    );

                    getFilters(dispatch, false);
                  }}
                />
                &nbsp;
                <div
                  dangerouslySetInnerHTML={{
                    __html: "Economy",
                  }}
                ></div>
              </div>
            </div>
          )}

          {!holidayType.accommodation && (
            <div className="col-12">
              <div style={{ display: "flex" }}>
                &nbsp;
                <input
                  disabled={loading > 0}
                  type="checkbox"
                  checked={businessclass}
                  onChange={() => {
                    dispatch(
                      setSearchOption({
                        property: "businessclass",
                        data: !businessclass,
                      })
                    );

                    getFilters(dispatch, false);
                  }}
                />
                &nbsp;
                <div
                  dangerouslySetInnerHTML={{
                    __html: "Business",
                  }}
                ></div>
              </div>
            </div>
          )}
          {!holidayType.accommodation && (
            <div className="col-12">
              <div style={{ display: "flex" }}>
                &nbsp;
                <input
                  disabled={loading > 0}
                  type="checkbox"
                  checked={firstclass}
                  onChange={() => {
                    dispatch(
                      setSearchOption({
                        property: "firstclass",
                        data: !firstclass,
                      })
                    );

                    getFilters(dispatch, false);
                  }}
                />
                &nbsp;
                <div
                  dangerouslySetInnerHTML={{
                    __html: "First",
                  }}
                ></div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
