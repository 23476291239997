import React, { useState } from "react";

export default function InfoPopupHover(props) {
  const { content, trigger, position, isDisabled, nested, objectcontent } =
    props;

  return (<>{trigger}</>
 /*   <Popup
      disabled={isDisabled}
      nested={nested}
      position={position}
      on="hover"
      trigger={(open) => trigger}
    >
      <div className="infopopuphover">
        {content && (
          <div
            dangerouslySetInnerHTML={{
              __html: "" + content,
            }}
          />
        )}
        {objectcontent && <div>{objectcontent}</div>}
      </div>
    </Popup>
    */
  );

}
