import { normalizeRoomName } from "./utils";

export const getalternativeTransports = (
  alternativeTransports,
  airports,
  df2
) => {
  let outboundStations = [];
  let inboundStations = [];

  if (Array.isArray(alternativeTransports?.Offers?.Offer)) {
    for (let i = 0; i < alternativeTransports.Offers.Offer.length; i++) {
      if (
        alternativeTransports?.Offers?.Offer[i].OfferServices?.Package?.Flight
          ?.OutboundFlight?.FlightDeparture?.DepartureAirportRef?.AirportCode &&
        alternativeTransports?.Offers?.Offer[i].OfferServices?.Package?.Flight
          ?.OutboundFlight?.FlightDeparture?.DepartureDate &&
        alternativeTransports?.Offers?.Offer[i].OfferServices?.Package?.Flight
          ?.OutboundFlight?.FlightDeparture?.DepartureTime
      ) {
        let outboundairport =
          alternativeTransports?.Offers?.Offer[i].OfferServices.Package.Flight
            .OutboundFlight.FlightDeparture.DepartureAirportRef.AirportCode;
        let date =
          alternativeTransports?.Offers?.Offer[i].OfferServices.Package.Flight
            .OutboundFlight.FlightDeparture.DepartureDate;
        let time =
          alternativeTransports?.Offers?.Offer[i].OfferServices.Package.Flight
            .OutboundFlight.FlightDeparture.DepartureTime;
        let row = outboundStations.find((e) => e.value === outboundairport);

        if (!row) {
          outboundStations.push({
            value: outboundairport,
            label:
              Array.isArray(airports) &&
              airports.find((e) => e.StationCode === outboundairport) !==
                undefined
                ? airports.find((e) => e.StationCode === outboundairport).Name
                : outboundairport,
            times: [
              {
                value: outboundairport + time + date,
                label: df2.format(new Date(date)) + " " + time,
                outboundairport: outboundairport,
                time: time,
                date: date,
              },
            ],
          });
        } else {
          let rt = row.times.find((e) => e.time === time && e.date === date);
          if (!rt) {
            row.times.push({
              value: outboundairport + time + date,
              label: df2.format(new Date(date)) + " " + time,
              outboundairport: outboundairport,
              time: time,
              date: date,
            });
          }
        }
      }

      if (
        alternativeTransports?.Offers?.Offer[i].OfferServices?.Package?.Flight
          ?.OutboundFlight?.FlightDeparture?.DepartureAirportRef?.AirportCode &&
        alternativeTransports?.Offers?.Offer[i].OfferServices?.Package?.Flight
          ?.InboundFlight?.FlightDeparture?.DepartureDate &&
        alternativeTransports?.Offers?.Offer[i].OfferServices?.Package?.Flight
          ?.InboundFlight?.FlightDeparture?.DepartureTime
      ) {
        let inboundairport =
          alternativeTransports?.Offers?.Offer[i].OfferServices.Package.Flight
            .OutboundFlight.FlightDeparture.DepartureAirportRef.AirportCode;
        let date =
          alternativeTransports?.Offers?.Offer[i].OfferServices.Package.Flight
            .InboundFlight.FlightDeparture.DepartureDate;
        let time =
          alternativeTransports?.Offers?.Offer[i].OfferServices.Package.Flight
            .InboundFlight.FlightDeparture.DepartureTime;
        let row = inboundStations.find((e) => e.value === inboundairport);

        if (!row) {
          inboundStations.push({
            value: inboundairport,
            label:
              Array.isArray(airports) &&
              airports.find((e) => e.StationCode === inboundairport) !==
                undefined
                ? airports.find((e) => e.StationCode === inboundairport).Name
                : inboundairport,
            times: [
              {
                value: inboundairport + time + date,
                label: df2.format(new Date(date)) + " " + time,
                inboundairport: inboundairport,
                time: time,
                date: date,
              },
            ],
          });
        } else {
          let rt = row.times.find((e) => e.time === time && e.date === date);
          if (!rt) {
            row.times.push({
              value: inboundairport + time + date,
              label: df2.format(new Date(date)) + " " + time,
              inboundairport: inboundairport,
              time: time,
              date: date,
            });
          }
        }
      }
    }
  }
  return { outboundStations, inboundStations };
};

export const roomTypesForTourOperatorCond = (
  tourOperator,
  offers,
  theOffer
) => {
  const roomTypes = [];
  if (Array.isArray(offers)) {
    for (let i = 0; i < offers.length; i++) {
      let offer = offers[i];
      if (offer.TourOperator.TourOperatorCode !== tourOperator) continue;
      if (theOffer) {
        if (
          offer.OfferServices.Package.Accommodation.BoardType !==
          theOffer.OfferServices.Package.Accommodation.BoardType
        )
          continue;
      }
      let roomname = normalizeRoomName(
        offer.OfferServices.Package.Accommodation.Room.RoomName
      );

      let offerrealprice = 0;
      let cacheprice = offer.PriceInfo.Price.value * 1;
      let checked = 0;
      if (offer?.bookinginfo) {
        checked = -1;
      }
      if (offer?.bookinginfo?.PriceInformation?.TotalPrice?.Amount?._) {
        checked = 1;
        offerrealprice =
          (offer?.bookinginfo?.PriceInformation?.TotalPrice?.Amount?._ * 1) /
          (offer.adults * 1 + offer.children * 1);
      } else {
        offerrealprice = offer.PriceInfo.Price.value * 1;
      }

      let rt = roomTypes.find((e) => e.label === roomname);
      if (rt) {
        rt.offers.push({
          offerid: offer.OfferID,
          price: offerrealprice,
          cacheprice: cacheprice,
          checked: checked,
          boardtype: offer.OfferServices.Package.Accommodation.BoardType,
          value: offer.OfferServices.Package.Accommodation.Room.RoomType,
        });
      } else
        roomTypes.push({
          offers: [
            {
              offerid: offer.OfferID,
              price: offerrealprice,
              cacheprice: cacheprice,
              checked: checked,
              boardtype: offer.OfferServices.Package.Accommodation.BoardType,
              value: offer.OfferServices.Package.Accommodation.Room.RoomType,
            },
          ],

          label: roomname,
          offer: offer,
        });
    }
  }

  return roomTypes;
};

export const roomTypesForTourOperator = (tourOperator, offers) => {
  const roomTypes = [];
  if (Array.isArray(offers)) {
    for (let i = 0; i < offers.length; i++) {
      let offer = offers[i];
      if (offer.TourOperator.TourOperatorCode !== tourOperator) continue;
      let roomname = normalizeRoomName(
        offer.OfferServices.Package.Accommodation.Room.RoomName
      );

      let offerrealprice = 0;
      let cacheprice = offer.PriceInfo.Price.value * 1;
      let checked = 0;
      if (offer?.bookinginfo) {
        checked = -1;
      }
      if (offer?.bookinginfo?.PriceInformation?.TotalPrice?.Amount?._) {
        checked = 1;
        offerrealprice =
          (offer?.bookinginfo?.PriceInformation?.TotalPrice?.Amount?._ * 1) /
          (offer.adults * 1 + offer.children * 1);
      } else {
        offerrealprice = offer.PriceInfo.Price.value * 1;
      }

      let rt = roomTypes.find((e) => e.label === roomname);
      if (rt) {
        rt.offers.push({
          offerid: offer.OfferID,
          price: offerrealprice,
          cacheprice: cacheprice,
          checked: checked,
          boardtype: offer.OfferServices.Package.Accommodation.BoardType,
          value: offer.OfferServices.Package.Accommodation.Room.RoomType,
        });
      } else
        roomTypes.push({
          offers: [
            {
              offerid: offer.OfferID,
              price: offerrealprice,
              cacheprice: cacheprice,
              checked: checked,
              boardtype: offer.OfferServices.Package.Accommodation.BoardType,
              value: offer.OfferServices.Package.Accommodation.Room.RoomType,
            },
          ],

          label: roomname,
          offer: offer,
        });
    }
  }

  return roomTypes;
};

export const boardTypesForTourOperator = (tourOperator, offers) => {
  const boardTypes = [];
  if (Array.isArray(offers)) {
    for (let i = 0; i < offers.length; i++) {
      let offer = offers[i];
      if (offer.TourOperator.TourOperatorCode !== tourOperator) continue;

      let bt = boardTypes.find(
        (e) => e.label === offer.OfferServices.Package.Accommodation.BoardType
      );
      let roomname = normalizeRoomName(
        offer.OfferServices.Package.Accommodation.Room.RoomName
      );
      if (bt) {
        bt.offers.push({
          offerid: offer.OfferID,
          price: offer.PriceInfo.Price.value,
          value: offer.OfferServices.Package.Accommodation.BoardType,
          roomname: roomname,
        });
      } else
        boardTypes.push({
          offers: [
            {
              offerid: offer.OfferID,
              price: offer.PriceInfo.Price.value,
              value: offer.OfferServices.Package.Accommodation.BoardType,
              roomname: roomname,
            },
          ],

          label: offer.OfferServices.Package.Accommodation.BoardType,
        });
    }
  }
  return boardTypes;
};

export const comparableOffersForOffer = (offer, offers) => {
  const comparableOffers = [];
  if (Array.isArray(offers) && offer.OfferID) {
    for (let i = 0; i < offers.length; i++) {
      let compoffer = offers[i];
      if (compoffer.OfferID === offer.OfferID) continue;
      if (
        compoffer.TourOperator.TourOperatorCode !==
        offer.TourOperator.TourOperatorCode
      )
        continue;

      if (
        compoffer.OfferServices.Package.Accommodation.ServiceCode ===
          offer.OfferServices.Package.Accommodation.ServiceCode &&
        compoffer.OfferServices.Package.Accommodation.BoardType ===
          offer.OfferServices.Package.Accommodation.BoardType &&
        compoffer.OfferServices.Package.Accommodation.Room.RoomCode ===
          offer.OfferServices.Package.Accommodation.Room.RoomCode
      ) {
        comparableOffers.push({ ...compoffer });
      }
    }
  }
  return comparableOffers;
};

export const sortOffersByFlight = (offers) => {
  offers
    .sort((a, b) =>
      a.OfferServices.Package.Flight.InboundFlight.FlightDeparture
        .DepartureTime >
      b.OfferServices.Package.Flight.InboundFlight.FlightDeparture.DepartureTime
        ? -1
        : 1
    )
    .sort((a, b) =>
      a.OfferServices.Package.Flight.InboundFlight.FlightDeparture
        .DepartureDate <
      b.OfferServices.Package.Flight.InboundFlight.FlightDeparture.DepartureDate
        ? -1
        : 1
    )
    .sort((a, b) =>
      a.OfferServices.Package.Flight.OutboundFlight.FlightDeparture
        .DepartureTime >
      b.OfferServices.Package.Flight.OutboundFlight.FlightDeparture
        .DepartureTime
        ? -1
        : 1
    )
    .sort((a, b) =>
      a.OfferServices.Package.Flight.OutboundFlight.FlightDeparture
        .DepartureAirportRef.AirportCode <
      b.OfferServices.Package.Flight.OutboundFlight.FlightDeparture
        .DepartureAirportRef.AirportCode
        ? -1
        : 1
    );
};

export const getOtherTransferOffer = (
  offers,
  offer,
  transferincl,
  railandfly,
  cancellationoption
) => {
  let otherTransferOffer = {};
  //console.log(offer);
  for (let i = 0; i < offers.length; i++) {
    if (offer.OfferID === offers[i].OfferID) continue;
    let trans =
      offers[i].OfferProperties?.TransferInfos?.includes("TransferIncluded");
    let rf = offers[i].OfferProperties?.OfferIncludes?.includes("RailAndFly");
    rf = rf ? true : false;
    let co = offers[i]?.OfferProperties?.CancellationOption;
    co = co ? co : "";
    if (
      offers[i].TourOperator?.TourOperatorCode ===
        offer.TourOperator?.TourOperatorCode &&
      offers[i].OfferServices.Package?.Flight?.OutboundFlight.FlightDeparture
        .DepartureAirportRef.AirportCode ===
        offer.OfferServices.Package?.Flight?.OutboundFlight.FlightDeparture
          .DepartureAirportRef.AirportCode &&
      offers[i].OfferServices.Package.Accommodation.Room.RoomName ===
        offer.OfferServices.Package.Accommodation.Room.RoomName &&
      offers[i].OfferServices.Package.Accommodation.BoardType ===
        offer.OfferServices.Package.Accommodation.BoardType &&
      offers[i].OfferServices.Package.Accommodation.Room.RoomCode ===
        offer.OfferServices.Package.Accommodation.Room.RoomCode &&
      trans !== transferincl &&
      rf === railandfly &&
      cancellationoption === co
    ) {
      otherTransferOffer = offers[i];
      break;
    }
  }
  return otherTransferOffer;
};

export const getOtherRailOffer = (
  offers,
  offer,
  transferincl,
  railandfly,
  cancellationoption
) => {
  let otherRailOffer = {};

  for (let i = 0; i < offers.length; i++) {
    if (offer.OfferID === offers[i].OfferID) continue;
    let trans =
      offers[i].OfferProperties?.TransferInfos?.includes("TransferIncluded");
    let rf = offers[i].OfferProperties?.OfferIncludes?.includes("RailAndFly");
    rf = rf ? true : false;
    let co = offers[i]?.OfferProperties?.CancellationOption;
    co = co ? co : "";

    if (
      offers[i].TourOperator?.TourOperatorCode ===
        offer.TourOperator?.TourOperatorCode &&
      offers[i].OfferServices.Package?.Flight?.OutboundFlight.FlightDeparture
        .DepartureAirportRef.AirportCode ===
        offer.OfferServices.Package?.Flight?.OutboundFlight.FlightDeparture
          .DepartureAirportRef.AirportCode &&
      offers[i].OfferServices.Package.Accommodation.Room.RoomName ===
        offer.OfferServices.Package.Accommodation.Room.RoomName &&
      offers[i].OfferServices.Package.Accommodation.BoardType ===
        offer.OfferServices.Package.Accommodation.BoardType &&
      offers[i].OfferServices.Package.Accommodation.Room.RoomCode ===
        offer.OfferServices.Package.Accommodation.Room.RoomCode &&
      rf !== railandfly &&
      trans === transferincl &&
      cancellationoption === co
    ) {
      otherRailOffer = offers[i];
      break;
    }
  }
  return otherRailOffer;
};
