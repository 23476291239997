import { addCollection } from "@iconify/react";
import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import axios from "axios";
import { addDays } from "date-fns";
import { PRICELIMIT } from "./config";
import { offerSortOptions, views } from "./utils";
import { fetchRegionGroups } from "./propertiesSlice";

axios.defaults.headers.common["AgencyID"] = window.configData.AGENCYID;
axios.defaults.headers.common["Version"] = process.env.REACT_APP_VERSION;
axios.defaults.headers.common["App"] = window.configData.APP;

const initialState = {
  selectedDestination: [],
  queryid: "",
  holidayType: { accommodation: true, flight: true },
  northEast: null,
  southWest: null,
  price: PRICELIMIT,
  adults: { value: "2", label: "2 Erwachsene" },
  children: { value: "0", label: "kein Kind" },
  durationkind: { value: "Trip", label: "Reisedauer" },
  childrenages: [
    { value: "0", label: "Alter" },
    { value: "0", label: "Alter" },
    { value: "0", label: "Alter" },
    { value: "0", label: "Alter" },
    { value: "0", label: "Alter" },
    { value: "0", label: "Alter" },
    { value: "0", label: "Alter" },
    { value: "0", label: "Alter" },
    { value: "0", label: "Alter" },
    { value: "0", label: "Alter" },
  ],
  stations: [],
  mid: "",
  multiroomdivider: 1,
  boardType: [],
  roomType: [],
  hotelAttribute: [],
  roomView: [],
  tourOperators: [],
  couponcode: "",
  outbounddate: "",
  outboundtime: "0-23",
  inbounddate: "",
  inboundtime: "0-23",
  travelDate: {
    from: JSON.stringify(addDays(new Date().setHours(0, 0, 0, 0), 7)),
    to: JSON.stringify(addDays(new Date().setHours(0, 0, 0, 0), 37)),
  },
  duration: { value: "7", label: "1 Woche" },
  freecancellation: false,
  addablecancellation: false,
  reservationoperatorsonly: false,
  freerebook: false,
  directflight: false,
  lowcost: false,
  linie: false,
  maxstops: -1,
  oneway: true,
  nofrill: false,
  fsciata: true,
  fscnego: true,
  fscconso: false,
  fsccorp: false,

  firstclass: false,
  buinessclass: false,
  economyclass: true,
  baggageonly: true,
  verifiedfares: true,
  charter: false,
  transferincluded: false,
  railandfly: false,
  rentalcar: false,
  operatingbrand: [],
  destinationStations: [],
  selectedMarketingActions: [],
  specialRoomType: [],
  sorting: offerSortOptions[0],
  ratingcount: "0",
  recommendationrate: "0",
  category: "",
  apicall: "packageOffers",
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    resetFilter: (state, action) => {
      //console.log(action.payload);
      if (action.payload === "mobile") {
        state.price = PRICELIMIT;
        state.recommendationrate = "0";
        state.category = "";
        state.ratingcount = "0";
        state.multiroomdivider = 1;
        state.hotelAttribute = [];
        state.specialRoomType = [];
        state.selectedMarketingActions = [];
        state.freecancellation = false;
        state.addablecancellation = false;
        state.freerebook = false;
        state.addablecancellation = false;
        state.reservationoperatorsonly = false;
        state.roomView = [];
        state.tourOperators = [];
        state.transferincluded = false;
        state.railandfly = false;
        state.rentalcar = false;
        state.operatingbrand = [];
        state.outboundtime = "0-23";
        state.inboundtime = "0-23";
        state.boardType = [];
        state.roomType = [];
        state.directflight = false;
        state.maxstops = 1;
        state.oneway = true;
        state.nofrill = false;
        state.fsciata = true;
        state.fscnego = true;
        state.fscconso = false;
        state.fsccorp = false;
        state.firstclass = false;
        state.buinessclass = false;
        state.economyclass = true;
        state.baggageonly = true;
        state.verifiedfares = true;
        state.destinationStations = [];
        state.couponcode = "";
        state.lowcost = false;
        state.linie = false;
        state.charter = false;
        state.operatingbrand = [];
        return;
      }
      if (action.payload === views.offerview) {
        state.price = PRICELIMIT;
        state.recommendationrate = "0";
        state.multiroomdivider = 1;
        state.category = "";
        state.ratingcount = "0";
        state.hotelAttribute = [];
        state.specialRoomType = [];
        state.selectedMarketingActions = [];
        state.freecancellation = false;
        state.addablecancellation = false;
        state.freerebook = false;
        state.addablecancellation = false;
        state.reservationoperatorsonly = false;
        state.roomView = [];
        state.tourOperators = [];
        state.directflight = false;
        state.maxstops = 1;
        state.oneway = true;
        state.nofrill = false;
        state.fsciata = true;
        state.fscnego = true;
        state.fscconso = false;
        state.fsccorp = false;

        state.firstclass = false;
        state.buinessclass = false;
        state.economyclass = true;
        state.baggageonly = true;
        state.verifiedfares = true;
        state.destinationStations = [];
        state.couponcode = "";
        state.transferincluded = false;
        state.railandfly = false;
        state.rentalcar = false;
        state.operatingbrand = [];
        state.outboundtime = "0-23";
        state.inboundtime = "0-23";
        state.boardType = [];
        state.roomType = [];
      }
      if (action.payload === views.hotelclassicview) {
        state.price = PRICELIMIT;
        state.recommendationrate = "0";
        state.category = "";
        state.ratingcount = "0";
        state.multiroomdivider = 1;
        state.hotelAttribute = [];
        state.specialRoomType = [];
        state.selectedMarketingActions = [];
        state.freecancellation = false;
        state.addablecancellation = false;
        state.freerebook = false;
        state.addablecancellation = false;
        state.reservationoperatorsonly = false;
        state.roomView = [];
        state.tourOperators = [];
        state.directflight = false;
        state.maxstops = 1;
        state.oneway = true;
        state.nofrill = false;
        state.fsciata = true;
        state.fscnego = true;
        state.fscconso = false;
        state.fsccorp = false;

        state.firstclass = false;
        state.buinessclass = false;
        state.economyclass = true;
        state.baggageonly = true;
        state.verifiedfares = true;
        state.destinationStations = [];
        state.couponcode = "";
        state.transferincluded = false;
        state.railandfly = false;
        state.rentalcar = false;
        state.operatingbrand = [];
        state.outboundtime = "0-23";
        state.inboundtime = "0-23";
        state.boardType = [];
        state.roomType = [];
      }
      if (action.payload === views.hotelcrossview) {
        state.price = PRICELIMIT;
        state.recommendationrate = "0";
        state.category = "";
        state.ratingcount = "0";
        state.multiroomdivider = 1;
        state.hotelAttribute = [];
        state.specialRoomType = [];
        state.selectedMarketingActions = [];
        state.freecancellation = false;
        state.addablecancellation = false;
        state.freerebook = false;
        state.addablecancellation = false;
        state.reservationoperatorsonly = false;
        state.roomView = [];
        state.tourOperators = [];
        state.directflight = false;
        state.maxstops = 1;
        state.oneway = true;
        state.nofrill = false;
        state.fsciata = true;
        state.fscnego = true;
        state.fscconso = false;
        state.fsccorp = false;

        state.firstclass = false;
        state.buinessclass = false;
        state.economyclass = true;
        state.baggageonly = true;
        state.verifiedfares = true;
        state.destinationStations = [];
        state.couponcode = "";
        state.transferincluded = false;
        state.railandfly = false;
        state.rentalcar = false;
        state.operatingbrand = [];
        state.outboundtime = "0-23";
        state.inboundtime = "0-23";
        state.boardType = [];
        state.roomType = [];
      }
      if (action.payload === views.searchview) {
        state.price = PRICELIMIT;
        state.recommendationrate = "0";
        state.category = "";
        state.ratingcount = "0";
        state.multiroomdivider = 1;
        state.hotelAttribute = [];
        state.specialRoomType = [];
        state.selectedMarketingActions = [];
        state.freecancellation = false;
        state.addablecancellation = false;
        state.freerebook = false;
        state.addablecancellation = false;
        state.reservationoperatorsonly = false;
        state.roomView = [];
        state.tourOperators = [];
        state.directflight = false;
        state.maxstops = 1;
        state.oneway = true;
        state.nofrill = false;
        state.fsciata = true;
        state.fscnego = true;
        state.fscconso = false;
        state.fsccorp = false;

        state.firstclass = false;
        state.buinessclass = false;
        state.economyclass = true;
        state.baggageonly = true;
        state.verifiedfares = true;
        state.destinationStations = [];
        state.couponcode = "";
        state.transferincluded = false;
        state.railandfly = false;
        state.rentalcar = false;
        state.operatingbrand = [];
        state.outboundtime = "0-23";
        state.inboundtime = "0-23";
        state.boardType = [];
        state.roomType = [];
      }
    },

    resetSearch: (state, action) => {
      state.selectedDestination = [];
      state.adults = { value: "2", label: "2 Erwachsene" };
      state.children = { value: "0", label: "kein Kind" };
      state.durationkind = {
        value: "Trip",
        label: "Reisedauer",
      };
      state.childrenages = [
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
        { value: "0", label: "Alter" },
      ];
      state.directflight = false;
      state.maxstops = 1;
      state.oneway = true;
      state.nofrill = false;
      state.fsciata = true;
      state.fscnego = true;
      state.fscconso = false;
      state.fsccorp = false;

      state.firstclass = false;
      state.buinessclass = false;
      state.economyclass = true;
      state.baggageonly = true;
      state.verifiedfares = true;
      state.ratingcount = "0";
      state.recommendationrate = "0";
      state.category = "";
      state.couponcode = "";
      state.stations = [];
      state.duration = { value: "7", label: "1 Woche" };
      state.travelDate = {
        from: JSON.stringify(addDays(new Date(), 7)),
        to: JSON.stringify(addDays(new Date(), 37)),
      };
    },

    setMid: (state, action) => {
      state.mid = action.payload;
    },
    setHolidayType: (state, action) => {
      state.holidayType = action.payload;
    },
    setSearchOption: (state, action) => {
      state[action.payload.property] = action.payload.data;
    },
    setSearchOptions: (state, action) => {
      let keys = Object.keys(action.payload);
      for (let i = 0; i < keys.length; i++) {
        state[keys[i]] = action.payload[keys[i]];
      }
    },
    setSelectedMarketingActions: (state, action) => {
      state.selectedMarketingActions = action.payload;
    },
    setSpecialRoomType: (state, action) => {
      state.specialRoomType = action.payload;
    },

    setSelectedDestination: (state, action) => {
      state.selectedDestination = action.payload;
    },
    setNorthEast: (state, action) => {
      state.northEast = action.payload;
    },
    setSouthWest: (state, action) => {
      state.southWest = action.payload;
    },
    setQueryid: (state, action) => {
      state.queryid = action.payload;
    },
    setOutbounddate: (state, action) => {
      state.outbounddate = action.payload;
    },
    setInbounddate: (state, action) => {
      state.inbounddate = action.payload;
    },
    setApicall: (state, action) => {
      state.apicall = action.payload;
    },
  },
});

export const selectSearch = createSelector(
  (state) => ({
    selectedDestination: state.search.selectedDestination,
    adults: state.search.adults,
    children: state.search.children,
    multiroomdivider: state.search.multiroomdivider,
    childrenages: state.search.childrenages,
    stations: state.search.stations,
    boardType: state.search.boardType,
    roomType: state.search.roomType,
    roomView: state.search.roomView,
    priceSelect: state.search.priceSelect,
    price: state.search.price,
    hotelAttribute: state.search.hotelAttribute,
    outboundtime: state.search.outboundtime,
    inboundtime: state.search.inboundtime,
    travelDate: state.search.travelDate,
    duration: state.search.duration,
    freecancellation: state.search.freecancellation,
    destinationStations: state.search.destinationStations,
    directflight: state.search.directflight,
    transferincluded: state.search.transferincluded,
    recommendationrate: state.search.recommendationrate,
    tourOperators: state.search.tourOperators,
    railandfly: state.search.railandfly,
    rentalcar: state.search.rentalcar,
    sorting: state.search.sorting,
    operatingbrand: state.search.operatingbrand,
    ratingcount: state.search.ratingcount,
    durationkind: state.search.durationkind,
    category: state.search.category,
    couponcode: state.search.couponcode,
    freerebook: state.search.freerebook,
    addablecancellation: state.search.addablecancellation,
  }),
  (state) => state
);
export const {
  setSearchOption,
  setSelectedDestination,
  setNorthEast,
  setSouthWest,
  setQueryid,

  setMid,
  setOutbounddate,
  setInbounddate,
  setHolidayType,
  setApicall,
  setSelectedMarketingActions,
  setSpecialRoomType,
  setSearchOptions,
  resetFilter,
  resetSearch,
} = searchSlice.actions;

export default searchSlice.reducer;
