import React, { useState } from "react";
import axios from "axios";
import {
  fetchAirlines,
  fetchDepartureStations,
  fetchRegionGroups,
  fetchTourOperators,
  fetchSuggestions,
  fetchAgencyproperties,
  getBestplaces,
  fetchAirlineProperties,
  fetchSessionHistory,
} from "amadeusredux/lib/propertiesSlice";
import { useDispatch, useSelector } from "react-redux";
import QueryString from "query-string";

import "./App.scss";

import CookieConsent from "react-cookie-consent";

import { EMPTY_ELEMENT, views } from "amadeusredux/lib/utils";
import MobileCrossView from "./pages/MobileCrossView.js";
import MobileHotelView from "./pages/MobileHotelView.js";
import MobileBookingView from "./pages/MobileBookingView.js";
import MobileOffersView from "./pages/MobileOffersView.js";
import MobileConfirmationView from "./pages/MobileConfirmationView";
import { BsWhatsapp } from "react-icons/bs";

//import SearchView from "./pages/SearchView.js";
import {
  setAccountId,
  setDelay,
  setFeatures,
  setDebug,
  setMode,
  setOpenSearch,
  setSaveSearch,
  setSessionId,
  setSidebar,
  setView,
  setSocketid,
} from "amadeusredux/lib/systemSlice";
import { v4 as uuidv4 } from "uuid";
import { useCookies } from "react-cookie";
import Header from "./pages/Header";
import {
  cookietosearch,
  getSessionMemo,
  memotosearch,
  search,
} from "amadeusredux/lib/api";
import { getHost } from "./utils";
import {
  setHolidayType,
  setInbounddate,
  setMid,
  setOutbounddate,
  setSearchOption,
  setSearchOptions,
  setSelectedDestination,
} from "amadeusredux/lib/searchSlice";
import { setSelectedHotel } from "amadeusredux/lib/hotelSlice";
import { InstallPWA } from "./installprompts/InstallPWA";
import BackButton from "./components/BackButton.js";
import SearchFullView from "./pages/SearchFullView.js";
import { tr } from "date-fns/locale";
import MobileFlightsView from "./pages/MobileFlightsView.js";
import { v4 as uuid } from "uuid";

import { socket } from "./socket";
const parsed = QueryString.parse(window.location.search);

axios.defaults.headers.common["AgencyID"] = window.configData.AGENCYID;
axios.defaults.headers.common["Version"] = process.env.REACT_APP_VERSION;
axios.defaults.headers.common["App"] = window.configData.APP;

function App() {
  const dispatch = useDispatch();

  const socketid = useSelector((state) => state.system.socketid);
  const loading = useSelector((state) => state.system.loading);
  const view = useSelector((state) => state.system.view);
  const [cookies, setCookie] = useCookies(["amadeussession"]);
  const [cookiesconsent, setCookiesconsent] = useCookies(["cookieconsent"]);
  const agencyproperties = useSelector(
    (state) => state.properties.agencyproperties
  );

  const offerInfos = useSelector((state) => state.offers.offerInfos);
  const memo = useSelector((state) => state.offers.memo);

  const hotelCount = useSelector((state) => state.filter.hotelCount);

  const bookingref = React.useRef();
  const [showMenu, setShowMenu] = React.useState(false);

  const [toplinks, setToplinks] = React.useState([]);
  const [playstorelink, setPlaystorelink] = React.useState("");
  const [appstorelink, setAppstorelink] = React.useState("");

  const [miscpages, setMiscpages] = React.useState({});
  const [miscpage, setMiscpage] = React.useState("home");

  React.useEffect(() => {
    function onConnect() {
      //console.log("connected");
      let socketiduuid = socketid || uuid();
      //console.log(socketiduuid);
      socket.emit("createroom", socketiduuid);
      dispatch(setSocketid(socketiduuid));
    }

    function onDisconnect() {
      //console.log("disconnected");
    }

    function onMessageEvent(message) {
      // console.log(message);
      //if (message.action == "alert") alert(message.text);
      //if (message.action == "newjob") dispatch(setTowConfirmed(true));
      //if (message.view) dispatch(setPageView(message.view));
      //dispatch(setStart(true));
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("message", onMessageEvent);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("message", onMessageEvent);
    };
  }, []);

  const dologin = () => {
    axios
      .post(getHost() + "/customerlogin", {
        agencyid: window.configData.AGENCYID,
        sessionid: sessid,
        email: document.getElementById("registerlogin").value,
        pwd: document.getElementById("registerpwd").value,
        register: true,
      })
      .then((response) => {
        try {
          dispatch(setAccountId(response?.data?.ret?.accountid));
        } catch (e) { }
      });
  };

  const sendcontactform = () => {
    axios
      .post(getHost() + "/contactform", {
        agencyid: window.configData.AGENCYID,
        name: document.getElementById("cfname").value,
        email: document.getElementById("cfemail").value,
        subject: document.getElementById("cfsubject").value,
        message: document.getElementById("cfmessage").value,
      })
      .then((response) => {
        try {
          //console.log(response);
        } catch (e) { }
      });
  };

  const sendnewsletter = () => {
    if (document.getElementById("nloutput"))
      document.getElementById("nloutput").innerHTML = "";

    if (!(document.getElementById("nlemail").value.indexOf("@") > 1)) {
      if (document.getElementById("nloutput"))
        document.getElementById("nloutput").innerHTML =
          "bitte gültige Email-Adresse eingeben.";
      return;
    }
    axios
      .get(
        getHost() +
        "/newsletter?provider=cleverreach&agencyid=" +
        window.configData.AGENCYID +
        "&email=" +
        document.getElementById("nlemail").value
      )
      .then((response) => {
        try {
          if (document.getElementById("nloutput"))
            document.getElementById("nloutput").innerHTML =
              response.data.ret.msg;
          if (document.getElementById("nltohide"))
            document.getElementById("nltohide").style.display = "none";
        } catch (e) { }
      });
  };

  const ref = React.useRef();

  const initFacebookPixel = (pixelId, checkAgreed) => {
    // Facebook Pixel Base Code
    //console.log("initFacebookPixel");

    if (process.env.NODE_ENV === `development`) return;
    if (nativeapp) return;

    if (checkAgreed) {
      if (cookies) {
        //console.log(cookies.metapixel);
        if (!cookies.metapixel) return;
        let chk = JSON.parse(cookies.metapixel);
        //console.log(chk);
        if (!chk) return;
      }
    }
    //console.log("initFacebookPixel2");

    if (!window.fbq) {
      //console.log(' !window.fbq initFacebookPixel');
      (function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );

      window.fbq("init", pixelId);
      window.fbq("track", "PageView");

      //console.log('initFacebookPixel');
      //console.log(pixelId);
    }

    // Initialisierung mit der erhaltenen ID
  };

  // Tracke PageView bei jedem Routenwechsel
  React.useEffect(() => {
    if (window.fbq) {
      window.fbq("track", "PageView");
    }
  }, [window.location]); // Wird bei jeder Location-Änderung ausgeführt

  React.useEffect(() => {
    /* convert your HTML string into DocumentFragment*/
    if (
      ref.current &&
      view == views.flyview &&
      agencyproperties?.properties?.flylink
    ) {
      const node = document
        .createRange()
        .createContextualFragment(
          '<script src="https://flr.ypsilon.net/static/resize/ypsnet-ibe.min.js"></script>'
        );
      ref.current.appendChild(node);
    }
  }, [view]);

  React.useEffect(() => {
    document.title =
      (agencyproperties?.properties?.mobileheadline ||
        agencyproperties?.properties?.mobileproperties?.headline
        ? (agencyproperties.properties.mobileheadline ||
          agencyproperties?.properties?.mobileproperties?.headline) + ": "
        : "") + "Reise finden";
    if (cookies) {
      if (cookiesconsent?.cookieconsent === "true" || !nativeapp) {
        if (agencyproperties?.properties?.facebookPixelId)
          initFacebookPixel(
            agencyproperties?.properties?.facebookPixelId,
            true
          );
      }
    }
  }, [agencyproperties]);

  let sessid = EMPTY_ELEMENT;
  let nativeapp = false;

  if (parsed.nativeapp) {
    if (parsed.nativeapp === "1") {
      nativeapp = true;
    }
  }

  React.useEffect(() => {
    if (parsed.features) {
      if (parsed.features === "1") {
        dispatch(setFeatures(true));
      }
    }

    if (parsed.mode) {
      if (parsed.mode) {
        dispatch(setMode(parsed.mode));
      }
    }

    if (parsed.debug) {
      if (parsed.debug === "1") {
        dispatch(setDebug(true));
      }
    }

    dispatch(
      setSearchOption({
        property: "durationkind",
        data: {
          value: "Stay",
          label: "Aufenthalt",
        },
      })
    );
    dispatch(setView(views.miscview));
    dispatch(fetchAirlines()).then(() => dispatch(fetchAirlineProperties()));

    dispatch(getBestplaces());
    dispatch(fetchRegionGroups());
    dispatch(fetchDepartureStations());
    dispatch(fetchTourOperators());

    dispatch(fetchAgencyproperties());

    if (cookies || nativeapp) {
      if (cookiesconsent?.cookieconsent === "true" || !nativeapp) {
        if (agencyproperties?.properties?.facebookPixelId)
          initFacebookPixel(
            agencyproperties?.properties?.facebookPixelId,
            true
          );
      }
      if (cookiesconsent?.cookieconsent === "true" || nativeapp) {
        window.setCookie = setCookie;

        if (!cookies.sessionid) {
          let sessionid = uuidv4();
          var dt = new Date(Date.now());

          dt.setDate(dt.getDate() + 60);
          setCookie("sessionid", sessionid, {
            expires: dt,
            path: "/",
          });
          sessid = sessionid;
          dispatch(setSessionId(sessionid));
        } else {
          dispatch(setSessionId(cookies.sessionid));
          sessid = cookies.sessionid;
        }
      } else {
        dispatch(setSessionId(EMPTY_ELEMENT));
        window.setCookie = undefined;
      }
      dispatch(fetchSessionHistory({ sessionid: sessid }));
      dispatch(fetchSuggestions());
      //socket.emit("sessionid", sessid);

      if (parsed.id) {
        try {
          fetch(getHost() + "/getlinkinfo?id=" + parsed.id)
            .then((response) => response.json())
            .then((data) => {
              //console.log("Success:", data);
              //console.log("Success:", data.ret[0]);
              dispatch(setMid(parsed.id));
              memotosearch(data, dispatch);
            })
            .catch((error) => {
              //console.error("Error:", error);
            });
        } catch (e) {
          //console.log(e);
        }
      } else {
        if (cookies.lastsearch && !parsed.qid) {
          //console.log(cookies.lastsearch);
          let lastsearch = cookies.lastsearch;
          cookietosearch({ ret: [lastsearch] }, dispatch, true);
        }
      }

      axios(
        getHost() +
        "/getmobilemisc?agencyid=" +
        window.configData.AGENCYID +
        "&sessionid=" +
        sessid
      )
        .then((response) => {
          let tlids = [];
          let aslink = "";
          let pslink = "";
          let data = response.data?.ret;

          const updateHTML = (html, search, tag) => {
            if (html.indexOf("{" + search) > -1) {
              let display = html.substring(
                html.indexOf("{" + search) + (search.length + 2),
                html.indexOf("}", html.indexOf("{" + search) + 1)
              );

              html = html.replace(
                "{" + search + "," + display + "}",
                '<span style="cursor:pointer"><' +
                tag +
                ">" +
                display +
                "</" +
                tag +
                "></span>"
              );
            }

            return html;
          };

          const updateHTMLWith3Parm = (html, search, tag) => {
            if (html.indexOf("{" + search) > -1) {
              let options = html.substring(
                html.indexOf("{" + search) + 1,
                html.indexOf("}", html.indexOf("{" + search) + 1)
              );

              let optionArray = options.split(",");

              if (optionArray.length < 3) {
                updateHTML(html, search, tag);
                return;
              }

              html = html.replace(
                "{" + options + "}",
                '<span style="cursor:pointer"><' +
                tag +
                ">" +
                optionArray[2].trim() +
                "</" +
                tag +
                "></span>"
              );
            }

            return html;
          };

          const updatePlaystorelink = (html, search, tag) => {
            if (html.indexOf("{" + search) > -1) {
              let options = html.substring(
                html.indexOf("{" + search) + 1,
                html.indexOf("}", html.indexOf("{" + search) + 1)
              );

              let optionArray = options.split(",");
              pslink = optionArray[1].trim();
              html = html.replace(
                "{" + options + "}",
                '<span style="cursor:pointer"><' +
                tag +
                ">" +
                optionArray[2].trim() +
                "</" +
                tag +
                "></span>"
              );
            }

            return html;
          };

          const updateAppstorelink = (html, search, tag) => {
            if (html.indexOf("{" + search) > -1) {
              let options = html.substring(
                html.indexOf("{" + search) + 1,
                html.indexOf("}", html.indexOf("{" + search) + 1)
              );

              let optionArray = options.split(",");
              aslink = optionArray[1].trim();
              html = html.replace(
                "{" + options + "}",
                '<span style="cursor:pointer"><' +
                tag +
                ">" +
                optionArray[2].trim() +
                "</" +
                tag +
                "></span>"
              );
            }

            return html;
          };

          const updateTopseller = (html, search, tag) => {
            if (html.indexOf("{" + search) > -1) {
              let display = html.substring(
                html.indexOf("{" + search) + (search.length + 2),
                html.indexOf("}", html.indexOf("{" + search) + 1)
              );
              let id = display.split(",")[0];
              display = display.split(",")[1];

              tlids.push(id);
              tag = tag + (tlids.length - 1);
              html = html.replace(
                "{" + search + "," + id + "," + display + "}",
                '<span class="btn btn-primary" style="cursor:pointer"><' +
                tag +
                ">" +
                display +
                "</" +
                tag +
                "></span>"
              );
            }

            return html;
          };

          const updateStars = (html, search) => {
            if (html.indexOf("{" + search) > -1) {
              let display = html.substring(
                html.indexOf("{" + search) + (search.length + 2),
                html.indexOf("}", html.indexOf("{" + search) + 1)
              );

              let strs = "";
              let ct = display * 1;
              for (let i = 0; i < ct; i++) {
                strs = strs + '<i class="bi bi-star-fill text-primary"></i>';
              }

              html = html.replace(
                "{" + search + "," + display + "}",
                "<span >" + strs + "</span>"
              );
            }

            return html;
          };

          //console.log("_______________________data");
          //console.log(data);

          data.home = updateHTMLWith3Parm(data.home, "packagelink", "PL");
          data.home = updateHTMLWith3Parm(data.home, "accommodationlink", "AL");
          data.home = updateHTMLWith3Parm(data.home, "flylink", "FL");
          data.home = updateHTMLWith3Parm(data.home, "cruiselink", "CL");
          data.home = updateHTMLWith3Parm(data.home, "fewolink", "WL");
          data.home = updateHTMLWith3Parm(data.home, "incllink", "IL");
          data.home = updateHTMLWith3Parm(data.home, "memolink", "ML");
          data.home = updateHTMLWith3Parm(data.home, "newsletterlink", "NL");

          data.home = updateAppstorelink(
            data.home,
            "appstorelink",
            "APPSTORELINK"
          );
          data.home = updatePlaystorelink(
            data.home,
            "playstorelink",
            "PLAYSTORELINK"
          );

          data.register = updateHTMLWith3Parm(data.register, "dologin", "LG");

          data.contactform = updateHTMLWith3Parm(
            data.contactform,
            "tagcontactform",
            "CF"
          );

          data.newsletter = updateHTMLWith3Parm(
            data.newsletter,
            "xxtagnewsletter",
            "NL"
          );

          while (data.home.indexOf("{stars") > -1) {
            data.home = updateStars(data.home, "stars");
          }

          while (data.home.indexOf("{topsellerlink") > -1) {
            data.home = updateTopseller(data.home, "topsellerlink", "TL");
          }
          setToplinks(tlids);
          setPlaystorelink(pslink);
          setAppstorelink(aslink);
          setMiscpages(data ? data : {});
          // handle the data
        })
        .catch((error) => {
          // handle any errors
        });

      getSessionMemo("", sessid, dispatch);
    }
    if (parsed.qid) {
      try {
        fetch(getHost() + "/searchattributes?qid=" + parsed.qid, {
          method: "get",

          headers: new Headers({
            "content-type": "application/json",
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            dispatch(setSearchOptions(data));
            dispatch(setSaveSearch(true));
            dispatch(setSidebar(false));
            if (data.selectedDestination.length > 0) {
              if (data.selectedDestination[0].HotelID) {
                dispatch(setOutbounddate(""));
                dispatch(setInbounddate(""));
                dispatch(setSelectedHotel(data.selectedDestination[0]));
                search(dispatch, true, false);
                dispatch(setView(views.hotelcrossview));
              } else {
                dispatch(setOutbounddate(""));
                dispatch(setInbounddate(""));
                search(dispatch, false, false);
                dispatch(setView(views.offerview));
              }
            }
            dispatch(setSaveSearch(false));
          });
      } catch (e) {
        //console.log(e);
      }
    }
  }, []);

  const clickHandler = (e) => {
    //console.log(e);
    //alert(1);
    //alert(JSON.stringify(e));
    if (
      e.target.nodeName === "PL" ||
      (e.target.parentNode && e.target.parentNode.nodeName === "PL")
    ) {
      dispatch(setOpenSearch(true));
      dispatch(
        setHolidayType({
          flight: true,
          accommodation: true,
        })
      );
      dispatch(
        setSearchOption({
          property: "durationkind",
          data: {
            value: "Trip",
            label: "Reisedauer",
          },
        })
      );
      dispatch(setView(views.searchview));
      setShowMenu(false);
      return;
    }
    //alert(2);
    if (
      e.target.nodeName === "LG" ||
      (e.target.parentNode && e.target.parentNode.nodeName === "LG")
    ) {
      dologin();
      return;
    }
    if (
      e.target.nodeName === "CF" ||
      (e.target.parentNode && e.target.parentNode.nodeName === "CF")
    ) {
      sendcontactform();
      return;
    }

    if (
      e.target.nodeName === "NL" ||
      (e.target.parentNode && e.target.parentNode.nodeName === "NL")
    ) {
      sendnewsletter();
      return;
    }

    if (
      e.target.nodeName === "AL" ||
      (e.target.parentNode && e.target.parentNode.nodeName === "AL")
    ) {
      dispatch(setOpenSearch(true));
      dispatch(
        setSearchOption({
          property: "durationkind",
          data: {
            value: "OvernightStays",
            label: "Übernachtungen",
          },
        })
      );

      dispatch(
        setHolidayType({
          flight: false,
          accommodation: true,
        })
      );
      dispatch(setView(views.searchview));
      setShowMenu(false);
      return;
    }

    if (
      e.target.nodeName === "CL" ||
      (e.target.parentNode && e.target.parentNode.nodeName === "CL")
    ) {
      dispatch(setView(views.cruiseview));
      setShowMenu(false);
      return;
    }
    if (
      e.target.nodeName === "WL" ||
      (e.target.parentNode && e.target.parentNode.nodeName === "WL")
    ) {
      dispatch(setView(views.fewoview));
      setShowMenu(false);
      return;
    }
    if (
      e.target.nodeName === "IL" ||
      (e.target.parentNode && e.target.parentNode.nodeName === "IL")
    ) {
      dispatch(setView(views.inclview));
      setShowMenu(false);
      return;
    }    
    if (
      e.target.nodeName === "FL" ||
      (e.target.parentNode && e.target.parentNode.nodeName === "FL")
    ) {
      dispatch(setView(views.flyview));
      setShowMenu(false);
      return;
    }
    if (
      e.target.nodeName === "ML" ||
      (e.target.parentNode && e.target.parentNode.nodeName === "ML")
    ) {
      //alert("ML");

      if (Array.isArray(memo)) {
        if (memo.length === 0) {
          alert("Sie haben noch keine Merkliste");
        }
        let arr = [];
        for (let i = 0; i < memo.length; i++) {
          arr.push({
            label: memo[i].HotelName
              ? memo[i].HotelName
              : "Merkliste" + (i + 1),
            HotelCodes: {
              HotelGiataID: memo[i].HotelGiataID * 1,
            },
          });
        }

        dispatch(setSelectedDestination(arr));
        search(dispatch, false, false);
        dispatch(setView(views.offerview));
      }
      return;
    }

    if (
      e.target.nodeName.startsWith("TL") ||
      (e.target.parentNode && e.target.parentNode.nodeName === "TL")
    ) {
      let idx = e.target.nodeName.replace("TL", "") * 1;

      try {
        fetch(getHost() + "/getlinkinfo?id=" + toplinks[idx])
          .then((response) => response.json())
          .then((data) => {
            dispatch(setHolidayType({ flight: true, accommodation: true }));
            dispatch(setMid(toplinks[idx]));
            memotosearch(data, dispatch);
          })
          .catch((error) => {
            //console.error("Error:", error);
          });
      } catch (e) {
        //console.log(e);
      }
      return;
    }

    if (!nativeapp) {
      if (
        e.target.nodeName.startsWith("APPSTORELINK") ||
        (e.target.parentNode && e.target.parentNode.nodeName === "APPSTORELINK")
      ) {
        window.open(appstorelink, "_blank", "noreferrer");
        return;
      }

      if (
        e.target.nodeName.startsWith("PLAYSTORELINK") ||
        (e.target.parentNode &&
          e.target.parentNode.nodeName === "PLAYSTORELINK")
      ) {
        window.open(playstorelink, "_blank", "noreferrer");
        return;
      }
    }
    /*                const el = e.target.closest("PL");
    console.log(el);
    if (el && e.currentTarget.contains(el)) {
    }*/
  };

  return (
    <div>
      {!(
        view === views.miscview &&
        (miscpage === "impressum" || miscpage === "datenschutz")
      ) &&
        !nativeapp && (
          <CookieConsent
            location="top"
            buttonText="Annehmen"
            declineButtonText="Ablehnen"
            enableDeclineButton
            flipButtons
            expires={60}
            onAccept={() => {
              var dt = new Date(Date.now());
              dt.setDate(dt.getDate() + 60);
              let sessionid = uuidv4();
              setCookie("sessionid", sessionid, {
                expires: dt,
                path: "/",
              });
              sessid = sessionid;

              setCookie(
                "metapixel",
                document.getElementById("checkpixel").checked,
                {
                  expires: dt,
                  path: "/",
                }
              );
              if (document.getElementById("checkpixel").checked === true) {
                if (agencyproperties?.properties?.facebookPixelId)
                  initFacebookPixel(
                    agencyproperties?.properties?.facebookPixelId,
                    false
                  );
              }
              dispatch(setSessionId(sessionid));
            }}
            cookieName="cookieconsent"
            containerClasses="bg-secondary"
            style={{ background: undefined, zIndex: 5000 }}
            declineButtonClasses={"btn btn-danger"}
            buttonClasses={"btn btn-primary"}
            declineButtonStyle={{
              background: undefined,
              border: undefined,
              borderRadius: undefined,
              color: undefined,
            }}
            buttonStyle={{
              background: undefined,
              border: undefined,
              borderRadius: undefined,
              color: undefined,
            }}
            overlayStyle={{}}
          >
            <h2>Cookie-Zustimmung verwalten</h2>
            Um dir ein optimales Erlebnis zu bieten, verwenden wir Technologien
            wie Cookies, um Geräteinformationen zu speichern und/oder darauf
            zuzugreifen. Wenn du diesen Technologien zustimmst, können wir Daten
            wie das Surfverhalten oder eindeutige IDs auf dieser Website anonym
            verarbeiten. Wenn du deine Zustimmung nicht erteilst oder
            zurückziehst, können bestimmte Merkmale und Funktionen
            beeinträchtigt werden.
            {agencyproperties?.properties?.facebookPixelId && (
              <label>
                <br />{" "}
                <input type="checkbox" id="checkpixel" name="checkpixel" />
                &nbsp; Ich stimme zu, dass ich zur Angebotsverfolgung
                personalisiert von Meta Platforms, Inc. getrackt werde und meine
                Aktionen auf dieser Seite in die USA übermittelt und mit
                meinem&nbsp;
                <a href="https://www.facebook.com">Facebook</a>-Konto verknüpft
                werden.
                <br />
                <br />
              </label>
            )}
            <div className="row">
              <div className="col-6">
                <div
                  className="btn btn-primary"
                  onClick={() => {
                    setMiscpage("datenschutz");
                    dispatch(setView(views.miscview));
                  }}
                >
                  Datenschutzerklärung
                </div>
              </div>
              <div className="col-6">
                <div
                  className="btn btn-primary"
                  onClick={() => {
                    setMiscpage("impressum");
                    dispatch(setView(views.miscview));
                  }}
                >
                  Impressum
                </div>
              </div>
            </div>
          </CookieConsent>
        )}
      {showMenu && (
        <div
          id="backdrop"
          style={{
            position: "fixed",
            top: "0",
            width: "100vw",
            height: "100vh",
            zIndex: "1000",
            backgroundColor: "rgb(0,0,0,0.8)",
          }}
        ></div>
      )}
      {loading > 0 && !nativeapp && (
        <div
          id="backdrop"
          style={{
            position: "fixed",
            top: "0",
            width: "100vw",
            height: "100vh",
            zIndex: "4000",
            backgroundColor: "rgb(0,0,0,0.2)",
          }}
        >
          <div
            className="loading"
            style={{ width: "100%", position: "relative", height: "100%" }}
          >
            <div
              className="spinner-border"
              role="status"
              style={{
                display: "block",
                position: "fixed",
                top: "calc(50% - (58px / 2))",
                right: "calc(50% - (58px / 2))",
                color: "black",
              }}
            >
              <span className="sr-only"></span>
            </div>
            <div
              style={{
                display: "block",
                position: "fixed",
                top: "calc(50% - (58px / 2) + 60px)",
                right: "calc(50% - (58px / 2) - 30px)",
                color: "black",
                fontWeight: 600,
              }}
            >
              {view === views.offerview &&
                Array.isArray(offerInfos?.Offers?.Offer) && (
                  <span>
                    <br />
                    Checking:
                    <br />
                    {offerInfos.Offers.Offer.length} / {hotelCount}
                  </span>
                )}
            </div>
          </div>
        </div>
      )}
      <nav
        style={{
          zIndex: 3000,
          backgroundColor: agencyproperties?.properties?.mobileproperties
            ?.menubackground
            ? agencyproperties?.properties?.mobileproperties?.menubackground
            : undefined,
        }}
        className={
          "navbar  fixed-top " +
          (!agencyproperties?.properties?.mobileproperties?.menubackground
            ? " bg-secondary "
            : "") +
          (agencyproperties?.properties?.mobileproperties?.menunavbar
            ? agencyproperties?.properties?.mobileproperties?.menunavbar
            : " navbar-dark ")
        }
      >
        {/*cookiesconsent?.cookieconsent === "true" && 1 === 0 && <InstallPWA />*/}
        <div className="container">
          <span
            style={{
              cursor: "pointer",
              marginTop:
                agencyproperties?.properties?.mobilelogo ||
                  agencyproperties?.properties?.mobileproperties?.logo
                  ? 0
                  : undefined,
              marginBottom:
                agencyproperties?.properties?.mobilelogo ||
                  agencyproperties?.properties?.mobileproperties?.logo
                  ? 0
                  : undefined,
              paddingTop:
                agencyproperties?.properties?.mobilelogo ||
                  agencyproperties?.properties?.mobileproperties?.logo
                  ? 0
                  : undefined,
              paddingBottom:
                agencyproperties?.properties?.mobilelogo ||
                  agencyproperties?.properties?.mobileproperties?.logo
                  ? 0
                  : undefined,
            }}
            className="navbar-brand"
          >
            <BackButton
              setBookedData={() => bookingref.current.setBookedData()}
            />
            <span
              style={
                agencyproperties?.properties?.mobileproperties?.menubranchstyle
                  ? agencyproperties?.properties?.mobileproperties
                    ?.menubranchstyle
                  : {}
              }
              onClick={() => {
                setShowMenu(false);
                setMiscpage("home");
                dispatch(setView(views.miscview));
                setTimeout(() => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                  });
                }, 200);
              }}
            >
              {(agencyproperties?.properties?.mobilelogo ||
                agencyproperties?.properties?.mobileproperties?.logo) && (
                  <img
                    src={
                      agencyproperties?.properties?.mobilelogo ||
                      agencyproperties?.properties?.mobileproperties?.logo
                    }
                    style={{ height: 40 }}
                  />
                )}
              &nbsp;
              <span>
                {agencyproperties?.properties?.mobileheadline ||
                  agencyproperties?.properties?.mobileproperties?.headline}
              </span>
            </span>
          </span>
          <div style={{ "flex-grow": 20 }}></div>
          {agencyproperties?.properties?.whatsapp && (
            <>
              <a
                className="nav-link"
                target="_blank"
                href={"https://wa.me/" + agencyproperties?.properties?.whatsapp}
              >
                <BsWhatsapp
                  style={{
                    fontSize: "20pt",
                    color: "green",
                    marginLeft: "1px",
                    marginRight: "1px",
                  }}
                  title="WhatsApp"
                />
              </a>
            </>
          )}
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => setShowMenu(!showMenu)}
            aria-controls="navbarNav"
            aria-expanded={showMenu ? "true" : "false"}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {showMenu && (
            <div
              className={" navbar-collapse" + (showMenu ? " show" : "collapse")}
              id="navbarNav"
            >
              <ul className="navbar-nav">
                <li className="nav-item ">
                  <span
                    className="nav-link text-end"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowMenu(false);
                    }}
                  >
                    <i class="bi bi-x-circle"></i>
                  </span>
                </li>

                <li className="nav-item active">
                  <span
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setMiscpage("home");
                      dispatch(setView(views.miscview));
                      setShowMenu(false);
                      setTimeout(() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                        });
                      }, 200);
                    }}
                  >
                    Home
                  </span>
                </li>

                <li className="nav-item">
                  <span
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatch(setOpenSearch(true));
                      dispatch(
                        setHolidayType({
                          flight: true,
                          accommodation: true,
                        })
                      );
                      dispatch(setView(views.searchview));
                      setShowMenu(false);
                      setTimeout(() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                        });
                      }, 200);
                    }}
                  >
                    Pauschalreisen
                  </span>
                </li>

                <li className="nav-item">
                  <span
                    className="nav-link "
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatch(setOpenSearch(true));
                      dispatch(
                        setHolidayType({
                          flight: false,
                          accommodation: true,
                        })
                      );
                      dispatch(setView(views.searchview));
                      setShowMenu(false);
                      setTimeout(() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                        });
                      }, 200);
                    }}
                  >
                    Nur Unterkunft
                  </span>
                </li>

                {/*<li className="nav-item">
                  <span
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatch(setOpenSearch(true));
                      dispatch(
                        setHolidayType({
                          flight: true,
                          accommodation: false,
                        })
                      );
                      dispatch(setView(views.searchview));
                      setShowMenu(false);
                      setTimeout(() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                        });
                      }, 200);
                    }}
                  >
                    Nur Flug
                  </span>
                </li>*/}

                {agencyproperties?.properties?.flylink && (
                  <li className="nav-item">
                    <span
                      style={{ cursor: "pointer" }}
                      className="nav-link "
                      onClick={() => {
                        dispatch(setView(views.flyview));
                        setShowMenu(false);
                        window.scrollTo(0, 0);
                      }}
                    >
                      Nur Flüge
                    </span>
                  </li>
                )}
                {agencyproperties?.properties?.fewolink && (
                  <li className="nav-item">
                    <span
                      style={{ cursor: "pointer" }}
                      className="nav-link "
                      onClick={() => {
                        dispatch(setView(views.fewoview));
                        setShowMenu(false);
                        window.scrollTo(0, 0);
                      }}
                    >
                      Ferienwohnungen
                    </span>
                  </li>
                )}
                {agencyproperties?.properties?.incllink && (
                  <li className="nav-item">
                    <span
                      style={{ cursor: "pointer" }}
                      className="nav-link "
                      onClick={() => {
                        dispatch(setView(views.inclview));
                        setShowMenu(false);
                        window.scrollTo(0, 0);
                      }}
                    >
                      Inclusions Reisen
                    </span>
                  </li>
                )}                
                {agencyproperties?.properties?.cruiselink && (
                  <li className="nav-item">
                    <span
                      style={{ cursor: "pointer" }}
                      className="nav-link "
                      onClick={() => {
                        dispatch(setView(views.cruiseview));
                        setShowMenu(false);
                        setTimeout(() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                          });
                        }, 200);
                      }}
                    >
                      Kreuzfahrten
                    </span>
                  </li>
                )}

                {agencyproperties?.properties?.register &&
                  (cookiesconsent?.cookieconsent === "true" || nativeapp) && (
                    <li className="nav-item">
                      <span
                        className="nav-link "
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setMiscpage("register");
                          dispatch(setView(views.miscview));
                          setShowMenu(false);
                          setTimeout(() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                            });
                          }, 200);
                        }}
                      >
                        mein Konto
                      </span>
                    </li>
                  )}
                {agencyproperties?.properties?.register &&
                  (cookiesconsent?.cookieconsent === "true" || nativeapp) && (
                    <li className="nav-item">
                      <span
                        className="nav-link "
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setMiscpage("contactform");
                          dispatch(setView(views.miscview));
                          setShowMenu(false);
                          setTimeout(() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                            });
                          }, 200);
                        }}
                      >
                        Kontaktformular
                      </span>
                    </li>
                  )}
                {agencyproperties?.properties?.register &&
                  (cookiesconsent?.cookieconsent === "true" || nativeapp) && (
                    <li className="nav-item">
                      <span
                        className="nav-link "
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          dispatch(setView(views.newsletterview));

                          setShowMenu(false);
                          setTimeout(() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                            });
                          }, 200);
                        }}
                      >
                        Newsletter
                      </span>
                    </li>
                  )}
                <li className="nav-item active">
                  <span
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setMiscpage("aboutus");
                      dispatch(setView(views.miscview));
                      setShowMenu(false);
                      setTimeout(() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                        });
                      }, 200);
                    }}
                  >
                    über uns
                  </span>
                </li>
                <li className="nav-item active">
                  <span
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setMiscpage("faqs");
                      dispatch(setView(views.miscview));
                      setShowMenu(false);
                      setTimeout(() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                        });
                      }, 200);
                    }}
                  >
                    FAQs
                  </span>
                </li>
                <li className="nav-item active">
                  <span
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setMiscpage("impressum");
                      dispatch(setView(views.miscview));
                      setShowMenu(false);
                      setTimeout(() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                        });
                      }, 200);
                    }}
                  >
                    Impressum
                  </span>
                </li>
                <li className="nav-item active">
                  <span
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setMiscpage("agbs");
                      dispatch(setView(views.miscview));
                      setShowMenu(false);
                    }}
                  >
                    AGB's (v {process.env.REACT_APP_VERSION})
                  </span>
                </li>
                <li className="nav-item active">
                  <span
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setMiscpage("datenschutz");
                      dispatch(setView(views.miscview));
                      setShowMenu(false);
                      setTimeout(() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                        });
                      }, 200);
                    }}
                  >
                    Datenschutz
                  </span>
                </li>

                <li className="nav-item active">
                  <span
                    className="nav-link"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setMiscpage("contact");
                      dispatch(setView(views.miscview));
                      setShowMenu(false);
                      setTimeout(() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                        });
                      }, 200);
                    }}
                  >
                    Kontakt
                  </span>
                </li>
              </ul>
            </div>
          )}
        </div>
      </nav>
      {!(
        view === views.miscview ||
        view === views.fewoview ||
        view === views.inclview ||
        view === views.flyview ||
        view === views.cruiseview
      ) && (
          <div className="container bg-body-tertiary">
            <div style={{ height: 98 }} className="bg-body-tertiary"></div>
            {view === views.searchview && (
              <>
                <Header />

                <SearchFullView />
              </>
            )}
            {view === views.offerview && (
              <>
                <Header />
                <MobileOffersView></MobileOffersView>
              </>
            )}

            {view === views.flightsview && (
              <>
                <Header />
                <MobileFlightsView></MobileFlightsView>
              </>
            )}
            {view === views.hotelcrossview && (
              <>
                <Header />
                <MobileCrossView></MobileCrossView>
              </>
            )}
            {view === views.hotelview && (
              <>
                <Header />
                <MobileHotelView></MobileHotelView>
              </>
            )}
            {view === views.bookingview && (
              <>
                <Header />
                <MobileBookingView ref={bookingref} />
              </>
            )}
            {view === views.confirmationview && (
              <>
                <Header />
                <MobileConfirmationView />
              </>
            )}
          </div>
        )}
      {view === views.miscview && (
        <div style={{ marginTop: 52 }}>
          <div
            onClick={(e) => {
              //console.log(e.target.nodeName);
              clickHandler(e);
            }}
            dangerouslySetInnerHTML={{ __html: miscpages[miscpage] }}
          ></div>
        </div>
      )}
      {views.fewoview === view && (
        <>
          <div style={{ height: 60 }} className="bg-body-tertiary"></div>
          <iframe
            title="fewoframe"
            className="iframe-fullwidth"
            width="100%"
            id="proFewoIFrame"
            src={agencyproperties?.properties?.fewolink}
            style={{ height: "4813px" }}
          ></iframe>
        </>
      )}
      {views.inclview === view && (
        <>
          <div style={{ height: 60 }} className="bg-body-tertiary"></div>
          <iframe
            title="inclframe"
            className="iframe-fullwidth"
            width="100%"
            id="proInclIFrame"
            src={agencyproperties?.properties?.incllink}
            style={{ height: "4813px" }}
          ></iframe>
        </>
      )}      
      {views.flyview === view && (
        <>
          {agencyproperties?.properties?.flylink && (
            <div
              ref={ref}
              id="ypsnet-ibe"
              data-src={agencyproperties?.properties?.flylink}
            ></div>
          )}
        </>
      )}

      {views.cruiseview === view && (
        <>
          <div style={{ height: 60 }} className="bg-body-tertiary"></div>
          <iframe
            id="xtibe"
            title="cruiseframe"
            scrolling="auto"
            border="0"
            src={agencyproperties?.properties?.cruiselink}
            seamless=""
            frameborder="0"
            width="100%"
            sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-modals"
            style={{ height: "3844px" }}
          ></iframe>
        </>
      )}
      {!(
        view === views.miscview ||
        view === views.fewoview ||
        view === views.inclview ||
        view === views.flyview ||
        view === views.cruiseview
      ) && <div style={{ height: 50 }} className="bg-body-tertiary"></div>}
    </div>
  );
}

export default App;
