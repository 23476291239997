import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Popup from "reactjs-popup";
import { BsSuitHeartFill, BsSuitHeart } from "react-icons/bs";

import { MdContentCopy } from "react-icons/md";
import { FiUpload, FiShare2 } from "react-icons/fi";
import { BsWhatsapp } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { RiCloseFill } from "react-icons/ri";

import { getLink2Share, setOfferMemo } from "amadeusredux/lib/api";

import { setMarked } from "amadeusredux/lib/offersSlice";
import { sendLink2Agency } from "amadeusredux/lib/bookingSlice";

export default function ShareOptions(props) {
  const dispatch = useDispatch();
  const [offerSelect, setOfferSelect] = React.useState({});
  const [uploadStep, setUploadStep] = React.useState(0);
  const [shareLink, setShareLink] = React.useState({});
  const [uploadOpen, setUploadOpen] = React.useState(false);
  const [requestData, setRequestData] = React.useState({
    name: "",
    mail: "",
    phone: "",
    remark: "",
  });

  const { offer, sessionId, offerInfos, hotel } = props;

  return (
    <div className="d-flex">
      <div style={{ width: "20px", height: "20px" }}>
        <svg
          style={{
            width: "30px",
            height: "20px",
            fontSize: "16pt",
            position: "absolute",
            cursor: "pointer",
          }}
        >
          <defs>
            <linearGradient id="testGradient" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor="rgba(0,0,0,0.5)" />
              <stop offset="60%" stopColor="rgba(255,255,255,1)" />
            </linearGradient>
          </defs>
          <BsSuitHeartFill
            style={{ zIndex: "1" }}
            fill="url(#testGradient)"
            viewBox="-1 0 18 17"
          ></BsSuitHeartFill>
        </svg>
        <svg
          style={{
            width: "30px",
            height: "20px",
            fontSize: "16pt",
            position: "absolute",
            cursor: "pointer",
          }}
        >
          <BsSuitHeartFill
            className={
              Array.isArray(offer.MarketingActions) &&
              offer.MarketingActions.length > 0 &&
              offer.MarketingActions[0].marked
                ? "heartfill"
                : ""
            }
            fill="transparent"
            strokeWidth="10%"
            viewBox="-1 0 18 17"
            onClick={() => {
              setOfferMemo(
                offer.OfferID,
                hotel?.HotelCodes?.HotelGiataID,
                sessionId,
                dispatch,
                offerInfos,
                offer
              );
              dispatch(
                setMarked({
                  offerId: offer.OfferID,
                  marked: !(
                    Array.isArray(offer.MarketingActions) &&
                    offer.MarketingActions.length > 0 &&
                    offer.MarketingActions[0].marked
                  ),
                })
              );
            }}
          ></BsSuitHeartFill>
        </svg>
      </div>
      <FiUpload
        className="text-black ms-3"
        style={{
          fontSize: "16pt",
          height: "20px",
          width: "20px",
        }}
        onClick={() => {
          setOfferSelect(offer);
          setUploadOpen(true);
        }}
      ></FiUpload>
      <div
        className=" ms-3"
        style={{
          height: "20px",
          width: "20px",
          cursor: "pointer",
        }}
        id="shareicon"
        onClick={() => {
          getLink2Share(offer.OfferID, sessionId, offerInfos, offer)
            .then((response) => response.json())
            .then((data) => {
              //console.log(data?.ret[0]?.link);

              setShareLink(data?.ret[0] ? data?.ret[0] : {});
            })
            .catch((e) => console.log(e));
        }}
      >
        <Popup
          position="right center"
          trigger={(open) => (
            <div style={{ height: "20px" }}>
              <FiShare2
                className="align-baseline text-black ml-1"
                style={{
                  fontSize: "15pt",
                }}
                onClick={() => {
                  getLink2Share(offer.OfferID, sessionId, offerInfos, offer)
                    .then((response) => response.json())
                    .then((data) => {
                      //console.log(data?.ret[0]?.link);

                      setShareLink(data?.ret[0] ? data?.ret[0] : {});
                    })
                    .catch((e) => console.log(e));
                }}
              />
            </div>
          )}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "5px 5px 5px 5px",
            }}
            className="p-2"
          >
            <MdContentCopy
              style={{
                fontSize: "16pt",
                color: "black",
                marginLeft: "4px",
                marginRight: "4px",
              }}
              title="Zwischenablage"
              className="shareicon"
              onClick={() => navigator.clipboard.writeText(shareLink.link)}
            />{" "}
            <a
              target="_blank"
              className="shareicona"
              href={
                "https://wa.me/?text=" +
                encodeURIComponent(shareLink.link + " " + shareLink.info)
              }
            >
              <BsWhatsapp
                style={{
                  fontSize: "16pt",
                  color: "black",
                  marginLeft: "4px",
                  marginRight: "4px",
                }}
                title="WhatsApp"
                className="shareicon"
              />
            </a>
            <a
              target="_blank"
              className="shareicona"
              href={`mailto:?body=Dieses%20Angebot%20habe%20ich%20für%20Dich%20ausgesucht:%0A%0A${encodeURIComponent(
                shareLink.link
              )}%0A&subject=Unsere%20Reiseplanung`}
            >
              <AiOutlineMail
                style={{
                  fontSize: "16pt",
                  color: "black",
                  marginLeft: "4px",
                  marginRight: "4px",
                }}
                title="Email"
                className="shareicon"
              />
            </a>
          </div>
        </Popup>
      </div>
      <Popup
        modal
        closeOnDocumentClick={false}
        onOpen={() =>
          getLink2Share(offerSelect.OfferID, sessionId, offerInfos, offerSelect)
            .then((response) => response.json())
            .then((data) => {
              //console.log(data);
              setShareLink(data?.ret[0] ? data?.ret[0] : {});
            })
            .catch((e) => console.log(e))
        }
        open={uploadOpen}
      >
        <div className="bg-white m-1 p-1 border border-primary rounded">
          <div className="d-flex justify-content-end">
            <div
              className="d-flex bg-white rounded-circle border border-primary justify-content-end"
              style={{
                height: "30px",
                width: "30px" /*Nachfragen*/,
              }}
            >
              <RiCloseFill
                onClick={() => {
                  setUploadOpen(false);
                  setUploadStep(0);
                }}
                style={{
                  fontSize: "1.5rem",
                }}
                className="m-auto"
              />
            </div>
          </div>
          {uploadStep === 0 ? (
            <>
              <div className="m-1" style={{ fontSize: "1.3em" }}>
                Wir machen Ihnen gerne ein personalisiertes Angebot zu Ihrer
                Traumreise
              </div>
              <div className="ms-1 me-1">
                <div>Name*</div>
                <input
                  className="p-1 w-100"
                  value={requestData.name}
                  onChange={(e) => {
                    setRequestData({
                      ...requestData,
                      name: e.target.value,
                    });
                  }}
                  type="text"
                ></input>
              </div>
              <div>
                <div className="ms-1 me-1">
                  <div>E-Mail**</div>
                  <input
                    className="p-1 w-100"
                    value={requestData.mail}
                    onChange={(e) => {
                      setRequestData({
                        ...requestData,
                        mail: e.target.value,
                      });
                    }}
                    type="text"
                  ></input>
                </div>
                <div className="flex-grow-1" />
                <div className="ms-1 me-1">
                  <div>Telefon**</div>
                  <input
                    className="p-1 w-100"
                    value={requestData.phone}
                    onChange={(e) => {
                      setRequestData({
                        ...requestData,
                        phone: e.target.value,
                      });
                    }}
                    type="text"
                  ></input>
                </div>
              </div>
              <div className="ms-1 me-1">
                <div>Zusätzliche Informationen</div>
                <textarea
                  className="p-1 w-100"
                  onChange={(e) => {
                    setRequestData({
                      ...requestData,
                      remark: e.target.value,
                    });
                  }}
                  value={requestData.remark}
                  type="text"
                ></textarea>
              </div>
              <div className="ms-1 me-1" style={{ fontSize: "0.8em" }}>
                *erforderliche Daten
              </div>
              <div className="ms-1 me-1" style={{ fontSize: "0.8em" }}>
                **Mail oder Telefon erforderlich
              </div>
              <div className="d-flex justify-content-center">
                <button
                  disabled={
                    !requestData.name ||
                    (!requestData.mail && !requestData.phone)
                  }
                  className={
                    "w-50 p-1 border rounded m-1 text-white " +
                    (requestData.name && (requestData.mail || requestData.phone)
                      ? "bg-primary"
                      : "bg-muted")
                  }
                  onClick={() => {
                    setUploadStep(1);
                    /*console.log({
                      ...requestData,
                      link: shareLink.link,
                    });*/
                    dispatch(
                      sendLink2Agency({
                        ...requestData,
                        link: shareLink.link,
                      })
                    );
                  }}
                >
                  Senden
                </button>
              </div>
            </>
          ) : (
            <div>
              <div className="d-flex justify-content-center align-items-center">
                {" "}
                <svg
                  className="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  {" "}
                  <circle
                    className="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />{" "}
                  <path
                    className="checkmark__check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  />
                </svg>
              </div>
              <div className="text-center" style={{ fontSize: "1.3em" }}>
                Vielen Dank
              </div>
              <div className="text-center" style={{ fontSize: "1.3em" }}>
                Ihre Anfrage wird schnellst möglich bearbeitet
              </div>
              <div className="d-flex justify-content-center">
                <button
                  className="w-50 p-1 border rounded m-1 text-white bg-primary"
                  onClick={() => {
                    setUploadStep(0);
                    setUploadOpen(false);
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          )}
        </div>
      </Popup>
    </div>
  );
}
