import React from "react";
import { roomTypes } from "../utils";

import { useDispatch, useSelector } from "react-redux";
import { views } from "amadeusredux/lib/utils";

import { FaInfoCircle } from "react-icons/fa";
import InfoPopupHover from "../components/InfoPopupHover";
import { setSearchOption } from "amadeusredux/lib/searchSlice";
import { getFilters } from "amadeusredux/lib/api";

export default function RoomTypeFilter(props) {
  const { changeFilterAndSearch, changeClientFilter } = props;
  const dispatch = useDispatch();

  const filteredItems = useSelector((state) => state.filter.roomTypes);
  const view = useSelector((state) => state.system.view);
  const loading = useSelector((state) => state.system.loading);
  const roomType = useSelector((state) => state.search.roomType);
  const [showAll, setShowAll] = React.useState(false);
  const specialRoomType = useSelector((state) => state.search.specialRoomType);
  const specialRoomTypes = useSelector(
    (state) => state.offers.specialRoomTypes
  );
  const specialAmount = useSelector((state) => state.offers.specialAmount);
  const hotelSpecialAmount = useSelector(
    (state) => state.hotel.hotelSpecialAmount
  );
  const hotelSpecialRoomTypes = useSelector(
    (state) => state.hotel.hotelSpecialRoomTypes
  );
  const debug = useSelector((state) => state.system.debug);
  const infos = useSelector((state) => state.properties.infos);
  let ct = 0;
  return (
    <div>
      <span className="fw-semibold">Zimmerart</span>
      {roomTypes.map((itm, kidx) => {
        let disabled = false;
        let f = filteredItems.find((a) => a.Value === itm.value);
        if (f) {
          if (f.HotelCount === 0) {
            disabled = true;
            //return <></>;
          }
        } else {
          disabled = true;
          f = { HotelCount: 0 };
        }
        if (roomType.includes(itm.value)) {
          disabled = false;
        }
        if (loading > 0) disabled = true;

        if (f.HotelCount > 0 || roomType.includes(itm.value)||
        roomType.length === 0) ct = ct + 1;
        if (ct > 5 && !showAll) return null;
        if (roomType.length === 0) disabled = false;
        return (
          <React.Fragment key={kidx}>
            {(f.HotelCount > 0 ||
              roomType.includes(itm.value) ||
              roomType.length === 0) && (
              <div key={itm.value}>
                <div style={{ display: "flex" }} className="mb-2">
                  &nbsp;
                  <input
                    checked={roomType.includes(itm.value)}
                    onChange={() => {
                      let changed = [...roomType];
                      if (changed.includes(itm.value)) {
                        changed = changed.filter((e) => e !== itm.value);
                      } else {
                        changed.push(itm.value);
                      }

                      dispatch(
                        setSearchOption({
                          property: "roomType",
                          data: changed,
                        })
                      );

                      getFilters(dispatch, false);
                    }}
                    disabled={disabled}
                    type="checkbox"
                  />
                  &nbsp;
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        "" +
                        itm.label +
                        (view === views.offerview
                          ? " (" + f.HotelCount + ")"
                          : ""),
                    }}
                  ></div>
                </div>
              </div>
            )}
          </React.Fragment>
        );
      })}
      {view === views.offerview &&
        specialRoomTypes.map((srt) => {
          let amount = specialAmount.find((e) => srt === e.value);
          return (
            <>
              {amount?.amount && (
                <div>
                  <div style={{ display: "flex" }}>
                    &nbsp;
                    <input
                      checked={specialRoomType.includes(srt)}
                      onChange={() => {
                        let changed = [...specialRoomType];
                        if (changed.includes(srt)) {
                          changed = changed.filter((e) => e !== srt);
                        } else {
                          changed.push(srt);
                        }

                        dispatch(
                          setSearchOption({
                            property: "specialRoomType",
                            data: changed,
                          })
                        );

                        getFilters(dispatch, false);
                      }}
                      type="checkbox"
                    />
                    &nbsp;
                    <div
                      className="filtertext"
                      dangerouslySetInnerHTML={{
                        __html: "" + srt + " (" + amount?.amount + ")",
                      }}
                    >
                      {" "}
                    </div>
                    <InfoPopupHover
                      trigger={
                        <span>
                          <FaInfoCircle className="filterinfo" />
                        </span>
                      }
                      content={infos.roomtypinfo}
                      position={"right center"}
                    ></InfoPopupHover>
                  </div>
                </div>
              )}
            </>
          );
        })}
      {view !== views.offerview &&
        hotelSpecialRoomTypes.map((srt) => {
          let amount = hotelSpecialAmount.find((e) => srt === e.value);
          return (
            <>
              {amount?.amount && (
                <div>
                  <div style={{ display: "flex" }}>
                    &nbsp;
                    <input
                      checked={specialRoomType.includes(srt)}
                      onChange={() => {
                        let changed = [...specialRoomType];
                        if (changed.includes(srt)) {
                          changed = changed.filter((e) => e !== srt);
                        } else {
                          changed.push(srt);
                        }

                        dispatch(
                          setSearchOption({
                            property: "specialRoomType",
                            data: changed,
                          })
                        );

                        getFilters(dispatch, false);
                      }}
                      type="checkbox"
                    />
                    &nbsp;
                    <div
                      className="filtertext"
                      dangerouslySetInnerHTML={{
                        __html: "" + srt + " (" + amount?.amount + ")",
                      }}
                    ></div>
                    <InfoPopupHover
                      trigger={
                        <span>
                          <FaInfoCircle className="filterinfo" />
                        </span>
                      }
                      content={infos.roomtypinfo}
                      position={"right center"}
                    ></InfoPopupHover>
                  </div>
                </div>
              )}
            </>
          );
        })}
      {!showAll && ct > 5 && (
        <div style={{ cursor: "pointer" }} onClick={() => setShowAll(true)}>
          alle anzeigen
        </div>
      )}
      {showAll && (
        <div style={{ cursor: "pointer" }} onClick={() => setShowAll(false)}>
          weniger anzeigen
        </div>
      )}
    </div>
  );
}
