import {
  createSlice,
  createSelector,
  createAsyncThunk,
  isRejectedWithValue,
} from "@reduxjs/toolkit";

import { v4 as uuidv4 } from "uuid";

import axios from "axios";

axios.defaults.headers.common["AgencyID"] = window.configData.AGENCYID;
axios.defaults.headers.common["Version"] = process.env.REACT_APP_VERSION;
axios.defaults.headers.common["App"] = window.configData.APP;

export const verifyFare = createAsyncThunk(
  "flight/verifyfare",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.post(
        "https://www.tstsrv.de/ratehawkapi/api/airtuerk/verify",
        {
          availableFareWebServiceLogIdReference:
            payload.offer.fare.webServiceLogId,
          fareid: payload.offer.fare.id,
          segments: payload.offer.OfferID.split("|"),
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchFares = createAsyncThunk(
  "flight/fetchfares",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();
    if (state.search.stations.length < 2) {
      return thunkAPI.rejectWithValue({
        error: "departure and destination required",
      });
    }
    let segments = [
      {
        date: state.search.travelDate.from.replaceAll('"', "").split("T")[0],
        departure: state.search.stations[0].value,
        destination: state.search.stations[1].value,
      },
    ];

    if (!state.search.oneway) {
      segments.push({
        date: state.search.travelDate.to.replaceAll('"', "").split("T")[0],
        departure: state.search.stations[1].value,
        destination: state.search.stations[0].value,
      });
    }
    let queryid = uuidv4();
    try {
      const response = await axios.post(
        "https://www.tstsrv.de/ratehawkapi/api/airtuerk",
        {
          adults: state.search.adults.value * 1,
          children:
            state.search.children.value * 1 > 0
              ? state.search.children.value * 1
              : undefined,
          directflight: state.search.directflight,
          nofrill: state.search.nofrill,
          fsciata: state.search.fsciata,
          fscnego: state.search.fscnego,
          fscconso: state.search.fscconso,
          fsccorp: state.search.fsccorp,
          queryid: queryid,
          promosession: state.system.sessionId,
          firstclass: state.search.firstclass,
          verifiedfares: state.search.verifiedfares,
          economyclass: state.search.economyclass,
          businessclass: state.search.businessclass,
          maxstops: state.search.maxstops,
          baggageonly: state.search.baggageonly,
          segments: segments,
        }
      );

      return { data: response.data, queryid: queryid };
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const initialState = {
  fares: [],
  offers: [],
  queryid: "",
};

export const flightSlice = createSlice({
  name: "flight",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchFares.pending, (state, action) => {
      state.fares = [];
      state.offers = [];
    });
    builder.addCase(fetchFares.fulfilled, (state, { payload, meta }) => {
      state.queryid = payload.queryid;
      state.fares = payload.data.fares;
      state.offers = payload.data.offers;
    });
    builder.addCase(fetchFares.rejected, (state, action) => {
      state.fares = [];
      state.offers = [];
    });

    builder.addCase(verifyFare.pending, (state, action) => {});
    builder.addCase(verifyFare.fulfilled, (state, { payload, meta }) => {
      state.offers = state.offers.map((e) =>
        e.OfferID === meta.arg.offer.OfferID
          ? { ...e, verifiedFare: payload }
          : { ...e }
      );
    });
    builder.addCase(verifyFare.rejected, (state, action) => {});
  },
});

export const {} = flightSlice.actions;

export default flightSlice.reducer;
