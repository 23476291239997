import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { getFilters } from "amadeusredux/lib/api";
import { setSearchOption } from "amadeusredux/lib/searchSlice";

export default function AirlineFilter(props) {
  const dispatch = useDispatch();

  const [showAll, setShowAll] = React.useState(false);
  const view = useSelector((state) => state.system.view);
  const loading = useSelector((state) => state.system.loading);
  const airlines = useSelector((state) => state.properties.airlines);

  const nolowcost = useSelector((state) => state.search.nolowcost);
  const linie = useSelector((state) => state.search.linie);
  const airlinerank = useSelector((state) => state.search.airlinerank);

  const transportOperators = useSelector(
    (state) => state.filter.transportOperators
  );
  const operatingbrand = useSelector((state) => state.search.operatingbrand);

  let destinationAirlines = transportOperators.map((e) => {
    let a = airlines.find((f) => e.value === f.value);
    return { ...e, properties: a ? a.properties : {} };
  });

  let selectedAirlines = [...destinationAirlines];

  if (nolowcost) {
    let filterAirlines = airlines
      .filter((e) => e.properties?.type !== "Low Cost")
      .map((e) => e.value);
    selectedAirlines = selectedAirlines.filter((e) =>
      filterAirlines.includes(e.value)
    );
  }
  if (linie) {
    let filterAirlines = airlines
      .filter((e) => e.properties?.type === "Linie")
      .map((e) => e.value);
    selectedAirlines = selectedAirlines.filter((e) =>
      filterAirlines.includes(e.value)
    );
  }
  if (airlinerank) {
    let filterAirlines = airlines
      .filter((e) => e.properties?.ranking < 3)
      .map((e) => e.value);
    selectedAirlines = selectedAirlines.filter((e) =>
      filterAirlines.includes(e.value)
    );
  }

  let ct = 0;

  return (
    <div>
      <div className="fw-semibold">Fluggesellschaft</div>

      {[...selectedAirlines]
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((itm) => {
          if (
            !(
              transportOperators.map((e) => e.value).includes(itm.value) ||
              operatingbrand.includes(itm.value)
            )
          )
            return <></>;

          ct = ct + 1;

          if (ct > 5 && !showAll) return null;

          return (
            <>
              <div style={{ display: "flex" }} key={itm.value} className="mb-2">
                &nbsp;
                <input
                  checked={operatingbrand.includes(itm.value)}
                  onChange={() => {
                    let changed = [...operatingbrand];
                    if (changed.includes(itm.value)) {
                      changed = changed.filter((e) => e !== itm.value);
                    } else {
                      changed.push(itm.value);
                    }

                    changed = changed.filter((c) =>
                      transportOperators.map((to) => to.value).includes(c)
                    );

                    dispatch(
                      setSearchOption({
                        property: "operatingbrand",
                        data: changed,
                      })
                    );

                    getFilters(dispatch, false);
                  }}
                  type="checkbox"
                />
                &nbsp;
                <div
                  className="filtertext"
                  dangerouslySetInnerHTML={{
                    __html: "" + itm.label + " (" + itm.properties?.type + ")",
                  }}
                ></div>
              </div>
            </>
          );
        })}
      {!showAll && ct > 5 && (
        <div style={{ cursor: "pointer" }} onClick={() => setShowAll(true)}>
          alle anzeigen
        </div>
      )}
      {showAll && (
        <div style={{ cursor: "pointer" }} onClick={() => setShowAll(false)}>
          weniger anzeigen
        </div>
      )}
    </div>
  );
}
