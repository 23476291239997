import React from "react";

import { MdAirplanemodeActive } from "react-icons/md";
import { FaInfoCircle, FaPlane } from "react-icons/fa";

import { parse } from "iso8601-duration";
import { FormattedMessage, FormattedNumberParts, useIntl } from "react-intl";
import InfoPopupHover from "../components/InfoPopupHover";

export default function OfferFlight(props) {
  const { offer, stations, verbose, horizontal, setPackageInfo, packageInfo } =
    props;

  const intl = useIntl();

  const [baggageOpen, setBaggageOpen] = React.useState(false);
  //console.log(intl.locale);
  const df = new Intl.DateTimeFormat(intl.locale, {
    weekday: "short",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const df2 = new Intl.DateTimeFormat(intl.locale, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  const tf = new Intl.DateTimeFormat(intl.locale, {
    hour: "2-digit",
    minute: "2-digit",
  });

  let checked = false;
  if (offer?.bookinginfo) checked = true;

  //console.log(offer?.bookinginfo);

  const outboundFlight = offer?.OfferServices?.Package?.Flight?.OutboundFlight;
  const inboundFlight = offer?.OfferServices?.Package?.Flight?.InboundFlight;

  let outboundWeight = 0;
  if (
    Array.isArray(
      offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments
        ?.FlightSegment
    ) &&
    offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments
      ?.FlightSegment[0]?.BaggageAllowance?.MaxWeight
  ) {
    outboundWeight =
      offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments
        ?.FlightSegment[0]?.BaggageAllowance?.MaxWeight * 1;
  } else if (
    offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments
      ?.FlightSegment?.BaggageAllowance?.MaxWeight
  ) {
    outboundWeight =
      offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments
        ?.FlightSegment?.BaggageAllowance?.MaxWeight * 1;
  }

  let outboundBags = 1;
  if (
    Array.isArray(
      offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments
        ?.FlightSegment
    ) &&
    offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments
      ?.FlightSegment[0]?.BaggageAllowance?.MaxBags
  ) {
    outboundBags =
      offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments
        ?.FlightSegment[0]?.BaggageAllowance?.MaxBags * 1;
  } else if (
    offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments
      ?.FlightSegment?.BaggageAllowance?.MaxBags
  ) {
    outboundBags =
      offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments
        ?.FlightSegment?.BaggageAllowance?.MaxBags * 1;
  }

  let inboundWeight = 0;

  if (
    Array.isArray(
      offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments
        ?.FlightSegment
    ) &&
    offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments
      ?.FlightSegment[0]?.BaggageAllowance?.MaxWeight
  ) {
    inboundWeight =
      offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments
        ?.FlightSegment[0]?.BaggageAllowance?.MaxWeight * 1;
  } else if (
    offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments
      ?.FlightSegment?.BaggageAllowance?.MaxWeight
  ) {
    inboundWeight =
      offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments
        ?.FlightSegment?.BaggageAllowance?.MaxWeight * 1;
  }

  let inboundBags = 1;
  if (
    Array.isArray(
      offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments
        ?.FlightSegment
    ) &&
    offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments
      ?.FlightSegment[0]?.BaggageAllowance?.MaxBags
  ) {
    inboundBags =
      offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments
        ?.FlightSegment[0]?.BaggageAllowance?.MaxBags * 1;
  } else if (
    offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments
      ?.FlightSegment?.BaggageAllowance?.MaxBags
  ) {
    inboundBags =
      offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments
        ?.FlightSegment?.BaggageAllowance?.MaxBags * 1;
  }

  let stopOversOutbound = [];
  let stopOversInbound = [];
  if (offer?.bookinginfo) {
    if (
      Array.isArray(
        offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments
      )
    ) {
      if (
        Array.isArray(
          offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments[0]
            .Stopover
        )
      ) {
        stopOversOutbound = [
          ...offer?.bookinginfo?.Offer?.Package?.Flight
            ?.OutboundFlightSegments[0].Stopover,
        ];
      } else {
        stopOversOutbound = [
          {
            ...offer?.bookinginfo?.Offer?.Package?.Flight
              ?.OutboundFlightSegments[0].Stopover,
          },
        ];
      }
    } else {
      if (
        Array.isArray(
          offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments
            ?.Stopover
        )
      ) {
        stopOversOutbound = [
          ...offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments
            ?.Stopover,
        ];
      } else {
        stopOversOutbound = [
          {
            ...offer?.bookinginfo?.Offer?.Package?.Flight
              ?.OutboundFlightSegments?.Stopover,
          },
        ];
      }
    }
  }
  if (offer?.bookinginfo) {
    if (
      Array.isArray(
        offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments
      )
    ) {
      if (
        Array.isArray(
          offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments[0]
            .Stopover
        )
      ) {
        stopOversInbound = [
          ...offer?.bookinginfo?.Offer?.Package?.Flight
            ?.InboundFlightSegments[0].Stopover,
        ];
      } else {
        stopOversInbound = [
          {
            ...offer?.bookinginfo?.Offer?.Package?.Flight
              ?.InboundFlightSegments[0].Stopover,
          },
        ];
      }
    } else {
      if (
        Array.isArray(
          offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments
            ?.Stopover
        )
      ) {
        stopOversInbound = [
          ...offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments
            ?.Stopover,
        ];
      } else {
        stopOversInbound = [
          {
            ...offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments
              ?.Stopover,
          },
        ];
      }
    }
  }

  let outboundFlightnumber = offer?.bookinginfo?.Offer?.Package?.Flight
    ?.OutboundFlightSegments?.FlightSegment?.FlightNumber
    ? "" +
      offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments
        ?.FlightSegment?.CarrierCode +
      offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments
        ?.FlightSegment?.FlightNumber
    : "";

  let inboundFlightnumber = offer?.bookinginfo?.Offer?.Package?.Flight
    ?.InboundFlightSegments?.FlightSegment?.FlightNumber
    ? "" +
      offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments
        ?.FlightSegment?.CarrierCode +
      offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments
        ?.FlightSegment?.FlightNumber
    : "";

  let outboundAirline = outboundFlight?.Airline
    ? outboundFlight.Airline.AirlineName
    : "";

  let inboundAirline = inboundFlight?.Airline
    ? inboundFlight.Airline.AirlineName
    : "";

  if (offer?.bookinginfo) {
    let tmpfs =
      Array.isArray(
        offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments
      ) &&
      offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments.length >
        0
        ? offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments[0]
        : offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments;
    if (!tmpfs) tmpfs = [];

    tmpfs =
      Array.isArray(tmpfs?.FlightSegment) && tmpfs?.FlightSegment.length > 0
        ? tmpfs.FlightSegment[0]
        : tmpfs.FlightSegment;

    if (tmpfs?.Airline?._) {
      inboundAirline = tmpfs.Airline._;
    }
    if (!tmpfs) tmpfs = [];
    tmpfs =
      Array.isArray(
        offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments
      ) &&
      offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments
        .length > 0
        ? offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments[0]
        : offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments;
    if (!tmpfs) tmpfs = [];
    tmpfs =
      Array.isArray(tmpfs?.FlightSegment) && tmpfs?.FlightSegment.length > 0
        ? tmpfs.FlightSegment[0]
        : tmpfs.FlightSegment;
    if (tmpfs?.Airline?._) {
      outboundAirline = tmpfs.Airline._;
    }
  }

  let airports = stations?.Station;

  if (!airports) airports = [];

  let outboundAirport = {};
  if (outboundFlight?.FlightDeparture) {
    outboundAirport = airports.find(
      (e) =>
        e.StationCode ===
        outboundFlight.FlightDeparture.DepartureAirportRef.AirportCode
    );
  }

  let inboundAirport = {};
  if (inboundFlight?.FlightDeparture) {
    inboundAirport = airports.find(
      (e) =>
        e.StationCode ===
        outboundFlight.FlightArrival.ArrivalAirportRef.AirportCode
    );
  }

  let outboundAirportName = outboundAirport?.Name;
  let inboundAirportName = inboundAirport?.Name;

  outboundAirportName = offer?.bookinginfo?.Offer?.Package?.Flight
    ?.OutboundFlightSegments?.DepartureAirport?._
    ? offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments
        ?.DepartureAirport?._
    : outboundAirportName;

  inboundAirportName = offer?.bookinginfo?.Offer?.Package?.Flight
    ?.OutboundFlightSegments?.DepartureAirport?._
    ? offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments
        ?.DepartureAirport?._
    : inboundAirportName;

  let outboundFlightSegments = {};
  let inboundFlightSegments = {};

  let outboundduration = -1;
  if (
    offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments
      ?.FlightSegment?.FlightDuration === "0"
  ) {
    outboundduration = 0;
  } else {
    if (
      offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments
        ?.FlightSegment?.FlightDuration
    ) {
      outboundduration =
        offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments
          ?.FlightSegment?.FlightDuration * 1;
    }
  }

  let inboundduration = -1;
  if (
    offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments
      ?.FlightSegment?.FlightDuration === "0"
  ) {
    inboundduration = 0;
  } else {
    if (
      offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments
        ?.FlightSegment?.FlightDuration
    ) {
      inboundduration =
        offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments
          ?.FlightSegment?.FlightDuration * 1;
    }
  }

  if (
    offer?.bookinginfo &&
    offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments
  ) {
    outboundFlightSegments =
      Array.isArray(
        offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments
      ) &&
      offer?.bookinginfo?.Offer?.Package?.Flight?.OutboundFlightSegments
        .length > 0
        ? {
            ...offer?.bookinginfo?.Offer?.Package?.Flight
              ?.OutboundFlightSegments[0],
          }
        : {
            ...offer?.bookinginfo?.Offer?.Package?.Flight
              ?.OutboundFlightSegments,
          };
  }

  if (
    offer?.bookinginfo &&
    offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments
  ) {
    inboundFlightSegments =
      Array.isArray(
        offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments
      ) &&
      offer?.bookinginfo?.Offer?.Package?.Flight?.InboundFlightSegments.length >
        0
        ? {
            ...offer?.bookinginfo?.Offer?.Package?.Flight
              ?.InboundFlightSegments[0],
          }
        : {
            ...offer?.bookinginfo?.Offer?.Package?.Flight
              ?.InboundFlightSegments,
          };
  }

  if (
    inboundFlightSegments?.FlightSegment &&
    !Array.isArray(inboundFlightSegments?.FlightSegment)
  ) {
    inboundFlightSegments.FlightSegment = [inboundFlightSegments.FlightSegment];
  }

  if (
    outboundFlightSegments?.FlightSegment &&
    !Array.isArray(outboundFlightSegments?.FlightSegment)
  ) {
    outboundFlightSegments.FlightSegment = [
      outboundFlightSegments.FlightSegment,
    ];
  }

  let outbounddeparturedate = df.format(
    outboundFlight?.FlightDeparture.DepartureDate
      ? new Date(outboundFlight?.FlightDeparture.DepartureDate)
      : new Date()
  );
  let outbounddeparturetime = outboundFlight?.FlightDeparture.DepartureTime;
  let outboundarrivaldate = df.format(
    outboundFlight?.FlightArrival.ArrivalDate
      ? new Date(outboundFlight?.FlightArrival.ArrivalDate)
      : new Date()
  );
  let outboundarrivaltime = outboundFlight?.FlightArrival.ArrivalTime;

  let inbounddeparturedate = df.format(
    inboundFlight?.FlightDeparture.DepartureDate
      ? new Date(inboundFlight?.FlightDeparture.DepartureDate)
      : new Date()
  );
  let inbounddeparturetime = inboundFlight?.FlightDeparture.DepartureTime;
  let inboundarrivaldate = df.format(
    inboundFlight?.FlightArrival.ArrivalDate
      ? new Date(inboundFlight?.FlightArrival.ArrivalDate)
      : new Date()
  );
  let inboundarrivaltime = inboundFlight?.FlightArrival.ArrivalTime;

  if (outboundFlightSegments?.FlightSegment) {
    let start =
      outboundFlightSegments.FlightSegment[0].FlightDateTimeSpan.$.Start;
    let end =
      outboundFlightSegments.FlightSegment[
        outboundFlightSegments.FlightSegment.length - 1
      ].FlightDateTimeSpan.$.End;
    outbounddeparturedate = df.format(new Date(start));
    outbounddeparturetime = tf.format(new Date(start));
    outboundarrivaldate = df.format(new Date(end));
    outboundarrivaltime = tf.format(new Date(end));
  }

  if (inboundFlightSegments?.FlightSegment) {
    let start =
      inboundFlightSegments.FlightSegment[0].FlightDateTimeSpan.$.Start;
    let end =
      inboundFlightSegments.FlightSegment[
        inboundFlightSegments.FlightSegment.length - 1
      ].FlightDateTimeSpan.$.End;
    inbounddeparturedate = df.format(new Date(start));
    inbounddeparturetime = tf.format(new Date(start));
    inboundarrivaldate = df.format(new Date(end));
    inboundarrivaltime = tf.format(new Date(end));
  }

  let outbounddirect = outboundFlight?.DirectFlightAssumed;
  let inbounddirect = outboundFlight?.DirectFlightAssumed;

  if (outboundFlightSegments?.FlightSegment) {
    if (outboundFlightSegments.FlightSegment.length > 1) {
      outbounddirect = false;
    } else {
      outbounddirect = true;
    }
  }

  if (inboundFlightSegments?.FlightSegment) {
    if (inboundFlightSegments.FlightSegment.length > 1) {
      inbounddirect = false;
    } else {
      inbounddirect = true;
    }
  }

  let flightchanged = false;

  if (
    (outboundFlightSegments?.$?.SegmentsHaveChanged &&
      outboundFlightSegments.$.SegmentsHaveChanged === "true") ||
    (inboundFlightSegments?.$?.SegmentsHaveChanged &&
      inboundFlightSegments.$.SegmentsHaveChanged === "true")
  ) {
    flightchanged = true;
  }

  if (setPackageInfo) {
    //let travelweight =
    //  outboundWeight > inboundWeight ? inboundWeight : outboundWeight;
    //let travelbags = outboundBags > inboundBags ? inboundBags : outboundBags;
    let ib = "";
    let ob = "";
    if (outboundWeight > 0) {
      ob = "inkl.  " + outboundWeight + " kg Gepäck pro Person";
    } else {
      ob = "inkl.  " + outboundBags + " Gepäckstück(e) pro Person";
    }

    if (inboundWeight > 0) {
      ib = "inkl.  " + inboundWeight + " kg Gepäck pro Person";
    } else {
      ib = "inkl.  " + inboundBags + " Gepäckstück(e) pro Person";
    }
    if (
      ob !== packageInfo.outbound ||
      ib !== packageInfo.inbound ||
      packageInfo.offerId !== offer.OfferID
    )
      setPackageInfo({ outbound: ob, inbound: ib, offerId: offer.OfferID });
  }

  return (
    <>
      {(verbose || horizontal) && (
        <>
          <div
            class="d-flex flex-column"
            style={{
              marginBlock: "10px",
              display: horizontal ? "flex" : undefined,
              flexDirection: horizontal ? "row" : undefined,
            }}
          >
            <div class="d-flex">
              <div style={{ marginRight: "4px" }}>
                <FaPlane className="flighIconoutbound" />
              </div>
              <div class="d-flex flex-column">
                <div className="offerflight4">
                  <div
                    className="offerflighttime"
                    style={{ textAlign: "left", fontWeight: "600" }}
                    onClick={() => console.log(offer)}
                  >
                    {offer?.OfferServices?.Package?.Flight?.InboundFlight
                      ? "Hinflug"
                      : "Flug"}
                  </div>
                  <div class="d-flex">
                    <div
                      class="flex-grow-1"
                      style={{ textAlign: "left", fontWeight: "600" }}
                    >
                      {outbounddeparturetime}
                    </div>
                  </div>
                  <div
                    className="offerflight6 offerflightdate"
                    style={{
                      textAlign: "left",
                      whiteSpace: "nowrap",
                      fontWeight: "400",
                    }}
                  >
                    {outbounddeparturedate}
                  </div>
                </div>
                <div class="flex-grow-1"></div>
                <div className="offerflight8">
                  <div class="d-flex">
                    <div
                      class="flex-grow-1"
                      style={{ textAlign: "left", fontWeight: "600" }}
                    >
                      {outboundarrivaltime}
                    </div>
                  </div>
                  <div
                    className="offerflight9 offerflightdate"
                    style={{
                      textAlign: "left",
                      whiteSpace: "nowrap",
                      fontWeight: "400",
                    }}
                  >
                    {outboundarrivaldate}
                  </div>
                </div>
              </div>
              <div class="d-flex flex-column">
                <div>&nbsp;</div>
                <div className="offerflight11">
                  <svg
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ width: 20, height: "100%" }}
                  >
                    <circle cx="10" cy="8" r="4" />

                    <line
                      x1="10"
                      y1="10"
                      x2="10"
                      y2="100"
                      style={{ stroke: "rgb(0, 0, 0)", strokeWidth: 1 }}
                    />
                  </svg>
                </div>
                <div class="flex-grow-1">
                  {" "}
                  <svg
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ width: 20, height: "100%" }}
                  >
                    <line
                      x1="10"
                      y1="-2000"
                      x2="10"
                      y2="2000"
                      style={{ stroke: "rgb(0, 0, 0)", strokeWidth: 1 }}
                    />
                  </svg>
                </div>
                <div className="offerflight13">
                  <svg
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ width: 20, height: "100%" }}
                  >
                    <circle cx="10" cy="14" r="4" />

                    <line
                      x1="10"
                      y1="-100"
                      x2="10"
                      y2="10"
                      style={{ stroke: "rgb(0, 0, 0)", strokeWidth: 1 }}
                    />
                  </svg>
                </div>
              </div>
              <div class="d-flex flex-column flex-grow-1">
                <div>
                  {outbounddirect ? (
                    "direkt"
                  ) : (
                    <>
                      {" "}
                      {offer.fare && (
                        <FormattedMessage
                          id="stopps"
                          values={{
                            num: Array.isArray(
                              offer?.bookinginfo?.Offer?.Package?.Flight
                                ?.OutboundFlightSegments?.Stopover
                            )
                              ? offer.bookinginfo.Offer.Package.Flight
                                  .OutboundFlightSegments.Stopover.length
                              : 0,
                          }}
                        />
                      )}
                      {!offer.fare && <FormattedMessage id="nodirectflight" />}
                    </>
                  )}
                </div>
                <div className="offerflight15" style={{ fontWeight: "600" }}>
                  {!offer.fare && outboundAirportName}
                  {offer.fare && (
                    <>
                      {
                        offer?.bookinginfo?.Offer?.Package?.Flight
                          ?.OutboundFlightSegments?.DepartureAirport?._
                      }{" "}
                      (
                      {
                        offer?.bookinginfo?.Offer?.Package?.Flight
                          ?.OutboundFlightSegments?.DepartureAirport?.$
                          ?.IataCode
                      }
                      )
                    </>
                  )}
                </div>

                <div class="flex-grow-1" style={{ padding: "8px" }}>
                  {" "}
                  <div className="offerflight17">
                    <div className="offerflight18">
                      {offer.fare && (
                        <>
                          {outboundduration === -1 &&
                          outboundFlight?.FlightDuration
                            ? Math.floor(outboundFlight?.FlightDuration / 60) +
                              ":" +
                              (outboundFlight?.FlightDuration % 60 < 10
                                ? "0"
                                : "") +
                              (outboundFlight?.FlightDuration % 60) +
                              " Std. - "
                            : outboundduration > 0
                            ? Math.floor(outboundduration / 60) +
                              ":" +
                              (outboundduration % 60 < 10 ? "0" : "") +
                              (outboundduration % 60) +
                              " Std. - "
                            : ""}{" "}
                        </>
                      )}
                      {!offer.fare && outbounddirect && (
                        <>
                          {outboundduration === -1 &&
                          outboundFlight?.FlightDuration
                            ? Math.floor(outboundFlight?.FlightDuration / 60) +
                              ":" +
                              (outboundFlight?.FlightDuration % 60 < 10
                                ? "0"
                                : "") +
                              (outboundFlight?.FlightDuration % 60) +
                              " Std."
                            : outboundduration > 0
                            ? Math.floor(outboundduration / 60) +
                              ":" +
                              (outboundduration % 60 < 10 ? "0" : "") +
                              (outboundduration % 60) +
                              " Std."
                            : ""}{" "}
                        </>
                      )}
                      {outboundAirline} {outboundFlightnumber}
                    </div>
                  </div>
                  {verbose &&
                    stopOversOutbound.map((o) => {
                      let waitTime = {};
                      if (!o.StopoverAirport) {
                        return null;
                      }
                      if (o.StopoverDuration) {
                        waitTime = parse(o.StopoverDuration);
                      }
                      return (
                        <>
                          <br />
                          Zwischenstopp: {o.StopoverAirport?._}
                          &nbsp;{df2.format(new Date(o.StopoverStart))}
                          &nbsp;-&nbsp;
                          {tf.format(new Date(o.StopoverEnd))}
                          &nbsp;(
                          {waitTime.days > 0 && (
                            <>
                              <FormattedMessage
                                id="days"
                                values={{ num: waitTime.days }}
                              />
                              &nbsp;
                            </>
                          )}
                          {waitTime.hours > 0 && (
                            <>
                              <FormattedMessage
                                id="hoursabb"
                                values={{ num: waitTime.hours }}
                              />
                              &nbsp;
                            </>
                          )}
                          {waitTime.minutes > 0 && (
                            <FormattedMessage
                              id="minutesabb"
                              values={{ num: waitTime.minutes }}
                            />
                          )}
                          )
                        </>
                      );
                    })}
                  {verbose && (
                    <>
                      {checked && (
                        <>
                          <br />
                        </>
                      )}
                      {!checked
                        ? ""
                        : outboundWeight > 0
                        ? "Gepäck: " + outboundWeight + " kg pro Person"
                        : outboundBags +
                          " Gepäckstück(e) pro Person zur Abgabe inklusive"}
                      {!checked ? (
                        ""
                      ) : (
                        <>
                          <br />+ Handgepäck{" "}
                          <InfoPopupHover
                            trigger={
                              <span
                                onClick={() => setBaggageOpen(!baggageOpen)}
                              >
                                <FaInfoCircle className="filterinfo" />
                              </span>
                            }
                            content={
                              "Zusätzlich zum angegebenen Gepäck dürfen Sie ein Handgepäck bis 8 kg mitnehmen. Bei Fragen wenden Sie sich an uns."
                            }
                            position={"right center"}
                          ></InfoPopupHover>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="offerflight20" style={{ fontWeight: "600" }}>
                  {!offer.fare && inboundAirportName}
                  {offer.fare && (
                    <>
                      {
                        offer?.bookinginfo?.Offer?.Package?.Flight
                          ?.OutboundFlightSegments?.DestinationAirport?._
                      }{" "}
                      (
                      {
                        offer?.bookinginfo?.Offer?.Package?.Flight
                          ?.OutboundFlightSegments?.DestinationAirport?.$
                          ?.IataCode
                      }
                      )
                    </>
                  )}
                </div>
              </div>
            </div>
            <div style={{ width: "5%", marginBottom: "20px" }}></div>
            {offer?.OfferServices?.Package?.Flight?.InboundFlight && (
              <div
                className="offerflight22"
                style={{
                  width: verbose ? undefined : "45%",
                  marginRight: horizontal ? 32 : undefined,
                }}
              >
                <div class="d-flex">
                  <div style={{ marginRight: "4px" }}>
                    <FaPlane className="flighIconinbound" />{" "}
                  </div>
                  <div class="d-flex flex-column">
                    <div className="offerflight25">
                      <div
                        className="offerflighttime"
                        style={{ textAlign: "left", fontWeight: "600" }}
                      >
                        Rückflug
                      </div>
                      <div className="offerflight26">
                        <div
                          className="offerflighttime"
                          style={{ textAlign: "left", fontWeight: "600" }}
                        >
                          {inbounddeparturetime}
                        </div>
                      </div>
                      <div
                        className="offerflight27 offerflightdate"
                        style={{
                          textAlign: "left",
                          whiteSpace: "nowrap",
                          fontWeight: "400",
                        }}
                      >
                        {inbounddeparturedate}
                      </div>
                    </div>
                    <div
                      class="flex-grow-1"
                      style={{ marginBottom: "10px" }}
                    ></div>
                    <div className="offerflight29">
                      <div className="offerflight26">
                        <div
                          className="offerflight30 offerflighttime"
                          style={{ textAlign: "left", fontWeight: "600" }}
                        >
                          {inboundarrivaltime}
                        </div>
                      </div>
                      <div
                        className="offerflight31 offerflightdate"
                        style={{
                          textAlign: "left",
                          whiteSpace: "nowrap",
                          fontWeight: "400",
                        }}
                      >
                        {inboundarrivaldate}
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-column">
                    <div>&nbsp;</div>
                    <div className="offerflight33">
                      <svg
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ width: 20, height: "100%" }}
                      >
                        <circle cx="10" cy="8" r="4" />

                        <line
                          x1="10"
                          y1="10"
                          x2="10"
                          y2="100"
                          style={{ stroke: "rgb(0, 0, 0)", strokeWidth: 1 }}
                        />
                      </svg>
                    </div>
                    <div class="flex-grow-1">
                      <svg
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ width: 20, height: "100%" }}
                      >
                        <line
                          x1="10"
                          y1="-2000"
                          x2="10"
                          y2="2000"
                          style={{ stroke: "rgb(0, 0, 0)", strokeWidth: 1 }}
                        />
                      </svg>
                    </div>
                    <div className="offerflight35">
                      <svg
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ width: 20, height: "100%" }}
                      >
                        <circle cx="10" cy="14" r="4" />

                        <line
                          x1="10"
                          y1="-100"
                          x2="10"
                          y2="10"
                          style={{ stroke: "rgb(0, 0, 0)", strokeWidth: 1 }}
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="d-flex flex-column flex-grow-1">
                    <div>
                      {inbounddirect ? (
                        "direkt"
                      ) : (
                        <>
                          {" "}
                          {offer.fare && (
                            <FormattedMessage
                              id="stopps"
                              values={{
                                num: Array.isArray(
                                  offer?.bookinginfo?.Offer?.Package?.Flight
                                    ?.InboundFlightSegments?.Stopover
                                )
                                  ? offer.bookinginfo.Offer.Package.Flight
                                      .InboundFlightSegments.Stopover.length
                                  : 0,
                              }}
                            />
                          )}
                          {!offer.fare && (
                            <FormattedMessage id="nodirectflight" />
                          )}
                        </>
                      )}
                    </div>
                    <div
                      className="offerflight37"
                      style={{ fontWeight: "600" }}
                    >
                      {!offer.fare && inboundAirportName}

                      {offer.fare && (
                        <>
                          {
                            offer?.bookinginfo?.Offer?.Package?.Flight
                              ?.InboundFlightSegments?.DepartureAirport?._
                          }{" "}
                          (
                          {
                            offer?.bookinginfo?.Offer?.Package?.Flight
                              ?.InboundFlightSegments?.DepartureAirport?.$
                              ?.IataCode
                          }
                          )
                        </>
                      )}
                    </div>

                    <div class="flex-grow-1" style={{ padding: "8px" }}>
                      {" "}
                      <div className="offerflight17">
                        <div className="offerflight18">
                          {offer.fare && (
                            <>
                              {inboundduration === -1 &&
                              inboundFlight?.FlightDuration
                                ? Math.floor(
                                    inboundFlight?.FlightDuration / 60
                                  ) +
                                  ":" +
                                  (inboundFlight?.FlightDuration % 60 < 10
                                    ? "0"
                                    : "") +
                                  (inboundFlight?.FlightDuration % 60) +
                                  " Std. - "
                                : inboundFlight > 0
                                ? Math.floor(inboundFlight / 60) +
                                  ":" +
                                  (inboundFlight % 60 < 10 ? "0" : "") +
                                  (inboundFlight % 60) +
                                  " Std. - "
                                : ""}{" "}
                            </>
                          )}
                          {!offer.fare && inbounddirect && (
                            <>
                              {inboundduration === -1 &&
                              inboundFlight?.FlightDuration
                                ? Math.floor(
                                    inboundFlight?.FlightDuration / 60
                                  ) +
                                  ":" +
                                  (inboundFlight?.FlightDuration % 60 < 10
                                    ? "0"
                                    : "") +
                                  (inboundFlight?.FlightDuration % 60) +
                                  " Std."
                                : inboundFlight > 0
                                ? Math.floor(inboundFlight / 60) +
                                  ":" +
                                  (inboundFlight % 60 < 10 ? "0" : "") +
                                  (inboundFlight % 60) +
                                  " Std."
                                : ""}{" "}
                            </>
                          )}
                          {inboundAirline} {inboundFlightnumber}
                        </div>
                      </div>
                      {verbose &&
                        stopOversInbound.map((o) => {
                          let waitTime = {};
                          if (!o.StopoverAirport) {
                            return;
                          }

                          if (o.StopoverDuration) {
                            waitTime = parse(o.StopoverDuration);
                          }
                          return (
                            <>
                              <br />
                              Zwischenstopp: {o.StopoverAirport?._}
                              &nbsp;{df2.format(new Date(o.StopoverStart))}
                              &nbsp;-&nbsp;
                              {tf.format(new Date(o.StopoverEnd))}
                              &nbsp;(
                              {waitTime.days > 0 && (
                                <>
                                  <FormattedMessage
                                    id="days"
                                    values={{ num: waitTime.days }}
                                  />
                                  &nbsp;
                                </>
                              )}
                              {waitTime.hours > 0 && (
                                <>
                                  <FormattedMessage
                                    id="hours"
                                    values={{ num: waitTime.hours }}
                                  />
                                  &nbsp;
                                </>
                              )}
                              {waitTime.minutes > 0 && (
                                <FormattedMessage
                                  id="minutes"
                                  values={{ num: waitTime.minutes }}
                                />
                              )}
                              )
                            </>
                          );
                        })}
                      {verbose && (
                        <>
                          {" "}
                          {checked && (
                            <>
                              <br />
                            </>
                          )}
                          {!checked
                            ? "b"
                            : inboundWeight > 0
                            ? "Gepäck: " + inboundWeight + " kg pro Person"
                            : inboundBags +
                              " Gepäckstück(e) pro Person zur Abgabe inklusive"}{" "}
                          {!checked ? (
                            ""
                          ) : (
                            <>
                              <br />+ Handgepäck{" "}
                              <InfoPopupHover
                                trigger={
                                  <span
                                    onClick={() => setBaggageOpen(!baggageOpen)}
                                  >
                                    <FaInfoCircle className="filterinfo" />
                                  </span>
                                }
                                content={
                                  "Zusätzlich zum angegebenen Gepäck dürfen Sie ein Handgepäck bis 8 kg mitnehmen. Bei Fragen wenden Sie sich an uns."
                                }
                                position={"right center"}
                              ></InfoPopupHover>
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <div
                      className="offerflight39"
                      style={{ fontWeight: "600" }}
                    >
                      {!offer.fare && outboundAirportName}

                      {offer.fare && (
                        <>
                          {
                            offer?.bookinginfo?.Offer?.Package?.Flight
                              ?.InboundFlightSegments?.DestinationAirport?._
                          }{" "}
                          (
                          {
                            offer?.bookinginfo?.Offer?.Package?.Flight
                              ?.InboundFlightSegments?.DestinationAirport?.$
                              ?.IataCode
                          }
                          )
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              className="mt-2 mb-2"
              style={{
                display: baggageOpen ? "block" : "none",
                fontStyle: "italic",
                fontWeight: "600",
                fontSize: "12pt",
                border: "1px solid black",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              {
                "Zusätzlich zum angegebenen Gepäck dürfen Sie ein Handgepäck bis 8 kg mitnehmen."
              }
              <br></br>
              {" Bei Fragen wenden Sie sich an uns."}
            </div>
          </div>
        </>
      )}
      {!(verbose || horizontal) && (
        <>
          {/* <div className="flightborderbottom">Ihre Flugzeiten</div>*/}
          <div className="flightborderbottom">
            <div>
              <FaPlane className="flighIconoutbound flightIconoutbound" />
              &nbsp;
              <span className="flightdate">{outbounddeparturedate}</span>{" "}
              |&nbsp;
              {outbounddirect ? (
                "Direktflug"
              ) : (
                <>
                  {" "}
                  {offer.fare && (
                    <FormattedMessage
                      id="stopps"
                      values={{
                        num: Array.isArray(
                          offer?.bookinginfo?.Offer?.Package?.Flight
                            ?.OutboundFlightSegments?.Stopover
                        )
                          ? offer.bookinginfo.Offer.Package.Flight
                              .OutboundFlightSegments.Stopover.length
                          : 0,
                      }}
                    />
                  )}
                  {!offer.fare && <FormattedMessage id="nodirectflight" />}
                </>
              )}
            </div>
            <div>
              {!offer.fare && outboundAirportName}{" "}
              {offer.fare && (
                <>
                  {
                    offer?.bookinginfo?.Offer?.Package?.Flight
                      ?.OutboundFlightSegments?.DepartureAirport?._
                  }{" "}
                  (
                  {
                    offer?.bookinginfo?.Offer?.Package?.Flight
                      ?.OutboundFlightSegments?.DepartureAirport?.$?.IataCode
                  }
                  )
                </>
              )}
              {" -> "} {!offer.fare && inboundAirportName}
              {offer.fare && (
                <>
                  {
                    offer?.bookinginfo?.Offer?.Package?.Flight
                      ?.OutboundFlightSegments?.DestinationAirport?._
                  }{" "}
                  (
                  {
                    offer?.bookinginfo?.Offer?.Package?.Flight
                      ?.OutboundFlightSegments?.DestinationAirport?.$?.IataCode
                  }
                  )
                </>
              )}
            </div>
            <div>
              {outbounddeparturetime} {" -> "} {outboundarrivaltime}
              {outboundarrivaldate !== outbounddeparturedate && (
                <sup>+1</sup>
              )}{" "}
              Uhr
            </div>
            <div>
              {!offer.fare && outbounddirect && (
                <>
                  {outboundduration === -1 && outboundFlight?.FlightDuration
                    ? Math.floor(outboundFlight?.FlightDuration / 60) +
                      ":" +
                      (outboundFlight?.FlightDuration % 60 < 10 ? "0" : "") +
                      (outboundFlight?.FlightDuration % 60) +
                      " Std."
                    : outboundduration > 0
                    ? Math.floor(outboundduration / 60) +
                      ":" +
                      (outboundduration % 60 < 10 ? "0" : "") +
                      (outboundduration % 60) +
                      " Std."
                    : ""}{" "}
                </>
              )}
              {offer.fare && (
                <>
                  {outboundduration === -1 && outboundFlight?.FlightDuration
                    ? Math.floor(outboundFlight?.FlightDuration / 60) +
                      ":" +
                      (outboundFlight?.FlightDuration % 60 < 10 ? "0" : "") +
                      (outboundFlight?.FlightDuration % 60) +
                      " Std."
                    : outboundduration > 0
                    ? Math.floor(outboundduration / 60) +
                      ":" +
                      (outboundduration % 60 < 10 ? "0" : "") +
                      (outboundduration % 60) +
                      " Std."
                    : ""}{" "}
                </>
              )}
              &nbsp;|&nbsp;
              {outboundAirline} {outboundFlightnumber}
            </div>
          </div>

          {offer?.OfferServices?.Package?.Flight?.InboundFlight && (
            <div>
              <div>
                <FaPlane className="flighIconinbound flightIconinbound" />
                &nbsp;
                <span className="flightdate">{inbounddeparturedate}</span>{" "}
                |&nbsp;
                {inbounddirect ? (
                  "Direktflug"
                ) : (
                  <>
                    {" "}
                    {offer.fare && (
                      <FormattedMessage
                        id="stopps"
                        values={{
                          num: Array.isArray(
                            offer?.bookinginfo?.Offer?.Package?.Flight
                              ?.InboundFlightSegments?.Stopover
                          )
                            ? offer.bookinginfo.Offer.Package.Flight
                                .InboundFlightSegments.Stopover.length
                            : 0,
                        }}
                      />
                    )}
                    {!offer.fare && <FormattedMessage id="nodirectflight" />}
                  </>
                )}
              </div>
              <div>
                {!offer.fare && inboundAirportName}{" "}
                {offer.fare && (
                  <>
                    {
                      offer?.bookinginfo?.Offer?.Package?.Flight
                        ?.InboundFlightSegments?.DepartureAirport?._
                    }{" "}
                    (
                    {
                      offer?.bookinginfo?.Offer?.Package?.Flight
                        ?.InboundFlightSegments?.DepartureAirport?.$?.IataCode
                    }
                    )
                  </>
                )}{" "}
                {" -> "}
                {!offer.fare && outboundAirportName}
                {offer.fare && (
                  <>
                    {
                      offer?.bookinginfo?.Offer?.Package?.Flight
                        ?.InboundFlightSegments?.DestinationAirport?._
                    }{" "}
                    (
                    {
                      offer?.bookinginfo?.Offer?.Package?.Flight
                        ?.InboundFlightSegments?.DestinationAirport?.$?.IataCode
                    }
                    )
                  </>
                )}
              </div>
              <div>
                {inbounddeparturetime} {"->"} {inboundarrivaltime}
                {inboundarrivaldate !== inbounddeparturedate && (
                  <sup>+1</sup>
                )}{" "}
                Uhr
              </div>
              <div>
                {!offer.fare && inbounddirect && (
                  <>
                    {inboundduration === -1 && inboundFlight?.FlightDuration
                      ? Math.floor(inboundFlight?.FlightDuration / 60) +
                        ":" +
                        (inboundFlight?.FlightDuration % 60 < 10 ? "0" : "") +
                        (inboundFlight?.FlightDuration % 60) +
                        " Std."
                      : inboundduration > 0
                      ? Math.floor(inboundduration / 60) +
                        ":" +
                        (inboundduration % 60 < 10 ? "0" : "") +
                        (inboundduration % 60) +
                        " Std."
                      : ""}{" "}
                  </>
                )}
                {offer.fare && (
                  <>
                    {inboundduration === -1 && inboundFlight?.FlightDuration
                      ? Math.floor(inboundFlight?.FlightDuration / 60) +
                        ":" +
                        (inboundFlight?.FlightDuration % 60 < 10 ? "0" : "") +
                        (inboundFlight?.FlightDuration % 60) +
                        " Std."
                      : inboundduration > 0
                      ? Math.floor(inboundduration / 60) +
                        ":" +
                        (inboundduration % 60 < 10 ? "0" : "") +
                        (inboundduration % 60) +
                        " Std."
                      : ""}{" "}
                  </>
                )}
                &nbsp;|&nbsp;
                {inboundAirline} {inboundFlightnumber}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
