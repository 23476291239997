export const airportsIata = {
    "19AK": { iata: "ICY", name: "Icy Bay Airport" },
    "2AK6": { iata: "HGZ", name: "Hog River Airport" },
    "38WA": { iata: "BYW", name: "Blakely Island Airport" },
    "3IS8": { iata: "BDF", name: "Rinkenberger Restricted Landing Area" },
    "65LA": { iata: "BCS", name: "Southern Seaplane Airport" },
    "6OK6": { iata: "BWL", name: "Earl Henry Airport" },
    "7NC2": { iata: "DUF", name: "Pine Island Airport" },
    "82CL": { iata: "FOB", name: "Fort Bragg Airport" },
    ADDE: { iata: "ALX", name: "Thomas C Russell Field" },
    AGAF: { iata: "AFT", name: "Afutara Aerodrome" },
    AGAR: { iata: "RNA", name: "Ulawa Airport" },
    AGAT: { iata: "ATD", name: "Uru Harbour Airport" },
    AGBA: { iata: "VEV", name: "Barakoma Airport" },
    AGBT: { iata: "BPF", name: "Batuna Aerodrome" },
    AGEV: { iata: "GEF", name: "Geva Airport" },
    AGGA: { iata: "AKS", name: "Auki Airport" },
    AGGB: { iata: "BNY", name: "Bellona/Anua Airport" },
    AGGC: { iata: "CHY", name: "Choiseul Bay Airport" },
    AGGE: { iata: "BAS", name: "Ballalae Airport" },
    AGGF: { iata: "FRE", name: "Fera/Maringe Airport" },
    AGGH: { iata: "HIR", name: "Honiara International Airport" },
    AGGI: { iata: "MBU", name: "Babanakira Airport" },
    AGGJ: { iata: "AVU", name: "Avu Avu Airport" },
    AGGK: { iata: "IRA", name: "Ngorangora Airport" },
    AGGL: { iata: "SCZ", name: "Santa Cruz/Graciosa Bay/Luova Airport" },
    AGGM: { iata: "MUA", name: "Munda Airport" },
    AGGN: { iata: "GZO", name: "Nusatupe Airport" },
    AGGO: { iata: "MNY", name: "Mono Airport" },
    AGGP: { iata: "PRS", name: "Parasi Airport" },
    AGGR: { iata: "RNL", name: "Rennell/Tingoa Airport" },
    AGGS: { iata: "EGM", name: "Sege Airport" },
    AGGT: { iata: "NNB", name: "Santa Ana Airport" },
    AGGU: { iata: "RUS", name: "Marau Airport" },
    AGGV: { iata: "VAO", name: "Suavanao Airport" },
    AGGY: { iata: "XYA", name: "Yandina Airport" },
    AGKG: { iata: "KGE", name: "Kagau Island Airport" },
    AGKW: { iata: "KWS", name: "Kwailabesi Airport" },
    AGOK: { iata: "GTA", name: "Gatokae Airport" },
    AGRC: { iata: "RIN", name: "Ringi Cove Airport" },
    AGRM: { iata: "RBV", name: "Ramata Airport" },
    AK13: { iata: "CEX", name: "Chena Hot Springs Airport" },
    AK33: { iata: "HED", name: "Herendeen Bay Airport" },
    AK49: { iata: "TWE", name: "Taylor Airport" },
    AK75: { iata: "CDL", name: "Candle 2 Airport" },
    AK96: { iata: "BSZ", name: "Bartletts Airport" },
    AK97: { iata: "BSW", name: "Boswell Bay Airport" },
    ANYN: { iata: "INU", name: "Nauru International Airport" },
    AYAI: { iata: "ATP", name: "Aitape Airport" },
    AYAM: { iata: "AMU", name: "Amanab Airport" },
    AYAN: { iata: "ADC", name: "Andakombe Airport" },
    AYAO: { iata: "AIE", name: "Aiome Airport" },
    AYAQ: { iata: "KPM", name: "Kompiam Airport" },
    AYAT: { iata: "AUJ", name: "Ambunti Airport" },
    AYAW: { iata: "AWB", name: "Awaba Airport" },
    AYAY: { iata: "AYU", name: "Aiyura Airport" },
    AYBA: { iata: "VMU", name: "Baimuru Airport" },
    AYBG: { iata: "BDZ", name: "Baindoung Airport" },
    AYBK: { iata: "BUA", name: "Buka Airport" },
    AYBM: { iata: "OPU", name: "Balimo Airport" },
    AYBU: { iata: "BUL", name: "Bulolo Airport" },
    AYCH: { iata: "CMU", name: "Chimbu Airport" },
    AYDE: { iata: "DER", name: "Derim Airport" },
    AYDU: { iata: "DAU", name: "Daru Airport" },
    AYED: { iata: "XYR", name: "Edwaki Airport" },
    AYFA: { iata: "FNE", name: "Fane Airport" },
    AYFI: { iata: "FIN", name: "Finschhafen Airport" },
    AYFR: { iata: "FAQ", name: "Frieda River Airport" },
    AYGA: { iata: "GKA", name: "Goroka Airport" },
    AYGG: { iata: "GRL", name: "Garasa Airport" },
    AYGI: { iata: "GAR", name: "Garaina Airport" },
    AYGN: { iata: "GUR", name: "Gurney Airport" },
    AYGP: { iata: "GAP", name: "Gusap Airport" },
    AYGR: { iata: "PNP", name: "Girua Airport" },
    AYGT: { iata: "GMI", name: "Gasmata Island Airport" },
    AYGV: { iata: "GVI", name: "Green River Airport" },
    AYHK: { iata: "HKN", name: "Kimbe Airport" },
    AYIQ: { iata: "KIE", name: "Aropa Airport" },
    AYKA: { iata: "LSA", name: "Losuia Airport" },
    AYKB: { iata: "KBM", name: "Kabwum" },
    AYKC: { iata: "KDR", name: "Kandrian Airport" },
    AYKI: { iata: "UNG", name: "Kiunga Airport" },
    AYKK: { iata: "KRI", name: "Kikori Airport" },
    AYKM: { iata: "KMA", name: "Kerema Airport" },
    AYKO: { iata: "KKD", name: "Kokoda Airport" },
    AYKT: { iata: "KZF", name: "Kaintiba Airport" },
    AYKU: { iata: "KUQ", name: "Kuri Airport" },
    AYKV: { iata: "KVG", name: "Kavieng Airport" },
    AYKW: { iata: "KWO", name: "Kawito Airport" },
    AYKY: { iata: "LNV", name: "Londolovit Airport" },
    AYLM: { iata: "LMY", name: "Lake Murray Airport" },
    AYLU: { iata: "LMI", name: "Lumi Airport" },
    AYMC: { iata: "MYX", name: "Menyamya Airport" },
    AYMD: { iata: "MAG", name: "Madang Airport" },
    AYMH: { iata: "HGU", name: "Mount Hagen Kagamuga Airport" },
    AYMI: { iata: "MXK", name: "Mindik Airport" },
    AYML: { iata: "GUV", name: "Mougulu Airport" },
    AYMN: { iata: "MDU", name: "Mendi Airport" },
    AYMO: { iata: "MAS", name: "Momote Airport" },
    AYMR: { iata: "MXH", name: "Moro Airport" },
    AYMS: { iata: "MIS", name: "Misima Island Airport" },
    AYNE: { iata: "GBF", name: "Negarbo(Negabo) Airport" },
    AYNG: { iata: "MFO", name: "Manguna Airport" },
    AYNU: { iata: "UKU", name: "Nuku Airport" },
    AYNZ: { iata: "LAE", name: "Lae Nadzab Airport" },
    AYOG: { iata: "OGE", name: "Ogeranang Airport" },
    AYOM: { iata: "OSE", name: "Omora Airport" },
    AYPD: { iata: "PDI", name: "Pindiu Airport" },
    AYPY: { iata: "POM", name: "Port Moresby Jacksons International Airport" },
    AYQA: { iata: "KRJ", name: "Karawari Airstrip" },
    AYRG: { iata: "RMN", name: "Rumginae Airport" },
    AYRI: { iata: "KMR", name: "Karimui Airport" },
    AYSA: { iata: "SBE", name: "Suabi Airport" },
    AYSE: { iata: "NIS", name: "Simberi Airport" },
    AYSG: { iata: "SIL", name: "Sila Airport" },
    AYSJ: { iata: "SIM", name: "Simbai Airport" },
    AYSU: { iata: "SKC", name: "Suki Airport" },
    AYTA: { iata: "TIZ", name: "Tari Airport" },
    AYTB: { iata: "TBG", name: "Tabubil Airport" },
    AYTE: { iata: "TFM", name: "Telefomin Airport" },
    AYTI: { iata: "TPI", name: "Tapini Airport" },
    AYTJ: { iata: "TAJ", name: "Tadji Airport" },
    AYTK: { iata: "RAB", name: "Tokua Airport" },
    AYTN: { iata: "TKW", name: "Tekin Airport" },
    AYTU: { iata: "TFI", name: "Tufi Airport" },
    AYVN: { iata: "VAI", name: "Vanimo Airport" },
    AYWB: { iata: "WAO", name: "Wabo Airport" },
    AYWD: { iata: "WBM", name: "Wapenamanda Airport" },
    AYWG: { iata: "AGL", name: "Wanigela Airport" },
    AYWK: { iata: "WWK", name: "Wewak International Airport" },
    AYWO: { iata: "WOA", name: "Wonenara Airport" },
    AYWS: { iata: "WSU", name: "Wasu Airport" },
    AYWT: { iata: "WTP", name: "Woitape Airport" },
    AYWU: { iata: "WUG", name: "Wau Airport" },
    BGAA: { iata: "JEG", name: "Aasiaat Airport" },
    BGBW: { iata: "UAK", name: "Narsarsuaq Airport" },
    BGCO: { iata: "CNP", name: "Neerlerit Inaat Airport" },
    BGGH: { iata: "GOH", name: "Godthaab / Nuuk Airport" },
    BGJN: { iata: "JAV", name: "Ilulissat Airport" },
    BGKK: { iata: "KUS", name: "Kulusuk Airport" },
    BGMQ: { iata: "JSU", name: "Maniitsoq Airport" },
    BGPT: { iata: "JFR", name: "Paamiut Airport" },
    BGQQ: { iata: "NAQ", name: "Qaanaaq Airport" },
    BGSF: { iata: "SFJ", name: "Kangerlussuaq Airport" },
    BGSS: { iata: "JHS", name: "Sisimiut Airport" },
    BGTL: { iata: "THU", name: "Thule Air Base" },
    BGUK: { iata: "JUV", name: "Upernavik Airport" },
    BGUQ: { iata: "JQA", name: "Qaarsut Airport" },
    BIAR: { iata: "AEY", name: "Akureyri Airport" },
    BIBD: { iata: "BIU", name: "Bildudalur Airport" },
    BIBF: { iata: "BGJ", name: "Borgarfjordur eystri Airport" },
    BIBK: { iata: "BJD", name: "Bakkafjordur Airport" },
    BIBL: { iata: "BLO", name: "Hjaltabakki Airport" },
    BIBR: { iata: "BQD", name: "Budardalur Airport" },
    BIBV: { iata: "BXV", name: "Breiddalsvik Airport" },
    BIDV: { iata: "DJU", name: "Djupivogur Airport" },
    BIEG: { iata: "EGS", name: "Egilsstadir Airport" },
    BIFF: { iata: "FAS", name: "Faskrudsfjordur Airport" },
    BIFM: { iata: "FAG", name: "Fagurholsmyri Airport" },
    BIGF: { iata: "GUU", name: "Grundarfjordur Airport" },
    BIGJ: { iata: "GJR", name: "Gjogur Airport" },
    BIGR: { iata: "GRY", name: "Grimsey Airport" },
    BIHK: { iata: "HVK", name: "Holmavik Airport" },
    BIHN: { iata: "HFN", name: "Hornafjordur Airport" },
    BIHT: { iata: "FLI", name: "Holt Airport" },
    BIHU: { iata: "HZK", name: "Husavik Airport" },
    BIHV: { iata: "HVM", name: "Krokstadarmelar Airport" },
    BIID: { iata: "HLO", name: "Ingjaldssandur Airport" },
    BIIS: { iata: "IFJ", name: "Isafjordur Airport" },
    BIKF: { iata: "KEF", name: "Keflavik International Airport" },
    BIKP: { iata: "OPA", name: "Kopasker Airport" },
    BIKR: { iata: "SAK", name: "Saudarkrokur Airport" },
    BINF: { iata: "NOR", name: "Nordfjordur Airport" },
    BIOF: { iata: "OFJ", name: "Olafsfjordur Airport" },
    BIPA: { iata: "PFJ", name: "Patreksfjordur Airport" },
    BIRE: { iata: "RHA", name: "Reykholar Airport" },
    BIRF: { iata: "OLI", name: "Rif Airport" },
    BIRG: { iata: "RFN", name: "Raufarhofn Airport" },
    BIRK: { iata: "RKV", name: "Reykjavik Airport" },
    BIRL: { iata: "MVA", name: "Reykjahlid Airport" },
    BISI: { iata: "SIJ", name: "Siglufjordur Airport" },
    BIST: { iata: "SYK", name: "Stykkisholmur Airport" },
    BITE: { iata: "TEY", name: "Tingeyri Airport" },
    BITN: { iata: "THO", name: "Thorshofn Airport" },
    BIVM: { iata: "VEY", name: "Vestmannaeyjar Airport" },
    BIVO: { iata: "VPN", name: "Vopnafjordur Airport" },
    BKPR: { iata: "PRN", name: "Pristina International Airport" },
    CAD4: { iata: "YZZ", name: "Trail Airport" },
    CAJ4: { iata: "YAA", name: "Anahim Lake Airport" },
    CAL3: { iata: "DGF", name: "Douglas Lake Airport" },
    CAL4: { iata: "JHL", name: "Fort MacKay/Albian Aerodrome" },
    CAM3: { iata: "DUQ", name: "Duncan Airport" },
    CBBC: { iata: "ZEL", name: "Bella Bella (Campbell Island) Airport" },
    CCA6: { iata: "YWM", name: "Williams Harbour Airport" },
    CCK4: { iata: "YFX", name: "St. Lewis (Fox Harbour) Airport" },
    CCP4: { iata: "YHA", name: "Port Hope Simpson Airport" },
    CCZ2: { iata: "YRG", name: "Rigolet Airport" },
    CDK2: { iata: "DVK", name: "Diavik Airport" },
    CEB3: { iata: "YCK", name: "Colville Lake Airport" },
    CEM3: { iata: "YLE", name: "Whati Airport" },
    CER4: { iata: "NML", name: "Fort McMurray / Mildred Lake Airport" },
    CEW3: { iata: "ZSP", name: "St. Paul Airport" },
    CFF4: { iata: "DAS", name: "Great Bear Lake Airport" },
    CFG6: { iata: "YFI", name: "Fort Mackay / Firebag" },
    CJV7: { iata: "SUR", name: "Summer Beaver Airport" },
    CKB6: { iata: "YAX", name: "Wapekeka Airport" },
    CKL3: { iata: "WNN", name: "Wunnumin Lake Airport" },
    CKQ3: { iata: "YNO", name: "North Spirit Lake Airport" },
    CNE3: { iata: "XBE", name: "Bearskin Lake Airport" },
    CNM5: { iata: "KIF", name: "Kingfisher Lake Airport" },
    CNT3: { iata: "YOG", name: "Ogoki Post Airport" },
    CPF2: { iata: "YEB", name: "Bar River Airport" },
    CPV7: { iata: "YHP", name: "Poplar Hill Airport" },
    CSK6: { iata: "YNX", name: "Snap Lake Airport" },
    CSU2: { iata: "YKU", name: "Chisasibi Airport" },
    CTB6: { iata: "ZTB", name: "Tete-a-la-Baleine Airport" },
    CTP9: { iata: "YAU", name: "Donaldson Airport" },
    CTU5: { iata: "ZLT", name: "La Tabatiere Airport" },
    CYAB: { iata: "YAB", name: "Arctic Bay Airport" },
    CYAC: { iata: "YAC", name: "Cat Lake Airport" },
    CYAD: { iata: "YAR", name: "La Grande-3 Airport" },
    CYAG: { iata: "YAG", name: "Fort Frances Municipal Airport" },
    CYAH: { iata: "YAH", name: "La Grande-4 Airport" },
    CYAL: { iata: "YAL", name: "Alert Bay Airport" },
    CYAM: { iata: "YAM", name: "Sault Ste Marie Airport" },
    CYAQ: { iata: "XKS", name: "Kasabonika Airport" },
    CYAS: { iata: "YKG", name: "Kangirsuk Airport" },
    CYAT: { iata: "YAT", name: "Attawapiskat Airport" },
    CYAW: { iata: "YAW", name: "Shearwater Airport" },
    CYAY: { iata: "YAY", name: "St. Anthony Airport" },
    CYAZ: { iata: "YAZ", name: "Tofino / Long Beach Airport" },
    CYBA: { iata: "YBA", name: "Banff Airport" },
    CYBB: { iata: "YBB", name: "Kugaaruk Airport" },
    CYBC: { iata: "YBC", name: "Baie Comeau Airport" },
    CYBD: { iata: "QBC", name: "Bella Coola Airport" },
    CYBE: { iata: "YBE", name: "Uranium City Airport" },
    CYBF: { iata: "YBY", name: "Bonnyville Airport" },
    CYBG: { iata: "YBG", name: "CFB Bagotville" },
    CYBK: { iata: "YBK", name: "Baker Lake Airport" },
    CYBL: { iata: "YBL", name: "Campbell River Airport" },
    CYBQ: { iata: "XTL", name: "Tadoule Lake Airport" },
    CYBR: { iata: "YBR", name: "Brandon Municipal Airport" },
    CYBT: { iata: "YBT", name: "Brochet Airport" },
    CYBV: { iata: "YBV", name: "Berens River Airport" },
    CYBX: { iata: "YBX", name: "Lourdes de Blanc Sablon Airport" },
    CYCA: { iata: "YRF", name: "Cartwright Airport" },
    CYCB: { iata: "YCB", name: "Cambridge Bay Airport" },
    CYCC: { iata: "YCC", name: "Cornwall Regional Airport" },
    CYCD: { iata: "YCD", name: "Nanaimo Airport" },
    CYCE: { iata: "YCE", name: "James T. Field Memorial Aerodrome" },
    CYCG: { iata: "YCG", name: "Castlegar/West Kootenay Regional Airport" },
    CYCH: { iata: "YCH", name: "Miramichi Airport" },
    CYCK: { iata: "XCM", name: "Chatham Kent Airport" },
    CYCL: { iata: "YCL", name: "Charlo Airport" },
    CYCN: { iata: "YCN", name: "Cochrane Airport" },
    CYCO: { iata: "YCO", name: "Kugluktuk Airport" },
    CYCQ: { iata: "YCQ", name: "Chetwynd Airport" },
    CYCR: { iata: "YCR", name: "Cross Lake (Charlie Sinclair Memorial) Airport" },
    CYCS: { iata: "YCS", name: "Chesterfield Inlet Airport" },
    CYCT: { iata: "YCT", name: "Coronation Airport" },
    CYCW: { iata: "YCW", name: "Chilliwack Airport" },
    CYCY: { iata: "YCY", name: "Clyde River Airport" },
    CYCZ: { iata: "YCZ", name: "Fairmont Hot Springs Airport" },
    CYDA: { iata: "YDA", name: "Dawson City Airport" },
    CYDB: { iata: "YDB", name: "Burwash Airport" },
    CYDF: { iata: "YDF", name: "Deer Lake Airport" },
    CYDL: { iata: "YDL", name: "Dease Lake Airport" },
    CYDM: { iata: "YDM", name: "Ross River Airport" },
    CYDN: { iata: "YDN", name: "Dauphin Barker Airport" },
    CYDO: { iata: "YDO", name: "Dolbeau St Felicien Airport" },
    CYDP: { iata: "YDP", name: "Nain Airport" },
    CYDQ: { iata: "YDQ", name: "Dawson Creek Airport" },
    CYEG: { iata: "YEG", name: "Edmonton International Airport" },
    CYEK: { iata: "YEK", name: "Arviat Airport" },
    CYEL: { iata: "YEL", name: "Elliot Lake Municipal Airport" },
    CYEM: { iata: "YEM", name: "Manitoulin East Municipal Airport" },
    CYEN: { iata: "YEN", name: "Estevan Airport" },
    CYER: { iata: "YER", name: "Fort Severn Airport" },
    CYET: { iata: "YET", name: "Edson Airport" },
    CYEU: { iata: "YEU", name: "Eureka Airport" },
    CYEV: { iata: "YEV", name: "Inuvik Mike Zubko Airport" },
    CYEY: { iata: "YEY", name: "Amos Magny Airport" },
    CYFA: { iata: "YFA", name: "Fort Albany Airport" },
    CYFB: { iata: "YFB", name: "Iqaluit Airport" },
    CYFC: { iata: "YFC", name: "Fredericton Airport" },
    CYFE: { iata: "YFE", name: "Forestville Airport" },
    CYFH: { iata: "YFH", name: "Fort Hope Airport" },
    CYFJ: {
      iata: "YTM",
      name: "La Macaza / Mont-Tremblant International Inc Airport",
    },
    CYFO: { iata: "YFO", name: "Flin Flon Airport" },
    CYFR: { iata: "YFR", name: "Fort Resolution Airport" },
    CYFS: { iata: "YFS", name: "Fort Simpson Airport" },
    CYFT: { iata: "YMN", name: "Makkovik Airport" },
    CYGB: { iata: "YGB", name: "Texada Gillies Bay Airport" },
    CYGD: { iata: "YGD", name: "Goderich Airport" },
    CYGH: { iata: "YGH", name: "Fort Good Hope Airport" },
    CYGK: { iata: "YGK", name: "Kingston Norman Rogers Airport" },
    CYGL: { iata: "YGL", name: "La Grande Riviere Airport" },
    CYGM: { iata: "YGM", name: "Gimli Industrial Park Airport" },
    CYGO: { iata: "YGO", name: "Gods Lake Narrows Airport" },
    CYGP: { iata: "YGP", name: "Gaspe (Michel-Pouliot) Airport" },
    CYGQ: { iata: "YGQ", name: "Geraldton Greenstone Regional Airport" },
    CYGR: { iata: "YGR", name: "Iles-de-la-Madeleine Airport" },
    CYGT: { iata: "YGT", name: "Igloolik Airport" },
    CYGV: { iata: "YGV", name: "Havre St Pierre Airport" },
    CYGW: { iata: "YGW", name: "Kuujjuarapik Airport" },
    CYGX: { iata: "YGX", name: "Gillam Airport" },
    CYGZ: { iata: "YGZ", name: "Grise Fiord Airport" },
    CYHA: { iata: "YQC", name: "Quaqtaq Airport" },
    CYHB: { iata: "YHB", name: "Hudson Bay Airport" },
    CYHD: { iata: "YHD", name: "Dryden Regional Airport" },
    CYHE: { iata: "YHE", name: "Hope Airport" },
    CYHF: { iata: "YHF", name: "Hearst Rene Fontaine Municipal Airport" },
    CYHH: { iata: "YNS", name: "Nemiscau Airport" },
    CYHI: { iata: "YHI", name: "Ulukhaktok Holman Airport" },
    CYHK: { iata: "YHK", name: "Gjoa Haven Airport" },
    CYHM: { iata: "YHM", name: "John C. Munro Hamilton International Airport" },
    CYHN: { iata: "YHN", name: "Hornepayne Municipal Airport" },
    CYHO: { iata: "YHO", name: "Hopedale Airport" },
    CYHR: { iata: "YHR", name: "Chevery Airport" },
    CYHT: { iata: "YHT", name: "Haines Junction Airport" },
    CYHU: { iata: "YHU", name: "Montreal / Saint-Hubert Airport" },
    CYHY: { iata: "YHY", name: "Hay River / Merlyn Carter Airport" },
    CYHZ: { iata: "YHZ", name: "Halifax / Stanfield International Airport" },
    CYIB: { iata: "YIB", name: "Atikokan Municipal Airport" },
    CYID: { iata: "YDG", name: "Digby / Annapolis Regional Airport" },
    CYIF: { iata: "YIF", name: "St Augustin Airport" },
    CYIK: { iata: "YIK", name: "Ivujivik Airport" },
    CYIO: { iata: "YIO", name: "Pond Inlet Airport" },
    CYIV: { iata: "YIV", name: "Island Lake Airport" },
    CYJA: { iata: "YJA", name: "Jasper Airport" },
    CYJF: { iata: "YJF", name: "Fort Liard Airport" },
    CYJN: { iata: "YJN", name: "St Jean Airport" },
    CYJT: { iata: "YJT", name: "Stephenville Airport" },
    CYKA: { iata: "YKA", name: "Kamloops Airport" },
    CYKC: { iata: "YKC", name: "Collins Bay Airport" },
    CYKD: { iata: "LAK", name: "Aklavik Airport" },
    CYKF: { iata: "YKF", name: "Waterloo Airport" },
    CYKG: { iata: "YWB", name: "Kangiqsujuaq (Wakeham Bay) Airport" },
    CYKJ: { iata: "YKJ", name: "Key Lake Airport" },
    CYKL: { iata: "YKL", name: "Schefferville Airport" },
    CYKO: { iata: "AKV", name: "Akulivik Airport" },
    CYKQ: { iata: "YKQ", name: "Waskaganish Airport" },
    CYKX: { iata: "YKX", name: "Kirkland Lake Airport" },
    CYKY: { iata: "YKY", name: "Kindersley Airport" },
    CYKZ: { iata: "YKZ", name: "Buttonville Municipal Airport" },
    CYLA: { iata: "YPJ", name: "Aupaluk Airport" },
    CYLB: { iata: "YLB", name: "Lac La Biche Airport" },
    CYLC: { iata: "YLC", name: "Kimmirut Airport" },
    CYLD: { iata: "YLD", name: "Chapleau Airport" },
    CYLH: { iata: "YLH", name: "Lansdowne House Airport" },
    CYLJ: { iata: "YLJ", name: "Meadow Lake Airport" },
    CYLK: { iata: "YSG", name: "Lutselk'e Airport" },
    CYLL: { iata: "YLL", name: "Lloydminster Airport" },
    CYLQ: { iata: "YLQ", name: "La Tuque Airport" },
    CYLR: { iata: "YLR", name: "Leaf Rapids Airport" },
    CYLS: { iata: "YLK", name: "Barrie-Orillia (Lake Simcoe Regional Airport)" },
    CYLT: { iata: "YLT", name: "Alert Airport" },
    CYLU: { iata: "XGR", name: "Kangiqsualujjuaq (Georges River) Airport" },
    CYLW: { iata: "YLW", name: "Kelowna Airport" },
    CYMA: { iata: "YMA", name: "Mayo Airport" },
    CYME: { iata: "YME", name: "Matane Airport" },
    CYMG: { iata: "YMG", name: "Manitouwadge Airport" },
    CYMH: { iata: "YMH", name: "Mary's Harbour Airport" },
    CYMJ: {
      iata: "YMJ",
      name: "Moose Jaw Air Vice Marshal C. M. McEwen Airport",
    },
    CYML: { iata: "YML", name: "Charlevoix Airport" },
    CYMM: { iata: "YMM", name: "Fort McMurray Airport" },
    CYMO: { iata: "YMO", name: "Moosonee Airport" },
    CYMT: { iata: "YMT", name: "Chapais Airport" },
    CYMU: { iata: "YUD", name: "Umiujaq Airport" },
    CYMW: { iata: "YMW", name: "Maniwaki Airport" },
    CYMX: { iata: "YMX", name: "Montreal International (Mirabel) Airport" },
    CYNA: { iata: "YNA", name: "Natashquan Airport" },
    CYNC: { iata: "YNC", name: "Wemindji Airport" },
    CYND: { iata: "YND", name: "Ottawa / Gatineau Airport" },
    CYNE: { iata: "YNE", name: "Norway House Airport" },
    CYNH: { iata: "YNH", name: "Hudsons Hope Airport" },
    CYNJ: { iata: "YLY", name: "Langley Airport" },
    CYNL: { iata: "YNL", name: "Points North Landing Airport" },
    CYNM: { iata: "YNM", name: "Matagami Airport" },
    CYNN: { iata: "YNN", name: "Nejanilini Lake Airport" },
    CYNR: { iata: "HZP", name: "Fort Mackay / Horizon Airport" },
    CYOA: { iata: "YOA", name: "Ekati Airport" },
    CYOC: { iata: "YOC", name: "Old Crow Airport" },
    CYOD: { iata: "YOD", name: "CFB Cold Lake" },
    CYOH: { iata: "YOH", name: "Oxford House Airport" },
    CYOJ: { iata: "YOJ", name: "High Level Airport" },
    CYOO: { iata: "YOO", name: "Oshawa Airport" },
    CYOP: { iata: "YOP", name: "Rainbow Lake Airport" },
    CYOS: { iata: "YOS", name: "Owen Sound / Billy Bishop Regional Airport" },
    CYOW: { iata: "YOW", name: "Ottawa Macdonald-Cartier International Airport" },
    CYPA: { iata: "YPA", name: "Prince Albert Glass Field" },
    CYPC: { iata: "YPC", name: "Paulatuk (Nora Aliqatchialuk Ruben) Airport" },
    CYPD: { iata: "YPS", name: "Port Hawkesbury Airport" },
    CYPE: { iata: "YPE", name: "Peace River Airport" },
    CYPG: { iata: "YPG", name: "Southport Airport" },
    CYPH: { iata: "YPH", name: "Inukjuak Airport" },
    CYPL: { iata: "YPL", name: "Pickle Lake Airport" },
    CYPM: { iata: "YPM", name: "Pikangikum Airport" },
    CYPN: { iata: "YPN", name: "Port Menier Airport" },
    CYPO: { iata: "YPO", name: "Peawanuck Airport" },
    CYPQ: { iata: "YPQ", name: "Peterborough Airport" },
    CYPR: { iata: "YPR", name: "Prince Rupert Airport" },
    CYPW: { iata: "YPW", name: "Powell River Airport" },
    CYPX: { iata: "YPX", name: "Puvirnituq Airport" },
    CYPY: { iata: "YPY", name: "Fort Chipewyan Airport" },
    CYPZ: { iata: "YPZ", name: "Burns Lake Airport" },
    CYQA: { iata: "YQA", name: "Muskoka Airport" },
    CYQB: { iata: "YQB", name: "Quebec Jean Lesage International Airport" },
    CYQD: { iata: "YQD", name: "The Pas Airport" },
    CYQF: { iata: "YQF", name: "Red Deer Regional Airport" },
    CYQG: { iata: "YQG", name: "Windsor Airport" },
    CYQH: { iata: "YQH", name: "Watson Lake Airport" },
    CYQI: { iata: "YQI", name: "Yarmouth Airport" },
    CYQK: { iata: "YQK", name: "Kenora Airport" },
    CYQL: { iata: "YQL", name: "Lethbridge County Airport" },
    CYQM: { iata: "YQM", name: "Greater Moncton International Airport" },
    CYQN: { iata: "YQN", name: "Nakina Airport" },
    CYQQ: { iata: "YQQ", name: "Comox Airport" },
    CYQR: { iata: "YQR", name: "Regina International Airport" },
    CYQS: { iata: "YQS", name: "St Thomas Municipal Airport" },
    CYQT: { iata: "YQT", name: "Thunder Bay Airport" },
    CYQU: { iata: "YQU", name: "Grande Prairie Airport" },
    CYQV: { iata: "YQV", name: "Yorkton Municipal Airport" },
    CYQW: { iata: "YQW", name: "North Battleford Airport" },
    CYQX: { iata: "YQX", name: "Gander International Airport" },
    CYQY: { iata: "YQY", name: "Sydney / J.A. Douglas McCurdy Airport" },
    CYQZ: { iata: "YQZ", name: "Quesnel Airport" },
    CYRA: { iata: "YRA", name: "Rae Lakes Airport" },
    CYRB: { iata: "YRB", name: "Resolute Bay Airport" },
    CYRI: { iata: "YRI", name: "Riviere-du-Loup Airport" },
    CYRJ: { iata: "YRJ", name: "Roberval Airport" },
    CYRL: { iata: "YRL", name: "Red Lake Airport" },
    CYRM: { iata: "YRM", name: "Rocky Mountain House Airport" },
    CYRO: { iata: "YRO", name: "Ottawa / Rockcliffe Airport" },
    CYRP: { iata: "YRP", name: "Ottawa / Carp Airport" },
    CYRQ: { iata: "YRQ", name: "Trois-Rivieres Airport" },
    CYRS: { iata: "YRS", name: "Red Sucker Lake Airport" },
    CYRT: { iata: "YRT", name: "Rankin Inlet Airport" },
    CYRV: { iata: "YRV", name: "Revelstoke Airport" },
    CYSB: { iata: "YSB", name: "Sudbury Airport" },
    CYSC: { iata: "YSC", name: "Sherbrooke Airport" },
    CYSE: { iata: "YSE", name: "Squamish Airport" },
    CYSF: { iata: "YSF", name: "Stony Rapids Airport" },
    CYSH: { iata: "YSH", name: "Smiths Falls-Montague (Russ Beach) Airport" },
    CYSJ: { iata: "YSJ", name: "Saint John Airport" },
    CYSK: { iata: "YSK", name: "Sanikiluaq Airport" },
    CYSL: { iata: "YSL", name: "St Leonard Airport" },
    CYSM: { iata: "YSM", name: "Fort Smith Airport" },
    CYSN: { iata: "YCM", name: "Niagara District Airport" },
    CYSP: { iata: "YSP", name: "Marathon Airport" },
    CYST: { iata: "YST", name: "St. Theresa Point Airport" },
    CYSU: { iata: "YSU", name: "Summerside Airport" },
    CYSY: {
      iata: "YSY",
      name: "Sachs Harbour (David Nasogaluak Jr. Saaryuaq) Airport",
    },
    CYTA: { iata: "YTA", name: "Pembroke Airport" },
    CYTE: { iata: "YTE", name: "Cape Dorset Airport" },
    CYTF: { iata: "YTF", name: "Alma Airport" },
    CYTH: { iata: "YTH", name: "Thompson Airport" },
    CYTL: { iata: "YTL", name: "Big Trout Lake Airport" },
    CYTQ: { iata: "YTQ", name: "Tasiujaq Airport" },
    CYTR: { iata: "YTR", name: "CFB Trenton" },
    CYTS: { iata: "YTS", name: "Timmins/Victor M. Power" },
    CYTZ: { iata: "YTZ", name: "Billy Bishop Toronto City Centre Airport" },
    CYUB: { iata: "YUB", name: "Tuktoyaktuk Airport" },
    CYUL: {
      iata: "YUL",
      name: "Montreal / Pierre Elliott Trudeau International Airport",
    },
    CYUT: { iata: "YUT", name: "Repulse Bay Airport" },
    CYUX: { iata: "YUX", name: "Hall Beach Airport" },
    CYUY: { iata: "YUY", name: "Rouyn Noranda Airport" },
    CYVB: { iata: "YVB", name: "Bonaventure Airport" },
    CYVC: { iata: "YVC", name: "La Ronge Airport" },
    CYVG: { iata: "YVG", name: "Vermilion Airport" },
    CYVK: { iata: "YVE", name: "Vernon Airport" },
    CYVM: { iata: "YVM", name: "Qikiqtarjuaq Airport" },
    CYVO: { iata: "YVO", name: "Val-d'Or Airport" },
    CYVP: { iata: "YVP", name: "Kuujjuaq Airport" },
    CYVQ: { iata: "YVQ", name: "Norman Wells Airport" },
    CYVR: { iata: "YVR", name: "Vancouver International Airport" },
    CYVT: { iata: "YVT", name: "Buffalo Narrows Airport" },
    CYVV: { iata: "YVV", name: "Wiarton Airport" },
    CYVZ: { iata: "YVZ", name: "Deer Lake Airport" },
    CYWA: { iata: "YWA", name: "Petawawa Airport" },
    CYWE: { iata: "YFJ", name: "Wekweeti Airport" },
    CYWG: {
      iata: "YWG",
      name: "Winnipeg / James Armstrong Richardson International Airport",
    },
    CYWJ: { iata: "YWJ", name: "Deline Airport" },
    CYWK: { iata: "YWK", name: "Wabush Airport" },
    CYWL: { iata: "YWL", name: "Williams Lake Airport" },
    CYWP: { iata: "YWP", name: "Webequie Airport" },
    CYWY: { iata: "YWY", name: "Wrigley Airport" },
    CYXC: { iata: "YXC", name: "Cranbrook Airport" },
    CYXD: {
      iata: "YXD",
      name: "Edmonton City Centre (Blatchford Field) Airport",
    },
    CYXE: {
      iata: "YXE",
      name: "Saskatoon John G. Diefenbaker International Airport",
    },
    CYXH: { iata: "YXH", name: "Medicine Hat Airport" },
    CYXJ: { iata: "YXJ", name: "Fort St John Airport" },
    CYXK: { iata: "YXK", name: "Rimouski Airport" },
    CYXL: { iata: "YXL", name: "Sioux Lookout Airport" },
    CYXN: { iata: "YXN", name: "Whale Cove Airport" },
    CYXP: { iata: "YXP", name: "Pangnirtung Airport" },
    CYXQ: { iata: "YXQ", name: "Beaver Creek Airport" },
    CYXR: { iata: "YXR", name: "Earlton (Timiskaming Regional) Airport" },
    CYXS: { iata: "YXS", name: "Prince George Airport" },
    CYXT: { iata: "YXT", name: "Terrace Airport" },
    CYXU: { iata: "YXU", name: "London Airport" },
    CYXX: { iata: "YXX", name: "Abbotsford Airport" },
    CYXY: {
      iata: "YXY",
      name: "Whitehorse / Erik Nielsen International Airport",
    },
    CYXZ: { iata: "YXZ", name: "Wawa Airport" },
    CYYB: { iata: "YYB", name: "North Bay Airport" },
    CYYC: { iata: "YYC", name: "Calgary International Airport" },
    CYYD: { iata: "YYD", name: "Smithers Airport" },
    CYYE: { iata: "YYE", name: "Fort Nelson Airport" },
    CYYF: { iata: "YYF", name: "Penticton Airport" },
    CYYG: { iata: "YYG", name: "Charlottetown Airport" },
    CYYH: { iata: "YYH", name: "Taloyoak Airport" },
    CYYJ: { iata: "YYJ", name: "Victoria International Airport" },
    CYYL: { iata: "YYL", name: "Lynn Lake Airport" },
    CYYM: { iata: "YYM", name: "Cowley Airport" },
    CYYN: { iata: "YYN", name: "Swift Current Airport" },
    CYYQ: { iata: "YYQ", name: "Churchill Airport" },
    CYYR: { iata: "YYR", name: "Goose Bay Airport" },
    CYYT: { iata: "YYT", name: "St. John's International Airport" },
    CYYU: { iata: "YYU", name: "Kapuskasing Airport" },
    CYYW: { iata: "YYW", name: "Armstrong Airport" },
    CYYY: { iata: "YYY", name: "Mont Joli Airport" },
    CYYZ: { iata: "YYZ", name: "Lester B. Pearson International Airport" },
    CYZD: { iata: "YZD", name: "Downsview Airport" },
    CYZE: { iata: "YZE", name: "Gore Bay Manitoulin Airport" },
    CYZF: { iata: "YZF", name: "Yellowknife Airport" },
    CYZG: { iata: "YZG", name: "Salluit Airport" },
    CYZH: { iata: "YZH", name: "Slave Lake Airport" },
    CYZP: { iata: "YZP", name: "Sandspit Airport" },
    CYZR: { iata: "YZR", name: "Chris Hadfield Airport" },
    CYZS: { iata: "YZS", name: "Coral Harbour Airport" },
    CYZT: { iata: "YZT", name: "Port Hardy Airport" },
    CYZU: { iata: "YZU", name: "Whitecourt Airport" },
    CYZV: { iata: "YZV", name: "Sept-Iles Airport" },
    CYZW: { iata: "YZW", name: "Teslin Airport" },
    CYZX: { iata: "YZX", name: "CFB Greenwood" },
    CYZY: { iata: "YZY", name: "Mackenzie Airport" },
    CZAC: { iata: "ZAC", name: "York Landing Airport" },
    CZAM: { iata: "YSN", name: "Salmon Arm Airport" },
    CZBA: { iata: "YDT", name: "Burlington Executive" },
    CZBB: { iata: "ZBD", name: "Vancouver / Boundary Bay Airport" },
    CZBD: { iata: "ILF", name: "Ilford Airport" },
    CZBF: { iata: "ZBF", name: "Bathurst Airport" },
    CZBM: { iata: "ZBM", name: "Bromont (Roland Desourdy) Airport" },
    CZEE: { iata: "ZEE", name: "Kelsey Airport" },
    CZEM: { iata: "ZEM", name: "Eastmain River Airport" },
    CZFA: { iata: "ZFA", name: "Faro Airport" },
    CZFD: { iata: "ZFD", name: "Fond-Du-Lac Airport" },
    CZFG: { iata: "ZFG", name: "Pukatawagan Airport" },
    CZFM: { iata: "ZFM", name: "Fort Mcpherson Airport" },
    CZFN: { iata: "ZFN", name: "Tulita Airport" },
    CZGF: { iata: "ZGF", name: "Grand Forks Airport" },
    CZGI: { iata: "ZGI", name: "Gods River Airport" },
    CZGR: { iata: "ZGR", name: "Little Grand Rapids Airport" },
    CZHP: { iata: "ZHP", name: "High Prairie Airport" },
    CZJG: { iata: "ZJG", name: "Jenpeg Airport" },
    CZJN: { iata: "ZJN", name: "Swan River Airport" },
    CZKE: { iata: "ZKE", name: "Kashechewan Airport" },
    CZLQ: { iata: "YTD", name: "Thicket Portage Airport" },
    CZMD: { iata: "MSA", name: "Muskrat Dam Airport" },
    CZML: { iata: "ZMH", name: "South Cariboo Region / 108 Mile Airport" },
    CZMN: { iata: "PIW", name: "Pikwitonei Airport" },
    CZMT: { iata: "ZMT", name: "Masset Airport" },
    CZNG: { iata: "ZNG", name: "Poplar River Airport" },
    CZPB: { iata: "ZPB", name: "Sachigo Lake Airport" },
    CZPC: { iata: "WPC", name: "Pincher Creek Airport" },
    CZPO: { iata: "ZPO", name: "Pinehouse Lake Airport" },
    CZRJ: { iata: "ZRJ", name: "Round Lake (Weagamow Lake) Airport" },
    CZSJ: { iata: "ZSJ", name: "Sandy Lake Airport" },
    CZSN: { iata: "ZSN", name: "South Indian Lake Airport" },
    CZST: { iata: "ZST", name: "Stewart Airport" },
    CZTA: { iata: "YDV", name: "Bloodvein River Airport" },
    CZTM: { iata: "ZTM", name: "Shamattawa Airport" },
    CZUC: { iata: "ZUC", name: "Ignace Municipal Airport" },
    CZUM: { iata: "ZUM", name: "Churchill Falls Airport" },
    CZWH: { iata: "ZWH", name: "Lac Brochet Airport" },
    CZWL: { iata: "ZWL", name: "Wollaston Lake Airport" },
    DAAB: { iata: "QLD", name: "Blida Airport" },
    DAAD: { iata: "BUJ", name: "Bou Saada Airport" },
    DAAE: { iata: "BJA", name: "Soummam Airport" },
    DAAG: { iata: "ALG", name: "Houari Boumediene Airport" },
    DAAJ: { iata: "DJG", name: "Djanet Inedbirene Airport" },
    DAAK: { iata: "QFD", name: "Boufarik Airport" },
    DAAP: { iata: "VVZ", name: "Illizi Takhamalt Airport" },
    DAAS: { iata: "QSF", name: "Ain Arnat Airport" },
    DAAT: { iata: "TMR", name: "Aguenar – Hadj Bey Akhamok Airport" },
    DAAV: { iata: "GJL", name: "Jijel Ferhat Abbas Airport" },
    DAAY: { iata: "MZW", name: "Mecheria Airport" },
    DAAZ: { iata: "QZN", name: "Relizane Airport" },
    DABB: { iata: "AAE", name: "Annaba Airport" },
    DABC: { iata: "CZL", name: "Mohamed Boudiaf International Airport" },
    DABS: { iata: "TEE", name: "Cheikh Larbi Tebessi Airport" },
    DABT: { iata: "BLJ", name: "Batna Airport" },
    DAFH: { iata: "HRM", name: "Hassi R'Mel Airport" },
    DAFI: { iata: "QDJ", name: "Tsletsi Airport" },
    DAOB: { iata: "TID", name: "Bou Chekif Airport" },
    DAOF: { iata: "TIN", name: "Tindouf Airport" },
    DAOI: { iata: "QAS", name: "Ech Cheliff Airport" },
    DAOL: { iata: "TAF", name: "Tafaraoui Airport" },
    DAON: { iata: "TLM", name: "Zenata – Messali El Hadj Airport" },
    DAOO: { iata: "ORN", name: "Es Senia Airport" },
    DAOR: { iata: "CBH", name: "Bechar Boudghene Ben Ali Lotfi Airport" },
    DAOS: { iata: "BFW", name: "Sidi Bel Abbes Airport" },
    DAOV: { iata: "MUW", name: "Ghriss Airport" },
    DAOY: { iata: "EBH", name: "El Bayadh Airport" },
    DATG: { iata: "INF", name: "In Guezzam Airport" },
    DATM: { iata: "BMW", name: "Bordj Badji Mokhtar Airport" },
    DAUA: { iata: "AZR", name: "Touat Cheikh Sidi Mohamed Belkebir Airport" },
    DAUB: { iata: "BSK", name: "Biskra Airport" },
    DAUE: { iata: "ELG", name: "El Golea Airport" },
    DAUG: { iata: "GHA", name: "Noumerat - Moufdi Zakaria Airport" },
    DAUH: { iata: "HME", name: "Oued Irara Airport" },
    DAUI: { iata: "INZ", name: "In Salah Airport" },
    DAUK: { iata: "TGR", name: "Touggourt Sidi Madhi Airport" },
    DAUL: { iata: "LOO", name: "Laghouat Airport" },
    DAUO: { iata: "ELU", name: "Guemar Airport" },
    DAUT: { iata: "TMX", name: "Timimoun Airport" },
    DAUU: { iata: "OGX", name: "Ain el Beida Airport" },
    DAUZ: { iata: "IAM", name: "In Amenas Airport" },
    DBBB: { iata: "COO", name: "Cadjehoun Airport" },
    DBBD: { iata: "DJA", name: "Djougou Airport" },
    DBBK: { iata: "KDC", name: "Kandi Airport" },
    DBBN: { iata: "NAE", name: "Natitingou Airport" },
    DBBP: { iata: "PKO", name: "Parakou Airport" },
    DBBS: { iata: "SVF", name: "Save Airport" },
    DFCA: { iata: "XKY", name: "Kaya Airport" },
    DFCC: { iata: "OUG", name: "Ouahigouya Airport" },
    DFCJ: { iata: "XDJ", name: "Djibo Airport" },
    DFCL: { iata: "XLU", name: "Leo Airport" },
    DFCP: { iata: "PUP", name: "Po Airport" },
    DFEA: { iata: "XBO", name: "Boulsa Airport" },
    DFEB: { iata: "XBG", name: "Bogande Airport" },
    DFED: { iata: "DIP", name: "Diapaga Airport" },
    DFEE: { iata: "DOR", name: "Dori Airport" },
    DFEF: { iata: "FNG", name: "Fada N'gourma Airport" },
    DFEG: { iata: "XGG", name: "Gorom-Gorom Airport" },
    DFEL: { iata: "XKA", name: "Kantchari Airport" },
    DFEM: { iata: "TMQ", name: "Tambao Airport" },
    DFEP: { iata: "XPA", name: "Pama Airport" },
    DFER: { iata: "ARL", name: "Arly Airport" },
    DFES: { iata: "XSE", name: "Sebba Airport" },
    DFET: { iata: "TEG", name: "Tenkodogo Airport" },
    DFEZ: { iata: "XZA", name: "Zabre Airport" },
    DFFD: { iata: "OUA", name: "Ouagadougou Airport" },
    DFOB: { iata: "BNR", name: "Banfora Airport" },
    DFOD: { iata: "DGU", name: "Dedougou Airport" },
    DFOG: { iata: "XGA", name: "Gaoua Airport" },
    DFON: { iata: "XNU", name: "Nouna Airport" },
    DFOO: { iata: "BOY", name: "Bobo Dioulasso Airport" },
    DFOT: { iata: "TUQ", name: "Tougan Airport" },
    DFOU: { iata: "XDE", name: "Diebougou Airport" },
    DFOY: { iata: "XAR", name: "Aribinda Airport" },
    DGAA: { iata: "ACC", name: "Kotoka International Airport" },
    DGLE: { iata: "TML", name: "Tamale Airport" },
    DGSI: { iata: "KMS", name: "Kumasi Airport" },
    DGSN: { iata: "NYI", name: "Sunyani Airport" },
    DGTK: { iata: "TKD", name: "Takoradi Airport" },
    DIAP: { iata: "ABJ", name: "Port Bouet Airport" },
    DIAU: { iata: "OGO", name: "Abengourou Airport" },
    DIBI: { iata: "BXI", name: "Boundiali Airport" },
    DIBK: { iata: "BYK", name: "Bouake Airport" },
    DIBN: { iata: "BQO", name: "Bouna Airport" },
    DIBU: { iata: "BDK", name: "Soko Airport" },
    DIDK: { iata: "DIM", name: "Dimbokro Airport" },
    DIDL: { iata: "DJO", name: "Daloa Airport" },
    DIFK: { iata: "FEK", name: "Ferkessedougou Airport" },
    DIGA: { iata: "GGN", name: "Gagnoa Airport" },
    DIGL: { iata: "GGO", name: "Guiglo Airport" },
    DIGN: { iata: "BBV", name: "Nero-Mer Airport" },
    DIKO: { iata: "HGO", name: "Korhogo Airport" },
    DIMN: { iata: "MJC", name: "Man Airport" },
    DIOD: { iata: "KEO", name: "Odienne Airport" },
    DIOF: { iata: "OFI", name: "Ouango Fitini Airport" },
    DISG: { iata: "SEO", name: "Seguela Airport" },
    DISP: { iata: "SPY", name: "San Pedro Airport" },
    DISS: { iata: "ZSS", name: "Sassandra Airport" },
    DITB: { iata: "TXU", name: "Tabou Airport" },
    DIYO: { iata: "ASK", name: "Yamoussoukro Airport" },
    DNAA: { iata: "ABV", name: "Nnamdi Azikiwe International Airport" },
    DNAK: { iata: "AKR", name: "Akure Airport" },
    DNAS: { iata: "ABB", name: "Asaba International Airport" },
    DNBE: { iata: "BNI", name: "Benin Airport" },
    DNCA: { iata: "CBQ", name: "Margaret Ekpo International Airport" },
    DNEN: { iata: "ENU", name: "Akanu Ibiam International Airport" },
    DNGO: { iata: "GMO", name: "Gombe Lawanti International Airport" },
    DNGU: { iata: "QUS", name: "Gusau Airport" },
    DNIB: { iata: "IBA", name: "Ibadan Airport" },
    DNIL: { iata: "ILR", name: "Ilorin International Airport" },
    DNIM: { iata: "QOW", name: "Sam Mbakwe International Airport" },
    DNJO: { iata: "JOS", name: "Yakubu Gowon Airport" },
    DNKA: { iata: "KAD", name: "Kaduna Airport" },
    DNKN: { iata: "KAN", name: "Mallam Aminu International Airport" },
    DNMA: { iata: "MIU", name: "Maiduguri International Airport" },
    DNMK: { iata: "MDI", name: "Makurdi Airport" },
    DNMM: { iata: "LOS", name: "Murtala Muhammed International Airport" },
    DNMN: { iata: "MXJ", name: "Minna Airport" },
    DNPO: { iata: "PHC", name: "Port Harcourt International Airport" },
    DNSO: { iata: "SKO", name: "Sadiq Abubakar III International Airport" },
    DNTZ: {
      iata: "NBE",
      name: "Enfidha Zine El Abidine Ben Ali International Airport",
    },
    DNYO: { iata: "YOL", name: "Yola Airport" },
    DNZA: { iata: "ZAR", name: "Zaria Airport" },
    DRRM: { iata: "MFQ", name: "Maradi Airport" },
    DRRN: { iata: "NIM", name: "Diori Hamani International Airport" },
    DRRT: { iata: "THZ", name: "Tahoua Airport" },
    DRZA: { iata: "AJY", name: "Mano Dayak International Airport" },
    DRZL: { iata: "RLT", name: "Arlit Airport" },
    DRZR: { iata: "ZND", name: "Zinder Airport" },
    DTKA: { iata: "TBJ", name: "Tabarka 7 Novembre Airport" },
    DTMB: { iata: "MIR", name: "Monastir Habib Bourguiba International Airport" },
    DTTA: { iata: "TUN", name: "Tunis Carthage International Airport" },
    DTTB: { iata: "OIZ", name: "Sidi Ahmed Air Base" },
    DTTF: { iata: "GAF", name: "Gafsa Ksar International Airport" },
    DTTG: { iata: "GAE", name: "Gabes Matmata International Airport" },
    DTTJ: { iata: "DJE", name: "Djerba Zarzis International Airport" },
    DTTR: { iata: "EBM", name: "El Borma Airport" },
    DTTX: { iata: "SFA", name: "Sfax Thyna International Airport" },
    DTTZ: { iata: "TOE", name: "Tozeur Nefta International Airport" },
    DXNG: { iata: "LRL", name: "Niamtougou International Airport" },
    DXXX: { iata: "LFW", name: "Lome-Tokoin Airport" },
    EBAW: { iata: "ANR", name: "Antwerp International Airport (Deurne)" },
    EBBR: { iata: "BRU", name: "Brussels Airport" },
    EBCI: { iata: "CRL", name: "Brussels South Charleroi Airport" },
    EBKT: { iata: "KJK", name: "Wevelgem Airport" },
    EBLG: { iata: "LGG", name: "Liege Airport" },
    EBNM: { iata: "QNM", name: "Suarlee Airport" },
    EBOS: { iata: "OST", name: "Ostend-Bruges International Airport" },
    EBZH: { iata: "QHA", name: "Hasselt Airport" },
    EBZR: { iata: "OBL", name: "Oostmalle Air Base" },
    EDAC: { iata: "AOC", name: "Altenburg-Nobitz Airport" },
    EDAH: { iata: "HDF", name: "Heringsdorf Airport" },
    EDAQ: { iata: "ZHZ", name: "Halle-Oppin Airport" },
    EDAU: { iata: "IES", name: "Riesa-Gohlis Airport" },
    EDAX: { iata: "REB", name: "Rechlin-Larz Airport" },
    EDDB: { iata: "BER", name: "Berlin Brandenburg Airport" },
    EDBC: { iata: "CSO", name: "Cochstedt Airport" },
    EDBH: { iata: "BBH", name: "Barth Airport" },
    EDBM: { iata: "ZMG", name: "Magdeburg Airport" },
    EDCD: { iata: "CBU", name: "Cottbus-Drewitz Airport" },
    EDCG: { iata: "GTI", name: "Rugen Airport" },
    EDCK: { iata: "KOQ", name: "Kothen Airport" },
    EDCP: { iata: "PEF", name: "Peenemunde Airport" },
    EDDC: { iata: "DRS", name: "Dresden Airport" },
    EDDE: { iata: "ERF", name: "Erfurt Airport" },
    EDDF: { iata: "FRA", name: "Frankfurt am Main International Airport" },
    EDDG: { iata: "FMO", name: "Munster Osnabruck Airport" },
    EDDH: { iata: "HAM", name: "Hamburg Airport" },
    EDDI: { iata: "THF", name: "Berlin Tempelhof Airport" },
    EDDK: { iata: "CGN", name: "Cologne Bonn Airport" },
    EDDL: { iata: "DUS", name: "Dusseldorf International Airport" },
    EDDM: { iata: "MUC", name: "Munich International Airport" },
    EDDN: { iata: "NUE", name: "Nuremberg Airport" },
    EDDP: { iata: "LEJ", name: "Leipzig Halle Airport" },
    EDDR: { iata: "SCN", name: "Saarbrucken Airport" },
    EDDS: { iata: "STR", name: "Stuttgart Airport" },
    EDDT: { iata: "TXL", name: "Berlin-Tegel International Airport" },
    EDDV: { iata: "HAJ", name: "Hannover Airport" },
    EDDW: { iata: "BRE", name: "Bremen Airport" },
    EDFE: { iata: "QEF", name: "Frankfurt-Egelsbach Airport" },
    EDFH: { iata: "HHN", name: "Frankfurt-Hahn Airport" },
    EDFM: { iata: "MHG", name: "Mannheim-City Airport" },
    EDFZ: { iata: "QMZ", name: "Mainz-Finthen Airport" },
    EDGE: { iata: "EIB", name: "Eisenach-Kindel Airport" },
    EDGS: { iata: "SGE", name: "Siegerland Airport" },
    EDHI: { iata: "XFW", name: "Hamburg-Finkenwerder Airport" },
    EDHK: { iata: "KEL", name: "Kiel-Holtenau Airport" },
    EDHL: { iata: "LBC", name: "Lubeck Blankensee Airport" },
    EDHN: { iata: "EUM", name: "Neumunster Airport" },
    EDJA: { iata: "FMM", name: "Memmingen Allgau Airport" },
    EDKA: { iata: "AAH", name: "Aachen-Merzbruck Airport" },
    EDKB: { iata: "BNJ", name: "Bonn-Hangelar Airport" },
    EDLE: { iata: "ESS", name: "Essen Mulheim Airport" },
    EDLI: { iata: "BFE", name: "Bielefeld Airport" },
    EDLM: { iata: "ZOJ", name: "Marl-Loemuhle Airport" },
    EDLN: { iata: "MGL", name: "Monchengladbach Airport" },
    EDLP: { iata: "PAD", name: "Paderborn Lippstadt Airport" },
    EDLV: { iata: "NRN", name: "Niederrhein Airport" },
    EDLW: { iata: "DTM", name: "Dortmund Airport" },
    EDMA: { iata: "AGB", name: "Augsburg Airport" },
    EDMO: { iata: "OBF", name: "Oberpfaffenhofen Airport" },
    EDMS: { iata: "RBM", name: "Straubing Airport" },
    EDNY: { iata: "FDH", name: "Friedrichshafen Airport" },
    EDOL: { iata: "FRF", name: "Oschersleben Airport" },
    EDOP: { iata: "SZW", name: "Schwerin Parchim Airport" },
    EDQD: { iata: "BYU", name: "Bayreuth Airport" },
    EDQE: { iata: "URD", name: "Burg Feuerstein Airport" },
    EDQF: { iata: "QOB", name: "Ansbach-Petersdorf Airport" },
    EDQM: { iata: "HOQ", name: "Hof-Plauen Airport" },
    EDRB: { iata: "BBJ", name: "Bitburg Airport" },
    EDRZ: { iata: "ZQW", name: "Zweibrucken Airport" },
    EDSB: { iata: "FKB", name: "Karlsruhe Baden-Baden Airport" },
    EDTD: { iata: "ZQL", name: "Donaueschingen-Villingen Airport" },
    EDTL: { iata: "LHA", name: "Lahr Airport" },
    EDVE: { iata: "BWE", name: "Braunschweig Wolfsburg Airport" },
    EDVK: { iata: "KSF", name: "Kassel-Calden Airport" },
    EDWB: { iata: "BRV", name: "Bremerhaven Airport" },
    EDWD: { iata: "XLW", name: "Lemwerder Airport" },
    EDWE: { iata: "EME", name: "Emden Airport" },
    EDWG: { iata: "AGE", name: "Wangerooge Airport" },
    EDWI: { iata: "WVN", name: "Wilhelmshaven-Mariensiel Airport" },
    EDWJ: { iata: "JUI", name: "Juist Airport" },
    EDWL: { iata: "LGO", name: "Langeoog Airport" },
    EDWN: { iata: "ZOW", name: "Nordhorn-Lingen Airport" },
    EDWR: { iata: "BMK", name: "Borkum Airport" },
    EDWS: { iata: "NOD", name: "Norden-Norddeich Airport" },
    EDWU: { iata: "VAC", name: "Varrelbusch Airport" },
    EDWY: { iata: "NRD", name: "Norderney Airport" },
    EDWZ: { iata: "BMR", name: "Baltrum Airport" },
    EDXB: { iata: "HEI", name: "Heide-Busum Airport" },
    EDXF: { iata: "FLF", name: "Flensburg-Schaeferhaus Airport" },
    EDXH: { iata: "HGL", name: "Helgoland-Dune Airport" },
    EDXJ: { iata: "QHU", name: "Husum-Schwesing Airport" },
    EDXO: { iata: "PSH", name: "St. Peter-Ording Airport" },
    EDXW: { iata: "GWT", name: "Westerland Sylt Airport" },
    EDXY: { iata: "OHR", name: "Wyk auf Fohr Airport" },
    EEKA: { iata: "KDL", name: "Kardla Airport" },
    EEKE: { iata: "URE", name: "Kuressaare Airport" },
    EEPU: { iata: "EPU", name: "Parnu Airport" },
    EETN: { iata: "TLL", name: "Tallinn Airport" },
    EETU: { iata: "TAY", name: "Tartu Airport" },
    EFET: { iata: "ENF", name: "Enontekio Airport" },
    EFFO: { iata: "QVE", name: "Forssa Airport" },
    EFHA: { iata: "KEV", name: "Halli Airport" },
    EFHF: { iata: "HEM", name: "Helsinki Malmi Airport" },
    EFHK: { iata: "HEL", name: "Helsinki Vantaa Airport" },
    EFHV: { iata: "HYV", name: "Hyvinkaa Airport" },
    EFIT: { iata: "KTQ", name: "Kitee Airport" },
    EFIV: { iata: "IVL", name: "Ivalo Airport" },
    EFJO: { iata: "JOE", name: "Joensuu Airport" },
    EFJY: { iata: "JYV", name: "Jyvaskyla Airport" },
    EFKA: { iata: "KAU", name: "Kauhava Airport" },
    EFKE: { iata: "KEM", name: "Kemi-Tornio Airport" },
    EFKI: { iata: "KAJ", name: "Kajaani Airport" },
    EFKJ: { iata: "KHJ", name: "Kauhajoki Airport" },
    EFKK: { iata: "KOK", name: "Kruunupyy Airport" },
    EFKS: { iata: "KAO", name: "Kuusamo Airport" },
    EFKT: { iata: "KTT", name: "Kittila Airport" },
    EFKU: { iata: "KUO", name: "Kuopio Airport" },
    EFLA: { iata: "QLF", name: "Lahti Vesivehmaa Airport" },
    EFLP: { iata: "LPP", name: "Lappeenranta Airport" },
    EFMA: { iata: "MHQ", name: "Mariehamn Airport" },
    EFMI: { iata: "MIK", name: "Mikkeli Airport" },
    EFOU: { iata: "OUL", name: "Oulu Airport" },
    EFPO: { iata: "POR", name: "Pori Airport" },
    EFRO: { iata: "RVN", name: "Rovaniemi Airport" },
    EFSA: { iata: "SVL", name: "Savonlinna Airport" },
    EFSI: { iata: "SJY", name: "Seinajoki Airport" },
    EFSO: { iata: "SOT", name: "Sodankyla Airport" },
    EFTP: { iata: "TMP", name: "Tampere-Pirkkala Airport" },
    EFTU: { iata: "TKU", name: "Turku Airport" },
    EFUT: { iata: "UTI", name: "Utti Air Base" },
    EFVA: { iata: "VAA", name: "Vaasa Airport" },
    EFVR: { iata: "VRK", name: "Varkaus Airport" },
    EFYL: { iata: "YLI", name: "Ylivieska Airport" },
    EG74: { iata: "LHB", name: "Bruntingthorpe Airport" },
    EGAA: { iata: "BFS", name: "Belfast International Airport" },
    EGAB: { iata: "ENK", name: "St Angelo Airport" },
    EGAC: { iata: "BHD", name: "George Best Belfast City Airport" },
    EGAE: { iata: "LDY", name: "City of Derry Airport" },
    EGBB: { iata: "BHX", name: "Birmingham International Airport" },
    EGBE: { iata: "CVT", name: "Coventry Airport" },
    EGBJ: { iata: "GLO", name: "Gloucestershire Airport" },
    EGBK: { iata: "ORM", name: "Sywell Aerodrome" },
    EGBN: { iata: "NQT", name: "Nottingham Airport" },
    EGBP: { iata: "GBA", name: "Kemble Airport" },
    EGCC: { iata: "MAN", name: "Manchester Airport" },
    EGCN: { iata: "DSA", name: "Robin Hood Doncaster Sheffield Airport" },
    EGDJ: { iata: "UPV", name: "Upavon Aerodrome" },
    EGDL: { iata: "LYE", name: "RAF Lyneham" },
    EGDX: { iata: "DGX", name: "MOD St. Athan" },
    EGDY: { iata: "YEO", name: "RNAS Yeovilton" },
    EGEC: { iata: "CAL", name: "Campbeltown Airport" },
    EGED: { iata: "EOI", name: "Eday Airport" },
    EGEF: { iata: "FIE", name: "Fair Isle Airport" },
    EGEH: { iata: "WHS", name: "Whalsay Airport" },
    EGEL: { iata: "COL", name: "Coll Airport" },
    EGEN: { iata: "NRL", name: "North Ronaldsay Airport" },
    EGEO: { iata: "OBN", name: "Oban Airport" },
    EGEP: { iata: "PPW", name: "Papa Westray Airport" },
    EGER: { iata: "SOY", name: "Stronsay Airport" },
    EGES: { iata: "NDY", name: "Sanday Airport" },
    EGET: { iata: "LWK", name: "Lerwick / Tingwall Airport" },
    EGEW: { iata: "WRY", name: "Westray Airport" },
    EGEY: { iata: "CSA", name: "Colonsay Airstrip" },
    EGFE: { iata: "HAW", name: "Haverfordwest Airport" },
    EGFF: { iata: "CWL", name: "Cardiff International Airport" },
    EGFH: { iata: "SWS", name: "Swansea Airport" },
    EGGD: { iata: "BRS", name: "Bristol International Airport" },
    EGGP: { iata: "LPL", name: "Liverpool John Lennon Airport" },
    EGGW: { iata: "LTN", name: "London Luton Airport" },
    EGHC: { iata: "LEQ", name: "Land's End Airport" },
    EGHD: { iata: "PLH", name: "Plymouth City Airport" },
    EGHE: { iata: "ISC", name: "St. Mary's Airport" },
    EGHH: { iata: "BOH", name: "Bournemouth Airport" },
    EGHI: { iata: "SOU", name: "Southampton Airport" },
    EGHJ: { iata: "BBP", name: "Bembridge Airport" },
    EGHL: { iata: "QLA", name: "Lasham Airport" },
    EGHQ: { iata: "NQY", name: "Newquay Cornwall Airport" },
    EGHR: { iata: "QUG", name: "Chichester/Goodwood Airport" },
    EGJA: { iata: "ACI", name: "Alderney Airport" },
    EGJB: { iata: "GCI", name: "Guernsey Airport" },
    EGJJ: { iata: "JER", name: "Jersey Airport" },
    EGKA: { iata: "ESH", name: "Shoreham Airport" },
    EGKB: { iata: "BQH", name: "London Biggin Hill Airport" },
    EGKK: { iata: "LGW", name: "London Gatwick Airport" },
    EGKR: { iata: "KRH", name: "Redhill Aerodrome" },
    EGLC: { iata: "LCY", name: "London City Airport" },
    EGLF: { iata: "FAB", name: "Farnborough Airport" },
    EGLK: { iata: "BBS", name: "Blackbushe Airport" },
    EGLL: { iata: "LHR", name: "London Heathrow Airport" },
    EGMC: { iata: "SEN", name: "Southend Airport" },
    EGMD: { iata: "LYX", name: "Lydd Airport" },
    EGMH: { iata: "MSE", name: "Kent International Airport" },
    EGNC: { iata: "CAX", name: "Carlisle Airport" },
    EGNH: { iata: "BLK", name: "Blackpool International Airport" },
    EGNJ: { iata: "HUY", name: "Humberside Airport" },
    EGNL: { iata: "BWF", name: "Barrow Walney Island Airport" },
    EGNM: { iata: "LBA", name: "Leeds Bradford Airport" },
    EGNO: { iata: "WRT", name: "Warton Airport" },
    EGNR: { iata: "CEG", name: "Hawarden Airport" },
    EGNS: { iata: "IOM", name: "Isle of Man Airport" },
    EGNT: { iata: "NCL", name: "Newcastle Airport" },
    EGNV: { iata: "MME", name: "Durham Tees Valley Airport" },
    EGNX: { iata: "EMA", name: "East Midlands Airport" },
    EGOV: { iata: "HLY", name: "Anglesey Airport" },
    EGPA: { iata: "KOI", name: "Kirkwall Airport" },
    EGPB: { iata: "LSI", name: "Sumburgh Airport" },
    EGPC: { iata: "WIC", name: "Wick Airport" },
    EGPD: { iata: "ABZ", name: "Aberdeen Dyce Airport" },
    EGPE: { iata: "INV", name: "Inverness Airport" },
    EGPF: { iata: "GLA", name: "Glasgow International Airport" },
    EGPH: { iata: "EDI", name: "Edinburgh Airport" },
    EGPI: { iata: "ILY", name: "Islay Airport" },
    EGPK: { iata: "PIK", name: "Glasgow Prestwick Airport" },
    EGPL: { iata: "BEB", name: "Benbecula Airport" },
    EGPM: { iata: "SCS", name: "Scatsta Airport" },
    EGPN: { iata: "DND", name: "Dundee Airport" },
    EGPO: { iata: "SYY", name: "Stornoway Airport" },
    EGPR: { iata: "BRR", name: "Barra Airport" },
    EGPT: { iata: "PSL", name: "Perth/Scone Airport" },
    EGPU: { iata: "TRE", name: "Tiree Airport" },
    EGPW: { iata: "UNT", name: "Unst Airport" },
    EGQB: { iata: "BOL", name: "Ballykelly Airport" },
    EGQK: { iata: "FSS", name: "RAF Kinloss" },
    EGQL: { iata: "ADX", name: "RAF Leuchars" },
    EGQS: { iata: "LMO", name: "RAF Lossiemouth" },
    EGSC: { iata: "CBG", name: "Cambridge Airport" },
    EGSH: { iata: "NWI", name: "Norwich International Airport" },
    EGSS: { iata: "STN", name: "London Stansted Airport" },
    EGSU: { iata: "QFO", name: "Duxford Airport" },
    EGTB: { iata: "HYC", name: "Wycombe Air Park" },
    EGTE: { iata: "EXT", name: "Exeter International Airport" },
    EGTG: { iata: "FZO", name: "Bristol Filton Airport" },
    EGTK: { iata: "OXF", name: "Oxford (Kidlington) Airport" },
    EGTO: { iata: "RCS", name: "Rochester Airport" },
    EGUB: { iata: "BEX", name: "RAF Benson" },
    EGUL: { iata: "LKZ", name: "RAF Lakenheath" },
    EGUN: { iata: "MHZ", name: "RAF Mildenhall" },
    EGUY: { iata: "QUY", name: "RAF Wyton" },
    EGVA: { iata: "FFD", name: "RAF Fairford" },
    EGVN: { iata: "BZZ", name: "RAF Brize Norton" },
    EGVO: { iata: "ODH", name: "RAF Odiham" },
    EGVT: { iata: "WXF", name: "Wethersfield Airport" },
    EGWU: { iata: "NHT", name: "RAF Northolt" },
    EGXC: { iata: "QCY", name: "RAF Coningsby" },
    EGXH: { iata: "BEQ", name: "RAF Honington" },
    EGXJ: { iata: "OKH", name: "RAF Cottesmore" },
    EGXP: { iata: "SQZ", name: "RAF Scampton" },
    EGXU: { iata: "HRT", name: "RAF Linton-On-Ouse" },
    EGXW: { iata: "WTN", name: "RAF Waddington" },
    EGYM: { iata: "MRH", name: "RAF Marham" },
    EGYP: { iata: "MPN", name: "Mount Pleasant Airport" },
    EHAM: { iata: "AMS", name: "Amsterdam Airport Schiphol" },
    EHBK: { iata: "MST", name: "Maastricht Aachen Airport" },
    EHDL: { iata: "QAR", name: "Deelen Air Base" },
    EHEH: { iata: "EIN", name: "Eindhoven Airport" },
    EHGG: { iata: "GRQ", name: "Eelde Airport" },
    EHGR: { iata: "GLZ", name: "Gilze Rijen Air Base" },
    EHKD: { iata: "DHR", name: "De Kooy Airport" },
    EHLE: { iata: "LEY", name: "Lelystad Airport" },
    EHLW: { iata: "LWR", name: "Leeuwarden Air Base" },
    EHOW: { iata: "---", name: "Oostwold Airport" },
    EHRD: { iata: "RTM", name: "Rotterdam Airport" },
    EHTW: { iata: "ENS", name: "Twenthe Airport" },
    EHVK: { iata: "UDE", name: "Volkel Air Base" },
    EHWO: { iata: "WOE", name: "Woensdrecht Air Base" },
    EIBN: { iata: "BYT", name: "Bantry Aerodrome" },
    EIBT: { iata: "BLY", name: "Belmullet Aerodrome" },
    EICA: { iata: "NNR", name: "Connemara Regional Airport" },
    EICB: { iata: "CLB", name: "Castlebar Airport" },
    EICD: { iata: "WEX", name: "Castlebridge Airport" },
    EICK: { iata: "ORK", name: "Cork Airport" },
    EICM: { iata: "GWY", name: "Galway Airport" },
    EIDL: { iata: "CFN", name: "Donegal Airport" },
    EIDW: { iata: "DUB", name: "Dublin Airport" },
    EIIM: { iata: "IOR", name: "Inishmore Aerodrome" },
    EIIR: { iata: "INQ", name: "Inisheer Aerodrome" },
    EIKK: { iata: "KKY", name: "Kilkenny Airport" },
    EIKN: { iata: "NOC", name: "Ireland West Knock Airport" },
    EIKY: { iata: "KIR", name: "Kerry Airport" },
    EILT: { iata: "LTR", name: "Letterkenny Airport" },
    EIMN: { iata: "IIA", name: "Inishmaan Aerodrome" },
    EINN: { iata: "SNN", name: "Shannon Airport" },
    EISG: { iata: "SXL", name: "Sligo Airport" },
    EIWF: { iata: "WAT", name: "Waterford Airport" },
    EK_4: { iata: "0", name: "Vejro" },
    EKAH: { iata: "AAR", name: "Aarhus Airport" },
    EKBI: { iata: "BLL", name: "Billund Airport" },
    EKCH: { iata: "CPH", name: "Copenhagen Kastrup Airport" },
    EKEB: { iata: "EBJ", name: "Esbjerg Airport" },
    EKKA: { iata: "KRP", name: "Karup Airport" },
    EKLS: { iata: "BYR", name: "Laeso Airport" },
    EKMB: { iata: "MRW", name: "Lolland Falster Maribo Airport" },
    EKOD: { iata: "ODE", name: "Odense Airport" },
    EKRK: { iata: "RKE", name: "Copenhagen Roskilde Airport" },
    EKRN: { iata: "RNN", name: "Bornholm Airport" },
    EKSB: { iata: "SGD", name: "Sonderborg Airport" },
    EKSN: { iata: "CNL", name: "Sindal Airport" },
    EKSP: { iata: "SKS", name: "Vojens Skrydstrup Airport" },
    EKSV: { iata: "SQW", name: "Skive Airport" },
    EKTS: { iata: "TED", name: "Thisted Airport" },
    EKVG: { iata: "FAE", name: "Vagar Airport" },
    EKVJ: { iata: "STA", name: "Stauning Airport" },
    EKYT: { iata: "AAL", name: "Aalborg Airport" },
    ELLX: { iata: "LUX", name: "Luxembourg-Findel International Airport" },
    ENAL: { iata: "AES", name: "Alesund Airport" },
    ENAN: { iata: "ANX", name: "Andoya Airport" },
    ENAT: { iata: "ALF", name: "Alta Airport" },
    ENBL: { iata: "FDE", name: "Bringeland Airport" },
    ENBN: { iata: "BNN", name: "Bronnoysund Airport" },
    ENBO: { iata: "BOO", name: "Bodo Airport" },
    ENBR: { iata: "BGO", name: "Bergen Airport Flesland" },
    ENBS: { iata: "BJF", name: "Batsfjord Airport" },
    ENBV: { iata: "BVG", name: "Berlevag Airport" },
    ENCN: { iata: "KRS", name: "Kristiansand Airport" },
    ENDI: { iata: "DLD", name: "Geilo Airport Dagali" },
    ENDU: { iata: "BDU", name: "Bardufoss Airport" },
    ENEV: { iata: "EVE", name: "Harstad/Narvik Airport Evenes" },
    ENFG: { iata: "VDB", name: "Leirin Airport" },
    ENFL: { iata: "FRO", name: "Floro Airport" },
    ENGM: { iata: "OSL", name: "Oslo Gardermoen Airport" },
    ENHA: { iata: "HMR", name: "Stafsberg Airport" },
    ENHD: { iata: "HAU", name: "Haugesund Airport" },
    ENHF: { iata: "HFT", name: "Hammerfest Airport" },
    ENHK: { iata: "HAA", name: "Hasvik Airport" },
    ENHV: { iata: "HVG", name: "Valan Airport" },
    ENKA: { iata: "QKX", name: "Kautokeino Air Base" },
    ENKB: { iata: "KSU", name: "Kristiansund Airport Kvernberget" },
    ENKL: { iata: "GLL", name: "Gol Airport" },
    ENKR: { iata: "KKN", name: "Kirkenes Airport Hoybuktmoen" },
    ENLI: { iata: "FAN", name: "Lista Airport" },
    ENLK: { iata: "LKN", name: "Leknes Airport" },
    ENMH: { iata: "MEH", name: "Mehamn Airport" },
    ENML: { iata: "MOL", name: "Molde Airport" },
    ENMS: { iata: "MJF", name: "Mosjoen Airport Kjaerstad" },
    ENNA: { iata: "LKL", name: "Banak Airport" },
    ENNK: { iata: "NVK", name: "Narvik Framnes Airport" },
    ENNM: { iata: "OSY", name: "Namsos Hoknesora Airport" },
    ENNO: { iata: "NTB", name: "Notodden Airport" },
    ENOL: { iata: "OLA", name: "Orland Airport" },
    ENOV: { iata: "HOV", name: "Orsta-Volda Airport Hovden" },
    ENRA: { iata: "MQN", name: "Mo i Rana Airport Rossvoll" },
    ENRM: { iata: "RVK", name: "Rorvik Airport Ryum" },
    ENRO: { iata: "RRS", name: "Roros Airport" },
    ENRS: { iata: "RET", name: "Rost Airport" },
    ENRY: { iata: "RYG", name: "Moss Airport Rygge" },
    ENSA: { iata: "SRP", name: "Svea Airport" },
    ENSB: { iata: "LYR", name: "Svalbard Airport Longyear" },
    ENSD: { iata: "SDN", name: "Sandane Airport Anda" },
    ENSG: { iata: "SOG", name: "Sogndal Airport" },
    ENSH: { iata: "SVJ", name: "Svolvaer Helle Airport" },
    ENSK: { iata: "SKN", name: "Stokmarknes Skagen Airport" },
    ENSN: { iata: "SKE", name: "Skien Airport" },
    ENSO: { iata: "SRP", name: "Stord Airport" },
    ENSR: { iata: "SOJ", name: "Sorkjosen Airport" },
    ENSS: { iata: "VAW", name: "Vardo Airport Svartnes" },
    ENST: { iata: "SSJ", name: "Sandnessjoen Airport Stokka" },
    ENTC: { iata: "TOS", name: "Tromso Airport" },
    ENTO: { iata: "TRF", name: "Sandefjord Airport Torp" },
    ENVA: { iata: "TRD", name: "Trondheim Airport Vaernes" },
    ENVD: { iata: "VDS", name: "Vadso Airport" },
    ENZV: { iata: "SVG", name: "Stavanger Airport Sola" },
    EPBK: { iata: "QYY", name: "Bialystok-Krywlany Airport" },
    EPBP: { iata: "BXP", name: "Biala Podlaska Airport" },
    EPBY: { iata: "BZG", name: "Bydgoszcz Ignacy Jan Paderewski Airport" },
    EPCH: { iata: "CZW", name: "Czestochowa-Rudniki" },
    EPGD: { iata: "GDN", name: "Gdansk Lech Walesa Airport" },
    EPGL: { iata: "QLC", name: "Gliwice Glider Airport" },
    EPKK: {
      iata: "KRK",
      name: "John Paul II International Airport Krakow-Balice Airport",
    },
    EPKO: { iata: "OSZ", name: "Koszalin Zegrze Airport" },
    EPKT: { iata: "KTW", name: "Katowice International Airport" },
    EPKW: { iata: "QEO", name: "Bielsko-Bialo Kaniow Airfield" },
    EPLB: { iata: "LUZ", name: "Lublin Airport" },
    EPLL: { iata: "LCJ", name: "Lodz Wladyslaw Reymont Airport" },
    EPLR: { iata: "QLU", name: "Lublin Radwiec Airport" },
    EPMO: { iata: "WMI", name: "Warsaw Modlin Airport" },
    EPNT: { iata: "QWS", name: "Nowy Targ Airport" },
    EPOK: { iata: "QYD", name: "Oksywie Military Air Base" },
    EPOP: { iata: "QPM", name: "Opole-Polska Nowa Wies Airport" },
    EPPO: { iata: "POZ", name: "Poznan-Lawica Airport" },
    EPRA: { iata: "RDO", name: "Warsaw-Radom Airport" },
    EPRU: { iata: "CZW", name: "Czestochowa-Rudniki Airport" },
    EPRZ: { iata: "RZE", name: "Rzeszow-Jasionka Airport" },
    EPSC: { iata: "SZZ", name: "Szczecin-Goleniow Solidarnosc Airport" },
    EPSU: { iata: "ZWK", name: "Suwalki Airport" },
    EPSY: { iata: "SZY", name: "Olsztyn-Mazury Airport" },
    EPWA: { iata: "WAW", name: "Warsaw Chopin Airport" },
    EPWR: { iata: "WRO", name: "Copernicus Wroclaw Airport" },
    EPZG: { iata: "IEG", name: "Zielona Gora-Babimost Airport" },
    ESDF: { iata: "RNB", name: "Ronneby Airport" },
    ESFA: { iata: "XWP", name: "Hassleholm Bokeberg Airport" },
    ESGG: { iata: "GOT", name: "Gothenburg-Landvetter Airport" },
    ESGJ: { iata: "JKG", name: "Jonkoping Airport" },
    ESGL: { iata: "LDK", name: "Lidkoping-Hovby Airport" },
    ESGP: { iata: "GSE", name: "Gothenburg City Airport" },
    ESGR: { iata: "KVB", name: "Skovde Airport" },
    ESGT: { iata: "THN", name: "Trollhattan-Vanersborg Airport" },
    ESKK: { iata: "KSK", name: "Karlskoga Airport" },
    ESKM: { iata: "MXX", name: "Mora Airport" },
    ESKN: { iata: "NYO", name: "Stockholm Skavsta Airport" },
    ESMK: { iata: "KID", name: "Kristianstad Airport" },
    ESMO: { iata: "OSK", name: "Oskarshamn Airport" },
    ESMQ: { iata: "KLR", name: "Kalmar Airport" },
    ESMS: { iata: "MMX", name: "Malmo Sturup Airport" },
    ESMT: { iata: "HAD", name: "Halmstad Airport" },
    ESMX: { iata: "VXO", name: "Vaxjo Kronoberg Airport" },
    ESND: { iata: "EVG", name: "Sveg Airport" },
    ESNG: { iata: "GEV", name: "Gallivare Airport" },
    ESNK: { iata: "KRF", name: "Kramfors Solleftea Airport" },
    ESNL: { iata: "LYC", name: "Lycksele Airport" },
    ESNN: { iata: "SDL", name: "Sundsvall-Harnosand Airport" },
    ESNO: { iata: "OER", name: "Ornskoldsvik Airport" },
    ESNQ: { iata: "KRN", name: "Kiruna Airport" },
    ESNS: { iata: "SFT", name: "Skelleftea Airport" },
    ESNU: { iata: "UME", name: "Umea Airport" },
    ESNV: { iata: "VHM", name: "Vilhelmina Airport" },
    ESNX: { iata: "AJR", name: "Arvidsjaur Airport" },
    ESNY: { iata: "SOO", name: "Soderhamn Airport" },
    ESNZ: { iata: "OSD", name: "Ostersund Airport" },
    ESOE: { iata: "ORB", name: "Orebro Airport" },
    ESOH: { iata: "HFS", name: "Hagfors Airport" },
    ESOK: { iata: "KSD", name: "Karlstad Airport" },
    ESOW: { iata: "VST", name: "Stockholm Vasteras Airport" },
    ESPA: { iata: "LLA", name: "Lulea Airport" },
    ESSA: { iata: "ARN", name: "Stockholm-Arlanda Airport" },
    ESSB: { iata: "BMA", name: "Stockholm-Bromma Airport" },
    ESSD: { iata: "BLE", name: "Borlange Airport" },
    ESSF: { iata: "HLF", name: "Hultsfred Airport" },
    ESSK: { iata: "GVX", name: "Gavle Sandviken Airport" },
    ESSL: { iata: "LPI", name: "Linkoping SAAB Airport" },
    ESSP: { iata: "NRK", name: "Norrkoping Airport" },
    ESST: { iata: "TYF", name: "Torsby Airport" },
    ESSU: { iata: "EKT", name: "Eskilstuna Airport" },
    ESSV: { iata: "VBY", name: "Visby Airport" },
    ESSW: { iata: "VVK", name: "Vastervik Airport" },
    ESTA: { iata: "AGH", name: "Angelholm-Helsingborg Airport" },
    ESUD: { iata: "SQO", name: "Storuman Airport" },
    ESUE: { iata: "IDB", name: "Idre Airport" },
    ESUP: { iata: "PJA", name: "Pajala Airport" },
    ESUT: { iata: "HMV", name: "Hemavan Airport" },
    ETAD: { iata: "SPM", name: "Spangdahlem Air Base" },
    ETAR: { iata: "RMS", name: "Ramstein Air Base" },
    ETEJ: { iata: "ZCD", name: "Bamberg-Breitenau Airport" },
    ETEU: { iata: "GHF", name: "Giebelstadt Army Air Field" },
    ETHC: { iata: "ZCN", name: "Celle Airport" },
    ETHE: { iata: "ZPQ", name: "Rheine Bentlage Airport" },
    ETHF: { iata: "FRZ", name: "Fritzlar Airport" },
    ETID: { iata: "ZNF", name: "Hanau Army Air Field" },
    ETIK: { iata: "ILH", name: "Illesheim Air Base" },
    ETIN: { iata: "KZG", name: "Kitzingen Army Air Field" },
    ETMN: { iata: "NDZ", name: "Nordholz Airport" },
    ETNG: { iata: "GKE", name: "Geilenkirchen Airport" },
    ETNL: { iata: "RLG", name: "Rostock-Laage Airport" },
    ETNN: { iata: "QOE", name: "Norvenich Airport" },
    ETNS: { iata: "WBG", name: "Schleswig Airport" },
    ETNU: { iata: "FNB", name: "Neubrandenburg Airport" },
    ETOU: { iata: "WIE", name: "Wiesbaden Army Airfield" },
    ETSF: { iata: "FEL", name: "Furstenfeldbruck Airport" },
    ETSI: { iata: "IGS", name: "Ingolstadt Manching Airport" },
    ETUO: { iata: "GUT", name: "Gutersloh Airport" },
    EVDA: { iata: "DGP", name: "Daugavpils Intrenational Airport" },
    EVLA: { iata: "LPX", name: "Liepaja International Airport" },
    EVRA: { iata: "RIX", name: "Riga International Airport" },
    EVVA: { iata: "VNT", name: "Ventspils International Airport" },
    EYKA: { iata: "KUN", name: "Kaunas International Airport" },
    EYKL: { iata: "KLJ", name: "Klaipeda Airport" },
    EYPA: { iata: "PLQ", name: "Palanga International Airport" },
    EYPP: { iata: "PNV", name: "Panevezys Air Base" },
    EYSA: { iata: "SQQ", name: "Siauliai International Airport" },
    EYSB: { iata: "HLJ", name: "Barysiai Airport" },
    EYVI: { iata: "VNO", name: "Vilnius International Airport" },
    FAAB: { iata: "ALJ", name: "Alexander Bay Airport" },
    FAAG: { iata: "AGZ", name: "Aggeneys Airport" },
    FAAL: { iata: "ADY", name: "Alldays Airport" },
    FABE: { iata: "BIY", name: "Bisho Airport" },
    FABL: { iata: "BFN", name: "J B M Hertzog International Airport" },
    FABU: { iata: "UTE", name: "Bultfontein Airport" },
    FACD: { iata: "CDO", name: "Cradock Airport" },
    FACT: { iata: "CPT", name: "Cape Town International Airport" },
    FADK: { iata: "DUK", name: "Mubatuba Airport" },
    FADQ: { iata: "PZL", name: "Zulu Inyala Airport" },
    FAEL: { iata: "ELS", name: "Ben Schoeman Airport" },
    FAEM: { iata: "EMG", name: "Empangeni Airport" },
    FAER: { iata: "ELL", name: "Ellisras Matimba Airport" },
    FAFB: { iata: "FCB", name: "Ficksburg Sentraoes Airport" },
    FAGC: { iata: "GCJ", name: "Grand Central Airport" },
    FAGG: { iata: "GRJ", name: "George Airport" },
    FAGI: { iata: "GIY", name: "Giyani Airport" },
    FAGM: { iata: "QRA", name: "Rand Airport" },
    FAHL: { iata: "HLW", name: "Hluhluwe Airport" },
    FAHR: { iata: "HRS", name: "Harrismith Airport" },
    FAHS: { iata: "HDS", name: "Hoedspruit Air Force Base Airport" },
    FAKD: { iata: "KXE", name: "P C Pelser Airport" },
    FAKM: { iata: "KIM", name: "Kimberley Airport" },
    FAKN: { iata: "MQP", name: "Kruger Mpumalanga International Airport" },
    FAKP: { iata: "KOF", name: "Komatipoort Airport" },
    FAKU: { iata: "KMH", name: "Johan Pienaar Airport" },
    FAKZ: { iata: "KLZ", name: "Kleinsee Airport" },
    FALA: { iata: "HLA", name: "Lanseria Airport" },
    FALC: { iata: "LMR", name: "Lime Acres Finsch Mine Airport" },
    FALD: { iata: "LDZ", name: "Londolozi Airport" },
    FALE: { iata: "DUR", name: "King Shaka International Airport" },
    FALK: { iata: "LUJ", name: "Lusikisiki Airport" },
    FALO: { iata: "LCD", name: "Louis Trichardt Airport" },
    FALW: { iata: "SDB", name: "Langebaanweg Airport" },
    FALY: { iata: "LAY", name: "Ladysmith Airport" },
    FAMD: { iata: "AAM", name: "Malamala Airport" },
    FAMG: { iata: "MGH", name: "Margate Airport" },
    FAMH: { iata: "MEZ", name: "Musina(Messina) Airport" },
    FAMM: { iata: "MBD", name: "Mmabatho International Airport" },
    FAMN: { iata: "LLE", name: "Riverside Airport" },
    FAMO: { iata: "MZY", name: "Mossel Bay Airport" },
    FAMS: { iata: "MEZ", name: "Morningside Farm Airport" },
    FAMU: { iata: "MZQ", name: "Mkuze Airport" },
    FANC: { iata: "NCS", name: "Newcastle Airport" },
    FANG: { iata: "NGL", name: "Ngala Airport" },
    FANS: { iata: "NLP", name: "Nelspruit Airport" },
    FAOB: { iata: "OVG", name: "Overberg Airport" },
    FAOH: { iata: "OUH", name: "Oudtshoorn Airport" },
    FAOR: { iata: "JNB", name: "O. R. Tambo International Airport" },
    FAPA: { iata: "AFD", name: "Port Alfred Airport" },
    FAPE: { iata: "PLZ", name: "Port Elizabeth Airport" },
    FAPG: { iata: "PBZ", name: "Plettenberg Bay Airport" },
    FAPH: { iata: "PHW", name: "Hendrik Van Eck Airport" },
    FAPJ: { iata: "JOH", name: "Port St Johns Airport" },
    FAPK: { iata: "PRK", name: "Prieska Airport" },
    FAPM: { iata: "PZB", name: "Pietermaritzburg Airport" },
    FAPN: { iata: "NTY", name: "Pilanesberg International Airport" },
    FAPP: { iata: "PTG", name: "Polokwane International Airport" },
    FAPS: { iata: "PCF", name: "Potchefstroom Airport" },
    FAQT: { iata: "UTW", name: "Queenstown Airport" },
    FARB: { iata: "RCB", name: "Richards Bay Airport" },
    FARI: { iata: "RVO", name: "Reivilo Airport" },
    FARS: { iata: "ROD", name: "Robertson Airport" },
    FASB: { iata: "SBU", name: "Springbok Airport" },
    FASC: { iata: "ZEC", name: "Secunda Airport" },
    FASE: { iata: "GSS", name: "Sabi Sabi Airport" },
    FASS: { iata: "SIS", name: "Sishen Airport" },
    FASZ: { iata: "SZK", name: "Skukuza Airport" },
    FATD: { iata: "TDT", name: "Tanda Tula Airport" },
    FATH: { iata: "THY", name: "Thohoyandou Airport" },
    FATN: { iata: "TCU", name: "Thaba Nchu Tar Airport" },
    FATZ: { iata: "LTA", name: "Tzaneen Airport" },
    FAUL: { iata: "ULD", name: "Prince Mangosuthu Buthelezi Airport" },
    FAUP: { iata: "UTN", name: "Pierre Van Ryneveld Airport" },
    FAUS: { iata: "ULX", name: "Ulusaba Airport" },
    FAUT: { iata: "UTT", name: "K. D. Matanzima Airport" },
    FAVB: { iata: "VRU", name: "Vryburg Airport" },
    FAVG: { iata: "VIR", name: "Virginia Airport" },
    FAVR: { iata: "VRE", name: "Vredendal Airport" },
    FAVY: { iata: "VYD", name: "Vryheid Airport" },
    FAWB: { iata: "PRY", name: "Wonderboom Airport" },
    FAWK: { iata: "WKF", name: "Waterkloof Air Force Base" },
    FAWM: { iata: "WEL", name: "Welkom Airport" },
    FBFT: { iata: "FRW", name: "Francistown Airport" },
    FBGZ: { iata: "GNZ", name: "Ghanzi Airport" },
    FBJW: { iata: "JWA", name: "Jwaneng Airport" },
    FBKE: { iata: "BBK", name: "Kasane Airport" },
    FBKR: { iata: "KHW", name: "Khwai River Lodge Airport" },
    FBMN: { iata: "MUB", name: "Maun Airport" },
    FBOR: { iata: "ORP", name: "Orapa Airport" },
    FBPY: { iata: "QPH", name: "Palapye Airport" },
    FBSK: { iata: "GBE", name: "Sir Seretse Khama International Airport" },
    FBSN: { iata: "SXN", name: "Sua Pan Airport" },
    FBSP: { iata: "PKW", name: "Selebi Phikwe Airport" },
    FBSV: { iata: "SVT", name: "Savuti Airport" },
    FBSW: { iata: "SWX", name: "Shakawe Airport" },
    FBTL: { iata: "TLD", name: "Limpopo Valley Airport" },
    FBTS: { iata: "TBY", name: "Tshabong Airport" },
    FCBB: { iata: "BZV", name: "Maya-Maya Airport" },
    FCBD: { iata: "DJM", name: "Djambala Airport" },
    FCBK: { iata: "KNJ", name: "Kindamba Airport" },
    FCBL: { iata: "LCO", name: "Lague Airport" },
    FCBM: { iata: "MUY", name: "Mouyondzi Airport" },
    FCBS: { iata: "SIB", name: "Sibiti Airport" },
    FCBY: { iata: "NKY", name: "Yokangassi Airport" },
    FCBZ: { iata: "ANJ", name: "Zanaga Airport" },
    FCMM: { iata: "MSX", name: "Mossendjo Airport" },
    FCOB: { iata: "BOE", name: "Boundji Airport" },
    FCOE: { iata: "EWO", name: "Ewo Airport" },
    FCOG: { iata: "GMM", name: "Gamboma Airport" },
    FCOI: { iata: "ION", name: "Impfondo Airport" },
    FCOK: { iata: "KEE", name: "Kelle Airport" },
    FCOM: { iata: "MKJ", name: "Makoua Airport" },
    FCOO: { iata: "FTX", name: "Owando Airport" },
    FCOS: { iata: "SOE", name: "Souanke Airport" },
    FCOT: { iata: "BTB", name: "Betou Airport" },
    FCOU: { iata: "OUE", name: "Ouesso Airport" },
    FCPA: { iata: "KMK", name: "Makabana Airport" },
    FCPD: { iata: "DIS", name: "Ngot Nzoungou Airport" },
    FCPP: { iata: "PNR", name: "Pointe Noire Airport" },
    FDMS: { iata: "MTS", name: "Matsapha Airport" },
    FEFC: { iata: "CRF", name: "Carnot Airport" },
    FEFF: { iata: "BGF", name: "Bangui M'Poko International Airport" },
    FEFG: { iata: "BGU", name: "Bangassou Airport" },
    FEFI: { iata: "IRO", name: "Birao Airport" },
    FEFL: { iata: "BEM", name: "Bossembele Airport" },
    FEFM: { iata: "BBY", name: "Bambari Airport" },
    FEFN: { iata: "NDL", name: "N'Dele Airport" },
    FEFO: { iata: "BOP", name: "Bouar Airport" },
    FEFR: { iata: "BIV", name: "Bria Airport" },
    FEFS: { iata: "BSN", name: "Bossangoa Airport" },
    FEFT: { iata: "BBT", name: "Berberati Airport" },
    FEFW: { iata: "ODA", name: "Ouadda Airport" },
    FEFY: { iata: "AIG", name: "Yalinga Airport" },
    FEFZ: { iata: "IMO", name: "Zemio Airport" },
    FEGE: { iata: "MKI", name: "M'Boki Airport" },
    FEGF: { iata: "BTG", name: "Batangafo Airport" },
    FEGL: { iata: "GDI", name: "Gordil Airport" },
    FEGM: { iata: "BMF", name: "Bakouma Airport" },
    FEGO: { iata: "ODJ", name: "Ouanda Djalle Airport" },
    FEGR: { iata: "RFA", name: "Rafai Airport" },
    FEGU: { iata: "BCF", name: "Bouca Airport" },
    FEGZ: { iata: "BOZ", name: "Bozoum Airport" },
    FGBT: { iata: "BSG", name: "Bata Airport" },
    FGSL: { iata: "SSG", name: "Malabo Airport" },
    FHAW: { iata: "ASI", name: "RAF Ascension Island" },
    FIMP: {
      iata: "MRU",
      name: "Sir Seewoosagur Ramgoolam International Airport",
    },
    FIMR: { iata: "RRG", name: "Sir Charles Gaetan Duval Airport" },
    FJDG: { iata: "NKW", name: "Diego Garcia Naval Support Facility" },
    FKAN: { iata: "NKS", name: "Nkongsamba Airport" },
    FKKB: { iata: "KBI", name: "Kribi Airport" },
    FKKC: { iata: "TKC", name: "Tiko Airport" },
    FKKD: { iata: "DLA", name: "Douala International Airport" },
    FKKF: { iata: "MMF", name: "Mamfe Airport" },
    FKKG: { iata: "BLC", name: "Bali Airport" },
    FKKH: { iata: "KLE", name: "Kaele Airport" },
    FKKI: { iata: "OUR", name: "Batouri Airport" },
    FKKJ: { iata: "GXX", name: "Yagoua Airport" },
    FKKL: { iata: "MVR", name: "Salak Airport" },
    FKKM: { iata: "FOM", name: "Foumban Nkounja Airport" },
    FKKN: { iata: "NGE", name: "N'Gaoundere Airport" },
    FKKO: { iata: "BTA", name: "Bertoua Airport" },
    FKKR: { iata: "GOU", name: "Garoua International Airport" },
    FKKS: { iata: "DSC", name: "Dschang Airport" },
    FKKU: { iata: "BFX", name: "Bafoussam Airport" },
    FKKV: { iata: "BPC", name: "Bamenda Airport" },
    FKKW: { iata: "EBW", name: "Ebolowa Airport" },
    FKKY: { iata: "YAO", name: "Yaounde Airport" },
    FKYS: { iata: "NSI", name: "Yaounde Nsimalen International Airport" },
    FLBA: { iata: "MMQ", name: "Mbala Airport" },
    FLCP: { iata: "CIP", name: "Chipata Airport" },
    FLJK: { iata: "JEK", name: "Jeki Airstrip" },
    FLKE: { iata: "ZKP", name: "Kasompe Airport" },
    FLKK: { iata: "LUN", name: "Kenneth Kaunda International Airport" },
    FLKL: { iata: "KLB", name: "Kalabo Airport" },
    FLKO: { iata: "KMZ", name: "Kaoma Airport" },
    FLKS: { iata: "KAA", name: "Kasama Airport" },
    FLKY: { iata: "ZKB", name: "Kasaba Bay Airport" },
    FLLI: { iata: "LVI", name: "Livingstone Airport" },
    FLLK: { iata: "LXU", name: "Lukulu Airport" },
    FLLS: { iata: "LUN", name: "Lusaka International Airport" },
    FLMA: { iata: "MNS", name: "Mansa Airport" },
    FLMF: { iata: "MFU", name: "Mfuwe Airport" },
    FLMG: { iata: "MNR", name: "Mongu Airport" },
    FLNA: { iata: "ZGM", name: "Ngoma Airport" },
    FLND: { iata: "NLA", name: "Ndola Airport" },
    FLSN: { iata: "SXG", name: "Senanga Airport" },
    FLSO: { iata: "KIW", name: "Southdowns Airport" },
    FLSS: { iata: "SJQ", name: "Sesheke Airport" },
    FLSW: { iata: "SLI", name: "Solwesi Airport" },
    FLZB: { iata: "BBZ", name: "Zambezi Airport" },
    FMCH: { iata: "HAH", name: "Prince Said Ibrahim International Airport" },
    FMCI: { iata: "NWA", name: "Moheli Bandar Es Eslam Airport" },
    FMCN: { iata: "YVA", name: "Iconi Airport" },
    FMCV: { iata: "AJN", name: "Ouani Airport" },
    FMCZ: { iata: "DZA", name: "Dzaoudzi Pamandzi International Airport" },
    FMEE: { iata: "RUN", name: "Roland Garros Airport" },
    FMEP: { iata: "ZSE", name: "Pierrefonds Airport" },
    FMFE: { iata: "OHB", name: "Moramanga Aerodrome" },
    FMMC: { iata: "WML", name: "Malaimbandy Airport" },
    FMME: { iata: "ATJ", name: "Antsirabe Airport" },
    FMMG: { iata: "WAQ", name: "Antsalova Airport" },
    FMMH: { iata: "VVB", name: "Mahanoro Airport" },
    FMMI: { iata: "TNR", name: "Ivato Airport" },
    FMMK: { iata: "JVA", name: "Ankavandra Airport" },
    FMML: { iata: "BMD", name: "Belo sur Tsiribihina Airport" },
    FMMN: { iata: "ZVA", name: "Miandrivazo Airport" },
    FMMO: { iata: "MXT", name: "Maintirano Airport" },
    FMMQ: { iata: "ILK", name: "Atsinanana Airport" },
    FMMR: { iata: "TVA", name: "Morafenobe Airport" },
    FMMS: { iata: "SMS", name: "Sainte Marie Airport" },
    FMMT: { iata: "TMM", name: "Toamasina Airport" },
    FMMU: { iata: "WTA", name: "Tambohorano Airport" },
    FMMV: { iata: "MOQ", name: "Morondava Airport" },
    FMMX: { iata: "WTS", name: "Tsiroanomandidy Airport" },
    FMMY: { iata: "VAT", name: "Vatomandry Airport" },
    FMMZ: { iata: "WAM", name: "Ambatondrazaka Airport" },
    FMNA: { iata: "DIE", name: "Arrachart Airport" },
    FMNC: { iata: "WMR", name: "Mananara Nord Airport" },
    FMND: { iata: "ZWA", name: "Andapa Airport" },
    FMNE: { iata: "AMB", name: "Ambilobe Airport" },
    FMNF: { iata: "WBD", name: "Avaratra Airport" },
    FMNG: { iata: "WPB", name: "Port Berge Airport" },
    FMNH: { iata: "ANM", name: "Antsirabato Airport" },
    FMNJ: { iata: "IVA", name: "Ambanja Airport" },
    FMNL: { iata: "HVA", name: "Analalava Airport" },
    FMNM: { iata: "MJN", name: "Amborovy Airport" },
    FMNN: { iata: "NOS", name: "Fascene Airport" },
    FMNO: { iata: "DWB", name: "Soalala Airport" },
    FMNP: { iata: "WMP", name: "Mampikony Airport" },
    FMNQ: { iata: "BPY", name: "Besalampy Airport" },
    FMNR: { iata: "WMN", name: "Maroantsetra Airport" },
    FMNS: { iata: "SVB", name: "Sambava Airport" },
    FMNT: { iata: "TTS", name: "Tsaratanana Airport" },
    FMNV: { iata: "VOH", name: "Vohimarina Airport" },
    FMNW: { iata: "WAI", name: "Ambalabe Airport" },
    FMNX: { iata: "WMA", name: "Mandritsara Airport" },
    FMSB: { iata: "WBO", name: "Antsoa Airport" },
    FMSC: { iata: "WMD", name: "Mandabe Airport" },
    FMSD: { iata: "FTU", name: "Tolanaro Airport" },
    FMSF: { iata: "WFI", name: "Fianarantsoa Airport" },
    FMSG: { iata: "RVA", name: "Farafangana Airport" },
    FMSI: { iata: "IHO", name: "Ihosy Airport" },
    FMSJ: { iata: "MJA", name: "Manja Airport" },
    FMSK: { iata: "WVK", name: "Manakara Airport" },
    FMSL: { iata: "OVA", name: "Bekily Airport" },
    FMSM: { iata: "MNJ", name: "Mananjary Airport" },
    FMSN: { iata: "TDV", name: "Samangoky Airport" },
    FMSR: { iata: "MXM", name: "Morombe Airport" },
    FMST: { iata: "TLE", name: "Toliara Airport" },
    FMSU: { iata: "VND", name: "Vangaindrano Airport" },
    FMSV: { iata: "BKU", name: "Betioky Airport" },
    FMSY: { iata: "AMP", name: "Ampanihy Airport" },
    FMSZ: { iata: "WAK", name: "Ankazoabo Airport" },
    FNAM: { iata: "AZZ", name: "Ambriz Airport" },
    FNBC: { iata: "SSY", name: "Mbanza Congo Airport" },
    FNBG: { iata: "BUG", name: "Benguela Airport" },
    FNCA: { iata: "CAB", name: "Cabinda Airport" },
    FNCF: { iata: "CFF", name: "Cafunfo Airport" },
    FNCH: { iata: "PGI", name: "Chitato Airport" },
    FNCT: { iata: "CBT", name: "Catumbela Airport" },
    FNCV: { iata: "CTI", name: "Cuito Cuanavale Airport" },
    FNCX: { iata: "CXM", name: "Camaxilo Airport" },
    FNCZ: { iata: "CAV", name: "Cazombo Airport" },
    FNDU: { iata: "DUE", name: "Dundo Airport" },
    FNGI: { iata: "VPE", name: "Ngjiva Pereira Airport" },
    FNHU: { iata: "NOV", name: "Nova Lisboa Airport" },
    FNKU: { iata: "SVP", name: "Kuito Airport" },
    FNLB: { iata: "LLT", name: "Lobito Airport" },
    FNLK: { iata: "LBZ", name: "Lucapa Airport" },
    FNLU: { iata: "LAD", name: "Quatro De Fevereiro Airport" },
    FNLZ: { iata: "LZM", name: "Luzamba Airport" },
    FNMA: { iata: "MEG", name: "Malanje Airport" },
    FNME: { iata: "SPP", name: "Menongue Airport" },
    FNMO: { iata: "MSZ", name: "Namibe Airport" },
    FNNG: { iata: "GXG", name: "Negage Airport" },
    FNPA: { iata: "PBN", name: "Porto Amboim Airport" },
    FNSA: { iata: "VHC", name: "Saurimo Airport" },
    FNSO: { iata: "SZA", name: "Soyo Airport" },
    FNSU: { iata: "NDD", name: "Sumbe Airport" },
    FNUA: { iata: "UAL", name: "Luau Airport" },
    FNUB: { iata: "SDD", name: "Lubango Airport" },
    FNUE: { iata: "LUO", name: "Luena Airport" },
    FNUG: { iata: "UGO", name: "Uige Airport" },
    FNWK: { iata: "CEO", name: "Waco Kungo Airport" },
    FNXA: { iata: "XGN", name: "Xangongo Airport" },
    FNZE: { iata: "ARZ", name: "N'zeto Airport" },
    FNZG: { iata: "NZA", name: "Nzagi Airport" },
    FOGB: { iata: "BGB", name: "Booue Airport" },
    FOGE: { iata: "KDN", name: "Ndende Airport" },
    FOGF: { iata: "FOU", name: "Fougamou Airport" },
    FOGG: { iata: "MBC", name: "M'Bigou Airport" },
    FOGI: { iata: "MGX", name: "Moabi Airport" },
    FOGJ: { iata: "KDJ", name: "Ville Airport" },
    FOGK: { iata: "KOU", name: "Koulamoutou Airport" },
    FOGM: { iata: "MJL", name: "Mouilla Ville Airport" },
    FOGO: { iata: "OYE", name: "Oyem Airport" },
    FOGQ: { iata: "OKN", name: "Okondja Airport" },
    FOGR: { iata: "LBQ", name: "Lambarene Airport" },
    FOGV: { iata: "MVX", name: "Minvoul Airport" },
    FOOB: { iata: "BMM", name: "Bitam Airport" },
    FOOD: { iata: "MFF", name: "Moanda Airport" },
    FOOE: { iata: "MKB", name: "Mekambo Airport" },
    FOOG: { iata: "POG", name: "Port Gentil Airport" },
    FOOH: { iata: "OMB", name: "Omboue Hopital Airport" },
    FOOK: { iata: "MKU", name: "Makokou Airport" },
    FOOL: { iata: "LBV", name: "Leon M Ba Airport" },
    FOOM: { iata: "MZC", name: "Mitzic Airport" },
    FOON: {
      iata: "MVB",
      name: "M'Vengue El Hadj Omar Bongo Ondimba International Airport",
    },
    FOOR: { iata: "LTL", name: "Lastourville Airport" },
    FOOS: { iata: "ZKM", name: "Sette Cama Airport" },
    FOOT: { iata: "TCH", name: "Tchibanga Airport" },
    FOOY: { iata: "MYB", name: "Mayumba Airport" },
    FPPR: { iata: "PCP", name: "Principe Airport" },
    FPST: { iata: "TMS", name: "Sao Tome International Airport" },
    FQAG: { iata: "ANO", name: "Angoche Airport" },
    FQBR: { iata: "BEW", name: "Beira Airport" },
    FQCB: { iata: "FXO", name: "Cuamba Airport" },
    FQCH: { iata: "VPY", name: "Chimoio Airport" },
    FQIA: { iata: "IHC", name: "Inhaca Airport" },
    FQIN: { iata: "INH", name: "Inhambane Airport" },
    FQLC: { iata: "VXC", name: "Lichinga Airport" },
    FQLU: { iata: "LFB", name: "Lumbo Airport" },
    FQMA: { iata: "MPM", name: "Maputo Airport" },
    FQMD: { iata: "MUD", name: "Mueda Airport" },
    FQMP: { iata: "MZB", name: "Mocimboa da Praia Airport" },
    FQNC: { iata: "MNC", name: "Nacala Airport" },
    FQNP: { iata: "APL", name: "Nampula Airport" },
    FQPB: { iata: "POL", name: "Pemba Airport" },
    FQPO: { iata: "PDD", name: "Ponta do Ouro Airport" },
    FQQL: { iata: "UEL", name: "Quelimane Airport" },
    FQTT: { iata: "TET", name: "Chingozi Airport" },
    FQVL: { iata: "VNX", name: "Vilankulo Airport" },
    FQXA: { iata: "VJB", name: "Xai-Xai Airport" },
    FSDR: { iata: "DES", name: "Desroches Airport" },
    FSIA: { iata: "SEZ", name: "Seychelles International Airport" },
    FSPP: { iata: "PRI", name: "Praslin Airport" },
    FSSB: { iata: "BDI", name: "Bird Island Airport" },
    FSSD: { iata: "DEI", name: "Denis Island Airport" },
    FSSF: { iata: "FRK", name: "Fregate Island Airport" },
    FTTA: { iata: "SRH", name: "Sarh Airport" },
    FTTB: { iata: "OGR", name: "Bongor Airport" },
    FTTC: { iata: "AEH", name: "Abeche Airport" },
    FTTD: { iata: "MQQ", name: "Moundou Airport" },
    FTTH: { iata: "LTC", name: "Lai Airport" },
    FTTI: { iata: "ATV", name: "Ati Airport" },
    FTTJ: { iata: "NDJ", name: "N'Djamena International Airport" },
    FTTK: { iata: "BKR", name: "Bokoro Airport" },
    FTTL: { iata: "OTC", name: "Bol Airport" },
    FTTM: { iata: "MVO", name: "Mongo Airport" },
    FTTN: { iata: "AMC", name: "Am Timan Airport" },
    FTTP: { iata: "PLF", name: "Pala Airport" },
    FTTS: { iata: "OUT", name: "Bousso Airport" },
    FTTU: { iata: "AMO", name: "Mao Airport" },
    FTTY: { iata: "FYT", name: "Faya Largeau Airport" },
    FVBU: { iata: "BUQ", name: "Joshua Mqabuko Nkomo International Airport" },
    FVCH: { iata: "CHJ", name: "Chipinge Airport" },
    FVCZ: { iata: "BFO", name: "Buffalo Range Airport" },
    FVFA: { iata: "VFA", name: "Victoria Falls International Airport" },
    FVHA: { iata: "HRE", name: "Harare International Airport" },
    FVKB: { iata: "KAB", name: "Kariba International Airport" },
    FVMU: { iata: "UTA", name: "Mutare Airport" },
    FVMV: { iata: "MVZ", name: "Masvingo International Airport" },
    FVTL: { iata: "GWE", name: "Thornhill Air Base" },
    FVWN: { iata: "HWN", name: "Hwange National Park Airport" },
    FVWT: { iata: "WKI", name: "Hwange Airport" },
    FWCD: { iata: "CEH", name: "Chelinda Malawi Airport" },
    FWCL: { iata: "BLZ", name: "Chileka International Airport" },
    FWCM: { iata: "CMK", name: "Club Makokola Airport" },
    FWDW: { iata: "DWA", name: "Dwangwa Airport" },
    FWKA: { iata: "KGJ", name: "Karonga Airport" },
    FWKG: { iata: "KBQ", name: "Kasungu Airport" },
    FWKI: { iata: "LLW", name: "Lilongwe International Airport" },
    FWLK: { iata: "LIX", name: "Likoma Island Airport" },
    FWMG: { iata: "MAI", name: "Mangochi Airport" },
    FWMY: { iata: "MYZ", name: "Monkey Bay Airport" },
    FWSM: { iata: "LMB", name: "Salima Airport" },
    FWUU: { iata: "ZZU", name: "Mzuzu Airport" },
    FXLK: { iata: "LEF", name: "Lebakeng Airport" },
    FXLR: { iata: "LRB", name: "Leribe Airport" },
    FXLS: { iata: "LES", name: "Lesobeng Airport" },
    FXMA: { iata: "MSG", name: "Matsaile Airport" },
    FXMF: { iata: "MFC", name: "Mafeteng Airport" },
    FXMK: { iata: "MKH", name: "Mokhotlong Airport" },
    FXMM: { iata: "MSU", name: "Moshoeshoe I International Airport" },
    FXNK: { iata: "NKU", name: "Nkaus Airport" },
    FXPG: { iata: "PEL", name: "Pelaneng Airport" },
    FXQG: { iata: "UTG", name: "Quthing Airport" },
    FXQN: { iata: "UNE", name: "Qacha's Nek Airport" },
    FXSH: { iata: "SHK", name: "Sehonghong Airport" },
    FXSK: { iata: "SKQ", name: "Sekakes Airport" },
    FXSM: { iata: "SOK", name: "Semonkong Airport" },
    FXSS: { iata: "SHZ", name: "Seshutes Airport" },
    FXTA: { iata: "THB", name: "Thaba-Tseka Airport" },
    FXTK: { iata: "TKO", name: "Tlokoeng Airport" },
    FYAR: { iata: "ADI", name: "Arandis Airport" },
    FYGB: { iata: "GOG", name: "Gobabis Airport" },
    FYGF: { iata: "GFY", name: "Grootfontein Airport" },
    FYKM: { iata: "MPA", name: "Katima Mulilo Airport" },
    FYKT: { iata: "KMP", name: "Keetmanshoop Airport" },
    FYLS: { iata: "LHU", name: "Lianshulu Airport" },
    FYLZ: { iata: "LUD", name: "Luderitz Airport" },
    FYME: { iata: "MJO", name: "Mount Etjo Airport" },
    FYMO: { iata: "OKU", name: "Mokuti Lodge Airport" },
    FYNA: { iata: "NNI", name: "Namutoni Airport" },
    FYOA: { iata: "OND", name: "Ondangwa Airport" },
    FYOE: { iata: "OMG", name: "Omega Airport" },
    FYOG: { iata: "OMD", name: "Oranjemund Airport" },
    FYOO: { iata: "OKF", name: "Okaukuejo Airport" },
    FYOW: { iata: "OTJ", name: "Otjiwarongo Airport" },
    FYRU: { iata: "NDU", name: "Rundu Airport" },
    FYSA: { iata: "RHN", name: "Skorpion Mine Airport" },
    FYSM: { iata: "SWP", name: "Swakopmund Airport" },
    FYSS: { iata: "SZM", name: "Sesriem Airstrip" },
    FYTM: { iata: "TSB", name: "Tsumeb Airport" },
    FYWB: { iata: "WVB", name: "Walvis Bay Airport" },
    FYWE: { iata: "ERS", name: "Eros Airport" },
    FYWH: { iata: "WDH", name: "Hosea Kutako International Airport" },
    FZAA: { iata: "FIH", name: "Ndjili International Airport" },
    FZAB: { iata: "NLO", name: "Ndolo Airport" },
    FZAG: { iata: "MNB", name: "Muanda Airport" },
    FZAJ: { iata: "BOA", name: "Boma Airport" },
    FZAL: { iata: "LZI", name: "Luozi Airport" },
    FZAM: { iata: "MAT", name: "Tshimpi Airport" },
    FZAR: { iata: "NKL", name: "Nkolo Fuma Airport" },
    FZBA: { iata: "INO", name: "Inongo Airport" },
    FZBI: { iata: "NIO", name: "Nioki Airport" },
    FZBO: { iata: "FDU", name: "Bandundu Airport" },
    FZBT: { iata: "KRZ", name: "Basango Mboliasa Airport" },
    FZCA: { iata: "KKW", name: "Kikwit Airport" },
    FZCB: { iata: "IDF", name: "Idiofa Airport" },
    FZCE: { iata: "LUS", name: "Lusanga Airport" },
    FZCV: { iata: "MSM", name: "Masi Manimba Airport" },
    FZEA: { iata: "MDK", name: "Mbandaka Airport" },
    FZEN: { iata: "BSU", name: "Basankusu Airport" },
    FZFA: { iata: "LIE", name: "Libenge Airport" },
    FZFD: { iata: "BDT", name: "Gbadolite Airport" },
    FZFK: { iata: "GMA", name: "Gemena Airport" },
    FZFP: { iata: "KLI", name: "Kotakoli Airport" },
    FZFU: { iata: "BMB", name: "Bumbar Airport" },
    FZGA: { iata: "LIQ", name: "Lisala Airport" },
    FZGN: { iata: "BNB", name: "Boende Airport" },
    FZGV: { iata: "IKL", name: "Ikela Airport" },
    FZIC: { iata: "FKI", name: "Bangoka International Airport" },
    FZIR: { iata: "YAN", name: "Yangambi Airport" },
    FZJH: { iata: "IRP", name: "Matari Airport" },
    FZKA: { iata: "BUX", name: "Bunia Airport" },
    FZKJ: { iata: "BZU", name: "Buta Zega Airport" },
    FZMA: { iata: "BKY", name: "Bukavu Kavumu Airport" },
    FZMB: { iata: "RUE", name: "Rughenda Airfield" },
    FZNA: { iata: "GOM", name: "Goma International Airport" },
    FZNP: { iata: "BNC", name: "Beni Airport" },
    FZOA: { iata: "KND", name: "Kindu Airport" },
    FZOD: { iata: "KLY", name: "Kinkungwa Airport" },
    FZOK: { iata: "KGN", name: "Kasongo Airport" },
    FZOP: { iata: "PUN", name: "Punia Airport" },
    FZQA: { iata: "FBM", name: "Lubumbashi International Airport" },
    FZQC: { iata: "PWO", name: "Pweto Airport" },
    FZQG: { iata: "KEC", name: "Kasenga Airport" },
    FZQM: { iata: "KWZ", name: "Kolwezi Airport" },
    FZRA: { iata: "MNO", name: "Manono Airport" },
    FZRB: { iata: "BDV", name: "Moba Airport" },
    FZRF: { iata: "FMI", name: "Kalemie Airport" },
    FZRM: { iata: "KBO", name: "Kabalo Airport" },
    FZRQ: { iata: "KOO", name: "Kongolo Airport" },
    FZSA: { iata: "KMN", name: "Kamina Base Airport" },
    FZSB: { iata: "KMN", name: "Ville Airport" },
    FZSK: { iata: "KAP", name: "Kapanga Airport" },
    FZTK: { iata: "KNM", name: "Kaniama Airport" },
    FZUA: { iata: "KGA", name: "Kananga Airport" },
    FZUG: { iata: "LZA", name: "Luiza Airport" },
    FZUK: { iata: "TSH", name: "Tshikapa Airport" },
    FZVA: { iata: "LJA", name: "Lodja Airport" },
    FZVI: { iata: "LBO", name: "Lusambo Airport" },
    FZVM: { iata: "MEW", name: "Mweka Airport" },
    FZVR: { iata: "BAN", name: "Basongo Airport" },
    FZVS: { iata: "PFR", name: "Ilebo Airport" },
    FZWA: { iata: "MJM", name: "Mbuji Mayi Airport" },
    FZWC: { iata: "GDJ", name: "Gandajika Airport" },
    FZWT: { iata: "KBN", name: "Tunta Airport" },
    GABS: { iata: "BKO", name: "Senou Airport" },
    GAGM: { iata: "GUD", name: "Goundam Airport" },
    GAGO: { iata: "GAQ", name: "Gao Airport" },
    GAKA: { iata: "KNZ", name: "Kenieba Airport" },
    GAKO: { iata: "KTX", name: "Koutiala Airport" },
    GAKY: { iata: "KYS", name: "Kayes Dag Dag Airport" },
    GAMB: { iata: "MZI", name: "Ambodedjo Airport" },
    GANK: { iata: "NRM", name: "Nara Airport" },
    GANR: { iata: "NIX", name: "Nioro du Sahel Airport" },
    GASK: { iata: "KSS", name: "Sikasso Airport" },
    GATB: { iata: "TOM", name: "Timbuktu Airport" },
    GAYE: { iata: "EYL", name: "Yelimane Airport" },
    GBYD: { iata: "BJL", name: "Banjul International Airport" },
    GCFV: { iata: "FUE", name: "Fuerteventura Airport" },
    GCGM: { iata: "GMZ", name: "La Gomera Airport" },
    GCHI: { iata: "VDE", name: "Hierro Airport" },
    GCLA: { iata: "SPC", name: "La Palma Airport" },
    GCLP: { iata: "LPA", name: "Gran Canaria Airport" },
    GCRR: { iata: "ACE", name: "Lanzarote Airport" },
    GCTS: { iata: "TFS", name: "Tenerife South Airport" },
    GCXO: { iata: "TFN", name: "Tenerife Norte Airport" },
    GEML: { iata: "MLN", name: "Melilla Airport" },
    GFBN: { iata: "BTE", name: "Sherbro International Airport" },
    GFBO: { iata: "KBS", name: "Bo Airport" },
    GFGK: { iata: "GBK", name: "Gbangbatok Airport" },
    GFHA: { iata: "HGS", name: "Hastings Airport" },
    GFKB: { iata: "KBA", name: "Kabala Airport" },
    GFKE: { iata: "KEN", name: "Kenema Airport" },
    GFLL: { iata: "FNA", name: "Lungi International Airport" },
    GFYE: { iata: "WYE", name: "Yengema Airport" },
    GGBU: { iata: "BQE", name: "Bubaque Airport" },
    GGOV: { iata: "OXB", name: "Osvaldo Vieira International Airport" },
    GLBU: { iata: "UCN", name: "Buchanan Airport" },
    GLCP: { iata: "CPA", name: "Cape Palmas Airport" },
    GLGE: { iata: "SNI", name: "Greenville Sinoe Airport" },
    GLMR: { iata: "MLW", name: "Spriggs Payne Airport" },
    GLNA: { iata: "NIA", name: "Nimba Airport" },
    GLRB: { iata: "ROB", name: "Roberts International Airport" },
    GLST: { iata: "SAZ", name: "Sasstown Airport" },
    GLTN: { iata: "THC", name: "Tchien Airport" },
    GLVA: { iata: "VOI", name: "Voinjama Airport" },
    GMAD: { iata: "AGA", name: "Al Massira Airport" },
    GMAT: { iata: "TTA", name: "Tan Tan Airport" },
    GMAZ: { iata: "OZG", name: "Zagora Airport" },
    GMFB: { iata: "UAR", name: "Bouarfa Airport" },
    GMFF: { iata: "FEZ", name: "Saiss Airport" },
    GMFK: { iata: "ERH", name: "Moulay Ali Cherif Airport" },
    GMFM: { iata: "MEK", name: "Bassatine Airport" },
    GMFO: { iata: "OUD", name: "Angads Airport" },
    GMMA: { iata: "SMW", name: "Smara Airport" },
    GMMB: { iata: "GMD", name: "Ben Slimane Airport" },
    GMMC: { iata: "CAS", name: "Anfa Airport" },
    GMMD: { iata: "BEM", name: "Beni Mellal Airport" },
    GMME: { iata: "RBA", name: "Rabat-Sale Airport" },
    GMMF: { iata: "SII", name: "Sidi Ifni Xx Airport" },
    GMMH: { iata: "VIL", name: "Dakhla Airport" },
    GMMI: { iata: "ESU", name: "Mogador Airport" },
    GMML: { iata: "EUN", name: "Hassan I Airport" },
    GMMN: { iata: "CMN", name: "Mohammed V International Airport" },
    GMMS: { iata: "SFI", name: "Safi Airport" },
    GMMW: { iata: "NDR", name: "Nador International Airport" },
    GMMX: { iata: "RAK", name: "Menara Airport" },
    GMMY: { iata: "NNA", name: "Kenitra Airport" },
    GMMZ: { iata: "OZZ", name: "Ouarzazate Airport" },
    GMTA: { iata: "AHU", name: "Cherif Al Idrissi Airport" },
    GMTN: { iata: "TTU", name: "Saniat Rmel Airport" },
    GMTT: { iata: "TNG", name: "Ibn Batouta Airport" },
    GOBD: { iata: "DSS", name: "Blaise Diagne International Airport" },
    GODK: { iata: "KDA", name: "Kolda North Airport" },
    GOGG: { iata: "ZIG", name: "Ziguinchor Airport" },
    GOGS: { iata: "CSK", name: "Cap Skirring Airport" },
    GOOK: { iata: "KLC", name: "Kaolack Airport" },
    GOOY: { iata: "DKR", name: "Leopold Sedar Senghor International Airport" },
    GOSM: { iata: "MAX", name: "Ouro Sogui Airport" },
    GOSP: { iata: "POD", name: "Podor Airport" },
    GOSR: { iata: "RDT", name: "Richard Toll Airport" },
    GOSS: { iata: "XLS", name: "Saint Louis Airport" },
    GOTB: { iata: "BXE", name: "Bakel Airport" },
    GOTK: { iata: "KGG", name: "Kedougou Airport" },
    GOTS: { iata: "SMY", name: "Simenti Airport" },
    GOTT: { iata: "TUD", name: "Tambacounda Airport" },
    GQNA: { iata: "AEO", name: "Aioun el Atrouss Airport" },
    GQNB: { iata: "OTL", name: "Boutilimit Airport" },
    GQNC: { iata: "THI", name: "Tichitt Airport" },
    GQND: { iata: "TIY", name: "Tidjikja Airport" },
    GQNE: { iata: "BGH", name: "Abbaye Airport" },
    GQNF: { iata: "KFA", name: "Kiffa Airport" },
    GQNH: { iata: "TMD", name: "Timbedra Airport" },
    GQNI: { iata: "EMN", name: "Nema Airport" },
    GQNJ: { iata: "AJJ", name: "Akjoujt Airport" },
    GQNK: { iata: "KED", name: "Kaedi Airport" },
    GQNL: { iata: "MOM", name: "Letfotar Airport" },
    GQNN: { iata: "NKC", name: "Nouakchott International Airport" },
    GQNS: { iata: "SEY", name: "Selibaby Airport" },
    GQNT: { iata: "THT", name: "Tamchakett Airport" },
    GQPA: { iata: "ATR", name: "Atar International Airport" },
    GQPF: { iata: "FGD", name: "Fderik Airport" },
    GQPP: { iata: "NDB", name: "Nouadhibou International Airport" },
    GQPZ: { iata: "OUZ", name: "Tazadit Airport" },
    GUCY: { iata: "CKY", name: "Conakry Airport" },
    GUFA: { iata: "FIG", name: "Fria Airport" },
    GUFH: { iata: "FAA", name: "Faranah Airport" },
    GUKU: { iata: "KSI", name: "Kissidougou Airport" },
    GULB: { iata: "LEK", name: "Labe Airport" },
    GUMA: { iata: "MCA", name: "Macenta Airport" },
    GUNZ: { iata: "NZE", name: "Nzerekore Airport" },
    GUOK: { iata: "BKJ", name: "Boke Airport" },
    GUSB: { iata: "SBI", name: "Sambailo Airport" },
    GUSI: { iata: "GII", name: "Siguiri Airport" },
    GUXN: { iata: "KNN", name: "Kankan Airport" },
    GVAC: { iata: "SID", name: "Amilcar Cabral International Airport" },
    GVAN: { iata: "NTO", name: "Agostinho Neto Airport" },
    GVBA: { iata: "BVC", name: "Rabil Airport" },
    GVBR: { iata: "BVR", name: "Esperadinha Airport" },
    GVMA: { iata: "MMO", name: "Maio Airport" },
    GVMT: { iata: "MTI", name: "Mosteiros Airport" },
    GVNP: { iata: "RAI", name: "Praia International Airport" },
    GVSF: { iata: "SFL", name: "Sao Filipe Airport" },
    GVSN: { iata: "SNE", name: "Preguica Airport" },
    GVSV: { iata: "VXE", name: "Sao Pedro Airport" },
    HAAB: { iata: "ADD", name: "Bole International Airport" },
    HAAM: { iata: "AMH", name: "Arba Minch Airport" },
    HAAX: { iata: "AXU", name: "Axum Airport" },
    HABC: { iata: "BCO", name: "Baco Airport" },
    HABD: { iata: "BJR", name: "Bahir Dar Airport" },
    HABE: { iata: "BEI", name: "Beica Airport" },
    HADC: { iata: "DSE", name: "Combolcha Airport" },
    HADD: { iata: "DEM", name: "Dembidollo Airport" },
    HADM: { iata: "DBM", name: "Debra Marcos Airport" },
    HADR: {
      iata: "DIR",
      name: "Aba Tenna Dejazmach Yilma International Airport",
    },
    HADT: { iata: "DBT", name: "Debre Tabor Airport" },
    HAFN: { iata: "FNH", name: "Fincha Airport" },
    HAGB: { iata: "GOB", name: "Robe Airport" },
    HAGH: { iata: "GNN", name: "Ghinnir Airport" },
    HAGM: { iata: "GMB", name: "Gambella Airport" },
    HAGN: { iata: "GDQ", name: "Gonder Airport" },
    HAGO: { iata: "GDE", name: "Gode Airport" },
    HAGR: { iata: "GOR", name: "Gore Airport" },
    HAHM: { iata: "QHR", name: "Harar Meda Airport" },
    HAHU: { iata: "HUE", name: "Humera Airport" },
    HAJJ: { iata: "JIJ", name: "Jijiga Airport" },
    HAJM: { iata: "JIM", name: "Jimma Airport" },
    HAKD: { iata: "ABK", name: "Kabri Dehar Airport" },
    HAKL: { iata: "LFO", name: "Kelafo East Airport" },
    HALA: { iata: "AWA", name: "Awassa Airport" },
    HALL: { iata: "LLI", name: "Lalibella Airport" },
    HAMJ: { iata: "TUJ", name: "Tume Airport" },
    HAMK: { iata: "MQX", name: "Mekele Airport" },
    HAML: { iata: "MZX", name: "Masslo Airport" },
    HAMM: { iata: "ETE", name: "Metema Airport" },
    HAMN: { iata: "NDM", name: "Mendi Airport" },
    HAMT: { iata: "MTF", name: "Mizan Teferi Airport" },
    HANJ: { iata: "NEJ", name: "Nejjo Airport" },
    HANK: { iata: "NEK", name: "Nekemte Airport" },
    HASC: { iata: "SHC", name: "Shire Airport" },
    HASD: { iata: "SXU", name: "Soddu Airport" },
    HASM: { iata: "SZE", name: "Semera Airport" },
    HASO: { iata: "ASO", name: "Asosa Airport" },
    HATP: { iata: "TIE", name: "Tippi Airport" },
    HAWC: { iata: "WAC", name: "Waca Airport" },
    HBBA: { iata: "BJM", name: "Bujumbura International Airport" },
    HBBE: { iata: "GID", name: "Gitega Airport" },
    HBBO: { iata: "KRE", name: "Kirundo Airport" },
    HCMA: { iata: "ALU", name: "Alula Airport" },
    HCMB: { iata: "BIB", name: "Baidoa Airport" },
    HCMC: { iata: "CXN", name: "Candala Airport" },
    HCMD: { iata: "BSY", name: "Bardera Airport" },
    HCME: { iata: "HCM", name: "Eil Airport" },
    HCMF: { iata: "BSA", name: "Bosaso Airport" },
    HCMG: { iata: "GSR", name: "Gardo Airport" },
    HCMH: { iata: "HGA", name: "Egal International Airport" },
    HCMI: { iata: "BBO", name: "Berbera Airport" },
    HCMK: { iata: "KMU", name: "Kisimayu Airport" },
    HCMM: { iata: "MGQ", name: "Aden Adde International Airport" },
    HCMO: { iata: "CMO", name: "Obbia Airport" },
    HCMR: { iata: "GLK", name: "Galcaio Airport" },
    HCMS: { iata: "CMS", name: "Scusciuban Airport" },
    HCMU: { iata: "ERA", name: "Erigavo Airport" },
    HCMV: { iata: "BUO", name: "Burao Airport" },
    HDAM: { iata: "JIB", name: "Djibouti-Ambouli Airport" },
    HDAS: { iata: "AII", name: "Ali-Sabieh Airport" },
    HDMO: { iata: "MHI", name: "Moucha Airport" },
    HDOB: { iata: "OBC", name: "Obock Airport" },
    HDTJ: { iata: "TDJ", name: "Tadjoura Airport" },
    HE24: { iata: "SEW", name: "Siwa Oasis North Airport" },
    HEAL: { iata: "DBB", name: "El Alamein International Airport" },
    HEAR: { iata: "AAC", name: "El Arish International Airport" },
    HEAT: { iata: "ATZ", name: "Assiut International Airport" },
    HEAX: { iata: "ALY", name: "El Nouzha Airport" },
    HEBA: { iata: "HBE", name: "Borg El Arab International Airport" },
    HEBL: { iata: "ABS", name: "Abu Simbel Airport" },
    HECA: { iata: "CAI", name: "Cairo International Airport" },
    HECW: { iata: "CWE", name: "Cairo West Airport" },
    HEDK: { iata: "DAK", name: "Dakhla Airport" },
    HEGN: { iata: "HRG", name: "Hurghada International Airport" },
    HEGR: { iata: "EGH", name: "El Gora Airport" },
    HEKG: { iata: "UVL", name: "El Kharga Airport" },
    HELX: { iata: "LXR", name: "Luxor International Airport" },
    HEMA: { iata: "RMF", name: "Marsa Alam International Airport" },
    HEMK: { iata: "HMB", name: "Sohag International Airport" },
    HEMM: { iata: "MUH", name: "Mersa Matruh Airport" },
    HEOW: { iata: "GSQ", name: "Shark El Oweinat International Airport" },
    HEPS: { iata: "PSD", name: "Port Said Airport" },
    HESC: { iata: "SKV", name: "St Catherine International Airport" },
    HESH: { iata: "SSH", name: "Sharm El Sheikh International Airport" },
    HESN: { iata: "ASW", name: "Aswan International Airport" },
    HESX: { iata: "SPX", name: "Sphinx International Airport" },
    HETB: { iata: "TCP", name: "Taba International Airport" },
    HETR: { iata: "ELT", name: "El Tor Airport" },
    HHAS: { iata: "ASM", name: "Asmara International Airport" },
    HHMS: { iata: "MSW", name: "Massawa International Airport" },
    HHSB: { iata: "ASA", name: "Assab International Airport" },
    HHTS: { iata: "TES", name: "Tessenei Airport" },
    HKAM: { iata: "ASV", name: "Amboseli Airport" },
    HKEL: { iata: "EDL", name: "Eldoret International Airport" },
    HKES: { iata: "EYS", name: "Eliye Springs Airport" },
    HKFG: { iata: "KLK", name: "Kalokol Airport" },
    HKGA: { iata: "GAS", name: "Garissa Airport" },
    HKHO: { iata: "HOA", name: "Hola Airport" },
    HKJK: { iata: "NBO", name: "Jomo Kenyatta International Airport" },
    HKKE: { iata: "KEU", name: "Keekorok Airport" },
    HKKG: { iata: "GGM", name: "Kakamega Airport" },
    HKKI: { iata: "KIS", name: "Kisumu Airport" },
    HKKL: { iata: "ILU", name: "Kilaguni Airport" },
    HKKR: { iata: "KEY", name: "Kericho Airport" },
    HKKT: { iata: "KTL", name: "Kitale Airport" },
    HKLK: { iata: "LKG", name: "Lokichoggio Airport" },
    HKLO: { iata: "LOK", name: "Lodwar Airport" },
    HKLU: { iata: "LAU", name: "Manda Airstrip" },
    HKLY: { iata: "LOY", name: "Loyengalani Airport" },
    HKMA: { iata: "NDE", name: "Mandera Airport" },
    HKMB: { iata: "RBT", name: "Segel Airport" },
    HKMK: { iata: "JJM", name: "Mulika Lodge Airport" },
    HKML: { iata: "MYD", name: "Malindi Airport" },
    HKMO: { iata: "MBA", name: "Mombasa Moi International Airport" },
    HKMS: { iata: "MRE", name: "Mara Serena Lodge Airstrip" },
    HKMY: { iata: "OYL", name: "Moyale Airport" },
    HKNI: { iata: "NYE", name: "Nyeri Airport" },
    HKNK: { iata: "NUU", name: "Nakuru Airport" },
    HKNW: { iata: "WIL", name: "Nairobi Wilson Airport" },
    HKNY: { iata: "NYK", name: "Nanyuki Airport" },
    HKSB: { iata: "UAS", name: "Samburu South Airport" },
    HKUK: { iata: "UKA", name: "Ukunda Airstrip" },
    HKWJ: { iata: "WJR", name: "Wajir Airport" },
    HLGD: { iata: "SRX", name: "Gardabya Airport" },
    HLGN: { iata: "TOB", name: "Gamal Abdel Nasser Airport" },
    HLGT: { iata: "GHT", name: "Ghat Airport" },
    HLKF: { iata: "AKF", name: "Kufra Airport" },
    HLLB: { iata: "BEN", name: "Benina International Airport" },
    HLLM: { iata: "MJI", name: "Mitiga Airport" },
    HLLQ: { iata: "LAQ", name: "La Abraq Airport" },
    HLLS: { iata: "SEB", name: "Sabha Airport" },
    HLLT: { iata: "TIP", name: "Tripoli International Airport" },
    HLMB: { iata: "LMQ", name: "Marsa Brega Airport" },
    HLNR: { iata: "NFR", name: "Nafurah 1 Airport" },
    HLON: { iata: "HUQ", name: "Hon Airport" },
    HLTD: { iata: "LTD", name: "Ghadames East Airport" },
    HLZW: { iata: "WAX", name: "Zwara Airport" },
    HRYG: { iata: "GYI", name: "Gisenyi Airport" },
    HRYI: { iata: "BTQ", name: "Butare Airport" },
    HRYR: { iata: "KGL", name: "Kigali International Airport" },
    HRYU: { iata: "RHG", name: "Ruhengeri Airport" },
    HRZA: { iata: "KME", name: "Kamembe Airport" },
    HSAT: { iata: "ATB", name: "Atbara Airport" },
    HSDB: { iata: "EDB", name: "El Debba Airport" },
    HSDN: { iata: "DOG", name: "Dongola Airport" },
    HSDZ: { iata: "RSS", name: "Damazin Airport" },
    HSFS: { iata: "ELF", name: "El Fasher Airport" },
    HSGF: { iata: "GSU", name: "Azaza Airport" },
    HSGG: { iata: "DNX", name: "Galegu Airport" },
    HSGN: { iata: "EGN", name: "Geneina Airport" },
    HSHG: { iata: "HEG", name: "Heglig Airport" },
    HSKA: { iata: "KSL", name: "Kassala Airport" },
    HSKG: { iata: "GBU", name: "Khashm El Girba Airport" },
    HSKI: { iata: "KST", name: "Kosti Airport" },
    HSLI: { iata: "KDX", name: "Kadugli Airport" },
    HSMK: { iata: "RBX", name: "Rumbek Airport" },
    HSMR: { iata: "MWE", name: "Merowe Airport" },
    HSNH: { iata: "NUD", name: "En Nahud Airport" },
    HSNN: { iata: "UYL", name: "Nyala Airport" },
    HSNW: { iata: "NHF", name: "New Halfa Airport" },
    HSOB: { iata: "EBD", name: "El Obeid Airport" },
    HSPN: { iata: "PZU", name: "Port Sudan New International Airport" },
    HSSJ: { iata: "JUB", name: "Juba Airport" },
    HSSM: { iata: "MAK", name: "Malakal Airport" },
    HSSS: { iata: "KRT", name: "Khartoum International Airport" },
    HSSW: { iata: "WHF", name: "Wadi Halfa Airport" },
    HSWW: { iata: "WUU", name: "Wau Airport" },
    HSZA: { iata: "ZLX", name: "Zalingei Airport" },
    HTAR: { iata: "ARK", name: "Arusha Airport" },
    HTBU: { iata: "BKZ", name: "Bukoba Airport" },
    HTDA: {
      iata: "DAR",
      name: "Mwalimu Julius K. Nyerere International Airport",
    },
    HTDO: { iata: "DOD", name: "Dodoma Airport" },
    HTIR: { iata: "IRI", name: "Iringa Airport" },
    HTKA: { iata: "TKQ", name: "Kigoma Airport" },
    HTKI: { iata: "KIY", name: "Kilwa Masoko Airport" },
    HTKJ: { iata: "JRO", name: "Kilimanjaro International Airport" },
    HTLI: { iata: "LDI", name: "Kikwetu Airport" },
    HTLM: { iata: "LKY", name: "Lake Manyara Airport" },
    HTMA: { iata: "MFA", name: "Mafia Island Airport" },
    HTMB: { iata: "MBI", name: "Mbeya Airport" },
    HTMD: { iata: "MWN", name: "Mwadui Airport" },
    HTMI: { iata: "XMI", name: "Masasi Airport" },
    HTMS: { iata: "QSI", name: "Moshi Airport" },
    HTMT: { iata: "MYW", name: "Mtwara Airport" },
    HTMU: { iata: "MUZ", name: "Musoma Airport" },
    HTMW: { iata: "MWZ", name: "Mwanza Airport" },
    HTNA: { iata: "NCH", name: "Nachingwea Airport" },
    HTNJ: { iata: "JOM", name: "Njombe Airport" },
    HTPE: { iata: "PMA", name: "Pemba Airport" },
    HTSN: { iata: "SEU", name: "Seronera Airport" },
    HTSO: { iata: "SGX", name: "Songea Airport" },
    HTSU: { iata: "SUT", name: "Sumbawanga Airport" },
    HTSY: { iata: "SHY", name: "Shinyanga Airport" },
    HTTB: { iata: "TBO", name: "Tabora Airport" },
    HTTG: { iata: "TGT", name: "Tanga Airport" },
    HTZA: { iata: "ZNZ", name: "Zanzibar Airport" },
    HUAR: { iata: "RUA", name: "Arua Airport" },
    HUEN: { iata: "EBB", name: "Entebbe International Airport" },
    HUGU: { iata: "ULU", name: "Gulu Airport" },
    HUJI: { iata: "JIN", name: "Jinja Airport" },
    HUKF: { iata: "KBG", name: "Kabalega Falls Airport" },
    HUKS: { iata: "KSE", name: "Kasese Airport" },
    HUMA: { iata: "MBQ", name: "Mbarara Airport" },
    HUMI: { iata: "KCU", name: "Masindi Airport" },
    HUPA: { iata: "PAF", name: "Pakuba Airport" },
    HUSO: { iata: "SRT", name: "Soroti Airport" },
    HUTO: { iata: "TRY", name: "Tororo Airport" },
    K00C: { iata: "AMK", name: "Animas Air Park" },
    K00F: { iata: "BDX", name: "Broadus Airport" },
    K0B8: { iata: "FID", name: "Elizabeth Field" },
    K1G4: { iata: "GCW", name: "Grand Canyon West Airport" },
    K1O5: { iata: "RKC", name: "Montague-Yreka Rohrer Field" },
    K2O1: { iata: "GNF", name: "Gansner Field" },
    K3S8: { iata: "GTP", name: "Grants Pass Airport" },
    K3W7: { iata: "GCD", name: "Grand Coulee Dam Airport" },
    K40J: { iata: "FPY", name: "Perry Foley Airport" },
    K6S2: { iata: "FMU", name: "Florence Municipal Airport" },
    K80F: { iata: "ATE", name: "Antlers Municipal Airport" },
    KAAF: { iata: "AAF", name: "Apalachicola Regional Airport" },
    KABE: { iata: "ABE", name: "Lehigh Valley International Airport" },
    KABI: { iata: "ABI", name: "Abilene Regional Airport" },
    KABQ: { iata: "ABQ", name: "Albuquerque International Sunport Airport" },
    KABR: { iata: "ABR", name: "Aberdeen Regional Airport" },
    KABY: { iata: "ABY", name: "Southwest Georgia Regional Airport" },
    KACB: { iata: "ACB", name: "Antrim County Airport" },
    KACK: { iata: "ACK", name: "Nantucket Memorial Airport" },
    KACT: { iata: "ACT", name: "Waco Regional Airport" },
    KACV: { iata: "ACV", name: "Arcata Airport" },
    KACY: { iata: "ACY", name: "Atlantic City International Airport" },
    KADG: { iata: "ADG", name: "Lenawee County Airport" },
    KADH: { iata: "ADT", name: "Ada Municipal Airport" },
    KADM: { iata: "ADM", name: "Ardmore Municipal Airport" },
    KADS: { iata: "ADS", name: "Addison Airport" },
    KADW: { iata: "ADW", name: "Andrews Air Force Base" },
    KAEL: { iata: "AEL", name: "Albert Lea Municipal Airport" },
    KAEX: { iata: "AEX", name: "Alexandria International Airport" },
    KAFF: { iata: "AFF", name: "USAF Academy Airfield" },
    KAFJ: { iata: "WSG", name: "Washington County Airport" },
    KAFN: { iata: "AFN", name: "Jaffrey Airport Silver Ranch Airport" },
    KAFO: { iata: "AFO", name: "Afton Municipal Airport" },
    KAFW: { iata: "AFW", name: "Fort Worth Alliance Airport" },
    KAGC: { iata: "AGC", name: "Allegheny County Airport" },
    KAGO: { iata: "AGO", name: "Magnolia Municipal Airport" },
    KAGS: { iata: "AGS", name: "Augusta Regional At Bush Field" },
    KAHC: { iata: "AHC", name: "Amedee Army Air Field" },
    KAHH: { iata: "AHH", name: "Amery Municipal Airport" },
    KAHN: { iata: "AHN", name: "Athens Ben Epps Airport" },
    KAIA: { iata: "AIA", name: "Alliance Municipal Airport" },
    KAID: { iata: "AID", name: "Anderson Municipal Darlington Field" },
    KAIK: { iata: "AIK", name: "Aiken Municipal Airport" },
    KAIO: { iata: "AIO", name: "Atlantic Municipal Airport" },
    KAIV: { iata: "AIV", name: "George Downer Airport" },
    KAIZ: { iata: "AIZ", name: "Lee C Fine Memorial Airport" },
    KAKO: { iata: "AKO", name: "Colorado Plains Regional Airport" },
    KAKR: { iata: "AKC", name: "Akron Fulton International Airport" },
    KALB: { iata: "ALB", name: "Albany International Airport" },
    KALI: { iata: "ALI", name: "Alice International Airport" },
    KALM: { iata: "ALM", name: "Alamogordo White Sands Regional Airport" },
    KALN: { iata: "ALN", name: "St Louis Regional Airport" },
    KALO: { iata: "ALO", name: "Waterloo Regional Airport" },
    KALS: { iata: "ALS", name: "San Luis Valley Regional Bergman Field" },
    KALW: { iata: "ALW", name: "Walla Walla Regional Airport" },
    KAMA: { iata: "AMA", name: "Rick Husband Amarillo International Airport" },
    KAMN: { iata: "AMN", name: "RPD Airport" },
    KAMW: { iata: "AMW", name: "Ames Municipal Airport" },
    KANB: { iata: "ANB", name: "Anniston Metropolitan Airport" },
    KAND: { iata: "AND", name: "Anderson Regional Airport" },
    KANK: { iata: "SLT", name: "Harriet Alexander Field" },
    KANP: { iata: "ANP", name: "Lee Airport" },
    KANQ: { iata: "ANQ", name: "Tri State Steuben County Airport" },
    KANW: { iata: "ANW", name: "Ainsworth Municipal Airport" },
    KANY: { iata: "ANY", name: "Anthony Municipal Airport" },
    KAOH: { iata: "AOH", name: "Lima Allen County Airport" },
    KAOO: { iata: "AOO", name: "Altoona Blair County Airport" },
    KAPA: { iata: "APA", name: "Centennial Airport" },
    KAPC: { iata: "APC", name: "Napa County Airport" },
    KAPF: { iata: "APF", name: "Naples Municipal Airport" },
    KAPG: { iata: "APG", name: "Phillips Army Air Field" },
    KAPH: { iata: "APH", name: "A P Hill Aaf (Fort A P Hill) Airport" },
    KAPN: { iata: "APN", name: "Alpena County Regional Airport" },
    KAPT: { iata: "APT", name: "Marion County Brown Field" },
    KAPV: { iata: "APV", name: "Apple Valley Airport" },
    KARA: { iata: "ARA", name: "Acadiana Regional Airport" },
    KARB: { iata: "ARB", name: "Ann Arbor Municipal Airport" },
    KARG: { iata: "ARG", name: "Walnut Ridge Regional Airport" },
    KARM: { iata: "WHT", name: "Wharton Regional Airport" },
    KARR: { iata: "AUZ", name: "Aurora Municipal Airport" },
    KART: { iata: "ART", name: "Watertown International Airport" },
    KARV: { iata: "ARV", name: "Lakeland-Noble F. Lee Memorial field" },
    KARW: { iata: "BFT", name: "Beaufort County Airport" },
    KASE: { iata: "ASE", name: "Aspen-Pitkin Co/Sardy Field" },
    KASG: { iata: "SPZ", name: "Springdale Municipal Airport" },
    KASH: { iata: "ASH", name: "Boire Field" },
    KASL: { iata: "ASL", name: "Harrison County Airport" },
    KASN: { iata: "ASN", name: "Talladega Municipal Airport" },
    KAST: { iata: "AST", name: "Astoria Regional Airport" },
    KASX: { iata: "ASX", name: "John F Kennedy Memorial Airport" },
    KASY: { iata: "ASY", name: "Ashley Municipal Airport" },
    KATL: {
      iata: "ATL",
      name: "Hartsfield Jackson Atlanta International Airport",
    },
    KATS: { iata: "ATS", name: "Artesia Municipal Airport" },
    KATW: { iata: "ATW", name: "Appleton International Airport" },
    KATY: { iata: "ATY", name: "Watertown Regional Airport" },
    KAUG: { iata: "AUG", name: "Augusta State Airport" },
    KAUM: { iata: "AUM", name: "Austin Municipal Airport" },
    KAUN: { iata: "AUN", name: "Auburn Municipal Airport" },
    KAUO: { iata: "AUO", name: "Auburn Opelika Robert G. Pitts Airport" },
    KAUS: { iata: "AUS", name: "Austin Bergstrom International Airport" },
    KAUW: { iata: "AUW", name: "Wausau Downtown Airport" },
    KAVL: { iata: "AVL", name: "Asheville Regional Airport" },
    KAVO: { iata: "AVO", name: "Avon Park Executive Airport" },
    KAVP: { iata: "AVP", name: "Wilkes Barre Scranton International Airport" },
    KAVQ: { iata: "AVW", name: "Marana Regional Airport" },
    KAVX: { iata: "AVX", name: "Catalina Airport" },
    KAWM: { iata: "AWM", name: "West Memphis Municipal Airport" },
    KAXA: { iata: "AXG", name: "Algona Municipal Airport" },
    KAXN: { iata: "AXN", name: "Chandler Field" },
    KAXS: { iata: "AXS", name: "Altus Quartz Mountain Regional Airport" },
    KAXV: { iata: "AXV", name: "Neil Armstrong Airport" },
    KAXX: { iata: "AXX", name: "Angel Fire Airport" },
    KAYS: { iata: "AYS", name: "Waycross Ware County Airport" },
    KAZO: { iata: "AZO", name: "Kalamazoo Battle Creek International Airport" },
    KBAB: { iata: "BAB", name: "Beale Air Force Base" },
    KBAD: { iata: "BAD", name: "Barksdale Air Force Base" },
    KBAF: { iata: "BAF", name: "Barnes Municipal Airport" },
    KBAK: { iata: "CLU", name: "Columbus Municipal Airport" },
    KBAM: { iata: "BAM", name: "Battle Mountain Airport" },
    KBBB: { iata: "BBB", name: "Benson Municipal Airport" },
    KBBD: { iata: "BBD", name: "Curtis Field" },
    KBBG: { iata: "BKG", name: "Branson Airport" },
    KBBP: { iata: "BTN", name: "Marlboro County Jetport H.E. Avent Field" },
    KBBW: { iata: "BBW", name: "Broken Bow Municipal Airport" },
    KBCB: { iata: "BCB", name: "Virginia Tech Montgomery Executive Airport" },
    KBCE: { iata: "BCE", name: "Bryce Canyon Airport" },
    KBCT: { iata: "BCT", name: "Boca Raton Airport" },
    KBDE: { iata: "BDE", name: "Baudette International Airport" },
    KBDG: { iata: "BDG", name: "Blanding Municipal Airport" },
    KBDH: { iata: "ILL", name: "Willmar Municipal -John L Rice Field" },
    KBDL: { iata: "BDL", name: "Bradley International Airport" },
    KBDR: { iata: "BDR", name: "Igor I Sikorsky Memorial Airport" },
    KBDU: { iata: "WBU", name: "Boulder Municipal Airport" },
    KBEC: { iata: "BEC", name: "Beech Factory Airport" },
    KBED: { iata: "BED", name: "Laurence G Hanscom Field" },
    KBEH: { iata: "BEH", name: "Southwest Michigan Regional Airport" },
    KBFD: { iata: "BFD", name: "Bradford Regional Airport" },
    KBFF: { iata: "BFF", name: "Western Neb. Rgnl/William B. Heilig Airport" },
    KBFI: { iata: "BFI", name: "Boeing Field King County International Airport" },
    KBFL: { iata: "BFL", name: "Meadows Field" },
    KBFM: { iata: "BFM", name: "Mobile Downtown Airport" },
    KBFR: { iata: "BFR", name: "Virgil I Grissom Municipal Airport" },
    KBGD: { iata: "BGD", name: "Hutchinson County Airport" },
    KBGE: { iata: "BGE", name: "Decatur County Industrial Air Park" },
    KBGM: { iata: "BGM", name: "Greater Binghamton/Edwin A Link field" },
    KBGR: { iata: "BGR", name: "Bangor International Airport" },
    KBHB: { iata: "BHB", name: "Hancock County-Bar Harbor Airport" },
    KBHM: { iata: "BHM", name: "Birmingham-Shuttlesworth International Airport" },
    KBID: { iata: "BID", name: "Block Island State Airport" },
    KBIE: { iata: "BIE", name: "Beatrice Municipal Airport" },
    KBIF: { iata: "BIF", name: "Biggs Army Air Field (Fort Bliss)" },
    KBIH: { iata: "BIH", name: "Eastern Sierra Regional Airport" },
    KBIL: { iata: "BIL", name: "Billings Logan International Airport" },
    KBIS: { iata: "BIS", name: "Bismarck Municipal Airport" },
    KBIX: { iata: "BIX", name: "Keesler Air Force Base" },
    KBJC: { iata: "BJC", name: "Rocky Mountain Metropolitan Airport" },
    KBJI: { iata: "BJI", name: "Bemidji Regional Airport" },
    KBJJ: { iata: "BJJ", name: "Wayne County Airport" },
    KBKD: { iata: "BKD", name: "Stephens County Airport" },
    KBKE: { iata: "BKE", name: "Baker City Municipal Airport" },
    KBKF: { iata: "BFK", name: "Buckley Air Force Base" },
    KBKL: { iata: "BKL", name: "Burke Lakefront Airport" },
    KBKN: { iata: "BWL", name: "Blackwell Tonkawa Municipal Airport" },
    KBKT: { iata: "BKT", name: "Allen C Perkinson Blackstone Army Air Field" },
    KBKW: { iata: "BKW", name: "Raleigh County Memorial Airport" },
    KBKX: { iata: "BKX", name: "Brookings Regional Airport" },
    KBLF: { iata: "BLF", name: "Mercer County Airport" },
    KBLH: { iata: "BLH", name: "Blythe Airport" },
    KBLI: { iata: "BLI", name: "Bellingham International Airport" },
    KBLM: { iata: "BLM", name: "Monmouth Executive Airport" },
    KBLU: { iata: "BLU", name: "Blue Canyon Nyack Airport" },
    KBLV: { iata: "BLV", name: "Scott AFB/Midamerica Airport" },
    KBMC: { iata: "BMC", name: "Brigham City Airport" },
    KBMG: { iata: "BMG", name: "Monroe County Airport" },
    KBMI: {
      iata: "BMI",
      name: "Central Illinois Regional Airport at Bloomington-Normal",
    },
    KBML: { iata: "BML", name: "Berlin Regional Airport" },
    KBMT: { iata: "BMT", name: "Beaumont Municipal Airport" },
    KBNA: { iata: "BNA", name: "Nashville International Airport" },
    KBNG: { iata: "BNG", name: "Banning Municipal Airport" },
    KBNL: { iata: "BNL", name: "Barnwell Regional Airport" },
    KBNO: { iata: "BNO", name: "Burns Municipal Airport" },
    KBNW: { iata: "BNW", name: "Boone Municipal Airport" },
    KBOI: { iata: "BOI", name: "Boise Air Terminal/Gowen field" },
    KBOS: {
      iata: "BOS",
      name: "General Edward Lawrence Logan International Airport",
    },
    KBOW: { iata: "BOW", name: "Bartow Municipal Airport" },
    KBPG: { iata: "BGS", name: "Big Spring Mc Mahon-Wrinkle Airport" },
    KBPI: { iata: "BPI", name: "Miley Memorial Field" },
    KBPK: { iata: "WMH", name: "Ozark Regional Airport" },
    KBPP: { iata: "BWM", name: "Bowman Municipal Airport" },
    KBPT: { iata: "BPT", name: "Southeast Texas Regional Airport" },
    KBQK: { iata: "BQK", name: "Brunswick Golden Isles Airport" },
    KBRD: { iata: "BRD", name: "Brainerd Lakes Regional Airport" },
    KBRL: { iata: "BRL", name: "Southeast Iowa Regional Airport" },
    KBRO: {
      iata: "BRO",
      name: "Brownsville South Padre Island International Airport",
    },
    KBRY: { iata: "BRY", name: "Samuels Field" },
    KBTF: { iata: "BTF", name: "Skypark Airport" },
    KBTL: { iata: "BTL", name: "W K Kellogg Airport" },
    KBTM: { iata: "BTM", name: "Bert Mooney Airport" },
    KBTN: { iata: "TTO", name: "Britton Municipal Airport" },
    KBTP: { iata: "BTP", name: "Butler County-K W Scholter Field" },
    KBTR: { iata: "BTR", name: "Baton Rouge Metropolitan Ryan Field" },
    KBTV: { iata: "BTV", name: "Burlington International Airport" },
    KBTY: { iata: "BTY", name: "Beatty Airport" },
    KBUB: { iata: "BUB", name: "Cram Field" },
    KBUF: { iata: "BUF", name: "Buffalo Niagara International Airport" },
    KBUM: { iata: "BUM", name: "Butler Memorial Airport" },
    KBUR: { iata: "BUR", name: "Bob Hope Airport" },
    KBVI: { iata: "BFP", name: "Beaver County Airport" },
    KBVO: { iata: "BVO", name: "Bartlesville Municipal Airport" },
    KBVS: { iata: "MVW", name: "Skagit Regional Airport" },
    KBVU: { iata: "BLD", name: "Boulder City Municipal Airport" },
    KBVX: { iata: "BVX", name: "Batesville Regional Airport" },
    KBVY: { iata: "BVY", name: "Beverly Municipal Airport" },
    KBWC: { iata: "BWC", name: "Brawley Municipal Airport" },
    KBWD: { iata: "BWD", name: "Brownwood Regional Airport" },
    KBWG: { iata: "BWG", name: "Bowling Green Warren County Regional Airport" },
    KBWI: {
      iata: "BWI",
      name: "Baltimore/Washington International Thurgood Marshal Airport",
    },
    KBWP: { iata: "WAH", name: "Harry Stern Airport" },
    KBXA: { iata: "BXA", name: "George R Carr Memorial Air Field" },
    KBXK: { iata: "BXK", name: "Buckeye Municipal Airport" },
    KBXM: { iata: "NHZ", name: "Brunswick Executive Airport" },
    KBYG: { iata: "BYG", name: "Johnson County Airport" },
    KBYH: { iata: "BYH", name: "Arkansas International Airport" },
    KBYI: { iata: "BYI", name: "Burley Municipal Airport" },
    KBYS: { iata: "BYS", name: "Bicycle Lake Army Air Field" },
    KBYY: { iata: "BBC", name: "Bay City Municipal Airport" },
    KBZN: { iata: "BZN", name: "Gallatin Field" },
    KCAD: { iata: "CAD", name: "Wexford County Airport" },
    KCAE: { iata: "CAE", name: "Columbia Metropolitan Airport" },
    KCAG: { iata: "CIG", name: "Craig Moffat Airport" },
    KCAK: { iata: "CAK", name: "Akron Canton Regional Airport" },
    KCAO: { iata: "CAO", name: "Clayton Municipal Airpark" },
    KCAR: { iata: "CAR", name: "Caribou Municipal Airport" },
    KCBE: { iata: "CBE", name: "Greater Cumberland Regional Airport" },
    KCBF: { iata: "CBF", name: "Council Bluffs Municipal Airport" },
    KCBK: { iata: "CBK", name: "Shalz Field" },
    KCBM: { iata: "CBM", name: "Columbus Air Force Base" },
    KCCB: { iata: "CCB", name: "Cable Airport" },
    KCCR: { iata: "CCR", name: "Buchanan Field" },
    KCCY: { iata: "CCY", name: "Northeast Iowa Regional Airport" },
    KCDA: { iata: "LLX", name: "Caledonia County Airport" },
    KCDC: { iata: "CDC", name: "Cedar City Regional Airport" },
    KCDH: { iata: "CDH", name: "Harrell Field" },
    KCDK: { iata: "CDK", name: "George T Lewis Airport" },
    KCDN: { iata: "CDN", name: "Woodward Field" },
    KCDR: { iata: "CDR", name: "Chadron Municipal Airport" },
    KCDS: { iata: "CDS", name: "Childress Municipal Airport" },
    KCDW: { iata: "CDW", name: "Essex County Airport" },
    KCEA: { iata: "CEA", name: "Cessna Acft Field" },
    KCEC: { iata: "CEC", name: "Jack Mc Namara Field Airport" },
    KCEF: { iata: "CEF", name: "Westover ARB/Metropolitan Airport" },
    KCEU: { iata: "CEU", name: "Oconee County Regional Airport" },
    KCEV: { iata: "CEV", name: "Mettel Field" },
    KCEW: { iata: "CEW", name: "Bob Sikes Airport" },
    KCEY: { iata: "CEY", name: "Kyle Oakley Field" },
    KCEZ: { iata: "CEZ", name: "Cortez Municipal Airport" },
    KCFD: { iata: "CFD", name: "Coulter Field" },
    KCFS: { iata: "TZC", name: "Tuscola Area Airport" },
    KCFT: { iata: "CFT", name: "Greenlee County Airport" },
    KCFV: { iata: "CFV", name: "Coffeyville Municipal Airport" },
    KCGE: { iata: "CGE", name: "Cambridge Dorchester Airport" },
    KCGF: { iata: "CGF", name: "Cuyahoga County Airport" },
    KCGI: { iata: "CGI", name: "Cape Girardeau Regional Airport" },
    KCGS: { iata: "CGS", name: "College Park Airport" },
    KCGZ: { iata: "CGZ", name: "Casa Grande Municipal Airport" },
    KCHA: { iata: "CHA", name: "Lovell Field" },
    KCHK: { iata: "CHK", name: "Chickasha Municipal Airport" },
    KCHO: { iata: "CHO", name: "Charlottesville Albemarle Airport" },
    KCHS: {
      iata: "CHS",
      name: "Charleston Air Force Base-International Airport",
    },
    KCIC: { iata: "CIC", name: "Chico Municipal Airport" },
    KCID: { iata: "CID", name: "The Eastern Iowa Airport" },
    KCIN: { iata: "CIN", name: "Arthur N Neu Airport" },
    KCIR: { iata: "CIR", name: "Cairo Regional Airport" },
    KCIU: { iata: "CIU", name: "Chippewa County International Airport" },
    KCKA: { iata: "CKA", name: "Kegelman Af Aux Field" },
    KCKB: { iata: "CKB", name: "North Central West Virginia Airport" },
    KCKC: { iata: "GRM", name: "Grand Marais Cook County Airport" },
    KCKM: { iata: "CKM", name: "Fletcher Field" },
    KCKN: { iata: "CKN", name: "Crookston Municipal Kirkwood Field" },
    KCKV: { iata: "CKV", name: "Outlaw Field" },
    KCLE: { iata: "CLE", name: "Cleveland Hopkins International Airport" },
    KCLI: { iata: "CLI", name: "Clintonville Municipal Airport" },
    KCLK: { iata: "CLK", name: "Clinton Regional Airport" },
    KCLL: { iata: "CLL", name: "Easterwood Field" },
    KCLM: { iata: "CLM", name: "William R Fairchild International Airport" },
    KCLR: { iata: "CLR", name: "Cliff Hatfield Memorial Airport" },
    KCLS: { iata: "CLS", name: "Chehalis Centralia Airport" },
    KCLT: { iata: "CLT", name: "Charlotte Douglas International Airport" },
    KCLW: { iata: "CLW", name: "Clearwater Air Park" },
    KCMH: { iata: "CMH", name: "Port Columbus International Airport" },
    KCMI: { iata: "CMI", name: "University of Illinois Willard Airport" },
    KCMX: { iata: "CMX", name: "Houghton County Memorial Airport" },
    KCMY: { iata: "CMY", name: "Sparta Fort Mc Coy Airport" },
    KCNH: { iata: "CNH", name: "Claremont Municipal Airport" },
    KCNK: { iata: "CNK", name: "Blosser Municipal Airport" },
    KCNM: { iata: "CNM", name: "Cavern City Air Terminal" },
    KCNO: { iata: "CNO", name: "Chino Airport" },
    KCNU: { iata: "CNU", name: "Chanute Martin Johnson Airport" },
    KCNW: { iata: "CNW", name: "TSTC Waco Airport" },
    KCNY: { iata: "CNY", name: "Canyonlands Field" },
    KCOD: { iata: "COD", name: "Yellowstone Regional Airport" },
    KCOE: { iata: "COE", name: "Coeur D'Alene - Pappy Boyington Field" },
    KCOF: { iata: "COF", name: "Patrick Air Force Base" },
    KCOI: { iata: "COI", name: "Merritt Island Airport" },
    KCOM: { iata: "COM", name: "Coleman Municipal Airport" },
    KCON: { iata: "CON", name: "Concord Municipal Airport" },
    KCOS: { iata: "COS", name: "City of Colorado Springs Municipal Airport" },
    KCOT: { iata: "COT", name: "Cotulla-La Salle County" },
    KCOU: { iata: "COU", name: "Columbia Regional Airport" },
    KCPM: { iata: "CPM", name: "Compton Woodley Airport" },
    KCPR: { iata: "CPR", name: "Casper-Natrona County International Airport" },
    KCPS: { iata: "CPS", name: "St Louis Downtown Airport" },
    KCQW: { iata: "HCW", name: "Cheraw Municipal Lynch Bellinger Field" },
    KCRE: { iata: "CRE", name: "Grand Strand Airport" },
    KCRG: { iata: "CRG", name: "Craig Municipal Airport" },
    KCRO: { iata: "CRO", name: "Corcoran Airport" },
    KCRP: { iata: "CRP", name: "Corpus Christi International Airport" },
    KCRQ: { iata: "CLD", name: "Mc Clellan-Palomar Airport" },
    KCRS: {
      iata: "CRS",
      name: "C David Campbell Field Corsicana Municipal Airport",
    },
    KCRT: { iata: "CRT", name: "Z M Jack Stell Field" },
    KCRW: { iata: "CRW", name: "Yeager Airport" },
    KCRX: { iata: "CRX", name: "Roscoe Turner Airport" },
    KCSG: { iata: "CSG", name: "Columbus Metropolitan Airport" },
    KCSM: { iata: "CSM", name: "Clinton Sherman Airport" },
    KCSQ: { iata: "CSQ", name: "Creston Municipal Airport" },
    KCSV: { iata: "CSV", name: "Crossville Memorial Whitson Field" },
    KCTB: { iata: "CTB", name: "Cut Bank Municipal Airport" },
    KCTY: { iata: "CTY", name: "Cross City Airport" },
    KCTZ: { iata: "CTZ", name: "Sampson County Airport" },
    KCUB: { iata: "CUB", name: "Jim Hamilton L.B. Owens Airport" },
    KCUH: { iata: "CUH", name: "Cushing Municipal Airport" },
    KCVG: {
      iata: "CVG",
      name: "Cincinnati Northern Kentucky International Airport",
    },
    KCVH: { iata: "HLI", name: "Hollister Municipal Airport" },
    KCVK: { iata: "CKK", name: "Sharp County Regional Airport" },
    KCVN: { iata: "CVN", name: "Clovis Municipal Airport" },
    KCVO: { iata: "CVO", name: "Corvallis Municipal Airport" },
    KCVS: { iata: "CVS", name: "Cannon Air Force Base" },
    KCWA: { iata: "CWA", name: "Central Wisconsin Airport" },
    KCWC: { iata: "KIP", name: "Kickapoo Downtown Airport" },
    KCWF: { iata: "CWF", name: "Chennault International Airport" },
    KCWI: { iata: "CWI", name: "Clinton Municipal Airport" },
    KCXL: { iata: "CXL", name: "Calexico International Airport" },
    KCXO: { iata: "CXO", name: "Lone Star Executive Airport" },
    KCXP: { iata: "CSN", name: "Carson Airport" },
    KCXY: { iata: "HAR", name: "Capital City Airport" },
    KCYS: { iata: "CYS", name: "Cheyenne Regional Jerry Olson Field" },
    KCZT: { iata: "CZT", name: "Dimmit County Airport" },
    KDAA: { iata: "DAA", name: "Davison Army Air Field" },
    KDAB: { iata: "DAB", name: "Daytona Beach International Airport" },
    KDAG: { iata: "DAG", name: "Barstow Daggett Airport" },
    KDAL: { iata: "DAL", name: "Dallas Love Field" },
    KDAN: { iata: "DAN", name: "Danville Regional Airport" },
    KDAY: { iata: "DAY", name: "James M Cox Dayton International Airport" },
    KDBN: { iata: "DBN", name: "W H 'Bud' Barron Airport" },
    KDBQ: { iata: "DBQ", name: "Dubuque Regional Airport" },
    KDCA: { iata: "DCA", name: "Ronald Reagan Washington National Airport" },
    KDCU: { iata: "DCU", name: "Pryor Field Regional Airport" },
    KDDC: { iata: "DDC", name: "Dodge City Regional Airport" },
    KDEC: { iata: "DEC", name: "Decatur Airport" },
    KDEH: { iata: "DEH", name: "Decorah Municipal Airport" },
    KDEN: { iata: "DEN", name: "Denver International Airport" },
    KDET: { iata: "DET", name: "Coleman A. Young Municipal Airport" },
    KDFI: { iata: "DFI", name: "Defiance Memorial Airport" },
    KDFW: { iata: "DFW", name: "Dallas Fort Worth International Airport" },
    KDGL: { iata: "DGL", name: "Douglas Municipal Airport" },
    KDGW: { iata: "DGW", name: "Converse County Airport" },
    KDHN: { iata: "DHN", name: "Dothan Regional Airport" },
    KDHT: { iata: "DHT", name: "Dalhart Municipal Airport" },
    KDIK: { iata: "DIK", name: "Dickinson Theodore Roosevelt Regional Airport" },
    KDKK: { iata: "DKK", name: "Chautauqua County-Dunkirk Airport" },
    KDLC: { iata: "DLL", name: "Dillon County Airport" },
    KDLF: { iata: "DLF", name: "Laughlin Air Force Base" },
    KDLH: { iata: "DLH", name: "Duluth International Airport" },
    KDLN: { iata: "DLN", name: "Dillon Airport" },
    KDLS: {
      iata: "DLS",
      name: "Columbia Gorge Regional the Dalles Municipal Airport",
    },
    KDMA: { iata: "DMA", name: "Davis Monthan Air Force Base" },
    KDMN: { iata: "DMN", name: "Deming Municipal Airport" },
    KDMO: { iata: "DMO", name: "Sedalia Memorial Airport" },
    KDNL: { iata: "DNL", name: "Daniel Field" },
    KDNN: { iata: "DNN", name: "Dalton Municipal Airport" },
    KDNS: { iata: "DNS", name: "Denison Municipal Airport" },
    KDNV: { iata: "DNV", name: "Vermilion Regional Airport" },
    KDOV: { iata: "DOV", name: "Dover Air Force Base" },
    KDPA: { iata: "DPA", name: "Dupage Airport" },
    KDPG: { iata: "DPG", name: "Michael AAF (Dugway Proving Ground) Airport" },
    KDRA: { iata: "DRA", name: "Desert Rock Airport" },
    KDRI: { iata: "DRI", name: "Beauregard Regional Airport" },
    KDRM: { iata: "DRE", name: "Drummond Island Airport" },
    KDRO: { iata: "DRO", name: "Durango La Plata County Airport" },
    KDRT: { iata: "DRT", name: "Del Rio International Airport" },
    KDSM: { iata: "DSM", name: "Des Moines International Airport" },
    KDSV: { iata: "DSV", name: "Dansville Municipal Airport" },
    KDTA: { iata: "DTA", name: "Delta Municipal Airport" },
    KDTL: { iata: "DTL", name: "Detroit Lakes Airport - Wething Field" },
    KDTN: { iata: "DTN", name: "Shreveport Downtown Airport" },
    KDTS: { iata: "DSI", name: "Destin Fort Walton Beach Airport" },
    KDTW: { iata: "DTW", name: "Detroit Metropolitan Wayne County Airport" },
    KDUA: { iata: "DUA", name: "Eaker Field" },
    KDUC: { iata: "DUC", name: "Halliburton Field" },
    KDUG: { iata: "DUG", name: "Bisbee Douglas International Airport" },
    KDUJ: { iata: "DUJ", name: "DuBois Regional Airport" },
    KDVL: { iata: "DVL", name: "Devils Lake Regional Airport" },
    KDVN: { iata: "DVN", name: "Davenport Municipal Airport" },
    KDVO: { iata: "NOT", name: "Gnoss Field" },
    KDVP: { iata: "NSL", name: "Slayton Municipal Airport" },
    KDVT: { iata: "DVT", name: "Phoenix Deer Valley Airport" },
    KDWH: { iata: "DWH", name: "David Wayne Hooks Memorial Airport" },
    KDXR: { iata: "DXR", name: "Danbury Municipal Airport" },
    KDYL: { iata: "DYL", name: "Doylestown Airport" },
    KDYS: { iata: "DYS", name: "Dyess Air Force Base" },
    KE13: { iata: "CCG", name: "Crane County Airport" },
    KE41: { iata: "BGQ", name: "Reagan County Airport" },
    KE51: { iata: "BGT", name: "Bagdad Airport" },
    KEAN: { iata: "EAN", name: "Phifer Airfield" },
    KEAR: { iata: "EAR", name: "Kearney Regional Airport" },
    KEAT: { iata: "EAT", name: "Pangborn Memorial Airport" },
    KEAU: { iata: "EAU", name: "Chippewa Valley Regional Airport" },
    KEBS: { iata: "EBS", name: "Webster City Municipal Airport" },
    KECG: {
      iata: "ECG",
      name: "Elizabeth City Regional Airport & Coast Guard Air Station",
    },
    KECP: {
      iata: "ECP",
      name: "Northwest Florida Beaches International Airport",
    },
    KECS: { iata: "ECS", name: "Mondell Field" },
    KEDE: { iata: "EDE", name: "Northeastern Regional Airport" },
    KEDN: { iata: "ETS", name: "Enterprise Municipal Airport" },
    KEDW: { iata: "EDW", name: "Edwards Air Force Base" },
    KEED: { iata: "EED", name: "Needles Airport" },
    KEEN: { iata: "EEN", name: "Dillant Hopkins Airport" },
    KEFD: { iata: "EFD", name: "Ellington Field" },
    KEFK: { iata: "EFK", name: "Newport State Airport" },
    KEFW: { iata: "EFW", name: "Jefferson Municipal Airport" },
    KEGE: { iata: "EGE", name: "Eagle County Regional Airport" },
    KEGI: { iata: "EGI", name: "Duke Field(Eglin Af Aux Nr 3) Airport" },
    KEGV: { iata: "EGV", name: "Eagle River Union Airport" },
    KEKA: { iata: "EKA", name: "Murray Field" },
    KEKM: { iata: "EKI", name: "Elkhart Municipal Airport" },
    KEKN: { iata: "EKN", name: "Elkins-Randolph Co-Jennings Randolph Field" },
    KEKO: { iata: "EKO", name: "Elko Regional Airport" },
    KEKX: { iata: "EKX", name: "Addington Field" },
    KELA: { iata: "ELA", name: "Eagle Lake Airport" },
    KELD: { iata: "ELD", name: "South Arkansas Regional At Goodwin Field" },
    KELK: { iata: "ELK", name: "Elk City Regional Business Airport" },
    KELM: { iata: "ELM", name: "Elmira Corning Regional Airport" },
    KELN: { iata: "ELN", name: "Bowers Field" },
    KELO: { iata: "LYU", name: "Ely Municipal Airport" },
    KELP: { iata: "ELP", name: "El Paso International Airport" },
    KELY: { iata: "ELY", name: "Ely Airport Yelland Field" },
    KELZ: { iata: "ELZ", name: "Wellsville Municipal ArptTarantine Field" },
    KEMM: { iata: "EMM", name: "Kemmerer Municipal Airport" },
    KEMP: { iata: "EMP", name: "Emporia Municipal Airport" },
    KEMT: { iata: "EMT", name: "El Monte Airport" },
    KEND: { iata: "END", name: "Vance Air Force Base" },
    KENL: { iata: "ENL", name: "Centralia Municipal Airport" },
    KENV: { iata: "ENV", name: "Wendover Airport" },
    KENW: { iata: "ENW", name: "Kenosha Regional Airport" },
    KEOK: { iata: "EOK", name: "Keokuk Municipal Airport" },
    KEOS: { iata: "EOS", name: "Neosho Hugh Robinson Airport" },
    KEPH: { iata: "EPH", name: "Ephrata Municipal Airport" },
    KEQA: { iata: "EDK", name: "Captain Jack Thomas El Dorado Airport" },
    KERI: { iata: "ERI", name: "Erie International Tom Ridge Field" },
    KERR: { iata: "ERR", name: "Errol Airport" },
    KERV: { iata: "ERV", name: "Kerrville Municipal Louis Schreiner Field" },
    KESC: { iata: "ESC", name: "Delta County Airport" },
    KESF: { iata: "ESF", name: "Esler Regional Airport" },
    KESN: { iata: "ESN", name: "Easton Newnam Field" },
    KEST: { iata: "EST", name: "Estherville Municipal Airport" },
    KESW: { iata: "ESW", name: "Easton State Airport" },
    KETB: { iata: "ETB", name: "West Bend Municipal Airport" },
    KETN: { iata: "ETN", name: "Eastland Municipal Airport" },
    KEUF: { iata: "EUF", name: "Weedon Field" },
    KEUG: { iata: "EUG", name: "Mahlon Sweet Field" },
    KEVM: { iata: "EVM", name: "Eveleth Virginia Municipal Airport" },
    KEVV: { iata: "EVV", name: "Evansville Regional Airport" },
    KEVW: { iata: "EVW", name: "Evanston-Uinta County Airport-Burns Field" },
    KEWB: { iata: "EWB", name: "New Bedford Regional Airport" },
    KEWK: { iata: "EWK", name: "Newton City-County Airport" },
    KEWN: { iata: "EWN", name: "Coastal Carolina Regional Airport" },
    KEWR: { iata: "EWR", name: "Newark Liberty International Airport" },
    KEYW: { iata: "EYW", name: "Key West International Airport" },
    KFAF: { iata: "FAF", name: "Felker Army Air Field" },
    KFAM: { iata: "FAM", name: "Farmington Regional Airport" },
    KFAR: { iata: "FAR", name: "Hector International Airport" },
    KFAT: { iata: "FAT", name: "Fresno Yosemite International Airport" },
    KFAY: { iata: "FAY", name: "Fayetteville Regional Grannis Field" },
    KFBG: { iata: "FBG", name: "Simmons Army Air Field" },
    KFBL: { iata: "FBL", name: "Faribault Municipal Airport" },
    KFBR: { iata: "FBR", name: "Fort Bridger Airport" },
    KFBY: { iata: "FBY", name: "Fairbury Municipal Airport" },
    KFCH: { iata: "FCH", name: "Fresno Chandler Executive Airport" },
    KFCM: { iata: "FCM", name: "Flying Cloud Airport" },
    KFCS: { iata: "FCS", name: "Butts AAF (Fort Carson) Air Field" },
    KFCY: { iata: "FCY", name: "Forrest City Municipal Airport" },
    KFDK: { iata: "FDK", name: "Frederick Municipal Airport" },
    KFDR: { iata: "FDR", name: "Frederick Regional Airport" },
    KFDY: { iata: "FDY", name: "Findlay Airport" },
    KFEP: { iata: "FEP", name: "Albertus Airport" },
    KFET: { iata: "FET", name: "Fremont Municipal Airport" },
    KFFA: { iata: "FFA", name: "First Flight Airport" },
    KFFL: { iata: "FFL", name: "Fairfield Municipal Airport" },
    KFFM: { iata: "FFM", name: "Fergus Falls Municipal Einar Mickelson Field" },
    KFFO: { iata: "FFO", name: "Wright Patterson Air Force Base" },
    KFFT: { iata: "FFT", name: "Capital City Airport" },
    KFFZ: { iata: "MSC", name: "Falcon Field" },
    KFHB: { iata: "FHB", name: "Fernandina Beach Municipal Airport" },
    KFHR: { iata: "FRD", name: "Friday Harbor Airport" },
    KFHU: { iata: "FHU", name: "Sierra Vista Municipal Libby Army Air Field" },
    KFKL: { iata: "FKL", name: "Venango Regional Airport" },
    KFKN: { iata: "FKN", name: "Franklin Municipal John Beverly Rose Airport" },
    KFLD: { iata: "FLD", name: "Fond du Lac County Airport" },
    KFLG: { iata: "FLG", name: "Flagstaff Pulliam Airport" },
    KFLL: {
      iata: "FLL",
      name: "Fort Lauderdale Hollywood International Airport",
    },
    KFLO: { iata: "FLO", name: "Florence Regional Airport" },
    KFLP: { iata: "FLP", name: "Marion County Regional Airport" },
    KFLV: { iata: "FLV", name: "Sherman Army Air Field" },
    KFLX: { iata: "FLX", name: "Fallon Municipal Airport" },
    KFME: { iata: "FME", name: "Tipton Airport" },
    KFMH: { iata: "FMH", name: "Cape Cod Coast Guard Air Station" },
    KFMN: { iata: "FMN", name: "Four Corners Regional Airport" },
    KFMY: { iata: "FMY", name: "Page Field" },
    KFNL: { iata: "FNL", name: "Fort Collins Loveland Municipal Airport" },
    KFNT: { iata: "FNT", name: "Bishop International Airport" },
    KFOD: { iata: "FOD", name: "Fort Dodge Regional Airport" },
    KFOE: { iata: "FOE", name: "Forbes Field" },
    KFOK: { iata: "FOK", name: "Francis S Gabreski Airport" },
    KFOM: { iata: "FIL", name: "Fillmore Municipal Airport" },
    KFPR: { iata: "FPR", name: "St Lucie County International Airport" },
    KFRG: { iata: "FRG", name: "Republic Airport" },
    KFRH: { iata: "FRH", name: "French Lick Municipal Airport" },
    KFRI: { iata: "FRI", name: "Marshall Army Air Field" },
    KFRM: { iata: "FRM", name: "Fairmont Municipal Airport" },
    KFRR: { iata: "FRR", name: "Front Royal Warren County Airport" },
    KFSD: { iata: "FSD", name: "Joe Foss Field Airport" },
    KFSI: { iata: "FSI", name: "Henry Post Army Air Field (Fort Sill)" },
    KFSK: { iata: "FSK", name: "Fort Scott Municipal Airport" },
    KFSM: { iata: "FSM", name: "Fort Smith Regional Airport" },
    KFST: { iata: "FST", name: "Fort Stockton Pecos County Airport" },
    KFSU: { iata: "FSU", name: "Fort Sumner Municipal Airport" },
    KFSW: { iata: "FMS", name: "Fort Madison Municipal Airport" },
    KFTK: { iata: "FTK", name: "Godman Army Air Field" },
    KFTW: { iata: "FTW", name: "Fort Worth Meacham International Airport" },
    KFTY: { iata: "FTY", name: "Fulton County Airport Brown Field" },
    KFUL: { iata: "FUL", name: "Fullerton Municipal Airport" },
    KFVE: { iata: "WFK", name: "Northern Aroostook Regional Airport" },
    KFWA: { iata: "FWA", name: "Fort Wayne International Airport" },
    KFXE: { iata: "FXE", name: "Fort Lauderdale Executive Airport" },
    KFXY: { iata: "FXY", name: "Forest City Municipal Airport" },
    KFYM: { iata: "FYM", name: "Fayetteville Municipal Airport" },
    KFYV: { iata: "FYV", name: "Drake Field" },
    KGAB: { iata: "GAB", name: "Gabbs Airport" },
    KGAD: { iata: "GAD", name: "Northeast Alabama Regional Airport" },
    KGAG: { iata: "GAG", name: "Gage Airport" },
    KGAI: { iata: "GAI", name: "Montgomery County Airpark" },
    KGBD: { iata: "GBD", name: "Great Bend Municipal Airport" },
    KGBG: { iata: "GBG", name: "Galesburg Municipal Airport" },
    KGBR: { iata: "GBR", name: "Walter J. Koladza Airport" },
    KGCC: { iata: "GCC", name: "Gillette Campbell County Airport" },
    KGCD: { iata: "JDA", name: "Grant Co Regional/Ogilvie Field" },
    KGCK: { iata: "GCK", name: "Garden City Regional Airport" },
    KGCN: { iata: "GCN", name: "Grand Canyon National Park Airport" },
    KGCY: { iata: "GCY", name: "Greeneville Greene County Municipal Airport" },
    KGDM: { iata: "GDM", name: "Gardner Municipal Airport" },
    KGDV: { iata: "GDV", name: "Dawson Community Airport" },
    KGDW: { iata: "GDW", name: "Gladwin Zettel Memorial Airport" },
    KGDY: { iata: "GDY", name: "Grundy Municipal Airport" },
    KGED: { iata: "GED", name: "Sussex County Airport" },
    KGEG: { iata: "GEG", name: "Spokane International Airport" },
    KGEY: { iata: "GEY", name: "South Big Horn County Airport" },
    KGFK: { iata: "GFK", name: "Grand Forks International Airport" },
    KGFL: { iata: "GFL", name: "Floyd Bennett Memorial Airport" },
    KGGE: { iata: "GGE", name: "Georgetown County Airport" },
    KGGG: { iata: "GGG", name: "East Texas Regional Airport" },
    KGGW: { iata: "GGW", name: "Wokal Field Glasgow International Airport" },
    KGHM: { iata: "GHM", name: "Centerville Municipal Airport" },
    KGIC: { iata: "IDH", name: "Idaho County Airport" },
    KGIF: { iata: "GIF", name: "Winter Haven's Gilbert Airport" },
    KGJT: { iata: "GJT", name: "Grand Junction Regional Airport" },
    KGKJ: { iata: "MEJ", name: "Port Meadville Airport" },
    KGKT: { iata: "GKT", name: "Gatlinburg Pigeon Forge Airport" },
    KGLD: { iata: "GLD", name: "Renner Field-Goodland Municipal Airport" },
    KGLE: { iata: "GLE", name: "Gainesville Municipal Airport" },
    KGLH: { iata: "GLH", name: "Mid Delta Regional Airport" },
    KGLR: { iata: "GLR", name: "Gaylord Regional Airport" },
    KGLS: { iata: "GLS", name: "Scholes International At Galveston Airport" },
    KGLW: { iata: "GLW", name: "Glasgow Municipal Airport" },
    KGMU: { iata: "GMU", name: "Greenville Downtown Airport" },
    KGNG: { iata: "GNG", name: "Gooding Municipal Airport" },
    KGNT: { iata: "GNT", name: "Grants Milan Municipal Airport" },
    KGNV: { iata: "GNV", name: "Gainesville Regional Airport" },
    KGOK: { iata: "GOK", name: "Guthrie-Edmond Regional Airport" },
    KGON: { iata: "GON", name: "Groton New London Airport" },
    KGPI: { iata: "FCA", name: "Glacier Park International Airport" },
    KGPT: { iata: "GPT", name: "Gulfport Biloxi International Airport" },
    KGPZ: { iata: "GPZ", name: "Grand Rapids Itasca Co-Gordon Newstrom field" },
    KGQQ: { iata: "GQQ", name: "Galion Municipal Airport" },
    KGRB: { iata: "GRB", name: "Austin Straubel International Airport" },
    KGRD: { iata: "GRD", name: "Greenwood County Airport" },
    KGRE: { iata: "GRE", name: "Greenville Airport" },
    KGRF: { iata: "GRF", name: "Gray Army Air Field" },
    KGRI: { iata: "GRI", name: "Central Nebraska Regional Airport" },
    KGRK: { iata: "GRK", name: "Robert Gray  Army Air Field Airport" },
    KGRN: { iata: "GRN", name: "Gordon Municipal Airport" },
    KGRR: { iata: "GRR", name: "Gerald R. Ford International Airport" },
    KGSB: { iata: "GSB", name: "Seymour Johnson Air Force Base" },
    KGSH: { iata: "GSH", name: "Goshen Municipal Airport" },
    KGSO: { iata: "GSO", name: "Piedmont Triad International Airport" },
    KGSP: { iata: "GSP", name: "Greenville Spartanburg International Airport" },
    KGTB: { iata: "GTB", name: "Wheeler Sack Army Air Field" },
    KGTF: { iata: "GTF", name: "Great Falls International Airport" },
    KGTG: { iata: "GTG", name: "Grantsburg Municipal Airport" },
    KGTR: { iata: "GTR", name: "Golden Triangle Regional Airport" },
    KGUC: { iata: "GUC", name: "Gunnison Crested Butte Regional Airport" },
    KGUP: { iata: "GUP", name: "Gallup Municipal Airport" },
    KGUS: { iata: "GUS", name: "Grissom ARB Airport" },
    KGUY: { iata: "GUY", name: "Guymon Municipal Airport" },
    KGVL: { iata: "GVL", name: "Lee Gilmer Memorial Airport" },
    KGVT: { iata: "GVT", name: "Majors Airport" },
    KGWO: { iata: "GWO", name: "Greenwood Leflore Airport" },
    KGWS: { iata: "GWS", name: "Glenwood Springs Municipal Airport" },
    KGXY: { iata: "GXY", name: "Greeley Weld County Airport" },
    KGYH: { iata: "GDC", name: "Donaldson Center Airport" },
    KGYI: { iata: "PNX", name: "North Texas Rgnl/Perrin Field" },
    KGYR: { iata: "GYR", name: "Phoenix Goodyear Airport" },
    KGYY: { iata: "GYY", name: "Gary Chicago International Airport" },
    KHAB: { iata: "HAB", name: "Marion County Rankin Fite Airport" },
    KHAF: { iata: "HAF", name: "Half Moon Bay Airport" },
    KHAI: { iata: "HAI", name: "Three Rivers Municipal Dr Haines Airport" },
    KHAO: { iata: "HAO", name: "Butler Co Regional Airport" },
    KHBG: { iata: "HBG", name: "Hattiesburg Bobby L Chain Municipal Airport" },
    KHBR: { iata: "HBR", name: "Hobart Regional Airport" },
    KHDE: { iata: "HDE", name: "Brewster Field" },
    KHDN: { iata: "HDN", name: "Yampa Valley Airport" },
    KHEE: { iata: "HEE", name: "Thompson Robbins Airport" },
    KHEF: { iata: "MNZ", name: "Manassas Regional Harry P. Davis field" },
    KHEZ: {
      iata: "HEZ",
      name: "Hardy Anders Field Natchez Adams County Airport",
    },
    KHFD: { iata: "HFD", name: "Hartford Brainard Airport" },
    KHFF: { iata: "HFF", name: "Mackall Army Air Field" },
    KHGR: { iata: "HGR", name: "Hagerstown Regional Richard A Henson Field" },
    KHHR: {
      iata: "HHR",
      name: "Jack Northrop Field Hawthorne Municipal Airport",
    },
    KHHW: { iata: "HUJ", name: "Stan Stamper Municipal Airport" },
    KHIB: { iata: "HIB", name: "Chisholm Hibbing Airport" },
    KHIE: { iata: "HIE", name: "Mount Washington Regional Airport" },
    KHIF: { iata: "HIF", name: "Hill Air Force Base" },
    KHII: { iata: "HII", name: "Lake Havasu City Airport" },
    KHIO: { iata: "HIO", name: "Portland Hillsboro Airport" },
    KHKA: { iata: "HKA", name: "Blytheville Municipal Airport" },
    KHKS: { iata: "HKS", name: "Hawkins Field" },
    KHKY: { iata: "HKY", name: "Hickory Regional Airport" },
    KHLB: { iata: "HLB", name: "Hillenbrand Industries Airport" },
    KHLC: { iata: "HLC", name: "Hill City Municipal Airport" },
    KHLG: { iata: "HLG", name: "Wheeling Ohio County Airport" },
    KHLM: { iata: "HLM", name: "Park Township Airport" },
    KHLN: { iata: "HLN", name: "Helena Regional Airport" },
    KHLR: { iata: "HLR", name: "Hood Army Air Field" },
    KHMN: { iata: "HMN", name: "Holloman Air Force Base" },
    KHMT: { iata: "HMT", name: "Hemet Ryan Airport" },
    KHNB: { iata: "HNB", name: "Huntingburg Airport" },
    KHND: { iata: "HSH", name: "Henderson Executive Airport" },
    KHOB: { iata: "HOB", name: "Lea County Regional Airport" },
    KHON: { iata: "HON", name: "Huron Regional Airport" },
    KHOP: { iata: "HOP", name: "Campbell AAF (Fort Campbell) Air Field" },
    KHOT: { iata: "HOT", name: "Memorial Field" },
    KHOU: { iata: "HOU", name: "William P Hobby Airport" },
    KHPN: { iata: "HPN", name: "Westchester County Airport" },
    KHPT: { iata: "HPT", name: "Hampton Municipal Airport" },
    KHPY: { iata: "HPY", name: "Baytown Airport" },
    KHQM: { iata: "HQM", name: "Bowerman Airport" },
    KHRI: { iata: "HES", name: "Hermiston Municipal Airport" },
    KHRL: { iata: "HRL", name: "Valley International Airport" },
    KHRO: { iata: "HRO", name: "Boone County Airport" },
    KHSB: { iata: "HSB", name: "Harrisburg Raleigh Airport" },
    KHSE: { iata: "HNC", name: "Billy Mitchell Airport" },
    KHSI: { iata: "HSI", name: "Hastings Municipal Airport" },
    KHSP: { iata: "HSP", name: "Ingalls Field" },
    KHST: { iata: "HST", name: "Homestead ARB Airport" },
    KHSV: { iata: "HSV", name: "Huntsville International Carl T Jones Field" },
    KHTH: { iata: "HTH", name: "Hawthorne Industrial Airport" },
    KHTL: { iata: "HTL", name: "Roscommon County - Blodgett Memorial Airport" },
    KHTO: { iata: "HTO", name: "East Hampton Airport" },
    KHTS: { iata: "HTS", name: "Tri-State/Milton J. Ferguson Field" },
    KHTW: { iata: "HTW", name: "Lawrence County Airpark" },
    KHUA: { iata: "HUA", name: "Redstone Army Air Field" },
    KHUF: { iata: "HUF", name: "Terre Haute International Hulman Field" },
    KHUL: { iata: "HUL", name: "Houlton International Airport" },
    KHUM: { iata: "HUM", name: "Houma Terrebonne Airport" },
    KHUT: { iata: "HUT", name: "Hutchinson Municipal Airport" },
    KHVE: { iata: "HVE", name: "Hanksville Airport" },
    KHVN: { iata: "HVN", name: "Tweed New Haven Airport" },
    KHVR: { iata: "HVR", name: "Havre City County Airport" },
    KHVS: { iata: "HVS", name: "Hartsville Regional Airport" },
    KHWD: { iata: "HWD", name: "Hayward Executive Airport" },
    KHWO: { iata: "HWO", name: "North Perry Airport" },
    KHWV: { iata: "WSH", name: "Brookhaven Airport" },
    KHXD: { iata: "HHH", name: "Hilton Head Airport" },
    KHYA: { iata: "HYA", name: "Cape Cod Gateway Airport" },
    KHYR: { iata: "HYR", name: "Sawyer County Airport" },
    KHYS: { iata: "HYS", name: "Hays Regional Airport" },
    KHZL: { iata: "HZL", name: "Hazleton Municipal Airport" },
    KHZY: { iata: "JFN", name: "Ashtabula County Airport" },
    KIAB: { iata: "IAB", name: "Mc Connell Air Force Base" },
    KIAD: { iata: "IAD", name: "Washington Dulles International Airport" },
    KIAG: { iata: "IAG", name: "Niagara Falls International Airport" },
    KIAH: { iata: "IAH", name: "George Bush Intercontinental Houston Airport" },
    KICL: { iata: "ICL", name: "Schenck Field" },
    KICT: { iata: "ICT", name: "Wichita Mid Continent Airport" },
    KIDA: { iata: "IDA", name: "Idaho Falls Regional Airport" },
    KIDG: { iata: "IDG", name: "Ida Grove Municipal Airport" },
    KIDI: { iata: "IDI", name: "Indiana County/Jimmy Stewart Fld/ Airport" },
    KIDP: { iata: "IDP", name: "Independence Municipal Airport" },
    KIEN: { iata: "XPR", name: "Pine Ridge Airport" },
    KIFA: { iata: "IFA", name: "Iowa Falls Municipal Airport" },
    KIFP: { iata: "IFP", name: "Laughlin Bullhead International Airport" },
    KIGM: { iata: "IGM", name: "Kingman Airport" },
    KIJX: { iata: "IJX", name: "Jacksonville Municipal Airport" },
    KIKK: { iata: "IKK", name: "Greater Kankakee Airport" },
    KILE: { iata: "ILE", name: "Skylark Field" },
    KILG: { iata: "ILG", name: "New Castle Airport" },
    KILM: { iata: "ILM", name: "Wilmington International Airport" },
    KILN: { iata: "ILN", name: "Airborne Airpark" },
    KIML: { iata: "IML", name: "Imperial Municipal Airport" },
    KIMM: { iata: "IMM", name: "Immokalee Regional Airport" },
    KIMS: { iata: "MDN", name: "Madison Municipal Airport" },
    KIMT: { iata: "IMT", name: "Ford Airport" },
    KIND: { iata: "IND", name: "Indianapolis International Airport" },
    KINK: { iata: "INK", name: "Winkler County Airport" },
    KINL: { iata: "INL", name: "Falls International Airport" },
    KINS: { iata: "INS", name: "Creech Air Force Base" },
    KINT: { iata: "INT", name: "Smith Reynolds Airport" },
    KINW: { iata: "INW", name: "Winslow Lindbergh Regional Airport" },
    KIOW: { iata: "IOW", name: "Iowa City Municipal Airport" },
    KIPL: { iata: "IPL", name: "Imperial County Airport" },
    KIPT: { iata: "IPT", name: "Williamsport Regional Airport" },
    KIRK: { iata: "IRK", name: "Kirksville Regional Airport" },
    KIRS: { iata: "IRS", name: "Kirsch Municipal Airport" },
    KISM: { iata: "ISM", name: "Kissimmee Gateway Airport" },
    KISN: { iata: "ISN", name: "Sloulin Field International Airport" },
    KISO: { iata: "ISO", name: "Kinston Regional Jetport At Stallings Field" },
    KISP: { iata: "ISP", name: "Long Island Mac Arthur Airport" },
    KISQ: { iata: "ISQ", name: "Schoolcraft County Airport" },
    KISW: { iata: "ISW", name: "Alexander Field South Wood County Airport" },
    KITH: { iata: "ITH", name: "Ithaca Tompkins Regional Airport" },
    KIWA: { iata: "AZA", name: "Phoenix-Mesa-Gateway Airport" },
    KIWD: { iata: "IWD", name: "Gogebic Iron County Airport" },
    KIWI: { iata: "ISS", name: "Wiscasset Airport" },
    KIWS: { iata: "IWS", name: "West Houston Airport" },
    KIXD: { iata: "JCI", name: "New Century Aircenter Airport" },
    KIYK: { iata: "IYK", name: "Inyokern Airport" },
    KIZA: { iata: "SQA", name: "Santa Ynez Airport" },
    KIZG: { iata: "FRY", name: "Eastern Slopes Regional Airport" },
    KJAC: { iata: "JAC", name: "Jackson Hole Airport" },
    KJAN: { iata: "JAN", name: "Jackson Evers International Airport" },
    KJAS: { iata: "JAS", name: "Jasper County-Bell Field" },
    KJAX: { iata: "JAX", name: "Jacksonville International Airport" },
    KJBR: { iata: "JBR", name: "Jonesboro Municipal Airport" },
    KJCT: { iata: "JCT", name: "Kimble County Airport" },
    KJDN: { iata: "JDN", name: "Jordan Airport" },
    KJEF: { iata: "JEF", name: "Jefferson City Memorial Airport" },
    KJFK: { iata: "JFK", name: "John F Kennedy International Airport" },
    KJHW: { iata: "JHW", name: "Chautauqua County-Jamestown Airport" },
    KJKA: { iata: "GUF", name: "Jack Edwards Airport" },
    KJLN: { iata: "JLN", name: "Joplin Regional Airport" },
    KJMS: { iata: "JMS", name: "Jamestown Regional Airport" },
    KJOT: { iata: "JOT", name: "Joliet Regional Airport" },
    KJQF: { iata: "USA", name: "Concord Regional Airport" },
    KJSO: { iata: "JKV", name: "Cherokee County Airport" },
    KJST: { iata: "JST", name: "John Murtha Johnstown Cambria County Airport" },
    KJVL: { iata: "JVL", name: "Southern Wisconsin Regional Airport" },
    KJXN: { iata: "JXN", name: "Jackson County Reynolds Field" },
    KKIC: { iata: "KIC", name: "Mesa Del Rey Airport" },
    KKLS: { iata: "KLS", name: "Southwest Washington Regional Airport" },
    KKNB: { iata: "KNB", name: "Kanab Municipal Airport" },
    KL08: { iata: "BXS", name: "Borrego Valley Airport" },
    KLAA: { iata: "LAA", name: "Lamar Municipal Airport" },
    KLAF: { iata: "LAF", name: "Purdue University Airport" },
    KLAL: { iata: "LAL", name: "Lakeland Linder Regional Airport" },
    KLAM: { iata: "LAM", name: "Los Alamos Airport" },
    KLAN: { iata: "LAN", name: "Capital City Airport" },
    KLAR: { iata: "LAR", name: "Laramie Regional Airport" },
    KLAS: { iata: "LAS", name: "Harry Reid International Airport" },
    KLAW: { iata: "LAW", name: "Lawton Fort Sill Regional Airport" },
    KLAX: { iata: "LAX", name: "Los Angeles International Airport" },
    KLBB: { iata: "LBB", name: "Lubbock Preston Smith International Airport" },
    KLBE: { iata: "LBE", name: "Arnold Palmer Regional Airport" },
    KLBF: { iata: "LBF", name: "North Platte Regional Airport Lee Bird Field" },
    KLBL: { iata: "LBL", name: "Liberal Mid-America Regional Airport" },
    KLBT: { iata: "LBT", name: "Lumberton Municipal Airport" },
    KLBX: { iata: "LJN", name: "Brazoria County Airport" },
    KLCH: { iata: "LCH", name: "Lake Charles Regional Airport" },
    KLCI: { iata: "LCI", name: "Laconia Municipal Airport" },
    KLCK: { iata: "LCK", name: "Rickenbacker International Airport" },
    KLCQ: { iata: "LCQ", name: "Lake City Municipal Airport" },
    KLDJ: { iata: "LDJ", name: "Linden Airport" },
    KLDM: { iata: "LDM", name: "Mason County Airport" },
    KLEB: { iata: "LEB", name: "Lebanon Municipal Airport" },
    KLEE: { iata: "LEE", name: "Leesburg International Airport" },
    KLEM: { iata: "LEM", name: "Lemmon Municipal Airport" },
    KLEW: { iata: "LEW", name: "Auburn Lewiston Municipal Airport" },
    KLEX: { iata: "LEX", name: "Blue Grass Airport" },
    KLFI: { iata: "LFI", name: "Langley Air Force Base" },
    KLFK: { iata: "LFK", name: "Angelina County Airport" },
    KLFT: { iata: "LFT", name: "Lafayette Regional Airport" },
    KLGA: { iata: "LGA", name: "La Guardia Airport" },
    KLGB: { iata: "LGB", name: "Long Beach /Daugherty Field/ Airport" },
    KLGC: { iata: "LGC", name: "Lagrange Callaway Airport" },
    KLGD: { iata: "LGD", name: "La Grande Union County Airport" },
    KLGF: { iata: "LGF", name: "Laguna AAF (Yuma Proving Ground) Airfield" },
    KLGU: { iata: "LGU", name: "Logan Cache Airport" },
    KLHV: { iata: "LHV", name: "William T. Piper Memorial Airport" },
    KLHW: {
      iata: "LIY",
      name: "Wright Aaf (Fort Stewart)/Midcoast Regional Airport",
    },
    KLHZ: { iata: "LFN", name: "Franklin County Airport" },
    KLIC: { iata: "LIC", name: "Limon Municipal Airport" },
    KLIT: { iata: "LIT", name: "Adams Field" },
    KLKP: { iata: "LKP", name: "Lake Placid Airport" },
    KLKU: { iata: "LOW", name: "Louisa County-Freeman field" },
    KLKV: { iata: "LKV", name: "Lake County Airport" },
    KLLJ: { iata: "CHL", name: "Challis Airport" },
    KLMS: { iata: "LMS", name: "Louisville Winston County Airport" },
    KLMT: { iata: "LMT", name: "Klamath Falls Airport" },
    KLNA: { iata: "LNA", name: "Palm Beach County Park Airport" },
    KLND: { iata: "LND", name: "Hunt Field" },
    KLNK: { iata: "LNK", name: "Lincoln Airport" },
    KLNN: { iata: "LNN", name: "Willoughby Lost Nation Municipal Airport" },
    KLNP: { iata: "LNP", name: "Lonesome Pine Airport" },
    KLNR: { iata: "LNR", name: "Tri-County Regional Airport" },
    KLNS: { iata: "LNS", name: "Lancaster Airport" },
    KLOL: { iata: "LOL", name: "Derby Field" },
    KLOM: { iata: "BBX", name: "Wings Field" },
    KLOT: { iata: "LOT", name: "Lewis University Airport" },
    KLOU: { iata: "LOU", name: "Bowman Field" },
    KLOZ: { iata: "LOZ", name: "London Corbin Airport Magee Field" },
    KLPC: { iata: "LPC", name: "Lompoc Airport" },
    KLQK: { iata: "LQK", name: "Pickens County Airport" },
    KLRD: { iata: "LRD", name: "Laredo International Airport" },
    KLRF: { iata: "LRF", name: "Little Rock Air Force Base" },
    KLRJ: { iata: "LRJ", name: "Le Mars Municipal Airport" },
    KLRU: { iata: "LRU", name: "Las Cruces International Airport" },
    KLSB: { iata: "LSB", name: "Lordsburg Municipal Airport" },
    KLSE: { iata: "LSE", name: "La Crosse Municipal Airport" },
    KLSF: { iata: "LSF", name: "Lawson Army Air Field (Fort Benning)" },
    KLSK: { iata: "LSK", name: "Lusk Municipal Airport" },
    KLSN: { iata: "LSN", name: "Los Banos Municipal Airport" },
    KLSV: { iata: "LSV", name: "Nellis Air Force Base" },
    KLTS: { iata: "LTS", name: "Altus Air Force Base" },
    KLUF: { iata: "LUF", name: "Luke Air Force Base" },
    KLUK: { iata: "LUK", name: "Cincinnati Municipal Airport Lunken Field" },
    KLUL: { iata: "LUL", name: "Hesler Noble Field" },
    KLVK: { iata: "LVK", name: "Livermore Municipal Airport" },
    KLVL: { iata: "LVL", name: "Lawrenceville Brunswick Municipal Airport" },
    KLVM: { iata: "LVM", name: "Mission Field" },
    KLVS: { iata: "LVS", name: "Las Vegas Municipal Airport" },
    KLWB: { iata: "LWB", name: "Greenbrier Valley Airport" },
    KLWC: { iata: "LWC", name: "Lawrence Municipal Airport" },
    KLWL: { iata: "LWL", name: "Wells Municipal Harriet Field" },
    KLWM: { iata: "LWM", name: "Lawrence Municipal Airport" },
    KLWS: { iata: "LWS", name: "Lewiston Nez Perce County Airport" },
    KLWT: { iata: "LWT", name: "Lewistown Municipal Airport" },
    KLWV: { iata: "LWV", name: "Lawrenceville Vincennes International Airport" },
    KLXN: { iata: "LXN", name: "Jim Kelly Field" },
    KLXV: { iata: "LXV", name: "Lake County Airport" },
    KLYH: { iata: "LYH", name: "Lynchburg Regional Preston Glenn Field" },
    KLYO: { iata: "LYO", name: "Lyons Rice County Municipal Airport" },
    KLZU: { iata: "LZU", name: "Gwinnett County Briscoe Field" },
    KMAC: { iata: "MAC", name: "Macon Downtown Airport" },
    KMAE: { iata: "MAE", name: "Madera Municipal Airport" },
    KMAF: { iata: "MAF", name: "Midland International Airport" },
    KMAW: { iata: "MAW", name: "Malden Regional Airport" },
    KMBG: { iata: "MBG", name: "Mobridge Municipal Airport" },
    KMBL: { iata: "MBL", name: "Manistee Co Blacker Airport" },
    KMBO: { iata: "DXE", name: "Bruce Campbell Field" },
    KMBS: { iata: "MBS", name: "MBS International Airport" },
    KMBY: { iata: "MBY", name: "Omar N Bradley Airport" },
    KMCB: { iata: "MCB", name: "Mc Comb/Pike County/John E Lewis Field" },
    KMCC: { iata: "MCC", name: "Mc Clellan Airfield" },
    KMCD: { iata: "MCD", name: "Mackinac Island Airport" },
    KMCE: { iata: "MCE", name: "Merced Regional Macready Field" },
    KMCF: { iata: "MCF", name: "MacDill Air Force Base" },
    KMCI: { iata: "MCI", name: "Kansas City International Airport" },
    KMCK: { iata: "MCK", name: "Mc Cook Ben Nelson Regional Airport" },
    KMCN: { iata: "MCN", name: "Middle Georgia Regional Airport" },
    KMCO: { iata: "MCO", name: "Orlando International Airport" },
    KMCW: { iata: "MCW", name: "Mason City Municipal Airport" },
    KMDD: { iata: "MDD", name: "Midland Airpark" },
    KMDH: { iata: "MDH", name: "Southern Illinois Airport" },
    KMDS: { iata: "XMD", name: "Madison Municipal Airport" },
    KMDT: { iata: "MDT", name: "Harrisburg International Airport" },
    KMDW: { iata: "MDW", name: "Chicago Midway International Airport" },
    KMDZ: { iata: "MDF", name: "Taylor County Airport" },
    KMEB: { iata: "MXE", name: "Laurinburg Maxton Airport" },
    KMEI: { iata: "MEI", name: "Key Field" },
    KMEM: { iata: "MEM", name: "Memphis International Airport" },
    KMER: { iata: "MER", name: "Castle Airport" },
    KMEV: { iata: "MEV", name: "Minden Tahoe Airport" },
    KMFD: { iata: "MFD", name: "Mansfield Lahm Regional Airport" },
    KMFE: { iata: "MFE", name: "Mc Allen Miller International Airport" },
    KMFI: { iata: "MFI", name: "Marshfield Municipal Airport" },
    KMFR: { iata: "MFR", name: "Rogue Valley International Medford Airport" },
    KMFV: { iata: "MFV", name: "Accomack County Airport" },
    KMGC: { iata: "MGC", name: "Michigan City Municipal Airport" },
    KMGE: { iata: "MGE", name: "Dobbins Air Reserve Base Airport" },
    KMGJ: { iata: "MGJ", name: "Orange County Airport" },
    KMGM: { iata: "MGM", name: "Montgomery Regional (Dannelly Field) Airport" },
    KMGR: { iata: "MGR", name: "Moultrie Municipal Airport" },
    KMGW: { iata: "MGW", name: "Morgantown Municipal Walter L. Bill Hart Field" },
    KMGY: { iata: "MGY", name: "Dayton Wright Brothers Airport" },
    KMHE: { iata: "MHE", name: "Mitchell Municipal Airport" },
    KMHK: { iata: "MHK", name: "Manhattan Regional Airport" },
    KMHL: { iata: "MHL", name: "Marshall Memorial Municipal Airport" },
    KMHR: { iata: "MHR", name: "Sacramento Mather Airport" },
    KMHT: { iata: "MHT", name: "Manchester Airport" },
    KMHV: { iata: "MHV", name: "Mojave Airport" },
    KMIA: { iata: "MIA", name: "Miami International Airport" },
    KMIB: { iata: "MIB", name: "Minot Air Force Base" },
    KMIC: { iata: "MIC", name: "Crystal Airport" },
    KMIE: { iata: "MIE", name: "Delaware County Johnson Field" },
    KMIO: { iata: "MIO", name: "Miami Municipal Airport" },
    KMIT: { iata: "MIT", name: "Shafter Minter Field" },
    KMIV: { iata: "MIV", name: "Millville Municipal Airport" },
    KMIW: { iata: "MIW", name: "Marshalltown Municipal Airport" },
    KMJQ: { iata: "MJQ", name: "Jackson Municipal Airport" },
    KMJX: { iata: "MJX", name: "Robert J. Miller Air Park" },
    KMKC: { iata: "MKC", name: "Charles B. Wheeler Downtown Airport" },
    KMKE: { iata: "MKE", name: "General Mitchell International Airport" },
    KMKG: { iata: "MKG", name: "Muskegon County Airport" },
    KMKL: { iata: "MKL", name: "Mc Kellar Sipes Regional Airport" },
    KMKO: { iata: "MKO", name: "Davis Field" },
    KMKT: { iata: "MKT", name: "Mankato Regional Airport" },
    KMKY: { iata: "MRK", name: "Marco Island Airport" },
    KMLB: { iata: "MLB", name: "Melbourne International Airport" },
    KMLC: { iata: "MLC", name: "Mc Alester Regional Airport" },
    KMLD: { iata: "MLD", name: "Malad City Airport" },
    KMLE: { iata: "MIQ", name: "Millard Airport" },
    KMLF: { iata: "MLF", name: "Milford Municipal-Ben and Judy Briscoe Field" },
    KMLI: { iata: "MLI", name: "Quad City International Airport" },
    KMLJ: { iata: "MLJ", name: "Baldwin County Airport" },
    KMLS: { iata: "MLS", name: "Frank Wiley Field" },
    KMLT: { iata: "MLT", name: "Millinocket Municipal Airport" },
    KMLU: { iata: "MLU", name: "Monroe Regional Airport" },
    KMMH: { iata: "MMH", name: "Mammoth Yosemite Airport" },
    KMMI: { iata: "MMI", name: "McMinn County Airport" },
    KMML: {
      iata: "MML",
      name: "Southwest Minnesota Regional-Marshall-Ryan Field",
    },
    KMMS: { iata: "MMS", name: "Selfs Airport" },
    KMMT: { iata: "MMT", name: "Mc Entire JNGB Airport" },
    KMMU: { iata: "MMU", name: "Morristown Municipal Airport" },
    KMNM: { iata: "MNM", name: "Menominee Marinette Twin County Airport" },
    KMNN: { iata: "MNN", name: "Marion Municipal Airport" },
    KMOB: { iata: "MOB", name: "Mobile Regional Airport" },
    KMOD: { iata: "MOD", name: "Modesto City Co-Harry Sham Field" },
    KMOP: { iata: "MOP", name: "Mount Pleasant Municipal Airport" },
    KMOR: { iata: "MOR", name: "Moore Murrell Airport" },
    KMOT: { iata: "MOT", name: "Minot International Airport" },
    KMOX: { iata: "MOX", name: "Morris Municipal - Charlie Schmidt Airport" },
    KMPI: { iata: "RMY", name: "Mariposa Yosemite Airport" },
    KMPJ: { iata: "MPJ", name: "Petit Jean Park Airport" },
    KMPO: { iata: "MPO", name: "Pocono Mountains Municipal Airport" },
    KMPR: { iata: "MPR", name: "Mc Pherson Airport" },
    KMPV: { iata: "MPV", name: "Edward F Knapp State Airport" },
    KMPZ: { iata: "MPZ", name: "Mount Pleasant Municipal Airport" },
    KMQB: { iata: "MQB", name: "Macomb Municipal Airport" },
    KMQI: { iata: "MEO", name: "Dare County Regional Airport" },
    KMQS: { iata: "CTH", name: "Chester County G O Carlson Airport" },
    KMQW: { iata: "MQW", name: "Telfair Wheeler Airport" },
    KMQY: { iata: "MQY", name: "Smyrna Airport" },
    KMRB: { iata: "MRB", name: "Eastern Wv Regional Shepherd Field" },
    KMRC: { iata: "MRC", name: "Maury County Airport" },
    KMRF: { iata: "MRF", name: "Marfa Municipal Airport" },
    KMRN: { iata: "MRN", name: "Foothills Regional Airport" },
    KMRY: { iata: "MRY", name: "Monterey Peninsula Airport" },
    KMSL: { iata: "MSL", name: "Northwest Alabama Regional Airport" },
    KMSN: { iata: "MSN", name: "Dane County Regional Truax Field" },
    KMSO: { iata: "MSO", name: "Missoula International Airport" },
    KMSP: {
      iata: "MSP",
      name: "Minneapolis-St Paul International/Wold-Chamberlain Airport",
    },
    KMSS: { iata: "MSS", name: "Massena International Richards Field" },
    KMSV: { iata: "MSV", name: "Sullivan County International Airport" },
    KMSY: {
      iata: "MSY",
      name: "Louis Armstrong New Orleans International Airport",
    },
    KMTC: { iata: "MTC", name: "Selfridge Angb Airport" },
    KMTH: { iata: "MTH", name: "The Florida Keys Marathon Airport" },
    KMTJ: { iata: "MTJ", name: "Montrose Regional Airport" },
    KMTN: { iata: "MTN", name: "Martin State Airport" },
    KMTO: { iata: "MTO", name: "Coles County Memorial Airport" },
    KMTP: { iata: "MTP", name: "Montauk Airport" },
    KMTW: { iata: "MTW", name: "Manitowoc County Airport" },
    KMUI: {
      iata: "MUI",
      name: "Muir Army Air Field (Fort Indiantown Gap) Airport",
    },
    KMUL: { iata: "MUL", name: "Spence Airport" },
    KMUO: { iata: "MUO", name: "Mountain Home Air Force Base" },
    KMUT: { iata: "MUT", name: "Muscatine Municipal Airport" },
    KMVC: { iata: "MVC", name: "Monroe County Airport" },
    KMVE: { iata: "MVE", name: "Montevideo Chippewa County Airport" },
    KMVL: { iata: "MVL", name: "Morrisville Stowe State Airport" },
    KMVN: { iata: "MVN", name: "Mount Vernon Airport" },
    KMVY: { iata: "MVY", name: "Martha's Vineyard Airport" },
    KMWA: { iata: "MWA", name: "Williamson County Regional Airport" },
    KMWC: { iata: "MWC", name: "Lawrence J Timmerman Airport" },
    KMWH: { iata: "MWH", name: "Grant Co International Airport" },
    KMWL: { iata: "MWL", name: "Mineral Wells Airport" },
    KMWM: { iata: "MWM", name: "Windom Municipal Airport" },
    KMWO: {
      iata: "MWO",
      name: "Middletown Regional-Hook Field Municipal Airport",
    },
    KMXA: { iata: "MXA", name: "Manila Municipal Airport" },
    KMXF: { iata: "MXF", name: "Maxwell Air Force Base" },
    KMXO: { iata: "MXO", name: "Monticello Regional Airport" },
    KMYF: { iata: "MYF", name: "Montgomery Field" },
    KMYL: { iata: "MYL", name: "Mc Call Municipal Airport" },
    KMYR: { iata: "MYR", name: "Myrtle Beach International Airport" },
    KMYV: { iata: "MYV", name: "Yuba County Airport" },
    KMZJ: { iata: "MZJ", name: "Pinal Airpark" },
    KMZZ: { iata: "MZZ", name: "Marion Municipal Airport" },
    KNBG: {
      iata: "NBG",
      name: "New Orleans Nas Jrb/Alvin Callender Field/ Airport",
    },
    KNBJ: { iata: "NHX", name: "Barin Nolf Airport" },
    KNDY: { iata: "DGN", name: "Dahlgren Naval Surface Warfare Center Airport" },
    KNEL: { iata: "NEL", name: "Lakehurst NAES /Maxfield Field" },
    KNEN: { iata: "NEN", name: "Whitehouse Nolf Airport" },
    KNEW: { iata: "NEW", name: "Lakefront Airport" },
    KNFL: { iata: "NFL", name: "Fallon Naval Air Station /Van Voorhis Field" },
    KNFW: { iata: "FWH", name: "Fort Worth Nas Jrb/Carswell field" },
    KNGP: { iata: "NGP", name: "Corpus Christi Naval Air Station/Truax Field" },
    KNGU: { iata: "NGU", name: "Norfolk Ns (Chambers Fld) Airport" },
    KNGW: { iata: "NGW", name: "Cabaniss Field Nolf Airport" },
    KNHK: {
      iata: "NHK",
      name: "Patuxent River Naval Air Station/Trapnell Field Aiport",
    },
    KNIP: { iata: "NIP", name: "Jacksonville Naval Air Station" },
    KNJK: { iata: "NJK", name: "El Centro Naf Airport" },
    KNKX: { iata: "NKX", name: "Miramar Marine Corps Air Station" },
    KNLC: {
      iata: "NLC",
      name: "Lemoore Naval Air Station (Reeves Field) Airport",
    },
    KNPA: {
      iata: "NPA",
      name: "Pensacola Naval Air Station/Forrest Sherman field",
    },
    KNQA: { iata: "NQA", name: "Millington Regional Jetport Airport" },
    KNQI: { iata: "NQI", name: "Kingsville Naval Air Station" },
    KNQX: { iata: "NQX", name: "Key West Nas /Boca Chica Field/ Airport" },
    KNRB: {
      iata: "NRB",
      name: "Mayport Ns (Adm David L. Mcdonald Field) Airport",
    },
    KNRS: { iata: "NRS", name: "Imperial Beach Nolf (Ream Fld) Airport" },
    KNSE: { iata: "NSE", name: "Whiting Field Naval Air Station North Airport" },
    KNTD: {
      iata: "NTD",
      name: "Point Mugu Naval Air Station (Naval Base Ventura Co)",
    },
    KNTU: { iata: "NTU", name: "Oceana Nas /Apollo Soucek Field/ Station" },
    KNUN: { iata: "NUN", name: "Saufley Field Nolf Airport" },
    KNUQ: { iata: "NUQ", name: "Moffett Federal Airfield" },
    KNUW: {
      iata: "NUW",
      name: "Whidbey Island Naval Air Station /Ault Field/ Airport",
    },
    KNVD: { iata: "NVD", name: "Nevada Municipal Airport" },
    KNYG: { iata: "NYG", name: "Quantico MCAF /Turner field" },
    KNYL: { iata: "YUM", name: "Yuma MCAS/Yuma International Airport" },
    KNZY: { iata: "NZY", name: "North Island Naval Air Station-Halsey Field" },
    KO02: { iata: "NVN", name: "Nervino Airport" },
    KO27: { iata: "ODC", name: "Oakdale Airport" },
    KO43: { iata: "EYR", name: "Yerington Municipal Airport" },
    KOAJ: { iata: "OAJ", name: "Albert J Ellis Airport" },
    KOAK: { iata: "OAK", name: "Metropolitan Oakland International Airport" },
    KOAR: { iata: "OAR", name: "Marina Municipal Airport" },
    KOBE: { iata: "OBE", name: "Okeechobee County Airport" },
    KOCF: { iata: "OCF", name: "Ocala International Jim Taylor Field" },
    KOCH: { iata: "OCH", name: "A L Mangham Jr. Regional Airport" },
    KOCW: { iata: "OCW", name: "Warren Field" },
    KOEO: { iata: "OEO", name: "L O Simenstad Municipal Airport" },
    KOFF: { iata: "OFF", name: "Offutt Air Force Base" },
    KOFK: { iata: "OFK", name: "Karl Stefan Memorial Airport" },
    KOGA: { iata: "OGA", name: "Searle Field" },
    KOGB: { iata: "OGB", name: "Orangeburg Municipal Airport" },
    KOGD: { iata: "OGD", name: "Ogden Hinckley Airport" },
    KOGS: { iata: "OGS", name: "Ogdensburg International Airport" },
    KOIC: { iata: "OIC", name: "Lt Warren Eaton Airport" },
    KOJC: { iata: "OJC", name: "Johnson County Executive Airport" },
    KOKB: { iata: "OCN", name: "Oceanside Municipal Airport" },
    KOKC: { iata: "OKC", name: "Will Rogers World Airport" },
    KOKH: { iata: "ODW", name: "AJ Eisenberg Airport" },
    KOKK: { iata: "OKK", name: "Kokomo Municipal Airport" },
    KOKM: { iata: "OKM", name: "Okmulgee Regional Airport" },
    KOKS: { iata: "OKS", name: "Garden County Airport" },
    KOKV: { iata: "WGO", name: "Winchester Regional Airport" },
    KOLD: { iata: "OLD", name: "Dewitt FieldOld Town Municipal Airport" },
    KOLE: { iata: "OLE", name: "Cattaraugus County-Olean Airport" },
    KOLF: { iata: "OLF", name: "L M Clayton Airport" },
    KOLM: { iata: "OLM", name: "Olympia Regional Airport" },
    KOLS: { iata: "OLS", name: "Nogales International Airport" },
    KOLU: { iata: "OLU", name: "Columbus Municipal Airport" },
    KOLV: { iata: "OLV", name: "Olive Branch Airport" },
    KOLY: { iata: "OLY", name: "Olney Noble Airport" },
    KOMA: { iata: "OMA", name: "Eppley Airfield" },
    KOMK: { iata: "OMK", name: "Omak Airport" },
    KONA: { iata: "ONA", name: "Winona Municipal-Max Conrad Field" },
    KONL: { iata: "ONL", name: "The O'Neill Municipal John L Baker Field" },
    KONM: { iata: "ONM", name: "Socorro Municipal Airport" },
    KONO: { iata: "ONO", name: "Ontario Municipal Airport" },
    KONP: { iata: "ONP", name: "Newport Municipal Airport" },
    KONT: { iata: "ONT", name: "Ontario International Airport" },
    KONY: { iata: "ONY", name: "Olney Municipal Airport" },
    KOOA: { iata: "OOA", name: "Oskaloosa Municipal Airport" },
    KOPF: { iata: "OPF", name: "Opa-locka Executive Airport" },
    KOPL: { iata: "OPL", name: "St Landry Parish Ahart Field" },
    KOQU: { iata: "NCO", name: "Quonset State Airport" },
    KORD: { iata: "ORD", name: "Chicago O'Hare International Airport" },
    KORF: { iata: "ORF", name: "Norfolk International Airport" },
    KORH: { iata: "ORH", name: "Worcester Regional Airport" },
    KORL: { iata: "ORL", name: "Orlando Executive Airport" },
    KORS: { iata: "ESD", name: "Orcas Island Airport" },
    KOSA: { iata: "MPS", name: "Mount Pleasant Regional Airport" },
    KOSC: { iata: "OSC", name: "Oscoda Wurtsmith Airport" },
    KOSH: { iata: "OSH", name: "Wittman Regional Airport" },
    KOSU: { iata: "OSU", name: "Ohio State University Airport" },
    KOSX: { iata: "OSX", name: "Kosciusko Attala County Airport" },
    KOTG: { iata: "OTG", name: "Worthington Municipal Airport" },
    KOTH: { iata: "OTH", name: "Southwest Oregon Regional Airport" },
    KOTM: { iata: "OTM", name: "Ottumwa Regional Airport" },
    KOUN: { iata: "OUN", name: "University of Oklahoma Westheimer Airport" },
    KOVE: { iata: "OVE", name: "Oroville Municipal Airport" },
    KOWA: { iata: "OWA", name: "Owatonna Degner Regional Airport" },
    KOWB: { iata: "OWB", name: "Owensboro Daviess County Airport" },
    KOWD: { iata: "OWD", name: "Norwood Memorial Airport" },
    KOWK: { iata: "OWK", name: "Central Maine Arpt of Norridgewock Airport" },
    KOXB: { iata: "OCE", name: "Ocean City Municipal Airport" },
    KOXC: { iata: "OXC", name: "Waterbury Oxford Airport" },
    KOXD: { iata: "OXD", name: "Miami University Airport" },
    KOXR: { iata: "OXR", name: "Oxnard Airport" },
    KOYM: { iata: "STQ", name: "St Marys Municipal Airport" },
    KOZA: { iata: "OZA", name: "Ozona Municipal Airport" },
    KOZR: { iata: "OZR", name: "Cairns AAF (Fort Rucker) Air Field" },
    KP04: { iata: "BSQ", name: "Bisbee Municipal Airport" },
    KP14: { iata: "HBK", name: "Holbrook Municipal Airport" },
    KP33: { iata: "CWX", name: "Cochise County Airport" },
    KPAE: { iata: "PAE", name: "Snohomish County (Paine Field) Airport" },
    KPAH: { iata: "PAH", name: "Barkley Regional Airport" },
    KPAM: { iata: "PAM", name: "Tyndall Air Force Base" },
    KPAN: { iata: "PJB", name: "Payson Airport" },
    KPAO: { iata: "PAO", name: "Palo Alto Airport of Santa Clara County" },
    KPBF: { iata: "PBF", name: "Grider Field" },
    KPBG: { iata: "PBG", name: "Plattsburgh International Airport" },
    KPBI: { iata: "PBI", name: "Palm Beach International Airport" },
    KPBX: { iata: "PVL", name: "Pike County-Hatcher Field" },
    KPDC: { iata: "PCD", name: "Prairie Du Chien Municipal Airport" },
    KPDK: { iata: "PDK", name: "DeKalb Peachtree Airport" },
    KPDT: { iata: "PDT", name: "Eastern Oregon Regional At Pendleton Airport" },
    KPDX: { iata: "PDX", name: "Portland International Airport" },
    KPEQ: { iata: "PEQ", name: "Pecos Municipal Airport" },
    KPFC: { iata: "PFC", name: "Pacific City State Airport" },
    KPFN: { iata: "PFN", name: "Panama City-Bay Co International Airport" },
    KPGA: { iata: "PGA", name: "Page Municipal Airport" },
    KPGD: { iata: "PGD", name: "Charlotte County Airport" },
    KPGR: { iata: "PGR", name: "Kirk Field" },
    KPGV: { iata: "PGV", name: "Pitt Greenville Airport" },
    KPHD: { iata: "PHD", name: "Harry Clever Field" },
    KPHF: {
      iata: "PHF",
      name: "Newport News Williamsburg International Airport",
    },
    KPHH: { iata: "ADR", name: "Robert F Swinnie Airport" },
    KPHK: { iata: "PHK", name: "Palm Beach Co Glades Airport" },
    KPHL: { iata: "PHL", name: "Philadelphia International Airport" },
    KPHN: { iata: "PHN", name: "St Clair County International Airport" },
    KPHP: { iata: "PHP", name: "Philip Airport" },
    KPHT: { iata: "PHT", name: "Henry County Airport" },
    KPHX: { iata: "PHX", name: "Phoenix Sky Harbor International Airport" },
    KPIA: { iata: "PIA", name: "Greater Peoria Regional Airport" },
    KPIB: { iata: "PIB", name: "Hattiesburg Laurel Regional Airport" },
    KPIE: { iata: "PIE", name: "St Petersburg Clearwater International Airport" },
    KPIH: { iata: "PIH", name: "Pocatello Regional Airport" },
    KPIM: { iata: "PIM", name: "Harris County Airport" },
    KPIR: { iata: "PIR", name: "Pierre Regional Airport" },
    KPIT: { iata: "PIT", name: "Pittsburgh International Airport" },
    KPKB: { iata: "PKB", name: "Mid Ohio Valley Regional Airport" },
    KPKD: { iata: "PKD", name: "Park Rapids Municipal Konshok Field" },
    KPKF: { iata: "PKF", name: "Park Falls Municipal Airport" },
    KPLK: { iata: "PLK", name: "M. Graham Clark-Taney County Airport" },
    KPLN: {
      iata: "PLN",
      name: "Pellston Regional Airport of Emmet County Airport",
    },
    KPLR: { iata: "PLR", name: "St Clair County Airport" },
    KPMB: { iata: "PMB", name: "Pembina Municipal Airport" },
    KPMD: { iata: "PMD", name: "Palmdale Regional/USAF Plant 42 Airport" },
    KPMH: { iata: "PMH", name: "Greater Portsmouth Regional Airport" },
    KPMP: { iata: "PPM", name: "Pompano Beach Airpark" },
    KPNA: { iata: "PWY", name: "Ralph Wenz Field" },
    KPNC: { iata: "PNC", name: "Ponca City Regional Airport" },
    KPNE: { iata: "PNE", name: "Northeast Philadelphia Airport" },
    KPNN: { iata: "PNN", name: "Princeton Municipal Airport" },
    KPNS: { iata: "PNS", name: "Pensacola Regional Airport" },
    KPOB: { iata: "POB", name: "Pope Air Force Base" },
    KPOC: { iata: "POC", name: "Brackett Field" },
    KPOE: { iata: "POE", name: "Polk Army Air Field" },
    KPOF: { iata: "POF", name: "Poplar Bluff Municipal Airport" },
    KPOH: { iata: "POH", name: "Pocahontas Municipal Airport" },
    KPOU: { iata: "POU", name: "Dutchess County Airport" },
    KPOY: { iata: "POY", name: "Powell Municipal Airport" },
    KPPA: { iata: "PPA", name: "Perry Lefors Field" },
    KPPF: { iata: "PPF", name: "Tri City Airport" },
    KPPO: { iata: "LPO", name: "La Porte Municipal Airport" },
    KPQI: {
      iata: "PQI",
      name: "Northern Maine Regional Airport at Presque Isle",
    },
    KPQL: { iata: "PGL", name: "Trent Lott International Airport" },
    KPRB: { iata: "PRB", name: "Paso Robles Municipal Airport" },
    KPRC: { iata: "PRC", name: "Ernest A. Love Field" },
    KPRO: { iata: "PRO", name: "Perry Municipal Airport" },
    KPRX: { iata: "PRX", name: "Cox Field" },
    KPSB: { iata: "PSB", name: "Mid State Airport" },
    KPSC: { iata: "PSC", name: "Tri Cities Airport" },
    KPSF: { iata: "PSF", name: "Pittsfield Municipal Airport" },
    KPSK: { iata: "PSK", name: "New River Valley Airport" },
    KPSM: { iata: "PSM", name: "Portsmouth International at Pease Airport" },
    KPSN: { iata: "PSN", name: "Palestine Municipal Airport" },
    KPSO: { iata: "PGO", name: "Stevens Field" },
    KPSP: { iata: "PSP", name: "Palm Springs International Airport" },
    KPSX: { iata: "PSX", name: "Palacios Municipal Airport" },
    KPTB: { iata: "PTB", name: "Dinwiddie County Airport" },
    KPTK: { iata: "PTK", name: "Oakland County International Airport" },
    KPTN: { iata: "PTN", name: "Harry P Williams Memorial Airport" },
    KPTS: { iata: "PTS", name: "Atkinson Municipal Airport" },
    KPTT: { iata: "PTT", name: "Pratt Industrial Airport" },
    KPTV: { iata: "PTV", name: "Porterville Municipal Airport" },
    KPTW: { iata: "PTW", name: "Pottstown Limerick Airport" },
    KPUB: { iata: "PUB", name: "Pueblo Memorial Airport" },
    KPUC: { iata: "PUC", name: "Carbon County Regional/Buck Davis Field" },
    KPUW: { iata: "PUW", name: "Pullman Moscow Regional Airport" },
    KPVC: { iata: "PVC", name: "Provincetown Municipal Airport" },
    KPVD: { iata: "PVD", name: "Theodore Francis Green State Airport" },
    KPVF: { iata: "PVF", name: "Placerville Airport" },
    KPVU: { iata: "PVU", name: "Provo Municipal Airport" },
    KPVW: { iata: "PVW", name: "Hale County Airport" },
    KPWA: { iata: "PWA", name: "Wiley Post Airport" },
    KPWD: { iata: "PWD", name: "Sher Wood Airport" },
    KPWM: { iata: "PWM", name: "Portland International Jetport Airport" },
    KPWT: { iata: "PWT", name: "Bremerton National Airport" },
    KPYM: { iata: "PYM", name: "Plymouth Municipal Airport" },
    KRAC: { iata: "RAC", name: "John H Batten Airport" },
    KRAL: { iata: "RAL", name: "Riverside Municipal Airport" },
    KRAP: { iata: "RAP", name: "Rapid City Regional Airport" },
    KRBD: { iata: "RBD", name: "Dallas Executive Airport" },
    KRBG: { iata: "RBG", name: "Roseburg Regional Airport" },
    KRBL: { iata: "RBL", name: "Red Bluff Municipal Airport" },
    KRBW: { iata: "RBW", name: "Lowcountry Regional Airport" },
    KRCA: { iata: "RCA", name: "Ellsworth Air Force Base" },
    KRCK: { iata: "RCK", name: "H H Coffield Regional Airport" },
    KRCR: { iata: "RCR", name: "Fulton County Airport" },
    KRCT: { iata: "RCT", name: "Nartron Field" },
    KRDD: { iata: "RDD", name: "Redding Municipal Airport" },
    KRDG: { iata: "RDG", name: "Reading Regional Carl A Spaatz Field" },
    KRDM: { iata: "RDM", name: "Roberts Field" },
    KRDR: { iata: "RDR", name: "Grand Forks Air Force Base" },
    KRDU: { iata: "RDU", name: "Raleigh Durham International Airport" },
    KREO: { iata: "REO", name: "Rome State Airport" },
    KRFD: { iata: "RFD", name: "Chicago Rockford International Airport" },
    KRFG: { iata: "RFG", name: "Rooke Field" },
    KRHI: { iata: "RHI", name: "Rhinelander Oneida County Airport" },
    KRHV: { iata: "RHV", name: "Reid Hillview of Santa Clara County Airport" },
    KRIC: { iata: "RIC", name: "Richmond International Airport" },
    KRID: { iata: "RID", name: "Richmond Municipal Airport" },
    KRIF: { iata: "RIF", name: "Richfield Municipal Airport" },
    KRIL: { iata: "RIL", name: "Garfield County Regional Airport" },
    KRIR: { iata: "RIR", name: "Flabob Airport" },
    KRIV: { iata: "RIV", name: "March ARB Airport" },
    KRIW: { iata: "RIW", name: "Riverton Regional Airport" },
    KRKD: { iata: "RKD", name: "Knox County Regional Airport" },
    KRKP: { iata: "RKP", name: "Aransas County Airport" },
    KRKR: { iata: "RKR", name: "Robert S Kerr Airport" },
    KRKS: { iata: "RKS", name: "Rock Springs Sweetwater County Airport" },
    KRKW: { iata: "RKW", name: "Rockwood Municipal Airport" },
    KRLD: { iata: "RLD", name: "Richland Airport" },
    KRME: { iata: "RME", name: "Griffiss International Airport" },
    KRMG: { iata: "RMG", name: "Richard B Russell Airport" },
    KRNC: { iata: "RNC", name: "Warren County Memorial Airport" },
    KRND: { iata: "RND", name: "Randolph Air Force Base" },
    KRNH: { iata: "RNH", name: "New Richmond Regional Airport" },
    KRNO: { iata: "RNO", name: "Reno Tahoe International Airport" },
    KRNT: { iata: "RNT", name: "Renton Municipal Airport" },
    KROA: { iata: "ROA", name: "Roanoke Regional Woodrum Field" },
    KROC: { iata: "ROC", name: "Greater Rochester International Airport" },
    KROG: { iata: "ROG", name: "Rogers Municipal Carter Field" },
    KROW: { iata: "ROW", name: "Roswell International Air Center Airport" },
    KROX: { iata: "ROX", name: "Roseau Municipal Rudy Billberg Field" },
    KRPD: { iata: "RIE", name: "Rice Lake Regional Carl's Field" },
    KRPX: { iata: "RPX", name: "Roundup Airport" },
    KRQB: { iata: "WBR", name: "Roben Hood Airport" },
    KRQO: { iata: "RQO", name: "El Reno Regional Airport" },
    KRRL: { iata: "RRL", name: "Merrill Municipal Airport" },
    KRRT: { iata: "RRT", name: "Warroad International Memorial Field" },
    KRSL: { iata: "RSL", name: "Russell Municipal Airport" },
    KRSN: { iata: "RSN", name: "Ruston Regional Airport" },
    KRST: { iata: "RST", name: "Rochester International Airport" },
    KRSW: { iata: "RSW", name: "Southwest Florida International Airport" },
    KRTN: { iata: "RTN", name: "Raton Municipal-Crews Field" },
    KRUQ: { iata: "SRW", name: "Rowan County Airport" },
    KRUT: { iata: "RUT", name: "Rutland - Southern Vermont Regional Airport" },
    KRVL: { iata: "RED", name: "Mifflin County Airport" },
    KRVS: { iata: "RVS", name: "Richard Lloyd Jones Jr Airport" },
    KRWF: { iata: "RWF", name: "Redwood Falls Municipal Airport" },
    KRWI: { iata: "RWI", name: "Rocky Mount Wilson Regional Airport" },
    KRWL: { iata: "RWL", name: "Rawlins Municipal Harvey Field" },
    KRXE: { iata: "RXE", name: "Rexburg Madison County Airport" },
    KRZL: { iata: "RNZ", name: "Jasper County Airport" },
    KRZZ: { iata: "RZZ", name: "Halifax County Airport" },
    KS03: { iata: "AHM", name: "Ashland Municipal Sumner Parker Field" },
    KS05: { iata: "BDY", name: "Bandon State Airport" },
    KS21: { iata: "SUO", name: "Sunriver Airport" },
    KS98: { iata: "VSK", name: "Vista Field" },
    KSAA: { iata: "SAA", name: "Shively Field" },
    KSAC: { iata: "SAC", name: "Sacramento Executive Airport" },
    KSAD: { iata: "SAD", name: "Safford Regional Airport" },
    KSAF: { iata: "SAF", name: "Santa Fe Municipal Airport" },
    KSAN: { iata: "SAN", name: "San Diego International Airport" },
    KSAR: { iata: "SAR", name: "Sparta Community Hunter Field" },
    KSAS: { iata: "SAS", name: "Salton Sea Airport" },
    KSAT: { iata: "SAT", name: "San Antonio International Airport" },
    KSAV: { iata: "SAV", name: "Savannah Hilton Head International Airport" },
    KSAW: { iata: "MQT", name: "Sawyer International Airport" },
    KSBA: { iata: "SBA", name: "Santa Barbara Municipal Airport" },
    KSBD: { iata: "SBD", name: "San Bernardino International Airport" },
    KSBM: { iata: "SBM", name: "Sheboygan County Memorial Airport" },
    KSBN: { iata: "SBN", name: "South Bend Regional Airport" },
    KSBP: { iata: "SBP", name: "San Luis County Regional Airport" },
    KSBS: { iata: "SBS", name: "Steamboat Springs Bob Adams Field" },
    KSBX: { iata: "SBX", name: "Shelby Airport" },
    KSBY: { iata: "SBY", name: "Salisbury Ocean City Wicomico Regional Airport" },
    KSCB: { iata: "SCB", name: "Scribner State Airport" },
    KSCH: { iata: "SCH", name: "Schenectady County Airport" },
    KSCK: { iata: "SCK", name: "Stockton Metropolitan Airport" },
    KSDF: { iata: "SDF", name: "Louisville International Standiford Field" },
    KSDL: { iata: "SCF", name: "Scottsdale Airport" },
    KSDM: { iata: "SDM", name: "Brown Field Municipal Airport" },
    KSDY: { iata: "SDY", name: "Sidney Richland Municipal Airport" },
    KSEA: { iata: "SEA", name: "Seattle Tacoma International Airport" },
    KSEE: { iata: "SEE", name: "Gillespie Field" },
    KSEF: { iata: "SEF", name: "Sebring Regional Airport" },
    KSEG: { iata: "SEG", name: "Penn Valley Airport" },
    KSEM: { iata: "SEM", name: "Craig Field" },
    KSEP: { iata: "SEP", name: "Clark Field Municipal Airport" },
    KSER: { iata: "SER", name: "Freeman Municipal Airport" },
    KSEZ: { iata: "SDX", name: "Sedona Airport" },
    KSFB: { iata: "SFB", name: "Orlando Sanford International Airport" },
    KSFF: { iata: "SFF", name: "Felts Field" },
    KSFM: { iata: "SFM", name: "Sanford Regional Airport" },
    KSFO: { iata: "SFO", name: "San Francisco International Airport" },
    KSFZ: { iata: "SFZ", name: "North Central State Airport" },
    KSGF: { iata: "SGF", name: "Springfield Branson National Airport" },
    KSGH: { iata: "SGH", name: "Springfield Beckley Municipal Airport" },
    KSGJ: { iata: "UST", name: "St Augustine Airport" },
    KSGR: { iata: "SGR", name: "Sugar Land Regional Airport" },
    KSGT: { iata: "SGT", name: "Stuttgart Municipal Airport" },
    KSGU: { iata: "SGU", name: "St George Municipal Airport" },
    KSHD: { iata: "SHD", name: "Shenandoah Valley Regional Airport" },
    KSHN: { iata: "SHN", name: "Sanderson Field" },
    KSHR: { iata: "SHR", name: "Sheridan County Airport" },
    KSHV: { iata: "SHV", name: "Shreveport Regional Airport" },
    KSIK: { iata: "SIK", name: "Sikeston Memorial Municipal Airport" },
    KSIV: { iata: "SIV", name: "Sullivan County Airport" },
    KSIY: { iata: "SIY", name: "Siskiyou County Airport" },
    KSJC: {
      iata: "SJC",
      name: "Norman Y. Mineta San Jose International Airport",
    },
    KSJN: { iata: "SJN", name: "St Johns Industrial Air Park" },
    KSJT: { iata: "SJT", name: "San Angelo Regional Mathis Field" },
    KSKA: { iata: "SKA", name: "Fairchild Air Force Base" },
    KSKF: {
      iata: "SKF",
      name: "Lackland Air Force Base (Kelly Field Annex) Airport",
    },
    KSKX: { iata: "TSM", name: "Taos Regional Airport" },
    KSLB: { iata: "SLB", name: "Storm Lake Municipal Airport" },
    KSLC: { iata: "SLC", name: "Salt Lake City International Airport" },
    KSLE: { iata: "SLE", name: "McNary Field" },
    KSLG: { iata: "SLG", name: "Smith Field" },
    KSLK: { iata: "SLK", name: "Adirondack Regional Airport" },
    KSLN: { iata: "SLN", name: "Salina Municipal Airport" },
    KSLO: { iata: "SLO", name: "Salem Leckrone Airport" },
    KSLR: { iata: "SLR", name: "Sulphur Springs Municipal Airport" },
    KSMD: { iata: "SMD", name: "Smith Field" },
    KSME: { iata: "SME", name: "Lake Cumberland Regional Airport" },
    KSMF: { iata: "SMF", name: "Sacramento International Airport" },
    KSMN: { iata: "SMN", name: "Lemhi County Airport" },
    KSMO: { iata: "SMO", name: "Santa Monica Municipal Airport" },
    KSMS: { iata: "SUM", name: "Sumter Airport" },
    KSMX: { iata: "SMX", name: "Santa Maria Pub/Capt G Allan Hancock Field" },
    KSNA: { iata: "SNA", name: "John Wayne Airport-Orange County Airport" },
    KSNK: { iata: "SNK", name: "Winston Field" },
    KSNL: { iata: "SNL", name: "Shawnee Regional Airport" },
    KSNS: { iata: "SNS", name: "Salinas Municipal Airport" },
    KSNY: { iata: "SNY", name: "Sidney Municipal-Lloyd W Carr Field" },
    KSOP: { iata: "SOP", name: "Moore County Airport" },
    KSOW: { iata: "SOW", name: "Show Low Regional Airport" },
    KSPA: { iata: "SPA", name: "Spartanburg Downtown Memorial Airport" },
    KSPF: { iata: "SPF", name: "Black Hills Clyde Ice Field" },
    KSPG: { iata: "SPG", name: "Albert Whitted Airport" },
    KSPI: { iata: "SPI", name: "Abraham Lincoln Capital Airport" },
    KSPS: {
      iata: "SPS",
      name: "Sheppard Air Force Base-Wichita Falls Municipal Airport",
    },
    KSPW: { iata: "SPW", name: "Spencer Municipal Airport" },
    KSQI: { iata: "SQI", name: "Whiteside Co Arpt-Jos H Bittorf field" },
    KSQL: { iata: "SQL", name: "San Carlos Airport" },
    KSRC: { iata: "SRC", name: "Searcy Municipal Airport" },
    KSRQ: { iata: "SRQ", name: "Sarasota Bradenton International Airport" },
    KSRR: { iata: "RUI", name: "Sierra Blanca Regional Airport" },
    KSSC: { iata: "SSC", name: "Shaw Air Force Base" },
    KSSF: { iata: "SSF", name: "Stinson Municipal Airport" },
    KSSI: { iata: "SSI", name: "Malcolm Mc Kinnon Airport" },
    KSTC: { iata: "STC", name: "St Cloud Regional Airport" },
    KSTE: { iata: "STE", name: "Stevens Point Municipal Airport" },
    KSTJ: { iata: "STJ", name: "Rosecrans Memorial Airport" },
    KSTK: { iata: "STK", name: "Sterling Municipal Airport" },
    KSTL: { iata: "STL", name: "Lambert St Louis International Airport" },
    KSTP: { iata: "STP", name: "St Paul Downtown Holman Field" },
    KSTS: { iata: "STS", name: "Charles M. Schulz Sonoma County Airport" },
    KSUA: { iata: "SUA", name: "Witham Field" },
    KSUD: { iata: "SUD", name: "Stroud Municipal Airport" },
    KSUE: { iata: "SUE", name: "Door County Cherryland Airport" },
    KSUN: { iata: "SUN", name: "Friedman Memorial Airport" },
    KSUS: { iata: "SUS", name: "Spirit of St Louis Airport" },
    KSUU: { iata: "SUU", name: "Travis Air Force Base" },
    KSUW: { iata: "SUW", name: "Richard I Bong Airport" },
    KSUX: { iata: "SUX", name: "Sioux Gateway Col. Bud Day Field" },
    KSVC: { iata: "SVC", name: "Grant County Airport" },
    KSVE: { iata: "SVE", name: "Susanville Municipal Airport" },
    KSVH: { iata: "SVH", name: "Statesville Regional Airport" },
    KSVN: { iata: "SVN", name: "Hunter Army Air Field" },
    KSWF: { iata: "SWF", name: "Stewart International Airport" },
    KSWO: { iata: "SWO", name: "Stillwater Regional Airport" },
    KSWW: { iata: "SWW", name: "Avenger Field" },
    KSYI: { iata: "SYI", name: "Bomar Field Shelbyville Municipal Airport" },
    KSYN: { iata: "SYN", name: "Stanton Airfield" },
    KSYR: { iata: "SYR", name: "Syracuse Hancock International Airport" },
    KSYV: { iata: "SYV", name: "Sylvester Airport" },
    KSZL: { iata: "SZL", name: "Whiteman Air Force Base" },
    KSZN: { iata: "SZN", name: "Santa Cruz Island Airport" },
    KSZP: { iata: "SZP", name: "Santa Paula Airport" },
    KTAD: { iata: "TAD", name: "Perry Stokes Airport" },
    KTBN: { iata: "TBN", name: "Waynesville-St. Robert Regional Forney field" },
    KTBR: { iata: "TBR", name: "Statesboro Bulloch County Airport" },
    KTCC: { iata: "TCC", name: "Tucumcari Municipal Airport" },
    KTCL: { iata: "TCL", name: "Tuscaloosa Regional Airport" },
    KTCM: { iata: "TCM", name: "McChord Air Force Base" },
    KTCS: { iata: "TCS", name: "Truth Or Consequences Municipal Airport" },
    KTDO: {
      iata: "TDO",
      name: "Ed Carlson Memorial Field South Lewis County Airport",
    },
    KTDW: { iata: "TDW", name: "Tradewind Airport" },
    KTDZ: { iata: "TDZ", name: "Metcalf Field" },
    KTEB: { iata: "TEB", name: "Teterboro Airport" },
    KTEX: { iata: "TEX", name: "Telluride Regional Airport" },
    KTHA: { iata: "THA", name: "Tullahoma Regional Arpt/Wm Northern Field" },
    KTHM: { iata: "THM", name: "Thompson Falls Airport" },
    KTHP: { iata: "THP", name: "Hot Springs Co Thermopolis Municipal Airport" },
    KTHV: { iata: "THV", name: "York Airport" },
    KTIK: { iata: "TIK", name: "Tinker Air Force Base" },
    KTIW: { iata: "TIW", name: "Tacoma Narrows Airport" },
    KTIX: { iata: "TIX", name: "Space Coast Regional Airport" },
    KTKX: { iata: "KNT", name: "Kennett Memorial Airport" },
    KTLH: { iata: "TLH", name: "Tallahassee Regional Airport" },
    KTLR: { iata: "TLR", name: "Mefford Field" },
    KTMA: { iata: "TMA", name: "Henry Tift Myers Airport" },
    KTMB: { iata: "TMB", name: "Miami Executive Airport" },
    KTMK: { iata: "OTK", name: "Tillamook Airport" },
    KTMT: { iata: "ASQ", name: "Austin Airport" },
    KTNP: { iata: "TNP", name: "Twentynine Palms Airport" },
    KTNT: { iata: "TNT", name: "Dade Collier Training and Transition Airport" },
    KTNU: { iata: "TNU", name: "Newton Municipal Airport" },
    KTNX: { iata: "XSD", name: "Tonopah Test Range Airport" },
    KTOA: { iata: "TOA", name: "Zamperini Field" },
    KTOC: { iata: "TOC", name: "Toccoa Rg Letourneau Field" },
    KTOI: { iata: "TOI", name: "Troy Municipal Airport" },
    KTOL: { iata: "TOL", name: "Toledo Express Airport" },
    KTOP: { iata: "TOP", name: "Philip Billard Municipal Airport" },
    KTOR: { iata: "TOR", name: "Torrington Municipal Airport" },
    KTPA: { iata: "TPA", name: "Tampa International Airport" },
    KTPF: { iata: "TPF", name: "Peter O Knight Airport" },
    KTPH: { iata: "TPH", name: "Tonopah Airport" },
    KTPL: { iata: "TPL", name: "Draughon Miller Central Texas Regional Airport" },
    KTRI: { iata: "TRI", name: "Tri Cities Regional Tn Va Airport" },
    KTRK: { iata: "TKF", name: "Truckee Tahoe Airport" },
    KTRL: { iata: "TRL", name: "Terrell Municipal Airport" },
    KTRM: { iata: "TRM", name: "Jacqueline Cochran Regional Airport" },
    KTRX: { iata: "TRX", name: "Trenton Municipal Airport" },
    KTSP: { iata: "TSP", name: "Tehachapi Municipal Airport" },
    KTTD: { iata: "TTD", name: "Portland Troutdale Airport" },
    KTTN: { iata: "TTN", name: "Trenton Mercer Airport" },
    KTUL: { iata: "TUL", name: "Tulsa International Airport" },
    KTUP: { iata: "TUP", name: "Tupelo Regional Airport" },
    KTUS: { iata: "TUS", name: "Tucson International Airport" },
    KTVC: { iata: "TVC", name: "Cherry Capital Airport" },
    KTVF: { iata: "TVF", name: "Thief River Falls Regional Airport" },
    KTVI: { iata: "TVI", name: "Thomasville Regional Airport" },
    KTVL: { iata: "TVL", name: "Lake Tahoe Airport" },
    KTWF: { iata: "TWF", name: "Joslin Field Magic Valley Regional Airport" },
    KTXK: { iata: "TXK", name: "Texarkana Regional Webb Field" },
    KTYL: { iata: "TYZ", name: "Taylor Airport" },
    KTYR: { iata: "TYR", name: "Tyler Pounds Regional Airport" },
    KTYS: { iata: "TYS", name: "McGhee Tyson Airport" },
    KU07: { iata: "BFG", name: "Bullfrog Basin Airport" },
    KU34: { iata: "RVR", name: "Green River Municipal Airport" },
    KU70: { iata: "ICS", name: "Cascade Airport" },
    KUBS: { iata: "UBS", name: "Columbus Lowndes County Airport" },
    KUCY: { iata: "UCY", name: "Everett-Stewart Regional Airport" },
    KUDD: { iata: "UDD", name: "Bermuda Dunes Airport" },
    KUES: { iata: "UES", name: "Waukesha County Airport" },
    KUGN: { iata: "UGN", name: "Waukegan Regional Airport" },
    KUIL: { iata: "UIL", name: "Quillayute Airport" },
    KUIN: { iata: "UIN", name: "Quincy Regional Baldwin Field" },
    KUKF: { iata: "IKB", name: "Wilkes County Airport" },
    KUKI: { iata: "UKI", name: "Ukiah Municipal Airport" },
    KUKT: { iata: "UKT", name: "Quakertown Airport" },
    KULM: { iata: "ULM", name: "New Ulm Municipal Airport" },
    KUNI: { iata: "ATO", name: "Ohio University Snyder Field" },
    KUNU: { iata: "UNU", name: "Dodge County Airport" },
    KUNV: { iata: "SCE", name: "University Park Airport" },
    KUOS: { iata: "UOS", name: "Franklin County Airport" },
    KUOX: { iata: "UOX", name: "University Oxford Airport" },
    KUTA: { iata: "UTM", name: "Tunica Municipal Airport" },
    KUTS: { iata: "HTV", name: "Huntsville Municipal Airport" },
    KUUU: { iata: "NPT", name: "Newport State Airport" },
    KUVA: { iata: "UVA", name: "Garner Field" },
    KUZA: { iata: "RKH", name: "Rock Hill York Co Bryant field" },
    KVAD: { iata: "VAD", name: "Moody Air Force Base" },
    KVAY: { iata: "LLY", name: "South Jersey Regional Airport" },
    KVBG: { iata: "VBG", name: "Vandenberg Air Force Base" },
    KVCT: { iata: "VCT", name: "Victoria Regional Airport" },
    KVCV: { iata: "VCV", name: "Southern California Logistics Airport" },
    KVDI: { iata: "VDI", name: "Vidalia Regional Airport" },
    KVEL: { iata: "VEL", name: "Vernal Regional Airport" },
    KVGT: { iata: "VGT", name: "North Las Vegas Airport" },
    KVHN: { iata: "VHN", name: "Culberson County Airport" },
    KVIH: { iata: "VIH", name: "Rolla National Airport" },
    KVIS: { iata: "VIS", name: "Visalia Municipal Airport" },
    KVJI: { iata: "VJI", name: "Virginia Highlands Airport" },
    KVKS: { iata: "VKS", name: "Vicksburg Municipal Airport" },
    KVKX: { iata: "VKX", name: "Potomac Airfield" },
    KVLA: { iata: "VLA", name: "Vandalia Municipal Airport" },
    KVLD: { iata: "VLD", name: "Valdosta Regional Airport" },
    KVNC: { iata: "VNC", name: "Venice Municipal Airport" },
    KVNY: { iata: "VNY", name: "Van Nuys Airport" },
    KVOK: { iata: "VOK", name: "Volk Field" },
    KVPS: { iata: "VPS", name: "Eglin Air Force Base" },
    KVPZ: { iata: "VPZ", name: "Porter County Municipal Airport" },
    KVQQ: { iata: "VQQ", name: "Cecil Field" },
    KVRB: { iata: "VRB", name: "Vero Beach Municipal Airport" },
    KVSF: { iata: "VSF", name: "Hartness State (Springfield) Airport" },
    KVTN: { iata: "VTN", name: "Miller Field" },
    KVYS: { iata: "VYS", name: "Illinois Valley Regional-Walter A Duncan field" },
    KWAL: { iata: "WAL", name: "Wallops Flight Facility Airport" },
    KWAY: { iata: "WAY", name: "Greene County Airport" },
    KWBW: { iata: "WBW", name: "Wilkes Barre Wyoming Valley Airport" },
    KWDG: { iata: "WDG", name: "Enid Woodring Regional Airport" },
    KWDR: { iata: "WDR", name: "Barrow County Airport" },
    KWHP: { iata: "WHP", name: "Whiteman Airport" },
    KWJF: { iata: "WJF", name: "General WM J Fox Airfield" },
    KWLD: { iata: "WLD", name: "Strother Field" },
    KWLW: { iata: "WLW", name: "Willows Glenn County Airport" },
    KWMC: { iata: "WMC", name: "Winnemucca Municipal Airport" },
    KWRB: { iata: "WRB", name: "Robins Air Force Base" },
    KWRI: { iata: "WRI", name: "Mc Guire Air Force Base" },
    KWRL: { iata: "WRL", name: "Worland Municipal Airport" },
    KWSD: { iata: "WSD", name: "Condron Army Air Field" },
    KWST: { iata: "WST", name: "Westerly State Airport" },
    KWVI: { iata: "WVI", name: "Watsonville Municipal Airport" },
    KWVL: { iata: "WVL", name: "Waterville Robert Lafleur Airport" },
    KWWD: { iata: "WWD", name: "Cape May County Airport" },
    KWWR: { iata: "WWR", name: "West Woodward Airport" },
    KWYS: { iata: "WYS", name: "Yellowstone Airport" },
    KXNA: { iata: "XNA", name: "Northwest Arkansas Regional Airport" },
    KYIP: { iata: "YIP", name: "Willow Run Airport" },
    KYKM: { iata: "YKM", name: "Yakima Air Terminal McAllister Field" },
    KYKN: { iata: "YKN", name: "Chan Gurney Municipal Airport" },
    KYNG: { iata: "YNG", name: "Youngstown Warren Regional Airport" },
    KZPH: { iata: "ZPH", name: "Zephyrhills Municipal Airport" },
    KZZV: { iata: "ZZV", name: "Zanesville Municipal Airport" },
    LATI: { iata: "TIA", name: "Tirana International Airport Mother Teresa" },
    LBBG: { iata: "BOJ", name: "Burgas Airport" },
    LBGO: { iata: "GOZ", name: "Gorna Oryahovitsa Airport" },
    LBHS: { iata: "HKV", name: "Uzundzhovo Air Base" },
    LBIA: { iata: "JAM", name: "Bezmer Air Base" },
    LBPD: { iata: "PDV", name: "Plovdiv International Airport" },
    LBPL: { iata: "PVN", name: "Dolna Mitropoliya Air Base" },
    LBSF: { iata: "SOF", name: "Sofia Airport" },
    LBSS: { iata: "SLS", name: "Silistra Polkovnik Lambrinovo Airfield" },
    LBSZ: { iata: "SZR", name: "Stara Zagora Airport" },
    LBTG: { iata: "TGV", name: "Bukhovtsi Airfield" },
    LBVD: { iata: "VID", name: "Vidin Smurdan Airfield" },
    LBWN: { iata: "VAR", name: "Varna Airport" },
    LCEN: { iata: "ECN", name: "Ercan International Airport" },
    LCLK: { iata: "LCA", name: "Larnaca International Airport" },
    LCPH: { iata: "PFO", name: "Paphos International Airport" },
    LCRA: { iata: "AKT", name: "RAF Akrotiri" },
    LDDU: { iata: "DBV", name: "Dubrovnik Airport" },
    LDLO: { iata: "LSZ", name: "Losinj Island Airport" },
    LDOS: { iata: "OSI", name: "Osijek Airport" },
    LDPL: { iata: "PUY", name: "Pula Airport" },
    LDRI: { iata: "RJK", name: "Rijeka Airport" },
    LDSB: { iata: "BWK", name: "Brac Airport" },
    LDSP: { iata: "SPU", name: "Split Airport" },
    LDZA: { iata: "ZAG", name: "Zagreb Airport" },
    LDZD: { iata: "ZAD", name: "Zemunik Airport" },
    LEAB: { iata: "ABC", name: "Albacete-Los Llanos Airport" },
    LEAL: { iata: "ALC", name: "Alicante International Airport" },
    LEAM: { iata: "LEI", name: "Almeria International Airport" },
    LEAS: { iata: "OVD", name: "Asturias Airport" },
    LEBA: { iata: "ODB", name: "Cordoba Airport" },
    LEBB: { iata: "BIO", name: "Bilbao Airport" },
    LEBG: { iata: "RGS", name: "Burgos Airport" },
    LEBL: { iata: "BCN", name: "Barcelona International Airport" },
    LEBZ: { iata: "BJZ", name: "Badajoz Airport" },
    LECH: { iata: "CDT", name: "Calamocha Airport" },
    LECN: { iata: "CDT", name: "Castellon De La Plana Airport" },
    LECO: { iata: "LCG", name: "A Coruna Airport" },
    LECU: { iata: "ECV", name: "Cuatro Vientos Airport" },
    LEDA: { iata: "ILD", name: "Lleida-Alguaire Airport" },
    LEGE: { iata: "GRO", name: "Girona Airport" },
    LEGR: { iata: "GRX", name: "Federico Garcia Lorca Airport" },
    LEHC: { iata: "HSK", name: "Huesca/Pirineos Airport" },
    LEIB: { iata: "IBZ", name: "Ibiza Airport" },
    LEJR: { iata: "XRY", name: "Jerez Airport" },
    LELC: { iata: "MJV", name: "San Javier Airport" },
    LELL: { iata: "QSA", name: "Sabadell Airport" },
    LELN: { iata: "LEN", name: "Leon Airport" },
    LELO: { iata: "RJL", name: "Logrono-Agoncillo Airport" },
    LEMD: { iata: "MAD", name: "Madrid Barajas International Airport" },
    LEMF: { iata: "HEV", name: "Mafe - Gibraleon Airport" },
    LEMG: { iata: "AGP", name: "Malaga Airport" },
    LEMH: { iata: "MAH", name: "Menorca Airport" },
    LEMI: { iata: "RMU", name: "Región de Murcia International Airport" },
    LEMO: { iata: "OZP", name: "Moron Air Base" },
    LEPA: { iata: "PMI", name: "Palma De Mallorca Airport" },
    LEPP: { iata: "PNA", name: "Pamplona Airport" },
    LERS: { iata: "REU", name: "Reus Air Base" },
    LERT: { iata: "ROZ", name: "Rota Naval Station Airport" },
    LESA: { iata: "SLM", name: "Salamanca Airport" },
    LESO: { iata: "EAS", name: "San Sebastian Airport" },
    LEST: { iata: "SCQ", name: "Santiago de Compostela Airport" },
    LESU: { iata: "LEU", name: "Aerodrom dels Pirineus-Alt Urgell Airport" },
    LETO: { iata: "TOJ", name: "Torrejon Airport" },
    LEVC: { iata: "VLC", name: "Valencia Airport" },
    LEVD: { iata: "VLL", name: "Valladolid Airport" },
    LEVT: { iata: "VIT", name: "Vitoria/Foronda Airport" },
    LEVX: { iata: "VGO", name: "Vigo Airport" },
    LEXJ: { iata: "SDR", name: "Santander Airport" },
    LEZG: { iata: "ZAZ", name: "Zaragoza Air Base" },
    LEZL: { iata: "SVQ", name: "Sevilla Airport" },
    LFAB: { iata: "DPE", name: "St Aubin Airport" },
    LFAC: { iata: "CQF", name: "Calais-Dunkerque Airport" },
    LFAD: { iata: "XCP", name: "Compiegne Margny Airport" },
    LFAF: { iata: "XLN", name: "Laon - Chambry Airport" },
    LFAG: { iata: "XSJ", name: "Peronne-Saint-Quentin Airport" },
    LFAK: { iata: "XDK", name: "Dunkerque Les Moeres Airport" },
    LFAQ: { iata: "BYF", name: "Albert-Bray Airport" },
    LFAT: { iata: "LTQ", name: "Le Touquet-Cote d'Opale Airport" },
    LFAV: { iata: "XVS", name: "Valenciennes-Denain Airport" },
    LFAY: { iata: "QAM", name: "Amiens-Glisy Airport" },
    LFBA: { iata: "AGF", name: "Agen-La Garenne Airport" },
    LFBD: { iata: "BOD", name: "Bordeaux-Merignac (BA 106) Airport" },
    LFBE: { iata: "EGC", name: "Bergerac-Roumaniere Airport" },
    LFBG: { iata: "CNG", name: "Cognac-Chateaubernard (BA 709) Air Base" },
    LFBH: { iata: "LRH", name: "La Rochelle-Ile de Re Airport" },
    LFBI: { iata: "PIS", name: "Poitiers-Biard Airport" },
    LFBK: { iata: "MCU", name: "Montlucon-Gueret Airport" },
    LFBL: { iata: "LIG", name: "Limoges Airport" },
    LFBM: { iata: "XMJ", name: "Mont-de-Marsan (BA 118) Air Base" },
    LFBN: { iata: "NIT", name: "Niort-Souche Airport" },
    LFBO: { iata: "TLS", name: "Toulouse-Blagnac Airport" },
    LFBP: { iata: "PUF", name: "Pau Pyrenees Airport" },
    LFBT: { iata: "LDE", name: "Tarbes-Lourdes-Pyrenees Airport" },
    LFBU: { iata: "ANG", name: "Angouleme-Brie-Champniers Airport" },
    LFBV: { iata: "BVE", name: "Brive-La Roche Airport" },
    LFBX: { iata: "PGX", name: "Perigueux-Bassillac Airport" },
    LFBY: { iata: "XDA", name: "Dax Seyresse Airport" },
    LFBZ: { iata: "BIQ", name: "Biarritz-Anglet-Bayonne Airport" },
    LFCA: { iata: "XCX", name: "Chatellerault Airport" },
    LFCC: { iata: "ZAO", name: "Cahors-Lalbenque Airport" },
    LFCE: { iata: "XGT", name: "Gueret St Laurent Airport" },
    LFCH: { iata: "XAC", name: "Arcachon-La Teste-de-Buch Airport" },
    LFCI: { iata: "LBI", name: "Albi-Le Sequestre Airport" },
    LFCK: { iata: "DCM", name: "Castres-Mazamet Airport" },
    LFCR: { iata: "RDZ", name: "Rodez-Marcillac Airport" },
    LFCY: { iata: "RYN", name: "Royan-Medis Airport" },
    LFDB: { iata: "XMW", name: "Montauban Airport" },
    LFDI: { iata: "XLR", name: "Libourne-Artigues-de-Lussac Airport" },
    LFDN: { iata: "RCO", name: "Rochefort-Saint-Agnant (BA 721) Airport" },
    LFDS: { iata: "XSL", name: "Sarlat Domme Airport" },
    LFDT: { iata: "XTB", name: "Tarbes Laloubere Airport" },
    LFEY: { iata: "IDY", name: "Ile d'Yeu Airport" },
    LFGA: { iata: "CMR", name: "Colmar-Houssen Airport" },
    LFGF: { iata: "XBV", name: "Beaune-Challanges Airport" },
    LFGJ: { iata: "DLE", name: "Dole-Tavaux Airport" },
    LFGW: { iata: "XVN", name: "Verdun-Le Rozelier Airport" },
    LFHM: { iata: "MVV", name: "Megeve Airport" },
    LFHO: { iata: "OBS", name: "Aubenas-Ardeche Meridional Airport" },
    LFHP: { iata: "LPY", name: "Le Puy-Loudes Airport" },
    LFHS: { iata: "XBK", name: "Bourg-Ceyzeriat Airport" },
    LFHU: { iata: "AHZ", name: "L'alpe D'huez Airport" },
    LFHY: { iata: "XMU", name: "Moulins-Montbeugny Airport" },
    LFJL: { iata: "ETZ", name: "Metz-Nancy-Lorraine Airport" },
    LFJR: { iata: "ANE", name: "Angers-Loire Airport" },
    LFKA: { iata: "XAV", name: "Albertville Airport" },
    LFKB: { iata: "BIA", name: "Bastia-Poretta Airport" },
    LFKC: { iata: "CLY", name: "Calvi-Sainte-Catherine Airport" },
    LFKF: { iata: "FSC", name: "Figari Sud-Corse Airport" },
    LFKJ: { iata: "AJA", name: "Ajaccio-Napoleon Bonaparte Airport" },
    LFKO: { iata: "PRP", name: "Propriano Airport" },
    LFKS: { iata: "SOZ", name: "Solenzara (BA 126) Air Base" },
    LFKX: { iata: "MFX", name: "Meribel Airport" },
    LFLA: { iata: "AUF", name: "Auxerre-Branches Airport" },
    LFLB: { iata: "CMF", name: "Chambery-Savoie Airport" },
    LFLC: { iata: "CFE", name: "Clermont-Ferrand Auvergne Airport" },
    LFLD: { iata: "BOU", name: "Bourges Airport" },
    LFLI: { iata: "QNJ", name: "Annemasse Airport" },
    LFLJ: { iata: "CVF", name: "Courchevel Airport" },
    LFLL: { iata: "LYS", name: "Lyon Saint-Exupery Airport" },
    LFLM: { iata: "QNX", name: "Macon-Charnay Airport" },
    LFLN: { iata: "SYT", name: "Saint-Yan Airport" },
    LFLO: { iata: "RNE", name: "Roanne-Renaison Airport" },
    LFLP: { iata: "NCY", name: "Annecy-Haute-Savoie-Mont Blanc Airport" },
    LFLQ: { iata: "XMK", name: "Montelimar - Ancone Airport" },
    LFLS: { iata: "GNB", name: "Grenoble-Isere Airport" },
    LFLU: { iata: "VAF", name: "Valence-Chabeuil Airport" },
    LFLV: { iata: "VHY", name: "Vichy-Charmeil Airport" },
    LFLW: { iata: "AUR", name: "Aurillac Airport" },
    LFLX: { iata: "CHR", name: "Chateauroux-Deols Marcel Dassault Airport" },
    LFLY: { iata: "LYN", name: "Lyon-Bron Airport" },
    LFMA: { iata: "QXB", name: "Aix-en-Provence (BA 114) Airport" },
    LFMD: { iata: "CEQ", name: "Cannes-Mandelieu Airport" },
    LFMH: { iata: "EBU", name: "Saint-Etienne-Boutheon Airport" },
    LFMI: {
      iata: "QIE",
      name: "Istres Le Tube/Istres Air Base (BA 125) Airport",
    },
    LFMK: { iata: "CCF", name: "Carcassonne Airport" },
    LFML: { iata: "MRS", name: "Marseille Provence Airport" },
    LFMN: { iata: "NCE", name: "Nice-Cote d'Azur Airport" },
    LFMO: { iata: "XOG", name: "Orange-Caritat (BA 115) Air Base" },
    LFMP: { iata: "PGF", name: "Perpignan-Rivesaltes (Llabanere) Airport" },
    LFMQ: { iata: "CTT", name: "Le Castellet Airport" },
    LFMR: { iata: "BAE", name: "Barcelonnette - Saint-Pons Airport" },
    LFMS: { iata: "XAS", name: "Ales-Deaux Airport" },
    LFMT: { iata: "MPL", name: "Montpellier-Mediterranee Airport" },
    LFMU: { iata: "BZR", name: "Beziers-Vias Airport" },
    LFMV: { iata: "AVN", name: "Avignon-Caumont Airport" },
    LFNA: { iata: "GAT", name: "Gap - Tallard Airport" },
    LFNB: { iata: "MEN", name: "Mende-Brenoux Airport" },
    LFNC: { iata: "SCP", name: "Mont-Dauphin - St-Crepin Airport" },
    LFOB: { iata: "BVA", name: "Paris Beauvais Tille Airport" },
    LFOD: { iata: "XSU", name: "Saumur-Saint-Florent Airport" },
    LFOE: { iata: "EVX", name: "Evreux-Fauville (BA 105) Air Base" },
    LFOF: { iata: "XAN", name: "Alencon Valframbert Airport" },
    LFOH: { iata: "LEH", name: "Le Havre Octeville Airport" },
    LFOI: { iata: "XAB", name: "Abbeville-Buigny-Saint-Maclou Airport" },
    LFOJ: { iata: "ORE", name: "Orleans-Bricy (BA 123) Air Base" },
    LFOK: { iata: "XCR", name: "Chalons-Vatry Air Base" },
    LFOO: { iata: "LSO", name: "Les Sables-d'Olonne Talmont Airport" },
    LFOP: { iata: "URO", name: "Rouen Airport" },
    LFOQ: { iata: "XBQ", name: "Blois-Le Breuil Airport" },
    LFOR: { iata: "QTJ", name: "Chartres Champhol Airport" },
    LFOT: { iata: "TUF", name: "Tours-Val-de-Loire Airport" },
    LFOU: { iata: "CET", name: "Cholet Le Pontreau Airport" },
    LFOV: { iata: "LVA", name: "Laval-Entrammes Airport" },
    LFOZ: { iata: "ORE", name: "Orleans-Saint-Denis-de-l'Hotel Airport" },
    LFPB: { iata: "LBG", name: "Paris-Le Bourget Airport" },
    LFPC: { iata: "CSF", name: "Creil Air Base" },
    LFPG: { iata: "CDG", name: "Charles de Gaulle International Airport" },
    LFPN: { iata: "TNF", name: "Toussus-le-Noble Airport" },
    LFPO: { iata: "ORY", name: "Paris-Orly Airport" },
    LFPT: { iata: "POX", name: "Pontoise - Cormeilles-en-Vexin Airport" },
    LFPV: { iata: "VIY", name: "Villacoublay-Velizy (BA 107) Air Base" },
    LFQB: { iata: "QYR", name: "Troyes-Barberey Airport" },
    LFQG: { iata: "NVS", name: "Nevers-Fourchambault Airport" },
    LFQI: { iata: "XCB", name: "Cambrai-Epinoy (BA 103) Air Base" },
    LFQJ: { iata: "XME", name: "Maubeuge-Elesmes Airport" },
    LFQQ: { iata: "LIL", name: "Lille-Lesquin Airport" },
    LFQT: { iata: "HZB", name: "Merville-Calonne Airport" },
    LFQV: { iata: "XCZ", name: "Charleville-Mezieres Airport" },
    LFQW: { iata: "XVO", name: "Vesoul-Frotey Airport" },
    LFRB: { iata: "BES", name: "Brest Bretagne Airport" },
    LFRC: { iata: "CER", name: "Cherbourg-Maupertus Airport" },
    LFRD: { iata: "DNR", name: "Dinard-Pleurtuit-Saint-Malo Airport" },
    LFRE: { iata: "LBY", name: "La Baule-Escoublac Airport" },
    LFRF: { iata: "GFR", name: "Granville Airport" },
    LFRG: { iata: "DOL", name: "Deauville-Saint-Gatien Airport" },
    LFRH: { iata: "LRT", name: "Lorient South Brittany (Bretagne Sud) Airport" },
    LFRI: { iata: "EDM", name: "La Roche-sur-Yon Airport" },
    LFRJ: { iata: "LDV", name: "Landivisiau Air Base" },
    LFRK: { iata: "CFR", name: "Caen-Carpiquet Airport" },
    LFRM: { iata: "LME", name: "Le Mans-Arnage Airport" },
    LFRN: { iata: "RNS", name: "Rennes-Saint-Jacques Airport" },
    LFRO: { iata: "LAI", name: "Lannion-Cote de Granit Airport" },
    LFRQ: { iata: "UIP", name: "Quimper-Cornouaille Airport" },
    LFRS: { iata: "NTE", name: "Nantes Atlantique Airport" },
    LFRT: { iata: "SBK", name: "Saint-Brieuc-Armor Airport" },
    LFRU: { iata: "MXN", name: "Morlaix-Ploujean Airport" },
    LFRV: { iata: "VNE", name: "Vannes-Meucon Airport" },
    LFRZ: { iata: "SNR", name: "Saint-Nazaire-Montoir Airport" },
    LFSA: { iata: "QBQ", name: "Besancon Thise Airport" },
    LFSB: { iata: "BSL", name: "EuroAirport Basel-Mulhouse-Freiburg Airport" },
    LFSD: { iata: "DIJ", name: "Dijon-Bourgogne Airport" },
    LFSF: { iata: "MZM", name: "Metz-Frescaty (BA 128) Air Base" },
    LFSG: { iata: "EPL", name: "Epinal-Mirecourt Airport" },
    LFSL: { iata: "BVE", name: "Toul Rosieres Air Base" },
    LFSM: { iata: "XMF", name: "Montbeliard-Courcelles Airport" },
    LFSN: { iata: "ENC", name: "Nancy-Essey Airport" },
    LFSQ: { iata: "BOR", name: "Fontaine Airport" },
    LFSR: { iata: "RHE", name: "Reims-Champagne (BA 112) Airport" },
    LFST: { iata: "SXB", name: "Strasbourg Airport" },
    LFSZ: { iata: "VTL", name: "Vittel Champ De Course Airport" },
    LFTH: { iata: "TLN", name: "Toulon-Hyeres Airport" },
    LFTW: { iata: "FNI", name: "Nimes-Arles-Camargue Airport" },
    LFTZ: { iata: "LTT", name: "La Mole Airport" },
    LFVM: { iata: "MQC", name: "Miquelon Airport" },
    LFVP: { iata: "FSP", name: "St Pierre Airport" },
    LGAD: { iata: "PYR", name: "Andravida Airport" },
    LGAG: { iata: "AGQ", name: "Agrinion Airport" },
    LGAL: { iata: "AXD", name: "Dimokritos Airport" },
    LGAT: { iata: "HEW", name: "Athen Helenikon Airport" },
    LGAV: { iata: "ATH", name: "Eleftherios Venizelos International Airport" },
    LGBL: { iata: "VOL", name: "Nea Anchialos Airport" },
    LGHI: { iata: "JKH", name: "Chios Island National Airport" },
    LGHL: { iata: "PKH", name: "Porto Cheli Airport" },
    LGIK: { iata: "JIK", name: "Ikaria Airport" },
    LGIO: { iata: "IOA", name: "Ioannina Airport" },
    LGIR: {
      iata: "HER",
      name: "Heraklion International Nikos Kazantzakis Airport",
    },
    LGKA: { iata: "KSO", name: "Kastoria National Airport" },
    LGKC: { iata: "KIT", name: "Kithira Airport" },
    LGKF: { iata: "EFL", name: "Kefallinia Airport" },
    LGKJ: { iata: "KZS", name: "Kastelorizo Airport" },
    LGKL: { iata: "KLX", name: "Kalamata Airport" },
    LGKO: { iata: "KGS", name: "Kos Airport" },
    LGKP: { iata: "AOK", name: "Karpathos Airport" },
    LGKR: { iata: "CFU", name: "Ioannis Kapodistrias International Airport" },
    LGKS: { iata: "KSJ", name: "Kasos Airport" },
    LGKV: { iata: "KVA", name: "Alexander the Great International Airport" },
    LGKY: { iata: "JKL", name: "Kalymnos Airport" },
    LGKZ: { iata: "KZI", name: "Filippos Airport" },
    LGLE: { iata: "LRS", name: "Leros Airport" },
    LGLM: { iata: "LXS", name: "Limnos Airport" },
    LGLR: { iata: "LRA", name: "Larisa Airport" },
    LGMK: { iata: "JMK", name: "Mikonos Airport" },
    LGML: { iata: "MLO", name: "Milos Airport" },
    LGMT: { iata: "MJT", name: "Mytilene International Airport" },
    LGNX: { iata: "JNX", name: "Naxos Airport" },
    LGPA: { iata: "PAS", name: "Paros Airport" },
    LGPL: { iata: "JTY", name: "Astypalaia Airport" },
    LGPZ: { iata: "PVK", name: "Aktion National Airport" },
    LGRP: { iata: "RHO", name: "Diagoras Airport" },
    LGRX: { iata: "GPA", name: "Araxos Airport" },
    LGSA: { iata: "CHQ", name: "Chania International Airport" },
    LGSK: { iata: "JSI", name: "Skiathos Island National Airport" },
    LGSM: { iata: "SMI", name: "Samos Airport" },
    LGSO: { iata: "JSY", name: "Syros Airport" },
    LGSP: { iata: "SPJ", name: "Sparti Airport" },
    LGSR: { iata: "JTR", name: "Santorini Airport" },
    LGST: { iata: "JSH", name: "Sitia Airport" },
    LGSY: { iata: "SKU", name: "Skiros Airport" },
    LGTS: { iata: "SKG", name: "Thessaloniki Macedonia International Airport" },
    LGZA: { iata: "ZTH", name: "Dionysios Solomos Airport" },
    LHBP: { iata: "BUD", name: "Budapest Ferenc Liszt International Airport" },
    LHDC: { iata: "DEB", name: "Debrecen International Airport" },
    LHMC: { iata: "MCQ", name: "Miskolc Airport" },
    LHPP: { iata: "PEV", name: "Pecs-Pogany Airport" },
    LHPR: { iata: "QGY", name: "Gyor-Per International Airport" },
    LHSM: { iata: "SOB", name: "Sarmellek International Airport" },
    LHTA: { iata: "TZR", name: "Taszar Air Base" },
    LHUD: { iata: "QZD", name: "Szeged Glider Airport" },
    LIAP: { iata: "QAQ", name: "L'Aquila / Preturo Airport" },
    LIBC: { iata: "CRV", name: "Crotone Airport" },
    LIBD: { iata: "BRI", name: "Bari / Palese International Airport" },
    LIBF: { iata: "FOG", name: "Foggia / Gino Lisa Airport" },
    LIBG: { iata: "TAR", name: "Taranto / Grottaglie Airport" },
    LIBN: { iata: "LCC", name: "Lecce Airport" },
    LIBP: { iata: "PSR", name: "Pescara International Airport" },
    LIBR: { iata: "BDS", name: "Brindisi / Casale Airport" },
    LICA: { iata: "SUF", name: "Lamezia Terme Airport" },
    LICB: { iata: "CIY", name: "Comiso Airport Vincenzo Magliocco" },
    LICC: { iata: "CTA", name: "Catania / Fontanarossa Airport" },
    LICD: { iata: "LMP", name: "Lampedusa Airport" },
    LICG: { iata: "PNL", name: "Pantelleria Airport" },
    LICJ: { iata: "PMO", name: "Palermo / Punta Raisi Airport" },
    LICR: { iata: "REG", name: "Reggio Calabria Airport" },
    LICT: { iata: "TPS", name: "Trapani / Birgi Airport" },
    LICZ: { iata: "NSY", name: "Sigonella Airport" },
    LIDB: { iata: "BLX", name: "Belluno Airport" },
    LIDI: { iata: "CDF", name: "Cortina Airport" },
    LIDR: { iata: "RAN", name: "Ravenna Airport" },
    LIDT: { iata: "ZIA", name: "Trento / Mattarello Airport" },
    LIEA: { iata: "AHO", name: "Alghero / Fertilia Airport" },
    LIED: { iata: "DCI", name: "Decimomannu Airport" },
    LIEE: { iata: "CAG", name: "Cagliari / Elmas Airport" },
    LIEO: { iata: "OLB", name: "Olbia / Costa Smeralda Airport" },
    LIER: { iata: "FNU", name: "Oristano / Fenosu Airport" },
    LIET: { iata: "TTB", name: "Tortoli' / Arbatax Airport" },
    LILN: { iata: "QVA", name: "Varese / Venegono Airport" },
    LILQ: { iata: "QMM", name: "Massa Cinquale Airport" },
    LIMC: { iata: "MXP", name: "Malpensa International Airport" },
    LIME: { iata: "BGY", name: "Bergamo / Orio Al Serio Airport" },
    LIMF: { iata: "TRN", name: "Torino / Caselle International Airport" },
    LIMG: { iata: "ALL", name: "Villanova D'Albenga International Airport" },
    LIMJ: { iata: "GOA", name: "Genova / Sestri Cristoforo Colombo Airport" },
    LIML: { iata: "LIN", name: "Linate Airport" },
    LIMP: { iata: "PMF", name: "Parma Airport" },
    LIMS: { iata: "QPZ", name: "Piacenza Airport" },
    LIMW: { iata: "AOT", name: "Aosta Airport" },
    LIMZ: { iata: "CUF", name: "Cuneo / Levaldigi Airport" },
    LIPA: { iata: "AVB", name: "Aviano Air Base" },
    LIPB: { iata: "BZO", name: "Bolzano Airport" },
    LIPD: { iata: "UDN", name: "Udine / Campoformido Air Base" },
    LIPE: { iata: "BLQ", name: "Bologna / Borgo Panigale Airport" },
    LIPH: { iata: "TSF", name: "Treviso / Sant'Angelo Airport" },
    LIPK: { iata: "FRL", name: "Forli Airport" },
    LIPO: { iata: "VBS", name: "Brescia / Montichiari Airport" },
    LIPQ: { iata: "TRS", name: "Trieste / Ronchi Dei Legionari" },
    LIPR: {
      iata: "RMI",
      name: "Rimini / Miramare - Federico Fellini International Airport",
    },
    LIPT: { iata: "VIC", name: "Vicenza Airport" },
    LIPU: { iata: "QPA", name: "Padova Airport" },
    LIPX: { iata: "VRN", name: "Verona / Villafranca Airport" },
    LIPY: { iata: "AOI", name: "Ancona / Falconara Airport" },
    LIPZ: { iata: "VCE", name: "Venezia / Tessera -  Marco Polo Airport" },
    LIQB: { iata: "QZO", name: "Arezzo Airport" },
    LIQL: { iata: "LCV", name: "Lucca / Tassignano Airport" },
    LIQN: { iata: "QRT", name: "Rieti Airport" },
    LIQS: { iata: "SAY", name: "Siena / Ampugnano Airport" },
    LIRA: { iata: "CIA", name: "Ciampino Airport" },
    LIRF: {
      iata: "FCO",
      name: "Leonardo Da Vinci (Fiumicino) International Airport",
    },
    LIRH: { iata: "QFR", name: "Frosinone Military Airport" },
    LIRI: { iata: "QSR", name: "Salerno / Pontecagnano Airport" },
    LIRJ: { iata: "EBA", name: "Marina Di Campo Airport" },
    LIRL: { iata: "QLT", name: "Latina Airport" },
    LIRN: { iata: "NAP", name: "Napoli / Capodichino International Airport" },
    LIRP: {
      iata: "PSA",
      name: "Pisa / San Giusto - Galileo Galilei International Airport",
    },
    LIRQ: { iata: "FLR", name: "Firenze / Peretola Airport" },
    LIRS: { iata: "GRS", name: "Grosseto Airport" },
    LIRZ: { iata: "PEG", name: "Perugia / San Egidio Airport" },
    LJLJ: { iata: "LJU", name: "Ljubljana Joze Pucnik Airport" },
    LJMB: { iata: "MBX", name: "Maribor Airport" },
    LJPZ: { iata: "POW", name: "Portoroz Airport" },
    LKHO: { iata: "GTW", name: "Holesov Airport" },
    LKKU: { iata: "UHE", name: "Kunovice Airport" },
    LKKV: { iata: "KLV", name: "Karlovy Vary International Airport" },
    LKMR: { iata: "MKA", name: "Marianske Lazne Airport" },
    LKMT: { iata: "OSR", name: "Ostrava Leos Janacek Airport" },
    LKOL: { iata: "OLO", name: "Olomouc Glider Airport" },
    LKPD: { iata: "PED", name: "Pardubice Airport" },
    LKPO: { iata: "PRV", name: "Prerov Air Base" },
    LKPR: { iata: "PRG", name: "Vaclav Havel Airport" },
    LKTB: { iata: "BRQ", name: "Brno-Turany Airport" },
    LKVO: { iata: "VOD", name: "Vodochody Airport" },
    LKZA: { iata: "ZBE", name: "Zabreh Ostrava Airport" },
    LLBG: { iata: "TLV", name: "Ben Gurion International Airport" },
    LLBS: { iata: "BEV", name: "Be'er Sheva (Teyman) Airport" },
    LLET: { iata: "ETH", name: "Eilat Airport" },
    LLER: { iata: "ETM", name: "Ramon Airport" },
    LLEY: { iata: "EIY", name: "Ein Yahav Airfield" },
    LLHA: { iata: "HFA", name: "Haifa International Airport" },
    LLIB: { iata: "RPN", name: "Ben Ya'akov Airport" },
    LLKS: { iata: "KSW", name: "Kiryat Shmona Airport" },
    LLMR: { iata: "MIP", name: "Mitzpe Ramon Airfield" },
    LLMZ: { iata: "MTZ", name: "Bar Yehuda Airfield" },
    LLNV: { iata: "VTM", name: "Nevatim Air Base" },
    LLOV: { iata: "VDA", name: "Ovda International Airport" },
    LLRM: { iata: "MIP", name: "Ramon Air Base" },
    LLSD: { iata: "SDV", name: "Sde Dov Airport" },
    LLYT: { iata: "YOT", name: "Yotvata Airfield" },
    LMML: { iata: "MLA", name: "Luqa Airport" },
    LOIH: { iata: "HOH", name: "Hohenems-Dornbirn Airport" },
    LOWG: { iata: "GRZ", name: "Graz Airport" },
    LOWI: { iata: "INN", name: "Innsbruck Airport" },
    LOWK: { iata: "KLU", name: "Klagenfurt Airport" },
    LOWL: { iata: "LNZ", name: "Linz Airport" },
    LOWS: { iata: "SZG", name: "Salzburg Airport" },
    LOWW: { iata: "VIE", name: "Vienna International Airport" },
    LPAZ: { iata: "SMA", name: "Santa Maria Airport" },
    LPBG: { iata: "BGC", name: "Braganca Airport" },
    LPBJ: { iata: "BYJ", name: "Beja International Airport" },
    LPBR: { iata: "BGZ", name: "Braga Municipal Aerodrome" },
    LPCH: { iata: "CHV", name: "Chaves Airport" },
    LPCO: { iata: "CBP", name: "Coimbra Airport" },
    LPCR: { iata: "CVU", name: "Corvo Airport" },
    LPCS: { iata: "CAT", name: "Cascais Airport" },
    LPCV: { iata: "COV", name: "Covilha Airport" },
    LPFL: { iata: "FLW", name: "Flores Airport" },
    LPFR: { iata: "FAO", name: "Faro Airport" },
    LPGR: { iata: "GRW", name: "Graciosa Airport" },
    LPHR: { iata: "HOR", name: "Horta Airport" },
    LPLA: { iata: "TER", name: "Lajes Field" },
    LPMA: { iata: "FNC", name: "Madeira Airport" },
    LPPD: { iata: "PDL", name: "Joao Paulo II Airport" },
    LPPI: { iata: "PIX", name: "Pico Airport" },
    LPPM: { iata: "PRM", name: "Portimao Airport" },
    LPPR: { iata: "OPO", name: "Francisco de Sa Carneiro Airport" },
    LPPS: { iata: "PXO", name: "Porto Santo Airport" },
    LPPT: { iata: "LIS", name: "Lisbon Portela Airport" },
    LPSI: { iata: "SIE", name: "Sines Airport" },
    LPSJ: { iata: "SJZ", name: "Sao Jorge Airport" },
    LPVR: { iata: "VRL", name: "Vila Real Airport" },
    LPVZ: { iata: "VSE", name: "Viseu Airport" },
    LQBK: { iata: "BNX", name: "Banja Luka International Airport" },
    LQMO: { iata: "OMO", name: "Mostar International Airport" },
    LQSA: { iata: "SJJ", name: "Sarajevo International Airport" },
    LQTZ: { iata: "TZL", name: "Tuzla International Airport" },
    LRAR: { iata: "ARW", name: "Arad International Airport" },
    LRBC: { iata: "BCM", name: "Bacau Airport" },
    LRBM: { iata: "BAY", name: "Tautii Magheraus Airport" },
    LRBS: { iata: "BBU", name: "Baneasa International Airport" },
    LRCK: { iata: "CND", name: "Mihail Kogalniceanu International Airport" },
    LRCL: { iata: "CLJ", name: "Cluj-Napoca International Airport" },
    LRCS: { iata: "CSB", name: "Caransebes Airport" },
    LRCV: { iata: "CRA", name: "Craiova Airport" },
    LRIA: { iata: "IAS", name: "Iasi Airport" },
    LROD: { iata: "OMR", name: "Oradea International Airport" },
    LROP: { iata: "OTP", name: "Henri Coanda International Airport" },
    LRSB: { iata: "SBZ", name: "Sibiu International Airport" },
    LRSM: { iata: "SUJ", name: "Satu Mare Airport" },
    LRSV: { iata: "SCV", name: "Suceava Stefan cel Mare Airport" },
    LRTC: { iata: "TCE", name: "Tulcea Airport" },
    LRTM: { iata: "TGM", name: "Transilvania Targu Mures International Airport" },
    LRTR: { iata: "TSR", name: "Timisoara Traian Vuia Airport" },
    LSGG: { iata: "GVA", name: "Geneva Cointrin International Airport" },
    LSGL: { iata: "QLS", name: "Lausanne-la Blecherette Airport" },
    LSGN: { iata: "QNC", name: "Neuchatel Airport" },
    LSGS: { iata: "SIR", name: "Sion Airport" },
    LSME: { iata: "EML", name: "Emmen Airport" },
    LSMI: { iata: "ZIN", name: "Interlaken Air Base" },
    LSZA: { iata: "LUG", name: "Lugano Airport" },
    LSZB: { iata: "BRN", name: "Bern Belp Airport" },
    LSZC: { iata: "BXO", name: "Buochs Airport" },
    LSZG: { iata: "ZHI", name: "Grenchen Airport" },
    LSZH: { iata: "ZRH", name: "Zurich Airport" },
    LSZL: { iata: "ZJI", name: "Locarno Airport" },
    LSZM: { iata: "MLH", name: "EuroAirport Basel-Mulhouse-Freiburg Airport" },
    LSZR: { iata: "ACH", name: "St Gallen Altenrhein Airport" },
    LSZS: { iata: "SMV", name: "Samedan Airport" },
    LTAC: { iata: "ESB", name: "Esenboga International Airport" },
    LTAD: { iata: "ANK", name: "Etimesgut Air Base" },
    LTAF: { iata: "ADA", name: "Adana Airport" },
    LTAG: { iata: "UAB", name: "Incirlik Air Base" },
    LTAH: { iata: "AFY", name: "Afyon Airport" },
    LTAI: { iata: "AYT", name: "Antalya International Airport" },
    LTAJ: { iata: "GZT", name: "Gaziantep International Airport" },
    LTAL: { iata: "KFS", name: "Kastamonu Airport" },
    LTAN: { iata: "KYA", name: "Konya Airport" },
    LTAP: { iata: "MZH", name: "Amasya Merzifon Airport" },
    LTAQ: { iata: "SSX", name: "Samsun Samair Airport" },
    LTAR: { iata: "VAS", name: "Sivas Airport" },
    LTAS: { iata: "ONQ", name: "Zonguldak Airport" },
    LTAT: { iata: "MLX", name: "Malatya Erhac Airport" },
    LTAU: { iata: "ASR", name: "Kayseri Erkilet Airport" },
    LTAW: { iata: "TJK", name: "Tokat Airport" },
    LTAY: { iata: "DNZ", name: "Cardak Airport" },
    LTAZ: { iata: "NAV", name: "Nevsehir Kapadokya International Airport" },
    LTBA: { iata: "ISL", name: "Ataturk International Airport" },
    LTFM: { iata: "IST", name: "Istanbul Airport" },
    LTBD: { iata: "CII", name: "Cildir Airport" },
    LTBE: { iata: "BTZ", name: "Bursa Airport" },
    LTBF: { iata: "BZI", name: "Balikesir Merkez Airport" },
    LTBG: { iata: "BDM", name: "Bandirma Airport" },
    LTBH: { iata: "CKZ", name: "Canakkale Airport" },
    LTBI: { iata: "ESK", name: "Eskisehir Air Base" },
    LTBJ: { iata: "ADB", name: "Adnan Menderes International Airport" },
    LTBL: { iata: "IGL", name: "Cigli Airport" },
    LTBO: { iata: "USQ", name: "Usak Airport" },
    LTBQ: { iata: "KCO", name: "Cengiz Topel Airport" },
    LTBR: { iata: "YEI", name: "Bursa Yenisehir Airport" },
    LTBS: { iata: "DLM", name: "Dalaman International Airport" },
    LTBU: { iata: "TEQ", name: "Tekirdag Corlu Airport" },
    LTBV: { iata: "BXN", name: "Imsik Airport" },
    LTBY: { iata: "AOE", name: "Anadolu University Airport" },
    LTBZ: { iata: "KZR", name: "Zafer Airport" },
    LTCA: { iata: "EZS", name: "Elazig Airport" },
    LTCB: { iata: "OGU", name: "Ordu–Giresun Airport" },
    LTCC: { iata: "DIY", name: "Diyarbakir Airport" },
    LTCD: { iata: "ERC", name: "Erzincan Airport" },
    LTCE: { iata: "ERZ", name: "Erzurum International Airport" },
    LTCF: { iata: "KSY", name: "Kars Airport" },
    LTCG: { iata: "TZX", name: "Trabzon International Airport" },
    LTCH: { iata: "SFQ", name: "Sanliurfa Airport" },
    LTCI: { iata: "VAN", name: "Van Ferit Melen Airport" },
    LTCJ: { iata: "BAL", name: "Batman Airport" },
    LTCK: { iata: "MSR", name: "Mus Airport" },
    LTCL: { iata: "SXZ", name: "Siirt Airport" },
    LTCM: { iata: "NOP", name: "Sinop Airport" },
    LTCN: { iata: "KCM", name: "Kahramanmaras Airport" },
    LTCO: { iata: "AJI", name: "Agri Airport" },
    LTCP: { iata: "ADF", name: "Adiyaman Airport" },
    LTCR: { iata: "MQM", name: "Mardin Airport" },
    LTCS: { iata: "GNY", name: "Sanliurfa GAP Airport" },
    LTCT: { iata: "IGD", name: "Igdir Airport" },
    LTCW: { iata: "YKO", name: "Hakkari Yuksekova Airport" },
    LTDA: { iata: "HTY", name: "Hatay Airport" },
    LTFC: { iata: "ISE", name: "Suleyman Demirel International Airport" },
    LTFD: { iata: "EDO", name: "Balikesir Korfez Airport" },
    LTFE: { iata: "BJV", name: "Milas Bodrum International Airport" },
    LTFG: { iata: "GZP", name: "Gazipasa Airport" },
    LTFH: { iata: "SZF", name: "Samsun Carsamba Airport" },
    LTFJ: { iata: "SAW", name: "Sabiha Gokcen International Airport" },
    LTFK: { iata: "GKD", name: "Imroz Airport" },
    LUBL: { iata: "BZY", name: "Balti International Airport" },
    LUKK: { iata: "KIV", name: "Chisinau International Airport" },
    LWOH: { iata: "OHD", name: "Ohrid St. Paul the Apostle Airport" },
    LWSK: { iata: "SKP", name: "Skopje Alexander the Great Airport" },
    LXGB: { iata: "GIB", name: "Gibraltar Airport" },
    LYBE: { iata: "BEG", name: "Belgrade Nikola Tesla Airport" },
    LYBR: { iata: "IVG", name: "Berane Airport" },
    LYBT: { iata: "BJY", name: "Batajnica Air Base" },
    LYNI: { iata: "INI", name: "Nis Airport" },
    LYNS: { iata: "QND", name: "Cenej Airport" },
    LYPG: { iata: "TGD", name: "Podgorica Airport" },
    LYTV: { iata: "TIV", name: "Tivat Airport" },
    LYUZ: { iata: "UZC", name: "Ponikve Airport" },
    LYVA: { iata: "QWV", name: "Divci Airport" },
    LYZR: { iata: "ZRE", name: "Zrenjanin Airport" },
    LZIB: { iata: "BTS", name: "M. R. Stefanik Airport" },
    LZKZ: { iata: "KSC", name: "Kosice Airport" },
    LZLU: { iata: "LUE", name: "Lucenec Airport" },
    LZPP: { iata: "PZY", name: "Piestany Airport" },
    LZPW: { iata: "POV", name: "Presov Air Base" },
    LZSL: { iata: "SLD", name: "Sliac Airport" },
    LZTT: { iata: "TAT", name: "Poprad-Tatry Airport" },
    LZZI: { iata: "ILZ", name: "Zilina Airport" },
    MBGT: { iata: "GDT", name: "JAGS McCartney International Airport" },
    MBMC: { iata: "MDS", name: "Middle Caicos Airport" },
    MBNC: { iata: "NCA", name: "North Caicos Airport" },
    MBPI: { iata: "PIC", name: "Pine Cay Airport" },
    MBPV: { iata: "PLS", name: "Providenciales Airport" },
    MBSC: { iata: "XSC", name: "South Caicos Airport" },
    MBSY: { iata: "SLX", name: "Salt Cay Airport" },
    MDAB: { iata: "EPS", name: "Arroyo Barril Airport" },
    MDBH: { iata: "BRX", name: "Maria Montez International Airport" },
    MDCR: { iata: "CBJ", name: "Cabo Rojo Airport" },
    MDCY: { iata: "AZS", name: "Samana El Catey International Airport" },
    MDCZ: { iata: "COZ", name: "Constanza Dom Re Airport" },
    MDJB: { iata: "JBQ", name: "La Isabela International Airport" },
    MDLR: { iata: "LRM", name: "Casa De Campo International Airport" },
    MDPC: { iata: "PUJ", name: "Punta Cana International Airport" },
    MDPO: { iata: "EPS", name: "Samana El Portillo Airport" },
    MDPP: { iata: "POP", name: "Gregorio Luperon International Airport" },
    MDSB: { iata: "SNX", name: "Sabana de Mar Airport" },
    MDSD: { iata: "SDQ", name: "Las Americas International Airport" },
    MDST: { iata: "STI", name: "Cibao International Airport" },
    MGCB: { iata: "CBV", name: "Coban Airport" },
    MGCQ: { iata: "CIQ", name: "Chiquimula Airport" },
    MGCR: { iata: "CMM", name: "Carmelita Airport" },
    MGCT: { iata: "CTF", name: "Coatepeque Airport" },
    MGDL: { iata: "DON", name: "Dos Lagunas Airport" },
    MGGT: { iata: "GUA", name: "La Aurora Airport" },
    MGHT: { iata: "HUG", name: "Huehuetenango Airport" },
    MGMM: { iata: "MCR", name: "Melchor de Mencos Airport" },
    MGPB: { iata: "PBR", name: "Puerto Barrios Airport" },
    MGPC: { iata: "PCG", name: "Paso Caballos Airport" },
    MGPG: { iata: "PKJ", name: "Playa Grande Airport" },
    MGPP: { iata: "PON", name: "Poptun Airport" },
    MGQC: { iata: "AQB", name: "Santa Cruz del Quiche Airport" },
    MGQZ: { iata: "AAZ", name: "Quezaltenango Airport" },
    MGRB: { iata: "RUV", name: "Rubelsanto Airport" },
    MGRD: { iata: "LCF", name: "Las Vegas Airport" },
    MGRT: { iata: "RER", name: "Retalhuleu Airport" },
    MGSJ: { iata: "GSJ", name: "San Jose Airport" },
    MGTK: { iata: "FRS", name: "Mundo Maya International Airport" },
    MGUX: { iata: "UAX", name: "Uaxactun Airport" },
    MHAH: { iata: "AHS", name: "Ahuas Airport" },
    MHBL: { iata: "BHG", name: "Brus Laguna Airport" },
    MHCA: { iata: "CAA", name: "Catacamas Airport" },
    MHCS: { iata: "CYL", name: "Coyoles Airport" },
    MHCU: { iata: "CDD", name: "Cauquira Airport" },
    MHEA: { iata: "OAN", name: "El Arrayan Airport" },
    MHGS: { iata: "GAC", name: "Gracias Airport" },
    MHIR: { iata: "IRN", name: "Iriona Airport" },
    MHJU: { iata: "JUT", name: "Jutigalpa airport" },
    MHLC: { iata: "LCE", name: "Goloson International Airport" },
    MHLE: { iata: "LEZ", name: "La Esperanza Airport" },
    MHLM: { iata: "SAP", name: "Ramon Villeda Morales International Airport" },
    MHNJ: { iata: "GJA", name: "La Laguna Airport" },
    MHPC: { iata: "PCH", name: "Palacios Airport" },
    MHPL: { iata: "PEU", name: "Puerto Lempira Airport" },
    MHRO: { iata: "RTB", name: "Juan Manuel Galvez International Airport" },
    MHRS: { iata: "SDH", name: "Santa Rosa Copan Airport" },
    MHRU: { iata: "RUY", name: "Copan Ruinas Airport" },
    MHSC: { iata: "XPL", name: "Coronel Enrique Soto Cano Air Base" },
    MHTE: { iata: "TEA", name: "Tela Airport" },
    MHTG: { iata: "TGU", name: "Toncontin International Airport" },
    MHTJ: { iata: "TJI", name: "Trujillo Airport" },
    MHUL: { iata: "SCD", name: "Sulaco Airport" },
    MHUT: { iata: "UII", name: "Utila Airport" },
    MKBS: { iata: "OCJ", name: "Boscobel Aerodrome" },
    MKJP: { iata: "KIN", name: "Norman Manley International Airport" },
    MKJS: { iata: "MBJ", name: "Sangster International Airport" },
    MKKJ: { iata: "POT", name: "Ken Jones Airport" },
    MKNG: { iata: "NEG", name: "Negril Airport" },
    MKTP: { iata: "KTP", name: "Tinson Pen Airport" },
    MLIP: { iata: "MIJ", name: "Mili Island Airport" },
    MMAA: { iata: "ACA", name: "General Juan N Alvarez International Airport" },
    MMAN: { iata: "NTR", name: "Del Norte International Airport" },
    MMAS: { iata: "AGU", name: "Jesus Teran International Airport" },
    MMBT: { iata: "HUX", name: "Bahias de Huatulco International Airport" },
    MMCA: { iata: "CNA", name: "Cananea Airport" },
    MMCB: { iata: "CVJ", name: "General Mariano Matamoros Airport" },
    MMCC: { iata: "ACN", name: "Ciudad Acuna New International Airport" },
    MMCE: { iata: "CME", name: "Ciudad del Carmen International Airport" },
    MMCG: { iata: "NCG", name: "Nuevo Casas Grandes Airport" },
    MMCL: { iata: "CUL", name: "Federal de Bachigualato International Airport" },
    MMCM: { iata: "CTM", name: "Chetumal International Airport" },
    MMCN: { iata: "CEN", name: "Ciudad Obregon International Airport" },
    MMCO: { iata: "CJT", name: "Comitan Airport" },
    MMCP: {
      iata: "CPE",
      name: "Ingeniero Alberto Acuna Ongay International Airport",
    },
    MMCS: { iata: "CJS", name: "Abraham Gonzalez International Airport" },
    MMCT: { iata: "CZA", name: "Chichen Itza International Airport" },
    MMCU: {
      iata: "CUU",
      name: "General Roberto Fierro Villalobos International Airport",
    },
    MMCV: {
      iata: "CVM",
      name: "General Pedro Jose Mendez International Airport",
    },
    MMCY: { iata: "CYW", name: "Captain Rogelio Castillo National Airport" },
    MMCZ: { iata: "CZM", name: "Cozumel International Airport" },
    MMDA: { iata: "CUA", name: "Ciudad Constitucion Airport" },
    MMDM: { iata: "MMC", name: "Ciudad Mante National Airport" },
    MMDO: {
      iata: "DGO",
      name: "General Guadalupe Victoria International Airport",
    },
    MMEP: { iata: "TPQ", name: "Amado Nervo National Airport" },
    MMES: { iata: "ESE", name: "Ensenada Airport" },
    MMGL: {
      iata: "GDL",
      name: "Don Miguel Hidalgo Y Costilla International Airport",
    },
    MMGM: { iata: "GYM", name: "General Jose Maria Yanez International Airport" },
    MMGR: { iata: "GUB", name: "Guerrero Negro Airport" },
    MMHC: { iata: "TCN", name: "Tehuacan Airport" },
    MMHO: {
      iata: "HMO",
      name: "General Ignacio P. Garcia International Airport",
    },
    MMIA: { iata: "CLQ", name: "Lic. Miguel de la Madrid Airport" },
    MMIM: { iata: "ISJ", name: "Isla Mujeres Airport" },
    MMIO: { iata: "SLW", name: "Plan De Guadalupe International Airport" },
    MMIT: { iata: "IZT", name: "Ixtepec Airport" },
    MMJA: { iata: "JAL", name: "El Lencero Airport" },
    MMJC: { iata: "AZP", name: "Atizapan De Zaragoza Airport" },
    MMLC: { iata: "LZC", name: "Lazaro Cardenas Airport" },
    MMLM: { iata: "LMM", name: "Valle del Fuerte International Airport" },
    MMLO: { iata: "BJX", name: "Del Bajio International Airport" },
    MMLP: { iata: "LAP", name: "Manuel Marquez de Leon International Airport" },
    MMLT: { iata: "LTO", name: "Loreto International Airport" },
    MMMA: { iata: "MAM", name: "General Servando Canales International Airport" },
    MMMD: { iata: "MID", name: "Licenciado Manuel Crescencio Rejon Int Airport" },
    MMMG: { iata: "MUG", name: "Mulege Airport" },
    MMML: {
      iata: "MXL",
      name: "General Rodolfo Sanchez Taboada International Airport",
    },
    MMMM: {
      iata: "MLM",
      name: "General Francisco J. Mujica International Airport",
    },
    MMMT: { iata: "MTT", name: "Minatitlan/Coatzacoalcos National Airport" },
    MMMV: { iata: "LOV", name: "Monclova International Airport" },
    MMMX: { iata: "MEX", name: "Licenciado Benito Juarez International Airport" },
    MMMY: { iata: "MTY", name: "General Mariano Escobedo International Airport" },
    MMMZ: { iata: "MZT", name: "General Rafael Buelna International Airport" },
    MMNG: { iata: "NOG", name: "Nogales International Airport" },
    MMNL: { iata: "NLD", name: "Quetzalcoatl International Airport" },
    MMOX: { iata: "OAX", name: "Xoxocotlan International Airport" },
    MMPA: { iata: "PAZ", name: "El Tajin National Airport" },
    MMPB: { iata: "PBC", name: "Hermanos Serdan International Airport" },
    MMPE: { iata: "PPE", name: "Puerto Penasco International Airport" },
    MMPG: { iata: "PDS", name: "Piedras Negras International Airport" },
    MMPL: { iata: "PCO", name: "Punta Colorada Airport" },
    MMPN: {
      iata: "UPN",
      name: "Licenciado y General Ignacio Lopez Rayon Airport",
    },
    MMPQ: { iata: "PQM", name: "Palenque International Airport" },
    MMPR: {
      iata: "PVR",
      name: "Licenciado Gustavo Diaz Ordaz International Airport",
    },
    MMPS: { iata: "PXM", name: "Puerto Escondido International Airport" },
    MMQT: { iata: "QRO", name: "Queretaro Intercontinental Airport" },
    MMRX: { iata: "REX", name: "General Lucio Blanco International Airport" },
    MMSC: { iata: "SZT", name: "San Cristobal De Las Casas Airport" },
    MMSD: { iata: "SJD", name: "Los Cabos International Airport" },
    MMSF: { iata: "SFH", name: "San Felipe International Airport" },
    MMSM: { iata: "NLU", name: "Felipe Angeles International Airport" },
    MMSP: { iata: "SLP", name: "Ponciano Arriaga International Airport" },
    MMTC: { iata: "TRC", name: "Francisco Sarabia International Airport" },
    MMTG: { iata: "TGZ", name: "Angel Albino Corzo International Airport" },
    MMTJ: {
      iata: "TIJ",
      name: "General Abelardo L. Rodriguez International Airport",
    },
    MMTM: {
      iata: "TAM",
      name: "General Francisco Javier Mina International Airport",
    },
    MMTN: { iata: "TSL", name: "Tamuin Airport" },
    MMTO: {
      iata: "TLC",
      name: "Licenciado Adolfo Lopez Mateos International Airport",
    },
    MMTP: { iata: "TAP", name: "Tapachula International Airport" },
    MMTX: { iata: "WIX", name: "Tuxpan Airport" },
    MMUN: { iata: "CUN", name: "Cancun International Airport" },
    MMVA: { iata: "VSA", name: "Carlos Rovirosa Perez International Airport" },
    MMVR: { iata: "VER", name: "General Heriberto Jara International Airport" },
    MMZC: { iata: "ZCL", name: "General Leobardo C. Ruiz International Airport" },
    MMZH: { iata: "ZIH", name: "Ixtapa Zihuatanejo International Airport" },
    MMZM: { iata: "ZMM", name: "Zamora Airport" },
    MMZO: { iata: "ZLO", name: "Playa De Oro International Airport" },
    MNBL: { iata: "BEF", name: "Bluefields Airport" },
    MNBZ: { iata: "BZA", name: "San Pedro Airport" },
    MNCI: { iata: "RNI", name: "Corn Island" },
    MNMG: {
      iata: "MGA",
      name: "Augusto C. Sandino (Managua) International Airport",
    },
    MNNG: { iata: "NVG", name: "Nueva Guinea Airport" },
    MNPC: { iata: "PUZ", name: "Puerto Cabezas Airport" },
    MNRT: { iata: "RFS", name: "Rosita Airport" },
    MNSC: { iata: "NCR", name: "San Carlos" },
    MNSI: { iata: "SIU", name: "Siuna" },
    MNWP: { iata: "WSP", name: "Waspam Airport" },
    MPBO: { iata: "BOC", name: "Bocas Del Toro International Airport" },
    MPCE: { iata: "CTD", name: "Alonso Valderrama Airport" },
    MPCH: { iata: "CHX", name: "Cap Manuel Nino International Airport" },
    MPDA: { iata: "DAV", name: "Enrique Malek International Airport" },
    MPEJ: { iata: "ONX", name: "Enrique Adolfo Jimenez Airport" },
    MPHO: { iata: "BLB", name: "Howard Airport" },
    MPJE: { iata: "JQE", name: "Jaque Airport" },
    MPLP: { iata: "PLP", name: "Captain Ramon Xatruch Airport" },
    MPMG: { iata: "PAC", name: "Marcos A. Gelabert International Airport" },
    MPOA: { iata: "PUE", name: "Puerto Obaldia Airport" },
    MPPA: { iata: "BLB", name: "Howard/Panama Pacifico International Airport" },
    MPSM: { iata: "RIH", name: "Scarlett Martinez International Airport" },
    MPSA: { iata: "SYP", name: "Ruben Cantu Airport" },
    MPTO: { iata: "PTY", name: "Tocumen International Airport" },
    MPVR: { iata: "PVE", name: "El Porvenir Airport" },
    MPWN: { iata: "NBL", name: "San Blas Airport" },
    MRAN: { iata: "FON", name: "Arenal Airport" },
    MRAO: { iata: "TTQ", name: "Aerotortuguero Airport" },
    MRBA: { iata: "BAI", name: "Buenos Aires Airport" },
    MRBC: { iata: "BCL", name: "Barra del Colorado Airport" },
    MRCA: { iata: "CSC", name: "Codela Airport" },
    MRCC: { iata: "OTR", name: "Coto 47 Airport" },
    MRCH: { iata: "JAP", name: "Chacarita Airport" },
    MRCR: { iata: "RIK", name: "Carrillo Airport" },
    MRDK: { iata: "DRK", name: "Drake Bay Airport" },
    MRFL: { iata: "FMG", name: "Flamingo Airport" },
    MRGF: { iata: "GLF", name: "Golfito Airport" },
    MRGP: { iata: "GPL", name: "Guapiles Airport" },
    MRIA: { iata: "PBP", name: "Islita Airport" },
    MRLB: { iata: "LIR", name: "Daniel Oduber Quiros International Airport" },
    MRLC: { iata: "LSL", name: "Los Chiles Airport" },
    MRLM: { iata: "LIO", name: "Limon International Airport" },
    MRMJ: { iata: "CSC", name: "Mojica Airport" },
    MRNC: { iata: "NCT", name: "Guanacaste Airport" },
    MRNS: { iata: "NOB", name: "Nosara Airport" },
    MROC: { iata: "SJO", name: "Juan Santamaria International Airport" },
    MRPJ: { iata: "PJM", name: "Puerto Jimenez Airport" },
    MRPM: { iata: "PMZ", name: "Palmar Sur Airport" },
    MRPV: { iata: "SYQ", name: "Tobias Bolanos International Airport" },
    MRQP: { iata: "XQP", name: "Quepos Managua Airport" },
    MRRF: { iata: "RFR", name: "Rio Frio / Progreso Airport" },
    MRSI: { iata: "IPZ", name: "San Isidro del General Airport" },
    MRSR: { iata: "PLD", name: "Playa Samara Airport" },
    MRSV: { iata: "TOO", name: "San Vito De Java Airport" },
    MRTM: { iata: "TNO", name: "Tamarindo De Santa Cruz Airport" },
    MRTR: { iata: "TMU", name: "Tambor Airport" },
    MRUP: { iata: "UPL", name: "Upala Airport" },
    MSLP: { iata: "SAL", name: "El Salvador International Airport" },
    MSSS: { iata: "ILS", name: "Ilopango International Airport" },
    MTCA: { iata: "CYA", name: "Les Cayes Airport" },
    MTCH: { iata: "CAP", name: "Cap Haitien International Airport" },
    MTJA: { iata: "JAK", name: "Jacmel Airport" },
    MTJE: { iata: "JEE", name: "Jeremie Airport" },
    MTPP: { iata: "PAP", name: "Toussaint Louverture International Airport" },
    MTPX: { iata: "PAX", name: "Port-de-Paix Airport" },
    MUBA: { iata: "BCA", name: "Gustavo Rizo Airport" },
    MUBR: { iata: "BWW", name: "Las Brujas Airport" },
    MUBY: { iata: "BYM", name: "Carlos Manuel de Cespedes Airport" },
    MUCA: { iata: "AVI", name: "Maximo Gomez Airport" },
    MUCC: { iata: "CCC", name: "Jardines Del Rey Airport" },
    MUCF: { iata: "CFG", name: "Jaime Gonzalez Airport" },
    MUCL: { iata: "CYO", name: "Vilo Acuna International Airport" },
    MUCM: { iata: "CMW", name: "Ignacio Agramonte International Airport" },
    MUCO: { iata: "QCO", name: "Colon Airport" },
    MUCU: { iata: "SCU", name: "Antonio Maceo International Airport" },
    MUGM: { iata: "NBW", name: "Leeward Point Field" },
    MUGT: { iata: "GAO", name: "Mariana Grajales Airport" },
    MUHA: { iata: "HAV", name: "Jose Marti International Airport" },
    MUHG: { iata: "HOG", name: "Frank Pais International Airport" },
    MUKW: { iata: "VRO", name: "Kawama Airport" },
    MULM: { iata: "LCL", name: "La Coloma Airport" },
    MUMA: { iata: "UMA", name: "Punta de Maisi Airport" },
    MUMJ: { iata: "MJG", name: "Mayajigua Airport" },
    MUMO: { iata: "MOA", name: "Orestes Acosta Airport" },
    MUMZ: { iata: "MZO", name: "Sierra Maestra Airport" },
    MUNB: { iata: "QSN", name: "San Nicolas De Bari Airport" },
    MUNC: { iata: "ICR", name: "Nicaro Airport" },
    MUNG: { iata: "GER", name: "Rafael Cabrera Airport" },
    MUPB: { iata: "UPB", name: "Playa Baracoa Airport" },
    MUPR: { iata: "QPD", name: "Pinar Del Rio Airport" },
    MUSC: { iata: "SNU", name: "Abel Santamaria Airport" },
    MUSJ: { iata: "SNJ", name: "San Julian Air Base" },
    MUSN: { iata: "SZJ", name: "Siguanea Airport" },
    MUSS: { iata: "USS", name: "Sancti Spiritus Airport" },
    MUTD: { iata: "TND", name: "Alberto Delgado Airport" },
    MUVR: { iata: "VRA", name: "Juan Gualberto Gomez International Airport" },
    MUVT: { iata: "VTU", name: "Hermanos Ameijeiras Airport" },
    MWCB: { iata: "CYB", name: "Gerrard Smith International Airport" },
    MWCL: { iata: "LYB", name: "Edward Bodden Airfield" },
    MWCR: { iata: "GCM", name: "Owen Roberts International Airport" },
    MYAB: { iata: "MAY", name: "Clarence A. Bain Airport" },
    MYAF: { iata: "ASD", name: "Andros Town Airport" },
    MYAK: { iata: "COX", name: "Congo Town Airport" },
    MYAM: { iata: "MHH", name: "Marsh Harbour International Airport" },
    MYAN: { iata: "SAQ", name: "San Andros Airport" },
    MYAP: { iata: "AXP", name: "Spring Point Airport" },
    MYAT: { iata: "TCB", name: "Treasure Cay Airport" },
    MYAW: { iata: "WKR", name: "Abaco I Walker C Airport" },
    MYBC: { iata: "CCZ", name: "Chub Cay Airport" },
    MYBG: { iata: "GHC", name: "Great Harbour Cay Airport" },
    MYBS: { iata: "BIM", name: "South Bimini Airport" },
    MYCA: { iata: "ATC", name: "Arthur's Town Airport" },
    MYCB: { iata: "CAT", name: "New Bight Airport" },
    MYCC: { iata: "CXY", name: "Cat Cay Airport" },
    MYCI: { iata: "CRI", name: "Colonel Hill Airport" },
    MYCP: { iata: "PWN", name: "Pitts Town Airport" },
    MYEF: { iata: "GGT", name: "Exuma International Airport" },
    MYEH: { iata: "ELH", name: "North Eleuthera Airport" },
    MYEM: { iata: "GHB", name: "Governor's Harbour Airport" },
    MYEN: { iata: "NMC", name: "Normans Cay Airport" },
    MYER: { iata: "RSD", name: "Rock Sound Airport" },
    MYES: { iata: "TYM", name: "Staniel Cay Airport" },
    MYGF: { iata: "FPO", name: "Grand Bahama International Airport" },
    MYGM: { iata: "GBI", name: "Auxiliary Airfield" },
    MYGW: { iata: "WTD", name: "West End Airport" },
    MYIG: { iata: "IGA", name: "Inagua Airport" },
    MYLD: { iata: "LGI", name: "Deadman's Cay Airport" },
    MYLS: { iata: "SML", name: "Stella Maris Airport" },
    MYMM: { iata: "MYG", name: "Mayaguana Airport" },
    MYNN: { iata: "NAS", name: "Lynden Pindling International Airport" },
    MYPI: { iata: "PID", name: "Nassau Paradise Island Airport" },
    MYRD: { iata: "DCT", name: "Duncan Town Airport" },
    MYRP: { iata: "RCY", name: "Rum Cay Airport" },
    MYSM: { iata: "ZSA", name: "San Salvador Airport" },
    MZBZ: { iata: "BZE", name: "Philip S. W. Goldson International Airport" },
    NCAI: { iata: "AIT", name: "Aitutaki Airport" },
    NCAT: { iata: "AIU", name: "Enua Airport" },
    NCMG: { iata: "MGS", name: "Mangaia Island Airport" },
    NCMH: { iata: "MHX", name: "Manihiki Island Airport" },
    NCMK: { iata: "MUK", name: "Mauke Airport" },
    NCMR: { iata: "MOI", name: "Mitiaro Island Airport" },
    NCPY: { iata: "PYE", name: "Tongareva Airport" },
    NCRG: { iata: "RAR", name: "Rarotonga International Airport" },
    NFCI: { iata: "ICI", name: "Cicia Airport" },
    NFFA: { iata: "BFJ", name: "Ba Airport" },
    NFFN: { iata: "NAN", name: "Nadi International Airport" },
    NFFO: { iata: "PTF", name: "Malolo Lailai Island Airport" },
    NFKD: { iata: "KDV", name: "Vunisea Airport" },
    NFMA: { iata: "MNF", name: "Mana Island Airport" },
    NFMO: { iata: "MFJ", name: "Moala Airport" },
    NFNA: { iata: "SUV", name: "Nausori International Airport" },
    NFNB: { iata: "LEV", name: "Levuka Airfield" },
    NFNG: { iata: "NGI", name: "Ngau Airport" },
    NFNH: { iata: "LUC", name: "Laucala Island Airport" },
    NFNK: { iata: "LKB", name: "Lakeba Island Airport" },
    NFNL: { iata: "LBS", name: "Labasa Airport" },
    NFNM: { iata: "TVU", name: "Matei Airport" },
    NFNO: { iata: "KXF", name: "Koro Island Airport" },
    NFNR: { iata: "RTA", name: "Rotuma Airport" },
    NFNS: { iata: "SVU", name: "Savusavu Airport" },
    NFNV: { iata: "VAU", name: "Vatukoula Airport" },
    NFNW: { iata: "KAY", name: "Wakaya Island Airport" },
    NFOL: { iata: "ONU", name: "Ono-I-Lau Airport" },
    NFSW: { iata: "YAS", name: "Yasawa Island Airport" },
    NFTE: { iata: "EUA", name: "Kaufana Airport" },
    NFTF: { iata: "TBU", name: "Fua'amotu International Airport" },
    NFTL: { iata: "HPA", name: "Lifuka Island Airport" },
    NFTO: { iata: "Niu", name: "Mata'aho Airport" },
    NFTP: { iata: "NTT", name: "Kuini Lavenia Airport" },
    NFTV: { iata: "VAV", name: "Vava'u International Airport" },
    NFVB: { iata: "VBV", name: "Vanua Balavu Airport" },
    NFVL: { iata: "VTF", name: "Vatulele Airport" },
    NGAB: { iata: "ABF", name: "Abaiang Airport" },
    NGBR: { iata: "BEZ", name: "Beru Airport" },
    NGFU: { iata: "FUN", name: "Funafuti International Airport" },
    NGKT: { iata: "KUC", name: "Kuria Airport" },
    NGMA: { iata: "MNK", name: "Maiana Airport" },
    NGMK: { iata: "MZK", name: "Marakei Airport" },
    NGMN: { iata: "MTK", name: "Makin Island Airport" },
    NGNU: { iata: "NIG", name: "Nikunau Airport" },
    NGON: { iata: "OOT", name: "Onotoa Airport" },
    NGTA: { iata: "TRW", name: "Bonriki International Airport" },
    NGTB: { iata: "AEA", name: "Abemama Atoll Airport" },
    NGTE: { iata: "TBF", name: "Tabiteuea North Airport" },
    NGTM: { iata: "TMN", name: "Tamana Island Airport" },
    NGTO: { iata: "NON", name: "Nonouti Airport" },
    NGTR: { iata: "AIS", name: "Arorae Island Airport" },
    NGTS: { iata: "TSU", name: "Tabiteuea South Airport" },
    NGTU: { iata: "BBG", name: "Butaritari Atoll Airport" },
    NGUK: { iata: "AAK", name: "Buariki Airport" },
    NIUE: { iata: "IUE", name: "Niue International Airport" },
    NLWF: { iata: "FUT", name: "Pointe Vele Airport" },
    NLWW: { iata: "WLS", name: "Hihifo Airport" },
    NSAS: { iata: "OFU", name: "Ofu Village Airport" },
    NSAU: { iata: "AAU", name: "Asau Airport" },
    NSFA: { iata: "APW", name: "Faleolo International Airport" },
    NSFI: { iata: "FGI", name: "Fagali'i Airport" },
    NSFQ: { iata: "FTI", name: "Fitiuta Airport" },
    NSMA: { iata: "MXS", name: "Maota Airport" },
    NSTU: { iata: "PPG", name: "Pago Pago International Airport" },
    NTAA: { iata: "PPT", name: "Faa'a International Airport" },
    NTAR: { iata: "RUR", name: "Rurutu Airport" },
    NTAT: { iata: "TUB", name: "Tubuai Airport" },
    NTAV: { iata: "RVV", name: "Raivavae Airport" },
    NTGA: { iata: "AAA", name: "Anaa Airport" },
    NTGB: { iata: "FGU", name: "Fangatau Airport" },
    NTGC: { iata: "TIH", name: "Tikehau Airport" },
    NTGD: { iata: "APK", name: "Apataki Airport" },
    NTGE: { iata: "REA", name: "Reao Airport" },
    NTGF: { iata: "FAV", name: "Fakarava Airport" },
    NTGH: { iata: "HHZ", name: "Hikueru Atoll Airport" },
    NTGI: { iata: "XMH", name: "Manihi Airport" },
    NTGJ: { iata: "GMR", name: "Totegegie Airport" },
    NTGK: { iata: "KKR", name: "Kaukura Airport" },
    NTGM: { iata: "MKP", name: "Makemo Airport" },
    NTGN: { iata: "NAU", name: "Napuka Island Airport" },
    NTGO: { iata: "TKV", name: "Tatakoto Airport" },
    NTGP: { iata: "PKP", name: "Puka Puka Airport" },
    NTGQ: { iata: "PUK", name: "Pukarua Airport" },
    NTGT: { iata: "TKP", name: "Takapoto Airport" },
    NTGU: { iata: "AXR", name: "Arutua Airport" },
    NTGV: { iata: "MVT", name: "Mataiva Airport" },
    NTGW: { iata: "NUK", name: "Nukutavake Airport" },
    NTGY: { iata: "ZTA", name: "Tureia Airport" },
    NTHE: { iata: "AHE", name: "Ahe Airport" },
    NTKA: { iata: "KHZ", name: "Kauehi Airport" },
    NTKF: { iata: "FAC", name: "Faaite Airport" },
    NTKH: { iata: "FHZ", name: "Fakahina Airport" },
    NTKK: { iata: "RKA", name: "Aratika Nord Airport" },
    NTKM: { iata: "TJN", name: "Takume Airport" },
    NTKO: { iata: "RRR", name: "Raroia Airport" },
    NTKR: { iata: "TKX", name: "Takaroa Airport" },
    NTKT: { iata: "KXU", name: "Katiu Airport" },
    NTMD: { iata: "NHV", name: "Nuku Hiva Airport" },
    NTMN: { iata: "AUQ", name: "Hiva Oa-Atuona Airport" },
    NTMP: { iata: "UAP", name: "Ua Pou Airport" },
    NTMU: { iata: "UAH", name: "Ua Huka Airport" },
    NTTB: { iata: "BOB", name: "Bora Bora Airport" },
    NTTE: { iata: "TTI", name: "Tetiaroa Airport" },
    NTTG: { iata: "RGI", name: "Rangiroa Airport" },
    NTTH: { iata: "HUH", name: "Huahine-Fare Airport" },
    NTTM: { iata: "MOZ", name: "Moorea Airport" },
    NTTO: { iata: "HOI", name: "Hao Airport" },
    NTTP: { iata: "MAU", name: "Maupiti Airport" },
    NTTR: { iata: "RFP", name: "Raiatea Airport" },
    NTTX: { iata: "UOA", name: "Mururoa Atoll Airport" },
    NTUV: { iata: "VHZ", name: "Vahitahi Airport" },
    NVSA: { iata: "MTV", name: "Mota Lava Airport" },
    NVSC: { iata: "SLH", name: "Sola Airport" },
    NVSD: { iata: "TOH", name: "Torres Airstrip" },
    NVSE: { iata: "EAE", name: "Sangafa Airport" },
    NVSF: { iata: "CCV", name: "Craig Cove Airport" },
    NVSG: { iata: "LOD", name: "Longana Airport" },
    NVSH: { iata: "SSR", name: "Sara Airport" },
    NVSI: { iata: "PBJ", name: "Tavie Airport" },
    NVSL: { iata: "LPM", name: "Lamap Airport" },
    NVSM: { iata: "LNB", name: "Lamen Bay Airport" },
    NVSN: { iata: "MWF", name: "Naone Airport" },
    NVSO: { iata: "LNE", name: "Lonorore Airport" },
    NVSP: { iata: "NUS", name: "Norsup Airport" },
    NVSQ: { iata: "ZGU", name: "Gaua Island Airport" },
    NVSR: { iata: "RCL", name: "Redcliffe Airport" },
    NVSS: { iata: "SON", name: "Santo Pekoa International Airport" },
    NVST: { iata: "TGH", name: "Tongoa Island Airport" },
    NVSU: { iata: "ULB", name: "Ulei Airport" },
    NVSV: { iata: "VLS", name: "Valesdir Airport" },
    NVSW: { iata: "WLH", name: "Walaha Airport" },
    NVSX: { iata: "SWJ", name: "Southwest Bay Airport" },
    NVSZ: { iata: "OLJ", name: "North West Santo Airport" },
    NVVA: { iata: "AUY", name: "Anelghowhat Airport" },
    NVVB: { iata: "AWD", name: "Aniwa Airport" },
    NVVD: { iata: "DLY", name: "Dillon's Bay Airport" },
    NVVF: { iata: "FTA", name: "Futuna Airport" },
    NVVI: { iata: "IPA", name: "Ipota Airport" },
    NVVQ: { iata: "UIQ", name: "Quion Hill Airport" },
    NVVV: { iata: "VLI", name: "Port Vila Bauerfield Airport" },
    NVVW: { iata: "TAH", name: "Tanna Airport" },
    NWWA: { iata: "TGJ", name: "Tiga Airport" },
    NWWC: { iata: "BMY", name: "Ile Art - Waala Airport" },
    NWWD: { iata: "KNQ", name: "Kone Airport" },
    NWWE: { iata: "ILP", name: "Ile des Pins Airport" },
    NWWH: { iata: "HLU", name: "Nesson Airport" },
    NWWK: { iata: "KOC", name: "Koumac Airport" },
    NWWL: { iata: "LIF", name: "Lifou Airport" },
    NWWM: { iata: "GEA", name: "Noumea Magenta Airport" },
    NWWO: { iata: "IOU", name: "Edmond Cane Airport" },
    NWWP: { iata: "PUV", name: "Poum Airport" },
    NWWQ: { iata: "PDC", name: "Mueo Airport" },
    NWWR: { iata: "MEE", name: "Mare Airport" },
    NWWU: { iata: "TOU", name: "Touho Airport" },
    NWWV: { iata: "UVE", name: "Ouvea Airport" },
    NWWW: { iata: "NOU", name: "La Tontouta International Airport" },
    NZAA: { iata: "AKL", name: "Auckland International Airport" },
    NZAP: { iata: "TUO", name: "Taupo Airport" },
    NZAR: { iata: "AMZ", name: "Ardmore Airport" },
    NZAS: { iata: "ASG", name: "Ashburton Aerodrome" },
    NZCH: { iata: "CHC", name: "Christchurch International Airport" },
    NZCI: { iata: "CHT", name: "Chatham Islands-Tuuta Airport" },
    NZCX: { iata: "CMV", name: "Coromandel Aerodrome" },
    NZDA: { iata: "DGR", name: "Dargaville Aerodrome" },
    NZDN: { iata: "DUD", name: "Dunedin Airport" },
    NZFJ: { iata: "WHO", name: "Franz Josef Aerodrome" },
    NZGB: { iata: "GBZ", name: "Great Barrier Aerodrome" },
    NZGM: { iata: "GMN", name: "Greymouth Airport" },
    NZGS: { iata: "GIS", name: "Gisborne Airport" },
    NZGT: { iata: "GTN", name: "Glentanner Airport" },
    NZHK: { iata: "HKK", name: "Hokitika Airfield" },
    NZHN: { iata: "HLZ", name: "Hamilton International Airport" },
    NZKE: { iata: "WIK", name: "Waiheke Reeve Airport" },
    NZKI: { iata: "KBZ", name: "Kaikoura Airport" },
    NZKK: { iata: "KKE", name: "Kerikeri Airport" },
    NZKO: { iata: "KKO", name: "Kaikohe Airport" },
    NZKT: { iata: "KAT", name: "Kaitaia Airport" },
    NZLX: { iata: "ALR", name: "Alexandra Airport" },
    NZMA: { iata: "MTA", name: "Matamata Glider Airport" },
    NZMC: { iata: "MON", name: "Mount Cook Airport" },
    NZMF: { iata: "MFN", name: "Milford Sound Airport" },
    NZMK: { iata: "MZP", name: "Motueka Airport" },
    NZMO: { iata: "TEU", name: "Manapouri Airport" },
    NZMS: { iata: "MRO", name: "Hood Airport" },
    NZNP: { iata: "NPL", name: "New Plymouth Airport" },
    NZNR: { iata: "NPE", name: "Napier Airport" },
    NZNS: { iata: "NSN", name: "Nelson Airport" },
    NZNV: { iata: "IVC", name: "Invercargill Airport" },
    NZOH: { iata: "OHA", name: "RNZAF Base Ohakea" },
    NZOU: { iata: "OAM", name: "Oamaru Airport" },
    NZPM: { iata: "PMR", name: "Palmerston North Airport" },
    NZPN: { iata: "PCN", name: "Picton Aerodrome" },
    NZPP: { iata: "PPQ", name: "Paraparaumu Airport" },
    NZQN: { iata: "ZQN", name: "Queenstown International Airport" },
    NZRA: { iata: "RAG", name: "Raglan Airfield" },
    NZRC: { iata: "SZS", name: "Ryans Creek Aerodrome" },
    NZRO: { iata: "ROT", name: "Rotorua Regional Airport" },
    NZTG: { iata: "TRG", name: "Tauranga Airport" },
    NZTH: { iata: "TMZ", name: "Thames Aerodrome" },
    NZTK: { iata: "KTF", name: "Takaka Airport" },
    NZTO: { iata: "TKZ", name: "Tokoroa Airfield" },
    NZTS: { iata: "THH", name: "Taharoa Aerodrome" },
    NZTU: { iata: "TIU", name: "Timaru Airport" },
    NZUK: { iata: "TWZ", name: "Pukaki Airport" },
    NZWB: { iata: "BHE", name: "Woodbourne Airport" },
    NZWF: { iata: "WKA", name: "Wanaka Airport" },
    NZWK: { iata: "WHK", name: "Whakatane Airport" },
    NZWN: { iata: "WLG", name: "Wellington International Airport" },
    NZWO: { iata: "WIR", name: "Wairoa Airport" },
    NZWR: { iata: "WRE", name: "Whangarei Airport" },
    NZWS: { iata: "WSZ", name: "Westport Airport" },
    NZWT: { iata: "WTZ", name: "Whitianga Airport" },
    NZWU: { iata: "WAG", name: "Wanganui Airport" },
    OABN: { iata: "BIN", name: "Bamiyan Airport" },
    OABT: { iata: "BST", name: "Bost Airport" },
    OACC: { iata: "CCN", name: "Chakcharan Airport" },
    OADZ: { iata: "DAZ", name: "Darwaz Airport" },
    OAFR: { iata: "FAH", name: "Farah Airport" },
    OAFZ: { iata: "FBD", name: "Faizabad Airport" },
    OAHN: { iata: "KWH", name: "Khwahan Airport" },
    OAHR: { iata: "HEA", name: "Herat Airport" },
    OAIX: { iata: "OAI", name: "Bagram Air Base" },
    OAJL: { iata: "JAA", name: "Jalalabad Airport" },
    OAKB: { iata: "KBL", name: "Kabul International Airport" },
    OAKN: { iata: "KDH", name: "Kandahar Airport" },
    OAKS: { iata: "KHT", name: "Khost Airport" },
    OAMN: { iata: "MMZ", name: "Maimana Airport" },
    OAMS: { iata: "MZR", name: "Mazar I Sharif Airport" },
    OAQN: { iata: "LQN", name: "Qala-I-Naw Airport" },
    OASA: { iata: "OAS", name: "Sharana Airstrip" },
    OASD: { iata: "OAH", name: "Shindand Airport" },
    OASN: { iata: "SGA", name: "Sheghnan Airport" },
    OATN: { iata: "TII", name: "Tarin Kowt Airport" },
    OATQ: { iata: "TQN", name: "Talolqan Airport" },
    OAUZ: { iata: "UND", name: "Konduz Airport" },
    OAZI: { iata: "OAZ", name: "Camp Bastion Airport" },
    OAZJ: { iata: "ZAJ", name: "Zaranj Airport" },
    OBBI: { iata: "BAH", name: "Bahrain International Airport" },
    OEAB: { iata: "AHB", name: "Abha Regional Airport" },
    OEAH: { iata: "HOF", name: "Al Ahsa Airport" },
    OEAO: {
      iata: "ULH",
      name: "Prince Abdul Majeed bin Abdulaziz International Airport",
    },
    OEBA: { iata: "ABT", name: "Al Baha Airport" },
    OEBH: { iata: "BHH", name: "Bisha Airport" },
    OEDF: { iata: "DMM", name: "King Fahd International Airport" },
    OEDR: { iata: "DHA", name: "King Abdulaziz Air Base" },
    OEDW: { iata: "DWD", name: "Dawadmi Domestic Airport" },
    OEGN: { iata: "GIZ", name: "Jizan Regional Airport" },
    OEGS: { iata: "ELQ", name: "Gassim Airport" },
    OEGT: { iata: "URY", name: "Guriat Domestic Airport" },
    OEHL: { iata: "HAS", name: "Hail Airport" },
    OEJB: { iata: "QJB", name: "Jubail Airport" },
    OEJN: { iata: "JED", name: "King Abdulaziz International Airport" },
    OEKK: { iata: "HBT", name: "King Khaled Military City Airport" },
    OEKM: { iata: "KMX", name: "King Khaled Air Base" },
    OEMA: { iata: "MED", name: "Prince Mohammad Bin Abdulaziz Airport" },
    OENG: { iata: "EAM", name: "Nejran Airport" },
    OEPA: { iata: "AQI", name: "Hafr Al Batin Airport" },
    OEPS: { iata: "AKH", name: "Prince Sultan Air Base" },
    OERF: { iata: "RAH", name: "Rafha Domestic Airport" },
    OERK: { iata: "RUH", name: "King Khaled International Airport" },
    OERR: { iata: "RAE", name: "Arar Domestic Airport" },
    OERY: { iata: "XXN", name: "Riyadh Air Base" },
    OESH: { iata: "SHW", name: "Sharurah Airport" },
    OESK: { iata: "AJF", name: "Al-Jawf Domestic Airport" },
    OESL: { iata: "SLF", name: "Sulayel Airport" },
    OETB: { iata: "TUU", name: "Tabuk Airport" },
    OETF: { iata: "TIF", name: "Taif Airport" },
    OETR: { iata: "TUI", name: "Turaif Domestic Airport" },
    OEWD: { iata: "WAE", name: "Wadi Al Dawasir Airport" },
    OEWJ: { iata: "EJH", name: "Al Wajh Domestic Airport" },
    OEYN: { iata: "YNB", name: "Yenbo Airport" },
    OEZL: { iata: "ZUL", name: "Zilfi Airport" },
    OIAA: { iata: "ABD", name: "Abadan Airport" },
    OIAD: { iata: "DEF", name: "Dezful Airport" },
    OIAG: { iata: "AKW", name: "Aghajari Airport" },
    OIAH: { iata: "GCH", name: "Gachsaran Airport" },
    OIAJ: { iata: "OMI", name: "Omidiyeh Airport" },
    OIAM: { iata: "MRX", name: "Mahshahr Airport" },
    OIAW: { iata: "AWZ", name: "Ahwaz Airport" },
    OIBA: { iata: "AEU", name: "Abumusa Island Airport" },
    OIBB: { iata: "BUZ", name: "Bushehr Airport" },
    OIBH: { iata: "IAQ", name: "Bastak Airport" },
    OIBI: { iata: "AOY", name: "Asaloyeh Airport" },
    OIBJ: { iata: "KNR", name: "Jam Airport" },
    OIBK: { iata: "KIH", name: "Kish International Airport" },
    OIBL: { iata: "BDH", name: "Bandar Lengeh Airport" },
    OIBP: { iata: "YEH", name: "Persian Gulf International Airport" },
    OIBQ: { iata: "KHK", name: "Khark Island Airport" },
    OIBS: { iata: "SXI", name: "Sirri Island Airport" },
    OIBV: { iata: "LVP", name: "Lavan Island Airport" },
    OICC: { iata: "KSH", name: "Shahid Ashrafi Esfahani Airport" },
    OICI: { iata: "IIL", name: "Ilam Airport" },
    OICK: { iata: "KHD", name: "Khoram Abad Airport" },
    OICS: { iata: "SDG", name: "Sanandaj Airport" },
    OIFE: { iata: "IFH", name: "Hesa Airport" },
    OIFK: { iata: "KKS", name: "Kashan Airport" },
    OIFM: { iata: "IFN", name: "Esfahan Shahid Beheshti International Airport" },
    OIFS: { iata: "CQD", name: "Shahrekord Airport" },
    OIGG: { iata: "RAS", name: "Sardar-e-Jangal Airport" },
    OIHH: { iata: "HDM", name: "Hamadan Airport" },
    OIHR: { iata: "AJK", name: "Arak Airport" },
    OIHS: { iata: "NUJ", name: "Hamadan Air Base" },
    OIIE: { iata: "IKA", name: "Imam Khomeini International Airport" },
    OIII: { iata: "THR", name: "Mehrabad International Airport" },
    OIIK: { iata: "GZW", name: "Qazvin Airport" },
    OIIP: { iata: "PYK", name: "Payam Airport" },
    OIIS: { iata: "SNX", name: "Semnan Airport" },
    OIKB: { iata: "BND", name: "Bandar Abbas International Airport" },
    OIKJ: { iata: "JYR", name: "Jiroft Airport" },
    OIKK: { iata: "KER", name: "Kerman Airport" },
    OIKM: { iata: "BXR", name: "Bam Airport" },
    OIKP: { iata: "HDR", name: "Havadarya Airport" },
    OIKQ: { iata: "GSM", name: "Dayrestan Airport" },
    OIKR: { iata: "RJN", name: "Rafsanjan Airport" },
    OIKY: { iata: "SYJ", name: "Sirjan Airport" },
    OIMB: { iata: "XBJ", name: "Birjand Airport" },
    OIMC: { iata: "CKT", name: "Sarakhs Airport" },
    OIMJ: { iata: "RUD", name: "Shahroud Airport" },
    OIMM: { iata: "MHD", name: "Mashhad International Airport" },
    OIMN: { iata: "BJB", name: "Bojnord Airport" },
    OIMS: { iata: "AFZ", name: "Sabzevar National Airport" },
    OIMT: { iata: "TCX", name: "Tabas Airport" },
    OINB: { iata: "BBL", name: "Babolsar Airport" },
    OINE: { iata: "KLM", name: "Kalaleh Airport" },
    OING: { iata: "GBT", name: "Gorgan Airport" },
    OINJ: { iata: "BSM", name: "Bishe Kola Air Base" },
    OINN: { iata: "NSH", name: "Noshahr Airport" },
    OINR: { iata: "RZR", name: "Ramsar Airport" },
    OINZ: { iata: "SRY", name: "Dasht-e Naz Airport" },
    OISF: { iata: "FAZ", name: "Fasa Airport" },
    OISJ: { iata: "JAR", name: "Jahrom Airport" },
    OISL: { iata: "LRR", name: "Lar Airport" },
    OISR: { iata: "LFM", name: "Lamerd Airport" },
    OISS: { iata: "SYZ", name: "Shiraz Shahid Dastghaib International Airport" },
    OISY: { iata: "YES", name: "Yasouj Airport" },
    OITK: { iata: "KHY", name: "Khoy Airport" },
    OITL: { iata: "ADU", name: "Ardabil Airport" },
    OITM: { iata: "ACP", name: "Sahand Airport" },
    OITP: { iata: "PFQ", name: "Parsabade Moghan Airport" },
    OITR: { iata: "OMH", name: "Urmia Airport" },
    OITT: { iata: "TBZ", name: "Tabriz International Airport" },
    OITU: { iata: "IMQ", name: "Makou Airport" },
    OITZ: { iata: "JWN", name: "Zanjan Airport" },
    OIYY: { iata: "AZD", name: "Shahid Sadooghi Airport" },
    OIZB: { iata: "ACZ", name: "Zabol Airport" },
    OIZC: { iata: "ZBR", name: "Konarak Airport" },
    OIZH: { iata: "ZAH", name: "Zahedan International Airport" },
    OIZI: { iata: "IHR", name: "Iran Shahr Airport" },
    OJAI: { iata: "AMM", name: "Queen Alia International Airport" },
    OJAM: { iata: "ADJ", name: "Amman-Marka International Airport" },
    OJAQ: { iata: "AQJ", name: "Aqaba King Hussein International Airport" },
    OJMF: { iata: "OMF", name: "King Hussein Air College" },
    OK03: { iata: "DWN", name: "Downtown Airpark" },
    OKAJ: { iata: "XIJ", name: "Ahmed Al Jaber Air Base" },
    OKKK: { iata: "KWI", name: "Kuwait International Airport" },
    OLBA: { iata: "BEY", name: "Beirut Rafic Hariri International Airport" },
    OLKA: { iata: "KYE", name: "Rene Mouawad Air Base" },
    OMAA: { iata: "AUH", name: "Abu Dhabi International Airport" },
    OMAD: { iata: "AZI", name: "Al Bateen Executive Airport" },
    OMAL: { iata: "AAN", name: "Al Ain International Airport" },
    OMAM: { iata: "DHF", name: "Al Dhafra Air Base" },
    OMDB: { iata: "DXB", name: "Dubai International Airport" },
    OMDM: { iata: "NHD", name: "Al Minhad Air Base" },
    OMDW: { iata: "DWC", name: "Al Maktoum International Airport" },
    OMFJ: { iata: "FJR", name: "Fujairah International Airport" },
    OMRK: { iata: "RKT", name: "Ras Al Khaimah International Airport" },
    OMSJ: { iata: "SHJ", name: "Sharjah International Airport" },
    OOBR: { iata: "RMB", name: "Buraimi Airport" },
    OODQ: { iata: "DQM", name: "Duqm International Airport" },
    OOFD: { iata: "FAU", name: "Fahud Airport" },
    OOGB: { iata: "RNM", name: "Qarn Alam Airport" },
    OOKB: { iata: "KHS", name: "Khasab Air Base" },
    OOLK: { iata: "LKW", name: "Lekhwair Airport" },
    OOMA: { iata: "MSH", name: "Masirah Air Base" },
    OOMS: { iata: "MCT", name: "Muscat International Airport" },
    OOMX: { iata: "OMM", name: "Marmul Airport" },
    OORQ: { iata: "MNH", name: "Rustaq Airport" },
    OOSA: { iata: "SLL", name: "Salalah Airport" },
    OOSH: { iata: "OHS", name: "Sohar Airport" },
    OOSR: { iata: "SUH", name: "Sur Airport" },
    OOTH: { iata: "TTH", name: "Thumrait Air Base" },
    OPAB: { iata: "AAW", name: "Abbottabad Airport" },
    OPBG: { iata: "BHW", name: "Bhagatanwala Airport" },
    OPBN: { iata: "BNP", name: "Bannu Airport" },
    OPBR: { iata: "WGB", name: "Bahawalnagar Airport" },
    OPBW: { iata: "BHV", name: "Bahawalpur Airport" },
    OPCH: { iata: "CJL", name: "Chitral Airport" },
    OPCL: { iata: "CHB", name: "Chilas Airport" },
    OPDB: { iata: "DBA", name: "Dalbandin Airport" },
    OPDD: { iata: "DDU", name: "Dadu Airport" },
    OPDG: { iata: "DEA", name: "Dera Ghazi Khan Airport" },
    OPDI: { iata: "DSK", name: "Dera Ismael Khan Airport" },
    OPFA: { iata: "LYP", name: "Faisalabad International Airport" },
    OPGD: { iata: "GWD", name: "Gwadar International Airport" },
    OPGT: { iata: "GIL", name: "Gilgit Airport" },
    OPIS: { iata: "ISB", name: "Islamabad International Airport" },
    OPJA: { iata: "JAG", name: "Shahbaz Air Base" },
    OPJI: { iata: "JIW", name: "Jiwani Airport" },
    OPKC: { iata: "KHI", name: "Jinnah International Airport" },
    OPKD: { iata: "HDD", name: "Hyderabad Airport" },
    OPKH: { iata: "KDD", name: "Khuzdar Airport" },
    OPKL: { iata: "KBH", name: "Kalat Airport" },
    OPKT: { iata: "OHT", name: "Kohat Airport" },
    OPLA: { iata: "LHE", name: "Alama Iqbal International Airport" },
    OPLL: { iata: "LRG", name: "Loralai Airport" },
    OPMA: { iata: "XJM", name: "Mangla Airport" },
    OPMF: { iata: "MFG", name: "Muzaffarabad Airport" },
    OPMI: { iata: "MWD", name: "Mianwali Air Base" },
    OPMJ: { iata: "MJD", name: "Moenjodaro Airport" },
    OPMP: { iata: "MPD", name: "Sindhri Tharparkar Airport" },
    OPMT: { iata: "MUX", name: "Multan International Airport" },
    OPNH: { iata: "WNS", name: "Nawabshah Airport" },
    OPNK: { iata: "NHS", name: "Nushki Airport" },
    OPOR: { iata: "ORW", name: "Ormara Airport" },
    OPPC: { iata: "PAJ", name: "Parachinar Airport" },
    OPPG: { iata: "PJG", name: "Panjgur Airport" },
    OPPI: { iata: "PSI", name: "Pasni Airport" },
    OPPS: { iata: "PEW", name: "Peshawar International Airport" },
    OPQT: { iata: "UET", name: "Quetta International Airport" },
    OPRK: { iata: "RYK", name: "Shaikh Zaid Airport" },
    OPRN: { iata: "ISB", name: "Benazir Bhutto International Airport" },
    OPRT: { iata: "RAZ", name: "Rawalakot Airport" },
    OPSB: { iata: "SBQ", name: "Sibi Airport" },
    OPSD: { iata: "KDU", name: "Skardu Airport" },
    OPSK: { iata: "SKZ", name: "Sukkur Airport" },
    OPSN: { iata: "SYW", name: "Sehwan Sharif Airport" },
    OPSR: { iata: "SGI", name: "Mushaf Air Base" },
    OPSS: { iata: "SDT", name: "Saidu Sharif Airport" },
    OPST: { iata: "SKT", name: "Sialkot Airport" },
    OPSU: { iata: "SUL", name: "Sui Airport" },
    OPSW: { iata: "SWN", name: "Sahiwal Airport" },
    OPTA: { iata: "TLB", name: "Tarbela Dam Airport" },
    OPTH: { iata: "BDN", name: "Talhar Airport" },
    OPTT: { iata: "TFT", name: "Taftan Airport" },
    OPTU: { iata: "TUK", name: "Turbat International Airport" },
    OPWN: { iata: "WAF", name: "Wana Airport" },
    OPZB: { iata: "PZH", name: "Zhob Airport" },
    ORAA: { iata: "IQA", name: "Al Asad Air Base" },
    ORAT: { iata: "TQD", name: "Al Taqaddum Air Base" },
    ORBB: { iata: "BMN", name: "Bamarni Airport" },
    ORBI: { iata: "BGW", name: "Baghdad International Airport" },
    ORBM: { iata: "OSB", name: "Mosul International Airport" },
    ORER: { iata: "EBL", name: "Erbil International Airport" },
    ORKK: { iata: "KIK", name: "Kirkuk Air Base" },
    ORMM: { iata: "BSR", name: "Basrah International Airport" },
    ORNI: { iata: "NJF", name: "Al Najaf International Airport" },
    ORQW: { iata: "RQW", name: "Qayyarah West Airport" },
    ORSU: { iata: "ISU", name: "Sulaymaniyah International Airport" },
    OSAP: { iata: "ALP", name: "Aleppo International Airport" },
    OSDI: { iata: "DAM", name: "Damascus International Airport" },
    OSDZ: { iata: "DEZ", name: "Deir ez-Zor Airport" },
    OSKL: { iata: "KAC", name: "Kamishly Airport" },
    OSLK: { iata: "LTK", name: "Bassel Al-Assad International Airport" },
    OSPR: { iata: "PMS", name: "Palmyra Airport" },
    OTBD: { iata: "DIA", name: "Doha International Airport" },
    OTBH: { iata: "IUD", name: "Al Udeid Air Base" },
    OTHH: { iata: "DOH", name: "Hamad International Airport" },
    OYAA: { iata: "ADE", name: "Aden International Airport" },
    OYAT: { iata: "AXK", name: "Ataq Airport" },
    OYBI: { iata: "BYD", name: "Al-Bayda Airport" },
    OYBN: { iata: "BHN", name: "Beihan Airport" },
    OYGD: { iata: "AAY", name: "Al Ghaidah International Airport" },
    OYHD: { iata: "HOD", name: "Hodeidah International Airport" },
    OYMB: { iata: "MYN", name: "Mareb Airport" },
    OYQN: { iata: "IHN", name: "Qishn Airport" },
    OYRN: { iata: "RIY", name: "Mukalla International Airport" },
    OYSH: { iata: "SYE", name: "Sadah Airport" },
    OYSN: { iata: "SAH", name: "Sana'a International Airport" },
    OYSQ: { iata: "SCT", name: "Socotra International Airport" },
    OYSY: { iata: "GXF", name: "Sayun International Airport" },
    OYTZ: { iata: "TAI", name: "Ta'izz International Airport" },
    PAAK: { iata: "AKB", name: "Atka Airport" },
    PAAL: { iata: "PML", name: "Port Moller Airport" },
    PAAQ: { iata: "PAQ", name: "Palmer Municipal Airport" },
    PABA: { iata: "BTI", name: "Barter Island LRRS Airport" },
    PABE: { iata: "BET", name: "Bethel Airport" },
    PABG: { iata: "BVU", name: "Beluga Airport" },
    PABI: { iata: "BIG", name: "Allen Army Airfield" },
    PABL: { iata: "BKC", name: "Buckland Airport" },
    PABM: { iata: "BMX", name: "Big Mountain Airport" },
    PABR: { iata: "BRW", name: "Wiley Post Will Rogers Memorial Airport" },
    PABT: { iata: "BTT", name: "Bettles Airport" },
    PACD: { iata: "CDB", name: "Cold Bay Airport" },
    PACE: { iata: "CEM", name: "Central Airport" },
    PACH: { iata: "CHU", name: "Chuathbaluk Airport" },
    PACI: { iata: "CIK", name: "Chalkyitsik Airport" },
    PACK: { iata: "CYF", name: "Chefornak Airport" },
    PACM: { iata: "SCM", name: "Scammon Bay Airport" },
    PACR: { iata: "IRC", name: "Circle City /New/ Airport" },
    PACS: { iata: "WSF", name: "Cape Sarichef Airport" },
    PACV: { iata: "CDV", name: "Merle K (Mudhole) Smith Airport" },
    PACX: { iata: "CXF", name: "Coldfoot Airport" },
    PACY: { iata: "CYT", name: "Yakataga Airport" },
    PACZ: { iata: "CZF", name: "Cape Romanzof LRRS Airport" },
    PADE: { iata: "DRG", name: "Deering Airport" },
    PADG: { iata: "RDB", name: "Red Dog Airport" },
    PADK: { iata: "ADK", name: "Adak Airport" },
    PADL: { iata: "DLG", name: "Dillingham Airport" },
    PADM: { iata: "MLL", name: "Marshall Don Hunter Sr Airport" },
    PADQ: { iata: "ADQ", name: "Kodiak Airport" },
    PADU: { iata: "DUT", name: "Unalaska Airport" },
    PADY: { iata: "KKH", name: "Kongiganak Airport" },
    PAED: { iata: "EDF", name: "Elmendorf Air Force Base" },
    PAEE: { iata: "EEK", name: "Eek Airport" },
    PAEG: { iata: "EAA", name: "Eagle Airport" },
    PAEH: { iata: "EHM", name: "Cape Newenham LRRS Airport" },
    PAEI: { iata: "EIL", name: "Eielson Air Force Base" },
    PAEM: { iata: "EMK", name: "Emmonak Airport" },
    PAEN: { iata: "ENA", name: "Kenai Municipal Airport" },
    PAEW: { iata: "WWT", name: "Newtok Airport" },
    PAFA: { iata: "FAI", name: "Fairbanks International Airport" },
    PAFB: { iata: "FBK", name: "Ladd AAF Airfield" },
    PAFE: { iata: "AFE", name: "Kake Airport" },
    PAFM: { iata: "ABL", name: "Ambler Airport" },
    PAFS: { iata: "NIB", name: "Nikolai Airport" },
    PAFV: { iata: "FMC", name: "Five Mile Airport" },
    PAFW: { iata: "FWL", name: "Farewell Airport" },
    PAGA: { iata: "GAL", name: "Edward G. Pitka Sr Airport" },
    PAGB: { iata: "GBH", name: "Galbraith Lake Airport" },
    PAGG: { iata: "KWK", name: "Kwigillingok Airport" },
    PAGK: { iata: "GKN", name: "Gulkana Airport" },
    PAGL: { iata: "GLV", name: "Golovin Airport" },
    PAGM: { iata: "GAM", name: "Gambell Airport" },
    PAGQ: { iata: "BGQ", name: "Big Lake Airport" },
    PAGS: { iata: "GST", name: "Gustavus Airport" },
    PAGT: { iata: "NME", name: "Nightmute Airport" },
    PAGY: { iata: "SGY", name: "Skagway Airport" },
    PAGZ: { iata: "GMT", name: "Granite Mountain Air Station" },
    PAHC: { iata: "HCR", name: "Holy Cross Airport" },
    PAHE: { iata: "HNE", name: "Tahneta Pass Airport" },
    PAHL: { iata: "HSL", name: "Huslia Airport" },
    PAHN: { iata: "HNS", name: "Haines Airport" },
    PAHO: { iata: "HOM", name: "Homer Airport" },
    PAHP: { iata: "HPB", name: "Hooper Bay Airport" },
    PAHU: { iata: "HUS", name: "Hughes Airport" },
    PAHX: { iata: "SHX", name: "Shageluk Airport" },
    PAIG: { iata: "IGG", name: "Igiugig Airport" },
    PAII: { iata: "EGX", name: "Egegik Airport" },
    PAIK: { iata: "IAN", name: "Bob Baker Memorial Airport" },
    PAIL: { iata: "ILI", name: "Iliamna Airport" },
    PAIM: { iata: "UTO", name: "Indian Mountain LRRS Airport" },
    PAIN: { iata: "MCL", name: "Mc Kinley National Park Airport" },
    PAIW: { iata: "WAA", name: "Wales Airport" },
    PAJN: { iata: "JNU", name: "Juneau International Airport" },
    PAJZ: { iata: "KGK", name: "Koliganek Airport" },
    PAKD: { iata: "KDK", name: "Kodiak Municipal Airport" },
    PAKF: { iata: "KFP", name: "False Pass Airport" },
    PAKH: { iata: "AKK", name: "Akhiok Airport" },
    PAKI: { iata: "KPN", name: "Kipnuk Airport" },
    PAKK: { iata: "KKA", name: "Koyuk Alfred Adams Airport" },
    PAKL: { iata: "LKK", name: "Kulik Lake Airport" },
    PAKN: { iata: "AKN", name: "King Salmon Airport" },
    PAKO: { iata: "IKO", name: "Nikolski Air Station" },
    PAKP: { iata: "AKP", name: "Anaktuvuk Pass Airport" },
    PAKT: { iata: "KTN", name: "Ketchikan International Airport" },
    PAKU: { iata: "UUK", name: "Ugnu-Kuparuk Airport" },
    PAKV: { iata: "KAL", name: "Kaltag Airport" },
    PAKW: { iata: "AKW", name: "Klawock Airport" },
    PAKY: { iata: "KYK", name: "Karluk Airport" },
    PALB: { iata: "KLN", name: "Larsen Bay Airport" },
    PALG: { iata: "KLG", name: "Kalskag Airport" },
    PALJ: { iata: "PTA", name: "Port Alsworth Airport" },
    PALN: { iata: "LNI", name: "Lonely Air Station" },
    PALP: { iata: "DQH", name: "Alpine Airstrip" },
    PALR: { iata: "WCR", name: "Chandalar Lake Airport" },
    PALU: { iata: "LUR", name: "Cape Lisburne LRRS Airport" },
    PAMB: { iata: "KMO", name: "Manokotak Airport" },
    PAMC: { iata: "MCG", name: "McGrath Airport" },
    PAMD: { iata: "MDO", name: "Middleton Island Airport" },
    PAMH: { iata: "LMA", name: "Minchumina Airport" },
    PAMK: { iata: "SMK", name: "St Michael Airport" },
    PAML: { iata: "MLY", name: "Manley Hot Springs Airport" },
    PAMO: { iata: "MOU", name: "Mountain Village Airport" },
    PAMR: { iata: "MRI", name: "Merrill Field" },
    PAMX: { iata: "MXY", name: "Mc Carthy Airport" },
    PAMY: { iata: "MYU", name: "Mekoryuk Airport" },
    PANA: { iata: "WNA", name: "Napakiak Airport" },
    PANC: { iata: "ANC", name: "Ted Stevens Anchorage International Airport" },
    PANI: { iata: "ANI", name: "Aniak Airport" },
    PANN: { iata: "ENN", name: "Nenana Municipal Airport" },
    PANO: { iata: "NNL", name: "Nondalton Airport" },
    PANT: { iata: "ANN", name: "Annette Island Airport" },
    PANU: { iata: "NUL", name: "Nulato Airport" },
    PANV: { iata: "ANV", name: "Anvik Airport" },
    PANW: { iata: "KNW", name: "New Stuyahok Airport" },
    PAOB: { iata: "OBU", name: "Kobuk Airport" },
    PAOC: { iata: "PCA", name: "Portage Creek Airport" },
    PAOH: { iata: "HNH", name: "Hoonah Airport" },
    PAOM: { iata: "OME", name: "Nome Airport" },
    PAOO: { iata: "OOK", name: "Toksook Bay Airport" },
    PAOR: { iata: "ORT", name: "Northway Airport" },
    PAOT: { iata: "OTZ", name: "Ralph Wien Memorial Airport" },
    PAOU: { iata: "NLG", name: "Nelson Lagoon Airport" },
    PAPB: { iata: "PBV", name: "St George Airport" },
    PAPC: { iata: "KPC", name: "Port Clarence Coast Guard Station" },
    PAPE: { iata: "KPV", name: "Perryville Airport" },
    PAPG: { iata: "PSG", name: "Petersburg James A Johnson Airport" },
    PAPH: { iata: "PTH", name: "Port Heiden Airport" },
    PAPK: { iata: "PKA", name: "Napaskiak Airport" },
    PAPM: { iata: "PTU", name: "Platinum Airport" },
    PAPN: { iata: "PIP", name: "Pilot Point Airport" },
    PAPO: { iata: "PHO", name: "Point Hope Airport" },
    PAPR: { iata: "PPC", name: "Prospect Creek Airport" },
    PAQH: { iata: "KWN", name: "Quinhagak Airport" },
    PAQT: { iata: "NUI", name: "Nuiqsut Airport" },
    PARC: { iata: "ARC", name: "Arctic Village Airport" },
    PARS: { iata: "RSH", name: "Russian Mission Airport" },
    PARY: { iata: "RBY", name: "Ruby Airport" },
    PASA: { iata: "SVA", name: "Savoonga Airport" },
    PASC: { iata: "SCC", name: "Deadhorse Airport" },
    PASD: { iata: "SDP", name: "Sand Point Airport" },
    PASH: { iata: "SHH", name: "Shishmaref Airport" },
    PASI: { iata: "SIT", name: "Sitka Rocky Gutierrez Airport" },
    PASK: { iata: "WLK", name: "Selawik Airport" },
    PASL: { iata: "SQL", name: "Sleetmute Airport" },
    PASM: { iata: "KSM", name: "St Mary's Airport" },
    PASN: { iata: "SNP", name: "St Paul Island Airport" },
    PASO: { iata: "SOV", name: "Seldovia Airport" },
    PASP: { iata: "SMU", name: "Sheep Mountain Airport" },
    PAST: { iata: "UMM", name: "Summit Airport" },
    PASV: { iata: "SVW", name: "Sparrevohn LRRS Airport" },
    PASW: { iata: "SKW", name: "Skwentna Airport" },
    PASX: { iata: "SXQ", name: "Soldotna Airport" },
    PASY: { iata: "SYA", name: "Eareckson Air Station" },
    PATA: { iata: "TAL", name: "Ralph M Calhoun Memorial Airport" },
    PATC: { iata: "TNC", name: "Tin City Long Range Radar Station Airport" },
    PATE: { iata: "TLA", name: "Teller Airport" },
    PATG: { iata: "TOG", name: "Togiak Airport" },
    PATJ: { iata: "TKJ", name: "Tok Airport" },
    PATK: { iata: "TKA", name: "Talkeetna Airport" },
    PATL: { iata: "TLJ", name: "Tatalina LRRS Airport" },
    PATQ: { iata: "ATK", name: "Atqasuk Edward Burnell Sr Memorial Airport" },
    PAUK: { iata: "AUK", name: "Alakanuk Airport" },
    PAUM: { iata: "UMT", name: "Umiat Airport" },
    PAUN: { iata: "UNK", name: "Unalakleet Airport" },
    PAUO: { iata: "WOW", name: "Willow Airport" },
    PAVA: { iata: "VAK", name: "Chevak Airport" },
    PAVC: { iata: "KVC", name: "King Cove Airport" },
    PAVD: { iata: "VDZ", name: "Valdez Pioneer Field" },
    PAVE: { iata: "VEE", name: "Venetie Airport" },
    PAVL: { iata: "KVL", name: "Kivalina Airport" },
    PAWB: { iata: "WBQ", name: "Beaver Airport" },
    PAWD: { iata: "SWD", name: "Seward Airport" },
    PAWG: { iata: "WRG", name: "Wrangell Airport" },
    PAWI: { iata: "AIN", name: "Wainwright Airport" },
    PAWM: { iata: "WMO", name: "White Mountain Airport" },
    PAWN: { iata: "WTK", name: "Noatak Airport" },
    PAWS: { iata: "IYS", name: "Wasilla Airport" },
    PAYA: { iata: "YAK", name: "Yakutat Airport" },
    PCIS: { iata: "CIS", name: "Canton Airport" },
    PFAK: { iata: "AKI", name: "Akiak Airport" },
    PFAL: { iata: "AET", name: "Allakaket Airport" },
    PFCB: { iata: "NCN", name: "Chenega Bay Airport" },
    PFCL: { iata: "CLP", name: "Clarks Point Airport" },
    PFEL: { iata: "ELI", name: "Elim Airport" },
    PFKA: { iata: "KUK", name: "Kasigluk Airport" },
    PFKK: { iata: "KNK", name: "Kokhanok Airport" },
    PFKO: { iata: "KOT", name: "Kotlik Airport" },
    PFKT: { iata: "KTS", name: "Brevig Mission Airport" },
    PFKU: { iata: "KYU", name: "Koyukuk Airport" },
    PFKW: { iata: "KWT", name: "Kwethluk Airport" },
    PFNO: { iata: "ORV", name: "Robert (Bob) Curtis Memorial Airport" },
    PFSH: { iata: "SKK", name: "Shaktoolik Airport" },
    PFTO: { iata: "TKJ", name: "Tok Junction Airport" },
    PFWS: { iata: "WSN", name: "South Naknek Nr 2 Airport" },
    PFYU: { iata: "FYU", name: "Fort Yukon Airport" },
    PGRO: { iata: "ROP", name: "Rota International Airport" },
    PGSN: { iata: "SPN", name: "Francisco C. Ada Saipan International Airport" },
    PGUA: { iata: "UAM", name: "Andersen Air Force Base" },
    PGUM: { iata: "GUM", name: "Antonio B. Won Pat International Airport" },
    PGWT: { iata: "TIQ", name: "Tinian International Airport" },
    PHBK: { iata: "BKH", name: "Barking Sands Airport" },
    PHDH: { iata: "HDH", name: "Dillingham Airfield" },
    PHHI: { iata: "HHI", name: "Wheeler Army Airfield" },
    PHHN: { iata: "HNM", name: "Hana Airport" },
    PHJH: { iata: "JHM", name: "Kapalua Airport" },
    PHJR: { iata: "JRF", name: "Kalaeloa (John Rodgers Field) Airport" },
    PHKO: { iata: "KOA", name: "Kona International At Keahole Airport" },
    PHLI: { iata: "LIH", name: "Lihue Airport" },
    PHLU: { iata: "LUP", name: "Kalaupapa Airport" },
    PHMK: { iata: "MKK", name: "Molokai Airport" },
    PHMU: { iata: "MUE", name: "Waimea Kohala Airport" },
    PHNG: {
      iata: "NGF",
      name: "Kaneohe Bay MCAS (Marion E. Carl Field) Airport",
    },
    PHNL: { iata: "HNL", name: "Honolulu International Airport" },
    PHNY: { iata: "LNY", name: "Lanai Airport" },
    PHOG: { iata: "OGG", name: "Kahului Airport" },
    PHPA: { iata: "PAK", name: "Port Allen Airport" },
    PHSF: { iata: "BSF", name: "Bradshaw Army Airfield" },
    PHTO: { iata: "ITO", name: "Hilo International Airport" },
    PHUP: { iata: "UPP", name: "Upolu Airport" },
    PKMA: { iata: "ENT", name: "Eniwetok Airport" },
    PKMJ: { iata: "MAJ", name: "Marshall Islands International Airport" },
    PKSA: { iata: "KIA", name: "Kaieteur International Airport" },
    PKWA: { iata: "KWA", name: "Bucholz Army Air Field" },
    PLCH: { iata: "CXI", name: "Cassidy International Airport" },
    PLFA: { iata: "TNV", name: "Tabuaeran Island Airport" },
    PMDY: { iata: "MDY", name: "Henderson Field" },
    PPIZ: { iata: "PIZ", name: "Point Lay Lrrs Airport" },
    PTKK: { iata: "TKK", name: "Chuuk International Airport" },
    PTPN: { iata: "PNI", name: "Pohnpei International Airport" },
    PTRO: { iata: "ROR", name: "Babelthuap Airport" },
    PTSA: { iata: "KSA", name: "Kosrae International Airport" },
    PTYA: { iata: "YAP", name: "Yap International Airport" },
    PWAK: { iata: "AWK", name: "Wake Island Airfield" },
    RCBS: { iata: "KNH", name: "Kinmen Airport" },
    RCDC: { iata: "PIF", name: "Pingtung South Airport" },
    RCFG: { iata: "LZN", name: "Matsu Nangan Airport" },
    RCFN: { iata: "TTT", name: "Taitung Airport" },
    RCGI: { iata: "GNI", name: "Lyudao Airport" },
    RCKH: { iata: "KHH", name: "Kaohsiung International Airport" },
    RCKU: { iata: "CYI", name: "Chiayi Airport" },
    RCKW: { iata: "HCN", name: "Hengchun Airport" },
    RCLG: { iata: "TXG", name: "Taichung Airport" },
    RCLY: { iata: "KYD", name: "Lanyu Airport" },
    RCMQ: { iata: "RMQ", name: "Taichung Ching Chuang Kang Airport" },
    RCMT: { iata: "MFK", name: "Matsu Beigan Airport" },
    RCNN: { iata: "TNN", name: "Tainan Airport" },
    RCPO: { iata: "HSZ", name: "Hsinchu Air Base" },
    RCQC: { iata: "MZG", name: "Makung Airport" },
    RCSQ: { iata: "PIF", name: "Pingtung North Airport" },
    RCSS: { iata: "TSA", name: "Taipei Songshan Airport" },
    RCTP: { iata: "TPE", name: "Taiwan Taoyuan International Airport" },
    RCWA: { iata: "WOT", name: "Wang-an Airport" },
    RCYU: { iata: "HUN", name: "Hualien Airport" },
    RJAA: { iata: "NRT", name: "Narita International Airport" },
    RJAF: { iata: "MMJ", name: "Matsumoto Airport" },
    RJAH: { iata: "IBR", name: "Hyakuri Airport" },
    RJAM: { iata: "MUS", name: "Minami Torishima Airport" },
    RJAW: { iata: "IWO", name: "Iwo Jima Airport" },
    RJBB: { iata: "KIX", name: "Kansai International Airport" },
    RJBD: { iata: "SHM", name: "Nanki Shirahama Airport" },
    RJBE: { iata: "UKB", name: "Kobe Airport" },
    RJBH: { iata: "HIW", name: "Hiroshimanishi Airport" },
    RJBT: { iata: "TJH", name: "Tajima Airport" },
    RJCB: { iata: "OBO", name: "Tokachi-Obihiro Airport" },
    RJCC: { iata: "CTS", name: "New Chitose Airport" },
    RJCH: { iata: "HKD", name: "Hakodate Airport" },
    RJCK: { iata: "KUH", name: "Kushiro Airport" },
    RJCM: { iata: "MMB", name: "Memanbetsu Airport" },
    RJCN: { iata: "SHB", name: "Nakashibetsu Airport" },
    RJCO: { iata: "OKD", name: "Okadama Airport" },
    RJCR: { iata: "RBJ", name: "Rebun Airport Airport" },
    RJCW: { iata: "WKJ", name: "Wakkanai Airport" },
    RJDA: { iata: "AXJ", name: "Amakusa Airport" },
    RJDB: { iata: "IKI", name: "Iki Airport" },
    RJDC: { iata: "UBJ", name: "Yamaguchi Ube Airport" },
    RJDT: { iata: "TSJ", name: "Tsushima Airport" },
    RJEB: { iata: "MBE", name: "Monbetsu Airport" },
    RJEC: { iata: "AKJ", name: "Asahikawa Airport" },
    RJEO: { iata: "OIR", name: "Okushiri Airport" },
    RJER: { iata: "RIS", name: "Rishiri Airport" },
    RJFC: { iata: "KUM", name: "Yakushima Airport" },
    RJFE: { iata: "FUJ", name: "Fukue Airport" },
    RJFF: { iata: "FUK", name: "Fukuoka Airport" },
    RJFG: { iata: "TNE", name: "New Tanegashima Airport" },
    RJFK: { iata: "KOJ", name: "Kagoshima Airport" },
    RJFM: { iata: "KMI", name: "Miyazaki Airport" },
    RJFO: { iata: "OIT", name: "Oita Airport" },
    RJFR: { iata: "KKJ", name: "Kitakyushu Airport" },
    RJFS: { iata: "HSG", name: "Saga Airport" },
    RJFT: { iata: "KMJ", name: "Kumamoto Airport" },
    RJFU: { iata: "NGS", name: "Nagasaki Airport" },
    RJGG: { iata: "NGO", name: "Chubu Centrair International Airport" },
    RJKA: { iata: "ASJ", name: "Amami Airport" },
    RJKB: { iata: "OKE", name: "Okierabu Airport" },
    RJKI: { iata: "KKX", name: "Kikai Airport" },
    RJKN: { iata: "TKN", name: "Tokunoshima Airport" },
    RJNA: { iata: "NKM", name: "Nagoya Airport" },
    RJNF: { iata: "FKJ", name: "Fukui Airport" },
    RJNG: { iata: "QGU", name: "Gifu Airport" },
    RJNK: { iata: "KMQ", name: "Komatsu Airport" },
    RJNO: { iata: "OKI", name: "Oki Airport" },
    RJNS: { iata: "FSZ", name: "Mt. Fuji Shizuoka Airport" },
    RJNT: { iata: "TOY", name: "Toyama Airport" },
    RJNW: { iata: "NTQ", name: "Noto Airport" },
    RJOA: { iata: "HIJ", name: "Hiroshima Airport" },
    RJOB: { iata: "OKJ", name: "Okayama Airport" },
    RJOC: { iata: "IZO", name: "Izumo Airport" },
    RJOH: { iata: "YGJ", name: "Miho Yonago Airport" },
    RJOI: { iata: "IWK", name: "Iwakuni Marine Corps Air Station" },
    RJOK: { iata: "KCZ", name: "Kochi Ryoma Airport" },
    RJOM: { iata: "MYJ", name: "Matsuyama Airport" },
    RJOO: { iata: "ITM", name: "Osaka International Airport" },
    RJOR: { iata: "TTJ", name: "Tottori Airport" },
    RJOS: { iata: "TKS", name: "Tokushima Airport" },
    RJOT: { iata: "TAK", name: "Takamatsu Airport" },
    RJOW: { iata: "IWJ", name: "Iwami Airport" },
    RJSA: { iata: "AOJ", name: "Aomori Airport" },
    RJSC: { iata: "GAJ", name: "Yamagata Airport" },
    RJSD: { iata: "SDS", name: "Sado Airport" },
    RJSF: { iata: "FKS", name: "Fukushima Airport" },
    RJSH: { iata: "HHE", name: "Hachinohe Airport" },
    RJSI: { iata: "HNA", name: "Hanamaki Airport" },
    RJSK: { iata: "AXT", name: "Akita Airport" },
    RJSM: { iata: "MSJ", name: "Misawa Air Base" },
    RJSN: { iata: "KIJ", name: "Niigata Airport" },
    RJSR: { iata: "ONJ", name: "Odate Noshiro Airport" },
    RJSS: { iata: "SDJ", name: "Sendai Airport" },
    RJSY: { iata: "SYO", name: "Shonai Airport" },
    RJTA: { iata: "NJA", name: "Atsugi Naval Air Facility" },
    RJTH: { iata: "HAC", name: "Hachijojima Airport" },
    RJTO: { iata: "OIM", name: "Oshima Airport" },
    RJTQ: { iata: "MYE", name: "Miyakejima Airport" },
    RJTT: { iata: "HND", name: "Tokyo International Airport" },
    RJTU: { iata: "QUT", name: "Utsunomiya Airport" },
    RJTY: { iata: "OKO", name: "Yokota Air Base" },
    RKJB: { iata: "MWX", name: "Muan International Airport" },
    RKJJ: { iata: "KWJ", name: "Gwangju Airport" },
    RKJK: { iata: "KUV", name: "Kunsan Air Base" },
    RKJU: { iata: "CHN", name: "Jeon Ju Airport" },
    RKJY: { iata: "RSU", name: "Yeosu Airport" },
    RKNC: { iata: "QUN", name: "A-306 Airport" },
    RKND: { iata: "SHO", name: "Sokcho Airport" },
    RKNN: { iata: "KAG", name: "Gangneung Airport" },
    RKNW: { iata: "WJU", name: "Wonju Airport" },
    RKNY: { iata: "YNY", name: "Yangyang International Airport" },
    RKPC: { iata: "CJU", name: "Jeju International Airport" },
    RKPD: { iata: "JDG", name: "Jeongseok Airport" },
    RKPE: { iata: "CHF", name: "Jinhae Airport" },
    RKPK: { iata: "PUS", name: "Gimhae International Airport" },
    RKPS: { iata: "HIN", name: "Sacheon Air Base" },
    RKPU: { iata: "USN", name: "Ulsan Airport" },
    RKSI: { iata: "ICN", name: "Incheon International Airport" },
    RKSM: { iata: "SSN", name: "Seoul Air Base" },
    RKSO: { iata: "OSN", name: "Osan Air Base" },
    RKSS: { iata: "GMP", name: "Gimpo International Airport" },
    RKSW: { iata: "SWU", name: "Suwon Airport" },
    RKTH: { iata: "KPO", name: "Pohang Airport" },
    RKTI: { iata: "JWO", name: "Jungwon Air Base" },
    RKTN: { iata: "TAE", name: "Daegu Airport" },
    RKTP: { iata: "HMY", name: "Seosan Air Base" },
    RKTU: { iata: "CJJ", name: "Cheongju International Airport" },
    RKTY: { iata: "YEC", name: "Yecheon Airport" },
    ROAH: { iata: "OKA", name: "Naha Airport" },
    RODN: { iata: "DNA", name: "Kadena Air Base" },
    ROIG: { iata: "ISG", name: "Ishigaki Airport" },
    ROKJ: { iata: "UEO", name: "Kumejima Airport" },
    ROKR: { iata: "KJP", name: "Kerama Airport" },
    ROMD: { iata: "MMD", name: "Minami Daito Airport" },
    ROMY: { iata: "MMY", name: "Miyako Airport" },
    RORA: { iata: "AGJ", name: "Aguni Airport" },
    RORE: { iata: "IEJ", name: "Ie Jima Airport" },
    RORH: { iata: "HTR", name: "Hateruma Airport" },
    RORK: { iata: "KTD", name: "Kitadaito Airport" },
    RORS: { iata: "SHI", name: "Shimojishima Airport" },
    RORT: { iata: "TRA", name: "Tarama Airport" },
    RORY: { iata: "RNJ", name: "Yoron Airport" },
    ROYN: { iata: "OGN", name: "Yonaguni Airport" },
    RPLB: { iata: "SFS", name: "Subic Bay International Airport" },
    RPLC: { iata: "CRK", name: "Diosdado Macapagal International Airport" },
    RPLI: { iata: "LAO", name: "Laoag International Airport" },
    RPLL: { iata: "MNL", name: "Ninoy Aquino International Airport" },
    RPLO: { iata: "CYU", name: "Cuyo Airport" },
    RPLP: { iata: "LGP", name: "Legazpi City International Airport" },
    RPLS: { iata: "NSP", name: "Sangley Point Air Base" },
    RPLU: { iata: "LBX", name: "Lubang Airport" },
    RPMA: { iata: "AAV", name: "Allah Valley Airport" },
    RPMB: { iata: "GES", name: "General Santos International Airport" },
    RPMC: { iata: "CBO", name: "Awang Airport" },
    RPMD: { iata: "DVO", name: "Francisco Bangoy International Airport" },
    RPME: { iata: "BXU", name: "Bancasi Airport" },
    RPMF: { iata: "BPH", name: "Bislig Airport" },
    RPMG: { iata: "DPL", name: "Dipolog Airport" },
    RPMH: { iata: "CGM", name: "Camiguin Airport" },
    RPMI: { iata: "IGN", name: "Iligan Airport" },
    RPMJ: { iata: "JOL", name: "Jolo Airport" },
    RPML: { iata: "CGY", name: "Cagayan De Oro Airport" },
    RPMM: { iata: "MLP", name: "Malabang Airport" },
    RPMN: { iata: "SGS", name: "Sanga Sanga Airport" },
    RPMO: { iata: "OZC", name: "Labo Airport" },
    RPMP: { iata: "PAG", name: "Pagadian Airport" },
    RPMQ: { iata: "MXI", name: "Mati National Airport" },
    RPMR: { iata: "GES", name: "Tambler Airport" },
    RPMS: { iata: "SUG", name: "Surigao Airport" },
    RPMU: { iata: "CDY", name: "Cagayan de Sulu Airport" },
    RPMV: { iata: "IPE", name: "Ipil Airport" },
    RPMW: { iata: "TDG", name: "Tandag Airport" },
    RPMY: { iata: "CGY", name: "Malaybalay Airport" },
    RPMZ: { iata: "ZAM", name: "Zamboanga International Airport" },
    RPNS: { iata: "IAO", name: "Siargao Airport" },
    RPSD: { iata: "RZP", name: "Cesar Lim Rodriguez Airport" },
    RPUB: { iata: "BAG", name: "Loakan Airport" },
    RPUD: { iata: "DTE", name: "Daet Airport" },
    RPUH: { iata: "SJI", name: "San Jose Airport" },
    RPUM: { iata: "MBO", name: "Mamburao Airport" },
    RPUN: { iata: "WNP", name: "Naga Airport" },
    RPUO: { iata: "BSO", name: "Basco Airport" },
    RPUR: { iata: "BQA", name: "Dr.Juan C. Angara Airport" },
    RPUS: { iata: "SFE", name: "San Fernando Airport" },
    RPUT: { iata: "TUG", name: "Tuguegarao Airport" },
    RPUV: { iata: "VRC", name: "Virac Airport" },
    RPUW: { iata: "MRQ", name: "Marinduque Airport" },
    RPUY: { iata: "CYZ", name: "Cauayan Airport" },
    RPVA: { iata: "TAC", name: "Daniel Z. Romualdez Airport" },
    RPVB: { iata: "BCD", name: "Bacolod-Silay City International Airport" },
    RPVC: { iata: "CYP", name: "Calbayog Airport" },
    RPVD: { iata: "DGT", name: "Sibulan Airport" },
    RPVE: { iata: "MPH", name: "Godofredo P. Ramos Airport" },
    RPVF: { iata: "CRM", name: "Catarman National Airport" },
    RPVI: { iata: "ILO", name: "Iloilo International Airport" },
    RPVJ: { iata: "MBT", name: "Moises R. Espinosa Airport" },
    RPVK: { iata: "KLO", name: "Kalibo International Airport" },
    RPVM: { iata: "CEB", name: "Mactan Cebu International Airport" },
    RPVO: { iata: "OMC", name: "Ormoc Airport" },
    RPVP: { iata: "PPS", name: "Puerto Princesa Airport" },
    RPVR: { iata: "RXS", name: "Roxas Airport" },
    RPVS: { iata: "EUQ", name: "Evelio Javier Airport" },
    RPVT: { iata: "TAG", name: "Tagbilaran Airport" },
    RPVU: { iata: "TBH", name: "Romblon Airport" },
    RPVV: { iata: "USU", name: "Francisco B. Reyes Airport" },
    RPVW: { iata: "BPR", name: "Borongan Airport" },
    RPXX: { iata: "ENI", name: "El Nido Airport" },
    SA30: { iata: "CCT", name: "Colonia Catriel Airport" },
    SAAC: { iata: "COC", name: "Comodoro Pierrestegui Airport" },
    SAAG: { iata: "GHU", name: "Gualeguaychu Airport" },
    SAAJ: { iata: "JNI", name: "Junin Airport" },
    SAAP: { iata: "PRA", name: "General Urquiza Airport" },
    SAAR: { iata: "ROS", name: "Islas Malvinas Airport" },
    SAAV: { iata: "SFN", name: "Sauce Viejo Airport" },
    SABE: { iata: "AEP", name: "Jorge Newbery Airpark" },
    SACC: { iata: "LCM", name: "La Cumbre Airport" },
    SACO: { iata: "COR", name: "Ingeniero Ambrosio Taravella Airport" },
    SADF: { iata: "FDO", name: "San Fernando Airport" },
    SADL: { iata: "LPG", name: "La Plata Airport" },
    SADP: { iata: "EPA", name: "El Palomar Airport" },
    SAEM: { iata: "MJR", name: "Miramar Airport" },
    SAEZ: { iata: "EZE", name: "Ministro Pistarini International Airport" },
    SAFR: { iata: "RAF", name: "Rafaela Airport" },
    SAFS: { iata: "NCJ", name: "Sunchales Aeroclub Airport" },
    SAHC: { iata: "HOS", name: "Chos Malal Airport" },
    SAHE: { iata: "CVH", name: "Caviahue Airport" },
    SAHR: { iata: "GNR", name: "Dr. Arturo H. Illia Airport" },
    SAHS: { iata: "RDS", name: "Rincon De Los Sauces Airport" },
    SAHZ: { iata: "APZ", name: "Zapala Airport" },
    SAME: { iata: "MDZ", name: "El Plumerillo Airport" },
    SAMM: { iata: "LGS", name: "Comodoro D.R. Salomon Airport" },
    SAMR: { iata: "AFA", name: "Suboficial Ay Santiago Germano Airport" },
    SANC: { iata: "CTC", name: "Catamarca Airport" },
    SANE: { iata: "SDE", name: "Vicecomodoro Angel D. La Paz Aragones Airport" },
    SANH: { iata: "RHD", name: "Las Termas Airport" },
    SANL: { iata: "IRJ", name: "Capitan V A Almonacid Airport" },
    SANT: { iata: "TUC", name: "Teniente Benjamin Matienzo Airport" },
    SANU: { iata: "UAQ", name: "Domingo Faustino Sarmiento Airport" },
    SANW: { iata: "CRR", name: "Ceres Airport" },
    SAOC: { iata: "RCU", name: "Area De Material Airport" },
    SAOD: { iata: "VDR", name: "Villa Dolores Airport" },
    SAOR: { iata: "VME", name: "Villa Reynolds Airport" },
    SAOS: { iata: "RLO", name: "Valle Del Conlara International Airport" },
    SAOU: { iata: "LUQ", name: "Brigadier Mayor D Cesar Raul Ojeda Airport" },
    SARC: { iata: "CNQ", name: "Corrientes Airport" },
    SARE: { iata: "RES", name: "Resistencia International Airport" },
    SARF: { iata: "FMA", name: "Formosa Airport" },
    SARI: { iata: "IGR", name: "Cataratas Del Iguazu International Airport" },
    SARL: { iata: "AOL", name: "Paso De Los Libres Airport" },
    SARM: { iata: "MCS", name: "Monte Caseros Airport" },
    SARP: { iata: "PSS", name: "Libertador Gral D Jose De San Martin Airport" },
    SARS: { iata: "PRQ", name: "Termal Airport" },
    SASA: { iata: "SLA", name: "Martin Miguel De Guemes International Airport" },
    SASJ: {
      iata: "JUJ",
      name: "Gobernador Horacio Guzman International Airport",
    },
    SASO: { iata: "ORA", name: "Oran Airport" },
    SAST: { iata: "TTG", name: "General Enrique Mosconi Airport" },
    SATC: { iata: "CLX", name: "Clorinda Airport" },
    SATD: { iata: "ELO", name: "El Dorado Airport" },
    SATG: { iata: "OYA", name: "Goya Airport" },
    SATK: { iata: "LLS", name: "Alferez Armando Rodriguez Airport" },
    SATM: { iata: "MDX", name: "Mercedes Airport" },
    SATR: { iata: "RCQ", name: "Reconquista Airport" },
    SATU: { iata: "UZU", name: "Curuzu Cuatia Airport" },
    SAVB: { iata: "EHL", name: "El Bolson Airport" },
    SAVC: { iata: "CRD", name: "General E. Mosconi Airport" },
    SAVD: { iata: "EMX", name: "El Maiten Airport" },
    SAVE: { iata: "EQS", name: "Brigadier Antonio Parodi Airport" },
    SAVH: { iata: "LHS", name: "Las Heras Airport" },
    SAVJ: { iata: "IGB", name: "Cabo F.A.A. H. R. Bordon Airport" },
    SAVM: { iata: "OLN", name: "Lago Musters Airport" },
    SAVN: { iata: "OES", name: "Antoine De St Exupery Airport" },
    SAVQ: { iata: "MQD", name: "Maquinchao Airport" },
    SAVR: { iata: "ARR", name: "D. Casimiro Szlapelis Airport" },
    SAVS: { iata: "SGV", name: "Sierra Grande Airport" },
    SAVT: { iata: "REL", name: "Almirante Marco Andres Zar Airport" },
    SAVV: { iata: "VDM", name: "Gobernador Castello Airport" },
    SAVY: { iata: "PMY", name: "El Tehuelche Airport" },
    SAWA: { iata: "ING", name: "Lago Argentino Airport" },
    SAWC: { iata: "FTE", name: "El Calafate Airport" },
    SAWD: { iata: "PUD", name: "Puerto Deseado Airport" },
    SAWE: { iata: "RGA", name: "Hermes Quijada International Airport" },
    SAWG: { iata: "RGL", name: "Piloto Civil N. Fernandez Airport" },
    SAWH: { iata: "USH", name: "Malvinas Argentinas Airport" },
    SAWJ: { iata: "ULA", name: "Capitan D Daniel Vazquez Airport" },
    SAWM: { iata: "ROY", name: "Rio Mayo Airport" },
    SAWP: { iata: "PMQ", name: "Perito Moreno Airport" },
    SAWR: { iata: "GGS", name: "Gobernador Gregores Airport" },
    SAWS: { iata: "JSM", name: "Jose De San Martin Airport" },
    SAWT: { iata: "RYO", name: "28 De Noviembre Airport" },
    SAWU: { iata: "RZA", name: "Santa Cruz Airport" },
    SAZB: { iata: "BHI", name: "Comandante Espora Airport" },
    SAZC: { iata: "CSZ", name: "Brigadier D.H.E. Ruiz Airport" },
    SAZF: { iata: "OVR", name: "Olavarria Airport" },
    SAZG: { iata: "GPO", name: "General Pico Airport" },
    SAZH: { iata: "OYO", name: "Tres Arroyos Airport" },
    SAZL: { iata: "SST", name: "Santa Teresita Airport" },
    SAZM: { iata: "MDQ", name: "Astor Piazzola International Airport" },
    SAZN: { iata: "NQN", name: "Presidente Peron Airport" },
    SAZO: { iata: "NEC", name: "Necochea Airport" },
    SAZP: { iata: "PEH", name: "Comodoro Pedro Zanni Airport" },
    SAZR: { iata: "RSA", name: "Santa Rosa Airport" },
    SAZS: { iata: "BRC", name: "San Carlos De Bariloche Airport" },
    SAZT: { iata: "TDL", name: "Heroes De Malvinas Airport" },
    SAZV: { iata: "VLG", name: "Villa Gesell Airport" },
    SAZW: { iata: "CUT", name: "Cutral-Co Airport" },
    SAZY: { iata: "CPC", name: "Aviador C. Campos Airport" },
    SBAA: { iata: "CDJ", name: "Conceicao do Araguaia Airport" },
    SBAE: { iata: "JTC", name: "Bauru-Arealva Airport" },
    SBAF: { iata: "0", name: "Campo Delio Jardim de Mattos Airport" },
    SBAM: { iata: "0", name: "Amapa Airport" },
    SBAQ: { iata: "AQA", name: "Araraquara Airport" },
    SBAR: { iata: "AJU", name: "Santa Maria Airport" },
    SBAS: { iata: "AIF", name: "Marcelo Pires Halzhausen Airport" },
    SBAT: { iata: "AFL", name: "Alta Floresta Airport" },
    SBAU: { iata: "ARU", name: "Aracatuba Airport" },
    SBAX: { iata: "AAX", name: "Araxa Airport" },
    SBBE: {
      iata: "BEL",
      name: "Val de Cans/Julio Cezar Ribeiro International Airport",
    },
    SBBG: { iata: "BGX", name: "Comandante Gustavo Kraemer Airport" },
    SBBH: { iata: "PLU", name: "Pampulha - Carlos Drummond de Andrade Airport" },
    SBBI: { iata: "BFH", name: "Bacacheri Airport" },
    SBBP: { iata: "BJP", name: "Aeroporto Estadual Arthur Siqueira Airport" },
    SBBQ: { iata: "QAK", name: "Major Brigadeiro Doorgal Borges Airport" },
    SBBR: {
      iata: "BSB",
      name: "Presidente Juscelino Kubistschek International Airport",
    },
    SBBT: { iata: "BAT", name: "Chafei Amsei Airport" },
    SBBU: { iata: "BAU", name: "Bauru Airport" },
    SBBV: { iata: "BVB", name: "Atlas Brasil Cantanhede Airport" },
    SBBW: { iata: "BPG", name: "Barra do Garcas Airport" },
    SBBZ: { iata: "BZC", name: "Umberto Modiano Airport" },
    SBCA: { iata: "CAC", name: "Cascavel Airport" },
    SBCB: { iata: "CFB", name: "Cabo Frio Airport" },
    SBCD: { iata: "0", name: "Cacador Airport" },
    SBCF: { iata: "CNF", name: "Tancredo Neves International Airport" },
    SBCG: { iata: "CGR", name: "Campo Grande Airport" },
    SBCH: { iata: "XAP", name: "Chapeco Airport" },
    SBCI: { iata: "CLN", name: "Brig. Lysias Augusto Rodrigues Airport" },
    SBCJ: { iata: "CKS", name: "Carajas Airport" },
    SBCM: { iata: "CCM", name: "Forquilhinha - Criciuma Airport" },
    SBCN: { iata: "CLV", name: "Caldas Novas Airport" },
    SBCO: { iata: "QNS", name: "Canoas Airport" },
    SBCP: { iata: "CAW", name: "Bartolomeu Lisandro Airport" },
    SBCR: { iata: "CMG", name: "Corumba International Airport" },
    SBCT: { iata: "CWB", name: "Afonso Pena Airport" },
    SBCV: { iata: "CRQ", name: "Caravelas Airport" },
    SBCX: { iata: "CXJ", name: "Campo dos Bugres Airport" },
    SBCY: { iata: "CGB", name: "Marechal Rondon Airport" },
    SBCZ: { iata: "CZS", name: "Cruzeiro do Sul Airport" },
    SBDB: { iata: "BYO", name: "Bonito Airport" },
    SBDN: { iata: "PPB", name: "Presidente Prudente Airport" },
    SBEG: { iata: "MAO", name: "Eduardo Gomes International Airport" },
    SBEK: { iata: "JCR", name: "Jacareacanga Airport" },
    SBEP: { iata: "ESI", name: "Espinosa Airport" },
    SBES: { iata: "0", name: "Sao Pedro da Aldeia Airport" },
    SBFI: { iata: "IGU", name: "Cataratas International Airport" },
    SBFL: { iata: "FLN", name: "Hercilio Luz International Airport" },
    SBFN: { iata: "FEN", name: "Fernando de Noronha Airport" },
    SBFU: { iata: "0", name: "Furnas Airport" },
    SBFZ: { iata: "FOR", name: "Pinto Martins International Airport" },
    SBGL: {
      iata: "GIG",
      name: "Galeao - Antonio Carlos Jobim International Airport",
    },
    SBGM: { iata: "GJM", name: "Guajara-Mirim Airport" },
    SBGO: { iata: "GYN", name: "Santa Genoveva Airport" },
    SBGP: { iata: "0", name: "EMBRAER - Unidade Gaviao Peixoto Airport" },
    SBGR: {
      iata: "GRU",
      name: "Guarulhos - Governador Andre Franco Montoro International Airport",
    },
    SBGU: { iata: "GPB", name: "Tancredo Thomas de Faria Airport" },
    SBGV: { iata: "GVR", name: "Governador Valadares Airport" },
    SBGW: { iata: "GUJ", name: "Guaratingueta Airport" },
    SBHT: { iata: "ATM", name: "Altamira Airport" },
    SBIC: { iata: "ITA", name: "Itacoatiara Airport" },
    SBIH: { iata: "ITB", name: "Itaituba Airport" },
    SBIL: { iata: "IOS", name: "Bahia - Jorge Amado Airport" },
    SBIP: { iata: "IPN", name: "Usiminas Airport" },
    SBIT: { iata: "0", name: "Hidroeletrica Airport" },
    SBIZ: { iata: "IMP", name: "Prefeito Renato Moreira Airport" },
    SBJC: { iata: "0", name: "Julio Cesar Airport" },
    SBJE: { iata: "JJD", name: "Comte. Ariston Pessoa Regional Airport" },
    SBJF: { iata: "JDF", name: "Francisco de Assis Airport" },
    SBJP: { iata: "JPA", name: "Presidente Castro Pinto Airport" },
    SBJU: { iata: "JDO", name: "Orlando Bezerra de Menezes Airport" },
    SBJV: { iata: "JOI", name: "Lauro Carneiro de Loyola Airport" },
    SBKG: { iata: "CPV", name: "Presidente Joao Suassuna Airport" },
    SBKP: { iata: "VCP", name: "Viracopos International Airport" },
    SBLE: { iata: "LEC", name: "Chapada Diamantina Airport" },
    SBLJ: { iata: "LAJ", name: "Lages Airport" },
    SBLN: { iata: "LIP", name: "Lins Airport" },
    SBLO: { iata: "LDB", name: "Governador Jose Richa Airport" },
    SBLP: { iata: "LAZ", name: "Bom Jesus da Lapa Airport" },
    SBLS: { iata: "0", name: "Lagoa Santa Airport" },
    SBMA: { iata: "MAB", name: "Maraba Airport" },
    SBMC: { iata: "MQH", name: "Minacu Airport" },
    SBMD: { iata: "MEU", name: "Monte Dourado Airport" },
    SBME: { iata: "MEA", name: "Macae Airport" },
    SBMG: {
      iata: "MGF",
      name: "Regional de Maringa - Silvio Nane Junior Airport",
    },
    SBMK: { iata: "MOC", name: "Mario Ribeiro Airport" },
    SBML: { iata: "MII", name: "Marilia Airport" },
    SBMN: { iata: "PLL", name: "Ponta Pelada Airport" },
    SBMO: { iata: "MCZ", name: "Zumbi dos Palmares Airport" },
    SBMQ: { iata: "MCP", name: "Alberto Alcolumbre Airport" },
    SBMS: { iata: "MVF", name: "Dix-Sept Rosado Airport" },
    SBMT: { iata: "SAO", name: "Campo de Marte Airport" },
    SBMY: { iata: "MNX", name: "Manicore Airport" },
    SBNF: { iata: "NVT", name: "Ministro Victor Konder International Airport" },
    SBNM: { iata: "GEL", name: "Santo Angelo Airport" },
    SBNT: { iata: "NAT", name: "Augusto Severo Airport" },
    SBOI: { iata: "OYK", name: "Oiapoque Airport" },
    SBPA: { iata: "POA", name: "Salgado Filho Airport" },
    SBPB: { iata: "PHB", name: "Prefeito Doutor Joao Silva Filho Airport" },
    SBPC: { iata: "POO", name: "Pocos de Caldas Airport" },
    SBPF: { iata: "PFB", name: "Lauro Kurtz Airport" },
    SBPJ: { iata: "PMW", name: "Brigadeiro Lysias Rodrigues Airport" },
    SBPK: { iata: "PET", name: "Pelotas Airport" },
    SBPL: { iata: "PNZ", name: "Senador Nilo Coelho Airport" },
    SBPN: { iata: "PNB", name: "Porto Nacional Airport" },
    SBPP: { iata: "PMG", name: "Ponta Pora Airport" },
    SBPR: { iata: "0", name: "Carlos Prates Airport" },
    SBPS: { iata: "BPS", name: "Porto Seguro Airport" },
    SBPV: { iata: "PVH", name: "Governador Jorge Teixeira de Oliveira Airport" },
    SBQV: { iata: "VDC", name: "Vitoria da Conquista Airport" },
    SBRB: { iata: "RBR", name: "Placido de Castro Airport" },
    SBRF: {
      iata: "REC",
      name: "Guararapes - Gilberto Freyre International Airport",
    },
    SBRJ: { iata: "SDU", name: "Santos Dumont Airport" },
    SBRP: { iata: "RAO", name: "Leite Lopes Airport" },
    SBRR: { iata: "BRB", name: "Barreirinhas Airport" },
    SBSC: { iata: "SNZ", name: "Santa Cruz Airport" },
    SBSJ: { iata: "SJK", name: "Professor Urbano Ernesto Stumpf Airport" },
    SBSL: { iata: "SLZ", name: "Marechal Cunha Machado International Airport" },
    SBSM: { iata: "RIA", name: "Santa Maria Airport" },
    SBSN: { iata: "STM", name: "Maestro Wilson Fonseca Airport" },
    SBSP: { iata: "CGH", name: "Congonhas Airport" },
    SBSR: { iata: "SJP", name: "Sao Jose do Rio Preto Airport" },
    SBST: { iata: "SSZ", name: "Base Aerea de Santos Airport" },
    SBSV: {
      iata: "SSA",
      name: "Deputado Luiz Eduardo Magalhaes International Airport",
    },
    SBTA: { iata: "QHP", name: "Base de Aviacao de Taubate Airport" },
    SBTB: { iata: "TMT", name: "Trombetas Airport" },
    SBTC: { iata: "UNA", name: "Hotel Transamerica Airport" },
    SBTD: { iata: "TOW", name: "Toledo Airport" },
    SBTE: { iata: "THE", name: "Senador Petronio Portela Airport" },
    SBTF: { iata: "TFF", name: "Tefe Airport" },
    SBTG: { iata: "TJL", name: "Plinio Alarcom Airport" },
    SBTK: { iata: "TRQ", name: "Tarauaca Airport" },
    SBTL: { iata: "TEC", name: "Telemaco Borba Airport" },
    SBTR: { iata: "0", name: "Torres Airport" },
    SBTT: { iata: "TBT", name: "Tabatinga Airport" },
    SBTU: { iata: "TUR", name: "Tucurui Airport" },
    SBUA: { iata: "SJL", name: "Sao Gabriel da Cachoeira Airport" },
    SBUF: { iata: "PAV", name: "Paulo Afonso Airport" },
    SBUG: { iata: "URG", name: "Rubem Berta Airport" },
    SBUL: { iata: "UDI", name: "Ten. Cel. Aviador Cesar Bombonato Airport" },
    SBUR: { iata: "UBA", name: "Mario de Almeida Franco Airport" },
    SBVG: { iata: "VAG", name: "Major Brigadeiro Trompowsky Airport" },
    SBVH: { iata: "BVH", name: "Vilhena Airport" },
    SBVT: { iata: "VIX", name: "Eurico de Aguiar Salles Airport" },
    SBYA: { iata: "0", name: "Iauarete Airport" },
    SBYS: { iata: "QPS", name: "Campo Fontenelle Airport" },
    SBZM: { iata: "IZA", name: "Zona da Mata Regional Airport" },
    SCAC: { iata: "ZUD", name: "Pupelde Airport" },
    SCAN: { iata: "LOB", name: "San Rafael Airport" },
    SCAP: { iata: "WAP", name: "Alto Palena Airport" },
    SCAR: { iata: "ARI", name: "Chacalluta Airport" },
    SCAS: { iata: "WPA", name: "Cabo 1° Juan Roman Airport" },
    SCAT: { iata: "CPO", name: "Desierto de Atacama Airport" },
    SCBA: { iata: "BBA", name: "Balmaceda Airport" },
    SCBE: { iata: "TOQ", name: "Barriles Airport" },
    SCCC: { iata: "CCH", name: "Chile Chico Airport" },
    SCCF: { iata: "CJC", name: "El Loa Airport" },
    SCCH: { iata: "YAI", name: "Gral. Bernardo O´Higgins Airport" },
    SCCI: { iata: "PUQ", name: "Pdte. Carlos Ibanez del Campo Airport" },
    SCCQ: { iata: "COW", name: "Tambillos Airport" },
    SCCY: { iata: "GXQ", name: "Teniente Vidal Airport" },
    SCDA: { iata: "IQQ", name: "Diego Aracena Airport" },
    SCEL: {
      iata: "SCL",
      name: "Comodoro Arturo Merino Benitez International Airport",
    },
    SCES: { iata: "ESR", name: "Ricardo Garcia Posada Airport" },
    SCEV: { iata: "FRT", name: "El Avellano Airport" },
    SCFA: { iata: "ANF", name: "Cerro Moreno Airport" },
    SCFI: { iata: "FRT", name: "Fundo Tehuen Airport" },
    SCFM: { iata: "WPR", name: "Capitan Fuentes Martinez Airport Airport" },
    SCFT: { iata: "FFU", name: "Futaleufu Airport" },
    SCGE: { iata: "LSQ", name: "Maria Dolores Airport" },
    SCGZ: { iata: "WPU", name: "Guardiamarina Zanartu Airport" },
    SCHR: { iata: "LGR", name: "Cochrane Airport" },
    SCIE: { iata: "CCP", name: "Carriel Sur Airport" },
    SCIP: { iata: "IPC", name: "Mataveri Airport" },
    SCJO: { iata: "ZOS", name: "Canal Bajo Carlos - Hott Siebert Airport" },
    SCKP: { iata: "CPP", name: "Coposa Airport" },
    SCLL: { iata: "VLR", name: "Vallenar Airport" },
    SCLN: { iata: "ZLR", name: "Municipal de Linares Airport" },
    SCNT: { iata: "PNT", name: "Tte. Julio Gallardo Airport" },
    SCOV: { iata: "OVL", name: "El Tuqui Airport" },
    SCPC: { iata: "ZPC", name: "Pucon Airport" },
    SCPQ: { iata: "MHC", name: "Mocopulli Airport" },
    SCPV: { iata: "PUX", name: "El Mirador Airport" },
    SCRA: { iata: "CNR", name: "Chanaral Airport" },
    SCRD: { iata: "VAP", name: "Rodelillo Airport" },
    SCRG: { iata: "QRC", name: "De La Independencia Airport" },
    SCRM: { iata: "TNM", name: "Teniente Rodolfo Marsh Martin Base" },
    SCSB: { iata: "SMB", name: "Franco Bianco Airport" },
    SCSE: { iata: "LSC", name: "La Florida Airport" },
    SCSF: { iata: "SSD", name: "Victor Lafon Airport" },
    SCST: { iata: "WCA", name: "Gamboa Airport" },
    SCTC: { iata: "ZCO", name: "Maquehue Airport" },
    SCTE: { iata: "PMC", name: "El Tepual Airport" },
    SCTL: { iata: "TLX", name: "Panguilemo Airport" },
    SCTN: { iata: "WCH", name: "Chaiten Airport" },
    SCTO: { iata: "ZIC", name: "Victoria Airport" },
    SCTT: { iata: "TTC", name: "Las Breas Airport" },
    SCVD: { iata: "ZAL", name: "Pichoy Airport" },
    SCVM: { iata: "KNA", name: "Vina del mar Airport" },
    SDAM: { iata: "CPQ", name: "Amarais Airport" },
    SDAY: { iata: "0", name: "Fazenda Altair Airport" },
    SDBK: { iata: "QCJ", name: "Botucatu Airport" },
    SDBY: { iata: "0", name: "Bariri Airport" },
    SDCG: { iata: "OLC", name: "Senadora Eunice Micheles Airport" },
    SDCO: { iata: "SOD", name: "Sorocaba Airport" },
    SDDJ: { iata: "0", name: "Fazenda Santa Maria Airport" },
    SDDR: { iata: "QDC", name: "Dracena Airport" },
    SDDV: { iata: "0", name: "Usina Catanduva Airport" },
    SDFB: { iata: "0", name: "Fazenda Brumado Airport" },
    SDFC: { iata: "0", name: "Fazenda Constancia Airport" },
    SDGC: { iata: "0", name: "Garca Airport" },
    SDJC: { iata: "0", name: "Jaboticabal Airport" },
    SDJL: { iata: "JLS", name: "Jales Airport" },
    SDJO: { iata: "0", name: "Sao Joaquim da Barra Airport" },
    SDJZ: { iata: "0", name: "Fazenda Barra do Agudo Airport" },
    SDKK: { iata: "QOA", name: "Mococa Airport" },
    SDLC: { iata: "0", name: "Lucelia Airport" },
    SDLL: { iata: "0", name: "Leme Airport" },
    SDLP: { iata: "QGC", name: "Lencois Paulista Airport" },
    SDLY: { iata: "0", name: "Matao Airport" },
    SDMH: { iata: "0", name: "Mirassol Airport" },
    SDMJ: { iata: "0", name: "Mogi Mirim Airport" },
    SDNH: { iata: "0", name: "Novo Horizonte Airport" },
    SDNL: { iata: "0", name: "Fazenda Sao Francisco do Itaquere Airport" },
    SDNY: { iata: "QNV", name: "Aeroclube Airport" },
    SDOU: { iata: "OUS", name: "Ourinhos Airport" },
    SDOW: { iata: "OIA", name: "Ourilandia do Norte Airport" },
    SDPV: { iata: "0", name: "Presidente Venceslau Airport" },
    SDPW: { iata: "QHB", name: "Piracicaba Airport" },
    SDRA: { iata: "0", name: "Fazenda das Represas Airport" },
    SDRK: { iata: "QIQ", name: "Rio Claro Airport" },
    SDRR: { iata: "QVP", name: "Avare-Arandu Airport" },
    SDRS: { iata: "QRZ", name: "Resende Airport" },
    SDSC: { iata: "QSC", name: "Sao Carlos Airport" },
    SDSJ: { iata: "0", name: "Fazenda Sao Joao Airport" },
    SDTO: { iata: "0", name: "Fazenda Cataco Airport" },
    SDUB: { iata: "UBT", name: "Ubatuba Airport" },
    SDUN: { iata: "ITP", name: "Itaperuna Airport" },
    SDUO: { iata: "QGS", name: "Alagoinhas Airport" },
    SDVG: { iata: "VOT", name: "Votuporanga Airport" },
    SDVS: { iata: "0", name: "Fazenda Vassoural Airport" },
    SDYM: { iata: "QGB", name: "Limeira Airport" },
    SEAM: { iata: "ATF", name: "Chachoan Airport" },
    SECO: { iata: "OCC", name: "Francisco De Orellana Airport" },
    SECU: { iata: "CUE", name: "Mariscal Lamar Airport" },
    SEGS: { iata: "GPS", name: "Seymour Airport" },
    SEGU: { iata: "GYE", name: "Simon Bolivar International Airport" },
    SEII: { iata: "IBB", name: "General Villamil Airport" },
    SEJI: { iata: "JIP", name: "Jipijapa Airport" },
    SELT: { iata: "LTX", name: "Cotopaxi International Airport" },
    SEMA: { iata: "MRR", name: "Jose Maria Velasco Ibarra Airport" },
    SEMC: { iata: "XMS", name: "Coronel E Carvajal Airport" },
    SEMH: { iata: "MCH", name: "General Manuel Serrano Airport" },
    SEMT: { iata: "MEC", name: "Eloy Alfaro International Airport" },
    SENL: { iata: "LGQ", name: "Nueva Loja Airport" },
    SEPT: { iata: "PYO", name: "Putumayo Airport" },
    SEPV: { iata: "PVO", name: "Reales Tamarindos Airport" },
    SEQM: { iata: "UIO", name: "Nuevo Aeropuerto Internacional Mariscal Sucre" },
    SERO: { iata: "ETR", name: "Coronel Artilleria Victor Larrea Airport" },
    SESA: { iata: "SNC", name: "General Ulpiano Paez Airport" },
    SESC: { iata: "SUQ", name: "Sucua Airport" },
    SESM: { iata: "PTZ", name: "Rio Amazonas Airport" },
    SEST: { iata: "SCY", name: "San Cristobal Airport" },
    SESV: { iata: "BHA", name: "Los Perales Airport" },
    SETH: { iata: "TSC", name: "Taisha Airport" },
    SETI: { iata: "TPN", name: "Tiputini Airport" },
    SETM: { iata: "LOH", name: "Camilo Ponce Enriquez Airport" },
    SETN: { iata: "ESM", name: "General Rivadeneira Airport" },
    SETR: { iata: "TPC", name: "Tarapoa Airport" },
    SETU: { iata: "TUA", name: "Teniente Coronel Luis a Mantilla Airport" },
    SFAL: { iata: "PSY", name: "Stanley Airport" },
    SGAS: { iata: "ASU", name: "Silvio Pettirossi International Airport" },
    SGAY: { iata: "AYO", name: "Juan De Ayolas Airport" },
    SGBN: { iata: "BFA", name: "Bahia Negra Airport" },
    SGCO: { iata: "CIO", name: "Teniente Col Carmelo Peralta Airport" },
    SGEN: { iata: "ENO", name: "Encarnacion Airport" },
    SGES: { iata: "AGT", name: "Guarani International Airport" },
    SGFI: { iata: "FLM", name: "Filadelfia Airport" },
    SGME: { iata: "ESG", name: "Dr. Luis Maria Argana International Airport" },
    SGOL: { iata: "OLK", name: "Fuerte Olimpo Airport" },
    SGPI: { iata: "PIL", name: "Carlos Miguel Gimenez Airport" },
    SGPJ: {
      iata: "PJC",
      name: "Dr Augusto Roberto Fuster International Airport",
    },
    SIAM: { iata: "0", name: "Fazenda Palmital Airport" },
    SIII: { iata: "0", name: "Doutor Heraclito da Motta Luiz Airport" },
    SILC: { iata: "LVR", name: "Municipal Bom Futuro Airport" },
    SIMK: { iata: "FRC", name: "Franca Airport" },
    SISP: { iata: "0", name: "Santa Paula Airport" },
    SIZX: { iata: "JUA", name: "Juara Sul Airport" },
    SJHG: { iata: "CFO", name: "Confresa Airport" },
    SJNP: { iata: "NPR", name: "Novo Progresso Airport" },
    SJRG: { iata: "RIG", name: "Rio Grande Airport" },
    SKAC: { iata: "ACR", name: "Araracuara Airport" },
    SKAD: { iata: "ACD", name: "Alcides Fernandez Airport" },
    SKAG: { iata: "HAY", name: "Aguachica Airport" },
    SKAM: { iata: "AFI", name: "Amalfi Airport" },
    SKAN: { iata: "ADN", name: "Andes Airport" },
    SKAP: { iata: "API", name: "Gomez Nino Apiay Air Base" },
    SKAR: { iata: "AXM", name: "El Eden Airport" },
    SKAS: { iata: "PUU", name: "Tres De Mayo Airport" },
    SKBC: { iata: "ELB", name: "Las Flores Airport" },
    SKBG: { iata: "BGA", name: "Palonegro Airport" },
    SKBO: { iata: "BOG", name: "El Dorado International Airport" },
    SKBQ: { iata: "BAQ", name: "Ernesto Cortissoz International Airport" },
    SKBS: { iata: "BSC", name: "Jose Celestino Mutis Airport" },
    SKBU: { iata: "BUN", name: "Gerardo Tobar Lopez Airport" },
    SKCA: { iata: "CPB", name: "Capurgana Airport" },
    SKCC: { iata: "CUC", name: "Camilo Daza International Airport" },
    SKCD: { iata: "COG", name: "Mandinga Airport" },
    SKCG: { iata: "CTG", name: "Rafael Nunez International Airport" },
    SKCI: { iata: "CCO", name: "Carimagua Airport" },
    SKCL: { iata: "CLO", name: "Alfonso Bonilla Aragon International Airport" },
    SKCM: { iata: "CIM", name: "Cimitarra Airport" },
    SKCN: { iata: "RAV", name: "Cravo Norte Airport" },
    SKCO: { iata: "TCO", name: "La Florida Airport" },
    SKCP: { iata: "BHF", name: "Cupica Airport" },
    SKCR: { iata: "CUO", name: "Caruru Airport" },
    SKCU: { iata: "CAQ", name: "Juan H White Airport" },
    SKCV: { iata: "CVE", name: "Covenas Airport" },
    SKCZ: { iata: "CZU", name: "Las Brujas Airport" },
    SKEB: { iata: "EBG", name: "El Bagre Airport" },
    SKEJ: { iata: "EJA", name: "Yariguies Airport" },
    SKFL: { iata: "FLA", name: "Gustavo Artunduaga Paredes Airport" },
    SKFU: { iata: "FDA", name: "Fundacion Airport" },
    SKGA: { iata: "LGT", name: "La Gaviota Airport" },
    SKGI: { iata: "GIR", name: "Santiago Vila Airport" },
    SKGO: { iata: "CRC", name: "Santa Ana Airport" },
    SKGP: { iata: "GPI", name: "Juan Casiano Airport" },
    SKGZ: { iata: "GLJ", name: "Garzon La Jagua Airport" },
    SKHA: { iata: "CPL", name: "Chaparral Airport" },
    SKHC: { iata: "HTZ", name: "Hato Corozal Airport" },
    SKIB: { iata: "IBE", name: "Perales Airport" },
    SKIG: { iata: "IGO", name: "Chigorodo Airport" },
    SKIO: { iata: "MMP", name: "Cicuco Airport" },
    SKIP: { iata: "IPI", name: "San Luis Airport" },
    SKLC: { iata: "APO", name: "Antonio Roldan Betancourt Airport" },
    SKLG: { iata: "LQM", name: "Caucaya Airport" },
    SKLM: { iata: "MCJ", name: "Jorge Isaac Airport" },
    SKLP: { iata: "LPD", name: "La Pedrera Airport" },
    SKLT: { iata: "LET", name: "Alfredo Vasquez Cobo International Airport" },
    SKMD: { iata: "EOH", name: "Enrique Olaya Herrera Airport" },
    SKMF: { iata: "MFS", name: "Miraflores Airport" },
    SKMG: { iata: "MGN", name: "Baracoa Airport" },
    SKML: { iata: "MTB", name: "Montelibano Airport" },
    SKMP: { iata: "MMP", name: "San Bernardo Airport" },
    SKMR: { iata: "MTR", name: "Los Garzones Airport" },
    SKMU: { iata: "MVP", name: "Fabio Alberto Leon Bentley Airport" },
    SKMZ: { iata: "MZL", name: "La Nubia Airport" },
    SKNC: { iata: "NCI", name: "Necocli Airport" },
    SKNQ: { iata: "NQU", name: "Reyes Murillo Airport" },
    SKNV: { iata: "NVA", name: "Benito Salas Airport" },
    SKOC: { iata: "OCV", name: "Aguas Claras Airport" },
    SKOE: { iata: "ORC", name: "Orocue Airport" },
    SKOT: { iata: "OTU", name: "Otu Airport" },
    SKPA: { iata: "RON", name: "Juan Jose Rondon Airport" },
    SKPC: { iata: "PCR", name: "German Olano Airport" },
    SKPD: { iata: "PDA", name: "Obando Airport" },
    SKPE: { iata: "PEI", name: "Matecana International Airport" },
    SKPI: { iata: "PTX", name: "Pitalito Airport" },
    SKPL: { iata: "PLT", name: "Plato Airport" },
    SKPN: { iata: "NAR", name: "Puerto Nare Airport" },
    SKPP: { iata: "PPN", name: "Guillermo Leon Valencia Airport" },
    SKPQ: { iata: "PQE", name: "German Olano Air Base" },
    SKPR: { iata: "PBE", name: "Puerto Berrio Airport" },
    SKPS: { iata: "PSO", name: "Antonio Narino Airport" },
    SKPV: { iata: "PVA", name: "El Embrujo Airport" },
    SKPZ: { iata: "PZA", name: "Paz De Ariporo Airport" },
    SKQU: { iata: "MQU", name: "Jose Celestino Mutis Airport" },
    SKRG: { iata: "MDE", name: "Jose Maria Cordova International Airport" },
    SKRH: { iata: "RCH", name: "Almirante Padilla Airport" },
    SKSA: { iata: "RVE", name: "Los Colonizadores Airport" },
    SKSJ: { iata: "SJE", name: "Jorge E. Gonzalez Torres Airport" },
    SKSM: { iata: "SMR", name: "Simon Bolivar International Airport" },
    SKSO: { iata: "SOX", name: "Alberto Lleras Camargo Airport" },
    SKSP: { iata: "ADZ", name: "Gustavo Rojas Pinilla International Airport" },
    SKSV: { iata: "SVI", name: "Eduardo Falla Solano Airport" },
    SKTA: { iata: "TAU", name: "Tauramena Airport" },
    SKTB: { iata: "TIB", name: "Tibu Airport" },
    SKTD: { iata: "TDA", name: "Trinidad Airport" },
    SKTL: { iata: "TLU", name: "Tolu Airport" },
    SKTM: { iata: "TME", name: "Gustavo Vargas Airport" },
    SKTQ: { iata: "TQS", name: "Tres Esquinas Air Base" },
    SKTU: { iata: "TRB", name: "Gonzalo Mejia Airport" },
    SKUC: { iata: "AUC", name: "Santiago Perez Airport" },
    SKUI: { iata: "UIB", name: "El Carano Airport" },
    SKUL: { iata: "ULQ", name: "Farfan Airport" },
    SKUR: { iata: "URR", name: "Urrao Airport" },
    SKVG: { iata: "VGZ", name: "Villa Garzon Airport" },
    SKVL: { iata: "PYA", name: "Velasquez Airport" },
    SKVP: { iata: "VUP", name: "Alfonso Lopez Pumarejo Airport" },
    SKVV: { iata: "VVC", name: "Vanguardia Airport" },
    SKYA: { iata: "AYG", name: "Yaguara Airport" },
    SKYP: { iata: "EYP", name: "El Yopal Airport" },
    SLAG: { iata: "MHW", name: "Monteagudo Airport" },
    SLAL: { iata: "SRE", name: "AlcantarÃ­ International Airport" },
    SLAP: { iata: "APB", name: "Apolo Airport" },
    SLAS: { iata: "ASC", name: "Ascencion De Guarayos Airport" },
    SLBA: { iata: "BVL", name: "Baures Airport" },
    SLBJ: { iata: "BJO", name: "Bermejo Airport" },
    SLCA: { iata: "CAM", name: "Camiri Airport" },
    SLCB: { iata: "CBB", name: "Jorge Wilsterman International Airport" },
    SLCO: { iata: "CIJ", name: "Capitan Anibal Arab Airport" },
    SLCP: { iata: "CEP", name: "Concepcion Airport" },
    SLET: { iata: "SRZ", name: "El Trompillo Airport" },
    SLGY: { iata: "GYA", name: "Capitan de Av. Emilio Beltran Airport" },
    SLHJ: { iata: "BVK", name: "Huacaraje Airport" },
    SLJE: { iata: "SJS", name: "San Jose De Chiquitos Airport" },
    SLJO: { iata: "SJB", name: "San Joaquin Airport" },
    SLJV: { iata: "SJV", name: "San Javier Airport" },
    SLLP: { iata: "LPB", name: "El Alto International Airport" },
    SLMG: { iata: "MGD", name: "Magdalena Airport" },
    SLOR: { iata: "ORU", name: "Juan Mendoza Airport" },
    SLPO: { iata: "POI", name: "Capitan Nicolas Rojas Airport" },
    SLPR: { iata: "PUR", name: "Puerto Rico Airport" },
    SLPS: { iata: "PSZ", name: "Capitan Av. Salvador Ogaya G. airport" },
    SLRA: { iata: "SRD", name: "San Ramon Airport" },
    SLRB: { iata: "RBO", name: "Robore Airport" },
    SLRI: { iata: "RIB", name: "Capitan Av. Selin Zeitun Lopez Airport" },
    SLRQ: { iata: "RBQ", name: "Rurenabaque Airport" },
    SLRY: { iata: "REY", name: "Reyes Airport" },
    SLSA: { iata: "SBL", name: "Santa Ana Del Yacuma Airport" },
    SLSB: { iata: "SRJ", name: "Capitan Av. German Quiroga G. Airport" },
    SLSI: { iata: "SNG", name: "Capitan Av. Juan Cochamanidis S. Airport" },
    SLSM: { iata: "SNM", name: "San Ignacio de Moxos Airport" },
    SLSR: { iata: "SRB", name: "Santa Rosa De Yacuma Airport" },
    SLSU: { iata: "SRE", name: "Juana Azurduy De Padilla Airport" },
    SLTI: { iata: "MQK", name: "San Matias Airport" },
    SLTJ: { iata: "TJA", name: "Capitan Oriel Lea Plaza Airport" },
    SLTR: { iata: "TDD", name: "Teniente Av. Jorge Henrich Arauz Airport" },
    SLUY: { iata: "UYU", name: "Uyuni Airport" },
    SLVG: { iata: "VAH", name: "Capitan Av. Vidal Villagomez Toledo Airport" },
    SLVM: { iata: "VLM", name: "Teniente Coronel Rafael Pabon Airport" },
    SLVR: { iata: "VVI", name: "Viru Viru International Airport" },
    SLYA: { iata: "BYC", name: "Yacuiba Airport" },
    SMBN: { iata: "ABN", name: "Albina Airport" },
    SMBO: { iata: "BTO", name: "Botopasi Airport" },
    SMCA: { iata: "AAJ", name: "Cayana Airstrip" },
    SMCO: { iata: "TOT", name: "Totness Airport" },
    SMDA: { iata: "DRJ", name: "Drietabbetje Airport" },
    SMDJ: { iata: "DOE", name: "Djumu-Djomoe Airport" },
    SMDO: { iata: "LDO", name: "Ladouanie Airport" },
    SMJP: { iata: "PBM", name: "Johan Adolf Pengel International Airport" },
    SMMO: { iata: "MOJ", name: "Moengo Airstrip" },
    SMNI: { iata: "ICK", name: "Nieuw Nickerie Airport" },
    SMPA: { iata: "OEM", name: "Vincent Fayks Airport" },
    SMST: { iata: "SMZ", name: "Stoelmanseiland Airport" },
    SMTP: { iata: "KCB", name: "Tepoe Airstrip" },
    SMWA: { iata: "AGI", name: "Wageningen Airport Airport" },
    SMWS: { iata: "WSO", name: "Washabo Airport" },
    SMZO: { iata: "ORG", name: "Zorg en Hoop Airport" },
    SNAI: { iata: "APY", name: "Alto Parnaiba Airport" },
    SNAL: { iata: "APQ", name: "Arapiraca Airport" },
    SNAR: { iata: "AMJ", name: "Cirilo Queiroz Airport" },
    SNAU: { iata: "0", name: "Aeroclube Airport" },
    SNBC: { iata: "BDC", name: "Barra do Corda Airport" },
    SNBL: { iata: "BVM", name: "Belmonte Airport" },
    SNBO: { iata: "0", name: "Boquira Airport" },
    SNBR: { iata: "BRA", name: "Barreiras Airport" },
    SNBS: { iata: "BSS", name: "Balsas Airport" },
    SNBU: { iata: "BMS", name: "Socrates Mariani Bittencourt Airport" },
    SNBX: { iata: "BQQ", name: "Barra Airport" },
    SNCE: { iata: "0", name: "Campo do Meio Airport" },
    SNCP: { iata: "CTP", name: "Carutapera Airport" },
    SNCU: { iata: "CPU", name: "Cururupu Airport" },
    SNCV: { iata: "0", name: "Campina Verde Airport" },
    SNCX: { iata: "QCH", name: "Colatina Airport" },
    SNDC: { iata: "RDC", name: "Redencao Airport" },
    SNDN: { iata: "LEP", name: "Leopoldina Airport" },
    SNDT: { iata: "DTI", name: "Diamantina Airport" },
    SNDV: { iata: "DIQ", name: "Divinopolis Airport" },
    SNED: { iata: "CNV", name: "Canavieiras Airport" },
    SNEM: { iata: "0", name: "Encanta Moca Airport" },
    SNFO: { iata: "0", name: "Formiga Airport" },
    SNFX: { iata: "SXX", name: "Sao Felix do Xingu Airport" },
    SNGA: { iata: "GUZ", name: "Guarapari Airport" },
    SNGD: { iata: "GDP", name: "Guadalupe Airport" },
    SNGI: { iata: "GNM", name: "Guanambi Airport" },
    SNGJ: { iata: "0", name: "Grajau Airport" },
    SNGM: { iata: "GMS", name: "Fazenda Canada Airport" },
    SNGN: { iata: "QGP", name: "Garanhuns Airport" },
    SNIC: { iata: "IRE", name: "Irece Airport" },
    SNIG: { iata: "QIG", name: "Iguatu Airport" },
    SNIP: { iata: "QIT", name: "Itapetinga Airport" },
    SNIU: { iata: "IPU", name: "Ipiau Airport" },
    SNJB: { iata: "JCM", name: "Jacobina Airport" },
    SNJD: { iata: "FEC", name: "Joao Durval Carneiro Airport" },
    SNJK: { iata: "JEQ", name: "Jequie Airport" },
    SNJN: { iata: "JNA", name: "Januaria Airport" },
    SNJR: { iata: "JDR", name: "Prefeito Octavio de Almeida Neves Airport" },
    SNKE: { iata: "CMP", name: "Santana do Araguaia Airport" },
    SNKF: { iata: "QDF", name: "Conselheiro Lafaiete Airport" },
    SNKI: { iata: "CDI", name: "Cachoeiro do Itapemirim Airport" },
    SNKL: { iata: "0", name: "Colinas Airport" },
    SNKN: { iata: "QCP", name: "Currais Novos Airport" },
    SNLB: { iata: "LVB", name: "Livramento do Brumado Airport" },
    SNLO: { iata: "SSO", name: "Sao Lourenco Airport" },
    SNMA: { iata: "MTE", name: "Monte Alegre Airport" },
    SNMB: { iata: "0", name: "Mombaca Airport" },
    SNMS: { iata: "0", name: "Monte Santo de Minas Airport" },
    SNMU: { iata: "MVS", name: "Mucuri Airport" },
    SNMX: { iata: "SBJ", name: "Sao Mateus Airport" },
    SNMZ: { iata: "PTQ", name: "Porto de Moz Airport" },
    SNNU: { iata: "NNU", name: "Nanuque Airport" },
    SNOB: { iata: "QBX", name: "Sobral Airport" },
    SNOC: { iata: "0", name: "Morro do Chapeu Airport" },
    SNOE: { iata: "0", name: "Oeiras Airport" },
    SNOF: { iata: "0", name: "Ouro Fino Airport" },
    SNOP: { iata: "0", name: "Propria Airport" },
    SNOS: { iata: "PSW", name: "Municipal Jose Figueiredo Airport" },
    SNOU: { iata: "FEJ", name: "Novo Aerodromo de Feijo Airport" },
    SNOX: { iata: "ORX", name: "Oriximina Airport" },
    SNPB: { iata: "0", name: "Pastos Bons Airport" },
    SNPC: { iata: "PCS", name: "Picos Airport" },
    SNPD: { iata: "POJ", name: "Patos de Minas Airport" },
    SNPI: { iata: "0", name: "Piata Airport" },
    SNPJ: { iata: "0", name: "Patrocinio Airport" },
    SNPO: { iata: "0", name: "Pompeu Airport" },
    SNPU: { iata: "0", name: "Paraguacu Airport" },
    SNPX: { iata: "PIV", name: "Pirapora Airport" },
    SNQG: { iata: "FLB", name: "Cangapara Airport" },
    SNQP: { iata: "0", name: "Fazenda Sao Francisco Airport" },
    SNRD: { iata: "PDF", name: "Prado Airport" },
    SNRP: { iata: "0", name: "Rio Paranaiba Airport" },
    SNRU: { iata: "CAU", name: "Caruaru Airport" },
    SNRX: { iata: "0", name: "Riachao Airport" },
    SNSC: { iata: "0", name: "Sacramento Airport" },
    SNSH: { iata: "0", name: "Sao Jose Airport" },
    SNSW: { iata: "SFK", name: "Soure Airport" },
    SNTF: { iata: "TXF", name: "Teixeira de Freitas Airport" },
    SNTI: { iata: "OBI", name: "Obidos Airport" },
    SNTK: { iata: "0", name: "Monte Carmelo Airport" },
    SNTO: { iata: "TFL", name: "Juscelino Kubitscheck Airport" },
    SNTQ: { iata: "0", name: "Buritirama Airport" },
    SNVB: { iata: "VAL", name: "Valenca Airport" },
    SNVD: { iata: "0", name: "Santa Maria da Vitoria Airport" },
    SNVG: { iata: "0", name: "Volta Grande Airport" },
    SNVI: { iata: "QID", name: "Melio Viana Airport" },
    SNVS: { iata: "BVS", name: "Breves Airport" },
    SNWC: { iata: "CMC", name: "Camocim Airport" },
    SNXA: { iata: "0", name: "Machado Airport" },
    SNXS: { iata: "QXC", name: "Fazenda Sao Braz Airport" },
    SNYA: { iata: "GGF", name: "Almeirim Airport" },
    SNYE: { iata: "PHI", name: "Pinheiro Airport" },
    SNYH: { iata: "ITI", name: "Agropecuaria Castanhais Airport" },
    SNZA: { iata: "PPY", name: "Pouso Alegre Airport" },
    SNZW: { iata: "ITE", name: "Itubera Airport" },
    SOCA: { iata: "CAY", name: "Cayenne-Rochambeau Airport" },
    SOGS: { iata: "GSI", name: "Grand-Santi Airport" },
    SOOA: { iata: "MPY", name: "Maripasoula Airport" },
    SOOG: { iata: "OXP", name: "Saint-Georges-de-l'Oyapock Airport" },
    SOOM: { iata: "LDX", name: "Saint-Laurent-du-Maroni Airport" },
    SOOR: { iata: "REI", name: "Regina Airport" },
    SOOS: { iata: "XAU", name: "Saul Airport" },
    SPAO: { iata: "APE", name: "San Juan Aposento Airport" },
    SPAR: { iata: "ALD", name: "Alerta Airport" },
    SPAS: {
      iata: "AOP",
      name: "Alferez FAP Alfredo Vladimir Sara Bauer Airport",
    },
    SPAY: { iata: "ATG", name: "Teniente General Gerardo Perez Pinedo Airport" },
    SPBB: { iata: "MBP", name: "Moyobamba Airport" },
    SPBL: { iata: "BLP", name: "Huallaga Airport" },
    SPBR: { iata: "IBP", name: "Iberia Airport" },
    SPCL: {
      iata: "PCL",
      name: "Cap FAP David Abenzur Rengifo International Airport",
    },
    SPDR: { iata: "TDP", name: "Trompeteros Airport" },
    SPEO: {
      iata: "CHM",
      name: "Teniente FAP Jaime A De Montreuil Morales Airport",
    },
    SPGM: { iata: "TGI", name: "Tingo Maria Airport" },
    SPHI: {
      iata: "CIX",
      name: "Capitan FAP Jose A Quinones Gonzales International Airport",
    },
    SPHO: { iata: "AYP", name: "Coronel FAP Alfredo Mendivil Duarte Airport" },
    SPHY: { iata: "ANS", name: "Andahuaylas Airport" },
    SPHZ: { iata: "ATA", name: "Comandante FAP German Arias Graziani Airport" },
    SPIL: { iata: "UMI", name: "Quince Air Base" },
    SPIZ: { iata: "UCZ", name: "Uchiza Airport" },
    SPJA: { iata: "RIJ", name: "Juan Simons Vela Airport" },
    SPJC: { iata: "LIM", name: "Jorge Chavez International Airport" },
    SPJE: { iata: "JAE", name: "Shumba Airport" },
    SPJI: { iata: "JJI", name: "Juanjui Airport" },
    SPJJ: { iata: "JAU", name: "Francisco Carle Airport" },
    SPJL: { iata: "JUL", name: "Inca Manco Capac International Airport" },
    SPJN: { iata: "SJA", name: "San Juan de Marcona Airport" },
    SPJR: {
      iata: "CJA",
      name: "Mayor General FAP Armando Revoredo Iglesias Airport",
    },
    SPLN: { iata: "RIM", name: "San Nicolas Airport" },
    SPLO: { iata: "ILQ", name: "Ilo Airport" },
    SPME: { iata: "TBP", name: "Capitan FAP Pedro Canga Rodriguez Airport" },
    SPMF: { iata: "MZA", name: "Manuel Prado Ugarteche Airport" },
    SPMR: { iata: "SMG", name: "Santa Maria Airport" },
    SPMS: { iata: "YMS", name: "Moises Benzaquen Rengifo Airport" },
    SPNC: { iata: "HUU", name: "Alferez Fap David Figueroa Fernandini Airport" },
    SPOA: { iata: "SQU", name: "Saposoa Airport" },
    SPPY: { iata: "CHH", name: "Chachapoyas Airport" },
    SPQN: { iata: "REQ", name: "Requena Airport" },
    SPQT: {
      iata: "IQT",
      name: "Coronel FAP Francisco Secada Vignetta International Airport",
    },
    SPQU: { iata: "AQP", name: "Rodriguez Ballon International Airport" },
    SPRU: {
      iata: "TRU",
      name: "Capitan FAP Carlos Martinez De Pinillos International Airport",
    },
    SPSF: { iata: "SQD", name: "San Francisco Airport" },
    SPSO: {
      iata: "PIO",
      name: "Capitan FAP Renan Elias Olivera International Airport",
    },
    SPST: {
      iata: "TPP",
      name: "Cadete FAP Guillermo Del Castillo Paredes Airport",
    },
    SPSY: { iata: "SYC", name: "Shiringayoc Airport" },
    SPTN: {
      iata: "TCQ",
      name: "Coronel FAP Carlos Ciriani Santa Rosa International Airport",
    },
    SPTU: { iata: "PEM", name: "Padre Aldamiz International Airport" },
    SPUR: {
      iata: "PIU",
      name: "Capitan FAP Guillermo Concha Iberico International Airport",
    },
    SPYL: { iata: "TYL", name: "Capitan Montes Airport" },
    SPZA: { iata: "NZA", name: "Maria Reiche Neuman Airport" },
    SPZO: { iata: "CUZ", name: "Alejandro Velasco Astete International Airport" },
    SSAP: { iata: "APU", name: "Apucarana Airport" },
    SSAQ: { iata: "0", name: "Aeroclube Airport" },
    SSBG: { iata: "BGV", name: "Aeroclube de Bento Goncalves Airport" },
    SSBL: { iata: "BNU", name: "Blumenau Airport" },
    SSBR: { iata: "0", name: "Bandeirantes Airport" },
    SSCK: { iata: "CCI", name: "Concordia Airport" },
    SSCL: { iata: "CSS", name: "Cassilandia Airport" },
    SSCN: { iata: "QCN", name: "Canela Airport" },
    SSCP: { iata: "CKO", name: "Cornelio Procopio Airport" },
    SSCT: { iata: "GGH", name: "Cianorte Airport" },
    SSDO: { iata: "DOU", name: "Dourados Airport" },
    SSEP: { iata: "0", name: "Sao Sepe Airport" },
    SSER: { iata: "ERM", name: "Erechim Airport" },
    SSFB: { iata: "FBE", name: "Francisco Beltrao Airport" },
    SSGR: { iata: "0", name: "Guapore Airport" },
    SSGY: { iata: "QGA", name: "Guaira Airport" },
    SSHZ: { iata: "HRZ", name: "Horizontina Airport" },
    SSIJ: { iata: "IJU", name: "Ijui Airport" },
    SSIQ: { iata: "ITQ", name: "Itaqui Airport" },
    SSJA: { iata: "JCB", name: "Santa Terezinha Airport" },
    SSJK: { iata: "0", name: "Julio de Castilho Airport" },
    SSKM: { iata: "CBW", name: "Campo Mourao Airport" },
    SSKS: { iata: "QDB", name: "Cachoeira do Sul Airport" },
    SSKU: { iata: "QCR", name: "Curitibanos Airport" },
    SSKW: { iata: "OAL", name: "Cacoal Airport" },
    SSLA: { iata: "0", name: "Laguna Airport" },
    SSLI: { iata: "LVB", name: "Estancia Portal do Sol Airport" },
    SSLN: { iata: "LOI", name: "Helmuth Baungarten Airport" },
    SSLT: { iata: "ALQ", name: "Alegrete Novo Airport" },
    SSMF: { iata: "QMF", name: "Mafra Airport" },
    SSNG: { iata: "QGF", name: "Montenegro Airport" },
    SSNH: { iata: "QHV", name: "Novo Hamburgo Airport" },
    SSNO: { iata: "0", name: "Nonoai Airport" },
    SSNP: { iata: "0", name: "Nova Prata Airport" },
    SSOE: { iata: "SQX", name: "Sao Miguel do Oeste Airport" },
    SSOG: { iata: "APX", name: "Arapongas Airport" },
    SSOU: { iata: "AIR", name: "Fazenda Inhumas Airport" },
    SSPB: { iata: "PTO", name: "Pato Branco Airport" },
    SSPG: { iata: "PNG", name: "Paranagua Airport" },
    SSPI: { iata: "PVI", name: "Paranavai Airport" },
    SSPN: { iata: "PBB", name: "Paranaiba Airport" },
    SSQM: { iata: "0", name: "Tanque Novo Airport" },
    SSQN: { iata: "0", name: "Mundo Novo Airport" },
    SSQT: { iata: "QAC", name: "Castro Airport" },
    SSRU: { iata: "SQY", name: "Sao Lourenco do Sul Airport" },
    SSSB: { iata: "QOJ", name: "Sao Borja Airport" },
    SSSC: { iata: "CSU", name: "Santa Cruz do Sul Airport" },
    SSSD: { iata: "0", name: "Soledade Airport" },
    SSSG: { iata: "0", name: "Sao Gabriel Airport" },
    SSSQ: { iata: "0", name: "Sao Joaquim Airport" },
    SSSZ: { iata: "0", name: "Sertanopolis Airport" },
    SSTO: { iata: "0", name: "Tres Passos Airport" },
    SSUM: { iata: "UMU", name: "Umuarama Airport" },
    SSUV: { iata: "QVB", name: "Uniao da Vitoria Airport" },
    SSUX: { iata: "0", name: "Usina Mandu Airport" },
    SSVI: { iata: "VIA", name: "Videira Airport" },
    SSVN: { iata: "0", name: "Veranopolis Airport" },
    SSVP: { iata: "CTQ", name: "Santa Vitoria do Palmar Airport" },
    SSVY: { iata: "0", name: "Fazenda Tres Barras Airport" },
    SSWA: { iata: "0", name: "Venancio Aires Airport" },
    SSWS: { iata: "0", name: "Cacapava do Sul Airport" },
    SSXD: { iata: "0", name: "Sarandi Airport" },
    SSXX: { iata: "AXE", name: "Xanxere Airport" },
    SSYA: { iata: "AAG", name: "Arapoti Airport" },
    SSZR: { iata: "SRA", name: "Santa Rosa Airport" },
    SSZS: { iata: "0", name: "Centenario do Sul Airport" },
    SSZW: { iata: "PGZ", name: "Ponta Grossa Airport" },
    SUAG: { iata: "ATI", name: "Artigas International Airport" },
    SUCA: { iata: "CYR", name: "Laguna de Los Patos International Airport" },
    SUCM: { iata: "CAR", name: "Carmelo International Airport" },
    SUDU: { iata: "DZO", name: "Santa Bernardina International Airport" },
    SULS: {
      iata: "PDP",
      name: "Capitan Corbeta CA Curbelo International Airport",
    },
    SUME: { iata: "MER", name: "Ricardo de Tomasi International Airport" },
    SUMO: { iata: "MLZ", name: "Cerro Largo International Airport" },
    SUMU: {
      iata: "MVD",
      name: "Carrasco International /General C L Berisso Airport",
    },
    SUPE: { iata: "MDO", name: "El Jaguel / Punta del Este Airport" },
    SUPU: { iata: "PDU", name: "Tydeo Larre Borges Airport" },
    SURV: {
      iata: "RVY",
      name: "Presidente General Don Oscar D. Gestido International Airport",
    },
    SUSO: { iata: "STY", name: "Nueva Hesperides International Airport" },
    SUTB: { iata: "TAW", name: "Tacuarembo Airport" },
    SUTR: { iata: "TYT", name: "Treinta y Tres Airport" },
    SUVO: { iata: "VCH", name: "Vichadero Airport" },
    SVAC: { iata: "AGV", name: "Oswaldo Guevara Mujica Airport" },
    SVAN: { iata: "AAO", name: "Anaco Airport" },
    SVAS: { iata: "LPJ", name: "Armando Schwarck Airport" },
    SVBC: {
      iata: "BLA",
      name: "General Jose Antonio Anzoategui International Airport",
    },
    SVBI: { iata: "BNS", name: "Barinas Airport" },
    SVBM: { iata: "BRM", name: "Barquisimeto International Airport" },
    SVBS: { iata: "MYC", name: "Escuela Mariscal Sucre Airport" },
    SVCB: { iata: "CBL", name: "Ciudad Bolivar Airport" },
    SVCD: { iata: "CXA", name: "Caicara del Orinoco Airport" },
    SVCG: { iata: "CUV", name: "Casigua El Cubo Airport" },
    SVCL: { iata: "CLZ", name: "Calabozo Airport" },
    SVCN: { iata: "CAJ", name: "Canaima Airport" },
    SVCO: { iata: "VCR", name: "Carora Airport" },
    SVCP: { iata: "CUP", name: "General Francisco Bermudez Airport" },
    SVCR: { iata: "CZE", name: "Jose Leonardo Chirinos Airport" },
    SVCU: { iata: "CUM", name: "Cumana (Antonio Jose de Sucre) Airport" },
    SVDA: { iata: "isl", name: "La Tortuga Punta Delgada Airport" },
    SVED: { iata: "EOR", name: "El Dorado Airport" },
    SVEZ: { iata: "EOZ", name: "Elorza Airport" },
    SVGD: { iata: "GDO", name: "Guasdalito Airport" },
    SVGI: { iata: "GUI", name: "Guiria Airport" },
    SVGU: { iata: "GUQ", name: "Guanare Airport" },
    SVHG: { iata: "HGE", name: "Higuerote Airport" },
    SVIC: { iata: "ICA", name: "Icabaru Airport" },
    SVIE: { iata: "ICC", name: "Andres Miguel Salazar Marcano Airport" },
    SVJC: { iata: "LSP", name: "Josefa Camejo International Airport" },
    SVKA: { iata: "KAV", name: "Kavanayen Airport" },
    SVLF: { iata: "LFR", name: "La Fria Airport" },
    SVMC: { iata: "MAR", name: "La Chinita International Airport" },
    SVMD: { iata: "MRD", name: "Alberto Carnevalli Airport" },
    SVMG: {
      iata: "PMV",
      name: "Del Caribe Santiago Marino International Airport",
    },
    SVMI: {
      iata: "CCS",
      name: "Maiquetia (Simon Bolivar Internacional) Airport",
    },
    SVMT: { iata: "MUN", name: "Maturin Airport" },
    SVON: { iata: "CBS", name: "Oro Negro Airport" },
    SVPA: { iata: "PYH", name: "Cacique Aramare Airport" },
    SVPC: { iata: "PBL", name: "General Bartolome Salom International Airport" },
    SVPE: { iata: "PDZ", name: "Pedernales Airport" },
    SVPH: { iata: "PPH", name: "Perai Tepuy Airport" },
    SVPM: { iata: "SCI", name: "Paramillo Airport" },
    SVPR: {
      iata: "PZO",
      name: "General Manuel Carlos Piar International Airport",
    },
    SVPT: { iata: "PTM", name: "Palmarito Airport" },
    SVRS: { iata: "LRV", name: "Gran Roque Airport" },
    SVSA: { iata: "SVZ", name: "San Antonio Del Tachira Airport" },
    SVSB: { iata: "SBB", name: "Santa Barbara de Barinas Airport" },
    SVSE: { iata: "SNV", name: "Santa Elena de Uairen Airport" },
    SVSO: { iata: "STD", name: "Mayor Buenaventura Vivas International Airport" },
    SVSP: { iata: "SNF", name: "Sub Teniente Nestor Arias Airport" },
    SVSR: { iata: "SFD", name: "San Fernando De Apure Airport" },
    SVST: { iata: "SOM", name: "San Tome Airport" },
    SVSZ: { iata: "STB", name: "Santa Barbara del Zulia Airport" },
    SVTC: { iata: "TUV", name: "Tucupita Airport" },
    SVTM: { iata: "TMO", name: "Tumeremo Airport" },
    SVUM: { iata: "URM", name: "Uriman Airport" },
    SVVA: { iata: "VLN", name: "Arturo Michelena International Airport" },
    SVVG: { iata: "VIG", name: "Juan Pablo Perez Alfonso Airport" },
    SVVL: { iata: "VLV", name: "Dr. Antonio Nicolas Briceno Airport" },
    SVVP: { iata: "VDP", name: "Valle de La Pascua Airport" },
    SWAY: { iata: "0", name: "Araguaiana Airport" },
    SWBA: { iata: "0", name: "Buriti Alegre Airport" },
    SWBC: { iata: "BAZ", name: "Barcelos Airport" },
    SWBR: { iata: "RBB", name: "Borba Airport" },
    SWCA: { iata: "CAF", name: "Carauari Airport" },
    SWCQ: { iata: "CQS", name: "Costa Marques Airport" },
    SWCW: { iata: "0", name: "Cavalcante Airport" },
    SWCZ: { iata: "0", name: "Ceres Airport" },
    SWDM: { iata: "DMT", name: "Diamantino Airport" },
    SWDN: { iata: "DNO", name: "Dianopolis Airport" },
    SWEC: { iata: "ARS", name: "Estancia das Cascatas Airport" },
    SWEI: { iata: "ERN", name: "Eirunepe Airport" },
    SWEK: { iata: "CQA", name: "Canarana Airport" },
    SWFJ: { iata: "FEJ", name: "Feijo Airport" },
    SWFX: { iata: "SXO", name: "Sao Felix do Araguaia Airport" },
    SWGI: { iata: "GRP", name: "Gurupi Airport" },
    SWGN: { iata: "AUX", name: "Araguaina Airport" },
    SWHP: { iata: "GGB", name: "Fazenda Olhos D`agua Airport" },
    SWHT: { iata: "HUW", name: "Humaita Airport" },
    SWIA: { iata: "0", name: "Iaciara Airport" },
    SWII: { iata: "IPG", name: "Ipiranga Airport" },
    SWIY: { iata: "IDO", name: "Santa Izabel do Morro Airport" },
    SWJI: { iata: "JPR", name: "Ji-Parana Airport" },
    SWJN: { iata: "JIA", name: "Juina Airport" },
    SWJU: { iata: "JRN", name: "Juruena Airport" },
    SWJW: { iata: "JTI", name: "Jatai Airport" },
    SWKC: { iata: "CCX", name: "Caceres Airport" },
    SWKO: { iata: "CIZ", name: "Coari Airport" },
    SWKT: { iata: "TLZ", name: "Catalao Airport" },
    SWKX: { iata: "0", name: "Corixa Airport" },
    SWLB: { iata: "LBR", name: "Labrea Airport" },
    SWLC: { iata: "RVD", name: "General Leite de Castro Airport" },
    SWMW: { iata: "MBZ", name: "Maues Airport" },
    SWNA: { iata: "NVP", name: "Novo Aripuana Airport" },
    SWNI: { iata: "AQM", name: "Nova Vida Airport" },
    SWNK: { iata: "BCR", name: "Novo Campo Airport" },
    SWNQ: { iata: "NQL", name: "Niquelandia Airport" },
    SWNS: { iata: "APS", name: "Anapolis Airport" },
    SWOB: { iata: "FBA", name: "Fonte Boa Airport" },
    SWPG: { iata: "PBV", name: "Porto dos Gauchos Airport" },
    SWPI: { iata: "PIN", name: "Parintins Airport" },
    SWPM: { iata: "PBQ", name: "Pimenta Bueno Airport" },
    SWPQ: { iata: "PBX", name: "Fazenda Piraguassu Airport" },
    SWRA: { iata: "AAI", name: "Arraias Airport" },
    SWRD: { iata: "ROO", name: "Rondonopolis Airport" },
    SWRP: { iata: "AIR", name: "Aripuana Airport" },
    SWRU: { iata: "0", name: "Meruri Airport" },
    SWSI: { iata: "OPS", name: "Presidente Joao Batista Figueiredo Airport" },
    SWST: { iata: "STZ", name: "Santa Terezinha Airport" },
    SWTP: { iata: "IRZ", name: "Tapuruquara Airport" },
    SWTS: { iata: "TGQ", name: "Tangara da Serra Airport" },
    SWTU: { iata: "AZL", name: "Fazenda Tucunare Airport" },
    SWTY: { iata: "QHN", name: "Taguatinga Airport" },
    SWUA: { iata: "SQM", name: "Sao Miguel do Araguaia Airport" },
    SWVB: { iata: "MTG", name: "Vila Bela da Santissima Trindade Airport" },
    SWVC: { iata: "VLP", name: "Vila Rica Airport" },
    SWXM: { iata: "MBK", name: "Regional Orlando Villas Boas Airport" },
    SWXV: { iata: "NOK", name: "Xavantina Airport" },
    SYAH: { iata: "AHL", name: "Aishalton Airport" },
    SYAN: { iata: "NAI", name: "Annai Airport" },
    SYBE: { iata: "BCG", name: "Bemichi Airport" },
    SYBR: { iata: "BMJ", name: "Baramita Airport" },
    SYBT: { iata: "GFO", name: "Bartica A Airport" },
    SYCJ: { iata: "GEO", name: "Cheddi Jagan International Airport" },
    SYGO: { iata: "OGL", name: "Ogle Airport" },
    SYIB: { iata: "IMB", name: "Imbaimadai Airport" },
    SYKM: { iata: "KAR", name: "Kamarang Airport" },
    SYKR: { iata: "KRM", name: "Karanambo Airport" },
    SYKS: { iata: "KRG", name: "Karasabai Airport" },
    SYKT: { iata: "KTO", name: "Kato Airport" },
    SYLP: { iata: "LUB", name: "Lumid Pau Airport" },
    SYLT: { iata: "LTM", name: "Lethem Airport" },
    SYMB: { iata: "USI", name: "Mabaruma Airport" },
    SYMD: { iata: "MHA", name: "Mahdia Airport" },
    SYMM: { iata: "MYM", name: "Monkey Mountain Airport" },
    SYMR: { iata: "MWJ", name: "Matthews Ridge Airport" },
    SYNA: { iata: "QSX", name: "New Amsterdam Airport" },
    SYOR: { iata: "ORJ", name: "Orinduik Airport" },
    SYPR: { iata: "PRR", name: "Paruma Airport" },
    TAPA: { iata: "ANU", name: "V.C. Bird International Airport" },
    TAPH: { iata: "BBQ", name: "Codrington Airport" },
    TBPB: { iata: "BGI", name: "Sir Grantley Adams International Airport" },
    TDCF: { iata: "DCF", name: "Canefield Airport" },
    TDPD: { iata: "DOM", name: "Melville Hall Airport" },
    TFFA: { iata: "DSD", name: "La Desirade Airport" },
    TFFB: { iata: "BBR", name: "Baillif Airport" },
    TFFC: { iata: "SFC", name: "St-Francois Airport" },
    TFFF: { iata: "FDF", name: "Martinique Aime Cesaire International Airport" },
    TFFG: { iata: "SFG", name: "L'Esperance Airport" },
    TFFJ: { iata: "SBH", name: "Gustaf III Airport" },
    TFFM: { iata: "GBJ", name: "Les Bases Airport" },
    TFFR: { iata: "PTP", name: "Pointe-a-Pitre Le Raizet" },
    TFFS: { iata: "LSS", name: "Terre-de-Haut Airport" },
    TGPY: { iata: "GND", name: "Point Salines International Airport" },
    TGPZ: { iata: "CRU", name: "Lauriston Airport" },
    TIST: { iata: "STT", name: "Cyril E. King Airport" },
    TISX: { iata: "STX", name: "Henry E Rohlsen Airport" },
    TJAB: { iata: "ARE", name: "Antonio Nery Juarbe Pol Airport" },
    TJBQ: { iata: "BQN", name: "Rafael Hernandez Airport" },
    TJCP: { iata: "CPX", name: "Benjamin Rivera Noriega Airport" },
    TJFA: { iata: "FAJ", name: "Diego Jimenez Torres Airport" },
    TJIG: { iata: "SIG", name: "Fernando Luis Ribas Dominicci Airport" },
    TJMZ: { iata: "MAZ", name: "Eugenio Maria De Hostos Airport" },
    TJPS: { iata: "PSE", name: "Mercedita Airport" },
    TJRV: { iata: "NRR", name: "Jose Aponte de la Torre Airport" },
    TJSJ: { iata: "SJU", name: "Luis Munoz Marin International Airport" },
    TJVQ: { iata: "VQS", name: "Antonio Rivera Rodriguez Airport" },
    TKPK: { iata: "SKB", name: "Robert L. Bradshaw International Airport" },
    TKPN: { iata: "NEV", name: "Vance W. Amory International Airport" },
    TLPC: { iata: "SLU", name: "George F. L. Charles Airport" },
    TLPL: { iata: "UVF", name: "Hewanorra International Airport" },
    TNCA: { iata: "AUA", name: "Queen Beatrix International Airport" },
    TNCB: { iata: "BON", name: "Flamingo International Airport" },
    TNCC: { iata: "CUR", name: "Hato International Airport" },
    TNCE: { iata: "EUX", name: "F. D. Roosevelt Airport" },
    TNCM: { iata: "SXM", name: "Princess Juliana International Airport" },
    TNCS: { iata: "SAB", name: "Juancho E. Yrausquin Airport" },
    TQPF: { iata: "AXA", name: "Wallblake Airport" },
    TRPG: { iata: "MNI", name: "John A. Osborne Airport" },
    TTCP: { iata: "TAB", name: "Tobago-Crown Point Airport" },
    TTPP: { iata: "POS", name: "Piarco International Airport" },
    TUPA: { iata: "NGD", name: "Captain Auguste George Airport" },
    TUPJ: { iata: "EIS", name: "Terrance B. Lettsome International Airport" },
    TUPW: { iata: "VIJ", name: "Virgin Gorda Airport" },
    TVSB: { iata: "BQU", name: "J F Mitchell Airport" },
    TVSC: { iata: "CIW", name: "Canouan Airport" },
    TVSM: { iata: "MQS", name: "Mustique Airport" },
    TVSU: { iata: "UNI", name: "Union Island International Airport" },
    TVSV: { iata: "SVD", name: "E. T. Joshua Airport" },
    TXKF: { iata: "BDA", name: "L.F. Wade International International Airport" },
    UAAA: { iata: "ALA", name: "Almaty Airport" },
    UAAH: { iata: "BXH", name: "Balkhash Airport" },
    UAAL: { iata: "USJ", name: "Usharal Airport" },
    UAAR: { iata: "BXJ", name: "Boralday Airport" },
    UAAT: { iata: "TDK", name: "Taldykorgan Airport" },
    UACC: { iata: "NQZ", name: "Astana International Airport" },
    UACK: { iata: "KOV", name: "Kokshetau Airport" },
    UACP: { iata: "PPK", name: "Petropavlosk South Airport" },
    UADD: { iata: "DMB", name: "Taraz Airport" },
    UAII: { iata: "CIT", name: "Shymkent Airport" },
    UAIT: { iata: "HSA", name: "Hazrat Sultan International Airport" },
    UAKD: { iata: "DZN", name: "Zhezkazgan Airport" },
    UAKK: { iata: "KGF", name: "Sary-Arka Airport" },
    UAOL: { iata: "BXY", name: "Krainiy Airport" },
    UAOO: { iata: "KZO", name: "Kzyl-Orda Southwest Airport" },
    UARR: { iata: "URA", name: "Uralsk Airport" },
    UASB: { iata: "EKB", name: "Ekibastuz Airport" },
    UASK: { iata: "UKK", name: "Ust-Kamennogorsk Airport" },
    UASP: { iata: "PWQ", name: "Pavlodar Airport" },
    UASS: { iata: "PLX", name: "Semey Airport" },
    UATE: { iata: "SCO", name: "Aktau Airport" },
    UATG: { iata: "GUW", name: "Atyrau Airport" },
    UATT: { iata: "AKX", name: "Aktobe Airport" },
    UAUR: { iata: "AYK", name: "Arkalyk North Airport" },
    UAUU: { iata: "KSN", name: "Kostanay West Airport" },
    UBBB: { iata: "GYD", name: "Heydar Aliyev International Airport" },
    UBBG: { iata: "GNJ", name: "Ganja Airport" },
    UBBL: { iata: "LLK", name: "Lankaran International Airport" },
    UBBN: { iata: "NAJ", name: "Nakhchivan Airport" },
    UBBQ: { iata: "GBB", name: "Gabala International Airport" },
    UBBY: { iata: "ZTU", name: "Zaqatala International Airport" },
    UBEE: { iata: "YLV", name: "Yevlakh Airport" },
    UCFL: { iata: "IKU", name: "Issyk-Kul International Airport" },
    UCFM: { iata: "FRU", name: "Manas International Airport" },
    UCFO: { iata: "OSS", name: "Osh Airport" },
    UDSG: { iata: "LWN", name: "Gyumri Shirak Airport" },
    UDYZ: { iata: "EVN", name: "Zvartnots International Airport" },
    UEBT: { iata: "UKG", name: "Ust-Kuyga Airport" },
    UECT: { iata: "TLK", name: "Talakan Airport" },
    UEEA: { iata: "ADH", name: "Aldan Airport" },
    UEEE: { iata: "YKS", name: "Yakutsk Airport" },
    UELL: { iata: "CNN", name: "Chulman Airport" },
    UEMA: { iata: "MQJ", name: "Moma Airport" },
    UEMH: { iata: "KDY", name: "Tyopliy Klyuch Airport" },
    UEMM: { iata: "GYG", name: "Magan Airport" },
    UEMO: { iata: "OLZ", name: "Olyokminsk Airport" },
    UEMT: { iata: "USR", name: "Ust-Nera Airport" },
    UEMU: { iata: "UMS", name: "Ust-Maya Airport" },
    UENI: { iata: "VHV", name: "Verkhnevilyuisk Airport" },
    UENN: { iata: "NYR", name: "Nyurba Airport" },
    UENS: { iata: "SUY", name: "Suntar Airport" },
    UENW: { iata: "VYI", name: "Vilyuisk Airport" },
    UERL: { iata: "ULK", name: "Lensk Airport" },
    UERO: { iata: "ONK", name: "Olenyok Airport" },
    UERP: { iata: "PYJ", name: "Polyarny Airport" },
    UERR: { iata: "MJZ", name: "Mirny Airport" },
    UERS: { iata: "SYS", name: "Saskylakh Airport" },
    UESG: { iata: "BGN", name: "Belaya Gora Airport" },
    UESO: { iata: "CKH", name: "Chokurdakh Airport" },
    UESS: { iata: "CYX", name: "Cherskiy Airport" },
    UEST: { iata: "IKS", name: "Tiksi Airport" },
    UESU: { iata: "ZKP", name: "Zyryanka Airport" },
    UEVV: { iata: "ZIX", name: "Zhigansk Airport" },
    UGKO: { iata: "KUT", name: "Kopitnari Airport" },
    UGSB: { iata: "BUS", name: "Batumi International Airport" },
    UGSS: { iata: "SUI", name: "Sukhumi Dranda Airport" },
    UGTB: { iata: "TBS", name: "Tbilisi International Airport" },
    UHBB: { iata: "BQS", name: "Ignatyevo Airport" },
    UHBI: { iata: "GDG", name: "Magdagachi Airport" },
    UHBW: { iata: "TYD", name: "Tynda Airport" },
    UHHH: { iata: "KHV", name: "Khabarovsk-Novy Airport" },
    UHKK: { iata: "KXK", name: "Komsomolsk-on-Amur Airport" },
    UHKM: { iata: "GVN", name: "Maygatka Airport." },
    UHMA: { iata: "DYR", name: "Ugolny Airport" },
    UHMD: { iata: "PVS", name: "Provideniya Bay Airport" },
    UHMK: { iata: "KPW", name: "Keperveem Airport" },
    UHMM: { iata: "GDX", name: "Sokol Airport" },
    UHMO: { iata: "KVM", name: "Markovo Airport" },
    UHMP: { iata: "PWE", name: "Pevek Airport" },
    UHMW: { iata: "SWV", name: "Severo-Evensk Airport" },
    UHNB: { iata: "BQG", name: "Bogorodskoye Airport" },
    UHNN: { iata: "NLI", name: "Nikolayevsk-na-Amure Airport" },
    UHOO: { iata: "OHO", name: "Okhotsk Airport" },
    UHPP: { iata: "PKC", name: "Yelizovo Airport" },
    UHSB: { iata: "BVV", name: "Burevestnik Airport" },
    UHSH: { iata: "OHH", name: "Okha Airport" },
    UHSK: { iata: "EKS", name: "Shakhtyorsk Airport" },
    UHSM: { iata: "DEE", name: "Mendeleyevo Airport" },
    UHSO: { iata: "ZZO", name: "Zonalnoye Airport" },
    UHSS: { iata: "UUS", name: "Yuzhno-Sakhalinsk Airport" },
    UHWP: { iata: "TLY", name: "Plastun Airport" },
    UHWW: { iata: "VVO", name: "Vladivostok International Airport" },
    UIAA: { iata: "HTA", name: "Chita-Kadala Airport" },
    UIBB: { iata: "BTK", name: "Bratsk Airport" },
    UIBS: { iata: "UIK", name: "Ust-Ilimsk Airport" },
    UIII: { iata: "IKT", name: "Irkutsk Airport" },
    UIKB: { iata: "ODO", name: "Bodaybo Airport" },
    UIKE: { iata: "ERG", name: "Yerbogachen Airport" },
    UIKK: { iata: "KCK", name: "Kirensk Airport" },
    UITT: { iata: "UKX", name: "Ust-Kut Airport" },
    UIUU: { iata: "UUD", name: "Ulan-Ude Airport (Mukhino)" },
    UJAP: { iata: "UJE", name: "Ujae Atoll Airport" },
    UKBB: { iata: "KBP", name: "Boryspil International Airport" },
    UKCC: { iata: "DOK", name: "Donetsk International Airport" },
    UKCK: { iata: "KRQ", name: "Kramatorsk Airport" },
    UKCM: { iata: "MPW", name: "Mariupol International Airport" },
    UKCS: { iata: "SEV", name: "Sievierodonetsk Airport" },
    UKCW: { iata: "VSG", name: "Luhansk International Airport" },
    UKDB: { iata: "ERD", name: "Berdyansk Airport" },
    UKDD: { iata: "DNK", name: "Dnipropetrovsk International Airport" },
    UKDE: { iata: "OZH", name: "Zaporizhzhia International Airport" },
    UKDR: { iata: "KWG", name: "Kryvyi Rih International Airport" },
    UKFF: { iata: "SIP", name: "Simferopol International Airport" },
    UKFK: { iata: "KHC", name: "Kerch Airport" },
    UKHH: { iata: "HRK", name: "Kharkiv International Airport" },
    UKHP: { iata: "PLV", name: "Suprunovka Airport" },
    UKHS: { iata: "UMY", name: "Sumy Airport" },
    UKKE: { iata: "CKC", name: "Cherkasy International Airport" },
    UKKG: { iata: "KGO", name: "Kirovograd Airport" },
    UKKK: { iata: "IEV", name: "Kiev Zhuliany International Airport" },
    UKKM: { iata: "GML", name: "Gostomel Airport" },
    UKKV: { iata: "ZTR", name: "Zhytomyr Airport" },
    UKLC: { iata: "UCK", name: "Lutsk Airport" },
    UKLH: { iata: "HMJ", name: "Khmelnytskyi Airport" },
    UKLI: { iata: "IFO", name: "Ivano-Frankivsk International Airport" },
    UKLL: { iata: "LWO", name: "Lviv International Airport" },
    UKLN: { iata: "CWC", name: "Chernivtsi International Airport" },
    UKLR: { iata: "RWN", name: "Rivne International Airport" },
    UKLT: { iata: "TNL", name: "Ternopil International Airport" },
    UKLU: { iata: "UDJ", name: "Uzhhorod International Airport" },
    UKOH: { iata: "KHE", name: "Chernobayevka Airport" },
    UKON: { iata: "NLV", name: "Mykolaiv International Airport" },
    UKOO: { iata: "ODS", name: "Odessa International Airport" },
    UKWW: { iata: "VIN", name: "Vinnytsia/Gavyryshivka Airport" },
    ULAA: { iata: "ARH", name: "Talagi Airport" },
    ULAH: { iata: "VKV", name: "Vaskovo Airport" },
    ULAL: { iata: "LDG", name: "Leshukonskoye Airport" },
    ULAM: { iata: "NNM", name: "Naryan Mar Airport" },
    ULAS: { iata: "CSH", name: "Solovki Airport" },
    ULDD: { iata: "AMV", name: "Amderma Airport" },
    ULDW: { iata: "VRI", name: "Varandey Airport" },
    ULKK: { iata: "KSZ", name: "Kotlas Airport" },
    ULLI: { iata: "LED", name: "Pulkovo Airport" },
    ULMK: { iata: "KVK", name: "Kirovsk-Apatity Airport" },
    ULMM: { iata: "MMK", name: "Murmansk Airport" },
    ULOL: { iata: "VLU", name: "Velikiye Luki Airport" },
    ULOO: { iata: "PKV", name: "Pskov Airport" },
    ULPB: { iata: "PES", name: "Petrozavodsk Airport" },
    ULWC: { iata: "CEE", name: "Cherepovets Airport" },
    ULWU: { iata: "VUS", name: "Velikiy Ustyug Airport" },
    ULWW: { iata: "VGD", name: "Vologda Airport" },
    UMBB: { iata: "BQT", name: "Brest Airport" },
    UMGG: { iata: "GME", name: "Gomel Airport" },
    UMII: { iata: "VTB", name: "Vitebsk East Airport" },
    UMKK: { iata: "KGD", name: "Khrabrovo Airport" },
    UMMG: { iata: "GNA", name: "Hrodna Airport" },
    UMMM: { iata: "MHP", name: "Minsk 1 Airport" },
    UMMS: { iata: "MSQ", name: "Minsk International Airport" },
    UMOO: { iata: "MVQ", name: "Mogilev Airport" },
    UNAA: { iata: "ABA", name: "Abakan Airport" },
    UNBB: { iata: "BAX", name: "Barnaul Airport" },
    UNBG: { iata: "RGK", name: "Gorno-Altaysk Airport" },
    UNEE: { iata: "KEJ", name: "Kemerovo Airport" },
    UNII: { iata: "EIE", name: "Yeniseysk Airport" },
    UNIP: { iata: "TGP", name: "Podkamennaya Tunguska Airport" },
    UNKL: { iata: "KJA", name: "Yemelyanovo Airport" },
    UNKS: { iata: "ACS", name: "Achinsk Airport" },
    UNKY: { iata: "KYZ", name: "Kyzyl Airport" },
    UNNT: { iata: "OVB", name: "Tolmachevo Airport" },
    UNOO: { iata: "OMS", name: "Omsk Central Airport" },
    UNSS: { iata: "SWT", name: "Strezhevoy Airport" },
    UNTT: { iata: "TOF", name: "Bogashevo Airport" },
    UNWW: { iata: "NOZ", name: "Spichenkovo Airport" },
    UODD: { iata: "DKS", name: "Dikson Airport" },
    UOHH: { iata: "HTG", name: "Khatanga Airport" },
    UOII: { iata: "IAA", name: "Igarka Airport" },
    UOOO: { iata: "NSK", name: "Norilsk-Alykel Airport" },
    UOTT: { iata: "THX", name: "Turukhansk Airport" },
    URFB: { iata: "UKS", name: "Belbek Airport" },
    URFF: { iata: "SIP", name: "Simferopol International Airport" },
    URKA: { iata: "AAQ", name: "Anapa Airport" },
    URKE: { iata: "EIK", name: "Yeysk Airport" },
    URKG: { iata: "GDZ", name: "Gelendzhik Airport" },
    URKK: { iata: "KRR", name: "Krasnodar International Airport" },
    URMG: { iata: "GRV", name: "Grozny North Airport" },
    URML: { iata: "MCX", name: "Uytash Airport" },
    URMM: { iata: "MRV", name: "Mineralnyye Vody Airport" },
    URMN: { iata: "NAL", name: "Nalchik Airport" },
    URMO: { iata: "OGZ", name: "Beslan Airport" },
    URMS: { iata: "IGT", name: "Magas Airport" },
    URMT: { iata: "STW", name: "Stavropol Shpakovskoye Airport" },
    URRP: { iata: "ROV", name: "Platov International Airport" },
    URRR: { iata: "RVI", name: "Rostov-na-Donu Airport" },
    URRT: { iata: "TGK", name: "Taganrog Yuzhny Airport" },
    URRY: { iata: "VLK", name: "Zavetnoe Airport" },
    URSS: { iata: "AER", name: "Sochi International Airport" },
    URWA: { iata: "ASF", name: "Astrakhan Airport" },
    URWI: { iata: "ESL", name: "Elista Airport" },
    URWW: { iata: "VOG", name: "Volgograd International Airport" },
    USCC: { iata: "CEK", name: "Chelyabinsk Balandino Airport" },
    USCM: { iata: "MQF", name: "Magnitogorsk International Airport" },
    USDA: { iata: "SBT", name: "Sabetta Airport" },
    USDB: { iata: "BVJ", name: "Bovanenkovo" },
    USDD: { iata: "SLY", name: "Salekhard Airport" },
    USDK: { iata: "YMK", name: "Mys Kamenny Airport" },
    USDP: { iata: "KKQ", name: "Krasnoselkup Airport" },
    USDS: { iata: "TQL", name: "Tarko-Sale Airport" },
    USDU: { iata: "UEN", name: "Urengoy Airport" },
    USHB: { iata: "EZV", name: "Berezovo Airport" },
    USHH: { iata: "HMA", name: "Khanty Mansiysk Airport" },
    USHI: { iata: "IRM", name: "Igrim Airport" },
    USHK: { iata: "KXD", name: "Kondinskoye Airport" },
    USHN: { iata: "NYA", name: "Nyagan Airport" },
    USHS: { iata: "OVS", name: "Sovetskiy Airport" },
    USHU: { iata: "URJ", name: "Uray Airport" },
    USII: { iata: "IJK", name: "Izhevsk Airport" },
    USKK: { iata: "KVX", name: "Pobedilovo Airport" },
    USMM: { iata: "NYM", name: "Nadym Airport" },
    USMU: { iata: "NUX", name: "Novy Urengoy Airport" },
    USNN: { iata: "NJC", name: "Nizhnevartovsk Airport" },
    USPP: { iata: "PEE", name: "Bolshoye Savino Airport" },
    USRK: { iata: "KGP", name: "Kogalym International Airport" },
    USRN: { iata: "NFG", name: "Nefteyugansk Airport" },
    USRO: { iata: "NOJ", name: "Noyabrsk Airport" },
    USRR: { iata: "SGC", name: "Surgut Airport" },
    USSS: { iata: "SVX", name: "Koltsovo Airport" },
    USTO: { iata: "TOX", name: "Tobolsk Airport" },
    USTR: { iata: "TJM", name: "Roshchino International Airport" },
    USUU: { iata: "KRO", name: "Kurgan Airport" },
    UTAA: { iata: "ASB", name: "Ashgabat Airport" },
    UTAK: { iata: "KRW", name: "Turkmenbashi Airport" },
    UTAM: { iata: "MYP", name: "Mary Airport" },
    UTAT: { iata: "TAZ", name: "Dashoguz Airport" },
    UTAV: { iata: "CRZ", name: "Turkmenabat Airport" },
    UTDD: { iata: "DYU", name: "Dushanbe Airport" },
    UTDK: { iata: "TJU", name: "Kulob Airport" },
    UTDL: { iata: "LBD", name: "Khudzhand Airport" },
    UTDT: { iata: "KQT", name: "Qurghonteppa International Airport" },
    UTKA: { iata: "AZN", name: "Andizhan Airport" },
    UTKF: { iata: "FEG", name: "Fergana Airport" },
    UTKN: { iata: "NMA", name: "Namangan Airport" },
    UTNN: { iata: "NCU", name: "Nukus Airport" },
    UTNU: { iata: "UGC", name: "Urgench Airport" },
    UTSA: { iata: "NVI", name: "Navoi Airport" },
    UTSB: { iata: "BHK", name: "Bukhara Airport" },
    UTSK: { iata: "KSQ", name: "Karshi Airport" },
    UTSL: { iata: "KSQ", name: "Karshi Khanabad Airport" },
    UTSN: { iata: "AFS", name: "Sugraly Airport" },
    UTSS: { iata: "SKD", name: "Samarkand Airport" },
    UTST: { iata: "TMJ", name: "Termez Airport" },
    UTTT: { iata: "TAS", name: "Tashkent International Airport" },
    UUBA: { iata: "KMW", name: "Kostroma Sokerkino Airport" },
    UUBB: { iata: "BKA", name: "Bykovo Airport" },
    UUBC: { iata: "KLF", name: "Grabtsevo Airport" },
    UUBI: { iata: "IWA", name: "Ivanovo South Airport" },
    UUBK: { iata: "RYB", name: "Staroselye Airport" },
    UUBP: { iata: "BZK", name: "Bryansk Airport" },
    UUBS: { iata: "LNX", name: "Smolensk South Airport" },
    UUBW: { iata: "ZIA", name: "Ramenskoye Airport" },
    UUDD: { iata: "DME", name: "Domodedovo International Airport" },
    UUDL: { iata: "IAR", name: "Tunoshna Airport" },
    UUEE: { iata: "SVO", name: "Sheremetyevo International Airport" },
    UUEM: { iata: "KLD", name: "Migalovo Air Base" },
    UUMO: { iata: "OSF", name: "Ostafyevo International Airport" },
    UUMU: { iata: "CKL", name: "Chkalovskiy Airport" },
    UUOB: { iata: "EGO", name: "Belgorod International Airport" },
    UUOK: { iata: "URS", name: "Kursk East Airport" },
    UUOL: { iata: "LPK", name: "Lipetsk Airport" },
    UUOO: { iata: "VOZ", name: "Voronezh International Airport" },
    UUOR: { iata: "OEL", name: "Oryol Yuzhny Airport" },
    UUOT: { iata: "TBW", name: "Donskoye Airport" },
    UUWR: { iata: "RZN", name: "Turlatovo Airport" },
    UUWW: { iata: "VKO", name: "Vnukovo International Airport" },
    UUYH: { iata: "UCT", name: "Ukhta Airport" },
    UUYI: { iata: "INA", name: "Inta Airport" },
    UUYP: { iata: "PEX", name: "Pechora Airport" },
    UUYS: { iata: "USK", name: "Usinsk Airport" },
    UUYW: { iata: "VKT", name: "Vorkuta Airport" },
    UUYX: { iata: "UTS", name: "Ust-Tsylma Airport" },
    UUYY: { iata: "SCW", name: "Syktyvkar Airport" },
    UWGG: { iata: "GOJ", name: "Nizhny Novgorod International Airport" },
    UWKB: { iata: "UUA", name: "Bugulma Airport" },
    UWKD: { iata: "KZN", name: "Kazan International Airport" },
    UWKE: { iata: "NBC", name: "Begishevo Airport" },
    UWKJ: { iata: "JOK", name: "Yoshkar-Ola Airport" },
    UWKS: { iata: "CSY", name: "Cheboksary Airport" },
    UWLL: { iata: "ULV", name: "Ulyanovsk Baratayevka Airport" },
    UWLW: { iata: "ULY", name: "Ulyanovsk East Airport" },
    UWOO: { iata: "REN", name: "Orenburg Central Airport" },
    UWOR: { iata: "OSW", name: "Orsk Airport" },
    UWPP: { iata: "PEZ", name: "Penza Airport" },
    UWPS: { iata: "SKX", name: "Saransk Airport" },
    UWSB: { iata: "BWO", name: "Balakovo Airport" },
    UWSG: { iata: "GSV", name: "Saratov Gagarin Airport" },
    UWSS: { iata: "RTW", name: "Saratov Central Airport" },
    UWUB: { iata: "BCX", name: "Beloretsk Airport" },
    UWUF: { iata: "NEF", name: "Neftekamsk Airport" },
    UWUK: { iata: "OKT", name: "Oktyabrskiy Airport" },
    UWUU: { iata: "UFA", name: "Ufa International Airport" },
    UWWW: { iata: "KUF", name: "Kurumoch International Airport" },
    VA1P: { iata: "DIU", name: "Diu Airport" },
    VAAH: { iata: "AMD", name: "Sardar Vallabhbhai Patel International Airport" },
    VAAK: { iata: "AKD", name: "Akola Airport" },
    VAAU: { iata: "IXU", name: "Aurangabad Airport" },
    VABB: { iata: "BOM", name: "Chhatrapati Shivaji International Airport" },
    VABI: { iata: "PAB", name: "Bilaspur Airport" },
    VABJ: { iata: "BHJ", name: "Bhuj Airport" },
    VABM: { iata: "IXG", name: "Belgaum Airport" },
    VABO: { iata: "BDQ", name: "Vadodara Airport" },
    VABP: { iata: "BHO", name: "Bhopal Airport" },
    VABV: { iata: "BHU", name: "Bhavnagar Airport" },
    VADN: { iata: "NMB", name: "Daman Airport" },
    VAGN: { iata: "GUX", name: "Guna Airport" },
    VAHB: { iata: "HBX", name: "Hubli Airport" },
    VAID: { iata: "IDR", name: "Devi Ahilyabai Holkar Airport" },
    VAJB: { iata: "JLR", name: "Jabalpur Airport" },
    VAJM: { iata: "JGA", name: "Jamnagar Airport" },
    VAKE: { iata: "IXY", name: "Kandla Airport" },
    VAKJ: { iata: "HJR", name: "Khajuraho Airport" },
    VAKP: { iata: "KLH", name: "Kolhapur Airport" },
    VAKS: { iata: "IXK", name: "Keshod Airport" },
    VALT: { iata: "LTU", name: "Murod Kond Airport" },
    VAND: { iata: "NDC", name: "Nanded Airport" },
    VANP: { iata: "NAG", name: "Dr. Babasaheb Ambedkar International Airport" },
    VANR: { iata: "ISK", name: "Gandhinagar Airport" },
    VAOZ: { iata: "ISK", name: "Ozar Air Force Station" },
    VAPO: { iata: "PNQ", name: "Pune Airport" },
    VAPR: { iata: "PBD", name: "Porbandar Airport" },
    VARG: { iata: "RTC", name: "Ratnagiri Airport" },
    VARK: { iata: "RAJ", name: "Rajkot Airport" },
    VARP: { iata: "RPR", name: "Raipur Airport" },
    VASD: { iata: "SAG", name: "Shirdi Airport" },
    VASL: { iata: "SSE", name: "Solapur Airport" },
    VASU: { iata: "STV", name: "Surat Airport" },
    VAUD: { iata: "UDR", name: "Maharana Pratap Airport" },
    VCBI: { iata: "CMB", name: "Bandaranaike International Colombo Airport" },
    VCCA: { iata: "ACJ", name: "Anuradhapura Air Force Base" },
    VCCB: { iata: "BTC", name: "Batticaloa Airport" },
    VCCC: { iata: "RML", name: "Colombo Ratmalana Airport" },
    VCCG: { iata: "GOY", name: "Amparai Airport" },
    VCCH: { iata: "MNH", name: "Hingurakgoda Air Force Base" },
    VCCJ: { iata: "JAF", name: "Kankesanturai Airport" },
    VCCK: { iata: "KCT", name: "Koggala Airport" },
    VCCN: { iata: "KTY", name: "Katukurunda Air Force Base" },
    VCCS: { iata: "GIU", name: "Sigiriya Air Force Base" },
    VCCT: { iata: "TRR", name: "China Bay Airport" },
    VCCW: { iata: "WRZ", name: "Weerawila International Airport" },
    VCRI: { iata: "HRI", name: "Mattala Rajapaksa International Airport" },
    VDBG: { iata: "BBM", name: "Battambang Airport" },
    VDKH: { iata: "KZC", name: "Kampong Chhnang Airport" },
    VDKK: { iata: "KKZ", name: "Kaoh Kong Airport" },
    VDKT: { iata: "KTI", name: "Kratie Airport" },
    VDMK: { iata: "MWV", name: "Mondulkiri Airport" },
    VDPP: { iata: "PNH", name: "Phnom Penh International Airport" },
    VDRK: { iata: "RBE", name: "Ratanakiri Airport" },
    VDSR: { iata: "REP", name: "Angkor International Airport" },
    VDST: { iata: "TNX", name: "Stung Treng Airport" },
    VDSV: { iata: "KOS", name: "Sihanoukville International Airport" },
    VEDH: { iata: "DBR", name: "Darbhanga Airport" },
    VEAN: { iata: "IXV", name: "Along Airport" },
    VEAT: { iata: "IXA", name: "Agartala Airport" },
    VEBD: { iata: "IXB", name: "Bagdogra Airport" },
    VEBG: { iata: "RGH", name: "Balurghat Airport" },
    VEBI: { iata: "SHL", name: "Shillong Airport" },
    VEBN: { iata: "VNS", name: "Lal Bahadur Shastri Airport" },
    VEBS: { iata: "BBI", name: "Biju Patnaik Airport" },
    VECC: {
      iata: "CCU",
      name: "Netaji Subhash Chandra Bose International Airport",
    },
    VECO: { iata: "COH", name: "Cooch Behar Airport" },
    VEDB: { iata: "DBD", name: "Dhanbad Airport" },
    VEDO: { iata: "DGH", name: "Deoghar Airport" },
    VEDZ: { iata: "DAE", name: "Daporijo Airport" },
    VEGK: { iata: "GOP", name: "Gorakhpur Airport" },
    VEGT: {
      iata: "GAU",
      name: "Lokpriya Gopinath Bordoloi International Airport",
    },
    VEGY: { iata: "GAY", name: "Gaya Airport" },
    VEIM: { iata: "IMF", name: "Imphal Airport" },
    VEJH: { iata: "JRG", name: "Jharsuguda Airport" },
    VEJP: { iata: "PYB", name: "Jeypore Airport" },
    VEJS: { iata: "IXW", name: "Jamshedpur Airport" },
    VEJT: { iata: "JRH", name: "Jorhat Airport" },
    VEKM: { iata: "IXQ", name: "Kamalpur Airport" },
    VEKR: { iata: "IXH", name: "Kailashahar Airport" },
    VEKU: { iata: "IXS", name: "Silchar Airport" },
    VEKW: { iata: "IXN", name: "Khowai Airport" },
    VELP: { iata: "AJL", name: "Lengpui Airport" },
    VELR: { iata: "IXI", name: "North Lakhimpur Airport" },
    VEMH: { iata: "LDA", name: "Malda Airport" },
    VEMN: { iata: "DIB", name: "Dibrugarh Airport" },
    VEMR: { iata: "DMU", name: "Dimapur Airport" },
    VEMZ: { iata: "MZU", name: "Muzaffarpur Airport" },
    VEPG: { iata: "IXT", name: "Pasighat Airport" },
    VEPT: { iata: "PAT", name: "Lok Nayak Jayaprakash Airport" },
    VERC: { iata: "IXR", name: "Birsa Munda Airport" },
    VERK: { iata: "RRK", name: "Rourkela Airport" },
    VERU: { iata: "RUP", name: "Rupsi India Airport" },
    VETJ: { iata: "TEI", name: "Tezu Airport" },
    VETZ: { iata: "TEZ", name: "Tezpur Airport" },
    VEVZ: { iata: "VTZ", name: "Vishakhapatnam Airport" },
    VEZO: { iata: "ZER", name: "Zero Airport" },
    VGBR: { iata: "BZL", name: "Barisal Airport" },
    VGCB: { iata: "CXB", name: "Cox's Bazar Airport" },
    VGCM: { iata: "CLA", name: "Comilla Airport" },
    VGEG: { iata: "CGP", name: "Shah Amanat International Airport" },
    VGHS: { iata: "DAC", name: "Dhaka / Hazrat Shahjalal International Airport" },
    VGIS: { iata: "IRD", name: "Ishurdi Airport" },
    VGJR: { iata: "JSR", name: "Jessore Airport" },
    VGLM: { iata: "LLJ", name: "Lalmonirhat Airport" },
    VGRJ: { iata: "RJH", name: "Shah Mokhdum Airport" },
    VGSD: { iata: "SPD", name: "Saidpur Airport" },
    VGSG: { iata: "TKR", name: "Thakurgaon Airport" },
    VGSH: { iata: "ZHM", name: "Shamshernagar Airport" },
    VGSY: { iata: "ZYL", name: "Osmany International Airport" },
    VHHH: { iata: "HKG", name: "Chek Lap Kok International Airport" },
    VIAG: { iata: "AGR", name: "Agra Airport" },
    VIAL: { iata: "IXD", name: "Allahabad Airport" },
    VIAR: {
      iata: "ATQ",
      name: "Sri Guru Ram Dass Jee International Airport Amritsar",
    },
    VIAX: { iata: "AIP", name: "Adampur Air Force Station" },
    VIBK: { iata: "BKB", name: "Nal Airport" },
    VIBN: { iata: "VNS", name: "Lal Bahadur Shastri Airport" },
    VIBR: { iata: "KUU", name: "Kullu Manali Airport" },
    VIBT: { iata: "BUP", name: "Bhatinda Air Force Station" },
    VIBY: { iata: "BEK", name: "Bareilly Air Force Station" },
    VICG: { iata: "IXC", name: "Chandigarh Airport" },
    VIDN: { iata: "DED", name: "Dehradun Airport" },
    VIDP: { iata: "DEL", name: "Indira Gandhi International Airport" },
    VIGG: { iata: "DHM", name: "Kangra Airport" },
    VIGR: { iata: "GWL", name: "Gwalior Airport" },
    VIHR: { iata: "HSS", name: "Hissar Airport" },
    VIJO: { iata: "JDH", name: "Jodhpur Airport" },
    VIJP: { iata: "JAI", name: "Jaipur International Airport" },
    VIJR: { iata: "JSA", name: "Jaisalmer Airport" },
    VIJU: { iata: "IXJ", name: "Jammu Airport" },
    VIKA: { iata: "KNU", name: "Kanpur Airport" },
    VIKG: { iata: "KQH", name: "Kishangarh Airport, Ajmer" },
    VIKO: { iata: "KTU", name: "Kota Airport" },
    VILD: { iata: "LUH", name: "Ludhiana Airport" },
    VILH: { iata: "IXL", name: "Leh Kushok Bakula Rimpochee Airport" },
    VILK: { iata: "LKO", name: "Chaudhary Charan Singh International Airport" },
    VIPK: { iata: "IXP", name: "Pathankot Air Force Station" },
    VIPT: { iata: "PGH", name: "Pantnagar Airport" },
    VISM: { iata: "SLV", name: "Shimla Airport" },
    VISR: { iata: "SXR", name: "Sheikh ul Alam Airport" },
    VIST: { iata: "TNI", name: "Satna Airport" },
    VLAP: { iata: "AOU", name: "Attopeu Airport" },
    VLHS: { iata: "OUI", name: "Ban Huoeisay Airport" },
    VLLB: { iata: "LPQ", name: "Luang Phabang International Airport" },
    VLLN: { iata: "LXG", name: "Luang Namtha Airport" },
    VLOS: { iata: "ODY", name: "Oudomsay Airport" },
    VLPS: { iata: "PKZ", name: "Pakse International Airport" },
    VLSB: { iata: "ZBY", name: "Sayaboury Airport" },
    VLSK: { iata: "ZVK", name: "Savannakhet Airport" },
    VLSN: { iata: "NEU", name: "Sam Neua Airport" },
    VLSV: { iata: "VNA", name: "Saravane Airport" },
    VLTK: { iata: "THK", name: "Thakhek Airport" },
    VLVT: { iata: "VTE", name: "Wattay International Airport" },
    VLXK: { iata: "XKH", name: "Xieng Khouang Airport" },
    VMMC: { iata: "MFM", name: "Macau International Airport" },
    VNBG: { iata: "BJH", name: "Bajhang Airport" },
    VNBJ: { iata: "BHP", name: "Bhojpur Airport" },
    VNBL: { iata: "BGL", name: "Baglung Airport" },
    VNBP: { iata: "BHR", name: "Bharatpur Airport" },
    VNBR: { iata: "BJU", name: "Bajura Airport" },
    VNBT: { iata: "BIT", name: "Baitadi Airport" },
    VNBW: { iata: "BWA", name: "Bhairahawa Airport" },
    VNCG: { iata: "BDP", name: "Bhadrapur Airport" },
    VNDG: { iata: "DNP", name: "Tulsipur Airport" },
    VNDH: { iata: "DHI", name: "Dhangarhi Airport" },
    VNDL: { iata: "DAP", name: "Darchula Airport" },
    VNDP: { iata: "DOP", name: "Dolpa Airport" },
    VNDT: { iata: "SIH", name: "Silgadi Doti Airport" },
    VNGK: { iata: "GKH", name: "Gorkha Airport" },
    VNJI: { iata: "JIR", name: "Jiri Airport" },
    VNJL: { iata: "JUM", name: "Jumla Airport" },
    VNJP: { iata: "JKR", name: "Janakpur Airport" },
    VNJS: { iata: "JMO", name: "Jomsom Airport" },
    VNKT: { iata: "KTM", name: "Tribhuvan International Airport" },
    VNLD: { iata: "LDN", name: "Lamidanda Airport" },
    VNLK: { iata: "LUA", name: "Lukla Airport" },
    VNLT: { iata: "LTG", name: "Langtang Airport" },
    VNMA: { iata: "NGX", name: "Manang Airport" },
    VNMG: { iata: "MEY", name: "Meghauli Airport" },
    VNMN: { iata: "XMG", name: "Mahendranagar Airport" },
    VNNG: { iata: "KEP", name: "Nepalgunj Airport" },
    VNPK: { iata: "PKR", name: "Pokhara Airport" },
    VNPL: { iata: "PPL", name: "Phaplu Airport" },
    VNRB: { iata: "RJB", name: "Rajbiraj Airport" },
    VNRC: { iata: "RHP", name: "Ramechhap Airport" },
    VNRK: { iata: "RUK", name: "Rukumkot Airport" },
    VNRP: { iata: "RPA", name: "Rolpa Airport" },
    VNRT: { iata: "RUM", name: "Rumjatar Airport" },
    VNSI: { iata: "SIF", name: "Simara Airport" },
    VNSK: { iata: "SKH", name: "Surkhet Airport" },
    VNSR: { iata: "FEB", name: "Sanfebagar Airport" },
    VNST: { iata: "IMK", name: "Simikot Airport" },
    VNTJ: { iata: "TPJ", name: "Suketar Airport" },
    VNTP: { iata: "TPU", name: "Tikapur Airport" },
    VNTR: { iata: "TMI", name: "Tumling Tar Airport" },
    VNVT: { iata: "BIR", name: "Biratnagar Airport" },
    VOTK: { iata: "TCR", name: "Tuticorin Airport" },
    VOAT: { iata: "AGX", name: "Agatti Airport" },
    VOBI: { iata: "BEP", name: "Bellary Airport" },
    VOBL: { iata: "BLR", name: "Bengaluru International Airport" },
    VOBZ: { iata: "VGA", name: "Vijayawada Airport" },
    VOCB: { iata: "CJB", name: "Coimbatore International Airport" },
    VOCI: { iata: "COK", name: "Cochin International Airport" },
    VOCL: { iata: "CCJ", name: "Calicut International Airport" },
    VOCP: { iata: "CDP", name: "Cuddapah Airport" },
    VOCX: { iata: "CBD", name: "Car Nicobar Air Force Station" },
    VOGO: { iata: "GOI", name: "Dabolim Airport" },
    VOHS: { iata: "HYD", name: "Rajiv Gandhi International Airport Shamshabad" },
    VOHY: { iata: "BPM", name: "Begumpet Airport" },
    VOJV: { iata: "VDY", name: "Vijayanagar Aerodrome (JSW)" },
    VOKN: { iata: "CNN", name: "Kannur International Airport" },
    VOMD: { iata: "IXM", name: "Madurai Airport" },
    VOML: { iata: "IXE", name: "Mangalore International Airport" },
    VOMM: { iata: "MAA", name: "Chennai International Airport" },
    VOMY: { iata: "MYQ", name: "Mysore Airport" },
    VOPB: { iata: "IXZ", name: "Vir Savarkar International Airport" },
    VOPC: { iata: "PNY", name: "Pondicherry Airport" },
    VORY: { iata: "RJA", name: "Rajahmundry Airport" },
    VOSM: { iata: "SXV", name: "Salem Airport" },
    VOTJ: { iata: "TJV", name: "Tanjore Air Force Base" },
    VOTP: { iata: "TIR", name: "Tirupati Airport" },
    VOTR: { iata: "TRZ", name: "Tiruchirapally Civil Airport Airport" },
    VOTV: { iata: "TRV", name: "Trivandrum International Airport" },
    VOWA: { iata: "WGC", name: "Warangal Airport" },
    VQPR: { iata: "PBH", name: "Paro Airport" },
    VRBK: { iata: "HDK", name: "Kulhudhuffushi Airport" },
    VREI: { iata: "IFU", name: "Ifuru Airport" },
    VRMF: { iata: "FVM", name: "Fuvahmulah Airport" },
    VRMG: { iata: "GAN", name: "Gan International Airport" },
    VRMH: { iata: "HAQ", name: "Hanimaadhoo Airport" },
    VRMK: { iata: "KDO", name: "Kadhdhoo Airport" },
    VRMM: { iata: "MLE", name: "Male International Airport" },
    VRMT: { iata: "KDM", name: "Kaadedhdhoo Airport" },
    VRMV: { iata: "VAM", name: "Villa Airport" },
    VTBD: { iata: "DMK", name: "Don Mueang International Airport" },
    VTBH: { iata: "KKM", name: "Sa Pran Nak Airport" },
    VTBK: { iata: "KDT", name: "Kamphaeng Saen Airport" },
    VTBL: { iata: "KKM", name: "Khok Kathiam Airport" },
    VTBO: { iata: "TDX", name: "Trat Airport" },
    VTBS: { iata: "BKK", name: "Suvarnabhumi Airport" },
    VTBU: { iata: "UTP", name: "U-Tapao International Airport" },
    VTCC: { iata: "CNX", name: "Chiang Mai International Airport" },
    VTCH: { iata: "HGN", name: "Mae Hong Son Airport" },
    VTCI: { iata: "PYY", name: "Mae Hong Son Airport" },
    VTCL: { iata: "LPT", name: "Lampang Airport" },
    VTCN: { iata: "NNT", name: "Nan Airport" },
    VTCP: { iata: "PRH", name: "Phrae Airport" },
    VTCT: { iata: "CEI", name: "Chiang Rai International Airport" },
    VTED: { iata: "BAO", name: "Udorn Air Base" },
    VTPB: { iata: "PHY", name: "Phetchabun Airport" },
    VTPH: { iata: "HHQ", name: "Hua Hin Airport" },
    VTPI: { iata: "TKH", name: "Takhli Airport" },
    VTPM: { iata: "MAQ", name: "Mae Sot Airport" },
    VTPO: { iata: "THS", name: "Sukhothai Airport" },
    VTPP: { iata: "PHS", name: "Phitsanulok Airport" },
    VTPT: { iata: "TKT", name: "Tak Airport" },
    VTPU: { iata: "UTR", name: "Uttaradit Airport" },
    VTSB: { iata: "URT", name: "Surat Thani Airport" },
    VTSC: { iata: "NAW", name: "Narathiwat Airport" },
    VTSE: { iata: "CJM", name: "Chumphon Airport" },
    VTSF: { iata: "NST", name: "Nakhon Si Thammarat Airport" },
    VTSG: { iata: "KBV", name: "Krabi Airport" },
    VTSH: { iata: "SGZ", name: "Songkhla Airport" },
    VTSK: { iata: "PAN", name: "Pattani Airport" },
    VTSM: { iata: "USM", name: "Samui Airport" },
    VTSP: { iata: "HKT", name: "Phuket International Airport" },
    VTSR: { iata: "UNN", name: "Ranong Airport" },
    VTSS: { iata: "HDY", name: "Hat Yai International Airport" },
    VTST: { iata: "TST", name: "Trang Airport" },
    VTUD: { iata: "UTH", name: "Udon Thani Airport" },
    VTUI: { iata: "SNO", name: "Sakon Nakhon Airport" },
    VTUJ: { iata: "PXR", name: "Surin Airport" },
    VTUK: { iata: "KKC", name: "Khon Kaen Airport" },
    VTUL: { iata: "LOE", name: "Loei Airport" },
    VTUO: { iata: "BFV", name: "Buri Ram Airport" },
    VTUQ: { iata: "NAK", name: "Nakhon Ratchasima Airport" },
    VTUU: { iata: "UBP", name: "Ubon Ratchathani Airport" },
    VTUV: { iata: "ROI", name: "Roi Et Airport" },
    VTUW: { iata: "KOP", name: "Nakhon Phanom Airport" },
    VVBM: { iata: "BMV", name: "Buon Ma Thuot Airport" },
    VVCA: { iata: "VCL", name: "Chu Lai International Airport" },
    VVCI: { iata: "HPH", name: "Cat Bi International Airport" },
    VVCM: { iata: "CAH", name: "Ca Mau Airport" },
    VVCR: { iata: "CXR", name: "Cam Ranh Airport" },
    VVCS: { iata: "VCS", name: "Co Ong Airport" },
    VVCT: { iata: "VCA", name: "Tra Noc Airport" },
    VVDB: { iata: "DIN", name: "Dien Bien Phu Airport" },
    VVDH: { iata: "VDH", name: "Dong Hoi Airport" },
    VVDL: { iata: "DLI", name: "Lien Khuong Airport" },
    VVDN: { iata: "DAD", name: "Da Nang International Airport" },
    VVNB: { iata: "HAN", name: "Noi Bai International Airport" },
    VVNS: { iata: "SQH", name: "Na-San Airport" },
    VVNT: { iata: "NHA", name: "Nha Trang Air Base" },
    VVPB: { iata: "HUI", name: "Phu Bai Airport" },
    VVPC: { iata: "UIH", name: "Phu Cat Airport" },
    VVPK: { iata: "PXU", name: "Pleiku Airport" },
    VVPQ: { iata: "PQC", name: "Phu Quoc Airport" },
    VVPR: { iata: "PHA", name: "Phan Rang Airport" },
    VVPT: { iata: "PHH", name: "Phan Thiet Airport" },
    VVRG: { iata: "VKG", name: "Rach Gia Airport" },
    VVTH: { iata: "TBB", name: "Dong Tac Airport" },
    VVTS: { iata: "SGN", name: "Tan Son Nhat International Airport" },
    VVTX: { iata: "THD", name: "Thọ Xuân Airport" },
    VVVH: { iata: "VII", name: "Vinh Airport" },
    VVVT: { iata: "VTG", name: "Vung Tau Airport" },
    VYAN: { iata: "VBA", name: "Ann Airport" },
    VYBG: { iata: "NYU", name: "Bagan Airport" },
    VYBM: { iata: "BMO", name: "Banmaw Airport" },
    VYBP: { iata: "VBP", name: "Bokpyinn Airport" },
    VYCZ: { iata: "VBC", name: "Chanmyathazi Airport" },
    VYDW: { iata: "TVY", name: "Dawei Airport" },
    VYEL: { iata: "NYT", name: "Naypyidaw Airport" },
    VYGG: { iata: "GAW", name: "Gangaw Airport" },
    VYGW: { iata: "GWA", name: "Gwa Airport" },
    VYHH: { iata: "HEH", name: "Heho Airport" },
    VYHL: { iata: "HOX", name: "Hommalinn Airport" },
    VYHN: { iata: "TIO", name: "Tilin Airport" },
    VYKG: { iata: "KET", name: "Kengtung Airport" },
    VYKI: { iata: "KHM", name: "Kanti Airport" },
    VYKL: { iata: "KMV", name: "Kalay Airport" },
    VYKP: { iata: "KYP", name: "Kyaukpyu Airport" },
    VYKT: { iata: "KAW", name: "Kawthoung Airport" },
    VYKU: { iata: "KYT", name: "Kyauktu Airport" },
    VYLK: { iata: "LIW", name: "Loikaw Airport" },
    VYLS: { iata: "LSH", name: "Lashio Airport" },
    VYMD: { iata: "MDL", name: "Mandalay International Airport" },
    VYME: { iata: "MGZ", name: "Myeik Airport" },
    VYMK: { iata: "MYT", name: "Myitkyina Airport" },
    VYMM: { iata: "MNU", name: "Mawlamyine Airport" },
    VYMN: { iata: "MGU", name: "Manaung Airport" },
    VYMO: { iata: "MOE", name: "Momeik Airport" },
    VYMS: { iata: "MOG", name: "Mong Hsat Airport" },
    VYMT: { iata: "MGK", name: "Mong Tong Airport" },
    VYMW: { iata: "MWQ", name: "Magway Airport" },
    VYMY: { iata: "NYW", name: "Monywar Airport" },
    VYNS: { iata: "NMS", name: "Namsang Airport" },
    VYNT: { iata: "NMT", name: "Namtu Airport" },
    VYPA: { iata: "PAA", name: "Hpa-N Airport" },
    VYPK: { iata: "PAU", name: "Pauk Airport" },
    VYPN: { iata: "BSX", name: "Pathein Airport" },
    VYPP: { iata: "PPU", name: "Hpapun Airport" },
    VYPT: { iata: "PBU", name: "Putao Airport" },
    VYPU: { iata: "PKK", name: "Pakhokku Airport" },
    VYPY: { iata: "PRU", name: "Pyay Airport" },
    VYSW: { iata: "AKY", name: "Sittwe Airport" },
    VYTD: { iata: "SNW", name: "Thandwe Airport" },
    VYTL: { iata: "THL", name: "Tachileik Airport" },
    VYYE: { iata: "XYE", name: "Ye Airport" },
    VYYY: { iata: "RGN", name: "Yangon International Airport" },
    WA44: { iata: "TQQ", name: "Maranggo Airport" },
    WAAA: { iata: "UPG", name: "Hasanuddin International Airport" },
    WABB: { iata: "BIK", name: "Frans Kaisiepo Airport" },
    WABD: { iata: "ONI", name: "Moanamani Airport" },
    WABF: { iata: "FOO", name: "Kornasoren Airfield" },
    WABG: { iata: "WET", name: "Wagethe Airport" },
    WABI: { iata: "NBX", name: "Nabire Airport" },
    WABL: { iata: "ILA", name: "Illaga Airport" },
    WABN: { iata: "KOX", name: "Kokonau Airport" },
    WABO: { iata: "ZRI", name: "Serui Airport" },
    WABP: { iata: "TIM", name: "Moses Kilangin Airport" },
    WABT: { iata: "EWI", name: "Enarotali Airport" },
    WADA: { iata: "AMI", name: "Selaparang Airport" },
    WADB: { iata: "BMU", name: "Muhammad Salahuddin Airport" },
    WADD: { iata: "DPS", name: "Ngurah Rai (Bali) International Airport" },
    WADL: { iata: "LOP", name: "Bandara International Lombok Airport" },
    WADS: { iata: "SWQ", name: "Sumbawa Besar Airport" },
    WADT: { iata: "TMC", name: "Tambolaka Airport" },
    WADW: { iata: "WGP", name: "Waingapu Airport" },
    WAHI: { iata: "YIA", name: "Yogyakarta International Airport" },
    WAJA: { iata: "ARJ", name: "Arso Airport" },
    WAJB: { iata: "BUI", name: "Bokondini Airport" },
    WAJI: { iata: "ZRM", name: "Sarmi Airport" },
    WAJJ: { iata: "DJJ", name: "Sentani Airport" },
    WAJL: { iata: "LHI", name: "Lereh Airport" },
    WAJM: { iata: "LII", name: "Mulia Airport" },
    WAJO: { iata: "OKL", name: "Oksibil Airport" },
    WAJR: { iata: "WAR", name: "Waris Airport" },
    WAJS: { iata: "SEH", name: "Senggeh Airport" },
    WAJU: { iata: "UBR", name: "Ubrub Airport" },
    WAJW: { iata: "WMX", name: "Wamena Airport" },
    WAKD: { iata: "MDP", name: "Mindiptana Airport" },
    WAKE: { iata: "BXD", name: "Bade Airport" },
    WAKK: { iata: "MKQ", name: "Mopah Airport" },
    WAKO: { iata: "OKQ", name: "Okaba Airport" },
    WAKP: { iata: "KEI", name: "Kepi Airport" },
    WAKT: { iata: "TMH", name: "Tanah Merah Airport" },
    WALG: { iata: "TJS", name: "Tanjung Harapan Airport" },
    WALJ: { iata: "DTD", name: "Datadawai Airport" },
    WALK: { iata: "BEJ", name: "Barau(Kalimaru) Airport" },
    WALL: { iata: "BPN", name: "Sepinggan International Airport" },
    WALR: { iata: "TRK", name: "Juwata Airport" },
    WALS: {
      iata: "AAP",
      name: "Aji Pangeran Tumenggung Pranoto International Airport",
    },
    WALT: { iata: "TSX", name: "Tanjung Santan Airport" },
    WALV: { iata: "BYQ", name: "Bunyu Airport" },
    WAMA: { iata: "GLX", name: "Gamarmalamo Airport" },
    WAMG: { iata: "GTO", name: "Jalaluddin Airport" },
    WAMH: { iata: "NAH", name: "Naha Airport" },
    WAMI: { iata: "TLI", name: "Toli Toli Airport" },
    WAMJ: { iata: "GEB", name: "Gebe Airport" },
    WAMK: { iata: "KAZ", name: "Kao Airport" },
    WAML: { iata: "PLW", name: "Mutiara Airport" },
    WAMM: { iata: "MDC", name: "Sam Ratulangi Airport" },
    WAMN: { iata: "MNA", name: "Melangguane Airport" },
    WAMP: { iata: "PSJ", name: "Kasiguncu Airport" },
    WAMR: { iata: "OTI", name: "Pitu Airport" },
    WAMT: { iata: "TTE", name: "Sultan Khairun Babullah Airport" },
    WAMW: { iata: "LUW", name: "Bubung Airport" },
    WAMY: { iata: "UOL", name: "Buol Airport" },
    WAOC: { iata: "BTW", name: "Batu Licin Airport" },
    WAOI: { iata: "PKN", name: "Iskandar Airport" },
    WAOK: { iata: "KBU", name: "Stagen Airport" },
    WAOM: { iata: "MTW", name: "Beringin Airport" },
    WAON: { iata: "TJG", name: "Warukin Airport" },
    WAOO: { iata: "BDJ", name: "Syamsudin Noor Airport" },
    WAOP: { iata: "PKY", name: "Tjilik Riwut Airport" },
    WAOS: { iata: "SMQ", name: "Sampit(Hasan) Airport" },
    WAPA: { iata: "AHI", name: "Amahai Airport" },
    WAPC: { iata: "NDA", name: "Banda Airport Kepulauan" },
    WAPD: { iata: "DOB", name: "Dobo Airport" },
    WAPE: { iata: "MAL", name: "Mangole Airport Falabisahaya" },
    WAPG: { iata: "NRE", name: "Namrole Airport" },
    WAPH: { iata: "LAH", name: "Oesman Sadik Airport Labuha" },
    WAPI: { iata: "SXK", name: "Saumlaki Airport" },
    WAPK: { iata: "BJK", name: "Nangasuri Airport" },
    WAPL: { iata: "LUV", name: "Dumatumbun Airport" },
    WAPN: { iata: "SQN", name: "Emalamo Sanana Airport" },
    WAPP: { iata: "AMQ", name: "Pattimura Airport Ambon" },
    WAPR: { iata: "NAM", name: "Namlea Airport" },
    WAPT: { iata: "TAX", name: "Taliabu Island Airport" },
    WAPV: { iata: "WBA", name: "WahaiSeram Island" },
    WARA: { iata: "MLG", name: "Abdul Rachman Saleh Airport" },
    WARC: { iata: "CPF", name: "Cepu Airport" },
    WARJ: { iata: "JOG", name: "Adi Sutjipto International Airport" },
    WARQ: { iata: "SOC", name: "Adi Sumarmo Wiryokusumo Airport" },
    WARR: { iata: "SUB", name: "Juanda International Airport" },
    WARS: { iata: "SRG", name: "Achmad Yani Airport" },
    WART: { iata: "SUP", name: "Trunojoyo Airport" },
    WASB: { iata: "NTI", name: "Stenkol Airport" },
    WASC: { iata: "RSK", name: "Abresso Airport" },
    WASE: { iata: "KEQ", name: "Kebar Airport" },
    WASF: { iata: "FKQ", name: "Fakfak Airport" },
    WASI: { iata: "INX", name: "Inanwatan Airport" },
    WASK: { iata: "KNG", name: "Kaimana Airport" },
    WASM: { iata: "RDE", name: "Merdei Airport" },
    WASO: { iata: "BXB", name: "Babo Airport" },
    WASR: { iata: "MKW", name: "Rendani Airport" },
    WASS: { iata: "SOQ", name: "Sorong (Jefman) Airport" },
    WAST: { iata: "TXM", name: "Teminabuan Airport" },
    WASW: { iata: "WSR", name: "Wasior Airport" },
    WATB: { iata: "BJW", name: "Bajawa Soa Airport" },
    WATC: { iata: "MOF", name: "Maumere(Wai Oti) Airport" },
    WATE: { iata: "ENE", name: "Ende (H Hasan Aroeboesman) Airport" },
    WATG: { iata: "RTG", name: "Satar Tacik Airport" },
    WATM: { iata: "ARD", name: "Mali Airport" },
    WATO: { iata: "LBJ", name: "Komodo (Mutiara II) Airport" },
    WATT: { iata: "KOE", name: "El Tari Airport" },
    WAWB: { iata: "BUW", name: "Betoambari Airport" },
    WAWJ: { iata: "MJU", name: "Tampa Padang Airport" },
    WAWM: { iata: "MXB", name: "Andi Jemma Airport" },
    WAWP: { iata: "KXB", name: "Sangia Nibandera Airport" },
    WAWS: { iata: "SQR", name: "Soroako Airport" },
    WAWT: { iata: "TTR", name: "Pongtiku Airport" },
    WAWW: { iata: "KDI", name: "Wolter Monginsidi Airport" },
    WBGB: { iata: "BTU", name: "Bintulu Airport" },
    WBGC: { iata: "BLG", name: "Belaga Airport" },
    WBGD: { iata: "LSM", name: "Long Semado Airport" },
    WBGF: { iata: "LGL", name: "Long Lellang Airport" },
    WBGG: { iata: "KCH", name: "Kuching International Airport" },
    WBGI: { iata: "ODN", name: "Long Seridan Airport" },
    WBGJ: { iata: "LMN", name: "Limbang Airport" },
    WBGK: { iata: "MKM", name: "Mukah Airport" },
    WBGL: { iata: "LKH", name: "Long Akah Airport" },
    WBGM: { iata: "MUR", name: "Marudi Airport" },
    WBGN: { iata: "BSE", name: "Sematan Airport" },
    WBGP: { iata: "KPI", name: "Kapit Airport" },
    WBGQ: { iata: "BKM", name: "Bakalalan Airport" },
    WBGR: { iata: "MYY", name: "Miri Airport" },
    WBGS: { iata: "SBW", name: "Sibu Airport" },
    WBGT: { iata: "TGC", name: "Tanjung Manis Airport" },
    WBGU: { iata: "LSU", name: "Long Sukang Airport" },
    WBGW: { iata: "LWY", name: "Lawas Airport" },
    WBGY: { iata: "SGG", name: "Simanggang Airport" },
    WBGZ: { iata: "BBN", name: "Bario Airport" },
    WBKA: { iata: "SMM", name: "Semporna Airport" },
    WBKD: { iata: "LDU", name: "Lahad Datu Airport" },
    WBKE: { iata: "TEL", name: "Telupid Airport" },
    WBKG: { iata: "KGU", name: "Keningau Airport" },
    WBKH: { iata: "SXS", name: "Sahabat [Sahabat 16] Airport" },
    WBKK: { iata: "BKI", name: "Kota Kinabalu International Airport" },
    WBKL: { iata: "LBU", name: "Labuan Airport" },
    WBKM: { iata: "TMG", name: "Tomanggong Airport" },
    WBKN: { iata: "GSA", name: "Long Pasia Airport" },
    WBKO: { iata: "SPE", name: "Sepulot Airport" },
    WBKP: { iata: "PAY", name: "Pamol Airport" },
    WBKR: { iata: "RNU", name: "Ranau Airport" },
    WBKS: { iata: "SDK", name: "Sandakan Airport" },
    WBKT: { iata: "KUD", name: "Kudat Airport" },
    WBKW: { iata: "TWU", name: "Tawau Airport" },
    WBMU: { iata: "MZV", name: "Mulu Airport" },
    WBSB: { iata: "BWN", name: "Brunei International Airport" },
    WIAG: { iata: "AKQ", name: "Menggala Airport" },
    WIBB: { iata: "PKU", name: "Sultan Syarif Kasim Ii (Simpang Tiga) Airport" },
    WIBD: { iata: "DUM", name: "Pinang Kampai Airport" },
    WIBR: { iata: "RKO", name: "Rokot Airport" },
    WIBS: { iata: "SEQ", name: "Sungai Pakning Bengkalis Airport" },
    WIBT: { iata: "TJB", name: "Tanjung Balai Airport" },
    WICC: { iata: "BDO", name: "Husein Sastranegara International Airport" },
    WICD: { iata: "CBN", name: "Penggung Airport" },
    WICM: { iata: "TSY", name: "Cibeureum Airport" },
    WICT: { iata: "TKG", name: "Radin Inten II (Branti) Airport" },
    WIDD: { iata: "BTH", name: "Hang Nadim Airport" },
    WIDE: { iata: "PPR", name: "Pasir Pangaraan Airport" },
    WIDN: { iata: "TNJ", name: "Kijang Airport" },
    WIDS: { iata: "SIQ", name: "Dabo Airport" },
    WIEE: { iata: "PDG", name: "Minangkabau Airport" },
    WIHH: { iata: "HLP", name: "Halim Perdanakusuma International Airport" },
    WIHL: { iata: "CXP", name: "Tunggul Wulung Airport" },
    WIHP: { iata: "PCB", name: "Pondok Cabe Air Base" },
    WIIG: { iata: "PPJ", name: "Pulau Panjang Airport" },
    WIII: { iata: "CGK", name: "Soekarno-Hatta International Airport" },
    WIMB: { iata: "GNS", name: "Binaka Airport" },
    WIME: { iata: "AEG", name: "Aek Godang Airport" },
    WIMK: { iata: "MES", name: "Soewondo Air Force Base" },
    WIMM: { iata: "KNO", name: "Polonia International Airport" },
    WIMN: { iata: "DTB", name: "Sisingamangaraja XII International Airport" },
    WIMP: { iata: "SIW", name: "Parapat Airport" },
    WIMS: { iata: "FLZ", name: "Dr Ferdinand Lumban Tobing Airport" },
    WIOD: { iata: "TJQ", name: "Buluh Tumbang (H A S Hanandjoeddin) Airport" },
    WIOG: { iata: "NPO", name: "Nanga Pinoh I Airport" },
    WIOK: { iata: "KTG", name: "Ketapang(Rahadi Usman) Airport" },
    WIOM: { iata: "MWK", name: "Tarempa Airport" },
    WION: { iata: "NTX", name: "Ranai Airport" },
    WIOO: { iata: "PNK", name: "Supadio Airport" },
    WIOP: { iata: "PSU", name: "Pangsuma Airport" },
    WIOS: { iata: "SQG", name: "Sintang(Susilo) Airport" },
    WIPA: { iata: "DJB", name: "Sultan Thaha Airport" },
    WIPK: { iata: "PGK", name: "Pangkal Pinang (Depati Amir) Airport" },
    WIPL: { iata: "BKS", name: "Padang Kemiling (Fatmawati Soekarno) Airport" },
    WIPP: { iata: "PLM", name: "Sultan Mahmud Badaruddin Ii Airport" },
    WIPQ: { iata: "PDO", name: "Pendopo Airport" },
    WIPR: { iata: "RGT", name: "Japura Airport" },
    WIPT: { iata: "PDG", name: "Minangkabau Airport" },
    WIPU: { iata: "MPC", name: "Muko Muko Airport" },
    WIPV: { iata: "KLQ", name: "Keluang Airport" },
    WITA: { iata: "TPK", name: "Teuku Cut Ali Airport" },
    WITC: { iata: "MEQ", name: "Seunagan Airport" },
    WITL: { iata: "LSX", name: "Lhok Sukon Airport" },
    WITM: { iata: "LSW", name: "Malikus Saleh Airport" },
    WITN: { iata: "SBG", name: "Maimun Saleh Airport" },
    WITT: { iata: "BTJ", name: "Sultan Iskandarmuda Airport" },
    WMAN: { iata: "SXT", name: "Sungai Tiang Airport" },
    WMAU: { iata: "MEP", name: "Mersing Airport" },
    WMBA: { iata: "SWY", name: "Sitiawan Airport" },
    WMBI: { iata: "TPG", name: "Taiping (Tekah) Airport" },
    WMBT: { iata: "TOD", name: "Pulau Tioman Airport" },
    WMKA: { iata: "AOR", name: "Sultan Abdul Halim Airport" },
    WMKB: { iata: "BWH", name: "Butterworth Airport" },
    WMKC: { iata: "KBR", name: "Sultan Ismail Petra Airport" },
    WMKD: { iata: "KUA", name: "Kuantan Airport" },
    WMKE: { iata: "KTE", name: "Kerteh Airport" },
    WMKI: { iata: "IPH", name: "Sultan Azlan Shah Airport" },
    WMKJ: { iata: "JHB", name: "Senai International Airport" },
    WMKK: { iata: "KUL", name: "Kuala Lumpur International Airport" },
    WMKL: { iata: "LGK", name: "Langkawi International Airport" },
    WMKM: { iata: "MKZ", name: "Malacca Airport" },
    WMKN: { iata: "TGG", name: "Sultan Mahmud Airport" },
    WMKP: { iata: "PEN", name: "Penang International Airport" },
    WMPA: { iata: "PKG", name: "Pulau Pangkor Airport" },
    WMPR: { iata: "RDN", name: "LTS Pulau Redang Airport" },
    WMSA: { iata: "SZB", name: "Sultan Abdul Aziz Shah International Airport" },
    WN07: { iata: "DTR", name: "Decatur Shores Airport" },
    WPAT: { iata: "AUT", name: "Atauro Airport" },
    WPDB: { iata: "UAI", name: "Suai Airport" },
    WPDL: {
      iata: "DIL",
      name: "Presidente Nicolau Lobato International Airport",
    },
    WPEC: { iata: "BCH", name: "Cakung Airport" },
    WPMN: { iata: "MPT", name: "Maliana Airport" },
    WPOC: { iata: "OEC", name: "Oecussi Airport" },
    WPVQ: { iata: "VIQ", name: "Viqueque Airport" },
    WRKA: { iata: "ABU", name: "Haliwen Airport" },
    WRKB: { iata: "BJW", name: "Padhameleda Airport" },
    WRKL: { iata: "LKA", name: "Gewayentana Airport" },
    WRKS: { iata: "SAU", name: "Sawu Airport" },
    WRLA: { iata: "SGQ", name: "Sanggata Airport" },
    WRLB: { iata: "LBW", name: "Long Bawan Airport" },
    WRLC: { iata: "BXT", name: "Bontang Airport" },
    WRLF: { iata: "NNX", name: "Nunukan Airport" },
    WRLH: { iata: "TNB", name: "Tanah Grogot Airport" },
    WRLP: { iata: "LPU", name: "Long Apung Airport" },
    WSAP: { iata: "QPG", name: "Paya Lebar Air Base" },
    WSAT: { iata: "TGA", name: "Tengah Air Base" },
    WSSL: { iata: "XSP", name: "Seletar Airport" },
    WSSS: { iata: "SIN", name: "Singapore Changi International Airport" },
    XBRO: { iata: "SKL", name: "Skye Bridge Ashaig Airport" },
    YABA: { iata: "ALH", name: "Albany Airport" },
    YABI: { iata: "ABG", name: "Abingdon Downs Airport" },
    YADS: { iata: "AWN", name: "Alton Downs Airport" },
    YAGD: { iata: "AUD", name: "Augustus Downs Airport" },
    YALA: { iata: "MRP", name: "Marla Airport" },
    YALX: { iata: "AXL", name: "Alexandria Homestead Airport" },
    YAMC: { iata: "AXC", name: "Aramac Airport" },
    YAMK: { iata: "ADO", name: "Andamooka Airport" },
    YAMM: { iata: "AMX", name: "Ammaroo Airport" },
    YAMT: { iata: "AMT", name: "Amata Airport" },
    YANG: { iata: "WLP", name: "West Angelas Airport" },
    YANL: { iata: "AYL", name: "Anthony Lagoon Airport" },
    YAPH: { iata: "ABH", name: "Alpha Airport" },
    YARA: { iata: "ARY", name: "Ararat Airport" },
    YARG: { iata: "GYL", name: "Argyle Airport" },
    YARM: { iata: "ARM", name: "Armidale Airport" },
    YARY: { iata: "AAB", name: "Arrabury Airport" },
    YAUR: { iata: "AUU", name: "Aurukun Airport" },
    YAUS: { iata: "AWP", name: "Austral Downs Airport" },
    YAUV: { iata: "AVG", name: "Auvergne Airport" },
    YAYE: { iata: "AYQ", name: "Ayers Rock Connellan Airport" },
    YAYR: { iata: "AYR", name: "Ayr Airport" },
    YBAF: { iata: "ACF", name: "Brisbane Archerfield Airport" },
    YBAM: { iata: "ABM", name: "Bamaga Injinoo Airport" },
    YBAR: { iata: "BCI", name: "Barcaldine Airport" },
    YBAS: { iata: "ASP", name: "Alice Springs Airport" },
    YBAU: { iata: "BDD", name: "Badu Island Airport" },
    YBAW: { iata: "BKP", name: "Barkly Downs Airport" },
    YBBE: { iata: "BBE", name: "Big Bell Airport" },
    YBBN: { iata: "BNE", name: "Brisbane International Airport" },
    YBCG: { iata: "OOL", name: "Gold Coast Airport" },
    YBCK: { iata: "BKQ", name: "Blackall Airport" },
    YBCS: { iata: "CNS", name: "Cairns International Airport" },
    YBCV: { iata: "CTL", name: "Charleville Airport" },
    YBDF: { iata: "BDW", name: "Bedford Downs Airport" },
    YBDG: { iata: "BXG", name: "Bendigo Airport" },
    YBDV: { iata: "BVI", name: "Birdsville Airport" },
    YBEB: { iata: "BXF", name: "Pumululu National Park" },
    YBEO: { iata: "BTX", name: "Betoota Airport" },
    YBGD: { iata: "OCM", name: "Boolgeeda Airport" },
    YBGO: { iata: "BQW", name: "Balgo Hill Airport" },
    YBHI: { iata: "BHQ", name: "Broken Hill Airport" },
    YBHM: { iata: "HTI", name: "Hamilton Island Airport" },
    YBIE: { iata: "BEU", name: "Bedourie Airport" },
    YBIL: { iata: "BIW", name: "Billiluna Airport" },
    YBIZ: { iata: "BZP", name: "Bizant Airport" },
    YBKE: { iata: "BRK", name: "Bourke Airport" },
    YBKT: { iata: "BUC", name: "Burketown Airport" },
    YBLA: { iata: "BLN", name: "Benalla Airport" },
    YBLC: { iata: "LCN", name: "Balcanoona Airport" },
    YBLL: { iata: "BLS", name: "Bollon Airport" },
    YBLN: { iata: "BQB", name: "Busselton Regional Airport" },
    YBMA: { iata: "ISA", name: "Mount Isa Airport" },
    YBMC: { iata: "MCY", name: "Sunshine Coast Airport" },
    YBMK: { iata: "MKY", name: "Mackay Airport" },
    YBNA: { iata: "BNK", name: "Ballina Byron Gateway Airport" },
    YBNS: { iata: "BSJ", name: "Bairnsdale Airport" },
    YBOI: { iata: "GIC", name: "Boigu Airport" },
    YBOK: { iata: "OKY", name: "Oakey Airport" },
    YBOU: { iata: "BQL", name: "Boulia Airport" },
    YBPI: { iata: "BMP", name: "Brampton Island Airport" },
    YBPN: { iata: "PPP", name: "Proserpine Whitsunday Coast Airport" },
    YBRK: { iata: "ROK", name: "Rockhampton Airport" },
    YBRL: { iata: "BOX", name: "Borroloola Airport" },
    YBRM: { iata: "BME", name: "Broome International Airport" },
    YBRN: { iata: "BZD", name: "Balranald Airport" },
    YBRU: { iata: "BTD", name: "Brunette Downs Airport" },
    YBRW: { iata: "BWQ", name: "Brewarrina Airport" },
    YBRY: { iata: "BYP", name: "Barimunya Airport" },
    YBTH: { iata: "BHS", name: "Bathurst Airport" },
    YBTI: { iata: "BRT", name: "Bathurst Island Airport" },
    YBTL: { iata: "TSV", name: "Townsville Airport" },
    YBTR: { iata: "BLT", name: "Blackwater Airport" },
    YBUD: { iata: "BDB", name: "Bundaberg Airport" },
    YBUN: { iata: "BUY", name: "Bunbury Airport" },
    YBWM: { iata: "BIP", name: "Bulimba Airport" },
    YBWN: { iata: "ZBO", name: "Bowen Airport" },
    YBWP: { iata: "WEI", name: "Weipa Airport" },
    YBWW: { iata: "WTB", name: "Toowoomba Wellcamp Airport" },
    YBWX: { iata: "BWB", name: "Barrow Island Airport" },
    YBYS: { iata: "BVZ", name: "Beverley Springs Airport" },
    YCAG: { iata: "CGV", name: "Caiguna Airport" },
    YCAH: { iata: "CLH", name: "Coolah Airport" },
    YCAR: { iata: "CVQ", name: "Carnarvon Airport" },
    YCAS: { iata: "CSI", name: "Casino Airport" },
    YCBA: { iata: "CAZ", name: "Cobar Airport" },
    YCBB: { iata: "COJ", name: "Coonabarabran Airport" },
    YCBE: { iata: "CBY", name: "Canobie Airport" },
    YCBN: { iata: "CBI", name: "Cape Barren Island Airport" },
    YCBP: { iata: "CPD", name: "Coober Pedy Airport" },
    YCBR: { iata: "CRB", name: "Collarenebri Airport" },
    YCCA: { iata: "CCL", name: "Chinchilla Airport" },
    YCCT: { iata: "CNC", name: "Coconut Island Airport" },
    YCCY: { iata: "CNJ", name: "Cloncurry Airport" },
    YCDO: { iata: "CBX", name: "Condobolin Airport" },
    YCDR: { iata: "CUD", name: "Caloundra Airport" },
    YCDU: { iata: "CED", name: "Ceduna Airport" },
    YCEE: { iata: "CVC", name: "Cleve Airport" },
    YCFD: { iata: "CFI", name: "Camfield Airport" },
    YCGO: { iata: "LLG", name: "Chillagoe Airport" },
    YCHK: { iata: "CKW", name: "Graeme Rowley Aerodrome" },
    YCHT: { iata: "CXT", name: "Charters Towers Airport" },
    YCIN: { iata: "DCN", name: "RAAF Base Curtin" },
    YCKI: { iata: "CKI", name: "Croker Island Airport" },
    YCKN: { iata: "CTN", name: "Cooktown Airport" },
    YCMT: { iata: "CMQ", name: "Clermont Airport" },
    YCMU: { iata: "CMA", name: "Cunnamulla Airport" },
    YCMW: { iata: "CML", name: "Camooweal Airport" },
    YCNF: { iata: "NIF", name: "Camp Nifty Airport" },
    YCNK: { iata: "CES", name: "Cessnock Airport" },
    YCNM: { iata: "CNB", name: "Coonamble Airport" },
    YCOD: { iata: "ODL", name: "Cordillo Downs Airport" },
    YCOE: { iata: "CUQ", name: "Coen Airport" },
    YCOI: { iata: "CIE", name: "Collie Airport" },
    YCOM: { iata: "OOM", name: "Cooma Snowy Mountains Airport" },
    YCOO: { iata: "CDA", name: "Cooinda Airport" },
    YCOR: { iata: "CWW", name: "Corowa Airport" },
    YCRG: { iata: "CYG", name: "Corryong Airport" },
    YCRK: { iata: "CXQ", name: "Christmas Creek Station Airport" },
    YCRY: { iata: "CDQ", name: "Croydon Airport" },
    YCSV: { iata: "KCE", name: "Collinsville Airport" },
    YCTM: { iata: "CMD", name: "Cootamundra Airport" },
    YCUA: { iata: "CUG", name: "Cudal Airport" },
    YCUE: { iata: "CUY", name: "Cue Airport" },
    YCWA: { iata: "CJF", name: "Coondewanna Wa Airport" },
    YCWI: { iata: "CWR", name: "Cowarie Airport" },
    YCWL: { iata: "CCW", name: "Cowell Airport" },
    YCWR: { iata: "CWT", name: "Cowra Airport" },
    YCWY: { iata: "COY", name: "Coolawanyah Airport" },
    YDAJ: { iata: "DJR", name: "Dajarra Airport" },
    YDAY: { iata: "DBY", name: "Dalby Airport" },
    YDBI: { iata: "DRN", name: "Dirranbandi Airport" },
    YDBR: { iata: "DNB", name: "Dunbar Airport" },
    YDBY: { iata: "DRB", name: "Derby Airport" },
    YDDF: { iata: "DFP", name: "Drumduff Airport" },
    YDGA: { iata: "DGD", name: "Dalgaranga Gold Mine Airport" },
    YDIX: { iata: "DXD", name: "Dixie Airport" },
    YDKI: { iata: "DKI", name: "Dunk Island Airport" },
    YDLK: { iata: "DLK", name: "Dulkaninna Airport" },
    YDLQ: { iata: "DNQ", name: "Deniliquin Airport" },
    YDLT: { iata: "DDN", name: "Delta Downs Airport" },
    YDLV: { iata: "DLV", name: "Delissaville Airport" },
    YDLW: { iata: "DYW", name: "Daly Waters Airport" },
    YDMG: { iata: "DMD", name: "Doomadgee Airport" },
    YDMN: { iata: "DVR", name: "Daly River Airport" },
    YDNI: { iata: "NLF", name: "Darnley Island Airport" },
    YDOR: { iata: "DRD", name: "Dorunda Airport" },
    YDPD: { iata: "DVP", name: "Davenport Downs Airport" },
    YDPO: { iata: "DPO", name: "Devonport Airport" },
    YDRA: { iata: "DOX", name: "Dongara Airport" },
    YDRD: { iata: "DRY", name: "Drysdale River Airport" },
    YDRH: { iata: "DHD", name: "Durham Downs Airport" },
    YDRI: { iata: "DRR", name: "Durrie Airport" },
    YDVR: { iata: "DKV", name: "Docker River Airport" },
    YDYS: { iata: "DYA", name: "Dysart Airport" },
    YECH: { iata: "ECH", name: "Echuca Airport" },
    YECL: { iata: "EUC", name: "Eucla Airport" },
    YEDA: { iata: "ETD", name: "Etadunna Airport" },
    YEEB: { iata: "ENB", name: "Eneabba Airport" },
    YEIN: { iata: "EIH", name: "Einasleigh Airport" },
    YELD: { iata: "ELC", name: "Elcho Island Airport" },
    YEML: { iata: "EMD", name: "Emerald Airport" },
    YERN: { iata: "ERB", name: "Ernabella Airport" },
    YESP: { iata: "EPR", name: "Esperance Airport" },
    YEVA: { iata: "EVD", name: "Eva Downs Airport" },
    YEVD: { iata: "EVH", name: "Evans Head Aerodrome" },
    YEXM: { iata: "EXM", name: "Exmouth Airport" },
    YFBS: { iata: "FRB", name: "Forbes Airport" },
    YFDF: { iata: "KFE", name: "Fortescue - Dave Forrest Aerodrome" },
    YFIL: { iata: "FLY", name: "Finley Airport" },
    YFLI: { iata: "FLS", name: "Flinders Island Airport" },
    YFLO: { iata: "FVL", name: "Flora Valley Airport" },
    YFNE: { iata: "FIK", name: "Finke Airport" },
    YFRT: { iata: "FOS", name: "Forrest Airport" },
    YFST: { iata: "FOT", name: "Forster (Wallis Is) Airport" },
    YFTZ: { iata: "FIZ", name: "Fitzroy Crossing Airport" },
    YGAM: { iata: "GBP", name: "Gamboola Airport" },
    YGAY: { iata: "GAH", name: "Gayndah Airport" },
    YGBI: { iata: "GBL", name: "South Goulburn Is Airport" },
    YGDH: { iata: "GUH", name: "Gunnedah Airport" },
    YGDI: { iata: "GOO", name: "Goondiwindi Airport" },
    YGDN: { iata: "GDD", name: "Gordon Downs Airport" },
    YGDS: { iata: "GGD", name: "Gregory Downs Airport" },
    YGDW: { iata: "GTS", name: "Granite Downs Airport" },
    YGEL: { iata: "GET", name: "Geraldton Airport" },
    YGFN: { iata: "GFN", name: "Grafton Airport" },
    YGIA: { iata: "GBW", name: "Ginbata" },
    YGIB: { iata: "GBV", name: "Gibb River Airport" },
    YGKL: { iata: "GKL", name: "Great Keppel Is Airport" },
    YGLA: { iata: "GLT", name: "Gladstone Airport" },
    YGLB: { iata: "GUL", name: "Goulburn Airport" },
    YGLE: { iata: "GLG", name: "Glengyle Airport" },
    YGLG: { iata: "GEX", name: "Geelong Airport" },
    YGLI: { iata: "GLI", name: "Glen Innes Airport" },
    YGLO: { iata: "GLM", name: "Glenormiston Airport" },
    YGNV: { iata: "GVP", name: "Greenvale Airport" },
    YGPT: { iata: "GPN", name: "Garden Point Airport" },
    YGSC: { iata: "GSC", name: "Gascoyne Junction Airport" },
    YGTE: { iata: "GTE", name: "Groote Eylandt Airport" },
    YGTH: { iata: "GFF", name: "Griffith Airport" },
    YGTN: { iata: "GTT", name: "Georgetown Airport" },
    YGTO: { iata: "GEE", name: "Georgetown (Tas) Airport" },
    YGYM: { iata: "GYP", name: "Gympie Airport" },
    YHAW: { iata: "HWK", name: "Wilpena Pound Airport" },
    YHAY: { iata: "HXX", name: "Hay Airport" },
    YHBA: { iata: "HVB", name: "Hervey Bay Airport" },
    YHBR: { iata: "HUB", name: "Humbert River Airport" },
    YHBY: { iata: "HRY", name: "Henbury Airport" },
    YHDY: { iata: "HIP", name: "Headingly Airport" },
    YHHY: { iata: "HIG", name: "Highbury Airport" },
    YHID: { iata: "HID", name: "Horn Island Airport" },
    YHIL: { iata: "HLL", name: "Hillside Airport" },
    YHLC: { iata: "HCQ", name: "Halls Creek Airport" },
    YHMB: { iata: "HMG", name: "Hermannsburg Airport" },
    YHML: { iata: "HLT", name: "Hamilton Airport" },
    YHOO: { iata: "HOK", name: "Hooker Creek Airport" },
    YHOT: { iata: "MHU", name: "Mount Hotham Airport" },
    YHPN: { iata: "HTU", name: "Hopetoun Airport" },
    YHSM: { iata: "HSM", name: "Horsham Airport" },
    YHTL: { iata: "HAT", name: "Heathlands Airport" },
    YHUG: { iata: "HGD", name: "Hughenden Airport" },
    YIDK: { iata: "IDK", name: "Indulkana Airport" },
    YIFL: { iata: "IFL", name: "Innisfail Airport" },
    YIFY: { iata: "IFF", name: "Iffley Airport" },
    YIGM: { iata: "IGH", name: "Ingham Airport" },
    YIKM: { iata: "IKP", name: "Inkerman Airport" },
    YINJ: { iata: "INJ", name: "Injune Airport" },
    YINN: { iata: "INM", name: "Innamincka Airport" },
    YINW: { iata: "IVW", name: "Inverway Airport" },
    YISF: { iata: "ISI", name: "Isisford Airport" },
    YIVL: { iata: "IVR", name: "Inverell Airport" },
    YJAB: { iata: "JAB", name: "Jabiru Airport" },
    YJDA: { iata: "JUN", name: "Jundah Airport" },
    YJIN: { iata: "QJD", name: "Jindabyne Airport" },
    YJLC: { iata: "JCK", name: "Julia Creek Airport" },
    YJNB: { iata: "JUR", name: "Jurien Bay Airport" },
    YKAL: { iata: "UBU", name: "Kalumburu Airport" },
    YKBL: { iata: "KDB", name: "Kambalda Airport" },
    YKBR: { iata: "KAX", name: "Kalbarri Airport" },
    YKBY: { iata: "KBY", name: "Streaky Bay Airport" },
    YKCA: { iata: "KBJ", name: "Kings Canyon Airport" },
    YKCS: { iata: "KCS", name: "Kings Creek Airport" },
    YKER: { iata: "KRA", name: "Kerang Airport" },
    YKII: { iata: "KNS", name: "King Island Airport" },
    YKIR: { iata: "KBB", name: "Kirkimbie Station Airport" },
    YKKG: { iata: "KFG", name: "Kalkgurung Airport" },
    YKLA: { iata: "KOH", name: "Koolatah Airport" },
    YKLB: { iata: "KKP", name: "Koolburra Airport" },
    YKMB: { iata: "KRB", name: "Karumba Airport" },
    YKML: { iata: "KML", name: "Kamileroi Airport" },
    YKMP: { iata: "KPS", name: "Kempsey Airport" },
    YKNG: { iata: "KNI", name: "Katanning Airport" },
    YKOW: { iata: "KWM", name: "Kowanyama Airport" },
    YKPR: { iata: "KPP", name: "Kalpowar Airport" },
    YKRY: { iata: "KGY", name: "Kingaroy Airport" },
    YKSC: { iata: "KGC", name: "Kingscote Airport" },
    YKUB: { iata: "KUG", name: "Kubin Airport" },
    YLAH: { iata: "LWH", name: "Lawn Hill Airport" },
    YLEC: { iata: "LGH", name: "Leigh Creek Airport" },
    YLEO: { iata: "LNO", name: "Leonora Airport" },
    YLEV: { iata: "LEL", name: "Lake Evella Airport" },
    YLFD: { iata: "LFP", name: "Lakefield Airport" },
    YLHI: { iata: "LDH", name: "Lord Howe Island Airport" },
    YLHR: { iata: "IRG", name: "Lockhart River Airport" },
    YLHS: { iata: "LTP", name: "Lyndhurst Airport" },
    YLIN: { iata: "LDC", name: "Lindeman Island Airport" },
    YLIS: { iata: "LSY", name: "Lismore Airport" },
    YLKN: { iata: "LNH", name: "Lake Nash Airport" },
    YLLE: { iata: "BBL", name: "Ballera Airport" },
    YLND: { iata: "LKD", name: "Lakeland Airport" },
    YLOK: { iata: "LOC", name: "Lock Airport" },
    YLOR: { iata: "LOA", name: "Lorraine Airport" },
    YLOV: { iata: "LTV", name: "Lotus Vale Airport" },
    YLRA: { iata: "LUU", name: "Laura Airport" },
    YLRD: { iata: "LHG", name: "Lightning Ridge Airport" },
    YLRE: { iata: "LRE", name: "Longreach Airport" },
    YLRS: { iata: "LUT", name: "New Laura Airport" },
    YLST: { iata: "LER", name: "Leinster Airport" },
    YLTN: { iata: "LVO", name: "Laverton Airport" },
    YLTV: { iata: "TGN", name: "Latrobe Valley Airport" },
    YLZI: { iata: "LZR", name: "Lizard Island Airport" },
    YMAA: { iata: "UBB", name: "Mabuiag Island Airport" },
    YMAV: { iata: "AVV", name: "Avalon Airport" },
    YMAY: { iata: "ABX", name: "Albury Airport" },
    YMBA: { iata: "MRG", name: "Mareeba Airport" },
    YMBL: { iata: "MBB", name: "Marble Bar Airport" },
    YMCO: { iata: "XMC", name: "Mallacoota Airport" },
    YMCR: { iata: "MFP", name: "Manners Creek Airport" },
    YMCT: { iata: "MLR", name: "Millicent Airport" },
    YMDG: { iata: "DGE", name: "Mudgee Airport" },
    YMDI: { iata: "MQA", name: "Mandora Airport" },
    YMDS: { iata: "MNW", name: "Macdonald Downs Airport" },
    YMEK: { iata: "MKR", name: "Meekatharra Airport" },
    YMEN: { iata: "MEB", name: "Melbourne Essendon Airport" },
    YMER: { iata: "MIM", name: "Merimbula Airport" },
    YMES: { iata: "SXE", name: "RAAF Base East Sale" },
    YMEU: { iata: "MLV", name: "Merluna Airport" },
    YMGB: { iata: "MGT", name: "Milingimbi Airport" },
    YMGD: { iata: "MNG", name: "Maningrida Airport" },
    YMGN: { iata: "GSN", name: "Mount Gunson Airport" },
    YMGR: { iata: "MGV", name: "Margaret River (Station) Airport" },
    YMGT: { iata: "MQZ", name: "Margaret River Airport" },
    YMGV: { iata: "MVU", name: "Musgrave Airport" },
    YMHB: { iata: "HBA", name: "Hobart International Airport" },
    YMHO: { iata: "MHO", name: "Mount House Airport" },
    YMHU: { iata: "MCV", name: "McArthur River Mine Airport" },
    YMIA: { iata: "MQL", name: "Mildura Airport" },
    YMIN: { iata: "XML", name: "Minlaton Airport" },
    YMIP: { iata: "MIH", name: "Mitchell Plateau Airport" },
    YMIR: { iata: "MWY", name: "Miralwyn Airport" },
    YMIT: { iata: "MTQ", name: "Mitchell Airport" },
    YMJM: { iata: "MJP", name: "Manjimup Airport" },
    YMLS: { iata: "WLE", name: "Miles Airport" },
    YMLT: { iata: "LST", name: "Launceston Airport" },
    YMMB: { iata: "MBW", name: "Melbourne Moorabbin Airport" },
    YMMI: { iata: "WUI", name: "Murrin Murrin Airport" },
    YMML: { iata: "MEL", name: "Melbourne International Airport" },
    YMMU: { iata: "MMM", name: "Middlemount Airport" },
    YMND: { iata: "MTL", name: "Maitland Airport" },
    YMNE: { iata: "WME", name: "Mount Keith Airport" },
    YMNK: { iata: "ONR", name: "Monkira Airport" },
    YMNS: { iata: "MSF", name: "Mount Swan Airport" },
    YMNY: { iata: "OXY", name: "Morney Airport" },
    YMOG: { iata: "MMG", name: "Mount Magnet Airport" },
    YMOO: { iata: "OOR", name: "Mooraberree Airport" },
    YMOR: { iata: "MRZ", name: "Moree Airport" },
    YMOT: { iata: "MET", name: "Moreton Airport" },
    YMPA: { iata: "MIN", name: "Minnipa Airport" },
    YMQA: { iata: "MQE", name: "Marqua Airport" },
    YMRB: { iata: "MOV", name: "Moranbah Airport" },
    YMRE: { iata: "RRE", name: "Marree Airport" },
    YMRW: { iata: "MWB", name: "Morawa Airport" },
    YMRY: { iata: "MYA", name: "Moruya Airport" },
    YMSF: { iata: "MTD", name: "Mount Sanford Station Airport" },
    YMTB: { iata: "UTB", name: "Muttaburra Airport" },
    YMTG: { iata: "MGB", name: "Mount Gambier Airport" },
    YMTI: { iata: "ONG", name: "Mornington Island Airport" },
    YMTO: { iata: "MNQ", name: "Monto Airport" },
    YMUC: { iata: "MUQ", name: "Muccan Station Airport" },
    YMUG: { iata: "MNE", name: "Mungeranie Airport" },
    YMUI: { iata: "MYI", name: "Murray Island Airport" },
    YMUK: { iata: "MVK", name: "Mulka Airport" },
    YMUP: { iata: "MUP", name: "Mulga Park Airport" },
    YMVG: { iata: "MKV", name: "Mount Cavenagh Airport" },
    YMWA: { iata: "MXU", name: "Mullewa Airport" },
    YMWT: { iata: "MWT", name: "Moolawatana Airport" },
    YMWX: { iata: "MXD", name: "Marion Downs Airport" },
    YMYB: { iata: "MBH", name: "Maryborough Airport" },
    YMYR: { iata: "MYO", name: "Myroodan Station Airport" },
    YMYT: { iata: "RTY", name: "Merty Merty Airport" },
    YNAP: { iata: "NMR", name: "Nappa Merrie Airport" },
    YNAR: { iata: "NRA", name: "Narrandera Airport" },
    YNBR: { iata: "NAA", name: "Narrabri Airport" },
    YNGU: { iata: "RPM", name: "Ngukurr Airport" },
    YNHS: { iata: "NBH", name: "Nambucca Heads Airport" },
    YNIC: { iata: "NLS", name: "Nicholson Airport" },
    YNRC: { iata: "NAC", name: "Naracoorte Airport" },
    YNRG: { iata: "NRG", name: "Narrogin Airport" },
    YNRM: { iata: "QRM", name: "Narromine Airport" },
    YNRV: { iata: "RVT", name: "Ravensthorpe Airport" },
    YNSH: { iata: "NSV", name: "Noosa Airport" },
    YNSM: { iata: "NSM", name: "Norseman Airport" },
    YNTN: { iata: "NTN", name: "Normanton Airport" },
    YNUB: { iata: "NUR", name: "Nullabor Motel Airport" },
    YNUL: { iata: "NLL", name: "Nullagine Airport" },
    YNUM: { iata: "NUB", name: "Numbulwar Airport" },
    YNWN: { iata: "ZNE", name: "Newman Airport" },
    YNYN: { iata: "NYN", name: "Nyngan Airport" },
    YOEN: { iata: "OPI", name: "Oenpelli Airport" },
    YOLA: { iata: "XCO", name: "Colac Airport" },
    YOLD: { iata: "OLP", name: "Olympic Dam Airport" },
    YOLW: { iata: "ONS", name: "Onslow Airport" },
    YOOD: { iata: "ODD", name: "Oodnadatta Airport" },
    YOOM: { iata: "MOO", name: "Moomba Airport" },
    YORB: { iata: "RBS", name: "Orbost Airport" },
    YORG: { iata: "OAG", name: "Orange Airport" },
    YORV: { iata: "ODR", name: "Ord River Airport" },
    YOSB: { iata: "OSO", name: "Osborne Mine Airport" },
    YOUY: { iata: "OYN", name: "Ouyen Airport" },
    YPAD: { iata: "ADL", name: "Adelaide International Airport" },
    YPAG: { iata: "PUG", name: "Port Augusta Airport" },
    YPAM: { iata: "PMK", name: "Palm Island Airport" },
    YPBO: { iata: "PBO", name: "Paraburdoo Airport" },
    YPCC: { iata: "CCK", name: "Cocos (Keeling) Islands Airport" },
    YPDI: { iata: "PDE", name: "Pandie Pandie Airport" },
    YPDN: { iata: "DRW", name: "Darwin International Airport" },
    YPDO: { iata: "PRD", name: "Pardoo Airport" },
    YPEC: { iata: "BEO", name: "Aeropelican Airport" },
    YPGV: { iata: "GOV", name: "Gove Airport" },
    YPIR: { iata: "PPI", name: "Port Pirie Airport" },
    YPJT: { iata: "JAD", name: "Perth Jandakot Airport" },
    YPKA: { iata: "KTA", name: "Karratha Airport" },
    YPKG: { iata: "KGI", name: "Kalgoorlie Boulder Airport" },
    YPKS: { iata: "PKE", name: "Parkes Airport" },
    YPKT: { iata: "PKT", name: "Port Keats Airport" },
    YPKU: { iata: "KNX", name: "Kununurra Airport" },
    YPLC: { iata: "PLO", name: "Port Lincoln Airport" },
    YPLM: { iata: "LEA", name: "Learmonth Airport" },
    YPMP: { iata: "EDR", name: "Pormpuraaw Airport" },
    YPMQ: { iata: "PQQ", name: "Port Macquarie Airport" },
    YPOD: { iata: "PTJ", name: "Portland Airport" },
    YPOK: { iata: "MBF", name: "Porepunkah Airport" },
    YPPD: { iata: "PHE", name: "Port Hedland International Airport" },
    YPPH: { iata: "PER", name: "Perth International Airport" },
    YPSH: { iata: "PEA", name: "Penneshaw Airport" },
    YPTN: { iata: "KTR", name: "Tindal Airport" },
    YPWR: { iata: "UMR", name: "Woomera Airfield" },
    YPXM: { iata: "XCH", name: "Christmas Island Airport" },
    YQDI: { iata: "UIR", name: "Quirindi Airport" },
    YQLP: { iata: "ULP", name: "Quilpie Airport" },
    YQNS: { iata: "UEE", name: "Queenstown Airport" },
    YREN: { iata: "RMK", name: "Renmark Airport" },
    YRMD: { iata: "RCM", name: "Richmond Airport" },
    YRNG: { iata: "RAM", name: "Ramingining Airport" },
    YROB: { iata: "ROH", name: "Robinhood Airport" },
    YROE: { iata: "RBU", name: "Roebourne Airport" },
    YROI: { iata: "RBC", name: "Robinvale Airport" },
    YROM: { iata: "RMA", name: "Roma Airport" },
    YRRB: { iata: "RPB", name: "Roper Bar Airport" },
    YRSB: { iata: "RSB", name: "Roseberth Airport" },
    YRTI: { iata: "RTS", name: "Rottnest Island Airport" },
    YRTP: { iata: "RTP", name: "Rutland Plains Airport" },
    YRYH: { iata: "RHL", name: "Roy Hill Station Airport" },
    YSAN: { iata: "NDS", name: "Sandstone Airport" },
    YSBK: { iata: "BWU", name: "Sydney Bankstown Airport" },
    YSCB: { iata: "CBR", name: "Canberra International Airport" },
    YSCH: { iata: "CFS", name: "Coffs Harbour Airport" },
    YSCN: { iata: "CDU", name: "Camden Airport" },
    YSCO: { iata: "NSO", name: "Scone Airport" },
    YSCR: { iata: "SQC", name: "Southern Cross Airport" },
    YSDU: { iata: "DBO", name: "Dubbo City Regional Airport" },
    YSGE: { iata: "SGO", name: "St George Airport" },
    YSGT: { iata: "SIX", name: "Singleton Airport" },
    YSGW: { iata: "ZGL", name: "South Galway Airport" },
    YSHG: { iata: "SGP", name: "Shay Gap Airport" },
    YSHK: { iata: "MJK", name: "Shark Bay Airport" },
    YSHR: { iata: "JHQ", name: "Shute Harbour Airport" },
    YSHT: { iata: "SHT", name: "Shepparton Airport" },
    YSII: { iata: "SBR", name: "Saibai Island Airport" },
    YSMI: { iata: "SIO", name: "Smithton Airport" },
    YSMP: { iata: "SHU", name: "Smith Point Airport" },
    YSMR: { iata: "STH", name: "Strathmore Airport" },
    YSNB: { iata: "SNB", name: "Snake Bay Airport" },
    YSNF: { iata: "NLK", name: "Norfolk Island International Airport" },
    YSNW: { iata: "NOA", name: "Nowra Airport" },
    YSOL: { iata: "SLJ", name: "Solomon Airport" },
    YSPE: { iata: "SNH", name: "Stanthorpe Airport" },
    YSPK: { iata: "SCG", name: "Spring Creek Airport" },
    YSPT: { iata: "SHQ", name: "Southport Airport" },
    YSPV: { iata: "KSV", name: "Springvale Airport" },
    YSRI: { iata: "XRH", name: "RAAF Base Richmond" },
    YSRN: { iata: "SRN", name: "Strahan Airport" },
    YSSY: { iata: "SYD", name: "Sydney Kingsford Smith International Airport" },
    YSTH: { iata: "HLS", name: "St Helens Airport" },
    YSTW: { iata: "TMW", name: "Tamworth Airport" },
    YSWG: { iata: "WGA", name: "Wagga Wagga City Airport" },
    YSWH: { iata: "SWH", name: "Swan Hill Airport" },
    YSWL: { iata: "SWC", name: "Stawell Airport" },
    YTAA: { iata: "XTR", name: "Tara Airport" },
    YTAB: { iata: "TBL", name: "Tableland Homestead Airport" },
    YTAM: { iata: "XTO", name: "Taroom Airport" },
    YTAR: { iata: "TAQ", name: "Tarcoola Airport" },
    YTBR: { iata: "TBK", name: "Timber Creek Airport" },
    YTDR: { iata: "TDR", name: "Theodore Airport" },
    YTEE: { iata: "TQP", name: "Trepell Airport" },
    YTEF: { iata: "TEF", name: "Telfer Airport" },
    YTEM: { iata: "TEM", name: "Temora Airport" },
    YTGA: { iata: "TAN", name: "Tangalooma Airport" },
    YTGM: { iata: "XTG", name: "Thargomindah Airport" },
    YTGT: { iata: "GTS", name: "The Granites Airport" },
    YTHD: { iata: "TDN", name: "Theda Station Airport" },
    YTHY: { iata: "TYG", name: "Thylungra Airport" },
    YTIB: { iata: "TYB", name: "Tibooburra Airport" },
    YTKY: { iata: "TKY", name: "Turkey Creek Airport" },
    YTMO: { iata: "PHQ", name: "The Monument Airport" },
    YTMP: { iata: "TPR", name: "Tom Price Airport" },
    YTMU: { iata: "TUM", name: "Tumut Airport" },
    YTMY: { iata: "TYP", name: "Tobermorey Airport" },
    YTNG: { iata: "THG", name: "Thangool Airport" },
    YTNK: { iata: "TCA", name: "Tennant Creek Airport" },
    YTOC: { iata: "TCW", name: "Tocumwal Airport" },
    YTRE: { iata: "TRO", name: "Taree Airport" },
    YTST: { iata: "TTX", name: "Truscott Mungalalu Airport" },
    YTWB: { iata: "TWB", name: "Toowoomba Airport" },
    YUDA: { iata: "UDA", name: "Undara Airport" },
    YUNY: { iata: "CZY", name: "Cluny Airport" },
    YUSL: { iata: "USL", name: "Useless Loop Airport" },
    YVRD: { iata: "VCD", name: "Victoria River Downs Airport" },
    YVRS: { iata: "VNR", name: "Vanrook Station Airport" },
    YWAL: { iata: "WLA", name: "Wallal Airport" },
    YWAV: { iata: "WAV", name: "Wave Hill Airport" },
    YWBL: { iata: "WMB", name: "Warrnambool Airport" },
    YWBS: { iata: "SYU", name: "Warraber Island Airport" },
    YWCA: { iata: "WIO", name: "Wilcannia Airport" },
    YWCH: { iata: "WLC", name: "Walcha Airport" },
    YWCK: { iata: "WAZ", name: "Warwick Airport" },
    YWDA: { iata: "WND", name: "Windarra Airport" },
    YWDH: { iata: "WNR", name: "Windorah Airport" },
    YWDL: { iata: "WON", name: "Wondoola Airport" },
    YWGT: { iata: "WGT", name: "Wangaratta Airport" },
    YWHA: { iata: "WYA", name: "Whyalla Airport" },
    YWIT: { iata: "WIT", name: "Wittenoom Airport" },
    YWKB: { iata: "WKB", name: "Warracknabeal Airport" },
    YWLG: { iata: "WGE", name: "Walgett Airport" },
    YWLM: { iata: "NTL", name: "Newcastle Airport" },
    YWLU: { iata: "WUN", name: "Wiluna Airport" },
    YWMP: { iata: "WPK", name: "Wrotham Park Airport" },
    YWND: { iata: "WDI", name: "Wondai Airport" },
    YWOL: { iata: "WOL", name: "Wollongong Airport" },
    YWOR: { iata: "WLL", name: "Wollogorang Airport" },
    YWRN: { iata: "QRR", name: "Warren Airport" },
    YWSL: { iata: "SXE", name: "West Sale Airport" },
    YWTL: { iata: "WLO", name: "Waterloo Airport" },
    YWTN: { iata: "WIN", name: "Winton Airport" },
    YWUD: { iata: "WUD", name: "Wudinna Airport" },
    YWWA: { iata: "WEW", name: "Wee Waa Airport" },
    YWWG: { iata: "WRW", name: "Warrawagine Airport" },
    YWWI: { iata: "WWI", name: "Woodie Woodie Airport" },
    YWWL: { iata: "WWY", name: "West Wyalong Airport" },
    YWYM: { iata: "WYN", name: "Wyndham Airport" },
    YWYY: { iata: "BWT", name: "Wynyard Airport" },
    YYAL: { iata: "YLG", name: "Yalgoo Airport" },
    YYKI: { iata: "OKR", name: "Yorke Island Airport" },
    YYLR: { iata: "KYF", name: "Yeelirrie Airport" },
    YYMI: { iata: "XMY", name: "Yam Island Airport" },
    YYND: { iata: "YUE", name: "Yuendumu Airport" },
    YYNG: { iata: "NGA", name: "Young Airport" },
    YYOR: { iata: "ORR", name: "Yorketown Airport" },
    YYTA: { iata: "KYI", name: "Yalata Mission Airport" },
    ZBAA: { iata: "PEK", name: "Beijing Capital International Airport" },
    ZBAD: { iata: "PKX", name: "Beijing Daxing International Airport" },
    ZBCF: { iata: "CIF", name: "Chifeng Airport" },
    ZBCZ: { iata: "CIH", name: "Changzhi Airport" },
    ZBDS: { iata: "DSN", name: "Ordos Ejin Horo Airport" },
    ZBDT: { iata: "DAT", name: "Datong Airport" },
    ZBER: { iata: "ERL", name: "Erenhot Saiwusu International Airport" },
    ZBGS: { iata: "AEB", name: "Tian Yang Air Base" },
    ZBHD: { iata: "HDG", name: "Handan Airport" },
    ZBHH: { iata: "HET", name: "Baita International Airport" },
    ZBLA: { iata: "HLD", name: "Dongshan Airport" },
    ZBNY: { iata: "NAY", name: "Beijing Nanyuan Airport" },
    ZBOW: { iata: "BAV", name: "Baotou Airport" },
    ZBSH: { iata: "SHP", name: "Shanhaiguan Airport" },
    ZBSJ: { iata: "SJW", name: "Shijiazhuang Daguocun International Airport" },
    ZBTJ: { iata: "TSN", name: "Tianjin Binhai International Airport" },
    ZBTL: { iata: "TGO", name: "Tongliao Airport" },
    ZBUL: { iata: "HLH", name: "Ulanhot Airport" },
    ZBXH: { iata: "XIL", name: "Xilinhot Airport" },
    ZBYC: { iata: "YCU", name: "Yuncheng Guangong Airport" },
    ZBYN: { iata: "TYN", name: "Taiyuan Wusu Airport" },
    ZGBH: { iata: "BHY", name: "Beihai Airport" },
    ZGCD: { iata: "CGD", name: "Changde Airport" },
    ZGCJ: { iata: "HJJ", name: "Zhijiang Airport" },
    ZGDY: { iata: "DYG", name: "Dayong Airport" },
    ZGFS: { iata: "FUO", name: "Foshan Shadi Airport" },
    ZGGG: { iata: "CAN", name: "Guangzhou Baiyun International Airport" },
    ZGHA: { iata: "CSX", name: "Changsha Huanghua Airport" },
    ZGHY: { iata: "HNY", name: "Hengyang Airport" },
    ZGHZ: { iata: "HUZ", name: "Huizhou Airport" },
    ZGKL: { iata: "KWL", name: "Guilin Liangjiang International Airport" },
    ZGLG: { iata: "LLF", name: "Lingling Airport" },
    ZGMX: { iata: "MXZ", name: "Meixian Airport" },
    ZGNN: { iata: "NNG", name: "Nanning Wuxu Airport" },
    ZGOW: { iata: "SWA", name: "Shantou Waisha Airport" },
    ZGSD: { iata: "ZUH", name: "Zhuhai Airport" },
    ZGSZ: { iata: "SZX", name: "Shenzhen Bao'an International Airport" },
    ZGWZ: { iata: "WUZ", name: "Changzhoudao Airport" },
    ZGXN: { iata: "XIN", name: "Xingning Airport" },
    ZGZH: { iata: "LZH", name: "Bailian Airport" },
    ZGZJ: { iata: "ZHA", name: "Zhanjiang Airport" },
    ZHAY: { iata: "AYN", name: "Anyang Airport" },
    ZHCC: { iata: "CGO", name: "Xinzheng Airport" },
    ZHES: { iata: "ENH", name: "Enshi Airport" },
    ZHGH: { iata: "LHK", name: "Guangzhou MR Air Base" },
    ZHHH: { iata: "WUH", name: "Wuhan Tianhe International Airport" },
    ZHLY: { iata: "LYA", name: "Luoyang Airport" },
    ZHNY: { iata: "NNY", name: "Nanyang Airport" },
    ZHSS: { iata: "SHS", name: "Shashi Airport" },
    ZHXF: { iata: "XFN", name: "Xiangfan Airport" },
    ZHYC: { iata: "YIH", name: "Yichang Airport" },
    ZJHK: { iata: "HAK", name: "Haikou Meilan International Airport" },
    ZJSY: { iata: "SYX", name: "Sanya Phoenix International Airport" },
    ZKHM: { iata: "RGO", name: "Orang Airport" },
    ZKPY: { iata: "FNJ", name: "Sunan International Airport" },
    ZKSD: { iata: "DSO", name: "Sondok Airport" },
    ZKSE: { iata: "YJS", name: "Samjiyon Airport" },
    ZLAK: { iata: "AKA", name: "Ankang Airport" },
    ZLDH: { iata: "DNH", name: "Dunhuang Airport" },
    ZLGM: { iata: "GOQ", name: "Golmud Airport" },
    ZLGY: { iata: "GYU", name: "Guyuan Liupanshan Airport" },
    ZLHZ: { iata: "HZG", name: "Hanzhong Airport" },
    ZLIC: { iata: "INC", name: "Yinchuan Airport" },
    ZLJC: { iata: "JIC", name: "Jinchuan Airport" },
    ZLJN: { iata: "JNG", name: "Jining Qufu Airport" },
    ZLJQ: { iata: "JGN", name: "Jiayuguan Airport" },
    ZLLL: { iata: "LHW", name: "Lanzhou Zhongchuan Airport" },
    ZLQY: { iata: "IQN", name: "Qingyang Airport" },
    ZLSN: { iata: "SIA", name: "Xiguan Airport" },
    ZLTS: { iata: "THQ", name: "Tianshui Maijishan Airport" },
    ZLXN: { iata: "XNN", name: "Xining Caojiabu Airport" },
    ZLXY: { iata: "XIY", name: "Xi'an Xianyang International Airport" },
    ZLYA: { iata: "ENY", name: "Yan'an Airport" },
    ZLYL: { iata: "UYN", name: "Yulin Airport" },
    ZLZW: { iata: "ZHY", name: "Zhongwei Shapotou Airport" },
    ZMAH: { iata: "AVK", name: "Arvaikheer Airport" },
    ZMAT: { iata: "LTI", name: "Altai Airport" },
    ZMBH: { iata: "BYN", name: "Bayankhongor Airport" },
    ZMBN: { iata: "UGA", name: "Bulgan Airport" },
    ZMBR: { iata: "UGT", name: "Bulagtai Resort Airport" },
    ZMBS: { iata: "HBU", name: "Bulgan Sum Airport" },
    ZMBU: { iata: "UUN", name: "Baruun Urt Airport" },
    ZMCD: { iata: "COQ", name: "Choibalsan Airport" },
    ZMCK: { iata: "UBN", name: "Chinggis Khaan International Airport" },
    ZMDZ: { iata: "DLZ", name: "Dalanzadgad Airport" },
    ZMHH: { iata: "KHR", name: "Kharkhorin Airport" },
    ZMHU: { iata: "HJT", name: "Khujirt Airport" },
    ZMKD: { iata: "HVD", name: "Khovd Airport" },
    ZMMN: { iata: "MXV", name: "Moron Airport" },
    ZMUB: { iata: "ULN", name: "Chinggis Khaan International Airport" },
    ZMUG: { iata: "ULO", name: "Ulaangom Airport" },
    ZMUL: { iata: "ULG", name: "Ulgii Mongolei Airport" },
    ZPBS: { iata: "BSD", name: "Baoshan Yunduan Airport" },
    ZPDL: { iata: "DLU", name: "Dali Airport" },
    ZPDQ: { iata: "DIG", name: "Diqing Airport" },
    ZPLC: { iata: "LNJ", name: "Lincang Airport" },
    ZPJH: { iata: "JHG", name: "Xishuangbanna Gasa Airport" },
    ZPLJ: { iata: "LJG", name: "Lijiang Airport" },
    ZPLX: { iata: "LUM", name: "Mangshi Airport" },
    ZPPP: { iata: "KMG", name: "Kunming Wujiaba International Airport" },
    ZPSM: { iata: "SYM", name: "Simao Airport" },
    ZPZT: { iata: "ZAT", name: "Zhaotong Airport" },
    ZSAM: { iata: "XMN", name: "Xiamen Gaoqi International Airport" },
    ZSAQ: { iata: "AQG", name: "Anqing Airport" },
    ZSBB: { iata: "BFU", name: "Bengbu Airport" },
    ZSCG: { iata: "CZX", name: "Changzhou Airport" },
    ZSCN: { iata: "KHN", name: "Nanchang Changbei International Airport" },
    ZSDY: { iata: "DOY", name: "Dongying Shengli Airport" },
    ZSFY: { iata: "FUG", name: "Fuyang Xiguan Airport" },
    ZSFZ: { iata: "FOC", name: "Fuzhou Changle International Airport" },
    ZSGS: { iata: "JGS", name: "Jinggangshan Airport" },
    ZSGZ: { iata: "KOW", name: "Ganzhou Airport" },
    ZSHC: { iata: "HGH", name: "Hangzhou Xiaoshan International Airport" },
    ZSJD: { iata: "JDZ", name: "Jingdezhen Airport" },
    ZSJJ: { iata: "JIU", name: "Jiujiang Lushan Airport" },
    ZSJN: { iata: "TNA", name: "Yaoqiang Airport" },
    ZSJU: { iata: "JUZ", name: "Quzhou Airport" },
    ZSLD: { iata: "LCX", name: "Longyan Guanzhishan Airport" },
    ZSLG: { iata: "LYG", name: "Lianyungang Airport" },
    ZSLQ: { iata: "HYN", name: "Huangyan Luqiao Airport" },
    ZSLY: { iata: "LYI", name: "Shubuling Airport" },
    ZSNB: { iata: "NGB", name: "Ningbo Lishe International Airport" },
    ZSNJ: { iata: "NKG", name: "Nanjing Lukou Airport" },
    ZSNT: { iata: "NTG", name: "Nantong Airport" },
    ZSOF: { iata: "HFE", name: "Hefei Xinqiao International Airport" },
    ZSPD: { iata: "PVG", name: "Shanghai Pudong International Airport" },
    ZSQD: { iata: "TAO", name: "Liuting Airport" },
    ZSQZ: { iata: "JJN", name: "Quanzhou Airport" },
    ZSRG: { iata: "RUG", name: "Rugao Air Base" },
    ZSSH: { iata: "HIA", name: "Lianshui Airport" },
    ZSSM: { iata: "SQJ", name: "Sanming Shaxian Airport" },
    ZSSS: { iata: "SHA", name: "Shanghai Hongqiao International Airport" },
    ZSSZ: { iata: "SZV", name: "Guangfu Airport" },
    ZSTX: { iata: "TXN", name: "Tunxi International Airport" },
    ZSWF: { iata: "WEF", name: "Weifang Airport" },
    ZSWH: { iata: "WEH", name: "Weihai Airport" },
    ZSWU: { iata: "WHU", name: "Wuhu Air Base" },
    ZSWX: { iata: "WUX", name: "Sunan Shuofang International Airport" },
    ZSWY: { iata: "WUS", name: "Nanping Wuyishan Airport" },
    ZSWZ: { iata: "WNZ", name: "Wenzhou Yongqiang Airport" },
    ZSXZ: { iata: "XUZ", name: "Xuzhou Guanyin Airport" },
    ZSYN: { iata: "YNZ", name: "Yancheng Airport" },
    ZSYT: { iata: "YNT", name: "Yantai Laishan Airport" },
    ZSYW: { iata: "YIW", name: "Yiwu Airport" },
    ZSZS: { iata: "HSN", name: "Zhoushan Airport" },
    ZUAL: { iata: "NGQ", name: "Ngari Gunsa Airport" },
    ZUAS: { iata: "AVA", name: "Anshun Huangguoshu Airport" },
    ZUBD: { iata: "BPX", name: "Qamdo Bangda Airport" },
    ZUCK: { iata: "CKG", name: "Chongqing Jiangbei International Airport" },
    ZUDX: { iata: "DAX", name: "Dachuan Airport" },
    ZUGU: { iata: "GYS", name: "Guangyuan Airport" },
    ZUGY: { iata: "KWE", name: "Longdongbao Airport" },
    ZUJZ: { iata: "JZH", name: "Jiuzhai Huanglong Airport" },
    ZULP: { iata: "LIA", name: "Liangping Airport" },
    ZULS: { iata: "LXA", name: "Lhasa Gonggar Airport" },
    ZULZ: { iata: "LZO", name: "Luzhou Airport" },
    ZUMY: { iata: "MIG", name: "Mianyang Airport" },
    ZUNC: { iata: "NAO", name: "Nanchong Airport" },
    ZUNZ: { iata: "LZY", name: "Nyingchi Airport" },
    ZUQJ: { iata: "JIQ", name: "Qianjiang Wulingshan Airport" },
    ZUTC: { iata: "TCZ", name: "Tengchong Tuofeng Airport" },
    ZUTF: { iata: "TFU", name: "Chengdu Tianfu International Airport" },
    ZUTR: { iata: "TEN", name: "Tongren Fenghuang Airport" },
    ZUUU: { iata: "CTU", name: "Chengdu Shuangliu International Airport" },
    ZUWX: { iata: "WXN", name: "Wanxian Airport" },
    ZUXC: { iata: "XIC", name: "Xichang Qingshan Airport" },
    ZUYB: { iata: "YBP", name: "Yibin Caiba Airport" },
    ZUYI: { iata: "ACX", name: "Xingyi Airport" },
    ZUZY: { iata: "ZYI", name: "Zunyi Xinzhou Airport" },
    ZWAK: { iata: "AKU", name: "Aksu Airport" },
    ZWAT: { iata: "AAT", name: "Altay Air Base" },
    ZWBL: { iata: "BPL", name: "Alashankou Bole (Bortala) airport" },
    ZWCM: { iata: "IQM", name: "Qiemo Airport" },
    ZWHM: { iata: "HMI", name: "Hami Airport" },
    ZWKC: { iata: "KCA", name: "Kuqa Airport" },
    ZWKL: { iata: "KRL", name: "Korla Airport" },
    ZWKM: { iata: "KRY", name: "Karamay Airport" },
    ZWSH: { iata: "KHG", name: "Kashgar Airport" },
    ZWSS: { iata: "SXJ", name: "Shanshan Airport" },
    ZWTC: { iata: "TCG", name: "Tacheng Airport" },
    ZWTN: { iata: "HTN", name: "Hotan Airport" },
    ZWWW: { iata: "URC", name: "Urumqi Diwopu International Airport" },
    ZWYN: { iata: "YIN", name: "Yining Airport" },
    ZYAS: { iata: "AOG", name: "Anshan Air Base" },
    ZYCC: { iata: "CGQ", name: "Longjia Airport" },
    ZYCH: { iata: "CNI", name: "Changhai Airport" },
    ZYCY: { iata: "CHG", name: "Chaoyang Airport" },
    ZYDD: { iata: "DDG", name: "Dandong Airport" },
    ZYDQ: { iata: "DQA", name: "Saertu Airport" },
    ZYHB: { iata: "HRB", name: "Taiping Airport" },
    ZYHE: { iata: "HEK", name: "Heihe Airport" },
    ZYJL: { iata: "JIL", name: "Jilin Airport" },
    ZYJM: { iata: "JMU", name: "Jiamusi Airport" },
    ZYJZ: { iata: "JNZ", name: "Jinzhou Airport" },
    ZYLD: { iata: "LDS", name: "Lindu Airport" },
    ZYLS: { iata: "YUS", name: "Yushu Batang Airport" },
    ZYMD: { iata: "MDG", name: "Mudanjiang Hailang International Airport" },
    ZYMH: { iata: "OHE", name: "Gu-Lian Airport" },
    ZYQQ: { iata: "NDG", name: "Qiqihar Sanjiazi Airport" },
    ZYTL: { iata: "DLC", name: "Zhoushuizi Airport" },
    ZYTN: { iata: "TNH", name: "Tonghua Sanyuanpu Airport" },
    ZYTX: { iata: "SHE", name: "Taoxian Airport" },
    ZYXC: { iata: "XEN", name: "Xingcheng Air Base" },
    ZYYJ: { iata: "YNJ", name: "Yanji Chaoyangchuan Airport" },
  };
  