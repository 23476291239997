import React from "react";
import ReactDOM from "react-dom";

import "./index.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";

import axios from "axios";

import TranslationWrapper from "./components/TranslationWrapper";

if (!window.configData?.AGENCYID) {
  window.configData = {
    TILE_URL: "https://holiday.placelogg.com/themewordpress/",
    ACCOMMODATION_URL:
      "https://holiday.placelogg.com/themewordpress/?page_id=1596",
    PACKAGE_URL: "https://holiday.placelogg.com/themewordpress/?page_id=334",
    RESULTS_URL: "https://holiday.placelogg.com/themewordpress/?page_id=2348",
    AGENCYID: "2d03fadb-c6a9-46e0-a425-8103afa0004d",
  };
}

//console.log(window.configData.AGENCYID);
axios.defaults.headers.common["AgencyID"] = window.configData.AGENCYID;

ReactDOM.render(
  <CookiesProvider defaultSetOptions={{ path: "/" }}>
    <TranslationWrapper>
      <Provider store={store}>
        <App />
      </Provider>
    </TranslationWrapper>
  </CookiesProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
