import {
  createSlice,
  createSelector,
  isPending,
  isRejected,
  isFulfilled,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import axios from "axios";
import { getHost, views } from "./utils";

axios.defaults.headers.common["AgencyID"] = window.configData.AGENCYID;
axios.defaults.headers.common["Version"] = process.env.REACT_APP_VERSION;
axios.defaults.headers.common["App"] = window.configData.APP;

const initialState = {
  loading: 0,
  error: "",
  socketid: "",
  view: views.searchview,
  viewBeforeSearch: views.homeview,
  sessionId: "",
  accountid: "",
  isloadertext: true,
  sidebar: false,
  map: false,
  urlfound: false,
  mode: "production",
  features: false,
  debug: false,
  saveSearch: true,
  searchEnded: true,
  openSearch: false,
};

export const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    setSocketid: (state, action) => {
      state.socketid = action.payload;
    },
    setViewBeforeSearch: (state, action) => {
      state.viewBeforeSearch = action.payload;
    },
    setView: (state, action) => {
      state.view = action.payload;
    },
    setIsloadertext: (state, action) => {
      state.isloadertext = action.payload;
    },
    setSessionId: (state, action) => {
      state.sessionId = action.payload;
    },
    setAccountId: (state, action) => {
      state.accountId = action.payload;
    },
    addLoading: (state, action) => {
      state.loading = state.loading + 1;
    },
    decLoading: (state, action) => {
      state.loading = state.loading - 1;
    },
    setSidebar: (state, action) => {
      state.sidebar = action.payload;
    },
    setOpenSearch: (state, action) => {
      state.openSearch = action.payload;
    },
    setMap: (state, action) => {
      state.map = action.payload;
    },
    setUrlfound: (state, action) => {
      state.urlfound = action.payload;
    },
    setFeatures: (state, action) => {
      state.features = action.payload;
    },
    setMode: (state, action) => {
      state.mode = action.payload;
    },
    setDebug: (state, action) => {
      state.debug = action.payload;
    },
    setSaveSearch: (state, action) => {
      state.saveSearch = action.payload;
    },
    setSearchEnded: (state, action) => {
      state.searchEnded = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isPending, (state, action) => {
      if (
        !(
          action.type.startsWith("hotel/availabilityAndPriceCheck") ||
          //action.type.startsWith("hotel/alternativeTransportForPackageOffer") ||
          //action.type.startsWith("hotel/searchBetterOffers") ||
          action.type.startsWith("hotel/checkOfferAction") ||
          action.type.startsWith("properties/getadvertising")
        )
      ) {
        state.loading += 1;
      }
      state.error = "";
    });
    builder.addMatcher(isRejected, (state, action) => {
      if (
        !(
          action.type.startsWith("hotel/availabilityAndPriceCheck") ||
          //action.type.startsWith("hotel/alternativeTransportForPackageOffer") ||
          //action.type.startsWith("hotel/searchBetterOffers") ||
          action.type.startsWith("hotel/checkOfferAction") ||
          action.type.startsWith("properties/getadvertising")
        )
      ) {
        state.loading -= 1;
      }
      state.error = action?.payload?.error;
    });
    builder.addMatcher(isFulfilled, (state, action) => {
      if (
        !(
          action.type.startsWith("hotel/availabilityAndPriceCheck") ||
          //action.type.startsWith("hotel/alternativeTransportForPackageOffer") ||
          //action.type.startsWith("hotel/searchBetterOffers") ||
          action.type.startsWith("hotel/checkOfferAction") ||
          action.type.startsWith("properties/getadvertising")
        )
      ) {
        state.loading -= 1;
      }
      state.error = "";
    });
  },
});

export const selectSystem = createSelector(
  (state) => ({
    loading: state.system.loading,
    error: state.system.error,
    view: state.system.view,
    sidebar: state.system.sidebar,
  }),
  (state) => state
);

export const {
  setView,
  setSessionId,
  setAccountId,
  setDebug,
  setSidebar,
  setMap,
  setUrlfound,
  setFeatures,
  setSaveSearch,
  setSearchEnded,
  setMode,
  setOpenSearch,
  setSocketid,
  setViewBeforeSearch,
  addLoading,
  decLoading,
  setIsloadertext,
} = systemSlice.actions;

export default systemSlice.reducer;
