import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import axios from "axios";
import {
  airportcity,
  airportregions,
  getHost,
  populatePost,
  views,
} from "./utils";

axios.defaults.headers.common["AgencyID"] = window.configData.AGENCYID;
axios.defaults.headers.common["Version"] = process.env.REACT_APP_VERSION;
axios.defaults.headers.common["App"] = window.configData.APP;

export const getAdvertising = createAsyncThunk(
  "properties/getadvertising",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.post(getHost() + "/getadvertising", {
        ...payload,
        ...state.search,
        url: document.location.href,
        hotelcount: state.filter.hotelCount,
        request:
          state.system.view === views.tileview ||
            state.system.view === views.confirmationview ||
            state.system.view === views.bookingview ||
            state.system.view === views.searchview
            ? {}
            : populatePost(
              state.search.selectedDestination,
              {
                ...state.search,
                promosession: state.system.sessionId,
                offset: 0,
                amount: 0,
              },
              state.properties.departureStations
            ),
        offerid: state.hotel.offerId,
        hotel: state.hotel.selectedHotel,
        agencyid: window.configData.AGENCYID,
        sessionid: state.system.sessionId,
      });

      if (payload?.panel && document.getElementById(payload.panel)) {
        document.getElementById(payload.panel).innerHTML = response.data;
      }
      //console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const checkAddressOrEmail = createAsyncThunk(
  "properties/checkAddressOrEmail",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();
    let parms = "";
    if (payload.customerCity)
      parms =
        parms + "&customerCity=" + encodeURIComponent(payload.customerCity);
    if (payload.customerStreet)
      parms =
        parms + "&customerStreet=" + encodeURIComponent(payload.customerStreet);

    //ACHTUNG property name gewechselt wegen checkaddress
    if (payload.customerCountryCode)
      parms =
        parms +
        "&customerCountry=" +
        encodeURIComponent(payload.customerCountryCode);

    if (payload.customerPostalCode)
      parms =
        parms +
        "&customerPostalCode=" +
        encodeURIComponent(payload.customerPostalCode);

    if (payload.customerEmail)
      parms =
        parms + "&customerEmail=" + encodeURIComponent(payload.customerEmail);

    try {
      const response = await axios.get(
        getHost() +
        "/checkaddress?agencyid=" +
        window.configData.AGENCYID +
        parms
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const checkIban = createAsyncThunk(
  "properties/checkIban",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/checkiban?agencyid=" +
        window.configData.AGENCYID +
        "&iban=" +
        encodeURIComponent(payload.iban)
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);



export const getBestplaces = createAsyncThunk(
  "properties/getBestplaces",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();
    try {
      const response = await axios.get(
        getHost() +
        "/getbestplaces?agencyid=" +
        window.configData.AGENCYID +
        "&target=mobile&typ=package&language=de"
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getInfos = createAsyncThunk(
  "properties/getInfos",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
        "/getinfos?agencyid=" +
        window.configData.AGENCYID +
        "&context=" +
        encodeURIComponent(payload.context) +
        "&language=de"
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchRegionGroups = createAsyncThunk(
  "properties/fetchRegionGroups",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(
        getHost() + "/inventoryDataForPackage?parameter=TouristicRegions"
      );

      //console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchDepartureStations = createAsyncThunk(
  "properties/fetchDepartureStations",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(
        getHost() + "/inventoryDataForPackage?parameter=DepartureStations"
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchTourOperators = createAsyncThunk(
  "properties/fetchTourOperators",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(
        getHost() + "/inventoryDataForPackage?parameter=TourOperator"
      );
      //console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const fetchTravelAgencies = createAsyncThunk(
  "properties/travelAgencies",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(getHost() + "/travelagencies2");

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchSessionHistory = createAsyncThunk(
  "properties/fetchSessionHistory",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(
        getHost() + "/getsessionhistory?sessionid=" + payload.sessionid
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const deletesessionhistory = createAsyncThunk(
  "properties/deletesessionhistory",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();
    try {
      const response = await axios.get(
        getHost() + "/deletesessionhistory?id=" + payload.id + "&sessionid=" + state.system.sessionId
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const fetchSuggestions = createAsyncThunk(
  "properties/suggestions",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();
    try {
      const response = await axios.get(
        getHost() +
        "/getsuggestions?sessionid=" +
        state.system.sessionId +
        "&agencyid=" +
        window.configData.AGENCYID
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const fetchAirlines = createAsyncThunk(
  "properties/fetchAirlines",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(
        getHost() + "/inventoryDataForPackage?parameter=TransportOperatingBrand"
      );
      //console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const fetchAirlineProperties = createAsyncThunk(
  "properties/fetchairlineproperties",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();
    try {
      const response = await axios.get(
        getHost() +
        "/fetchairlineproperties"
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const fetchAgencyproperties = createAsyncThunk(
  "properties/agencyproperties",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();
    try {
      const response = await axios.get(
        getHost() + "/agencyproperties?id=" + window.configData.AGENCYID
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const initialState = {
  regionGroups: [],
  departureStations: [],
  tourOperators: [],
  airlines: [],
  travelAgencies: [],
  bestplaces: [],
  infos: {},
  suggestions: [],
  ibanresult: {},
  addressresult: {},
  sessionHistory: [],
  agencyproperties: {},
};

export const propertiesSlice = createSlice({
  name: "properties",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(checkIban.fulfilled, (state, { payload, meta }) => {
      state.ibanresult = payload.response;
    });
    builder.addCase(
      checkAddressOrEmail.fulfilled,
      (state, { payload, meta }) => {
        state.addressresult = payload.response;
      }
    );

    builder.addCase(fetchAirlineProperties.fulfilled, (state, { payload, meta }) => {
      state.airlines = [...state.airlines.map((e) => {
        let a = payload.ret.find((f) => e.value === f.id);
        return { ...e, properties: a ? a.properties : {} };
      })];
    });

    builder.addCase(getBestplaces.fulfilled, (state, { payload, meta }) => {
      state.bestplaces = payload.ret;
    });

    builder.addCase(getInfos.fulfilled, (state, { payload, meta }) => {
      state.infos = { ...state.infos, [meta.arg.context]: payload.ret };
    });

    builder.addCase(fetchSessionHistory.pending, (state) => {
      state.sessionHistory = [];
    });
    builder.addCase(fetchSessionHistory.fulfilled, (state, { payload }) => {
      state.sessionHistory = payload.ret;
    });
    builder.addCase(deletesessionhistory.fulfilled, (state, { payload }) => {
      state.sessionHistory = payload.ret;
    });
    builder.addCase(fetchRegionGroups.pending, (state) => {
      state.regionGroups = [];
    });
    builder.addCase(fetchRegionGroups.fulfilled, (state, { payload }) => {
      state.regionGroups = payload.RegionGroups.RegionGroup;
    });
    builder.addCase(fetchAgencyproperties.fulfilled, (state, { payload }) => {
      state.agencyproperties = payload;
    });

    builder.addCase(fetchAirlines.pending, (state) => {
      state.airlines = [];
    });
    builder.addCase(fetchAirlines.fulfilled, (state, { payload }) => {
      //console.log("################", payload);

      let oplines = [];
      if (
        Array.isArray(
          payload?.TransportOperatingBrands?.TransportOperatingBrand
        )
      ) {
        for (
          let i = 0;
          i < payload.TransportOperatingBrands.TransportOperatingBrand.length;
          i++
        ) {
          oplines.push({
            value:
              payload.TransportOperatingBrands.TransportOperatingBrand[i].Code,
            label:
              payload.TransportOperatingBrands.TransportOperatingBrand[i].Name,
          });
        }
      }
      state.airlines = oplines;
    });
    builder.addCase(fetchSuggestions.pending, (state) => {
      state.suggestions = [];
    });
    builder.addCase(fetchSuggestions.fulfilled, (state, { payload }) => {
      state.suggestions = payload;
    });
    builder.addCase(fetchTourOperators.pending, (state) => {
      state.tourOperators = [];
    });
    builder.addCase(fetchTourOperators.fulfilled, (state, { payload }) => {
      state.tourOperators = payload.TourOperators.TourOperator;
    });
    builder.addCase(fetchDepartureStations.pending, (state) => {
      state.departureStations = [];
    });
    builder.addCase(fetchDepartureStations.fulfilled, (state, { payload }) => {
      //console.log(payload);
      let dep = payload.Stations.DepartureStations.map((e) => {
        return { value: e, label: airportcity(e) };
      });

      state.departureStations = [...airportregions(), ...dep];
    });
    builder.addCase(fetchTravelAgencies.pending, (state) => {
      state.departureStations = [];
    });
    builder.addCase(fetchTravelAgencies.fulfilled, (state, { payload }) => {
      //console.log(payload);
      state.travelAgencies = { ...payload };
    });
  },
});


export default propertiesSlice.reducer;
