import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  checkOfferAction,
  searchAlternativeTransports,
  setHotelOfferInfos,
  setOfferFlexbooked,
  setOfferId,
} from "amadeusredux/lib/hotelSlice";

import { availabilityAndPriceCheck } from "amadeusredux/lib/api";
import { roomTypesForTourOperator } from "../offerutils";
import { currencySymbol } from "../utils";

const nf1 = new Intl.NumberFormat("de-DE", {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});
const nf = new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2 });

function FlightSelect(props) {
  const {
    offer, //setredrawVoucher,
    outboundStations,
    alternativeTransports,
    outboundFlighttime,
    setOutboundFlighttime,
  } = props;
  const sessionId = useSelector((state) => state.system.sessionId);
  const checkids = useSelector((state) => state.hotel.checkids);
  const dispatch = useDispatch();
  const hotelOfferInfos = useSelector((state) => state.hotel.hotelOfferInfos);
  const stations = hotelOfferInfos.StationDictionary;
  const holidayType = useSelector((state) => state.search.holidayType);
  const offerdiv = React.useRef(null);
  const infos = useSelector((state) => state.properties.infos);
  const intl = useIntl();
  const df = new Intl.DateTimeFormat(intl.locale, {
    weekday: "short",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const [showAlternativeTransports, setShowAlternativeTransports] =
    React.useState(false);
  const [showallflighttimes, setshowallflighttimes] = React.useState(false);
  const [outboundairport, setoutboundairport] = React.useState("all");
  const [selectedFlight, setselectedFlight] = React.useState("");

  const outboundairports = useSelector((state) => state.search.stations);

  let hotels = hotelOfferInfos.HotelDictionary;
  const [packageInfo, setPackageInfo] = React.useState({
    outbound: "",
    inbound: "",
    offerId: "",
  });
  const hotel = hotels
    ? hotels.Hotel.find(
        (e) =>
          e.HotelID ===
          offer?.OfferServices?.Package?.Accommodation?.HotelRef?.HotelID
      )
    : {};

  let offerstatus = 0;

  if (offer?.bookinginfo) {
    if (
      offer?.bookinginfo?.Status?.Status === "Available" ||
      offer?.bookinginfo?.Status?.Status === "ToConfirm" ||
      offer?.bookinginfo?.Status?.Status === "OnRequest"
    ) {
      offerstatus = 1;
    } else {
      offerstatus = -1;
    }
  }

  let reason = "";
  reason = offer?.bookinginfo?.Status?.StatusNo;

  return (
    <>
      <div className="row justify-content-md-left ">
        {outboundStations.length > 0 && (
          <div className="col-sm-12  col-md-12 col-lg-12  ">
            <div
              className="text-body font-weight-bold mb-2 ms-3 mt-1 companybutton"
              onClick={() => setshowallflighttimes(!showallflighttimes)}
            >
              <span>
                <i class="bi bi-clock-history"></i>
              </span>
              &nbsp;Wählen Sie aus {outboundairports.length} Flughäfen.
            </div>

            {showallflighttimes && (
              <>
                <div
                  className="overflow-auto ms-3"
                  style={{ overflowY: "scroll", height: "35px" }}
                >
                  <span
                    onClick={() => setoutboundairport("all")}
                    className=" fw-light small me-1 mt-1 smallbutton"
                  >
                    alle&nbsp;&nbsp;
                  </span>

                  {Array.isArray(outboundairports) &&
                    outboundairports.map((oba, i) => {
                      return (
                        <>
                          <span
                            onClick={() =>
                              oba.value === outboundairport
                                ? setoutboundairport("all")
                                : setoutboundairport(oba.value)
                            }
                            className=" fw-light small me-1 mt-1 smallbutton"
                          >
                            {oba.value}&nbsp;&nbsp;
                          </span>
                        </>
                      );
                    })}
                </div>
                <div>
                  <div
                    className="ms-3 "
                    style={{ height: 30, display: "flex", overflowY: "scroll" }}
                  >
                    {Array.isArray(outboundStations) &&
                      outboundStations
                        .sort((a, b) => {
                          return a.times[0]?.time < b.times[0]?.time
                            ? -1
                            : a.times[0]?.time == b.times[0]?.time
                            ? 0
                            : 1;
                        })
                        .filter(
                          (e) =>
                            e.times[0].outboundairport === outboundairport ||
                            outboundairport === "all"
                        )
                        .map((outbounds, i) => {
                          return (
                            <>
                              {outbounds.times.map((flighttime, ti) => {
                                return (
                                  <>
                                    <div>
                                      <span
                                        className=" fw-light small smallbutton me-1 mt-1 "
                                        onClick={() => {
                                          let a = document.getElementById(
                                            "outbound" +
                                              flighttime.outboundairport +
                                              flighttime.time +
                                              flighttime.arrivaltime
                                          );
                                          if (a)
                                            a.scrollIntoView({
                                              behavior: "smooth",
                                              block: "center",
                                            });
                                          setselectedFlight(
                                            "outbound" +
                                              flighttime.outboundairport +
                                              flighttime.time +
                                              flighttime.arrivaltime
                                          );
                                          return false;
                                        }}
                                      >
                                        {" "}
                                        {flighttime.outboundairport}&nbsp;
                                        {flighttime.time}
                                      </span>
                                      &nbsp;&nbsp;
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          );
                        })}
                  </div>
                  <div>
                    &nbsp;
                    <br />
                  </div>
                  <div>
                    &nbsp;
                    <br />
                  </div>
                </div>
              </>
            )}

            <div>
              <div className="overflow-auto" style={{ height: "150px" }}>
                <div style={{ display: "flex", overflowY: "scroll" }}>
                  {Array.isArray(outboundStations) &&
                    outboundStations.map((outbounds, i) => {
                      return (
                        <>
                          {outbounds.times.map((flighttime, ti) => {
                            let offerPackageFlight =
                              offer?.OfferServices?.Package?.Flight
                                ?.OutboundFlight;
                            let curFlight =
                              flighttime.outboundairport ===
                                outboundFlighttime.outboundairport &&
                              flighttime.date === outboundFlighttime.date &&
                              flighttime.time === outboundFlighttime.time &&
                              flighttime.arrivaldate ===
                                outboundFlighttime.arrivaldate &&
                              flighttime.arrivaltime ===
                                outboundFlighttime.arrivaltime;

                            let offerFlight =
                              flighttime.outboundairport ===
                                offerPackageFlight.FlightDeparture
                                  .DepartureAirportRef.AirportCode &&
                              flighttime.date ===
                                offerPackageFlight.FlightDeparture
                                  .DepartureDate &&
                              flighttime.time ===
                                offerPackageFlight.FlightDeparture
                                  .DepartureTime &&
                              flighttime.arrivaldate ===
                                offerPackageFlight.FlightDeparture
                                  .ArrivalDateate &&
                              flighttime.arrivaltime ===
                                offerPackageFlight.FlightDeparture.ArrivalTime;
                            return (
                              <>
                                <div
                                  style={{
                                    height: "60px",
                                    minWidth: "250px",
                                  }}
                                  id={
                                    "outbound" +
                                    flighttime.outboundairport +
                                    flighttime.time +
                                    flighttime.arrivaltime
                                  }
                                  onClick={() =>
                                    setOutboundFlighttime(flighttime)
                                  }
                                  className={
                                    "mb-0 text small d-flex justify-content-center me-1 " +
                                    (curFlight || offerFlight
                                      ? " selectedbutton "
                                      : " smallbutton ")
                                  }
                                >
                                  <span>
                                    {flighttime.outboundairport}{" "}
                                    {flighttime.departure} -{" "}
                                    {flighttime.arrivaltime} <br />
                                    {flighttime.airline} ab {flighttime.price}{" "}
                                    {currencySymbol(
                                      offer.PriceInfo.Price.CurrencyCode
                                    )}
                                  </span>
                                </div>
                              </>
                            );
                          })}
                        </>
                      );
                    })}
                </div>
                <div className="text-body font-weight-bold mt-1">
                  {(outboundFlighttime.outboundairport &&
                    Array.isArray(alternativeTransports?.Offers?.Offer) &&
                    alternativeTransports.Offers.Offer.filter(
                      (e) =>
                        e.OfferServices.Package.Flight.OutboundFlight
                          .FlightDeparture.DepartureAirportRef.AirportCode ===
                          outboundFlighttime.outboundairport &&
                        e.OfferServices.Package.Flight.OutboundFlight
                          .FlightDeparture.DepartureDate ===
                          outboundFlighttime.date &&
                        e.OfferServices.Package.Flight.OutboundFlight
                          .FlightDeparture.DepartureTime ===
                          outboundFlighttime.time &&
                        e.OfferServices.Package.Flight.OutboundFlight
                          .FlightArrival.ArrivalDate ===
                          outboundFlighttime.arrivaldate &&
                        e.OfferServices.Package.Flight.OutboundFlight
                          .FlightArrival.ArrivalTime ===
                          outboundFlighttime.arrivaltime
                    ).length) > 0
                    ? "wählen Sie aus " +
                      (outboundFlighttime.outboundairport &&
                        Array.isArray(alternativeTransports?.Offers?.Offer) &&
                        alternativeTransports.Offers.Offer.filter(
                          (e) =>
                            e.OfferServices.Package.Flight.OutboundFlight
                              .FlightDeparture.DepartureAirportRef
                              .AirportCode ===
                              outboundFlighttime.outboundairport &&
                            e.OfferServices.Package.Flight.OutboundFlight
                              .FlightDeparture.DepartureDate ===
                              outboundFlighttime.date &&
                            e.OfferServices.Package.Flight.OutboundFlight
                              .FlightDeparture.DepartureTime ===
                              outboundFlighttime.time &&
                            e.OfferServices.Package.Flight.OutboundFlight
                              .FlightArrival.ArrivalDate ===
                              outboundFlighttime.arrivaldate &&
                            e.OfferServices.Package.Flight.OutboundFlight
                              .FlightArrival.ArrivalTime ===
                              outboundFlighttime.arrivaltime
                        ).length) +
                      " Rückflügen"
                    : ""}
                </div>

                <div style={{ display: "flex", overflowY: "scroll" }}>
                  {outboundFlighttime.outboundairport &&
                    Array.isArray(alternativeTransports?.Offers?.Offer) &&
                    alternativeTransports.Offers.Offer.filter(
                      (e) =>
                        e.OfferServices.Package.Flight.OutboundFlight
                          .FlightDeparture.DepartureAirportRef.AirportCode ===
                          outboundFlighttime.outboundairport &&
                        e.OfferServices.Package.Flight.OutboundFlight
                          .FlightDeparture.DepartureDate ===
                          outboundFlighttime.date &&
                        e.OfferServices.Package.Flight.OutboundFlight
                          .FlightDeparture.DepartureTime ===
                          outboundFlighttime.time &&
                        e.OfferServices.Package.Flight.OutboundFlight
                          .FlightArrival.ArrivalDate ===
                          outboundFlighttime.arrivaldate &&
                        e.OfferServices.Package.Flight.OutboundFlight
                          .FlightArrival.ArrivalTime ===
                          outboundFlighttime.arrivaltime
                    ).map((flight, ti) => {
                      return (
                        <>
                          <div
                            id={"inbound" + offer.OfferID}
                            style={{ height: "60px", minWidth: "250px" }}
                            onClick={() => {
                              let addOffer = true;
                              if (addOffer) {
                                if (
                                  Array.isArray(hotelOfferInfos?.Offers?.Offer)
                                ) {
                                  let tmp = [...hotelOfferInfos.Offers.Offer];
                                  let found = false;
                                  for (let i = 0; i < tmp.length; i++) {
                                    if (tmp[i].OfferID === flight.OfferID) {
                                      found = true;
                                      break;
                                    }
                                  }

                                  if (!found) {
                                    tmp.push(flight);
                                    let offs = {
                                      ...hotelOfferInfos.Offers,
                                    };
                                    offs.OfferCount = offs.OfferCount + 1;
                                    offs.Offer = [...tmp];
                                    dispatch(
                                      setHotelOfferInfos({
                                        ...hotelOfferInfos,
                                        Offers: offs,
                                      })
                                    );
                                  }
                                }
                              }
                              dispatch(
                                setOfferFlexbooked({
                                  offerId: flight.OfferID,
                                  flexbooked: false,
                                })
                              );
                              dispatch(setOfferId(flight.OfferID));
                              //setredrawVoucher(true);
                            }}
                            className={
                              "mb-0 text small me-1 d-flex justify-content-center " +
                              (flight.OfferID === offer.OfferID
                                ? " selectedbutton  "
                                : " smallbutton  ")
                            }
                          >
                            {
                              flight.OfferServices.Package.Flight.InboundFlight
                                .FlightDeparture.DepartureAirportRef.AirportCode
                            }
                            &nbsp;
                            {
                              flight.OfferServices.Package.Flight.InboundFlight
                                .FlightDeparture.DepartureTime
                            }{" "}
                            -
                            {
                              flight.OfferServices.Package.Flight.InboundFlight
                                .FlightArrival.ArrivalTime
                            }
                            &nbsp;
                            {
                              flight.OfferServices.Package.Flight.InboundFlight
                                .FlightArrival.ArrivalAirportRef.AirportCode
                            }{" "}
                            <br />
                            {flight.OfferServices?.Package?.Flight
                              ?.InboundFlight.Airline?.AirlineName
                              ? flight.OfferServices?.Package?.Flight
                                  ?.InboundFlight.Airline?.AirlineName
                              : "n.A."}
                            &nbsp;
                            {flight.PriceInfo.Price.value *
                              (offer.adults * 1 + offer.children * 1)}
                            &nbsp;
                            {currencySymbol(offer.PriceInfo.Price.CurrencyCode)}
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default FlightSelect;
