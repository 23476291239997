import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { getFilters } from "amadeusredux/lib/api";
import { setSearchOption } from "amadeusredux/lib/searchSlice";

export default function AllTouroperatorsFilter(props) {
  const { changeFilterAndSearch } = props;
  const dispatch = useDispatch();

  const [showAll, setShowAll] = React.useState(false);

  const loading = useSelector((state) => state.system.loading);
  const view = useSelector((state) => state.system.view);

  const searchTourOperators = useSelector(
    (state) => state.search.tourOperators
  );

  const tourOperators = useSelector((state) => state.properties.tourOperators);

  let ct = 0;

  return (
    <div>
      <span className="fw-semibold">Veranstalter</span>

      {[...tourOperators]
        .sort((a, b) =>
          ("" + a.TourOperatorName).localeCompare(b.TourOperatorName)
        )
        .map((itm) => {
          ct = ct + 1;
          if (ct > 5 && !showAll) return null;

          let disabled = false;
          if (loading > 0) disabled = true;
          return (
            <div key={itm.value}>
              <div style={{ display: "flex" }}  className="mb-2">
                &nbsp;
                <input
                  checked={searchTourOperators.includes(itm.TourOperatorCode)}
                  onChange={() => {
                    let changed = [...searchTourOperators];
                    if (changed.includes(itm.TourOperatorCode)) {
                      changed = changed.filter(
                        (e) => e !== itm.TourOperatorCode
                      );
                    } else {
                      changed.push(itm.TourOperatorCode);
                    }

                    dispatch(
                      setSearchOption({
                        property: "tourOperators",
                        data: changed,
                      })
                    );

                    getFilters(dispatch, false);
                  }}
                  disabled={disabled}
                  type="checkbox"
                />
                &nbsp;
                <div
                  dangerouslySetInnerHTML={{
                    __html: "" + itm.TourOperatorName,
                  }}
                ></div>
              </div>
            </div>
          );
        })}
      {!showAll && ct > 5 && (
        <div style={{ cursor: "pointer" }} onClick={() => setShowAll(true)}>
          alle anzeigen
        </div>
      )}
      {showAll && (
        <div style={{ cursor: "pointer" }} onClick={() => setShowAll(false)}>
          weniger anzeigen
        </div>
      )}
    </div>
  );
}
