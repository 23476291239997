import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import FlightLocations from "../components/FlightLocations";
import OfferFlight from "../components/OfferFlight";
import CancellationOptions from "../CancellationOptions";
import {
  checkOfferAction,
  searchAlternativeTransports,
  setOfferFlexbooked,
  setOfferId,
} from "amadeusredux/lib/hotelSlice";
import InfoPopupHover from "../components/InfoPopupHover";
import { AiOutlineMail } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";
import {
  FaHeart,
  FaInfoCircle,
  FaPlane,
  FaShareAlt,
  FaLongArrowAltLeft,
  FaLongArrowAltRight,
  FaCaretLeft,
} from "react-icons/fa";
import { HiOutlineRefresh } from "react-icons/hi";

import { MdContentCopy } from "react-icons/md";
import {
  airportcity,
  boardTypes,
  propertyFromArray,
  roomViews,
  currencySymbol,
  paymentOptions,
  roomTypes,
  stars,
} from "../utils";
const nf1 = new Intl.NumberFormat("de-DE", {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});
const nf = new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2 });

function MobileVoucherRooms(props) {
  const {
    offer,
    mode,
    redrawVoucher,
    //setredrawVoucher,
    otherRoomOffers,
    flexbooked,
    reservationselected,
    betterOffersButton,
    fullinfo,
    selectedTOMarketingAction,
    selectedTAMarketingAction,
  } = props;
  const dispatch = useDispatch();
  const hotelOfferInfos = useSelector((state) => state.hotel.hotelOfferInfos);
  const stations = hotelOfferInfos.StationDictionary;
  const holidayType = useSelector((state) => state.search.holidayType);
  const offerdiv = React.useRef(null);
  const infos = useSelector((state) => state.properties.infos);
  const intl = useIntl();
  const df = new Intl.DateTimeFormat(intl.locale, {
    weekday: "short",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  let hotels = hotelOfferInfos.HotelDictionary;
  const [packageInfo, setPackageInfo] = React.useState({
    outbound: "",
    inbound: "",
    offerId: "",
  });
  const hotel = hotels
    ? hotels.Hotel.find(
        (e) =>
          e.HotelID ===
          offer?.OfferServices?.Package?.Accommodation?.HotelRef?.HotelID
      )
    : {};
  let geodata = hotelOfferInfos.GeoDictionary;
  let transferincl = false;
  if (offer?.OfferProperties?.TransferInfos) {
    if (
      Array.isArray(offer.OfferProperties.TransferInfos) &&
      offer.OfferProperties.TransferInfos.includes("TransferIncluded")
    ) {
      transferincl = true;
    }
  }
  let railandfly = false;
  if (offer?.OfferProperties?.OfferIncludes) {
    if (
      Array.isArray(offer.OfferProperties.OfferIncludes) &&
      offer.OfferProperties.OfferIncludes.includes("RailAndFly")
    ) {
      railandfly = true;
    }
  }

  let rentalCar = false;
  if (offer?.OfferProperties?.OfferIncludes) {
    if (
      Array.isArray(offer.OfferProperties.OfferIncludes) &&
      offer.OfferProperties.OfferIncludes.includes("RentalCar")
    ) {
      rentalCar = true;
    }
  }
  let freeRebook = false;
  if (offer?.OfferProperties?.OfferIncludes) {
    if (
      Array.isArray(offer.OfferProperties.OfferIncludes) &&
      offer.OfferProperties.OfferIncludes.includes("FreeRebooking")
    ) {
      freeRebook = true;
    }
  }

  let cancellationoption = "";
  if (offer?.OfferProperties?.CancellationOption) {
    cancellationoption = offer?.OfferProperties?.CancellationOption;
  }

  let realprice = 0;

  if (offer?.bookinginfo?.PriceInformation?.TotalPrice?.Amount?._) {
    realprice = offer?.bookinginfo?.PriceInformation?.TotalPrice?.Amount?._ * 1;
  }

  if (realprice === 0 && offer.PriceInfo?.Price.value) {
    realprice =
      offer.PriceInfo?.Price.value * (offer.adults * 1 + offer.children * 1);
  }

  let reducedprice = realprice;
  let flexprice = -1;

  if (mode !== "booking" && realprice && offer.flexbooked) {
    if (
      Array.isArray(offer.MarketingActions) &&
      offer.MarketingActions.length > 0 &&
      !isNaN(
        offer.MarketingActions[0].flexconditions?.flexoptions?.flexoptionprice
      ) &&
      offer.MarketingActions[0].flexconditions?.flexoptions?.flexoptionprice
    )
      realprice =
        realprice +
        offer.MarketingActions[0].flexconditions.flexoptions.flexoptionprice *
          1;
    flexprice =
      offer.MarketingActions[0].flexconditions.flexoptions.flexoptionprice * 1;
  }

  if (mode === "booking" && realprice && flexbooked) {
    if (
      Array.isArray(offer.MarketingActions) &&
      offer.MarketingActions.length > 0 &&
      !isNaN(
        offer.MarketingActions[0].flexconditions?.flexoptions?.flexoptionprice
      ) &&
      offer.MarketingActions[0].flexconditions?.flexoptions?.flexoptionprice
    )
      realprice =
        realprice +
        offer.MarketingActions[0].flexconditions.flexoptions.flexoptionprice *
          1;
    flexprice =
      offer.MarketingActions[0].flexconditions.flexoptions.flexoptionprice * 1;
  }

  let offerstatus = 0;

  if (offer?.bookinginfo) {
    if (
      offer?.bookinginfo?.Status?.Status === "Available" ||
      offer?.bookinginfo?.Status?.Status === "ToConfirm" ||
      offer?.bookinginfo?.Status?.Status === "OnRequest"
    ) {
      offerstatus = 1;
    } else {
      offerstatus = -1;
    }
  }
  let columnclass = fullinfo
    ? "col-sm-12  col-md-4 col-lg-4 "
    : "col-sm-12  col-md-6 col-lg-6 ";
  //console.log(offer.PriceInfo.Price.value);

  let marketingGroup = "";

  let reason = "";
  reason = offer?.bookinginfo?.Status?.StatusNo;

  return (
    <>
      <div
        className="col-sm-12  col-md-12 col-lg-12 "
        onClick={() => {
          //setFlexSelected(false);
          dispatch(
            setOfferFlexbooked({
              offerId: offer.OfferID,
              flexbooked: false,
            })
          );
          dispatch(setOfferId(offer.OfferID));
          //setredrawVoucher(true);
        }}
      >
        <div>
          <p
            className="text-body font-weight-bold mb-0"
            title={offer.OfferServices?.Package.Accommodation.Room.RoomName}
          >
            {offer.OfferServices?.Package.Accommodation.Room.RoomName}
          </p>

          {offerstatus === 0 && (
            <>
              <p className="text-body mb-2 small font-weight-bold bg-warning bg-opacity-10">
                Zimmerverfügbarkeit wird geprüft
              </p>
            </>
          )}
          {offerstatus === 1 && (
            <>
              <p className="text-body mb-2  font-weight-bold">
                ab {realprice} €
              </p>
            </>
          )}
          {offerstatus === -1 && (
            <>
              <p className="text-body mb-2 small font-weight-bold bg-danger bg-opacity-10 ">
                {!reason && <>leider nicht verfügbar.</>}
                {reason &&
                  (reason.startsWith("41") ? (
                    <>Flug leider nicht verfügbar.</>
                  ) : reason.startsWith("42") ? (
                    <>Zimmer leider nicht verfügbar.</>
                  ) : (
                    <>leider nicht verfügbar.</>
                  ))}
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default MobileVoucherRooms;
