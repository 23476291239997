import React from "react";
import { format } from "date-fns";

import { v4 as uuidv4 } from "uuid";
import {
  MdGolfCourse,
  MdLocalParking,
  MdMasks,
  MdPets,
  MdRestaurantMenu,
  MdSailing,
  MdSmokeFree,
  MdStar,
  MdWifi,
  MdBrightness1,
} from "react-icons/md";
import { MdBeachAccess } from "react-icons/md";
import { PRICELIMIT } from "./config";

import ActionFun from "./icons/ActionFun";
import Babybett from "./icons/Babybett";
import Barrierefrei from "./icons/Barrierefrei";
import BeheizterPool from "./icons/BeheizterPool";
import Deluxe from "./icons/Deluxe";
import DeutschsprachigeKinderbetreuung from "./icons/DeutschsprachigeKinderbetreuung";
import Familiaer from "./icons/Familiaer";
import GrossesSportangebot from "./icons/GrossesSportangebot";
import Hallenbad from "./icons/Hallenbad";
import Kinderbuffet from "./icons/Kinderbuffet";
import Kinderpool from "./icons/Kinderpool";
import Kinderspielraum from "./icons/Kinderspielraum";
import Single from "./icons/Single";
import SinglemitKind from "./icons/SinglemitKind";
import Stadthotel from "./icons/Stadthotel";
import Thalasso from "./icons/Thalasso";
import Wasserrutschen from "./icons/Wasserrutschen";
import WellnessEinrichtungen from "./icons/WellnessEinrichtungen";

import Adult from "./icons/Adult";
import AelteresKind from "./icons/AelteresKind";
import Arzt from "./icons/Arzt";
import Arztservice from "./icons/Arztservice";
import Ayurveda from "./icons/Ayurveda";
import Baby from "./icons/Baby";
import Babyausstattung from "./icons/Babyausstattung";
import BabyClub from "./icons/BabyClub";
import BabyClub2 from "./icons/BabyClub2";
import Babysitting from "./icons/Babysitting";
import Badminton from "./icons/Badminton";
import Bar from "./icons/Bar";
import Berge from "./icons/Berge";
import Bodysoul from "./icons/Bodysoul";
import Buggy from "./icons/Buggy";
import ClubJugend from "./icons/ClubJugend";
import Disco from "./icons/Disco";
import Familie from "./icons/Familie";
import Familie2 from "./icons/Familie2";
import Family from "./icons/Family";
import Fitnessraum from "./icons/Fitnessraum";
import Freizeitpark from "./icons/Freizeitpark";
import Fussball from "./icons/Fussball";
import Gay from "./icons/Gay";
import Golf from "./icons/Golf";
import GourmetLobster from "./icons/GourmetLobster";
import Gymnastik from "./icons/Gymnastik";
import Haustier from "./icons/Haustier";
import Hochzeit from "./icons/Hochzeit";
import Kind from "./icons/Kind";
import Lift from "./icons/Lift";
import Luftballons from "./icons/Luftballons";
import Massage from "./icons/Massage";
import Massage2 from "./icons/Massage2";
import Meditation from "./icons/Meditation";
import Muschel from "./icons/Muschel";
import NearBeach from "./icons/NearBeach";
import NoSmoke from "./icons/NoSmoke";
import PaarHerz from "./icons/PaarHerz";
import Parken from "./icons/Parken";
import Pool from "./icons/Pool";
import Maske from "./icons/Maske";
import Rad from "./icons/Rad";
import Reiterin from "./icons/Reiterin";
import Restaurant from "./icons/Restaurant";
import RestaurantOberGourmet from "./icons/RestaurantOberGourmet";
import Ruhe from "./icons/Ruhe";
import Sandeimer from "./icons/Sandeimer";
import Sandstrand from "./icons/Sandstrand";
import Sauna from "./icons/Sauna";
import Schwimmschule from "./icons/Schwimmschule";
import Segeln from "./icons/Segeln";
import ServiceRestaurant from "./icons/ServiceRestaurant";
import Ski from "./icons/Ski";
import SkiGondel from "./icons/SkiGondel";
import Spielplatz from "./icons/Spielplatz";
import Squash from "./icons/Squash";
import Stadt from "./icons/Stadt";
import StrandNah from "./icons/StrandNah";
import Streichelzoo from "./icons/Streichelzoo";
import StreichelzooPferd from "./icons/StreichelzooPferd";
import Surf from "./icons/Surf";
import Tauchen from "./icons/Tauchen";
import Tauchen2 from "./icons/Tauchen2";
import Tennis from "./icons/Tennis";
import Waescheservice from "./icons/Waescheservice";
import Wassersport from "./icons/Wassersport";
import Wellness from "./icons/Wellness";
import Wellness2 from "./icons/Wellness2";
import Wlan from "./icons/Wlan";
import Yoga from "./icons/Yoga";
import ZimmerserviceGourmet from "./icons/ZimmerserviceGourmet";

import { FaCcAmex, FaCcMastercard, FaCcVisa } from "react-icons/fa";
import { SiSepa } from "react-icons/si";
import { Visa, Amex, Sepa, Mastercard, Diners, Giropay } from "react-pay-icons";
import { BsPhone } from "react-icons/bs";

import { GiRotaryPhone } from "react-icons/gi";

import AmexNew from "./assets/AMEX.png";
import DinersNew from "./assets/DinersClub.png";
import GiropayNew from "./assets/Directdebit.png";
import MastercardNew from "./assets/Mastercard-Schwarz.png";
import SepaNew from "./assets/SEPA-BANK.png";
import SepaPhoneNew from "./assets/SEPA-Phone.png";
import VisaNew from "./assets/VISA.png";
import { setSearchOption } from "amadeusredux/lib/searchSlice";
import { getFilters } from "amadeusredux/lib/api";

const styles = {
  fontFamily: "sans-serif",
  textAlign: "center",
};

export const daysInThisMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
};

export const currencySymbol = (currCode) => {
  switch (currCode) {
    case "EUR":
      return "€";
    default:
      return currCode;
  }
};

export const getHost = () => {
  return process.env.NODE_ENV === `development` ||
    document.location.href.indexOf("themewordpress") > -1 ||
    document.location.href.indexOf("app.holiserver.com") > -1
    ? "https://amadeus.holiserver.com/testapi"
    : //"http://localhost:4000/api"
      window.location.protocol + "//" + window.location.hostname + "/api";
};

export const printError = (error) => {
  console.log(error);
};

function intDividers(a) {
  let ma = [];
  for (let i = 1; i <= a; i++) {
    if (a % i === 0) {
      ma.push(i);
    }
  }
  return ma;
}

export const commonDivisors = (a, b) => {
  console.log(a, b);
  let factorsa = intDividers(a);
  let factorsb = intDividers(b);
  console.log(factorsa, factorsb);
  let res = [];
  for (let i = 0; i < factorsa.length; i++) {
    if (factorsb.includes(factorsa[i])) {
      res.push(factorsa[i]);
    }
  }

  return res;
};

export const updateairlines = (
  dispatch,
  airlines,
  originalPromiseResult,
  airlinerank,
  nolowcost,
  linie
) => {
  let oplines = [];
  if (
    !Array.isArrayoriginalPromiseResult?.TransportOperatingBrands
      ?.TransportOperatingBrand
  )
    return;
  for (
    let i = 0;
    i <
    originalPromiseResult.TransportOperatingBrands.TransportOperatingBrand
      .length;
    i++
  ) {
    let a = airlines.find(
      (f) =>
        f.value ===
        originalPromiseResult.TransportOperatingBrands.TransportOperatingBrand[
          i
        ].Code
    );
    oplines.push({
      value:
        originalPromiseResult.TransportOperatingBrands.TransportOperatingBrand[
          i
        ].Code,
      label:
        originalPromiseResult.TransportOperatingBrands.TransportOperatingBrand[
          i
        ].Name,
      properties: a ? a.properties : {},
    });
  }

  reevaluateAirlines(dispatch, oplines, airlinerank, nolowcost, linie);
};

export const reevaluateAirlines = (
  dispatch,
  destinationAirlines,
  airlinerank,
  nolowcost,
  linie
) => {
  let val = !airlinerank;

  let brands = [...destinationAirlines];
  if (airlinerank && brands.length > 0) {
    brands = brands.filter((e) => e.properties?.ranking < 3);
  }

  if (nolowcost && brands.length > 0) {
    brands = brands.filter((e) => e.properties?.type !== "Low Cost");
  }

  if (linie && brands.length > 0) {
    brands = brands.filter((e) => e.properties?.type === "Linie");
  }

  if (!linie && !nolowcost && !airlinerank) {
    brands = [];
  }

  if (brands.length > 0)
    dispatch(
      setSearchOption({
        property: "operatingbrand",
        data: brands.map((e) => e.value),
      })
    );
  else
    dispatch(
      setSearchOption({
        property: "operatingbrand",
        data: [],
      })
    );

  dispatch(
    setSearchOption({
      property: "airlinerank",
      data: airlinerank,
    })
  );

  dispatch(
    setSearchOption({
      property: "nolowcost",
      data: nolowcost,
    })
  );

  dispatch(
    setSearchOption({
      property: "linie",
      data: linie,
    })
  );

  getFilters(dispatch, false);
};

export const crtobr = (text) => {
  if (!text) return null;
  return text.split("\n").map((item, idx) => {
    return (
      <span key={idx}>
        {item}
        <br />
      </span>
    );
  });
};

export const hotelReviewsText = (txt) => {
  if (txt === "hotelRatingDetailsHygiene") return "Hygiene";
  if (txt === "hotelRatingDetailsWinterSportOffer")
    return "Wintersport-Angebot";
  if (txt === "hotelRatingDetailsWellness") return "Wellness";
  if (txt === "hotelRatingDetailsSport") return "Sportangebot";
  if (txt === "hotelRatingDetailsSkiArea") return "Skigebiet";
  if (txt === "hotelRatingDetailsService") return "Service";
  if (txt === "hotelRatingDetailsRoom") return "Zimmer";
  if (txt === "hotelRatingDetailsPool") return "Pool";
  if (txt === "hotelRatingDetailsLocation") return "Lage";
  if (txt === "hotelRatingDetailsInfrastructure") return "Infrastruktur";
  if (txt === "hotelRatingDetailsHotel") return "Hotel";
  if (txt === "hotelRatingDetailsFamily") return "Familienurlaub";
  if (txt === "hotelRatingDetailsEatAndDrink") return "Essen & Trinken";
  if (txt === "hotelRatingDetailsCulture") return "Kulturangebot";
  if (txt === "hotelRatingDetailsBeach") return "Strand";
  if (txt === "hotelRatingDetailsActivities") return "Aktivitäten";

  return txt;
};

export const hotelReviewDetailsText = (txt) => {
  if (txt === "cleanness") return "Sauberkeit";
  if (txt === "size") return "Größe";
  if (txt === "environment") return "Umgebung";
  if (txt === "recreationalValue") return "Erholungswert";
  if (txt === "suitableForCouple") return "für Paare";
  if (txt === "suitableForFamily") return "Für Familien";
  if (txt === "suitableForGroup") return "Für Gruppen";
  if (txt === "suitableForSingle") return "Für Alleinreisende";
  if (txt === "bath") return "Bad";
  if (txt === "design") return "Design";
  if (txt === "germanSpeaking") return "Deutschsprachig";
  if (txt === "reception") return "Rezeption";
  if (txt === "staff") return "Personal";
  if (txt === "drinks") return "Getränke";
  if (txt === "food") return "Speisen";
  if (txt === "party") return "Party";
  if (txt === "restaurants") return "Restaurants";
  if (txt === "gastronomy") return "Gastronomie";
  if (txt === "possibilityOfSport") return "Sportmöglichkeiten";
  if (txt === "reachability") return "Erreichbarkeit";
  if (txt === "distanceToBeach") return "Strandnähe";
  if (txt === "possibilityOfExcursion") return "Ausflugsmöglichkeiten";
  if (txt === "possibilityOfRecreation") return "Erholungsmöglichkeiten";
  if (txt === "possibilityOfShopping") return "Einkaufsmöglichkeiten";
  if (txt === "tennis") return "Tennis";
  if (txt === "sportsEquipment") return "Ausrüstung";
  if (txt === "animation") return "Animation";

  return txt;
};

export const normalizeRoomName = (roomname) => {
  /*if (roomname.indexOf("Einzelbelegung") > -1) roomname = "Doppelzimmer";
  if (roomname.indexOf("DZ") > -1) roomname = "Doppelzimmer";
  if (roomname.indexOf("Doppel") > -1 || roomname.indexOf("DOPPEL") > -1)
    roomname = "Doppelzimmer";
  if (roomname.indexOf("Double") > -1 || roomname.indexOf("DOUBLE") > -1)
    roomname = "Doppelzimmer";
  if (roomname.indexOf("EZ") > -1) roomname = "Einzelzimmer";
  if (roomname.indexOf("Einzel") > -1 || roomname.indexOf("EINZEL") > -1)
    roomname = "Einzelzimmer";
  if (roomname.indexOf("Single") > -1 || roomname.indexOf("SINGLE") > -1)
    roomname = "Einzelzimmer";
  if (roomname.indexOf("1Erw") > -1) roomname = "Einzelzimmer";*/

  return roomname;
};

/*export const populateOfferRequest = (
  fromDate,
  untilDate,
  station,
  searchOptions,
  selectedDestination
) => {
  let parms = "";
 
  if (
    selectedDestination.HotelCodes &&
    selectedDestination.HotelCodes.HotelGiataID
  ) {
    parms += "HotelGiataID=" + selectedDestination.HotelCodes.HotelGiataID;
  } else {
    if (selectedDestination.CityID) {
      parms += "CityID=" + selectedDestination.CityID;
    } else {
      parms += "RegionID=" + selectedDestination.RegionID;
    }
  }
 
  let childrenages = [];
  for (let i = 0; i < searchOptions.children.value * 1; i++) {
    childrenages.push(searchOptions.childrenages[i].value);
  }
 
  return (
    parms +
    "&adults=" +
    searchOptions.adults.value +
    "&children=" +
    searchOptions.children.value +
    "&childrenages=" +
    childrenages.join(",") +
    "&duration=" +
    searchOptions.duration.value +
    "&minDate=" +
    fromDate +
    "&maxDate=" +
    untilDate +
    (searchOptions.outboundtime !== "0-23"
      ? "&outboundtime=" +
        (searchOptions.outboundtime.split("-")[0] * 1 < 10
          ? "0" + searchOptions.outboundtime.split("-")[0]
          : searchOptions.outboundtime.split("-")[0]) +
        ":00-" +
        (searchOptions.outboundtime.split("-")[1] * 1 < 10
          ? "0" + searchOptions.outboundtime.split("-")[1]
          : searchOptions.outboundtime.split("-")[1] + ":59")
      : "") +
    (searchOptions.inboundtime !== "0-23"
      ? "&inboundtime=" +
        (searchOptions.inboundtime.split("-")[0] * 1 < 10
          ? "0" + searchOptions.inboundtime.split("-")[0]
          : searchOptions.inboundtime.split("-")[0]) +
        ":00-" +
        (searchOptions.inboundtime.split("-")[1] * 1 < 10
          ? "0" + searchOptions.inboundtime.split("-")[1]
          : searchOptions.inboundtime.split("-")[1] + ":59")
      : "") +
    (searchOptions.directflight
      ? "&directflight=" + searchOptions.directflight
      : "") +
    (searchOptions.freecancellation
      ? "&freecancellation=" + searchOptions.freecancellation
      : "") +
    (searchOptions.transferincluded
      ? "&transferincluded=" + searchOptions.transferincluded
      : "") +
    (searchOptions.railandfly
      ? "&railandfly=" + searchOptions.railandfly
      : "") +
    (searchOptions.ratingcount
      ? "&ratingcount=" + searchOptions.ratingcount
      : "") +
    //(searchOptions.sorting ? "&sorting=" + searchOptions.sorting : "") +
    "&stations=" +
    station
  );
};*/

export const touroperatorMappings = {
  XNEC: ["NEC"],
  XBU: ["BU"],
  XOGE: ["OGE"],
  XANE: ["ANEX"],
  XFTI: ["FTI"],
  X5VF: ["5VF"],
  XBIG: ["BIG"],
  ALDX: ["ALD"],
  XTUI: ["TUID"],
  XOLI: ["OLI"],
  XMWR: ["MWR"],
  XLMX: ["LMX"],
  XJAH: ["JAHN"],
  XFIT: ["FIT"],
  XECC: ["ECC"],
  XETI: ["ETI"],
  XDER: ["DER"],
  XALL: ["ALL"],
};

export const ancillaries = [
  {
    touroperator: "FTI",
    id: "OptionalFlexRate",
    flexdays: 15,
    label:
      "FLEXPLUS-TARIF (15 Tage) Aktion 16.01. - 12.02.23 am Buchungstag nur im Aktionszeitraum gegen Aufpreis zubuchbar 1 EUR unabhängig vom Gesamtreisepreis",
    price: 1,
  },
];

export const paymentOptions = [
  {
    id: "VI",
    type: "CreditCard",
    label: "Visa",
    icon: (
      <>
        <img
          src="https://holiday.placelogg.com/holidaymedia/VISA.png"
          className="payicon"
        />
      </>
    ),
  },
  {
    id: "MC",
    type: "CreditCard",
    label: "Master Card",
    icon: (
      <>
        <img
          src="https://holiday.placelogg.com/holidaymedia/Mastercard-Schwarz.png"
          className="payicon"
        />
      </>
    ),
  },
  {
    id: "AX",
    type: "CreditCard",
    label: "American Express",
    icon: (
      <>
        <img
          src="https://holiday.placelogg.com/holidaymedia/AMEX.png"
          className="payicon"
        />
      </>
    ),
  },
  {
    id: "DN",
    type: "CreditCard",
    label: "Diners Club",
    icon: (
      <>
        <img
          src="https://holiday.placelogg.com/holidaymedia/DinersClub.png"
          className="payicon"
        />
      </>
    ),
  },
  {
    id: "DirectDebitInternational",
    type: "DirectDebitInternational",
    label: "SEPA",
    icon: (
      <>
        <img
          src="https://holiday.placelogg.com/holidaymedia/SEPA-BANK.png"
          className="payicon"
        />
      </>
    ),
  },
  {
    id: "BankTransfer",
    type: "BankTransfer",
    label: "SEPA",
    icon: (
      <>
        <img
          src="https://holiday.placelogg.com/holidaymedia/RG.png"
          className="payicon"
        />
      </>
    ),
  },
  {
    id: "Phone",
    type: "Phone",
    label: "SEPA",
    icon: (
      <>
        <img
          src="https://holiday.placelogg.com/holidaymedia/SEPA-Phone.png"
          className="payphone"
        />
      </>
    ),
  },
];

export const creditCardNames = {
  VI: "Visa",
  MC: "Master Card",
  AX: "American Express",
  BC: "Bank Card",
  BL: "Carte Bleu",
  CB: "Carte Blanche",
  DN: "Diners Club",
  DS: "Discovery Card",
  EC: "Eurocard",
  JC: "Japanese Credit Bureau Credit Card",
  LC: "Local Card",
  MA: "Maestro",
  SO: "Solo",
  CU: "Union Pay",
  TP: "Universal Air Travel Card",
  VE: "Visa Electron",
};

export const adultsOptions = [
  { value: "1", label: "1 Erwachsener" },
  { value: "2", label: "2 Erwachsene" },
  { value: "3", label: "3 Erwachsene" },
  { value: "4", label: "4 Erwachsene" },
  { value: "5", label: "5 Erwachsene" },
  { value: "6", label: "6 Erwachsene" },
];

export const durationkindOptions = [
  { value: "Stay", label: "Aufenthalt" },
  { value: "Trip", label: "Reisedauer" },
  { value: "BetweenDepartures", label: "Zwischen den Abreisen" },
];

export const durationkindOptionsAccomodation = [
  { value: "OvernightStays", label: "Übernachtungen" },
];

export const childrenOptions = [
  { value: "0", label: "kein Kind" },
  { value: "1", label: "1 Kind" },
  { value: "2", label: "2 Kinder" },
  { value: "3", label: "3 Kinder" },
  { value: "4", label: "4 Kinder" },
];

export const childrenAgeOptions = [
  { value: "0", label: "Alter" },
  { value: "1", label: "< 2 Jahre" },
  { value: "2", label: "2 Jahre" },
  { value: "3", label: "3 Jahre" },
  { value: "4", label: "4 Jahre" },
  { value: "5", label: "5 Jahre" },
  { value: "6", label: "6 Jahre" },
  { value: "7", label: "7 Jahre" },
  { value: "8", label: "8 Jahre" },
  { value: "9", label: "9 Jahre" },
  { value: "10", label: "10 Jahre" },
  { value: "11", label: "11 Jahre" },
  { value: "12", label: "12 Jahre" },
  { value: "13", label: "13 Jahre" },
  { value: "14", label: "14 Jahre" },
  { value: "15", label: "15 Jahre" },
  { value: "16", label: "16 Jahre" },
  { value: "17", label: "17 Jahre" },
];

export const populateAlternativeFlightsPost = (
  offer,
  travellers,
  stations,
  durationkind,
  outboundtime,
  inboundtime,
  offset
) => {
  let parms = {};
  parms.queryid = uuidv4();
  //console.log(offer);

  parms.touroperator = offer.TourOperator.TourOperatorCode;
  parms.duration = offer.TravelDateInfo.TripDuration;
  parms.hotelcode = offer.destination.HotelCodes.HotelIffCode;
  parms.servicecode = offer.OfferServices.Package.Accommodation.ServiceCode;
  parms.boardtype = offer.OfferServices.Package.Accommodation.BoardType;
  parms.roomcode = offer.OfferServices.Package.Accommodation.Room.RoomCode;

  parms.outbounddate =
    offer.OfferServices.Package.Flight.OutboundFlight.FlightDeparture.DepartureDate;
  parms.outboundairport = stations.map((e) => e.value);
  parms.durationkind = durationkind.value;
  parms.inbounddate =
    offer.OfferServices.Package.Flight.InboundFlight.FlightDeparture.DepartureDate;
  //parms.inboundairport =
  //stations.map((e)=>e.value);
  parms.offset = offset;
  parms.inboundairport = [
    offer.OfferServices.Package.Flight.InboundFlight.FlightDeparture
      .DepartureAirportRef.AirportCode,
  ];
  parms.travellers = [];
  for (let i = 0; i < travellers.adults.value * 1; i++) {
    parms.travellers.push({ Age: 30 });
  }
  parms.adults = travellers.adults.value * 1;
  parms.children = travellers.children.value * 1;

  if (outboundtime !== "0-23") {
    parms.outboundtime =
      (outboundtime.split("-")[0] * 1 < 10
        ? "0" + outboundtime.split("-")[0]
        : outboundtime.split("-")[0]) +
      ":00-" +
      (outboundtime.split("-")[1] * 1 < 10
        ? "0" + outboundtime.split("-")[1]
        : outboundtime.split("-")[1]) +
      ":59";
  }
  if (inboundtime !== "0-23") {
    parms.inboundtime =
      (inboundtime.split("-")[0] * 1 < 10
        ? "0" + inboundtime.split("-")[0]
        : inboundtime.split("-")[0]) +
      ":00-" +
      (inboundtime.split("-")[1] * 1 < 10
        ? "0" + inboundtime.split("-")[1]
        : inboundtime.split("-")[1]) +
      ":59";
  }

  for (let i = 0; i < travellers.children.value * 1; i++) {
    parms.travellers.push({ Age: travellers.childrenages[i].value * 1 });
  }
  //console.log(parms);

  return parms;
};

export const populateOfferPost = (
  fromDate,
  untilDate,
  station,
  searchOptions,
  selectedDestination,
  touroperator
) => {
  let parms = {};
  parms.queryid = uuidv4();
  if (
    selectedDestination[0].HotelCodes &&
    selectedDestination[0].HotelCodes.HotelGiataID
  ) {
    parms.HotelGiataID = selectedDestination.map(
      (e) => e.HotelCodes.HotelGiataID
    );
  } else {
    if (selectedDestination[0].RegionGroupID) {
      parms.RegionGroupID = selectedDestination.map((e) => e.RegionGroupID);
    } else {
      if (selectedDestination[0].CityID) {
        parms.CityID = selectedDestination.map((e) => e.CityID);
      } else {
        parms.RegionID = selectedDestination.map((e) => e.RegionID);
      }
    }
  }

  let childrenages = [];
  for (let i = 0; i < searchOptions.children.value * 1; i++) {
    childrenages.push(searchOptions.childrenages[i].value);
  }

  parms.adults = searchOptions.adults.value;
  parms.children = searchOptions.children.value;
  parms.childrenages = childrenages.join(",");
  parms.duration = searchOptions.duration.value;
  parms.durationkind = searchOptions.durationkind.value;
  parms.minDate = fromDate;
  parms.maxDate = untilDate;
  if (searchOptions.price < PRICELIMIT) {
    parms.price = searchOptions.price;
  }

  parms.touroperators = touroperator;

  if (searchOptions.outboundtime !== "0-23") {
    parms.outboundtime =
      (searchOptions.outboundtime.split("-")[0] * 1 < 10
        ? "0" + searchOptions.outboundtime.split("-")[0]
        : searchOptions.outboundtime.split("-")[0]) +
      ":00-" +
      (searchOptions.outboundtime.split("-")[1] * 1 < 10
        ? "0" + searchOptions.outboundtime.split("-")[1]
        : searchOptions.outboundtime.split("-")[1]) +
      ":59";
  }
  if (searchOptions.outboundtime !== "0-23") {
    parms.inboundtime =
      (searchOptions.inboundtime.split("-")[0] * 1 < 10
        ? "0" + searchOptions.inboundtime.split("-")[0]
        : searchOptions.inboundtime.split("-")[0]) +
      ":00-" +
      (searchOptions.inboundtime.split("-")[1] * 1 < 10
        ? "0" + searchOptions.inboundtime.split("-")[1]
        : searchOptions.inboundtime.split("-")[1]) +
      ":59";
  }
  if (searchOptions.directflight)
    parms.directflight = searchOptions.directflight;
  if (searchOptions.freecancellation)
    parms.freecancellation = searchOptions.freecancellation;
  if (searchOptions.freerebook) parms.freerebook = searchOptions.freerebook;
  if (searchOptions.addablecancellation)
    parms.addablecancellation = searchOptions.addablecancellation;

  if (searchOptions.transferincluded)
    parms.transferincluded = searchOptions.transferincluded;
  if (searchOptions.railandfly) parms.railandfly = searchOptions.railandfly;
  if (searchOptions.rentalcar) parms.rentalcar = searchOptions.rentalcar;
  if (searchOptions.ratingcount) parms.ratingcount = searchOptions.ratingcount;
  if (searchOptions.recommendationrate)
    parms.recommendationrate = searchOptions.recommendationrate;

  parms.stations = station;
  return parms;
};

export const populatePost = (
  selectedDestination,
  searchOptions,
  departureStations,
  northeast,
  southwest
) => {
  let parms = {};

  //console.log(searchOptions);
  //console.log(departureStations);
  parms.queryid = searchOptions.queryid;
  parms.memoid = searchOptions.mid;
  parms.outbounddate = searchOptions.outbounddate;
  parms.inbounddate = searchOptions.inbounddate;
  if (southwest?.lat && northeast?.lng) {
    parms.lllat = southwest.lat;
    parms.lllng = southwest.lng;
    parms.urlat = northeast.lat;
    parms.urlng = northeast.lng;
  } else {
    if (
      selectedDestination[0].HotelCodes &&
      selectedDestination[0].HotelCodes.HotelGiataID
    ) {
      parms.HotelGiataID = selectedDestination.map(
        (e) => e.HotelCodes.HotelGiataID
      );
    } else {
      parms.RegionGroupID = selectedDestination
        .filter((e) => e.RegionGroupID)
        .map((e) => e.RegionGroupID);
      if (parms.RegionGroupID.length === 0) {
        delete parms.RegionGroupID;
      }
      parms.CityID = selectedDestination
        .filter((e) => e.CityID)
        .map((e) => e.CityID);
      if (parms.CityID.length === 0) {
        delete parms.CityID;
      }
      parms.RegionID = selectedDestination
        .filter((e) => e.RegionID)
        .map((e) => e.RegionID);
      if (parms.RegionID.length === 0) {
        delete parms.RegionID;
      }
    }
  }

  if (searchOptions.sorting) {
    parms.sorting = searchOptions.sorting.value;
  }

  parms.offset = searchOptions.offset;
  let childrenages = [];
  for (let i = 0; i < searchOptions.children.value * 1; i++) {
    childrenages.push(searchOptions.childrenages[i].value);
  }

  let departureStats = departureStations.filter((e) => e.length < 4);
  let stats = searchOptions.stations.filter((e) => e.value.length < 4);

  parms.adults = searchOptions.adults.value;
  parms.children = searchOptions.children.value;
  parms.childrenages = childrenages.join(",");
  if (!isNaN(searchOptions.duration.value))
    parms.duration = searchOptions.duration.value;
  else {
    parms.minDuration = searchOptions.duration.value.split("-")[0] * 1;
    parms.maxDuration = searchOptions.duration.value.split("-")[1] * 1;
  }
  parms.durationkind = searchOptions.durationkind.value;
  parms.minDate = format(
    new Date(searchOptions.travelDate.from.split('"').join("")),
    "yyyy-MM-dd"
  );

  parms.maxDate = format(
    new Date(searchOptions.travelDate.to.split('"').join("")),
    "yyyy-MM-dd"
  );

  if (parms.duration === "0.1") {
    let differenceInTime =
      new Date(searchOptions.travelDate.to.split('"').join("")).getTime() -
      new Date(searchOptions.travelDate.from.split('"').join("")).getTime();

    parms.duration = Math.floor(differenceInTime / (1000 * 3600 * 24)) + "";
    if (parms.durationkind === "Stay")
      parms.duration = Math.floor(differenceInTime / (1000 * 3600 * 24)) + "";
  }

  if (searchOptions.price < PRICELIMIT) {
    parms.price = searchOptions.price;
  }

  if (!parms.price) {
    parms.price = 10000;
  }

  if (searchOptions.amount > 0) {
    parms.amount = searchOptions.amount;
  }

  let searchBoardTypes = [];
  if (searchOptions.boardType.length > 0) {
    for (let i = 0; i < searchOptions.boardType.length; i++) {
      for (let j = 0; j < boardTypes.length; j++) {
        if (searchOptions.boardType[i] === boardTypes[j].value) {
          searchBoardTypes = [...searchBoardTypes, ...boardTypes[j].searchKeys];
        }
      }
    }
  }

  if (searchOptions.outboundtime !== "0-23") {
    parms.outboundtime =
      (searchOptions.outboundtime.split("-")[0] * 1 < 10
        ? "0" + searchOptions.outboundtime.split("-")[0]
        : searchOptions.outboundtime.split("-")[0]) +
      ":00-" +
      (searchOptions.outboundtime.split("-")[1] * 1 < 10
        ? "0" + searchOptions.outboundtime.split("-")[1]
        : searchOptions.outboundtime.split("-")[1]) +
      ":59";
  }
  if (searchOptions.inboundtime !== "0-23") {
    parms.inboundtime =
      (searchOptions.inboundtime.split("-")[0] * 1 < 10
        ? "0" + searchOptions.inboundtime.split("-")[0]
        : searchOptions.inboundtime.split("-")[0]) +
      ":00-" +
      (searchOptions.inboundtime.split("-")[1] * 1 < 10
        ? "0" + searchOptions.inboundtime.split("-")[1]
        : searchOptions.inboundtime.split("-")[1]) +
      ":59";
  }
  parms.promosession = searchOptions.promosession;
  parms.category = searchOptions.category;
  if (searchOptions.directflight)
    parms.directflight = searchOptions.directflight;
  if (searchOptions.freecancellation)
    parms.freecancellation = searchOptions.freecancellation;
  if (searchOptions.freerebook) parms.freerebook = searchOptions.freerebook;
  if (searchOptions.addablecancellation)
    parms.addablecancellation = searchOptions.addablecancellation;
  if (searchOptions.transferincluded)
    parms.transferincluded = searchOptions.transferincluded;
  if (searchOptions.railandfly) parms.railandfly = searchOptions.railandfly;
  if (searchOptions.rentalcar) parms.rentalcar = searchOptions.rentalcar;
  if (searchOptions.ratingcount) parms.ratingcount = searchOptions.ratingcount;
  if (searchOptions.recommendationrate)
    parms.recommendationrate = searchOptions.recommendationrate;

  if (searchOptions.roomType.length > 0)
    parms.roomType = JSON.stringify(searchOptions.roomType);
  if (searchOptions.hotelAttribute.length > 0)
    parms.hotelAttribute = JSON.stringify(searchOptions.hotelAttribute);
  if (searchBoardTypes.length > 0)
    parms.boardType = JSON.stringify(searchBoardTypes);
  if (searchOptions.roomView.length > 0)
    parms.roomView = JSON.stringify(searchOptions.roomView);
  if (searchOptions.tourOperators.length > 0)
    parms.touroperators = searchOptions.tourOperators.join(",");
  parms.stations =
    searchOptions.stations.length > 0
      ? stats.map((e) => e.value).join(",")
      : departureStats.map((e) => e.value).join(",");
  return parms;
};

/*export const populateRequest = (
  selectedDestination,
  searchOptions,
  departureStations,
  northeast,
  southwest
) => {
  let parms = "";
 
  if (southwest) {
    parms += "lllat=" + southwest.lat;
    parms += "&lllng=" + southwest.lng;
    parms += "&urlat=" + northeast.lat;
    parms += "&urlng=" + northeast.lng;
  } else {
    if (
      selectedDestination.HotelCodes &&
      selectedDestination.HotelCodes.HotelGiataID
    ) {
      parms += "HotelGiataID=" + selectedDestination.HotelCodes.HotelGiataID;
    } else {
      if (selectedDestination.CityID) {
        parms += "CityID=" + selectedDestination.CityID;
      } else {
        parms += "RegionID=" + selectedDestination.RegionID;
      }
    }
  }
 
  let childrenages = [];
  for (let i = 0; i < searchOptions.children.value * 1; i++) {
    childrenages.push(searchOptions.childrenages[i].value);
  }
 
  let departureStats = departureStations.filter((e) => e.length < 4);
  let stats = searchOptions.stations.filter((e) => e.length < 4);
  return (
    parms +
    "&adults=" +
    searchOptions.adults.value +
    "&children=" +
    searchOptions.children.value +
    "&childrenages=" +
    childrenages.join(",") +
    "&duration=" +
    searchOptions.duration.value +
    "&minDate=" +
    format(
      new Date(searchOptions.travelDate.from.split('"').join("")),
      "yyyy-MM-dd"
    ) +
    "&maxDate=" +
    format(
      new Date(searchOptions.travelDate.to.split('"').join("")),
      "yyyy-MM-dd"
    ) +
    "&category=" +
    searchOptions.category +
    (searchOptions.outboundtime !== "0-23"
      ? "&outboundtime=" +
        (searchOptions.outboundtime.split("-")[0] * 1 < 10
          ? "0" + searchOptions.outboundtime.split("-")[0]
          : searchOptions.outboundtime.split("-")[0]) +
        ":00-" +
        (searchOptions.outboundtime.split("-")[1] * 1 < 10
          ? "0" + searchOptions.outboundtime.split("-")[1]
          : searchOptions.outboundtime.split("-")[1] + ":59")
      : "") +
    (searchOptions.inboundtime !== "0-23"
      ? "&inboundtime=" +
        (searchOptions.inboundtime.split("-")[0] * 1 < 10
          ? "0" + searchOptions.inboundtime.split("-")[0]
          : searchOptions.inboundtime.split("-")[0]) +
        ":00-" +
        (searchOptions.inboundtime.split("-")[1] * 1 < 10
          ? "0" + searchOptions.inboundtime.split("-")[1]
          : searchOptions.inboundtime.split("-")[1] + ":59")
      : "") +
    (searchOptions.roomType.length > 0
      ? "&roomType=" +
        encodeURIComponent(JSON.stringify(searchOptions.roomType))
      : "") +
    (searchOptions.boardType.length > 0
      ? "&boardType=" +
        encodeURIComponent(JSON.stringify(searchOptions.boardType))
      : "") +
    (searchOptions.roomView.length > 0
      ? "&roomView=" +
        encodeURIComponent(JSON.stringify(searchOptions.roomView))
      : "") +
    (searchOptions.directflight
      ? "&directflight=" + searchOptions.directflight
      : "") +
    (searchOptions.freecancellation
      ? "&freecancellation=" + searchOptions.freecancellation
      : "") +
    (searchOptions.transferincluded
      ? "&transferincluded=" + searchOptions.transferincluded
      : "") +
    (searchOptions.railandfly
      ? "&railandfly=" + searchOptions.railandfly
      : "") +
    (searchOptions.ratingcount
      ? "&ratingcount=" + searchOptions.ratingcount
      : "") +
    //(searchOptions.sorting ? "&sorting=" + searchOptions.sorting.value : "") +
    (searchOptions.tourOperators.length > 0
      ? "&touroperators=" + searchOptions.tourOperators.join(",")
      : "") +
    "&stations=" +
    (searchOptions.stations.length > 0
      ? stats.map((e) => e.value).join(",")
      : departureStats.map((e) => e.value).join(","))
  );
};*/

export const offerSortOptions = [
  {
    value: 0,
    order: "asc",
    property: "oursorting",
    label: "Unsere Empfehlungen",
  },
  { value: 1, order: "asc", property: "price", label: "Preis aufsteigend" },
  { value: 2, order: "desc", property: "price", label: "Preis absteigend" },
  {
    value: 3,
    order: "asc",
    property: "recommendation",
    label: "Weiterempfehlung aufsteigend",
  },
  {
    value: 4,
    order: "desc",
    property: "recommendation",
    label: "Weiterempfehlung absteigend",
  },
];

export const hotelSortOptions = [
  { value: "PriceAsc", label: "Preis aufsteigend" },
  { value: "DepartureDateAsc", label: "Abreisedatum aufsteigend" },
  { value: "DepartureDateDesc", label: "Abreisedatum absteigend" },
  { value: "DepartureStationAsc", label: "Flughafen aufsteigend" },
  { value: "DepartureStationDesc", label: "Flughafen absteigend" },
  { value: "StayDurationAsc", label: "Aufenhaltsdauer aufsteigend" },
  { value: "StayDurationDesc", label: "Aufenhaltsdauer absteigend" },
  { value: "TripDurationAsc", label: "Reisedauer aufsteigend" },
  { value: "TripDurationDesc", label: "Reisedauer absteigend" },
];

// Gratis Wifi /Kühlschrank

export const roomAttributes = {
  airCondition: "Klimaanlage",
  fridge: "Kühlschrank",
  poolView: "Poolblick",
  oceanView: "Meerblick",
  privatePool: "Privater Pool",
  privateWhirlpool: "Privater Whirlpool",
  satelliteTV: "SAT-TV",
  separateBedroom: "separates Schlafzimmer",
  swimUpPool: "Swim Up Pool",
  balconyOrTerrace: "Balkon/Terrasse",
  atLeastTwoSeparateBedrooms: "mind. 2 separate Schlafzimmer",
};

export const airportregions = () => {
  return [
    {
      value: "NGER",
      label: "Norddeutschland",
      airports: [
        "BER",
        "BRE",
        "FMO",
        "GWT",
        "HAJ",
        "HAM",
        "KSF",
        "LBC",
        "LEJ",
        "PAD",
        "RLG",
      ],
    },
    {
      value: "WGER",
      label: "Westdeutschland",
      airports: [
        "CGN",
        "DTM",
        "DUS",
        "FMO",
        "FRA",
        "HHN",
        "KSF",
        "NRN",
        "PAD",
        "SCN",
      ],
    },
    {
      value: "OGER",
      label: "Ostdeutschland",
      airports: ["BER", "DRS", "ERF", "LEJ", "RLG"],
    },
    {
      value: "SGER",
      label: "Süddeutschland",
      airports: ["FDH", "FKB", "FMM", "FRA", "MUC", "NUE", "STR"],
    },
    {
      value: "GER",
      label: "Deutschland",
      airports: [
        "BER",
        "BRE",
        "FMO",
        "GWT",
        "HAJ",
        "HAM",
        "KSF",
        "LBC",
        "LEJ",
        "PAD",
        "RLG",
        "CGN",
        "DTM",
        "DUS",
        "FRA",
        "HHN",

        "NRN",

        "SCN",
        "DRS",
        "ERF",
        "FDH",
        "FKB",
        "FMM",
        "MUC",
        "NUE",
        "STR",
      ],
    },
    {
      value: "EUROPA",
      label: "Europa",
      airports: [
        "ACH",
        "AMS",
        "BLL",
        "BRN",
        "BRQ",
        "BRU",
        "BSL",
        "BTS",
        "CPH",
        "CRL",
        "EIN",
        "GDN",
        "GRQ",
        "GRZ",
        "GVA",
        "INN",
        "KLU",
        "KRK",
        "KSC",
        "KTW",
        "LCJ",
        "LNZ",
        "LUX",
        "LYS",
        "LZS",
        "MLH",
        "MST",
        "OSR",
        "POZ",
        "PRG",
        "QDL",
        "RTM",
        "SXB",
        "SZG",
        "SZZ",
        "VIE",
        "WAW",
        "WMI",
        "WRO",
        "ZRH",
      ],
    },
  ];
};

export const propertyFromArray = (
  array,
  value,
  valueProperty,
  outputProperty
) => {
  if (!Array.isArray(array)) return "";
  let opt = array.find((e) => e[valueProperty] === value);
  if (opt) {
    return opt[outputProperty] ? opt[outputProperty] : "";
  }
  return "";
};

export const getFirstDayOfNextMonth = () => {
  const date = new Date();

  return new Date(date.getFullYear(), date.getMonth() + 1, 1);
};

export const getFirstDayOf2Month = () => {
  const date = new Date();

  return new Date(date.getFullYear(), date.getMonth() + 2, 1);
};

export const stars = (amount) => {
  if (!amount) return <></>;
  if (amount < 1) return <></>;
  amount = Math.floor(amount);
  let tmp = [...Array(amount).keys()];
  return (
    <span className="stars">
      {tmp.map((e, idx) => {
        return <MdStar key={idx} />;
      })}
    </span>
  );
};

export const airportcity = (iata) => {
  if (iata === "ACH") return iata + " (St. Gallen)";
  if (iata === "AMS") return iata + " (Amsterdam)";
  if (iata === "BER") return iata + " (Berlin-Brandenburg)";
  if (iata === "BRE") return iata + " (Bremen)";
  if (iata === "BLL") return iata + " (Billund)";
  if (iata === "BRN") return iata + " (Bern)";

  if (iata === "BRQ") return iata + " (Brünn)";
  if (iata === "BRU") return iata + " (Brüssel-Zaventem)";
  if (iata === "BSL") return iata + " (Basel)";
  if (iata === "BTS") return iata + " (Bratislava)";

  if (iata === "CGN") return iata + " (Köln-Bonn)";
  if (iata === "CPH") return iata + " (Kopenhagen)";
  if (iata === "CRL") return iata + " (Brüssell-Charleroi)";
  if (iata === "DRS") return iata + " (Dresden)";
  if (iata === "DTM") return iata + " (Dortmund)";
  if (iata === "DUS") return iata + " (Düsseldorf)";
  if (iata === "EIN") return iata + " (Eindhoven)";
  if (iata === "ERF") return iata + " (Erfurt-Weimar)";
  if (iata === "FDH") return iata + " (Bodensee-Airport Friedrichshafen)";
  if (iata === "FKB") return iata + " (Karlsruhe/Baden-Baden)";
  if (iata === "FMM") return iata + " (Memmingen)";
  if (iata === "FMO") return iata + " (Münster/Osnabrück)";
  if (iata === "FRA") return iata + " (Frankfurt Am Main)";
  if (iata === "GDN") return iata + " (Danzig)";

  if (iata === "GRQ") return iata + " (Groningen)";
  if (iata === "GRZ") return iata + " (Graz)";
  if (iata === "GVA") return iata + " (Genf)";
  if (iata === "GWT") return iata + " (Sylt)";
  if (iata === "HAJ") return iata + " (Hannover)";
  if (iata === "HAM") return iata + " (Hamburg)";
  if (iata === "HHN") return iata + " (Frankfurt-Hahn)";
  if (iata === "INN") return iata + " (Innsbruck)";
  if (iata === "KLU") return iata + " (Klagenfurt)";
  if (iata === "KRK") return iata + " (Krakay)";
  if (iata === "KSC") return iata + " (Košice)";

  if (iata === "KSF") return iata + " (Kassel)";
  if (iata === "KTW") return iata + " (Katowice)";

  if (iata === "LBC") return iata + " (Lübeck)";
  if (iata === "LCJ") return iata + " (Łódź)";

  if (iata === "LEJ") return iata + " (Leipzig/Halle)";
  if (iata === "LNZ") return iata + " (Linz)";

  if (iata === "LUX") return iata + " (Luxemburg)";
  if (iata === "LYS") return iata + " (Lyon)";
  if (iata === "LZS") return "HBF Linz";
  if (iata === "MLH") return iata + " (Mulhouse)";
  if (iata === "MST") return iata + " (Maastricht-Aachen)";
  if (iata === "MUC") return iata + " (München)";
  if (iata === "NRN") return iata + " (Niederrhein)";
  if (iata === "NUE") return iata + " (Nürnberg)";
  if (iata === "OSR") return iata + " (Ostrava)";
  if (iata === "PAD") return iata + " (Paderborn-Lippstadt)";
  if (iata === "POZ") return iata + " (Posen)";
  if (iata === "PRG") return iata + " (Prag)";

  if (iata === "QDL") return iata + " (Lugano)";
  if (iata === "QDU") return "HBF Düsseldorf ";
  if (iata === "QKL") return "HBF Köln";
  if (iata === "QKU") return "BF Köln-Deutz";
  if (iata === "QPP") return "HBF Berlin";
  if (iata === "RLG") return iata + " (Rostock-Laage)";
  if (iata === "RTM") return iata + " (Rotterdam)";
  if (iata === "SCN") return iata + " (Saarbrücken)";
  if (iata === "STR") return iata + " (Stuttgart)";
  if (iata === "SXB") return iata + " (Straßburg)";
  if (iata === "SXF") return iata + " (Berlin Schönefeld)";
  if (iata === "SZG") return iata + " (Salzburg)";
  if (iata === "SZZ") return iata + " (Stettin)";
  if (iata === "TXL") return iata + " (Berlin-Tegel)";
  if (iata === "VIE") return iata + " (Wien-Schwechat)";
  if (iata === "WAW") return iata + " (Warschau Chopin)";
  if (iata === "WMI") return iata + " (Warschau-Modlin)";
  if (iata === "WRO") return iata + " (Breslau)";

  if (iata === "XIR") return "HBF Dresden";
  if (iata === "XIT") return "HBF Leipzig";
  if (iata === "ZAQ") return "HBF Nürnberg";
  if (iata === "ZDH") return "HBF Basel";
  if (iata === "ZHT") return "HBF Genf";
  if (iata === "ZMB") return "HBF Hamburg";
  if (iata === "ZMU") return "HBF München";
  if (iata === "ZPE") return "HBF Osnabrück";
  if (iata === "ZRH") return iata + " (Zürich)";
  if (iata === "ZSB") return "HBF Salzburg";
  if (iata === "ZVR") return "HBF Hannover";
  if (iata === "ZWS") return "HBF Stuttgart";
  if (iata === "PED") return iata + " (Pardubice)";
  if (iata === "RZE") return iata + " (Rzeszów-Jasionka)";
  if (iata === "ANR") return iata + " (Antwerpen)";
};

export const cancellationOptions = [
  { value: "Free", label: "kostenlose Stornierung" },
  { value: "Addable", label: "Stornierung zubuchbar" },
  { value: "Paid", label: "bezahlte Stornierung" },
];

export const boardTypes = [
  {
    value: "NoBoard",
    label: "Selbstverpflegung",
    short: "OV",
    order: 1,
    filter: true,

    searchKeys: [],
  },
  {
    value: "AccordingDescription",
    label: "laut Beschreibung",
    short: "LB",
    order: 2,
    filter: true,
    searchKeys: ["AccordingDescription"],
  },
  {
    value: "SelfCatering",
    label: "Selbstverpflegung",
    short: "OV",
    order: 3,
    filter: true,
    searchKeys: ["SelfCatering", "NoBoard"],
  },
  {
    value: "BreakfastEconomy",
    label: "Frühstück",
    short: "ÜFE",
    order: 4,
    filter: false,
    searchKeys: [],
  },
  {
    value: "Breakfast",
    label: "Frühstück",
    short: "ÜF",
    order: 4,
    filter: true,
    searchKeys: ["BreakfastEconomy", "Breakfast"],
  },
  {
    value: "BreakfastSuperior",
    label: "Frühstücksbuffet",
    short: "ÜF+",
    order: 5,
    filter: true,
    searchKeys: ["BreakfastSuperior"],
  },
  {
    value: "HalfBoardEconomy",
    label: "Halbpension",
    short: "HPE",
    order: 6,
    filter: false,
    searchKeys: [],
  },
  {
    value: "HalfBoard",
    label: "Halbpension",
    short: "HP",
    order: 6,
    filter: true,
    searchKeys: ["HalfBoardEconomy", "HalfBoard"],
  },
  {
    value: "HalfBoardSuperior",
    label: "Halbpension Plus",
    short: "HP+",
    order: 7,
    filter: true,
    searchKeys: ["HalfBoardSuperior"],
  },
  {
    value: "FullBoardEconomy",
    label: "Vollpension",
    short: "VPE",
    order: 8,
    filter: false,
    searchKeys: [],
  },
  {
    value: "FullBoard",
    label: "Vollpension",
    short: "VP",
    order: 8,
    filter: true,
    searchKeys: ["FullBoardEconomy", "FullBoard"],
  },
  {
    value: "FullBoardSuperior",
    label: "Vollpension Plus",
    short: "VP+",
    order: 9,
    filter: true,
    searchKeys: ["FullBoardSuperior"],
  },
  {
    value: "AllInclusiveEconomy",
    label: "All Inclusive",
    short: "AIE",
    order: 10,
    filter: false,
    searchKeys: [],
  },
  {
    value: "AllInclusive",
    label: "All Inclusive",
    short: "AI",
    order: 10,
    filter: true,
    searchKeys: ["AllInclusiveEconomy", "AllInclusive"],
  },
  {
    value: "AllInclusiveSuperior",
    label: "Ultra All Inclusive",
    short: "UAI",
    order: 11,
    filter: true,
    searchKeys: ["AllInclusiveSuperior"],
  },
];

export const roomTypes = [
  { value: "AccordingDescription", label: "nach Beschreibung", order: 1 },
  { value: "Caravan", label: "Caravan", order: 2 },
  { value: "SingleRoom", label: "Einzelzimmer", order: 3 },
  { value: "DoubleRoom", label: "Doppelzimmer", order: 4 },
  { value: "TwinRoom", label: "TwinRoom", order: 5 },
  { value: "ThreeBedRoom", label: "Dreibettzimmer", order: 6 },
  { value: "FamilyRoom", label: "FamilyRoom", order: 7 },
  { value: "FourBedRoom", label: "Vierbettzimmer", order: 8 },
  { value: "MultiBedRoom", label: "Mehrbettzimmer", order: 9 },
  { value: "DuplexRoom", label: "DuplexRoom", order: 10 },
  { value: "Suite", label: "Suite", order: 11 },
  { value: "Studio", label: "Studio", order: 12 },
  { value: "Apartment", label: "Appartment", order: 13 },
  { value: "Bungalow", label: "Bungalow", order: 14 },
  { value: "Villa", label: "Villa", order: 15 },
  { value: "SuperiorRoom", label: "SuperiorRoom", order: 16 },
  { value: "HolidayFlat", label: "Ferienwohnung", order: 17 },
  { value: "SummerCottage", label: "SummerCottage", order: 18 },
  { value: "DeluxeRoom", label: "DeluxeRoom", order: 19 },
];

export const roomViews = [
  {
    value: "PartialOcean",
    label: "Seitlicher Meerblick",
    filter: true,
    order: 1,
  },
  {
    value: "FullOcean",
    label: "Meerblick",
    filter: true,
    order: 3,
  },
  {
    value: "PartialOrFullOcean",
    label: "Seitlicher  Meerblick oder Meerblick",
    filter: true,
    order: 2,
  },
  {
    value: "NothingSpecial",
    label: "keine Auswahl",
    filter: false,
    order: 0,
  },
];

export const giataOperators = [
  {
    vc: "1AV",
    vi: "710",
    vn: "1AVista Reisen GmbH",
    logo: [
      {
        size: "20",
        width: "41",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=1av&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=1av&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=1av&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=1av&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "247T",
    vi: "1142",
    vn: "247 Travel Collection",
    logo: [
      {
        size: "20",
        width: "33",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=247t&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=247t&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=247t&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=247t&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "5VF",
    vi: "443",
    vn: "5 vor Flug",
    logo: [
      {
        size: "20",
        width: "28",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=5vf&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=5vf&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=5vf&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=5vf&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "5VFCH",
    vi: "997",
    vn: "5 vor Flug Schweiz",
    logo: [
      {
        size: "20",
        width: "27",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=5vfch&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=5vfch&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=5vfch&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=5vfch&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "AARE",
    vi: "1028",
    vn: "Aaretal Reisen",
    logo: [
      {
        size: "20",
        width: "64",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=aare&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=aare&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=aare&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=aare&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "ABLU",
    vi: "1124",
    vn: "Adrialin Blue GmbH",
    logo: [
      {
        size: "20",
        width: "72",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ablu&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ablu&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ablu&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ablu&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "ADRI",
    vi: "1077",
    vn: "Adrialin GmbH",
    logo: [
      {
        size: "20",
        width: "65",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=adri&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=adri&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=adri&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=adri&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "ATID",
    vi: "202",
    vn: "airtours",
    logo: [
      {
        size: "20",
        width: "29",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ati&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ati&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ati&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ati&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "ATIS",
    vi: "1013",
    vn: "airtours Suisse",
    logo: [
      {
        size: "20",
        width: "29",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ati&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ati&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ati&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ati&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "AWT",
    vi: "494",
    vn: "Airways Travel GmbH",
    logo: [
      {
        size: "20",
        width: "58",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=awt&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=awt&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=awt&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=awt&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "ALD",
    vi: "230",
    vn: "Aldiana",
    logo: [
      {
        size: "20",
        width: "20",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ald&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ald&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ald&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ald&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "ALDX",
    vi: "1153",
    vn: "Aldiana ALDX",
    logo: [
      {
        size: "20",
        width: "52",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=aldx&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=aldx&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=aldx&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=aldx&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "ALL",
    vi: "248",
    vn: "Alltours",
    logo: [
      {
        size: "20",
        width: "71",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=all&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=all&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=all&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=all&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "XALL",
    vi: "856",
    vn: "Alltours XALL",
    logo: [
      {
        size: "20",
        width: "75",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xall&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xall&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xall&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xall&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "AME",
    vi: "271",
    vn: "Ameropa-Reisen",
    logo: [
      {
        size: "20",
        width: "81",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ame&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ame&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ame&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ame&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "ANEX",
    vi: "1082",
    vn: "Anex Tour",
    logo: [
      {
        size: "20",
        width: "45",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=anex&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=anex&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=anex&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=anex&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "XANE",
    vi: "1109",
    vn: "Anex Tour XANE",
    logo: [
      {
        size: "20",
        width: "45",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xane&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xane&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xane&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xane&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "ATK",
    vi: "169",
    vn: "Attika",
    logo: [
      {
        size: "20",
        width: "91",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=atk&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=atk&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=atk&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=atk&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "BAV",
    vi: "447",
    vn: "Bavaria Fernreisen GmbH",
    logo: [
      {
        size: "20",
        width: "44",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bav&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bav&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bav&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bav&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "BCH",
    vi: "557",
    vn: "Bentour Reisen",
    logo: [
      {
        size: "20",
        width: "56",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bch&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bch&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bch&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bch&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "BENX",
    vi: "1087",
    vn: "BENX",
    logo: [
      {
        size: "20",
        width: "54",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=benx&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=benx&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=benx&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=benx&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "BM",
    vi: "680",
    vn: "Berge und Meer",
    logo: [
      {
        size: "20",
        width: "89",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bm&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bm&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bm&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bm&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "BIG",
    vi: "364",
    vn: "Big-Xtra Touristik GmbH",
    logo: [
      {
        size: "20",
        width: "72",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=big&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=big&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=big&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=big&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "BXCH",
    vi: "945",
    vn: "Big-Xtra Touristik GmbH (Schweiz)",
    logo: [
      {
        size: "20",
        width: "72",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bxch&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bxch&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bxch&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bxch&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "ITSB",
    vi: "322",
    vn: "Billa Reisen",
    logo: [
      {
        size: "20",
        width: "33",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=itsb&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=itsb&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=itsb&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=itsb&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "BOT",
    vi: "1133",
    vn: "BOTROS TOURS GMBH",
    logo: [
      {
        size: "20",
        width: "46",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bot&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bot&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bot&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bot&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "BU",
    vi: "190",
    vn: "BUCHER REISEN",
    logo: [
      {
        size: "20",
        width: "39",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bu&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bu&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bu&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bu&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "BYE",
    vi: "753",
    vn: "byebye",
    logo: [
      {
        size: "20",
        width: "64",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bye&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bye&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bye&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=bye&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "CHRI",
    vi: "313",
    vn: "Christophorus Reiseveranstaltungs GmbH",
    logo: [
      {
        size: "20",
        width: "91",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=chri&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=chri&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=chri&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=chri&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "CLEV",
    vi: "664",
    vn: "clevertours.com",
    logo: [
      {
        size: "20",
        width: "153",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=clev&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=clev&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=clev&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=clev&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "COR",
    vi: "1119",
    vn: "Coral Travel GmbH",
    logo: [
      {
        size: "20",
        width: "45",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=cor&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=cor&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=cor&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=cor&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "DAN",
    vi: "777",
    vn: "DanCenter A/S",
    logo: [
      {
        size: "20",
        width: "72",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=dan&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=dan&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=dan&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=dan&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "JI",
    vi: "1052",
    vn: "De Jong Intra Vakanties",
    logo: [
      {
        size: "20",
        width: "37",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=di&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "40",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=di&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=di&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=di&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "DER",
    vi: "207",
    vn: "DERTOUR",
    logo: [
      {
        size: "20",
        width: "74",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=der&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=der&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=der&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=der&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "DKT",
    vi: "1092",
    vn: "DK TOURISTIK Reiseconsulting Ges.m.b.H.",
    logo: [
      {
        size: "20",
        width: "45",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=dkt&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=dkt&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=dkt&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=dkt&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "DTA",
    vi: "992",
    vn: "dta Touristik GmbH",
    logo: [
      {
        size: "20",
        width: "31",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=dta&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=dta&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=dta&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=dta&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "ECC",
    vi: "573",
    vn: "ECCO Reisen GmbH",
    logo: [
      {
        size: "20",
        width: "51",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ecc&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ecc&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ecc&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ecc&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "ERNAM",
    vi: "1148",
    vn: "ERNA MEYER REISEN",
    logo: [
      {
        size: "20",
        width: "67",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=emr&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=emr&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=emr&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=emr&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "XEUR",
    vi: "985",
    vn: "Eurotours",
    logo: [
      {
        size: "20",
        width: "28",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xeur&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xeur&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xeur&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xeur&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "EWH",
    vi: "1117",
    vn: "Eurowings Holidays",
    logo: [
      {
        size: "20",
        width: "124",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ewh&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ewh&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ewh&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ewh&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "ETCH",
    vi: "1112",
    vn: "EXIM Tours Car Holiday",
    logo: [
      {
        size: "20",
        width: "68",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=etch&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=etch&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=etch&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=etch&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "ETDY",
    vi: "1110",
    vn: "EXIM Tours Dynamic",
    logo: [
      {
        size: "20",
        width: "68",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=etdy&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=etdy&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=etdy&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=etdy&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "ETI",
    vi: "357",
    vn: "Express Travel International GmbH",
    logo: [
      {
        size: "20",
        width: "32",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=eti&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=eti&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=eti&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=eti&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "ETIS",
    vi: "919",
    vn: "Express Travel International Schweiz",
    logo: [
      {
        size: "20",
        width: "32",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=eti&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=eti&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=eti&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=eti&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "FNTR",
    vi: "1031",
    vn: "Falk Tours GmbH",
    logo: [
      {
        size: "20",
        width: "75",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fntr&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fntr&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fntr&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fntr&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "FATV",
    vi: "1121",
    vn: "Falk Tours GmbH",
    logo: [
      {
        size: "20",
        width: "75",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fatv&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fatv&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fatv&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fatv&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "FTV",
    vi: "1149",
    vn: "Falktravel",
    logo: [
      {
        size: "20",
        width: "103",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ftv&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ftv&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ftv&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ftv&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "FER",
    vi: "395",
    vn: "FERIEN Touristik GmbH",
    logo: [
      {
        size: "20",
        width: "49",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fer&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fer&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fer&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fer&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "FUV",
    vi: "1104",
    vn: "Fit & Vital Reisen GmbH",
    logo: [
      {
        size: "20",
        width: "31",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fuv&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fuv&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fuv&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fuv&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "FIT",
    vi: "298",
    vn: "FIT Gesellschaft für gesundes Reisen mbH",
    logo: [
      {
        size: "20",
        width: "63",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fit&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fit&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fit&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fit&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "FLT",
    vi: "910",
    vn: "Flamingo Tours",
    logo: [
      {
        size: "20",
        width: "51",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=flt&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=flt&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=flt&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=flt&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "FOR",
    vi: "981",
    vn: "For You Travel",
    logo: [
      {
        size: "20",
        width: "31",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=for&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=for&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=for&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=for&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "FRAN",
    vi: "821",
    vn: "Frantour",
    logo: [
      {
        size: "20",
        width: "199",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fran&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fran&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fran&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fran&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "FTI",
    vi: "156",
    vn: "FTI",
    logo: [
      {
        size: "20",
        width: "27",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fti&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fti&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fti&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fti&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "FTI",
    vi: "1138",
    vn: "FTI Österreich",
    logo: [
      {
        size: "20",
        width: "27",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fti&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fti&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fti&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fti&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "FTIS",
    vi: "525",
    vn: "FTI Schweiz",
    logo: [
      {
        size: "20",
        width: "27",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ftis&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ftis&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ftis&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ftis&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "FTIV",
    vi: "921",
    vn: "FTI Voyages S.A.",
    logo: [
      {
        size: "20",
        width: "27",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ftiv&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ftiv&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ftiv&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ftiv&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "GIATA",
    vi: "157",
    vn: "GIATA",
    logo: [
      {
        size: "20",
        width: "73",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=giata&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=giata&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=giata&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=giata&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "GIAD",
    vi: "1144",
    vn: "GIATA-Drive",
    logo: [],
  },
  {
    vc: "GER",
    vi: "1113",
    vn: "Globalis Erlebnisreisen GmbH",
    logo: [
      {
        size: "20",
        width: "113",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ger&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ger&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ger&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ger&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "GRUB",
    vi: "315",
    vn: "Gruber Reisen Veranstalter GmbH",
    logo: [
      {
        size: "20",
        width: "145",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=grub&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=grub&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=grub&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=grub&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "HEL",
    vi: "624",
    vn: "Helvetic Tours",
    logo: [
      {
        size: "20",
        width: "26",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=hel&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=hel&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=hel&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=hel&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "XHEL",
    vi: "1071",
    vn: "Helvetic Tours Dynamic",
    logo: [
      {
        size: "20",
        width: "54",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xhel&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xhel&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xhel&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xhel&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "HLX",
    vi: "1057",
    vn: "HLX Touristik",
    logo: [
      {
        size: "20",
        width: "66",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=hlx&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=hlx&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=hlx&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=hlx&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "HOCL",
    vi: "1059",
    vn: "HolidayCloud",
    logo: [
      {
        size: "20",
        width: "57",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=hocl&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=hocl&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=hocl&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=hocl&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "HP",
    vi: "579",
    vn: "Hotelplan",
    logo: [
      {
        size: "20",
        width: "58",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=hp&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=hp&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=hp&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=hp&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "HTH",
    vi: "914",
    vn: "HTH Hanse Touristik Hamburg GmbH",
    logo: [
      {
        size: "20",
        width: "25",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=hth&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=hth&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=hth&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=hth&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "RIVA",
    vi: "524",
    vn: "I.D. Riva Tours",
    logo: [
      {
        size: "20",
        width: "48",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=riva&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=riva&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=riva&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=riva&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "IDEA",
    vi: "1111",
    vn: "IDEALTOURS GmbH",
    logo: [
      {
        size: "20",
        width: "64",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=idea&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=idea&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=idea&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=idea&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "ICO",
    vi: "1152",
    vn: "Inter-Connect",
    logo: [
      {
        size: "20",
        width: "20",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ico&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "47",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ico&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ico&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ico&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "IHOM",
    vi: "452",
    vn: "Interhome",
    logo: [
      {
        size: "20",
        width: "69",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ihom&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ihom&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ihom&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ihom&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "INTI",
    vi: "1143",
    vn: "Invia",
    logo: [
      {
        size: "20",
        width: "37",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=inti&size=20&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=inti&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=inti&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "ITS",
    vi: "200",
    vn: "ITS",
    logo: [
      {
        size: "20",
        width: "22",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=its&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=its&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=its&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=its&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "ITSC",
    vi: "568",
    vn: "ITS Coop Travel",
    logo: [
      {
        size: "20",
        width: "55",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=itsc&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=itsc&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=itsc&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=itsc&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "ITSX",
    vi: "811",
    vn: "ITS Indi",
    logo: [
      {
        size: "20",
        width: "24",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=itsx&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=itsx&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=itsx&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=itsx&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "ITT",
    vi: "431",
    vn: "ITT Ferien PUR",
    logo: [
      {
        size: "20",
        width: "48",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=itt&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=itt&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=itt&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=itt&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "XPUR",
    vi: "998",
    vn: "ITT XPUR",
    logo: [
      {
        size: "20",
        width: "44",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xpur&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xpur&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xpur&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xpur&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "XJAHN",
    vi: "899",
    vn: "Jahn Indi",
    logo: [
      {
        size: "20",
        width: "80",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xjahn&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xjahn&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xjahn&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xjahn&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "JAHN",
    vi: "246",
    vn: "Jahn Reisen",
    logo: [
      {
        size: "20",
        width: "61",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=jahn&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=jahn&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=jahn&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=jahn&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "JUMB",
    vi: "570",
    vn: "JUMBO Touristik",
    logo: [
      {
        size: "20",
        width: "69",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=jumb&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=jumb&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=jumb&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=jumb&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "NJA1",
    vi: "1126",
    vn: "JUST AWAY",
    logo: [],
  },
  {
    vc: "KUON",
    vi: "318",
    vn: "Kuoni Reisen",
    logo: [
      {
        size: "20",
        width: "86",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=kuon&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=kuon&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=kuon&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=kuon&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "TUR3",
    vi: "1146",
    vn: "l'tur Exclusive GmbH (Tui Gruppe)",
    logo: [
      {
        size: "20",
        width: "41",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ltur&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ltur&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ltur&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ltur&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "TUR1",
    vi: "1145",
    vn: "l'tur GmbH (Tui Gruppe)",
    logo: [
      {
        size: "20",
        width: "41",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ltur&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ltur&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ltur&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=ltur&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "LMXI",
    vi: "842",
    vn: "LMX International",
    logo: [
      {
        size: "20",
        width: "46",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=lmxi&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=lmxi&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=lmxi&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=lmxi&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "LMX",
    vi: "416",
    vn: "LMX Touristik",
    logo: [
      {
        size: "20",
        width: "46",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=lmx&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=lmx&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=lmx&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=lmx&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "LHH",
    vi: "1060",
    vn: "Lufthansa Holidays",
    logo: [
      {
        size: "20",
        width: "58",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=lhh&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=lhh&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=lhh&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=lhh&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "LUX",
    vi: "214",
    vn: "Luxair Tours GmbH",
    logo: [
      {
        size: "20",
        width: "81",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=lux&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=lux&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=lux&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=lux&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "MANT",
    vi: "625",
    vn: "Manta Reisen",
    logo: [
      {
        size: "20",
        width: "103",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=mant&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=mant&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=mant&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=mant&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "MED",
    vi: "220",
    vn: "Medina Reisen",
    logo: [
      {
        size: "20",
        width: "51",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=med&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=med&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=med&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=med&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "MWR",
    vi: "244",
    vn: "Meier's Weltreisen",
    logo: [
      {
        size: "20",
        width: "32",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=mwr&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=mwr&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=mwr&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=mwr&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "XMIG",
    vi: "798",
    vn: "Migros Ferien",
    logo: [
      {
        size: "20",
        width: "53",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xmig&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xmig&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xmig&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xmig&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "MIS",
    vi: "586",
    vn: "Misir Sonnenlandreisen",
    logo: [
      {
        size: "20",
        width: "65",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=mis&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=mis&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=mis&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=mis&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "MOA",
    vi: "1159",
    vn: "Moana Reisen",
    logo: [],
  },
  {
    vc: "MON",
    vi: "283",
    vn: "Mondial GmbH & Co. KG",
    logo: [
      {
        size: "20",
        width: "56",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=mon&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=mon&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=mon&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=mon&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "XNEC",
    vi: "1164",
    vn: "Neckermann Reisen XNEC, eine Marke der Anex Tour GmbH",
    logo: [
      {
        size: "20",
        width: "50",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xnec&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xnec&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xnec&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xnec&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "NEC",
    vi: "1163",
    vn: "Neckermann Reisen, eine Marke der Anex Tour GmbH",
    logo: [
      {
        size: "20",
        width: "50",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=nec&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=nec&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=nec&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=nec&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "OGE",
    vi: "873",
    vn: "ÖGER TOURS",
    logo: [
      {
        size: "20",
        width: "33",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=oge&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=oge&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=oge&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=oge&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "XOLI",
    vi: "1130",
    vn: "OLIMAR Outlet",
    logo: [
      {
        size: "20",
        width: "53",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xoli&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xoli&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xoli&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xoli&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "OLI",
    vi: "250",
    vn: "Olimar Reisen",
    logo: [
      {
        size: "20",
        width: "41",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=oli&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=oli&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=oli&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=oli&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "PHX",
    vi: "261",
    vn: "Phoenix Reisen",
    logo: [
      {
        size: "20",
        width: "72",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=phx&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=phx&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=phx&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=phx&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "PRIM",
    vi: "577",
    vn: "PRIMA REISEN GMBH",
    logo: [
      {
        size: "20",
        width: "48",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=prim&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=prim&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=prim&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=prim&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "PTI",
    vi: "304",
    vn: "PTI Panoramica Touristik",
    logo: [
      {
        size: "20",
        width: "75",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=pti&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=pti&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=pti&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=pti&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "RTS",
    vi: "613",
    vn: "railtour suisse sa",
    logo: [
      {
        size: "20",
        width: "56",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=rts&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=rts&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=rts&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=rts&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "RHOM",
    vi: "621",
    vn: "Rhomberg - Die Reisemaßschneiderei",
    logo: [
      {
        size: "20",
        width: "62",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=rho&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=rho&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=rho&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=rho&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "RSD",
    vi: "1158",
    vn: "RSD Reise Service Deutschland GmbH",
    logo: [
      {
        size: "20",
        width: "37",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=rsd&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=rsd&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=rsd&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=rsd&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "SLR",
    vi: "227",
    vn: "Schauinsland Reisen",
    logo: [
      {
        size: "20",
        width: "59",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=slr&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=slr&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=slr&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=slr&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "SELE",
    vi: "901",
    vn: "Select Holidays GmbH",
    logo: [
      {
        size: "20",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=sele&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=sele&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=sele&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=sele&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "SIT",
    vi: "801",
    vn: "SITALIA GmbH",
    logo: [
      {
        size: "20",
        width: "66",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=sit&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=sit&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=sit&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=sit&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "SO",
    vi: "1084",
    vn: "Solaris Travel GmbH",
    logo: [
      {
        size: "20",
        width: "90",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=so&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=so&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=so&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=so&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "SOFY",
    vi: "1107",
    vn: "SolFly",
    logo: [
      {
        size: "20",
        width: "58",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=sofy&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=sofy&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=sofy&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=sofy&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "SPRI",
    vi: "610",
    vn: "SPRINGER REISEN",
    logo: [
      {
        size: "20",
        width: "71",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=spri&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=spri&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=spri&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=spri&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "SUNH",
    vi: "939",
    vn: "SunHotels",
    logo: [
      {
        size: "20",
        width: "55",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=sunh&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=sunh&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=sunh&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=sunh&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "TUT",
    vi: "1162",
    vn: "Tierische Urlaubsträume",
    logo: [
      {
        size: "20",
        width: "20",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tut&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tut&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tut&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tut&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "TIMA",
    vi: "1161",
    vn: "Tierramare GmbH",
    logo: [
      {
        size: "20",
        width: "13",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tima&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tima&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tima&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tima&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "TISC",
    vi: "332",
    vn: "Tischler",
    logo: [
      {
        size: "20",
        width: "20",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tisc&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tisc&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tisc&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tisc&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "TOP",
    vi: "1068",
    vn: "TOP Touristik",
    logo: [
      {
        size: "20",
        width: "21",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=top&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=top&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=top&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=top&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "TOU",
    vi: "1156",
    vn: "Tourenia",
    logo: [
      {
        size: "20",
        width: "85",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tou&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tou&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tou&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tou&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "TPT",
    vi: "932",
    vn: "TPT - Tourisme Pour Tous",
    logo: [
      {
        size: "20",
        width: "63",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tpt&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tpt&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tpt&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tpt&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "TRANS",
    vi: "1096",
    vn: "TRANSAIR - TRANS",
    logo: [
      {
        size: "20",
        width: "66",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=trans&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=trans&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=trans&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=trans&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "TRAL",
    vi: "1141",
    vn: "TraveLeague AG",
    logo: [
      {
        size: "20",
        width: "55",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tral&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tral&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tral&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tral&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "REBAA",
    vi: "1047",
    vn: "Travelhouse",
    logo: [
      {
        size: "20",
        width: "115",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=rebaa&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=rebaa&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=rebaa&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=rebaa&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "XTJS",
    vi: "689",
    vn: "TRAVELIX",
    logo: [
      {
        size: "20",
        width: "35",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xtjs&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xtjs&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xtjs&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xtjs&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "TJAX",
    vi: "938",
    vn: "TRAVELIX TJAX",
    logo: [
      {
        size: "20",
        width: "35",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tjax&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tjax&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tjax&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tjax&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "TREX",
    vi: "698",
    vn: "TravelTrex GmbH - HolidayTrex",
    logo: [
      {
        size: "20",
        width: "133",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=trex&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=trex&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=trex&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=trex&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "SNOW",
    vi: "429",
    vn: "TravelTrex GmbH - SnowTrex Wintersportreisen",
    logo: [
      {
        size: "20",
        width: "79",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=snow&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=snow&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=snow&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=snow&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "TRND",
    vi: "1160",
    vn: "Trendtours Touristik",
    logo: [
      {
        size: "20",
        width: "27",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=trnd&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=trnd&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=trnd&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=trnd&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "TROL",
    vi: "319",
    vn: "Troll Tours Reisen",
    logo: [
      {
        size: "20",
        width: "44",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=trol&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=trol&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=trol&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=trol&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "TUID",
    vi: "199",
    vn: "TUI",
    logo: [
      {
        size: "20",
        width: "48",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tui&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tui&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tui&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tui&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "TUIS",
    vi: "345",
    vn: "TUI Suisse",
    logo: [
      {
        size: "20",
        width: "82",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tuis&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tuis&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tuis&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=tuis&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "UNIV",
    vi: "1136",
    vn: "UNIVERSAL FLUGREISEN AG",
    logo: [
      {
        size: "20",
        width: "55",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=univ&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=univ&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=univ&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=univ&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "XUSA",
    vi: "1131",
    vn: "USA-Reisen Experte",
    logo: [
      {
        size: "20",
        width: "100",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xusa&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xusa&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xusa&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xusa&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "FRE",
    vi: "1086",
    vn: "Voordeeluitjes.nl",
    logo: [
      {
        size: "20",
        width: "172",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fre&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fre&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fre&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fre&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "VTO",
    vi: "450",
    vn: "Vtours GmbH",
    logo: [
      {
        size: "20",
        width: "79",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=vto&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=vto&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=vto&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=vto&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "VTOI",
    vi: "1064",
    vn: "Vtours International",
    logo: [
      {
        size: "20",
        width: "66",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=vtoi&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=vtoi&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=vtoi&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=vtoi&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "VRG",
    vi: "1125",
    vn: "W-Reservation",
    logo: [
      {
        size: "20",
        width: "35",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=vrg&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=vrg&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=vrg&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=vrg&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "WIN",
    vi: "1120",
    vn: "Windrose",
    logo: [
      {
        size: "20",
        width: "78",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=win&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=win&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=win&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=win&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "XBIG",
    vi: "830",
    vn: "XBIG",
    logo: [
      {
        size: "20",
        width: "48",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xbig&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xbig&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xbig&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xbig&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "XETI",
    vi: "927",
    vn: "XExpress Travel International GmbH",
    logo: [
      {
        size: "20",
        width: "57",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xeti&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xeti&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xeti&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xeti&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "XTUI",
    vi: "807",
    vn: "XTUI",
    logo: [
      {
        size: "20",
        width: "64",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xtui&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xtui&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xtui&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xtui&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "XOGE",
    vi: "852",
    vn: "XÖGER",
    logo: [
      {
        size: "20",
        width: "64",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xoge&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xoge&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xoge&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xoge&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "FIB",
    vi: "1168",
    vn: "Fibula Travel GmbH",
    logo: [
      {
        size: "20",
        width: "64",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fib&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fib&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fib&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=fib&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "DEH",
    vi: "1169",
    vn: "Condor Holidays",
    logo: [
      {
        size: "20",
        width: "64",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=deh&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=deh&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=deh&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=deh&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "WFT",
    vi: "1170",
    vn: "We Flytour",
    logo: [
      {
        size: "20",
        width: "64",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=wft&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=wft&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=wft&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=wft&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "COS",
    vi: "1171",
    vn: "Coral Travel AG Schweiz",
    logo: [
      {
        size: "20",
        width: "64",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=cos&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=cos&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=cos&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=cos&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "XCOS",
    vi: "1172",
    vn: "Coral Travel AG Schweiz dynamic",
    logo: [
      {
        size: "20",
        width: "64",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xcos&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xcos&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xcos&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=xcos&size=288&uid=1580&source=xml",
      },
    ],
  },
  {
    vc: "EYH",
    vi: "1174",
    vn: "Etihad Holidays (operated by Capital Holidays Europe)",
    logo: [
      {
        size: "20",
        width: "64",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=eyh&size=20&uid=1580&source=xml",
      },
      {
        size: "40",
        width: "40",
        height: "20",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=eyh&size=40&uid=1580&source=xml",
      },
      {
        size: "140",
        width: "140",
        height: "30",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=eyh&size=140&uid=1580&source=xml",
      },
      {
        size: "288",
        width: "288",
        height: "61",
        url: "https://m.giatamedia.com/getVeaLogo.php?vea=eyh&size=288&uid=1580&source=xml",
      },
    ],
  },
];

export const hotelAttributes = [
  {
    value: "action_adventures_parties_fun",
    label: "Action & Fun",
    order: 401,
    group: "Aktivurlaub",
    icon: (size = 24) => <ActionFun width={size} height={size} />,
  },
  {
    value: "adults_only",
    label: "Adults Only",
    group: "Zielgruppe",
    order: 108,
    mostwanted: true,
    icon: (size = 24) => <Adult width={size} height={size} />,
    info: "adults_only",
  },
  {
    value: "attractive_for_couples",
    label: "Urlaub zu zweit",
    group: "Zielgruppe",
    order: 106,
    icon: (size = 24) => <PaarHerz width={size} height={size} />,
  },
  {
    value: "attractive_for_singles",
    label: "Single",
    group: "Zielgruppe",
    order: 104,
    icon: (size = 24) => <Single width={size} height={size} />,
  },
  {
    value: "attractive_for_singles_w_child",
    label: "Single mit Kind",
    group: "Zielgruppe",
    order: 105,
    icon: (size = 24) => <SinglemitKind width={size} height={size} />,
  },
  {
    value: "ayurveda",
    label: "Ayurveda",
    order: 301,
    group: "Body 'n' soul",
    icon: (size = 24) => <Ayurveda width={size} height={size} />,
  },
  {
    value: "baby_cot",
    label: "Babybett",
    order: 200,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <Babybett width={size} height={size} />,
  },
  {
    value: "baby_equipment",
    label: "Babyausstattung",
    order: 201,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <Babyausstattung width={size} height={size} />,
  },
  {
    value: "babysitting",
    label: "Babysitter",
    order: 202,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <Babysitting width={size} height={size} />,
  },
  {
    value: "beauty_farm",
    label: "Wellnessanwendungen",
    order: 301,
    group: "Body 'n' soul",
    icon: (size = 24) => <Wellness width={size} height={size} />,
  },
  {
    value: "bike_mountainbike_rental",
    label: "Rad/Mountainbike",
    order: 500,

    group: "Sport",
    icon: (size = 24) => <Rad width={size} height={size} />,
  },
  {
    value: "buffet_for_children",
    label: "Kinderbuffet",
    order: 203,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <Kinderbuffet width={size} height={size} />,
  },
  {
    value: "buggy_rent",
    label: "Buggy Service",
    order: 204,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <Buggy width={size} height={size} />,
  },
  {
    value: "central_location",
    label: "Zentrale Lage",
    order: 205,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <Stadt width={size} height={size} />,
  },
  {
    value: "city_breaks",
    label: "Stadthotel",
    order: 206,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <Stadthotel width={size} height={size} />,
  },
  {
    value: "club_with_entertainment",
    label: "Club",
    order: 207,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <Disco width={size} height={size} />,
  },
  {
    value: "cosmetic_treatments",
    label: "Wellnessanwendungen",
    order: 303,
    group: "Body 'n' soul",
    icon: (size = 24) => <Wellness2 width={size} height={size} />,
  },
  {
    value: "direct_beach_access",
    label: "Direkte Strandlage",
    icon: (size = 24) => <Muschel width={size} height={size} />,
    order: 208,
    mostwanted: true,
    group: "Unterkunft / Umgebung",
  },
  {
    value: "direct_proximity_ski_lift",
    label: "Direkt am Skilift",
    order: 209,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <SkiGondel width={size} height={size} />,
  },
  {
    value: "diving_close_to_hotel",
    label: "Tauchen",
    order: 501,
    group: "Sport",
    icon: (size = 24) => <Tauchen width={size} height={size} />,
  },
  {
    value: "elegant_deluxe",
    label: "Deluxe",
    order: 210,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <Deluxe width={size} height={size} />,
  },
  {
    value: "family_benefits",
    label: "Familienvergünstigungen",
    order: 102,
    group: "Zielgruppe",
    icon: (size = 24) => <Familie2 width={size} height={size} />,
  },
  {
    value: "family_friendly",
    label: "Familien",
    group: "Zielgruppe",
    order: 101,
    mostwanted: true,
    icon: (size = 24) => <Familie width={size} height={size} />,
  },
  {
    value: "family_friendly_2",
    label: "Familien",
    group: "Zielgruppe",
    order: 101,
    hide: true,
    icon: (size = 24) => <Familie2 width={size} height={size} />,
  },
  {
    value: "free_parking",
    label: "Kostenloser Parkplatz",
    icon: (size = 24) => <Parken width={size} height={size} />,
    order: 212,
    group: "Unterkunft / Umgebung",
  },
  {
    value: "free_wlan",
    label: "freies WLAN",
    icon: (size = 24) => <Wlan width={size} height={size} />,
    order: 213,
    group: "Unterkunft / Umgebung",
  },
  {
    value: "gay_hotels",
    label: "Gay Hotels",
    group: "Zielgruppe",
    order: 104,
    icon: (size = 24) => <Gay width={size} height={size} />,
  },
  {
    value: "LGBTQ",
    label: "LGBTQIA+",
    group: "Zielgruppe",
    order: 110,
    icon: (size = 24) => <Gay width={size} height={size} />,
  },
  {
    value: "gently_sloping_sandy_beach",
    label: "fl. abfall. Sandstrand",
    order: 214,
    mostwanted: true,

    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <Sandstrand width={size} height={size} />,
  },
  {
    value: "german_entertainment_for_kids",
    label: "Deutschsprachige Kinderbetreuung",
    order: 215,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => (
      <DeutschsprachigeKinderbetreuung width={size} height={size} />
    ),
  },
  {
    value: "golf_close_to_hotel",
    label: "Golf",
    icon: (size = 24) => <Golf width={size} height={size} />,
    order: 505,
    group: "Sport",
  },
  {
    value: "golf_close_to_hotel_2",
    label: "Golf",
    order: 506,
    hide: true,
    group: "Sport",
    icon: (size = 24) => <Golf width={size} height={size} />,
  },
  {
    value: "great_sports_offer",
    label: "Großes Sportangebot",
    order: 407,
    group: "Aktivurlaub",
    icon: (size = 24) => <GrossesSportangebot width={size} height={size} />,
  },
  {
    value: "handicapped_accessible",
    label: "Barrierefrei",
    order: 218,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <Barrierefrei width={size} height={size} />,
  },
  {
    value: "heated_pool",
    label: "beheizbarer Pool",
    order: 219,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <BeheizterPool width={size} height={size} />,
  },
  {
    value: "horseback_riding_close_to_hotel",
    label: "Reiten",
    order: 510,
    group: "Sport",
    icon: (size = 24) => <Reiterin width={size} height={size} />,
  },
  {
    value: "hotel_in_hiking_region",
    label: "Wandern",
    order: 511,
    group: "Sport",
  },
  {
    value: "hotel_near_beach",
    label: "Strandnah (max. 400m)",
    order: 220,
    mostwanted: true,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <NearBeach width={size} height={size} />,
  },
  {
    value: "hygienic_measures",
    label: "Besondere Hygienemaßnahmen",
    icon: (size = 24) => <Maske width={size} height={size} />,
    order: 221,
    group: "Unterkunft / Umgebung",
  },
  {
    value: "kids_disco",
    label: "Kinderdisko",
    order: 222,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <BabyClub width={size} height={size} />,
  },
  {
    value: "laundry_service",
    label: "Wäscheservice",
    order: 223,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <Waescheservice width={size} height={size} />,
  },
  {
    value: "long_haul_journey",
    label: "Langzeithotels",
    order: 224,
    group: "Unterkunft / Umgebung",
  },
  {
    value: "massages_and_body_treatments",
    label: "Massagen",
    order: 301,
    group: "Body 'n' soul",
    icon: (size = 24) => <Massage width={size} height={size} />,
  },
  {
    value: "maxi_club_7to12_years",
    label: "Kinderclub (7-12 Jahre)",
    order: 225,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <ClubJugend width={size} height={size} />,
  },
  {
    value: "medical_service",
    label: "Arztservice",
    order: 226,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <Arztservice width={size} height={size} />,
  },
  {
    value: "mini_club_3to6_years",
    label: "Miniclub (3-6 Jahre)",
    order: 227,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <BabyClub2 width={size} height={size} />,
  },
  {
    value: "nice_small_family_run_hotel",
    label: "Klein/Familiär/Nett",
    order: 228,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <Familiaer width={size} height={size} />,
  },
  {
    value: "non_smoking_hotel",
    label: "Nichtraucherhotel",
    icon: (size = 24) => <NoSmoke width={size} height={size} />,
    order: 229,
    group: "Unterkunft / Umgebung",
  },
  {
    value: "non_smoking_rooms",
    label: "Nichtraucherzimmer",
    icon: (size = 24) => <NoSmoke width={size} height={size} />,
    order: 230,
    group: "Unterkunft / Umgebung",
  },
  {
    value: "other_theme_parks",
    label: "Freizeitpark",
    order: 231,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <Freizeitpark width={size} height={size} />,
  },
  {
    value: "own_fitness_facilities",
    label: "Fitnessraum",
    order: 401,
    group: "Aktivurlaub",
    icon: (size = 24) => <Fitnessraum width={size} height={size} />,
  },
  {
    value: "own_indoor_pool",
    label: "Hallenbad",
    order: 232,
    group: "Unterkunft / Umgebung",
    mostwanted: true,
    icon: (size = 24) => <Hallenbad width={size} height={size} />,
  },
  {
    value: "own_outdoor_pool",
    label: "Schwimmbad",
    order: 233,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <Pool width={size} height={size} />,
  },
  {
    value: "vegetarian",
    label: "Vegetarisch",
    order: 280,
    group: "Unterkunft / Umgebung",
  },
  {
    value: "vegan",
    label: "Vegan",
    order: 281,
    group: "Unterkunft / Umgebung",
  },
  {
    value: "glutenfree",
    label: "Glutenfrei",
    order: 282,
    group: "Unterkunft / Umgebung",
  },
  {
    value: "a_la_carte",
    label: "A la Carte",
    order: 283,
    group: "Unterkunft / Umgebung",
  },
  {
    value: "swim_up_pool",
    label: "Swimm-Up Zimmer",
    order: 284,
    group: "Unterkunft / Umgebung",
    mostwanted: true,
  },
  {
    value: "organic_food",
    label: "Bio-Lebensmittel",
    order: 285,
    group: "Unterkunft / Umgebung",
  },
  {
    value: "car_charging_stations",
    label: "Ladestation für Fahrzeuge",
    order: 286,
    group: "Unterkunft / Umgebung",
  },
  {
    value: "electric_bike",
    label: "E-Bikes",
    order: 287,
    group: "Unterkunft / Umgebung",
  },
  {
    value: "own_sauna_bathing_facilities",
    label: "Dampfbad&Sauna",
    order: 234,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <Sauna width={size} height={size} />,
  },
  {
    value: "own_squash_court",
    label: "Squash",
    order: 502,
    group: "Sport",
    icon: (size = 24) => <Squash width={size} height={size} />,
  },
  {
    value: "own_tennis_court",
    label: "Tennis",
    order: 503,
    group: "Sport",
    icon: (size = 24) => <Tennis width={size} height={size} />,
  },
  {
    value: "own_water_slide",
    label: "Wasserrutschen",
    order: 235,
    group: "Unterkunft / Umgebung",
    mostwanted: true,
    icon: (size = 24) => <Wasserrutschen width={size} height={size} />,
  },
  {
    value: "own_water_sports_facilities",
    label: "Wassersport",
    order: 504,
    group: "Sport",
    icon: (size = 24) => <Wassersport width={size} height={size} />,
  },
  {
    value: "own_wellness_facilities",
    label: "Wellness",
    order: 302,
    group: "Body 'n' soul",
    mostwanted: true,
    icon: (size = 24) => <WellnessEinrichtungen width={size} height={size} />,
  },
  {
    value: "parking_spaces_available",
    label: "Parkmöglichkeit",
    icon: (size = 24) => <Parken width={size} height={size} />,
    order: 236,
    group: "Unterkunft / Umgebung",
  },
  {
    value: "pets_allowed",
    label: "Haustier erlaubt",
    icon: (size = 24) => <Haustier width={size} height={size} />,
    order: 237,
    group: "Unterkunft / Umgebung",
  },
  {
    value: "petting_zoo",
    label: "Streichelzoo",
    order: 238,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <Streichelzoo width={size} height={size} />,
  },
  {
    value: "playground_for_children",
    label: "Kinderspielplatz",
    order: 239,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <Spielplatz width={size} height={size} />,
  },
  {
    value: "playroom_for_children",
    label: "Kinderspielraum",
    order: 240,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <Kinderspielraum width={size} height={size} />,
  },
  {
    value: "pool_for_children",
    label: "Kinderpool",
    order: 241,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <Kinderpool width={size} height={size} />,
  },
  {
    value: "quiet_location",
    label: "Ruhige Lage",
    order: 242,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <Ruhe width={size} height={size} />,
  },
  {
    value: "relaxation_for_body_and_soul",
    label: "Body&Soul",
    order: 305,
    group: "Body 'n' soul",
    icon: (size = 24) => <Meditation width={size} height={size} />,
  },
  {
    value: "restaurant",
    label: "Restaurant",
    icon: (size = 24) => <Restaurant width={size} height={size} />,
    order: 243,
    group: "Unterkunft / Umgebung",
  },
  {
    value: "sailing_close_to_hotel",
    label: "Segeln",
    order: 505,
    group: "Sport",
    icon: (size = 24) => <Segeln width={size} height={size} />,
  },
  {
    value: "sandy_beach",
    label: "Sandstrand",
    order: 244,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <Sandstrand width={size} height={size} />,
  },
  {
    value: "soccer_school",
    label: "Fussballschule",
    order: 509,
    group: "Sport",
    icon: (size = 24) => <Fussball width={size} height={size} />,
  },
  {
    value: "special_atmosphere_design",
    label: "Charme/Flair",
    order: 307,
    group: "Body 'n' soul",
    icon: (size = 24) => <RestaurantOberGourmet width={size} height={size} />,
  },
  {
    value: "special_culinary_delights",
    label: "Gourmet/Genießens",
    order: 308,
    group: "Body 'n' soul",
    icon: (size = 24) => <GourmetLobster width={size} height={size} />,
  },
  {
    value: "specials_for_newly_married",
    label: "für Hochzeitsreisende",
    order: 107,
    group: "Zielgruppe",
    icon: (size = 24) => <Hochzeit width={size} height={size} />,
  },
  {
    value: "sporting_activities_for_children",
    label: "Sportangebot für Kinder",
    order: 508,
    group: "Sport",
  },
  {
    value: "surfing_close_to_hotel",
    label: "Surfen",
    order: 512,
    group: "Sport",
    icon: (size = 24) => <Surf width={size} height={size} />,
  },
  {
    value: "swim_school",
    label: "Schwimmschule",
    order: 245,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <Schwimmschule width={size} height={size} />,
  },
  {
    value: "thalasso_treatments",
    label: "Thalasso",
    order: 317,
    group: "Body 'n' soul",
    icon: (size = 24) => <Thalasso width={size} height={size} />,
  },
  {
    value: "vacation_at_mediterranean_sea",
    label: "Hotel am Mittelmeer",
    order: 246,
    group: "Unterkunft / Umgebung",
  },
  {
    value: "vacation_at_north_baltic_sea",
    label: "Hotel an der Ostsee",
    order: 247,
    group: "Unterkunft / Umgebung",
  },
  {
    value: "vacation_in_the_mountains",
    label: "Hotel in den Bergen",
    order: 249,
    group: "Unterkunft / Umgebung",
  },
  {
    value: "winter_sports_ski_area",
    label: "Hotel am Skigebiet",
    order: 250,
    group: "Unterkunft / Umgebung",
    icon: (size = 24) => <Ski width={size} height={size} />,
  },
  {
    value: "wlan_available",
    label: "WLAN verfügbar",
    icon: (size = 24) => <Wlan width={size} height={size} />,
    order: 251,
    group: "Unterkunft / Umgebung",
  },
  {
    value: "certified_sustainable",
    label: "Zertifiziert nachhaltige Unterkunft",
    mostwanted: true,
    order: 252,
    group: "Unterkunft / Umgebung",
  },
];

/*export const hotelRatings = [
  { value: "animation", label: "Animation" },
  { value: "complex", label: "Anlage" },
  { value: "complexCleanness", label: "Sauberkeit der Anlage" },
  { value: "complexDesignAndFlair", label: "Ambiente der Anlage" },
 
  { value: "beach", label: "Strand" },
  { value: "pool", label: "Pool" },
  { value: "poolCleanness", label: "Sauberkeit des Pools" },
  { value: "poolSize", label: "Poolgröße" },
 
  { value: "poolRecreationalValue", label: "Erholungswert des Pools" },
  { value: "room", label: "Zimmer" },
  { value: "roomCleanness", label: "Sauberkeit der Zimmer" },
  { value: "roomEquipment", label: "Zimmerausstattung" },
  { value: "roomSize", label: "Zimmergröße" },
  { value: "roomWLAN", label: "WLAN auf den Zimmern" },
  {
    value: "roomSanitationSizeAndCleanness",
    label: "Größe und Sauberkeit des Sanitärbereichs der Zimmer",
  },
  {
    value: "roomDesignAndFlair",
    label: "Ambiente der Zimmer",
  },
  { value: "service", label: "Service" },
  {
    value: "serviceForeignLanguageCompetence",
    label: "Fremdsprachenfähigkeit des Service",
  },
  {
    value: "serviceKindnessAndHelpfulness",
    label: "Höflichkeit und Hilfsbereitschaft des Service",
  },
];*/

export const hotelRatings = [
  {
    value: "animation",
    label: "Animation",
  },
  {
    value: "animationChildren",
    label: "Kinderanimation",
  },
  {
    value: "beach",
    label: "Strand",
  },
  {
    value: "beachEquipment",
    label: "Strandausstattung",
  },
  {
    value: "beachExtent",
    label: "Strandlänge",
  },
  {
    value: "beachGastronomy",
    label: "Gastronomie am Strand",
  },
  {
    value: "beachReachability",
    label: "Entfernung des Strands",
  },
  {
    value: "beachSport",
    label: "Sport am Strand",
  },
  {
    value: "businessCatering",
    label: "Betriebsgastronomie",
  },
  {
    value: "businessServiceStaff",
    label: "Business-Service-Personal",
  },
  {
    value: "childCare",
    label: "Kinderbetreuung",
  },
  {
    value: "complex",
    label: "Hotelattribute",
  },
  {
    value: "complexCleanness",
    label: "Sauberkeit des Hotels",
  },
  {
    value: "complexDesignAndFlair",
    label: "Hoteldesign und Flair",
  },
  {
    value: "concierge",
    label: "Concierge",
  },
  {
    value: "conferenceRoomTechnicalEquipment",
    label: "Konferenzraum / technische Ausstattung",
  },
  {
    value: "culture",
    label: "Kultur",
  },
  {
    value: "cultureEvents",
    label: "Kulturveranstaltungen vor Ort",
  },
  {
    value: "cultureGaleriesAndExhibitions",
    label: "Galerien und Ausstellungen vor Ort",
  },
  {
    value: "cultureGuidedTours",
    label: "Geführte Touren vor Ort",
  },
  {
    value: "cultureParks",
    label: "Kulturparks",
  },
  {
    value: "cultureSights",
    label: "Kulturelle Sehenswürdigkeiten",
  },
  {
    value: "cultureTheaterAndOpera",
    label: "Theater und Oper",
  },
  {
    value: "fitnessCenter",
    label: "Fitnesscenter",
  },
  {
    value: "fitnessEquipmentState",
    label: "Fitness Ausstattung",
  },
  {
    value: "infrastructure",
    label: "Infrastruktur",
  },
  {
    value: "infrastructureAirportConnection",
    label: "Flughafenanbindung",
  },
  {
    value: "infrastructureStationConnection",
    label: "Anbindung zu öffentlichen Verkehrsmitteln",
  },
  {
    value: "location",
    label: "Standort",
  },
  {
    value: "locationExcursions",
    label: "Ausflüge vor Ort",
  },
  {
    value: "locationGastronomy",
    label: "Gastronomie vor Ort",
  },
  {
    value: "locationRecreationalActivities",
    label: "Freizeitaktivitäten vor Ort",
  },
  {
    value: "mealsAndDrinks",
    label: "Essen und Trinken",
  },
  {
    value: "mealsAndDrinksAllInclusive",
    label: "Essen und Trinken All Iinclusive",
  },
  {
    value: "mealsAndDrinksChildren",
    label: "Essen und Trinken für Kinder",
  },
  {
    value: "mealsAndDrinksCleanness",
    label: "Sauberkeit der Küche",
  },
  {
    value: "mealsAssortmentAndQuality",
    label: "Essens- und Getränkequalität",
  },
  {
    value: "mealsVarietyAndQuantity",
    label: "Essens- und Getränkeauswahl",
  },
  {
    value: "museum",
    label: "Museum",
  },
  {
    value: "objectAsBusiness",
    label: "Für Geschäftsreisende",
  },
  {
    value: "objectAsFamily",
    label: "familienfreundlich",
  },
  {
    value: "objectRecreationalValue",
    label: "Freizeitwert",
  },
  {
    value: "objectTravelAsCouple",
    label: "Für Paare",
  },
  {
    value: "objectTravelAsFamily",
    label: "Für Familien",
  },
  {
    value: "objectTravelAsGroup",
    label: "Für Gruppen",
  },
  {
    value: "objectTravelAsSingle",
    label: "Für Alleinreisende",
  },
  {
    value: "playground",
    label: "Spielplatz",
  },
  {
    value: "pool",
    label: "Pool",
  },
  {
    value: "poolChildren",
    label: "Kinderpool",
  },
  {
    value: "poolCleanness",
    label: "Sauberkeit des Pools",
  },
  {
    value: "poolRecreationalValue",
    label: "Poollandschaft",
  },
  {
    value: "poolSize",
    label: "Poolgröße",
  },
  {
    value: "poolWaterSlide",
    label: "Wasserrutsche",
  },
  {
    value: "room",
    label: "Zimmer",
  },
  {
    value: "roomCleanness",
    label: "Sauberkeit des Zimmers",
  },
  {
    value: "roomDesignAndFlair",
    label: "Design & Flair des Zimmers",
  },
  {
    value: "roomEquipment",
    label: "Zimmerausstattung",
  },
  {
    value: "roomNoiseLevel",
    label: "Zimmer Lärmpegel",
  },
  {
    value: "roomSanitationSizeAndCleanness",
    label: "Zimmer Sanitärbereich Größe und Sauberkeit",
  },
  {
    value: "roomService",
    label: "Zimmerservice",
  },
  {
    value: "roomSize",
    label: "Zimmergröße",
  },
  {
    value: "roomTemperature",
    label: "Zimmertemperatur",
  },
  {
    value: "roomWLAN",
    label: "WLAN im Zimmer",
  },
  {
    value: "sauna",
    label: "Sauna",
  },
  {
    value: "service",
    label: "Service",
  },
  {
    value: "serviceCheckInAndOut",
    label: "Service Check-in/Check-out",
  },
  {
    value: "serviceForeignLanguageCompetence",
    label: "Service Fremdsprachen und Kompetenz",
  },
  {
    value: "serviceKindnessAndHelpfulness",
    label: "Service Hilfsbereitschaft u Freundlichkeit",
  },
  {
    value: "sport",
    label: "Sport",
  },
  {
    value: "sportFacilities",
    label: "Sporteinrichtungen",
  },
  {
    value: "sportFacilitiesState",
    label: "Zustand der Sportanlagen",
  },
  {
    value: "sportGolf",
    label: "Golf",
  },
  {
    value: "wellness",
    label: "Wellness",
  },
  {
    value: "wellnessAmbience",
    label: "Wellness Ambiente",
  },
  {
    value: "wellnessCosmetics",
    label: "Kosmetikanwendungen",
  },
  {
    value: "wellnessMassage",
    label: "Massage-Anwendungen",
  },
  {
    value: "wellnessPoolThermal",
    label: "Thermalbad",
  },
];

export const getTexts = (text, params) => {
  let texts = { original: text };
  if (!text) return { original: "" };
  for (let i = 0; i < params.length; i++) {
    let param = params[i];
    if (text.indexOf(param) > -1) {
      let txt = text.split(param)[1];
      if (txt) {
        for (let j = 0; j < params.length; j++) {
          txt = txt.split(params[j])[0];
        }
        txt = txt.trim();
        if (txt.endsWith(",")) {
          txt = txt.split(",")[0];
        }
      }
      texts[param.split(" ").join("").split(":").join("").toLowerCase()] = txt;
    }
  }
  return texts;
};

export const nationalities = [
  {
    name_de: "Deutschland",
    name_en: "Germany",
    name_fr: "Allemagne",
    name_it: "Germania",
    name_nl: "Duitsland",
    name_pl: "Niemcy",
    name_es: "Alemania",
    name_pt: "Alemanha",
    name_be: "",
    name_ru: "Германия",
    code: "DE",
    active: 1,
    sale: 1,
  },
  {
    name_de: "Österreich",
    name_en: "Austria",
    name_fr: "Autriche",
    name_it: "Austria",
    name_nl: "Oostenrijk",
    name_pl: "Austria",
    name_es: "Austria",
    name_pt: "Áustria",
    name_be: "",
    name_ru: "Австрия",
    code: "AT",
    active: 1,
    sale: 1,
  },
  {
    name_de: "Schweiz",
    name_en: "Switzerland",
    name_fr: "Suisse",
    name_it: "Svizzera",
    name_nl: "Zwitserland",
    name_pl: "Szwajcaria",
    name_es: "Suiza",
    name_pt: "Suíça",
    name_be: "",
    name_ru: "Швейцария",
    code: "CH",
    active: 1,
    sale: 1,
  },
  {
    name_de: "Türkei",
    name_en: "Turkey",
    name_fr: "Turquie",
    name_it: "Turchia",
    name_nl: "Turkije",
    name_pl: "Turcja",
    name_es: "Turquía",
    name_pt: "Turquia",
    name_be: "",
    name_ru: "Турция",
    code: "TR",
    active: 1,
  },
  {
    name_de: "Afghanistan",
    name_en: "Afghanistan",
    name_fr: "Afghanistan",
    name_it: "Afghanistan",
    name_nl: "Afghanistan",
    name_pl: "Afganistan",
    name_es: "Afganistán",
    name_pt: "Afeganistão",
    name_be: "",
    name_ru: "Афганистан",
    code: "AF",
    active: 1,
  },
  {
    name_de: "Ägypten",
    name_en: "Egypt",
    name_fr: "Égypte",
    name_it: "Egitto",
    name_nl: "Egypte",
    name_pl: "Egipt",
    name_es: "Egipto",
    name_pt: "Egito",
    name_be: "",
    name_ru: "Египет",
    code: "EG",
    active: 1,
  },
  {
    name_de: "Ålandinseln",
    name_en: "Åland Islands",
    name_fr: "Åland (Îles)",
    name_it: "Isole Åland",
    name_nl: "Ålandeilanden",
    name_pl: "Wyspy Alandzkie",
    name_es: "Islas Åland",
    name_pt: "Ilhas Åland",
    name_be: "",
    name_ru: "Аландские острова",
    code: "AX",
    active: 0,
  },
  {
    name_de: "Albanien",
    name_en: "Albania",
    name_fr: "Albanie",
    name_it: "Albania",
    name_nl: "Albanië",
    name_pl: "Albania",
    name_es: "Albania",
    name_pt: "Albânia",
    name_be: "",
    name_ru: "Албания",
    code: "AL",
    active: 1,
  },
  {
    name_de: "Algerien",
    name_en: "Algeria",
    name_fr: "Algérie",
    name_it: "Algeria",
    name_nl: "Algerije",
    name_pl: "Algieria",
    name_es: "Argelia",
    name_pt: "Argélia",
    name_be: "",
    name_ru: "Алжир",
    code: "DZ",
    active: 1,
  },
  {
    name_de: "Amerikanisch Samoa",
    name_en: "American Samoa",
    name_fr: "Samoa américaines",
    name_it: "Samoa Americane",
    name_nl: "Amerikaans Samoa",
    name_pl: "Samoa amerykańskie",
    name_es: "Samoa Americana",
    name_pt: "Samoa Americana",
    name_be: "",
    name_ru: "Американское Самоа",
    code: "AS",
    active: 0,
  },
  {
    name_de: "Andorra",
    name_en: "Andorra",
    name_fr: "Andorre",
    name_it: "Andorra",
    name_nl: "Andorra",
    name_pl: "Andora",
    name_es: "Andorra",
    name_pt: "Andorra",
    name_be: "",
    name_ru: "Андорра",
    code: "AD",
    active: 1,
  },
  {
    name_de: "Angola",
    name_en: "Angola",
    name_fr: "Angola",
    name_it: "Angola",
    name_nl: "Angola",
    name_pl: "Angola",
    name_es: "Angola",
    name_pt: "Angola",
    name_be: "",
    name_ru: "Ангола",
    code: "AO",
    active: 1,
  },
  {
    name_de: "Anguilla",
    name_en: "Anguilla",
    name_fr: "Anguilla",
    name_it: "Anguilla",
    name_nl: "Anguilla",
    name_pl: "Anguilla",
    name_es: "Anguila",
    name_pt: "Anguila",
    name_be: "",
    name_ru: "Ангилья",
    code: "AI",
    active: 0,
  },
  {
    name_de: "Antarktis",
    name_en: "Antarctica",
    name_fr: "Antarctique",
    name_it: "Antartide",
    name_nl: "Antarctica",
    name_pl: "Antarktyka",
    name_es: "Antártida",
    name_pt: "Antártida",
    name_be: "",
    name_ru: "Антарктика",
    code: "AQ",
    active: 0,
  },
  {
    name_de: "Antigua und Barbuda",
    name_en: "Antigua and Barbuda",
    name_fr: "Antigua-et-Barbuda",
    name_it: "Antigua e Barbuda",
    name_nl: "Antigua en Barbuda",
    name_pl: "Antigua i Barbuda",
    name_es: "Antigua y Barbuda",
    name_pt: "Antígua e Barbuda",
    name_be: "",
    name_ru: "Антигуа и Барбуда",
    code: "AG",
    active: 1,
  },
  {
    name_de: "Äquatorialguinea",
    name_en: "Equatorial Guinea",
    name_fr: "Guinée équatoriale",
    name_it: "Guinea Equatoriale",
    name_nl: "Equatoriaal-Guinea",
    name_pl: "Gwinea Równikowa",
    name_es: "Guinea Ecuatorial",
    name_pt: "Guiné Equatorial",
    name_be: "",
    name_ru: "Экваториальная Гвинея",
    code: "GQ",
    active: 1,
  },
  {
    name_de: "Argentinien",
    name_en: "Argentina",
    name_fr: "Argentine",
    name_it: "Argentina",
    name_nl: "Argentinië",
    name_pl: "Argentyna",
    name_es: "Argentina",
    name_pt: "Argentina",
    name_be: "",
    name_ru: "Аргентина",
    code: "AR",
    active: 1,
  },
  {
    name_de: "Armenien",
    name_en: "Armenia",
    name_fr: "Arménie",
    name_it: "Armenia",
    name_nl: "Armenië",
    name_pl: "Armenia",
    name_es: "Armenia",
    name_pt: "Arménia",
    name_be: "",
    name_ru: "Армения",
    code: "AM",
    active: 1,
  },
  {
    name_de: "Aruba",
    name_en: "Aruba",
    name_fr: "Aruba",
    name_it: "Aruba",
    name_nl: "Aruba",
    name_pl: "Aruba",
    name_es: "Aruba",
    name_pt: "Aruba",
    name_be: "",
    name_ru: "Аруба",
    code: "AW",
    active: 0,
  },
  {
    name_de: "Aserbaidschan",
    name_en: "Azerbaijan",
    name_fr: "Azerbaïdjan",
    name_it: "Azerbaigian",
    name_nl: "Azerbeidzjan",
    name_pl: "Azerbejdżan",
    name_es: "Azerbaiyán",
    name_pt: "Azerbaijão",
    name_be: "",
    name_ru: "Азербайджан",
    code: "AZ",
    active: 1,
  },
  {
    name_de: "Äthiopien",
    name_en: "Ethiopia",
    name_fr: "Éthiopie",
    name_it: "Etiopia",
    name_nl: "Ethiopië",
    name_pl: "Etiopia",
    name_es: "Etiopía",
    name_pt: "Etiópia",
    name_be: "",
    name_ru: "Эфиопия",
    code: "ET",
    active: 1,
  },
  {
    name_de: "Australien",
    name_en: "Australia",
    name_fr: "Australie",
    name_it: "Australia",
    name_nl: "Australië",
    name_pl: "Australia",
    name_es: "Australia",
    name_pt: "Austrália",
    name_be: "",
    name_ru: "Австралия",
    code: "AU",
    active: 1,
  },
  {
    name_de: "Bahamas",
    name_en: "Bahamas",
    name_fr: "Bahamas",
    name_it: "Bahamas",
    name_nl: "Bahama's",
    name_pl: "Bahamy",
    name_es: "Bahamas",
    name_pt: "Bahamas",
    name_be: "",
    name_ru: "Багамские Острова",
    code: "BS",
    active: 1,
  },
  {
    name_de: "Bahrain",
    name_en: "Bahrain",
    name_fr: "Bahreïn",
    name_it: "Bahrain",
    name_nl: "Bahrein",
    name_pl: "Bahrajn",
    name_es: "Bahréin",
    name_pt: "Bahrein",
    name_be: "",
    name_ru: "бахрейн",
    code: "BH",
    active: 1,
  },
  {
    name_de: "Bangladesch",
    name_en: "Bangladesh",
    name_fr: "Bangladesh",
    name_it: "Bangladesh",
    name_nl: "Bangladesh",
    name_pl: "Bangladesz",
    name_es: "Bangladesh",
    name_pt: "Bangladesh",
    name_be: "",
    name_ru: "Бангладеш",
    code: "BD",
    active: 1,
  },
  {
    name_de: "Barbados",
    name_en: "Barbados",
    name_fr: "Barbade",
    name_it: "Barbados",
    name_nl: "Barbados",
    name_pl: "Barbados",
    name_es: "Barbados",
    name_pt: "Barbados",
    name_be: "",
    name_ru: "Барбадос",
    code: "BB",
    active: 1,
  },
  {
    name_de: "Belarus",
    name_en: "Belarus",
    name_fr: "Biélorussie",
    name_it: "Bielorussia",
    name_nl: "Wit-Rusland",
    name_pl: "Białoruś",
    name_es: "Bielorrusia",
    name_pt: "Bielorrússia",
    name_be: "",
    name_ru: "Беларусь",
    code: "BY",
    active: 1,
  },
  {
    name_de: "Belgien",
    name_en: "Belgium",
    name_fr: "Belgique",
    name_it: "Belgio",
    name_nl: "België",
    name_pl: "Belgia",
    name_es: "Bélgica",
    name_pt: "Bélgica",
    name_be: "",
    name_ru: "Бельгия",
    code: "BE",
    active: 1,
    sale: 1,
  },
  {
    name_de: "Belize",
    name_en: "Belize",
    name_fr: "Belize",
    name_it: "Belize",
    name_nl: "Belize",
    name_pl: "Belize",
    name_es: "Belice",
    name_pt: "Belize",
    name_be: "",
    name_ru: "Белиз",
    code: "BZ",
    active: 1,
  },
  {
    name_de: "Benin",
    name_en: "Benin",
    name_fr: "Bénin",
    name_it: "Benin",
    name_nl: "Benin",
    name_pl: "Benin",
    name_es: "Benín",
    name_pt: "Benim",
    name_be: "",
    name_ru: "Бенин",
    code: "BJ",
    active: 1,
  },
  {
    name_de: "Bermuda",
    name_en: "Bermuda",
    name_fr: "Bermudes",
    name_it: "Bermuda",
    name_nl: "Bermuda",
    name_pl: "Bermudy",
    name_es: "Bermudas",
    name_pt: "Bermudas",
    name_be: "",
    name_ru: "Бермудские острова",
    code: "BM",
    active: 0,
  },
  {
    name_de: "Bhutan",
    name_en: "Bhutan",
    name_fr: "Bhoutan",
    name_it: "Bhutan",
    name_nl: "Bhutan",
    name_pl: "Bhutan",
    name_es: "Bután",
    name_pt: "Butão",
    name_be: "",
    name_ru: "Бутан",
    code: "BT",
    active: 1,
  },
  {
    name_de: "Bolivien",
    name_en: "Bolivia",
    name_fr: "Bolivie",
    name_it: "Bolivia",
    name_nl: "Bolivia",
    name_pl: "Boliwia",
    name_es: "Bolivia",
    name_pt: "Bolívia",
    name_be: "",
    name_ru: "Боливия",
    code: "BO",
    active: 1,
  },
  {
    name_de: "Bosnien und Herzegowina",
    name_en: "Bosnia and Herzegovina",
    name_fr: "Bosnie-Herzégovine",
    name_it: "Bosnia-Erzegovina",
    name_nl: "Bosnië en Herzegovina",
    name_pl: "Bośnia i Hercegowina",
    name_es: "Bosnia y Herzegovina",
    name_pt: "Bósnia e Herzegovina",
    name_be: "",
    name_ru: "Босния и Герцеговина",
    code: "BA",
    active: 1,
  },
  {
    name_de: "Botswana",
    name_en: "Botswana",
    name_fr: "Botswana",
    name_it: "Botswana",
    name_nl: "Botswana",
    name_pl: "Botswana",
    name_es: "Botsuana",
    name_pt: "Botsuana",
    name_be: "",
    name_ru: "Ботсвана",
    code: "BW",
    active: 1,
  },
  {
    name_de: "Bouvetinsel",
    name_en: "Bouvet Island",
    name_fr: "Île Bouvet",
    name_it: "Isola di Bouvet",
    name_nl: "Bouvet eiland",
    name_pl: "Wyspa Bouveta",
    name_es: "Isla Bouvet",
    name_pt: "Ilha Bouvet",
    name_be: "",
    name_ru: "остров Буве",
    code: "BV",
    active: 0,
  },
  {
    name_de: "Brasilien",
    name_en: "Brazil",
    name_fr: "Brésil",
    name_it: "Brasile",
    name_nl: "Brazilië",
    name_pl: "Brazylia",
    name_es: "Brasil",
    name_pt: "Brasil",
    name_be: "",
    name_ru: "Бразилия",
    code: "BR",
    active: 1,
  },
  {
    name_de: "Britisches Territorium im Indischen Ozean",
    name_en: "British Indian Ocean Territory",
    name_fr: "Territoire britannique de l'océan Indien",
    name_it: "Territorio britannico dell'Oceano Indiano",
    name_nl: "Brits gebied in de Indische Oceaan",
    name_pl: "Brytyjskie Terytorium Oceanu Indyjskiego",
    name_es: "Territorio Británico del Océano Índico",
    name_pt: "Território Britânico do Oceano Índico",
    name_be: "",
    name_ru: "Британская территория в Индийском океане",
    code: "IO",
    active: 0,
  },
  {
    name_de: "Brunei",
    name_en: "Brunei",
    name_fr: "Brunei",
    name_it: "Brunei",
    name_nl: "Brunei",
    name_pl: "Brunei",
    name_es: "Brunéi",
    name_pt: "Brunei",
    name_be: "",
    name_ru: "Бруней",
    code: "BN",
    active: 1,
  },
  {
    name_de: "Bulgarien",
    name_en: "Bulgaria",
    name_fr: "Bulgarie",
    name_it: "Bulgarie",
    name_nl: "Bulgarije",
    name_pl: "Bułgaria",
    name_es: "Bulgaria",
    name_pt: "Bulgária",
    name_be: "",
    name_ru: "Болгария",
    code: "BG",
    active: 1,
  },
  {
    name_de: "Burkina Faso",
    name_en: "Burkina Faso",
    name_fr: "Burkina Faso",
    name_it: "Burkina Faso",
    name_nl: "Burkina Faso",
    name_pl: "Burkina Faso",
    name_es: "Burkina Faso",
    name_pt: "Burkina Faso",
    name_be: "",
    name_ru: "Буркина-Фасо",
    code: "BF",
    active: 1,
  },
  {
    name_de: "Burundi",
    name_en: "Burundi",
    name_fr: "Burundi",
    name_it: "Burundi",
    name_nl: "Burundi",
    name_pl: "Burundi",
    name_es: "Burundi",
    name_pt: "Burundi",
    name_be: "",
    name_ru: "Бурунди",
    code: "BI",
    active: 1,
  },
  {
    name_de: "Chile",
    name_en: "Chile",
    name_fr: "Chili",
    name_it: "Cile",
    name_nl: "Chili",
    name_pl: "Chile",
    name_es: "Chile",
    name_pt: "Chile",
    name_be: "",
    name_ru: "Чили",
    code: "CL",
    active: 1,
  },
  {
    name_de: "China",
    name_en: "China",
    name_fr: "Chine",
    name_it: "Cina",
    name_nl: "China",
    name_pl: "Chiny",
    name_es: "China",
    name_pt: "China",
    name_be: "",
    name_ru: "Китай",
    code: "CN",
    active: 1,
  },
  {
    name_de: "Cookinseln",
    name_en: "Cook Islands",
    name_fr: "Cook (Îles Cook)",
    name_it: "Isole Cook",
    name_nl: "Cookeilanden",
    name_pl: "Wyspy Cooka",
    name_es: "Islas Cook",
    name_pt: "Ilhas Cook",
    name_be: "",
    name_ru: "Острова Кука",
    code: "CK",
    active: 0,
  },
  {
    name_de: "Costa Rica",
    name_en: "Costa Rica",
    name_fr: "Costa Rica",
    name_it: "Costa Rica",
    name_nl: "Costa Rica",
    name_pl: "Kostaryka",
    name_es: "Costa Rica",
    name_pt: "Costa Rica",
    name_be: "",
    name_ru: "Коста-Рика",
    code: "CR",
    active: 1,
  },
  {
    name_de: "Curaçao",
    name_en: "Curacao",
    name_fr: "Curaçao",
    name_it: "Curacao",
    name_nl: "Curaçao",
    name_pl: "Curacao",
    name_es: "Curaçao",
    name_pt: "Curaçao",
    name_be: "",
    name_ru: "Кюрасао",
    code: "CW",
    active: 0,
  },
  {
    name_de: "Dänemark",
    name_en: "Denmark",
    name_fr: "Danemark",
    name_it: "Danimarca",
    name_nl: "Denemarken",
    name_pl: "Dania",
    name_es: "Dinamarca",
    name_pt: "Dinamarca",
    name_be: "",
    name_ru: "Дания",
    code: "DK",
    active: 1,
    sale: 1,
  },
  {
    name_de: "Demokratische Republik Kongo",
    name_en: "Democratic Republic of the Congo",
    name_fr: "République Démocratique du Congo",
    name_it: "Repubblica Democratica del Congo",
    name_nl: "Democratische Republiek Congo",
    name_pl: "Demokratyczna Republika Konga",
    name_es: "República Democrática del Congo",
    name_pt: "República Democrática do Congo",
    name_be: "",
    name_ru: "Демократическая Республика Конго",
    code: "CD",
    active: 1,
  },

  {
    name_de: "Dominica",
    name_en: "Dominica",
    name_fr: "Dominique",
    name_it: "Dominica",
    name_nl: "Dominica",
    name_pl: "Dominika",
    name_es: "Dominica",
    name_pt: "Dominica",
    name_be: "",
    name_ru: "Доминика",
    code: "DM",
    active: 1,
  },
  {
    name_de: "Dominikanische Republik",
    name_en: "Dominican Republic",
    name_fr: "République dominicaine",
    name_it: "Repubblica Dominicana",
    name_nl: "Dominicaanse Republiek",
    name_pl: "Republika Dominikańska",
    name_es: "República Dominicana",
    name_pt: "República Dominicana",
    name_be: "",
    name_ru: "Доминиканская Республика",
    code: "DO",
    active: 1,
  },
  {
    name_de: "Dschibuti",
    name_en: "Djibouti",
    name_fr: "Djibouti",
    name_it: "Gibuti",
    name_nl: "Djibouti",
    name_pl: "Dżibuti",
    name_es: "Yibuti",
    name_pt: "Djibuti",
    name_be: "",
    name_ru: "джибутийский",
    code: "DJ",
    active: 1,
  },
  {
    name_de: "Ecuador",
    name_en: "Ecuador",
    name_fr: "Équateur",
    name_it: "Ecuador",
    name_nl: "Ecuador",
    name_pl: "Ekwador",
    name_es: "Ecuador",
    name_pt: "Equador",
    name_be: "",
    name_ru: "Эквадор",
    code: "EC",
    active: 1,
  },
  {
    name_de: "El Salvador",
    name_en: "El Salvador",
    name_fr: "Salvador",
    name_it: "El Salvador",
    name_nl: "El Salvador",
    name_pl: "Salwador",
    name_es: "El Salvador",
    name_pt: "El Salvador",
    name_be: "",
    name_ru: "Сальвадор",
    code: "SV",
    active: 1,
  },
  {
    name_de: "Elfenbeinküste",
    name_en: "Ivory Coast",
    name_fr: "Côte d’Ivoire",
    name_it: "Costa d'Avorio",
    name_nl: "Ivoorkust",
    name_pl: "Wybrzeże Kości Słoniowej",
    name_es: "Costa de Marfil",
    name_pt: "Costa do Marfim",
    name_be: "",
    name_ru: "Берег Слоновой Кости",
    code: "CI",
    active: 1,
  },
  {
    name_de: "Eritrea",
    name_en: "Eritrea",
    name_fr: "Érythrée",
    name_it: "Eritrea",
    name_nl: "Eritrea",
    name_pl: "Erytrea",
    name_es: "Eritrea",
    name_pt: "Eritreia",
    name_be: "",
    name_ru: "Эритрея",
    code: "ER",
    active: 1,
  },
  {
    name_de: "Estland",
    name_en: "Estonia",
    name_fr: "Estonie",
    name_it: "Estonia",
    name_nl: "Estland",
    name_pl: "Estonia",
    name_es: "Estonia",
    name_pt: "Estónia",
    name_be: "",
    name_ru: "Эстония",
    code: "EE",
    active: 1,
  },
  {
    name_de: "Falklandinseln",
    name_en: "Falkland Islands",
    name_fr: "Malouines (Îles)",
    name_it: "Isole Falkland",
    name_nl: "Falklandeilanden",
    name_pl: "Falklandy",
    name_es: "Islas Malvinas",
    name_pt: "Ilhas Malvinas",
    name_be: "",
    name_ru: "Фолклендские острова",
    code: "FK",
    active: 0,
  },
  {
    name_de: "Färöer-Inseln",
    name_en: "Faroe Islands",
    name_fr: "Féroé (Îles)",
    name_it: "Isole Faroe",
    name_nl: "Faeröer Eilanden",
    name_pl: "Wyspy Owcze",
    name_es: "Islas Feroe",
    name_pt: "Ilhas Faroé",
    name_be: "",
    name_ru: "Фарерские острова",
    code: "FO",
    active: 0,
  },
  {
    name_de: "Fidschi",
    name_en: "Fiji",
    name_fr: "Fidji",
    name_it: "Figi",
    name_nl: "Fiji",
    name_pl: "Fidżi",
    name_es: "Fiji",
    name_pt: "Fiji",
    name_be: "",
    name_ru: "Фиджи",
    code: "FJ",
    active: 1,
  },
  {
    name_de: "Finnland",
    name_en: "Finland",
    name_fr: "Finlande",
    name_it: "Finlandia",
    name_nl: "Finland",
    name_pl: "Finlandia",
    name_es: "Finlandia",
    name_pt: "Finlândia",
    name_be: "",
    name_ru: "Финляндия",
    code: "FI",
    active: 1,
  },
  {
    name_de: "Frankreich",
    name_en: "France",
    name_fr: "France",
    name_it: "Francia",
    name_nl: "Frankrijk",
    name_pl: "Francja",
    name_es: "Francia",
    name_pt: "França",
    name_be: "",
    name_ru: "Франция",
    code: "FR",
    active: 1,
    sale: 1,
  },
  {
    name_de: "Französisch-Guayana",
    name_en: "French Guiana",
    name_fr: "Guyane française",
    name_it: "Guyana Francese",
    name_nl: "Frans Guyana",
    name_pl: "Gujana Francuska",
    name_es: "Guayana Francesa",
    name_pt: "Guiana Francesa",
    name_be: "",
    name_ru: "Французская Гвиана",
    code: "GF",
    active: 0,
  },
  {
    name_de: "Französisch-Polynesien",
    name_en: "French Polynesia",
    name_fr: "Polynésie française",
    name_it: "Polinesia Francese",
    name_nl: "Frans Polynesië",
    name_pl: "Polinezja Francuska",
    name_es: "Polinesia Francesa",
    name_pt: "Polinésia Francesa",
    name_be: "",
    name_ru: "Французская Полинезия",
    code: "PF",
    active: 0,
  },
  {
    name_de: "Französische Süd- und Antarktisgebiete",
    name_en: "French Southern and Antarctic Lands",
    name_fr: "Terres australes françaises",
    name_it: "Territori francesi del Sud e dell'Antartico",
    name_nl: "Franse zuidelijke en Antarctische gebieden",
    name_pl: "Francuskie Terytoria Południowe i Antarktyczne",
    name_es: "Territorios Australes y Antárticos Franceses",
    name_pt: "Territórios austrais e antárcticos franceses",
    name_be: "",
    name_ru: "Французские южные и антарктические территории",
    code: "TF",
    active: 0,
  },
  {
    name_de: "Gabun",
    name_en: "Gabon",
    name_fr: "Le Gabon",
    name_it: "Gabon",
    name_nl: "Gabon",
    name_pl: "Gabon",
    name_es: "Gabón",
    name_pt: "Gabão",
    name_be: "",
    name_ru: "Габон",
    code: "GA",
    active: 1,
  },
  {
    name_de: "Gambia",
    name_en: "Gambia",
    name_fr: "Gambie",
    name_it: "Gambia",
    name_nl: "Gambia",
    name_pl: "Gambia",
    name_es: "Gambia",
    name_pt: "Gâmbia",
    name_be: "",
    name_ru: "Гамбия",
    code: "GM",
    active: 1,
  },
  {
    name_de: "Georgien",
    name_en: "Georgia",
    name_fr: "Géorgie",
    name_it: "Georgia",
    name_nl: "Georgië",
    name_pl: "Gruzja",
    name_es: "Georgia",
    name_pt: "Geórgia",
    name_be: "",
    name_ru: "Грузия",
    code: "GE",
    active: 1,
  },
  {
    name_de: "Ghana",
    name_en: "Ghana",
    name_fr: "Ghana",
    name_it: "Ghana",
    name_nl: "Ghana",
    name_pl: "Ghana",
    name_es: "Ghana",
    name_pt: "Gana",
    name_be: "",
    name_ru: "Гана",
    code: "GH",
    active: 1,
  },
  {
    name_de: "Gibraltar",
    name_en: "Gibraltar",
    name_fr: "Gibraltar",
    name_it: "Gibilterra",
    name_nl: "Gibraltar",
    name_pl: "Gibraltar",
    name_es: "Gibraltar",
    name_pt: "Gibraltar",
    name_be: "",
    name_ru: "Гибралтар",
    code: "GI",
    active: 0,
  },
  {
    name_de: "Grenada",
    name_en: "Grenada",
    name_fr: "Grenade",
    name_it: "Grenada",
    name_nl: "Grenada",
    name_pl: "Grenada",
    name_es: "Grenada",
    name_pt: "Granada",
    name_be: "",
    name_ru: "Гренада",
    code: "GD",
    active: 1,
  },
  {
    name_de: "Griechenland",
    name_en: "Greece",
    name_fr: "Grèce",
    name_it: "Grecia",
    name_nl: "Griekenland",
    name_pl: "Grecja",
    name_es: "Grecia",
    name_pt: "Grécia",
    name_be: "",
    name_ru: "Греция",
    code: "GR",
    active: 1,
  },
  {
    name_de: "Grönland",
    name_en: "Greenland",
    name_fr: "Groenland",
    name_it: "Groenlandia",
    name_nl: "Groenland",
    name_pl: "Grenlandia",
    name_es: "Groenlandia",
    name_pt: "Gronelândia",
    name_be: "",
    name_ru: "Гренландия",
    code: "GL",
    active: 0,
  },
  {
    name_de: "Guadeloupe",
    name_en: "Guadeloupe",
    name_fr: "Guadeloupe",
    name_it: "Guadalupa",
    name_nl: "Guadeloupe",
    name_pl: "Gwadelupa",
    name_es: "Guadalupe",
    name_pt: "Guadalupe",
    name_be: "",
    name_ru: "Гваделупа",
    code: "GP",
    active: 0,
  },
  {
    name_de: "Guam",
    name_en: "Guam",
    name_fr: "Guam",
    name_it: "Guam",
    name_nl: "Guam",
    name_pl: "Guam",
    name_es: "Guam",
    name_pt: "Guam",
    name_be: "",
    name_ru: "Гуам",
    code: "GU",
    active: 0,
  },
  {
    name_de: "Guatemala",
    name_en: "Guatemala",
    name_fr: "Guatemala",
    name_it: "Guatemala",
    name_nl: "Guatemala",
    name_pl: "Gwatemala",
    name_es: "Guatemala",
    name_pt: "Guatemala",
    name_be: "",
    name_ru: "Гватемала",
    code: "GT",
    active: 1,
  },
  {
    name_de: "Guernsey",
    name_en: "Guernsey",
    name_fr: "Guernesey",
    name_it: "Guernsey",
    name_nl: "Guernsey",
    name_pl: "Guernsey",
    name_es: "Guernesey",
    name_pt: "Guernsey",
    name_be: "",
    name_ru: "Гернси",
    code: "GG",
    active: 0,
  },
  {
    name_de: "Guinea",
    name_en: "Guinea",
    name_fr: "Guinée",
    name_it: "Guinea",
    name_nl: "Guinea",
    name_pl: "Gwinea",
    name_es: "Guinea",
    name_pt: "Guiné",
    name_be: "",
    name_ru: "Гвинея",
    code: "GN",
    active: 1,
  },
  {
    name_de: "Guinea-Bissau",
    name_en: "Guinea-Bissau",
    name_fr: "Guinée-Bissau",
    name_it: "Guinea-Bissau",
    name_nl: "Guinee-Bissau",
    name_pl: "Gwinea Bissau",
    name_es: "Guinea-Bissau",
    name_pt: "Guiné-Bissau",
    name_be: "",
    name_ru: "Гвинея-Бисау",
    code: "GW",
    active: 1,
  },
  {
    name_de: "Guyana",
    name_en: "Guyana",
    name_fr: "Guyane",
    name_it: "Guyana",
    name_nl: "Guyana",
    name_pl: "Gujana",
    name_es: "Guayana",
    name_pt: "Guiana",
    name_be: "",
    name_ru: "Гайана",
    code: "GY",
    active: 1,
  },
  {
    name_de: "Haiti",
    name_en: "Haiti",
    name_fr: "Haïti",
    name_it: "Haiti",
    name_nl: "Haïti",
    name_pl: "Haiti",
    name_es: "Haití",
    name_pt: "Haiti",
    name_be: "",
    name_ru: "Гаити",
    code: "HT",
    active: 1,
  },
  {
    name_de: "Heard und McDonaldinseln",
    name_en: "Heard Island and McDonald Islands",
    name_fr: "Heard et McDonald (Îles)",
    name_it: "Isole Heard e McDonald",
    name_nl: "Heard en McDonaldeilanden",
    name_pl: "Wyspy Heard i McDonald",
    name_es: "Islas Heard y McDonald",
    name_pt: "Ilhas Heard y McDonald",
    name_be: "",
    name_ru: "Слышал и Макдональдские острова",
    code: "HM",
    active: 0,
  },
  {
    name_de: "Honduras",
    name_en: "Honduras",
    name_fr: "Honduras",
    name_it: "Honduras",
    name_nl: "Honduras",
    name_pl: "Honduras",
    name_es: "Honduras",
    name_pt: "Honduras",
    name_be: "",
    name_ru: "Гондурас",
    code: "HN",
    active: 1,
  },
  {
    name_de: "Hongkong",
    name_en: "Hong Kong",
    name_fr: "Hong Kong",
    name_it: "Hong Kong",
    name_nl: "Hong Kong",
    name_pl: "Hongkong",
    name_es: "Hong Kong",
    name_pt: "Hong Kong",
    name_be: "",
    name_ru: "Гонконг",
    code: "HK",
    active: 1,
  },
  {
    name_de: "Indien",
    name_en: "India",
    name_fr: "Inde",
    name_it: "India",
    name_nl: "India",
    name_pl: "Indie",
    name_es: "India",
    name_pt: "Índia",
    name_be: "",
    name_ru: "Индия",
    code: "IN",
    active: 1,
  },
  {
    name_de: "Indonesien",
    name_en: "Indonesia",
    name_fr: "Indonésie",
    name_it: "Indonesia",
    name_nl: "Indonesië",
    name_pl: "Indonezja",
    name_es: "Indonesia",
    name_pt: "Indonésia",
    name_be: "",
    name_ru: "ИндонезияIndonésie",
    code: "ID",
    active: 1,
  },
  {
    name_de: "Insel Man",
    name_en: "Isle of Man",
    name_fr: "Île de Man",
    name_it: "Isola di Man",
    name_nl: "Eiland Man",
    name_pl: "Wyspa Man",
    name_es: "Isla de Man",
    name_pt: "Ilha de Man",
    name_be: "",
    name_ru: "Остров Человеческий",
    code: "IM",
    active: 0,
  },
  {
    name_de: "Irak",
    name_en: "Iraq",
    name_fr: "Irak",
    name_it: "Iraq",
    name_nl: "Irak",
    name_pl: "Irak",
    name_es: "Iraq",
    name_pt: "Iraque",
    name_be: "",
    name_ru: "Ирак",
    code: "IQ",
    active: 1,
  },
  {
    name_de: "Iran",
    name_en: "Iran",
    name_fr: "Iran",
    name_it: "Iran",
    name_nl: "Iran",
    name_pl: "Iran",
    name_es: "Irán",
    name_pt: "Irão",
    name_be: "",
    name_ru: "Иран",
    code: "IR",
    active: 1,
  },
  {
    name_de: "Irland",
    name_en: "Ireland",
    name_fr: "Irlande",
    name_it: "Irlanda",
    name_nl: "Ierland",
    name_pl: "Irlandia",
    name_es: "Irlanda",
    name_pt: "Irlanda",
    name_be: "",
    name_ru: "Ирландия",
    code: "IE",
    active: 1,
  },
  {
    name_de: "Island",
    name_en: "Iceland",
    name_fr: "Islande",
    name_it: "Islanda",
    name_nl: "IJsland",
    name_pl: "Islandia",
    name_es: "Islandia",
    name_pt: "Islândia",
    name_be: "",
    name_ru: "Исландия",
    code: "IS",
    active: 1,
  },
  {
    name_de: "Israel",
    name_en: "Israel",
    name_fr: "Israël",
    name_it: "Israele",
    name_nl: "Israël",
    name_pl: "Izrael",
    name_es: "Israel",
    name_pt: "Israel",
    name_be: "",
    name_ru: "Израиль",
    code: "IL",
    active: 1,
  },
  {
    name_de: "Italien",
    name_en: "Italy",
    name_fr: "Italie",
    name_it: "Italia",
    name_nl: "Italie",
    name_pl: "Włochy",
    name_es: "Italia",
    name_pt: "Itália",
    name_be: "",
    name_ru: "Италия",
    code: "IT",
    active: 1,
  },
  {
    name_de: "Jamaika",
    name_en: "Jamaica",
    name_fr: "Jamaïque",
    name_it: "Giamaica",
    name_nl: "Jamaica",
    name_pl: "Jamajka",
    name_es: "Jamaica",
    name_pt: "Jamaica",
    name_be: "",
    name_ru: "Ямайка",
    code: "JM",
    active: 1,
  },
  {
    name_de: "Japan",
    name_en: "Japan",
    name_fr: "Japon",
    name_it: "Giappone",
    name_nl: "Japan",
    name_pl: "Japonia",
    name_es: "Japón",
    name_pt: "Japão",
    name_be: "",
    name_ru: "Япония",
    code: "JP",
    active: 1,
  },
  {
    name_de: "Jemen",
    name_en: "Yemen",
    name_fr: "Yémen",
    name_it: "Yemen",
    name_nl: "Jemen",
    name_pl: "Jemen",
    name_es: "Yemen",
    name_pt: "Iêmen",
    name_be: "",
    name_ru: "Йемен",
    code: "YE",
    active: 1,
  },
  {
    name_de: "Jersey",
    name_en: "Jersey",
    name_fr: "Jersey",
    name_it: "Maglia",
    name_nl: "Jersey",
    name_pl: "Bluza",
    name_es: "Maillot",
    name_pt: "Jersey",
    name_be: "",
    name_ru: "Джерси",
    code: "JE",
    active: 0,
  },
  {
    name_de: "Jordanien",
    name_en: "Jordan",
    name_fr: "Jordanie",
    name_it: "Giordania",
    name_nl: "Jordanië",
    name_pl: "Jordania",
    name_es: "Jordania",
    name_pt: "Jordânia",
    name_be: "",
    name_ru: "Иордания",
    code: "JO",
    active: 1,
  },
  {
    name_de: "Jungferninseln (UK)",
    name_en: "British Virgin Islands",
    name_fr: "Îles Vierges britanniques",
    name_it: "Isole Vergini britanniche",
    name_nl: "Britse Maagdeneilanden",
    name_pl: "Brytyjskie Wyspy Dziewicze",
    name_es: "Islas Vírgenes Británicas",
    name_pt: "Ilhas Virgens Britânicas",
    name_be: "",
    name_ru: "Британские Виргинские острова",
    code: "VG",
    active: 0,
  },
  {
    name_de: "Jungferninseln (US)",
    name_en: "Virgin Islands",
    name_fr: "Vierges américaines (Îles)",
    name_it: "Isole Vergini Americane",
    name_nl: "Amerikaanse Maagdeneilanden",
    name_pl: "Amerykańskie Wyspy Dziewicze",
    name_es: "Islas Vírgenes Americanas",
    name_pt: "Ilhas Virgens Americanas",
    name_be: "",
    name_ru: "Американские Виргинские острова",
    code: "VI",
    active: 0,
  },
  {
    name_de: "Kaimaninseln",
    name_en: "Cayman Islands",
    name_fr: "Îles Caïmans",
    name_it: "Isole Cayman",
    name_nl: "Kaaiman Eilanden",
    name_pl: "Kajmany",
    name_es: "Islas Caimán",
    name_pt: "Ilhas Caimão",
    name_be: "",
    name_ru: "Каймановы острова",
    code: "KY",
    active: 0,
  },
  {
    name_de: "Kambodscha",
    name_en: "Cambodia",
    name_fr: "Cambodge",
    name_it: "Cambogia",
    name_nl: "Cambodja",
    name_pl: "Kambodża",
    name_es: "Camboya",
    name_pt: "Camboja",
    name_be: "",
    name_ru: "Камбоджа",
    code: "KH",
    active: 1,
  },
  {
    name_de: "Kamerun",
    name_en: "Cameroon",
    name_fr: "Cameroun",
    name_it: "Camerun",
    name_nl: "Kameroen",
    name_pl: "Kamerun",
    name_es: "Camerún",
    name_pt: "Camarões",
    name_be: "",
    name_ru: "Камерун",
    code: "CM",
    active: 1,
  },
  {
    name_de: "Kanada",
    name_en: "Canada",
    name_fr: "Canada",
    name_it: "Canada",
    name_nl: "Canada",
    name_pl: "Kanada",
    name_es: "Canadá",
    name_pt: "Canadá",
    name_be: "",
    name_ru: "Канада",
    code: "CA",
    active: 1,
  },
  {
    name_de: "Kap Verde",
    name_en: "Cape Verde",
    name_fr: "Cap-Vert",
    name_it: "Capo Verde",
    name_nl: "Kaapverdië",
    name_pl: "Wyspy Zielonego Przylądka",
    name_es: "Cabo Verde",
    name_pt: "Cabo Verde",
    name_be: "",
    name_ru: "Кабо-Верде",
    code: "CV",
    active: 1,
  },
  {
    name_de: "Kasachstan",
    name_en: "Kazakhstan",
    name_fr: "Kazakhstan",
    name_it: "Kazakistan",
    name_nl: "Kazachstan",
    name_pl: "Kazachstan",
    name_es: "Kazajstán",
    name_pt: "Cazaquistão",
    name_be: "",
    name_ru: "Казахстан",
    code: "KZ",
    active: 1,
  },
  {
    name_de: "Katar",
    name_en: "Qatar",
    name_fr: "Qatar",
    name_it: "Qatar",
    name_nl: "Qatar",
    name_pl: "atar",
    name_es: "Qatar",
    name_pt: "Qatar",
    name_be: "",
    name_ru: "Катар",
    code: "QA",
    active: 1,
  },
  {
    name_de: "Kenia",
    name_en: "Kenya",
    name_fr: "Kenya",
    name_it: "Kenya",
    name_nl: "Kenia",
    name_pl: "Kenia",
    name_es: "Kenia",
    name_pt: "Quênia",
    name_be: "",
    name_ru: "Кения",
    code: "KE",
    active: 1,
  },
  {
    name_de: "Kirgisistan",
    name_en: "Kyrgyzstan",
    name_fr: "Kirghizistan",
    name_it: "Kirghizistan",
    name_nl: "Kirgizië",
    name_pl: "Kirgistan",
    name_es: "Kirguistán",
    name_pt: "Quirguistão",
    name_be: "",
    name_ru: "Кыргызстан",
    code: "KG",
    active: 1,
  },
  {
    name_de: "Kiribati",
    name_en: "Kiribati",
    name_fr: "Kiribati",
    name_it: "Kiribati",
    name_nl: "Kiribati",
    name_pl: "Kiribati",
    name_es: "Kiribati",
    name_pt: "Kiribati",
    name_be: "",
    name_ru: "Кирибати",
    code: "KI",
    active: 1,
  },
  {
    name_de: "Kokosinseln",
    name_en: "Cocos Islands",
    name_fr: "Cocos (Îles)",
    name_it: "Isole Cocos",
    name_nl: "Cocos Eilanden",
    name_pl: "Wyspy Kokosowe",
    name_es: "Islas Cocos",
    name_pt: "Ilhas Cocos",
    name_be: "",
    name_ru: "Кокосовые острова",
    code: "CC",
    active: 0,
  },
  {
    name_de: "Kolumbien",
    name_en: "Colombia",
    name_fr: "Colombie",
    name_it: "Colombia",
    name_nl: "Colombia",
    name_pl: "Kolumbia",
    name_es: "Colombia",
    name_pt: "Colômbia",
    name_be: "",
    name_ru: "Колумбия",
    code: "CO",
    active: 1,
  },
  {
    name_de: "Komoren",
    name_en: "Comoros",
    name_fr: "Comores",
    name_it: "Comore",
    name_nl: "Comoren",
    name_pl: "Komory",
    name_es: "Comoras",
    name_pt: "Comores",
    name_be: "",
    name_ru: "Коморские острова",
    code: "KM",
    active: 1,
  },
  {
    name_de: "Kongo",
    name_en: "Republic of the Congo",
    name_fr: "Congo",
    name_it: "Congo",
    name_nl: "Congo",
    name_pl: "Kongo",
    name_es: "Congo",
    name_pt: "Congo",
    name_be: "",
    name_ru: "Конго",
    code: "CG",
    active: 1,
  },
  {
    name_de: "Kosovo",
    name_en: "Kosovo",
    name_fr: "Kosovo",
    name_it: "Kosovo",
    name_nl: "Kosovo",
    name_pl: "Kosowo",
    name_es: "Kosovo",
    name_pt: "Kosovo",
    name_be: "",
    name_ru: "Косово",
    code: "XK",
    active: 1,
  },
  {
    name_de: "Kroatien",
    name_en: "Croatia",
    name_fr: "Croatie",
    name_it: "Croazia",
    name_nl: "Kroatië",
    name_pl: "Chorwacja",
    name_es: "Croacia",
    name_pt: "Croácia",
    name_be: "",
    name_ru: "Хорватия",
    code: "HR",
    active: 1,
  },
  {
    name_de: "Kuba",
    name_en: "Cuba",
    name_fr: "Cuba",
    name_it: "Cuba",
    name_nl: "Cuba",
    name_pl: "Kuba",
    name_es: "Cuba",
    name_pt: "Cuba",
    name_be: "",
    name_ru: "Куба",
    code: "CU",
    active: 1,
  },
  {
    name_de: "Kuwait",
    name_en: "Kuwait",
    name_fr: "Koweït",
    name_it: "Kuwait",
    name_nl: "Koeweit",
    name_pl: "Kuwejt",
    name_es: "Kuwait",
    name_pt: "Kuwait",
    name_be: "",
    name_ru: "Кувейт",
    code: "KW",
    active: 1,
  },
  {
    name_de: "Laos",
    name_en: "Laos",
    name_fr: "Laos",
    name_it: "Laos",
    name_nl: "Laos",
    name_pl: "Laos",
    name_es: "Laos",
    name_pt: "Laos",
    name_be: "",
    name_ru: "Лаос",
    code: "LA",
    active: 1,
  },
  {
    name_de: "Lesotho",
    name_en: "Lesotho",
    name_fr: "Lesotho",
    name_it: "Lesotho",
    name_nl: "Lesotho",
    name_pl: "Lesoto",
    name_es: "Lesoto",
    name_pt: "Lesoto",
    name_be: "",
    name_ru: "Лесото",
    code: "LS",
    active: 1,
  },
  {
    name_de: "Lettland",
    name_en: "Latvia",
    name_fr: "Lettonie",
    name_it: "Lettonia",
    name_nl: "Letland",
    name_pl: "Łotwa",
    name_es: "Letonia",
    name_pt: "Letónia",
    name_be: "",
    name_ru: "Латвия",
    code: "LV",
    active: 1,
  },
  {
    name_de: "Libanon",
    name_en: "Lebanon",
    name_fr: "Liban",
    name_it: "Libano",
    name_nl: "Libanon",
    name_pl: "Liban",
    name_es: "Líbano",
    name_pt: "Líbano",
    name_be: "",
    name_ru: "Ливан",
    code: "LB",
    active: 1,
  },
  {
    name_de: "Liberia",
    name_en: "Liberia",
    name_fr: "Libéria",
    name_it: "Liberia",
    name_nl: "Liberia",
    name_pl: "Liberia",
    name_es: "Liberia",
    name_pt: "Libéria",
    name_be: "",
    name_ru: "Либерия",
    code: "LR",
    active: 1,
  },
  {
    name_de: "Libyen",
    name_en: "Libya",
    name_fr: "Libye",
    name_it: "Libia",
    name_nl: "Libië",
    name_pl: "Libia",
    name_es: "Libia",
    name_pt: "Líbia",
    name_be: "",
    name_ru: "Ливия",
    code: "LY",
    active: 1,
  },
  {
    name_de: "Liechtenstein",
    name_en: "Liechtenstein",
    name_fr: "Liechtenstein",
    name_it: "Liechtenstein",
    name_nl: "Liechtenstein",
    name_pl: "Liechtenstein",
    name_es: "Liechtenstein",
    name_pt: "Liechtenstein",
    name_be: "",
    name_ru: "Лихтенштейн",
    code: "LI",
    active: 1,
  },
  {
    name_de: "Litauen",
    name_en: "Lithuania",
    name_fr: "Lituanie",
    name_it: "Lituania",
    name_nl: "Litouwen",
    name_pl: "Litwa",
    name_es: "Lituania",
    name_pt: "Lituânia",
    name_be: "",
    name_ru: "Литва",
    code: "LT",
    active: 1,
  },
  {
    name_de: "Luxemburg",
    name_en: "Luxembourg",
    name_fr: "Luxembourg",
    name_it: "Lussemburgo",
    name_nl: "Luxemburg",
    name_pl: "Luksemburg",
    name_es: "Luxemburgo",
    name_pt: "Luxemburgo",
    name_be: "",
    name_ru: "Люксембург",
    code: "LU",
    active: 1,
    sale: 1,
  },
  {
    name_de: "Macau",
    name_en: "Macau",
    name_fr: "Macao",
    name_it: "Macao",
    name_nl: "Macau",
    name_pl: "Makao",
    name_es: "Macao",
    name_pt: "Macau",
    name_be: "",
    name_ru: "Макао",
    code: "MO",
    active: 1,
  },
  {
    name_de: "Madagaskar",
    name_en: "Madagascar",
    name_fr: "Madagascar",
    name_it: "Madagascar",
    name_nl: "Madagaskar",
    name_pl: "Madagaskar",
    name_es: "Madagascar",
    name_pt: "Madagáscar",
    name_be: "",
    name_ru: "Мадагаскар",
    code: "MG",
    active: 1,
  },
  {
    name_de: "Malawi",
    name_en: "Malawi",
    name_fr: "Malawi",
    name_it: "Malawi",
    name_nl: "Malawi",
    name_pl: "Malawi",
    name_es: "Malaui",
    name_pt: "Malaui",
    name_be: "",
    name_ru: "Малави",
    code: "MW",
    active: 1,
  },
  {
    name_de: "Malaysia",
    name_en: "Malaysia",
    name_fr: "Malaisie",
    name_it: "Malesia",
    name_nl: "Maleisië",
    name_pl: "Malezja",
    name_es: "Malasia",
    name_pt: "Malásia",
    name_be: "",
    name_ru: "Малайзия",
    code: "MY",
    active: 1,
  },
  {
    name_de: "Malediven",
    name_en: "Maldives",
    name_fr: "Maldives",
    name_it: "Maldive",
    name_nl: "Malediven",
    name_pl: "Malediwy",
    name_es: "Maledivas",
    name_pt: "Maldivas",
    name_be: "",
    name_ru: "Мальдивские острова",
    code: "MV",
    active: 1,
  },
  {
    name_de: "Mali",
    name_en: "Mali",
    name_fr: "Mali",
    name_it: "Mali",
    name_nl: "Mali",
    name_pl: "Mali",
    name_es: "Mali",
    name_pt: "Mali",
    name_be: "",
    name_ru: "Мали",
    code: "ML",
    active: 1,
  },
  {
    name_de: "Malta",
    name_en: "Malta",
    name_fr: "Malte",
    name_it: "Malta",
    name_nl: "Malta",
    name_pl: "Malta",
    name_es: "Malta",
    name_pt: "Malta",
    name_be: "",
    name_ru: "Мальта",
    code: "MT",
    active: 1,
  },
  {
    name_de: "Marokko",
    name_en: "Morocco",
    name_fr: "Maroc",
    name_it: "Marocco",
    name_nl: "Marokko",
    name_pl: "Maroko",
    name_es: "Marruecos",
    name_pt: "Marrocos",
    name_be: "",
    name_ru: "Марокко",
    code: "MA",
    active: 1,
  },
  {
    name_de: "Marshallinseln",
    name_en: "Marshall Islands",
    name_fr: "Marshall (Îles)",
    name_it: "Isole Marshall",
    name_nl: "Marshalleilanden",
    name_pl: "Wyspy Marshalla",
    name_es: "Islas Marshall",
    name_pt: "Ilhas Marshall",
    name_be: "",
    name_ru: "Маршалловы Острова",
    code: "MH",
    active: 1,
  },
  {
    name_de: "Martinique",
    name_en: "Martinique",
    name_fr: "Martinique",
    name_it: "Martinica",
    name_nl: "Martinique",
    name_pl: "Martynika",
    name_es: "Martinica",
    name_pt: "Martinica",
    name_be: "",
    name_ru: "Мартиника",
    code: "MQ",
    active: 0,
  },
  {
    name_de: "Mauretanien",
    name_en: "Mauritania",
    name_fr: "Mauritanie",
    name_it: "Mauritania",
    name_nl: "Mauritanië",
    name_pl: "Mauretania",
    name_es: "Mauritania",
    name_pt: "Mauritânia",
    name_be: "",
    name_ru: "Мавритания",
    code: "MR",
    active: 1,
  },
  {
    name_de: "Mauritius",
    name_en: "Mauritius",
    name_fr: "Île Maurice",
    name_it: "Mauritius",
    name_nl: "Mauritius",
    name_pl: "Mauritius",
    name_es: "Mauricio",
    name_pt: "Maurícia",
    name_be: "",
    name_ru: "Маврикий",
    code: "MU",
    active: 1,
  },
  {
    name_de: "Mayotte",
    name_en: "Mayotte",
    name_fr: "Mayotte",
    name_it: "Mayotte",
    name_nl: "Mayotte",
    name_pl: "Majotta",
    name_es: "Mayotte",
    name_pt: "Mayotte",
    name_be: "",
    name_ru: "Майотта",
    code: "YT",
    active: 0,
  },
  {
    name_de: "Republik Nordmazedonien",
    name_en: "Republic of Northern Macedonia",
    name_fr: "République de Macédoine du Nord",
    name_it: "Repubblica della Macedonia del Nord",
    name_nl: "Republiek Noord-Macedonië",
    name_pl: "Republika Macedonii Północnej",
    name_es: "República de Macedonia del Norte",
    name_pt: "República da Macedónia do Norte",
    name_be: "",
    name_ru: "Республика Северная Македония",
    code: "MK",
    active: 1,
  },
  {
    name_de: "Mexiko",
    name_en: "Mexico",
    name_fr: "Mexique",
    name_it: "Messico",
    name_nl: "Mexico",
    name_pl: "Meksyk",
    name_es: "México",
    name_pt: "México",
    name_be: "",
    name_ru: "Мексика",
    code: "MX",
    active: 1,
  },
  {
    name_de: "Mikronesien",
    name_en: "Micronesia, Federated States of",
    name_fr: "Micronésie",
    name_it: "Micronesia",
    name_nl: "Micronesië",
    name_pl: "Mikronezja",
    name_es: "Micronesia",
    name_pt: "Micronésia",
    name_be: "",
    name_ru: "Микронезия",
    code: "FM",
    active: 1,
  },
  {
    name_de: "Moldawien",
    name_en: "Moldova",
    name_fr: "Moldavie",
    name_it: "Moldavia",
    name_nl: "Moldavië",
    name_pl: "Mołdawia",
    name_es: "Moldavia",
    name_pt: "Moldávia",
    name_be: "",
    name_ru: "Молдова",
    code: "MD",
    active: 1,
  },
  {
    name_de: "Monaco",
    name_en: "Monaco",
    name_fr: "Monaco",
    name_it: "Monaco",
    name_nl: "Monaco",
    name_pl: "Monako",
    name_es: "Mónaco",
    name_pt: "Monaco",
    name_be: "",
    name_ru: "Монако",
    code: "MC",
    active: 1,
  },
  {
    name_de: "Mongolei",
    name_en: "Mongolia",
    name_fr: "Mongolie",
    name_it: "Mongolia",
    name_nl: "Mongolië",
    name_pl: "Mongolia",
    name_es: "Mongolia",
    name_pt: "Mongólia",
    name_be: "",
    name_ru: "Монголия",
    code: "MN",
    active: 1,
  },
  {
    name_de: "Montenegro",
    name_en: "Montenegro",
    name_fr: "Monténégro",
    name_it: "Montenegro",
    name_nl: "Montenegro",
    name_pl: "Czarnogóra",
    name_es: "Montenegro",
    name_pt: "Montenegro",
    name_be: "",
    name_ru: "Черногория",
    code: "ME",
    active: 1,
  },
  {
    name_de: "Montserrat",
    name_en: "Montserrat",
    name_fr: "Montserrat",
    name_it: "Montserrat",
    name_nl: "Montserrat",
    name_pl: "Montserrat",
    name_es: "Montserrat",
    name_pt: "Montserrate",
    name_be: "",
    name_ru: "Монтсеррат",
    code: "MS",
    active: 0,
  },
  {
    name_de: "Mosambik",
    name_en: "Mozambique",
    name_fr: "Mozambique",
    name_it: "Mozambico",
    name_nl: "Mozambique",
    name_pl: "Mozambik",
    name_es: "Mozambique",
    name_pt: "Moçambique",
    name_be: "",
    name_ru: "мозамбикский",
    code: "MZ",
    active: 1,
  },
  {
    name_de: "Myanmar",
    name_en: "Burma",
    name_fr: "Myanmar",
    name_it: "Myanmar",
    name_nl: "Myanmar",
    name_pl: "Myanmar",
    name_es: "Myanmar",
    name_pt: "Mianmar",
    name_be: "",
    name_ru: "Мьянма",
    code: "MM",
    active: 1,
  },
  {
    name_de: "Namibia",
    name_en: "Namibia",
    name_fr: "Namibie",
    name_it: "Namibia",
    name_nl: "Namibië",
    name_pl: "Namibia",
    name_es: "Namibia",
    name_pt: "Namíbia",
    name_be: "",
    name_ru: "Намибия",
    code: "NA",
    active: 1,
  },
  {
    name_de: "Nauru",
    name_en: "Nauru",
    name_fr: "Nauru",
    name_it: "Nauru",
    name_nl: "Nauru",
    name_pl: "Nauru",
    name_es: "Nauru",
    name_pt: "Nauru",
    name_be: "",
    name_ru: "Науру",
    code: "NR",
    active: 1,
  },
  {
    name_de: "Nepal",
    name_en: "Nepal",
    name_fr: "Népal",
    name_it: "Nepal",
    name_nl: "Nepal",
    name_pl: "Nepal",
    name_es: "Nepal",
    name_pt: "Nepal",
    name_be: "",
    name_ru: "Непал",
    code: "NP",
    active: 1,
  },
  {
    name_de: "Neukaledonien",
    name_en: "New Caledonia",
    name_fr: "Nouvelle-Calédonie",
    name_it: "Nuova Caledonia",
    name_nl: "Nieuw-Caledonië",
    name_pl: "Nowa Kaledonia",
    name_es: "Nueva Caledonia",
    name_pt: "Nova Caledónia",
    name_be: "",
    name_ru: "Новая Каледония",
    code: "NC",
    active: 0,
  },
  {
    name_de: "Neuseeland",
    name_en: "New Zealand",
    name_fr: "Nouvelle-Zélande",
    name_it: "Nuova Zelanda",
    name_nl: "Nieuw-Zeeland",
    name_pl: "Nowa Zelandia",
    name_es: "Nueva Zelanda",
    name_pt: "Nova Zelândia",
    name_be: "",
    name_ru: "Новая Зеландия",
    code: "NZ",
    active: 1,
  },
  {
    name_de: "Nicaragua",
    name_en: "Nicaragua",
    name_fr: "Nicaragua",
    name_it: "Nicaragua",
    name_nl: "Nicaragua",
    name_pl: "Nikaragua",
    name_es: "Nicaragua",
    name_pt: "Nicarágua",
    name_be: "",
    name_ru: "Никарагуа",
    code: "NI",
    active: 1,
  },
  {
    name_de: "Niederlande",
    name_en: "Netherlands",
    name_fr: "Pays-Bas",
    name_it: "Paesi Bassi",
    name_nl: "Nederland",
    name_pl: "Holandia",
    name_es: "Países Bajos",
    name_pt: "Países Baixos",
    name_be: "",
    name_ru: "Нидерланды",
    code: "NL",
    active: 1,
    sale: 1,
  },
  {
    name_de: "Niederländische Antillen",
    name_en: "Netherlands Antilles",
    name_fr: "Antilles néerlandaises",
    name_it: "Antille Olandesi",
    name_nl: "Nederlandse Antillen",
    name_pl: "Antyle Holenderskie",
    name_es: "Antillas Neerlandesas",
    name_pt: "Antilhas Holandesas",
    name_be: "",
    name_ru: "Нидерландские Антильские острова",
    code: "AN",
    active: 0,
  },
  {
    name_de: "Niger",
    name_en: "Niger",
    name_fr: "Niger",
    name_it: "Niger",
    name_nl: "Niger",
    name_pl: "Niger",
    name_es: "Níger",
    name_pt: "Níger",
    name_be: "",
    name_ru: "Нигер",
    code: "NE",
    active: 1,
  },
  {
    name_de: "Nigeria",
    name_en: "Nigeria",
    name_fr: "Nigéria",
    name_it: "Nigeria",
    name_nl: "Nigeria",
    name_pl: "Nigeria",
    name_es: "Nigeria",
    name_pt: "Nigéria",
    name_be: "",
    name_ru: "Нигерия",
    code: "NG",
    active: 1,
  },
  {
    name_de: "Niue",
    name_en: "Niue",
    name_fr: "Nioué",
    name_it: "Niue",
    name_nl: "Niue",
    name_pl: "Niue",
    name_es: "Niue",
    name_pt: "Niue",
    name_be: "",
    name_ru: "Ниуэ",
    code: "NU",
    active: 0,
  },
  {
    name_de: "Nordkorea",
    name_en: "North Korea",
    name_fr: "Corée du Nord",
    name_it: "Corea del Nord",
    name_nl: "Noord-Korea",
    name_pl: "Korea Północna",
    name_es: "Corea del Norte",
    name_pt: "Coreia do Norte",
    name_be: "",
    name_ru: "Северная Корея",
    code: "KP",
    active: 1,
  },
  {
    name_de: "Nördliche Marianen",
    name_en: "Northern Mariana Islands",
    name_fr: "Mariannes du Nord (Îles)",
    name_it: "Isole Marianne Settentrionali",
    name_nl: "Noordelijke Marianen",
    name_pl: "Mariany Północne",
    name_es: "Islas Marianas del Norte",
    name_pt: "Ilhas Marianas do Norte",
    name_be: "",
    name_ru: "Северные Марианские острова",
    code: "MP",
    active: 0,
  },
  {
    name_de: "Norfolkinsel",
    name_en: "Norfolk Island",
    name_fr: "Île Norfolk",
    name_it: "Isola di Norfolk",
    name_nl: "Norfolk eiland",
    name_pl: "Wyspa Norfolk",
    name_es: "Isla Norfolk",
    name_pt: "Ilha Norfolk",
    name_be: "",
    name_ru: "Остров Норфолк",
    code: "NF",
    active: 0,
  },
  {
    name_de: "Norwegen",
    name_en: "Norway",
    name_fr: "Norvège",
    name_it: "Norvegia",
    name_nl: "Noorwegen",
    name_pl: "Norwegia",
    name_es: "Noruega",
    name_pt: "Noruega",
    name_be: "",
    name_ru: "Норвегия",
    code: "NO",
    active: 1,
  },
  {
    name_de: "Oman",
    name_en: "Oman",
    name_fr: "Oman",
    name_it: "Oman",
    name_nl: "Oman",
    name_pl: "Oman",
    name_es: "Omán",
    name_pt: "Omã",
    name_be: "",
    name_ru: "Оман",
    code: "OM",
    active: 1,
  },
  {
    name_de: "Osttimor",
    name_en: "Timor-Leste",
    name_fr: "Timor oriental",
    name_it: "Timor Est",
    name_nl: "Oost-Timor",
    name_pl: "Timor Wschodni",
    name_es: "Timor Oriental",
    name_pt: "Timor Leste",
    name_be: "",
    name_ru: "Восточный Тимор",
    code: "TL",
    active: 1,
  },
  {
    name_de: "Pakistan",
    name_en: "Pakistan",
    name_fr: "Pakistan",
    name_it: "Pakistan",
    name_nl: "Pakistan",
    name_pl: "Pakistan",
    name_es: "Paquistán",
    name_pt: "Paquistão",
    name_be: "",
    name_ru: "Пакистан",
    code: "PK",
    active: 1,
  },
  {
    name_de: "Palästina",
    name_en: "Palestine",
    name_fr: "Palestine",
    name_it: "Palestina",
    name_nl: "Palestina",
    name_pl: "Palestyna",
    name_es: "Palestina",
    name_pt: "Palestina",
    name_be: "",
    name_ru: "Палестина",
    code: "PS",
    active: 1,
  },
  {
    name_de: "Palau",
    name_en: "Palau",
    name_fr: "Palau",
    name_it: "Palau",
    name_nl: "Palau",
    name_pl: "Palau",
    name_es: "Palaos",
    name_pt: "Palau",
    name_be: "",
    name_ru: "Палау",
    code: "PW",
    active: 1,
  },
  {
    name_de: "Panama",
    name_en: "Panama",
    name_fr: "Panama",
    name_it: "Panama",
    name_nl: "Panama",
    name_pl: "Panama",
    name_es: "Panamá",
    name_pt: "Panamá",
    name_be: "",
    name_ru: "Панама",
    code: "PA",
    active: 1,
  },
  {
    name_de: "Papua-Neuguinea",
    name_en: "Papua New Guinea",
    name_fr: "Papouasie-Nouvelle-Guinée",
    name_it: "Papua Nuova Guinea",
    name_nl: "Papoea-Nieuw-Guinea",
    name_pl: "Papua Nowa Gwinea",
    name_es: "Papúa Nueva Guinea",
    name_pt: "Papua-Nova Guiné",
    name_be: "",
    name_ru: "Папуа - Новая Гвинея",
    code: "PG",
    active: 1,
  },
  {
    name_de: "Paraguay",
    name_en: "Paraguay",
    name_fr: "Paraguay",
    name_it: "Paraguay",
    name_nl: "Paraguay",
    name_pl: "Paragwaj",
    name_es: "Paraguay",
    name_pt: "Paraguai",
    name_be: "",
    name_ru: "Парагвай",
    code: "PY",
    active: 1,
  },
  {
    name_de: "Peru",
    name_en: "Peru",
    name_fr: "Pérou",
    name_it: "Perù",
    name_nl: "Peru",
    name_pl: "Peru",
    name_es: "Perú",
    name_pt: "Peru",
    name_be: "",
    name_ru: "Перу",
    code: "PE",
    active: 1,
  },
  {
    name_de: "Philippinen",
    name_en: "Philippines",
    name_fr: "Philippines",
    name_it: "Filippine",
    name_nl: "Filippijnen",
    name_pl: "Filipiny",
    name_es: "Filipinas",
    name_pt: "Filipinas",
    name_be: "",
    name_ru: "Филиппины",
    code: "PH",
    active: 1,
  },
  {
    name_de: "Pitcairninseln",
    name_en: "Pitcairn Islands",
    name_fr: "Pitcairn (Îles)",
    name_it: "Isole Pitcairn",
    name_nl: "Pitcairneilanden",
    name_pl: "Wyspy Pitcairn",
    name_es: "Islas Pitcairn",
    name_pt: "Ilhas Pitcairn",
    name_be: "",
    name_ru: "острова Питкэрн",
    code: "PN",
    active: 0,
  },
  {
    name_de: "Polen",
    name_en: "Poland",
    name_fr: "Pologne",
    name_it: "Polonia",
    name_nl: "Polen",
    name_pl: "Polska",
    name_es: "Polonia",
    name_pt: "Polônia",
    name_be: "",
    name_ru: "Польша",
    code: "PL",
    active: 1,
  },
  {
    name_de: "Portugal",
    name_en: "Portugal",
    name_fr: "Portugal",
    name_it: "Portogallo",
    name_nl: "Portugal",
    name_pl: "Portugalia",
    name_es: "Portugal",
    name_pt: "Portugal",
    name_be: "",
    name_ru: "Португалия",
    code: "PT",
    active: 1,
  },
  {
    name_de: "Puerto Rico",
    name_en: "Puerto Rico",
    name_fr: "Porto Rico",
    name_it: "Porto Rico",
    name_nl: "Puerto Rico",
    name_pl: "Portoryko",
    name_es: "Puerto Rico",
    name_pt: "Porto Rico",
    name_be: "",
    name_ru: "Пуэрто-Рико",
    code: "PR",
    active: 0,
  },
  {
    name_de: "Réunion",
    name_en: "Reunion",
    name_fr: "Réunion",
    name_it: "Riunione",
    name_nl: "Réunion",
    name_pl: "Reunion",
    name_es: "Reunión",
    name_pt: "Reunião",
    name_be: "",
    name_ru: "Реюньон",
    code: "RE",
    active: 0,
  },
  {
    name_de: "Ruanda",
    name_en: "Rwanda",
    name_fr: "Rwanda",
    name_it: "Ruanda",
    name_nl: "Rwanda",
    name_pl: "Ruanda",
    name_es: "Ruanda",
    name_pt: "Ruanda",
    name_be: "",
    name_ru: "Руанда",
    code: "RW",
    active: 1,
  },
  {
    name_de: "Rumänien",
    name_en: "Romania",
    name_fr: "Roumanie",
    name_it: "Romania",
    name_nl: "Roemenië",
    name_pl: "Rumunia",
    name_es: "Rumania",
    name_pt: "Roménia",
    name_be: "",
    name_ru: "Румыния",
    code: "RO",
    active: 1,
  },
  {
    name_de: "Russland",
    name_en: "Russia",
    name_fr: "Russie",
    name_it: "Russia",
    name_nl: "Rusland",
    name_pl: "Rosje",
    name_es: "Rusia",
    name_pt: "Rússia",
    name_be: "",
    name_ru: "Россия",
    code: "RU",
    active: 1,
  },
  {
    name_de: "Saint Martin",
    name_en: "Saint Martin",
    name_fr: "Saint Martin",
    name_it: "San Martino",
    name_nl: "Sint Maarten",
    name_pl: "Święty Marcin",
    name_es: "San Martín",
    name_pt: "São Martinho",
    name_be: "",
    name_ru: "Сент-Мартин",
    code: "MF",
    active: 0,
  },
  {
    name_de: "Salomonen",
    name_en: "Solomon Islands",
    name_fr: "Îles Salomon",
    name_it: "Isole Salomone",
    name_nl: "Salomonseilanden",
    name_pl: "Wyspy Salomona",
    name_es: "Islas Salomón",
    name_pt: "Ilhas Salomão",
    name_be: "",
    name_ru: "Соломоновы Острова",
    code: "SB",
    active: 1,
  },
  {
    name_de: "Sambia",
    name_en: "Zambia",
    name_fr: "Zambie",
    name_it: "Zambia",
    name_nl: "Zambia",
    name_pl: "Zambia",
    name_es: "Zambia",
    name_pt: "Zâmbia",
    name_be: "",
    name_ru: "Замбия",
    code: "ZM",
    active: 1,
  },
  {
    name_de: "Samoa",
    name_en: "Samoa",
    name_fr: "Samoa",
    name_it: "Samoa",
    name_nl: "Samoa",
    name_pl: "Samoa",
    name_es: "Samoa",
    name_pt: "Samoa",
    name_be: "",
    name_ru: "Самоа",
    code: "WS",
    active: 1,
  },
  {
    name_de: "San Marino",
    name_en: "San Marino",
    name_fr: "Saint-Marin",
    name_it: "San Marino",
    name_nl: "San Marino",
    name_pl: "San Marino",
    name_es: "San Marino",
    name_pt: "São Marinho",
    name_be: "",
    name_ru: "Сан-Марино",
    code: "SM",
    active: 1,
  },
  {
    name_de: "Sankt Bartholomäus",
    name_en: "Saint Barthelemy",
    name_fr: "Saint-Barthélemy",
    name_it: "San Bartolomeo",
    name_nl: "Sint-Bartholomeus",
    name_pl: "Święty Bartłomiej",
    name_es: "San Bartolomé",
    name_pt: "São Bartolomeu",
    name_be: "",
    name_ru: "Святой Варфоломей",
    code: "BL",
    active: 0,
  },
  {
    name_de: "São Tomé und Príncipe",
    name_en: "Sao Tome and Principe",
    name_fr: "São Tomé e Príncipe",
    name_it: "São Tomé e Príncipe",
    name_nl: "São Tomé en Príncipe",
    name_pl: "Wyspy Świętego Tomasza i Książęca",
    name_es: "Santo Tomé y Príncipe",
    name_pt: "São Tomé e Príncipe",
    name_be: "",
    name_ru: "Сан-Томе и Принсипи",
    code: "ST",
    active: 1,
  },
  {
    name_de: "Saudi-Arabien",
    name_en: "Saudi Arabia",
    name_fr: "Arabie saoudite",
    name_it: "Arabia Saudita",
    name_nl: "Saoedi-Arabië",
    name_pl: "Arabia Saudyjska",
    name_es: "Arabia Saudita",
    name_pt: "Arábia Saudita",
    name_be: "",
    name_ru: "Саудовская Аравия",
    code: "SA",
    active: 1,
  },
  {
    name_de: "Schweden",
    name_en: "Sweden",
    name_fr: "Suède",
    name_it: "Svezia",
    name_nl: "Zweden",
    name_pl: "Szwecja",
    name_es: "Suecia",
    name_pt: "Suécia",
    name_be: "",
    name_ru: "Швеция",
    code: "SE",
    active: 1,
  },

  {
    name_de: "Senegal",
    name_en: "Senegal",
    name_fr: "Sénégal",
    name_it: "Senegal",
    name_nl: "Senegal",
    name_pl: "Senegal",
    name_es: "Senegal",
    name_pt: "Senegal",
    name_be: "",
    name_ru: "Сенегал",
    code: "SN",
    active: 1,
  },
  {
    name_de: "Serbien",
    name_en: "Serbia",
    name_fr: "Serbie",
    name_it: "Serbia",
    name_nl: "Servië",
    name_pl: "Serbia",
    name_es: "Serbia",
    name_pt: "Sérvia",
    name_be: "",
    name_ru: "Сербия",
    code: "RS",
    active: 1,
  },
  {
    name_de: "Seychellen",
    name_en: "Seychelles",
    name_fr: "Seychelles",
    name_it: "Seychelles",
    name_nl: "Seychellen",
    name_pl: "Seszele",
    name_es: "Seychelles",
    name_pt: "Seychelles",
    name_be: "",
    name_ru: "Сейшельские Острова",
    code: "SC",
    active: 1,
  },
  {
    name_de: "Sierra Leone",
    name_en: "Sierra Leone",
    name_fr: "Sierra Leone",
    name_it: "Sierra Leone",
    name_nl: "Sierra Leone",
    name_pl: "Sierra Leone",
    name_es: "Sierra Leona",
    name_pt: "Serra Leoa",
    name_be: "",
    name_ru: "Сьерра-Леоне",
    code: "SL",
    active: 1,
  },
  {
    name_de: "Simbabwe",
    name_en: "Zimbabwe",
    name_fr: "Zimbabwe",
    name_it: "Zimbabwe",
    name_nl: "Zimbabwe",
    name_pl: "Zimbabwe",
    name_es: "Zimbabwe",
    name_pt: "Zimbabwe",
    name_be: "",
    name_ru: "Зимбабве",
    code: "ZW",
    active: 1,
  },
  {
    name_de: "Singapur",
    name_en: "Singapore",
    name_fr: "Singapour",
    name_it: "Singapore",
    name_nl: "Singapore",
    name_pl: "Singapur",
    name_es: "Singapur",
    name_pt: "Cingapura",
    name_be: "",
    name_ru: "Сингапур",
    code: "SG",
    active: 1,
  },
  {
    name_de: "Sint Maarten",
    name_en: "Sint Maarten",
    name_fr: "Saint-Martin",
    name_it: "Sint Maarten",
    name_nl: "Sint Maarten",
    name_pl: "Sint Maarten",
    name_es: "San Martín",
    name_pt: "Sint Maarten",
    name_be: "",
    name_ru: "Синт-Мартен",
    code: "SX",
    active: 0,
  },
  {
    name_de: "Slowakei",
    name_en: "Slovakia",
    name_fr: "Slovaquie",
    name_it: "Slovacchia",
    name_nl: "Slowakije",
    name_pl: "Słowacja",
    name_es: "Eslovaquia",
    name_pt: "Eslováquia",
    name_be: "",
    name_ru: "Словакия",
    code: "SK",
    active: 1,
  },
  {
    name_de: "Slowenien",
    name_en: "Slovenia",
    name_fr: "Slovénie",
    name_it: "Slovenia",
    name_nl: "Slovenië",
    name_pl: "Słowenia",
    name_es: "Eslovenia",
    name_pt: "Eslovênia",
    name_be: "",
    name_ru: "Словения",
    code: "SI",
    active: 1,
  },
  {
    name_de: "Somalia",
    name_en: "Somalia",
    name_fr: "Somalie",
    name_it: "Somalia",
    name_nl: "Somalië",
    name_pl: "Somalia",
    name_es: "Somalia",
    name_pt: "Somália",
    name_be: "",
    name_ru: "Сомали",
    code: "SO",
    active: 1,
  },
  {
    name_de: "Spanien",
    name_en: "Spain",
    name_fr: "Espagne",
    name_it: "Spagna",
    name_nl: "Spanje",
    name_pl: "Hiszpania",
    name_es: "España",
    name_pt: "Espanha",
    name_be: "",
    name_ru: "Испания",
    code: "ES",
    active: 1,
  },
  {
    name_de: "Sri Lanka",
    name_en: "Sri Lanka",
    name_fr: "Sri Lanka",
    name_it: "Sri Lanka",
    name_nl: "Sri Lanka",
    name_pl: "Sri Lanka",
    name_es: "Sri Lanka",
    name_pt: "Sri Lanka",
    name_be: "",
    name_ru: "Шри-Ланка",
    code: "LK",
    active: 1,
  },
  {
    name_de: "St. Helena",
    name_en: "Saint Helena, Ascension and Tristan da Cunha",
    name_fr: "Sainte-Hélène",
    name_it: "Sant'Elena",
    name_nl: "St. Helena",
    name_pl: "Św. Helena",
    name_es: "Santa Elena",
    name_pt: "Santa Helena",
    name_be: "",
    name_ru: "Елены",
    code: "SH",
    active: 0,
  },
  {
    name_de: "St. Kitts und Nevis",
    name_en: "Saint Kitts and Nevis",
    name_fr: "Saint-Kitts-et-Nevis",
    name_it: "Saint Kitts e Nevis",
    name_nl: "Saint Kitts en Nevis",
    name_pl: "Saint Kitts i Nevis",
    name_es: "San Cristóbal y Nieves",
    name_pt: "São Cristóvão e Nevis",
    name_be: "",
    name_ru: "Сент-Китс и Невис",
    code: "KN",
    active: 1,
  },
  {
    name_de: "St. Lucia",
    name_en: "Saint Lucia",
    name_fr: "Sainte-Lucie",
    name_it: "Santa Lucia",
    name_nl: "St. Lucia",
    name_pl: "Łucja",
    name_es: "Santa Lucía",
    name_pt: "Santa Lúcia",
    name_be: "",
    name_ru: "Сент-Люсия",
    code: "LC",
    active: 1,
  },
  {
    name_de: "St. Pierre und Miquelon",
    name_en: "Saint Pierre and Miquelon",
    name_fr: "Saint-Pierre-et-Miquelon",
    name_it: "Saint-Pierre e Miquelon",
    name_nl: "St. Pierre en Miquelon",
    name_pl: "Pierre i Miquelon",
    name_es: "San Pedro y Miquelón",
    name_pt: "São Pedro e Miquelon",
    name_be: "",
    name_ru: "Сент-Пьер и Микелон",
    code: "PM",
    active: 0,
  },
  {
    name_de: "St. Vincent und die Grenadinen",
    name_en: "Saint Vincent and the Grenadines",
    name_fr: "Saint-Vincent-et-les Grenadines",
    name_it: "San Vincenzo e le Grenadine",
    name_nl: "Vincentius en de Grenadines",
    name_pl: "Vincent i Grenadyny",
    name_es: "San Vicente y las Granadinas",
    name_pt: "São Vicente e Granadinas",
    name_be: "",
    name_ru: "Сент-Винсент и Гренадины",
    code: "VC",
    active: 1,
  },
  {
    name_de: "Südafrika",
    name_en: "South Africa",
    name_fr: "Afrique du Sud",
    name_it: "Sudafrica",
    name_nl: "Zuid-Afrika",
    name_pl: "Republika Południowej Afryki",
    name_es: "Sudáfrica",
    name_pt: "África do Sul",
    name_be: "",
    name_ru: "Южная Африка",
    code: "ZA",
    active: 1,
  },
  {
    name_de: "Sudan",
    name_en: "Sudan",
    name_fr: "Soudan",
    name_it: "Sudan",
    name_nl: "Soedan",
    name_pl: "Sudan",
    name_es: "Sudán",
    name_pt: "Sudão",
    name_be: "",
    name_ru: "Судан",
    code: "SD",
    active: 1,
  },
  {
    name_de: "Südgeorgien und die Südlichen Sandwichinseln",
    name_en: "South Georgia and South Sandwich Islands",
    name_fr: "Géorgie du Sud et îles Sandwich du Sud",
    name_it: "Georgia del Sud e Isole Sandwich del Sud",
    name_nl: "Zuid-Georgië en de Zuidelijke Sandwicheilanden",
    name_pl: "Południowa Gruzja i Wyspy Sandwich Południowy",
    name_es: "Georgia del Sur y las Islas Sandwich del Sur",
    name_pt: "Geórgia do Sul e Ilhas Sandwich do Sul",
    name_be: "",
    name_ru: "Южная Георгия и Южные Сандвичевы острова",
    code: "GS",
    active: 0,
  },
  {
    name_de: "Südkorea",
    name_en: "South Korea",
    name_fr: "Corée du Sud",
    name_it: "Corea del Sud",
    name_nl: "Zuid-Korea",
    name_pl: "Korea Południowa",
    name_es: "Corea del Sur",
    name_pt: "Coreia do Sul",
    name_be: "",
    name_ru: "Южная Корея",
    code: "KR",
    active: 1,
  },
  {
    name_de: "Südsudan",
    name_en: "South Sudan",
    name_fr: "Sud-Soudan",
    name_it: "Sudan meridionale",
    name_nl: "Zuid-Soedan",
    name_pl: "Południowy Sudan",
    name_es: "Sudán del Sur",
    name_pt: "Sul do Sudão",
    name_be: "",
    name_ru: "Южный Судан",
    code: "SS",
    active: 1,
  },
  {
    name_de: "Suriname",
    name_en: "Suriname",
    name_fr: "Suriname",
    name_it: "Suriname",
    name_nl: "Suriname",
    name_pl: "Surinam",
    name_es: "Surinam",
    name_pt: "Suriname",
    name_be: "",
    name_ru: "суринам",
    code: "SR",
    active: 1,
  },
  {
    name_de: "Svalbard und Jan Mayen",
    name_en: "Svalbard",
    name_fr: "Svalbard et Jan Mayen",
    name_it: "Svalbard e Jan Mayen",
    name_nl: "Spitsbergen en Jan Mayen",
    name_pl: "Svalbard i Jan Mayen",
    name_es: "Svalbard y Jan Mayen",
    name_pt: "Svalbard e Jan Mayen",
    name_be: "",
    name_ru: "Свальбард и Ян Майен",
    code: "SJ",
    active: 0,
  },
  {
    name_de: "Eswatini",
    name_en: "Eswatini",
    name_fr: "Eswatini",
    name_it: "Eswatini",
    name_nl: "Eswatini",
    name_pl: "Eswatini",
    name_es: "Eswatini",
    name_pt: "Eswatini",
    name_be: "",
    name_ru: "Эсватини",
    code: "SZ",
    active: 1,
  },
  {
    name_de: "Syrien",
    name_en: "Syria",
    name_fr: "Syrie",
    name_it: "Siria",
    name_nl: "Syrië",
    name_pl: "Syria",
    name_es: "Siria",
    name_pt: "Síria",
    name_be: "",
    name_ru: "Сирия",
    code: "SY",
    active: 1,
  },
  {
    name_de: "Tadschikistan",
    name_en: "Tajikistan",
    name_fr: "Tadjikistan",
    name_it: "Tagikistan",
    name_nl: "Tadzjikistan",
    name_pl: "Tadżykistan",
    name_es: "Tayikistán",
    name_pt: "Tajiquistão",
    name_be: "",
    name_ru: "Таджикистан",
    code: "TJ",
    active: 1,
  },
  {
    name_de: "Taiwan",
    name_en: "Taiwan",
    name_fr: "Taïwan",
    name_it: "Taiwan",
    name_nl: "Taiwan",
    name_pl: "Tajwan",
    name_es: "Taiwán",
    name_pt: "Taiwan",
    name_be: "",
    name_ru: "Тайвань",
    code: "TW",
    active: 1,
  },
  {
    name_de: "Tansania",
    name_en: "Tanzania",
    name_fr: "Tanzanie",
    name_it: "Tanzania",
    name_nl: "Tanzania",
    name_pl: "Tanzania",
    name_es: "Tanzania",
    name_pt: "Tanzânia",
    name_be: "",
    name_ru: "Танзания",
    code: "TZ",
    active: 1,
  },
  {
    name_de: "Thailand",
    name_en: "Thailand",
    name_fr: "Thaïlande",
    name_it: "Thailandia",
    name_nl: "Thailand",
    name_pl: "Tajlandia",
    name_es: "Tailandia",
    name_pt: "Tailândia",
    name_be: "",
    name_ru: "Таиланд",
    code: "TH",
    active: 1,
  },
  {
    name_de: "Togo",
    name_en: "Togo",
    name_fr: "Togo",
    name_it: "Togo",
    name_nl: "Togo",
    name_pl: "Togo",
    name_es: "Togo",
    name_pt: "Togo",
    name_be: "",
    name_ru: "Того",
    code: "TG",
    active: 1,
  },
  {
    name_de: "Tokelau",
    name_en: "Tokelau",
    name_fr: "Tokélaou",
    name_it: "Tokelau",
    name_nl: "Tokelau",
    name_pl: "Tokilau",
    name_es: "Tokelau",
    name_pt: "Tokelau",
    name_be: "",
    name_ru: "Токелау",
    code: "TK",
    active: 0,
  },
  {
    name_de: "Tonga",
    name_en: "Tonga",
    name_fr: "Tonga",
    name_it: "Tonga",
    name_nl: "Tonga",
    name_pl: "Tonga",
    name_es: "Tonga",
    name_pt: "Tonga",
    name_be: "",
    name_ru: "Тонга",
    code: "TO",
    active: 1,
  },
  {
    name_de: "Trinidad und Tobago",
    name_en: "Trinidad and Tobago",
    name_fr: "Trinité-et-Tobago",
    name_it: "Trinidad e Tobago",
    name_nl: "Trinidad en Tobago",
    name_pl: "Trynidad i Tobago",
    name_es: "Trinidad y Tobago",
    name_pt: "Trinidad e Tobago",
    name_be: "",
    name_ru: "Тринидад и Тобаго",
    code: "TT",
    active: 1,
  },
  {
    name_de: "Tschad",
    name_en: "Chad",
    name_fr: "Tchad",
    name_it: "Ciad",
    name_nl: "Tsjaad",
    name_pl: "Czad",
    name_es: "Chad",
    name_pt: "Chade",
    name_be: "",
    name_ru: "Чад",
    code: "TD",
    active: 1,
  },
  {
    name_de: "Tschechien",
    name_en: "Czech Republic",
    name_fr: "Tchéquie",
    name_it: "Cecoslovacchia",
    name_nl: "Tsjechië",
    name_pl: "Czechy",
    name_es: "República Checa",
    name_pt: "República Checa",
    name_be: "",
    name_ru: "Чехия",
    code: "CZ",
    active: 1,
  },
  {
    name_de: "Tunesien",
    name_en: "Tunisia",
    name_fr: "Tunisie",
    name_it: "Tunisia",
    name_nl: "Tunesië",
    name_pl: "Tunezja",
    name_es: "Túnez",
    name_pt: "Tunísia",
    name_be: "",
    name_ru: "Тунис",
    code: "TN",
    active: 1,
  },
  {
    name_de: "Turkmenistan",
    name_en: "Turkmenistan",
    name_fr: "Turkménistan",
    name_it: "Turkmenistan",
    name_nl: "Turkmenistan",
    name_pl: "Turkmenistan",
    name_es: "Turkmenistán",
    name_pt: "Turquemenistão",
    name_be: "",
    name_ru: "Туркменистан",
    code: "TM",
    active: 1,
  },
  {
    name_de: "Turks und Caicosinseln",
    name_en: "Turks and Caicos Islands",
    name_fr: "Turks et Caïques (Îles)",
    name_it: "Isole Turks e Caicos",
    name_nl: "Turks- en Caicoseilanden",
    name_pl: "Wyspy Turks i Caicos",
    name_es: "Islas Turcas y Caicos",
    name_pt: "Ilhas Turcas e Caicos",
    name_be: "",
    name_ru: "Острова Тёркс и Кайкос",
    code: "TC",
    active: 0,
  },
  {
    name_de: "Tuvalu",
    name_en: "Tuvalu",
    name_fr: "Tuvalu",
    name_it: "Tuvalu",
    name_nl: "Tuvalu",
    name_pl: "Tuvalu",
    name_es: "Tuvalu",
    name_pt: "Tuvalu",
    name_be: "",
    name_ru: "Тувалу",
    code: "TV",
    active: 1,
  },
  {
    name_de: "Uganda",
    name_en: "Uganda",
    name_fr: "Ouganda",
    name_it: "Uganda",
    name_nl: "Oeganda",
    name_pl: "Uganda",
    name_es: "Uganda",
    name_pt: "Uganda",
    name_be: "",
    name_ru: "Уганда",
    code: "UG",
    active: 1,
  },
  {
    name_de: "Ukraine",
    name_en: "Ukraine",
    name_fr: "Ukraine",
    name_it: "Ucraina",
    name_nl: "Oekraïne",
    name_pl: "Ukraina",
    name_es: "Ucrania",
    name_pt: "Ucrânia",
    name_be: "",
    name_ru: "Украина",
    code: "UA",
    active: 1,
  },
  {
    name_de: "Ungarn",
    name_en: "Hungary",
    name_fr: "Hongrie",
    name_it: "Ungheria",
    name_nl: "Hongarije",
    name_pl: "Węgry",
    name_es: "Hungría",
    name_pt: "Hungria",
    name_be: "",
    name_ru: "Венгрия",
    code: "HU",
    active: 1,
  },
  {
    name_de: "Uruguay",
    name_en: "Uruguay",
    name_fr: "Uruguay",
    name_it: "Uruguay",
    name_nl: "Uruguay",
    name_pl: "Urugwaj",
    name_es: "Uruguay",
    name_pt: "Uruguai",
    name_be: "",
    name_ru: "Уругвай",
    code: "UY",
    active: 1,
  },
  {
    name_de: "Usbekistan",
    name_en: "Uzbekistan",
    name_fr: "Ouzbékistan",
    name_it: "Uzbekistan",
    name_nl: "Oezbekistan",
    name_pl: "Uzbekistan",
    name_es: "Uzbekistán",
    name_pt: "Uzbequistão",
    name_be: "",
    name_ru: "Узбекистан",
    code: "UZ",
    active: 1,
  },
  {
    name_de: "Vanuatu",
    name_en: "Vanuatu",
    name_fr: "Vanuatu",
    name_it: "Vanuatu",
    name_nl: "Vanuatu",
    name_pl: "Vanuatu",
    name_es: "Vanuatu",
    name_pt: "Vanuatu",
    name_be: "",
    name_ru: "Вануату",
    code: "VU",
    active: 1,
  },
  {
    name_de: "Vatikanstadt",
    name_en: "Holy See (Vatican City)",
    name_fr: "Cité du Vatican",
    name_it: "Città del Vaticano",
    name_nl: "Vaticaanstad",
    name_pl: "Państwo Watykańskie",
    name_es: "Ciudad del Vaticano",
    name_pt: "Cidade do Vaticano",
    name_be: "",
    name_ru: "Ватикан",
    code: "VA",
    active: 1,
  },
  {
    name_de: "Venezuela",
    name_en: "Venezuela",
    name_fr: "Venezuela",
    name_it: "Venezuela",
    name_nl: "Venezuela",
    name_pl: "Venezuela",
    name_es: "Venezuela",
    name_pt: "Venezuela",
    name_be: "",
    name_ru: "Венесуэла",
    code: "VE",
    active: 1,
  },
  {
    name_de: "Vereinigte Arabische Emirate",
    name_en: "United Arab Emirates",
    name_fr: "Émirats arabes unis",
    name_it: "Emirati Arabi Uniti",
    name_nl: "Verenigde Arabische Emiraten",
    name_pl: "Zjednoczone Emiraty Arabskie",
    name_es: "Emiratos Árabes Unidos",
    name_pt: "Emirados Árabes Unidos",
    name_be: "",
    name_ru: "Объединенные Арабские Эмираты",
    code: "AE",
    active: 1,
  },
  {
    name_de: "Vereinigte Staaten von Amerika",
    name_en: "United States",
    name_fr: "États-Unis d'Amérique",
    name_it: "Stati Uniti d'America",
    name_nl: "Verenigde Staten van Amerika",
    name_pl: "Stany Zjednoczone Ameryki",
    name_es: "Estados Unidos de América",
    name_pt: "Estados Unidos da América",
    name_be: "",
    name_ru: "Соединенные Штаты Америки",
    code: "US",
    active: 1,
  },
  {
    name_de: "Vereinigtes Königreich",
    name_en: "United Kingdom",
    name_fr: "Royaume-Uni",
    name_it: "Regno Unito",
    name_nl: "Verenigd Koninkrijk",
    name_pl: "Zjednoczone Królestwo",
    name_es: "Reino Unido",
    name_pt: "Reino Unido",
    name_be: "",
    name_ru: "Великобритания",
    code: "GB",
    active: 1,
  },
  {
    name_de: "Vietnam",
    name_en: "Vietnam",
    name_fr: "Vietnam",
    name_it: "Vietnam",
    name_nl: "Vietnam",
    name_pl: "Wietnam",
    name_es: "Vietnam",
    name_pt: "Vietname",
    name_be: "",
    name_ru: "Вьетнам",
    code: "VN",
    active: 1,
  },
  {
    name_de: "Wallis und Futuna",
    name_en: "Wallis and Futuna",
    name_fr: "Wallis et Futuna",
    name_it: "Wallis e Futuna",
    name_nl: "Wallis en Futuna",
    name_pl: "Wallis i Futuna",
    name_es: "Wallis y Futuna",
    name_pt: "Wallis e Futuna",
    name_be: "",
    name_ru: "Уоллис и Футуна",
    code: "WF",
    active: 0,
  },
  {
    name_de: "Weihnachtsinsel",
    name_en: "Christmas Island",
    name_fr: "Christmas (Île Christmas)",
    name_it: "Isola di Natale",
    name_nl: "Kerst eiland",
    name_pl: "Wyspa Bożego Narodzenia",
    name_es: "Isla de Navidad",
    name_pt: "Ilha do Natal",
    name_be: "",
    name_ru: "остров Рождества",
    code: "CX",
    active: 0,
  },
  {
    name_de: "Westsahara",
    name_en: "Western Sahara",
    name_fr: "Sahara occidental",
    name_it: "Sahara occidentale",
    name_nl: "Westelijke Sahara",
    name_pl: "Sahara Zachodnia",
    name_es: "Sáhara Occidental",
    name_pt: "Sahara Ocidental",
    name_be: "",
    name_ru: "Западная Сахара",
    code: "EH",
    active: 0,
  },
  {
    name_de: "Zentralafrikanische Republik",
    name_en: "Central African Republic",
    name_fr: "République centrafricaine",
    name_it: "Repubblica Centrafricana",
    name_nl: "Centraal-Afrikaanse Republiek",
    name_pl: "Republika Środkowoafrykańska",
    name_es: "República Centroafricana",
    name_pt: "República Centro-Africana",
    name_be: "",
    name_ru: "Центральноафриканская Республика",
    code: "CF",
    active: 1,
  },
  {
    name_de: "Zypern",
    name_en: "Cyprus",
    name_fr: "Chypre",
    name_it: "Cipro",
    name_nl: "Cyprus",
    name_pl: "Cypr",
    name_es: "Chipre",
    name_pt: "Chipre",
    name_be: "",
    name_ru: "Кипр",
    code: "CY",
    active: 1,
  },
  {
    name_de: "A0 - Testland ",
    name_en: "A0 - Test Country",
    name_fr: "A0-FR",
    name_it: "A0-IT",
    name_nl: "A0-NL",
    name_pl: "A0-PL",
    name_es: "A0-ES",
    name_pt: "A0-PT",
    name_be: "A0-BE",
    name_ru: "A0-RU",
    code: "A0",
    active: 0,
  },
  {
    name_de: "Bonaire",
    name_en: "Bonaire",
    name_fr: "Bonaire",
    name_it: "Bonaire",
    name_nl: "Bonaire",
    name_pl: "Bonaire",
    name_es: "Bonaire",
    name_pt: "Bonaire",
    name_be: "",
    name_ru: "Бонэйр",
    code: "BQ",
    active: 0,
  },
  {
    name_de: "AC",
    name_en: "AC Testland",
    name_fr: null,
    name_it: null,
    name_nl: null,
    name_pl: null,
    name_es: null,
    name_pt: null,
    name_be: null,
    name_ru: "",
    code: "AC",
    active: 0,
  },
  {
    name_de: "AB",
    name_en: "Testcountry",
    name_fr: "Pays test",
    name_it: "Paese di prova",
    name_nl: "testland",
    name_pl: "Praj testowy",
    name_es: "País de prueba",
    name_pt: "País teste",
    name_be: "",
    name_ru: "тестовая страна",
    code: "AB",
    active: 0,
  },
];
