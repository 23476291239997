import React, { useState } from "react";
import checkedcheckbox from "../assets/checkedcheckbox.png";
import emptycheckbox from "../assets/emptycheckbox.png";
import disabledcheckedcheckbox from "../assets/disabledcheckedcheckbox.png";
import disabledemptycheckbox from "../assets/disabledemptycheckbox.png";

export default function CheckBox(props) {
  const { disabled, checked, onChange, mandatory } = props;

  let checkedcheckbox =
    "https://holiday.placelogg.com/holidaymedia/checkedcheckbox.png";
  let emptycheckbox =
    "https://holiday.placelogg.com/holidaymedia/emptycheckbox.png";
  let disabledcheckedcheckbox =
    "https://holiday.placelogg.com/holidaymedia/disabledcheckedcheckbox.png";
  let disabledemptycheckbox =
    "https://holiday.placelogg.com/holidaymedia/disabledemptycheckbox.png";

  return (
    <div
      style={{
        marginTop: 4,
        paddingRight: 4,
        display: "flex",
        alignItems: "start",
      }}
      onClick={() => {
        if (!disabled) onChange(!checked);
      }}
    >
      <div>
        <img
          style={{ height: 28 }}
          src={
            disabled
              ? checked
                ? disabledcheckedcheckbox
                : disabledemptycheckbox
              : checked
              ? checkedcheckbox
              : emptycheckbox
          }
        />
      </div>
      <div>
        {mandatory && <sup style={{ marginLeft: 0, marginRight: 4 }}>*</sup>}
      </div>
    </div>
  );
}
