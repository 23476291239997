import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  boardTypes,
  getHost,
  hotelAttributes,
  offerSortOptions,
  propertyFromArray,
  roomTypes,
  roomViews,
  stars,
} from "../utils";
import {
  setSearchEnded,
  setView,
  setViewBeforeSearch,
} from "amadeusredux/lib/systemSlice";
import { views } from "amadeusredux/lib/utils";
import MarketingBox from "./MobileMarketingBox";

import ShareOptions from "../components/ShareOptions";

import Popup from "reactjs-popup";
import { BsSuitHeartFill, BsSuitHeart } from "react-icons/bs";

import { MdContentCopy } from "react-icons/md";
import { FiUpload, FiShare2 } from "react-icons/fi";
import {
  availabilityAndPriceCheck,
  getLink2Share,
  setOfferMemo,
} from "amadeusredux/lib/api";
import { BsWhatsapp } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { RiCloseFill } from "react-icons/ri";
import { sendLink2Agency } from "amadeusredux/lib/bookingSlice";

import {
  setHotelData,
  setHotelOfferInfos,
  setHotelOffersFound,
  setHotelPage,
  setInfoHotel,
  setOfferId,
  setSelectedHotel,
} from "amadeusredux/lib/hotelSlice";
import {
  setSelectedOfferId,
  setMarked,
  setOfferSort,
  setOfferPage,
} from "amadeusredux/lib/offersSlice";
import {
  setApicall,
  setInbounddate,
  setOutbounddate,
} from "amadeusredux/lib/searchSlice";
import { search } from "amadeusredux/lib/api";
import { FaAlignLeft, FaCaretLeft } from "react-icons/fa";
import { setInboundView } from "amadeusredux/lib/bookingSlice";
import Select from "../components/Select";
import axios from "axios";
import {
  propertiesCheck,
  setSelectedRoomAttributes,
} from "amadeusredux/lib/filterSlice";
import MobileOfferImage from "./MobileOfferImage";

const nf1 = new Intl.NumberFormat("de-DE", {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

const nf = new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2 });

const pageSize = 15;

function MobileOffersView() {
  const dispatch = useDispatch();
  const sessionId = useSelector((state) => state.system.sessionId);
  const hotelOfferInfos = useSelector((state) => state.hotel.hotelOfferInfos);
  const view = useSelector((state) => state.system.view);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [swiped, setSwiped] = useState(null);
  const [contentSearch, setContentSearch] = useState(false);
  const offerId = useSelector((state) => state.hotel.offerId);
  const multiroomdivider = useSelector(
    (state) => state.search.multiroomdivider
  );
  const offerPage = useSelector((state) => state.offers.offerPage);
  const offersSearched = useSelector((state) => state.offers.offersSearched);
  const queryorder = useSelector((state) => state.offers.queryorder);
  const offerstopper = useSelector((state) => state.offers.offerstopper);
  const [uploadStep, setUploadStep] = React.useState(0);
  const [offerSelect, setOfferSelect] = React.useState({});
  const [hotelFilter, setHotelFilter] = React.useState("");
  const [uploadOpen, setUploadOpen] = React.useState(false);

  const filterRoomViews = useSelector((state) => state.filter.roomViews);
  const filterBoardTypes = useSelector((state) => state.filter.boardTypes);
  const filterHotelAttributes = useSelector(
    (state) => state.filter.hotelAttributes
  );

  const [requestData, setRequestData] = React.useState({
    name: "",
    mail: "",
    phone: "",
    remark: "",
  });

  React.useEffect(() => {
    if (offerId) {
      let currentoffer = document.getElementById(offerId);
      if (currentoffer)
        currentoffer.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, []);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const [swipedirection, setSwipedirection] = React.useState(null);
  const minSwipeDistance = 100;

  let swipeoffset = 0;
  if (touchStart && touchEnd) {
    swipeoffset = touchStart - touchEnd;
  }

  const onTouchStart = (e, offerid) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
    setSwiped(offerid);
  };

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = (offer, hotel) => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) {
      if (isLeftSwipe && offer) {
        dispatch(
          setHotelData({
            ...offerInfos,
            ...{ Offers: { Offer: { ...offer } } },
          })
        );

        /*if (data.payload.version === 2)
            dispatch(setView(views.hotelcrossview));
          else dispatch(setView(views.hotelclassicview));*/
        dispatch(setSelectedHotel(hotel));

        dispatch(setInboundView(views.offerview));
        dispatch(setInfoHotel(hotel));
        dispatch(setView(views.hotelview));
      }
    }
    setTouchEnd(null);
  };

  const offerInfos = useSelector((state) => state.offers.offerInfos);
  const holidayType = useSelector((state) => state.search.holidayType);
  const offerSort = useSelector((state) => state.offers.offerSort);

  let geodata = offerInfos.GeoDictionary;
  let hotels = offerInfos.HotelDictionary;

  const touroperatorsForHotel = useSelector(
    (state) => state.filter.touroperatorsForHotel
  );

  const nf2 = new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const handleClick = (offer) => {
    let hotel = hotels.Hotel.find(
      (e) =>
        e.HotelID === offer.OfferServices.Package.Accommodation.HotelRef.HotelID
    );

    if (offer) {
      dispatch(setSelectedRoomAttributes([]));
      dispatch(setHotelOffersFound(true));
      dispatch(setSearchEnded(false));
      dispatch(setView(views.hotelcrossview));
      dispatch(setHotelOfferInfos({}));
      dispatch(setSelectedOfferId(offer.OfferID));

      dispatch(setSelectedHotel(hotel));
      dispatch(setHotelPage(1));
      dispatch(setOfferId(offer.OfferID));

      dispatch(
        setOutbounddate(
          offer.OfferServices.Package?.Flight?.OutboundFlight?.FlightDeparture
            ?.DepartureDate
            ? offer.OfferServices.Package?.Flight?.OutboundFlight
                ?.FlightDeparture?.DepartureDate
            : offer.OfferServices.Package.Accommodation.CheckInDate
        )
      );

      dispatch(setInbounddate(""));
      dispatch(setApicall("packageOffers"));
      search(dispatch, true, false);
    } else alert("Kein Angebot gefunden !");
  };

  let offersSorted = Array.isArray(offerInfos?.Offers?.Offer)
    ? [...offerInfos.Offers.Offer]
    : [];

  if (offerSort.property === "price" && offerSort.order === "asc") {
    offersSorted.sort(
      (a, b) => a.PriceInfo?.Price?.value - b.PriceInfo?.Price?.value
    );
  }

  if (offerSort.property === "price" && offerSort.order === "desc") {
    offersSorted.sort(
      (a, b) => b.PriceInfo?.Price?.value - a.PriceInfo?.Price?.value
    );
  }

  if (offerSort.property === "recommendation" && offerSort.order === "asc") {
    offersSorted.reverse();
  }

  if (offerSort.property === "oursorting") {
    let tmpArray = [];
    if (queryorder.length > 0) {
      for (let i = 0; i < queryorder.length; i++) {
        let toadd = offersSorted.find(
          (off) => queryorder[i].offerid === off.OfferID
        );
        if (toadd) {
          //toadd.topseller = queryorder[i].topseller;
          tmpArray.push(toadd);
        }
      }
      if (tmpArray.length > 0) offersSorted = [...tmpArray];
      //response.data.BestPackageOfferForHotelRS.Offers.Offer = tmpArray;
    }
  }

  /*if (
    hotelFilter &&
    Array.isArray(hotels.Hotel) &&
    Array.isArray(offersSorted)
  ) {
    offersSorted = offersSorted.filter((offer) => {
      let hotel = hotels.Hotel.find(
        (e) =>
          e.HotelID ===
          offer.OfferServices.Package.Accommodation.HotelRef.HotelID
      );
      //console.log(hotel, hotelFilter);
      if (hotel) {
        if (hotel.Name.toUpperCase().indexOf(hotelFilter.toUpperCase()) > -1) {
          return true;
        }
      }
      return false;
    });
  }*/
  let filter = [];
  if (hotelFilter) {
    let f = hotelFilter.split(" ");
    for (let i = 0; i < f.length; i++) {
      f[i].trim();
      if (f[i].length > 0) {
        filter.push(f[i].trim().toUpperCase());
      }
    }
  }

  if (filter.length > 0 && Array.isArray(offersSorted)) {
    offersSorted = offersSorted.filter((offer) => {
      let check = false;
      if (offer.searchtext) {
        check = true;
        for (let i = 0; i < filter.length; i++) {
          if (!check) break;
          if (offer.searchtext.toUpperCase().indexOf(filter[i]) === -1) {
            check = false;
          }
        }
      }

      return check;
    });
  }

  const dangerButtonHandler = (e, a) => {
    a++;
    if (e.target ? e.target.nodeName === "X" : e.nodeName === "X") {
      setUploadOpen(true);
    } else if (a < 5) {
      dangerButtonHandler(e.target ? e.target.parentNode : e.parentNode, a);
    }
  };

  if (Array.isArray(filterRoomViews)) {
    for (let i = 0; i < filterRoomViews.length; i++) {
      if (!roomViews.find((e) => e.value === filterRoomViews[i].Value)) {
        console.log(filterRoomViews[i]);
      }
    }
  }

  if (Array.isArray(filterHotelAttributes)) {
    for (let i = 0; i < filterHotelAttributes.length; i++) {
      if (
        !hotelAttributes.find((e) => e.value === filterHotelAttributes[i].Label)
      ) {
        console.log(filterHotelAttributes[i]);
      }
    }
  }

  if (Array.isArray(filterBoardTypes)) {
    for (let i = 0; i < filterBoardTypes.length; i++) {
      if (!boardTypes.find((e) => e.value === filterBoardTypes[i].Value)) {
        console.log(filterBoardTypes[i]);
      }
    }
  }

  return (
    <>
      <Popup modal closeOnDocumentClick={false} open={uploadOpen}>
        <div className="bg-white m-1 p-1 border border-primary rounded">
          <div className="d-flex justify-content-end">
            <div
              className="d-flex bg-white rounded-circle border border-primary justify-content-end"
              style={{
                height: "30px",
                width: "30px" /*Nachfragen*/,
              }}
            >
              <RiCloseFill
                onClick={() => {
                  setUploadOpen(false);
                  setUploadStep(0);
                }}
                style={{
                  fontSize: "1.5rem",
                }}
                className="m-auto"
              />
            </div>
          </div>
          {uploadStep === 0 ? (
            <>
              <div className="m-1" style={{ fontSize: "1.3em" }}>
                Wir machen Ihnen gerne ein personalisiertes Angebot zu Ihrer
                Traumreise
              </div>
              <div className="ms-1 me-1">
                <div>Name*</div>
                <input
                  className="p-1 w-100"
                  value={requestData.name}
                  onChange={(e) => {
                    setRequestData({
                      ...requestData,
                      name: e.target.value,
                    });
                  }}
                  type="text"
                ></input>
              </div>
              <div>
                <div className="ms-1 me-1">
                  <div>E-Mail**</div>
                  <input
                    className="p-1 w-100"
                    value={requestData.mail}
                    onChange={(e) => {
                      setRequestData({
                        ...requestData,
                        mail: e.target.value,
                      });
                    }}
                    type="text"
                  ></input>
                </div>
                <div className="flex-grow-1" />
                <div className="ms-1 me-1">
                  <div>Telefon**</div>
                  <input
                    className="p-1 w-100"
                    value={requestData.phone}
                    onChange={(e) => {
                      setRequestData({
                        ...requestData,
                        phone: e.target.value,
                      });
                    }}
                    type="text"
                  ></input>
                </div>
              </div>
              <div className="ms-1 me-1">
                <div>Zusätzliche Informationen</div>
                <textarea
                  className="p-1 w-100"
                  onChange={(e) => {
                    setRequestData({
                      ...requestData,
                      remark: e.target.value,
                    });
                  }}
                  value={requestData.remark}
                  type="text"
                ></textarea>
              </div>
              <div className="ms-1 me-1" style={{ fontSize: "0.8em" }}>
                *erforderliche Daten
              </div>
              <div className="ms-1 me-1" style={{ fontSize: "0.8em" }}>
                **Mail oder Telefon erforderlich
              </div>
              <div className="d-flex justify-content-center">
                <button
                  disabled={
                    !requestData.name ||
                    (!requestData.mail && !requestData.phone)
                  }
                  className={
                    "w-50 p-1 border rounded m-1 text-white " +
                    (requestData.name && (requestData.mail || requestData.phone)
                      ? "bg-primary"
                      : "bg-muted")
                  }
                  onClick={() => {
                    setUploadStep(1);
                    /*console.log({
                  ...requestData,
                  link: shareLink.link,
                });*/
                    dispatch(
                      sendLink2Agency({
                        ...requestData,
                      })
                    );
                  }}
                >
                  Senden
                </button>
              </div>
            </>
          ) : (
            <div>
              <div className="d-flex justify-content-center align-items-center">
                {" "}
                <svg
                  className="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  {" "}
                  <circle
                    className="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />{" "}
                  <path
                    className="checkmark__check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  />
                </svg>
              </div>
              <div className="text-center" style={{ fontSize: "1.3em" }}>
                Vielen Dank
              </div>
              <div className="text-center" style={{ fontSize: "1.3em" }}>
                Ihre Anfrage wird schnellst möglich bearbeitet
              </div>
              <div className="d-flex justify-content-center">
                <button
                  className="w-50 p-1 border rounded m-1 text-white bg-primary"
                  onClick={() => {
                    setUploadStep(0);
                    setUploadOpen(false);
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          )}
        </div>
      </Popup>
      {Array.isArray(offersSorted) && offersSorted.length > 0 && (
        <div className="row">
          <div className="col-3 pt-2 ">Sortierung</div>
          <div style={{ zIndex: 3 }} className="col-8">
            <Select
              value={offerSort}
              onChange={(val) => {
                dispatch(setOfferPage(1));
                dispatch(setOfferSort(val));
              }}
              className="mb-1"
              isSearchable={false}
              defaultValue={offerSortOptions[0]}
              options={offerSortOptions}
            />
          </div>
          <div
            className="col-1 pe-1"
            style={{ cursor: "pointer" }}
            onClick={() => setContentSearch(!contentSearch)}
          >
            <i class="bi bi-search"></i>
          </div>
        </div>
      )}
      {offersSearched &&
        Array.isArray(offersSorted) &&
        offersSorted.length === 0 && (
          <div className="row">
            <div style={{ zIndex: 3 }} className="col-6 text-end">
              keine Ergebnisse gefunden
            </div>
            <div style={{ zIndex: 3 }} className="col-6 text-start">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  dispatch(setViewBeforeSearch(view));

                  dispatch(setView(views.searchview));
                }}
              >
                Suche anpassen
              </button>
            </div>
          </div>
        )}
      {contentSearch && (
        <div className="row">
          <div className="col-3 pt-2">Ort/Hotel</div>
          <div className="col-9">
            <input
              class="form-control"
              value={hotelFilter}
              onChange={(e) => setHotelFilter(e.target.value)}
            />
          </div>
        </div>
      )}
      <div className="row">
        {Array.isArray(offersSorted) &&
          offersSorted /*.filter(
            (offer, idx) => idx < 20
          ) */
            .filter((offer, idx) => {
              return (
                idx >= (offerPage - 1) * pageSize && idx < offerPage * pageSize
              );
            })
            .map((offer, idx) => {
              //console.log(offer);
              let hotel = hotels.Hotel.find(
                (e) =>
                  e.HotelID ===
                  offer.OfferServices.Package.Accommodation.HotelRef.HotelID
              );
              let stopperpre = <></>;
              let stopperpost = <></>;
              for (let i = 0; i < offerstopper.length; i++) {
                if (
                  ((offerstopper[i].row === "first" && idx === 0) ||
                    (offerstopper[i].row === "last" && idx === pageSize - 1) ||
                    offerstopper[i].row === idx + 1) &&
                  (offerstopper[i].page === "all" ||
                    (offerstopper[i].page === "first" && offerPage === 1) ||
                    (offerstopper[i].page === "odd" && offerPage % 2 !== 0) ||
                    (offerstopper[i].page === "even" && offerPage % 2 == 0))
                ) {
                  if (offerstopper[i].row === "last") {
                    stopperpost = (
                      <div
                        onClick={(e) => dangerButtonHandler(e, 0)}
                        dangerouslySetInnerHTML={{
                          __html: offerstopper[i].mobilehtml,
                        }}
                      />
                    );
                  } else {
                    stopperpre = (
                      <div
                        onClick={(e) => dangerButtonHandler(e, 0)}
                        dangerouslySetInnerHTML={{
                          __html: offerstopper[i].mobilehtml,
                        }}
                      />
                    );
                  }
                }
              }

              return (
                <>
                  {stopperpre}
                  <div
                    id={offer.OfferID}
                    style={{
                      marginLeft:
                        swiped && offer.OfferID === swiped
                          ? swipeoffset * -1
                          : 0,
                    }}
                    onTouchStart={(e) => onTouchStart(e, offer.OfferID)}
                    onTouchMove={(e) => onTouchMove(e)}
                    onTouchEnd={() => onTouchEnd(offer, hotel)}
                    key={offer.OfferID}
                    className="col-sm-12 col-md-12 col-lg-12 align-items-stretch "
                  >
                    <div
                      className={
                        "card mb-3 " +
                        (offer.OfferID === offerId
                          ? "bg-success  bg-opacity-10 "
                          : "") +
                        (offer.topseller ? " border-3 border-warning " : "")
                      }
                      style={{ borderRadius: 10 }}
                    >
                      <div className="column no-gutters ">
                        {offer.topseller && (
                          <div
                            style={{
                              marginTop: -10,
                              zIndex: "2",
                              position: "relative",
                            }}
                            className="row mb-0  text-warning "
                          >
                            <div className="col-8 ps-3">
                              <span className="bg-white">
                                &nbsp;Unsere Empfehlung&nbsp;
                              </span>
                            </div>
                            <div className="col-4 text-end mt-1">
                              <img
                                src={offer.topsellerlogo}
                                width="40px"
                                alt="topseller"
                              ></img>
                            </div>
                          </div>
                        )}
                        <div id="oben">
                          <MobileOfferImage
                            offer={offer}
                            hotel={hotel}
                            handleClick={() => handleClick(offer)}
                          />

                          <div
                            style={{ fontSize: "0.7em" }}
                            className="text ps-2 pe-2"
                          >
                            Dieses Hotel wird im ausgewählten Zeitraum von&nbsp;
                            {hotel?.HotelCodes?.HotelIffCode &&
                              Array.isArray(touroperatorsForHotel) &&
                              touroperatorsForHotel.length > 0 &&
                              touroperatorsForHotel.filter(
                                (e) =>
                                  e.HotelIffCode ===
                                  hotel.HotelCodes.HotelIffCode
                              ) && (
                                <span>
                                  {
                                    touroperatorsForHotel.filter(
                                      (e) =>
                                        e.HotelIffCode ===
                                        hotel.HotelCodes.HotelIffCode
                                    )[0]?.TourOperatorCodes.length
                                  }
                                </span>
                              )}
                            &nbsp;Veranstaltern angeboten.
                          </div>
                          <div className="d-flex justify-content-between px-2">
                            <p
                              className="card-title fw-semibold overflow-hidden mb-0 fs-5 text"
                              title={hotel.Name}
                            >
                              {hotel.Name}
                            </p>
                          </div>
                        </div>
                        <div id="test" className="col">
                          {/*offer.topseller && (
                          <div style={{ height: 40 }}>&nbsp;&nbsp;</div>
                      )*/}
                          <div>
                            <MarketingBox
                              offer={offer}
                              topseller={offer.topseller}
                              onpage="0021-0001"
                            />
                          </div>

                          <div className="card-block px-2">
                            <div onClick={() => handleClick(offer)}>
                              <div className="d-flex justify-content-between">
                                <div>
                                  <p className="text-warning mb-0">
                                    {stars(
                                      offer.OfferServices.Package.Accommodation
                                        .HotelOfferCategory * 1
                                    )}
                                  </p>
                                </div>
                                <div
                                  className="mt-1"
                                  style={{ minWidth: "130px" }}
                                >
                                  {hotel.HotelReview.MeanRecommendationRate && (
                                    <p className="mb-0">
                                      <small>
                                        {nf1.format(
                                          hotel.HotelReview
                                            .MeanRecommendationRate
                                        )}{" "}
                                        %
                                      </small>
                                      <small className="fw-semibold">
                                        {" "}
                                        {hotel.HotelReview.MeanRatingOverall >=
                                        5 ? (
                                          "Sehr Gut"
                                        ) : hotel.HotelReview
                                            .MeanRatingOverall >= 4 ? (
                                          "Gut"
                                        ) : hotel.HotelReview
                                            .MeanRatingOverall >= 3 ? (
                                          "Befriedigend"
                                        ) : (
                                          <span>&nbsp;</span>
                                        )}
                                      </small>
                                      <small
                                        className="bg-primary text-white p-1 ms-1"
                                        style={{
                                          borderRadius: "5px 5px 5px 5px",
                                        }}
                                      >
                                        {nf1.format(
                                          hotel.HotelReview.MeanRatingOverall
                                        )}
                                        &nbsp;/&nbsp;6
                                      </small>{" "}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <p className="mb-3">
                                <small>
                                  in{" "}
                                  {hotel &&
                                    hotel.HotelLocationRef &&
                                    Array.isArray(geodata?.Cities?.City) &&
                                    geodata.Cities.City.find(
                                      (c) =>
                                        c.CityID ===
                                        hotel.HotelLocationRef.CityID
                                    ) !== undefined &&
                                    geodata.Cities.City.find(
                                      (c) =>
                                        c.CityID ===
                                        hotel.HotelLocationRef.CityID
                                    ).Name}{" "}
                                </small>
                              </p>
                            </div>
                            <div className="row">
                              <div className="col-4">
                                <ShareOptions
                                  offer={offer}
                                  sessionId={sessionId}
                                  offerInfos={offerInfos}
                                  hotel={hotel}
                                />
                              </div>

                              <div className="col-8">
                                {" "}
                                <MarketingBox
                                  offer={offer}
                                  onpage="0021-0003"
                                />
                              </div>
                            </div>
                            <div
                              className="card-text"
                              onClick={() => handleClick(offer)}
                            >
                              <div className="d-flex justify-content-between mt-2">
                                <p className="mb-0 text-end">
                                  {holidayType.flight && (
                                    <p className="mb-0">
                                      <small>
                                        {offer.TravelDateInfo.OvernightStays}{" "}
                                        Übernachtungen |{" "}
                                        {offer.TravelDateInfo.TripDuration
                                          ? offer.TravelDateInfo.TripDuration
                                          : offer.TravelDateInfo
                                              .OvernightStays}{" "}
                                        Tage
                                      </small>
                                    </p>
                                  )}
                                  {!holidayType.flight && (
                                    <p className="mb-0">
                                      <small>
                                        {offer.TravelDateInfo.OvernightStays}
                                        Übernachtungen
                                      </small>
                                    </p>
                                  )}
                                </p>
                                <p className="mb-0 text-end">
                                  <small>
                                    {multiroomdivider > 1
                                      ? multiroomdivider + " x "
                                      : ""}
                                    {propertyFromArray(
                                      roomTypes,
                                      offer.OfferServices.Package.Accommodation
                                        .Room.RoomType,
                                      "value",
                                      "label"
                                    )}
                                  </small>
                                </p>
                              </div>
                              <p className="mb-0 text-start">
                                <small>
                                  {propertyFromArray(
                                    boardTypes,
                                    offer.OfferServices.Package.Accommodation
                                      .BoardType,
                                    "value",
                                    "label"
                                  )}
                                </small>
                              </p>
                              <div className="d-flex justify-content-between ">
                                {offer.OfferServices.Package.Flight && (
                                  <div className="mb-1 text-end  d-flex flex-column">
                                    <div style={{ flexGrow: "1" }} />
                                    <small className="align-middle">
                                      inkl. Flug{" "}
                                      {offer?.OfferServices?.Package?.Flight
                                        ?.OutboundFlight?.FlightDeparture
                                        ?.DepartureAirportRef?.AirportCode && (
                                        <>
                                          (von{" "}
                                          {
                                            offer.OfferServices.Package.Flight
                                              .OutboundFlight.FlightDeparture
                                              .DepartureAirportRef.AirportCode
                                          }
                                          )
                                        </>
                                      )}
                                    </small>
                                  </div>
                                )}
                                <div>
                                  <p className="mb-0 text-end fw-semibold">
                                    ab{" "}
                                    <span className="fw-semibold fs-3 text">
                                      {nf.format(
                                        offer.PriceInfo.Price.value *
                                          (offer.adults * 1 +
                                            offer.children * 1)
                                      )}
                                    </span>
                                    &nbsp;
                                    <span className="fw-semibold">
                                      {offer.PriceInfo.Price.CurrencyCode}
                                    </span>
                                  </p>
                                  {offer.adults * 1 + offer.children * 1 >
                                    1 && (
                                    <>
                                      <small className="align-top">
                                        (
                                        {offer.PriceInfo.Price.value
                                          ? nf2.format(
                                              offer.realprice &&
                                                offer.realprice <
                                                  offer.PriceInfo.Price.value *
                                                    (offer.adults * 1 +
                                                      offer.children * 1) *
                                                    0.98
                                                ? offer.realprice /
                                                    (offer.adults * 1 +
                                                      offer.children * 1)
                                                : offer.realprice &&
                                                  offer.realprice >
                                                    offer.PriceInfo.Price
                                                      .value *
                                                      (offer.adults * 1 +
                                                        offer.children * 1)
                                                ? offer.realprice /
                                                  (offer.adults * 1 +
                                                    offer.children * 1)
                                                : offer.PriceInfo.Price.value
                                            )
                                          : ""}{" "}
                                        {offer.PriceInfo.Price.CurrencyCode} pro
                                        Person)
                                      </small>
                                    </>
                                  )}
                                </div>
                              </div>

                              {/*<p className="mb-0 text-end">
                              <a href="#" className="btn btn-primary mb-1">
                                Go somewhere
                              </a>
                                    </p>*/}
                              <div style={{ width: "100%" }}>
                                <MarketingBox
                                  offer={offer}
                                  onpage="0021-0004"
                                />
                              </div>
                            </div>
                          </div>
                          {/*
                        <div>
                          <MarketingBox offer={offer} onpage="0021-0004" />
                                  </div>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                  {stopperpost}
                </>
              );
            })}
      </div>
      {Array.isArray(offersSorted) && offersSorted.length > pageSize && (
        <div className="row">
          <div className="col-2">Seiten:</div>
          {Array.isArray(offersSorted) && (
            <div className="col-10">
              {[...Array(Math.ceil(offersSorted.length / pageSize))].map(
                (e, i) => {
                  if (
                    !(
                      i + 1 === 1 ||
                      i + 1 === offerPage - 4 ||
                      i + 1 === offerPage - 3 ||
                      i + 1 === offerPage - 2 ||
                      i + 1 === offerPage - 1 ||
                      i + 1 === offerPage ||
                      i + 1 === offerPage + 1 ||
                      i + 1 === offerPage + 2 ||
                      i + 1 === offerPage + 3 ||
                      i + 1 === offerPage + 4 ||
                      i + 1 === Math.ceil(offersSorted.length / pageSize)
                    )
                  )
                    return <></>;
                  return (
                    <>
                      {i > 0 &&
                        (offerPage > 6 && i + 1 === offerPage - 4 ? (
                          <> &nbsp;...&nbsp; </>
                        ) : i + 1 ===
                            Math.ceil(offersSorted.length / pageSize) &&
                          offerPage <
                            Math.ceil(offersSorted.length / pageSize) - 5 ? (
                          <> &nbsp;...&nbsp; </>
                        ) : (
                          <>&nbsp;|&nbsp;</>
                        ))}
                      <span
                        style={{
                          fontWeight: i + 1 === offerPage ? 700 : undefined,
                          cursor: i + 1 !== offerPage ? "pointer" : undefined,
                        }}
                        onClick={() => {
                          if (i + 1 !== offerPage) {
                            dispatch(setOfferPage(i + 1));
                            /*setTimeout(() => {
                              window.scrollTo({
                                top: 0,
                                left: 0,
                              });
                            }, 200);*/
                          }
                        }}
                      >
                        {i + 1}
                      </span>
                    </>
                  );
                }
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default MobileOffersView;
