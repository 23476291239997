import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { FaInfoCircle } from "react-icons/fa";
import InfoPopupHover from "../components/InfoPopupHover";
import { setSearchOption } from "amadeusredux/lib/searchSlice";
import { getFilters } from "amadeusredux/lib/api";

export default function OfferFilter(props) {
  const { changeFilterAndSearch, changeClientFilter } = props;
  const loading = useSelector((state) => state.system.loading);

  const freecancellation = useSelector(
    (state) => state.search.freecancellation
  );

  const addablecancellation = useSelector(
    (state) => state.search.addablecancellation
  );

  const reservationoperatorsonly = useSelector(
    (state) => state.search.reservationoperatorsonly
  );
  const dispatch = useDispatch();
  const freerebook = useSelector((state) => state.search.freerebook);

  const infos = useSelector((state) => state.properties.infos);

  // console.log(infos);

  return (
    <div>
      <div className="fw-semibold">Stornierungsoptionen</div>
      <div>
        <div style={{ display: "flex" }}  className="mb-2">
          &nbsp;
          <input
            disabled={loading > 0}
            type="checkbox"
            checked={freecancellation}
            onChange={() => {
              dispatch(
                setSearchOption({
                  property: "freecancellation",
                  data: !freecancellation,
                })
              );

              getFilters(dispatch, false);
            }}
          />
          &nbsp;
          <div
            dangerouslySetInnerHTML={{
              __html: "Kostenlos oder günstig stornierbar",
            }}
          ></div>
        </div>
      </div>
      <div>
        <div style={{ display: "flex" }} className="mb-2">
          &nbsp;
          <input
            disabled={loading > 0}
            type="checkbox"
            checked={freerebook}
            onChange={() => {
              dispatch(
                setSearchOption({
                  property: "freerebook",
                  data: !freerebook,
                })
              );

              getFilters(dispatch, false);
            }}
          />
          &nbsp;
          <div
            dangerouslySetInnerHTML={{
              __html: "Kostenlose Umbuchung",
            }}
          ></div>
        </div>
      </div>
      <div>
        <div style={{ display: "flex" }}  className="mb-2">
          &nbsp;
          <input
            disabled={loading > 0}
            type="checkbox"
            checked={addablecancellation}
            onChange={() => {
              dispatch(
                setSearchOption({
                  property: "addablecancellation",
                  data: !addablecancellation,
                })
              );

              getFilters(dispatch, false);
            }}
          />
          &nbsp;
          <div
            dangerouslySetInnerHTML={{
              __html: "FLEX-Tarif zubuchbar",
            }}
          ></div>
        </div>
      </div>
      <div>
        <div style={{ display: "flex" }}  className="mb-2">
          &nbsp;
          <input
            disabled={loading > 0}
            type="checkbox"
            checked={reservationoperatorsonly}
            onChange={() => {
              dispatch(
                setSearchOption({
                  property: "reservationoperatorsonly",
                  data: !reservationoperatorsonly,
                })
              );

              getFilters(dispatch, false);
            }}
          />
          &nbsp;
          <div
            dangerouslySetInnerHTML={{
              __html: "3 Tage unverbindlich reservieren",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
