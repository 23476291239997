import React from "react";
import InfoPopupHover from "../components/InfoPopupHover";
import { FaInfoCircle } from "react-icons/fa";

export default function MobileMarketingBox(props) {
  const { offer, onpage, topseller } = props;

  let ma = [];

  if (offer.MarketingActions) {
    ma = [...offer.MarketingActions];
  }
  ma.sort((a, b) => b.rank - a.rank);
  //console.log(ma);

  let pictop = 0;

  if (topseller) pictop = 28;

  let classPrefix = "";
  let myClass = "";
  let myStyle = "";

  if (onpage == "0021-0001") {
    classPrefix = "image";
    myClass = "bg-primary";
    myStyle = { position: "absolute", top: pictop, left: 0 };
  }
  if (onpage == "0021-0002") {
    classPrefix = "headline";
    myClass = "bg-primary";
    myStyle = { position: "absolute", top: 12, left: 0 };
  }
  if (onpage == "0021-0003") {
    classPrefix = "subline";
    myClass = "bg-success text-white text-end mb-2 p-1";
    myStyle = { };
  }
  if (onpage == "0021-0004") {
    classPrefix = "footline";
    myClass = "bg-danger ";
    myStyle = { textAlign: "center", bottom: 0, width: "100%" };
  }
  {
    /*
 className={
                      ma.MarketingProperties.typ === "0019-0003"
                        ? classPrefix + "call2actionbox"
                        : classPrefix + "promobox"
   (ma.MarketingProperties.typ === "0019-0003"
                            ? classPrefix + "call2action "
                            : classPrefix + "promolabel ") +
                          (ma.ticker ? "ticker" : "noticker")                     

      {ma.ticker && (
                      <span
                        className={
                          (ma.MarketingProperties.typ === "0019-0003"
                            ? classPrefix + "call2action "
                            : classPrefix + "promolabel ") +
                          (ma.ticker ? "ticker" : "noticker")
                        }
                      >
                        {ma.MarketingProperties.text}
                      </span>
                    )}                           
*/
  }

  return (
    <>
      {Array.isArray(offer.MarketingActions) &&
        offer.MarketingActions.map((ma) =>
          ma.onpage == onpage ? (
            <InfoPopupHover
              trigger={
                <div style={{ overflow: "hidden" }}>
                  <div
                    className={myClass}
                    style={{
                      ...myStyle,
                      ...(ma.MarketingProperties.styleM
                        ? ma.MarketingProperties.styleM
                        : {}),
                    }}
                    dangerouslySetInnerHTML={{
                      __html: "" + ma.MarketingProperties.text,
                    }}
                  />
                  {/*<FaInfoCircle
                        style={{ verticalAlign: "middle" }}
                      className="infowhite" />*/}
                </div>
              }
              content={ma.MarketingProperties.mouseover}
              position={"bottom center"}
            ></InfoPopupHover>
          ) : null
        )}
    </>
  );
}
