import React, { useState } from "react";
//import Select from "react-select";
import Select from "../components/Select";
import { airportcity, updateairlines } from "../utils";
import { useSelector, useDispatch } from "react-redux";
import { selectSearch, setSearchOption } from "amadeusredux/lib/searchSlice";
import Options from "../components/Options.js";
//import MapModal from "../components/MapModal.js";
import InfoPopupHover from "../components/InfoPopupHover";
import { getFilters } from "amadeusredux/lib/api";
import { unwrapResult } from "@reduxjs/toolkit";

export default function AirportSelect(props) {
  /*let options = departureStations.map((e) => {
    return { value: e, label: e };
  });*/

  const { stations } = useSelector(selectSearch);
  const dispatch = useDispatch();
  const { isDisabled, thisRef, currentInput, step, setStep } = props;
  //console.log(isDisabled);
  const airlines = useSelector((state) => state.properties.airlines);
  const nolowcost = useSelector((state) => state.search.nolowcost);
  const linie = useSelector((state) => state.search.linie);
  const airlinerank = useSelector((state) => state.search.airlinerank);

  const departureStations = useSelector(
    (state) => state.properties.departureStations
  );

  const logobject = (data1, data2) => {
    if (Object.keys(data1).length > 0 && Object.keys(data1).length > 0) {
      //console.log(data1);
      //console.log(data2);
      let tmp1 = Object.keys(data1);
      let tmp2 = Object.keys(data2);
      for (let i = 0; i < tmp1.length; i++) {
        //console.log(data1[tmp1[i]]);
        //console.log(data2[tmp2[i]]);
      }
    }
  };

  const handleSelectAll = (data) => {
    let tmpstations = [];
    if (stations.length > 0) {
      tmpstations = [...new Set(stations.map((item) => item.value))];
    }
    let tmpdata = [];
    if (data.length > 0) {
      tmpdata = [...new Set(data.map((item) => item.value))];
    }
    if (tmpdata.every((o) => tmpstations.includes(o))) {
      tmpstations = tmpstations.filter((o) => !tmpdata.includes(o));
    } else {
      tmpstations = [...tmpstations, ...tmpdata];
    }

    let uniqueairports = [...new Set(tmpstations.map((item) => item))];
    let val = [];
    for (let i = 0; i < uniqueairports.length; i++) {
      val.push({
        value: uniqueairports[i],
        label: airportcity(uniqueairports[i]),
      });
    }
    if (val.length === 0) {
      if (setStep) setStep(1);
    } else {
      if (setStep) setStep(2);
    }
    airportcity();
    dispatch(setSearchOption({ property: "stations", data: val }));
    getFilters(dispatch, false);
  };

  const groupDepartureStations = () => {
    let tmp = [];
    if (Array.isArray(departureStations)) {
      tmp = [...departureStations];
    }
    if (Array.isArray(tmp)) {
      let tmpall = { label: "Alle Flughäfen", options: [] };
      for (let i = 0; i < tmp.length; i++) {
        if (tmp[i].airports) {
          let tmpoption = {
            label: (() => {
              return (
                <div onClick={() => handleSelectAll(tmp[i].options)}>
                  {tmp[i].label}
                </div>
              );
            })(),
            options: [],
          };
          for (let j = 0; j < tmp[i].airports.length; j++) {
            tmpoption.options.push({
              value: tmp[i].airports[j],
              label: airportcity(tmp[i].airports[j]),
            });
          }
          tmp[i] = tmpoption;
        } else {
          tmpall.options.push(tmp[i]);
        }
      }
      tmp.push(tmpall);
    }
    tmp = tmp.filter((o) => o.options);
    return tmp;
  };

  let groupedDepartureStations = groupDepartureStations();

  let stationstring = "";
  if (stations.length > 0) {
    for (let i = 0; i < stations.length; i++) {
      stationstring =
        stationstring + "<span>" + stations[i].label + ",<br/></span>";
    }
  }

  return (
    <InfoPopupHover
      trigger={
        <div>
          <Select
            styles={{
              valueContainer: (base, state) => ({
                ...base,
                textOverflow: "ellipsis",
                maxWidth: "90%",
                flexWrap: "wrap",
                whiteSpace: "nowrap",
                //overflow: "hidden",
              }),
              multiValue: (base, state) => ({
                ...base, //minWidth: "50%"
              }),
            }}
            ref={thisRef}
            isDisabled={isDisabled}
            placeholder={"Mehrauswahl und Regionsauswahl möglich"}
            isMulti
            value={stations}
            options={groupedDepartureStations}
            isSearchable={false}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option: Options }}
            onChange={(val) => {
              if (Array.isArray(val)) {
                for (let i = 0; i < val.length; i++) {
                  if (val[i].airports) {
                    for (let j = 0; j < val[i].airports.length; j++) {
                      val.push({
                        value: val[i].airports[j],
                        label: airportcity(val[i].airports[j]),
                      });
                    }
                  }
                }

                for (let i = 0; i < val.length; i++) {
                  if (val[i].airports) {
                    val.splice(i, 1);
                  }
                }

                const uniqueairports = [
                  ...new Set(val.map((item) => item.value)),
                ];
                val = [];
                for (let i = 0; i < uniqueairports.length; i++) {
                  val.push({
                    value: uniqueairports[i],
                    label: airportcity(uniqueairports[i]),
                  });
                }
                airportcity();
              }
              dispatch(setSearchOption({ property: "stations", data: val }));
              getFilters(dispatch, false)
                .then(unwrapResult)
                .then((originalPromiseResult) => {
                  updateairlines(
                    dispatch,
                    airlines,
                    originalPromiseResult,
                    airlinerank,
                    nolowcost,
                    linie
                  );
                });
              if (setStep) {
                if (step === 1) {
                  setStep(2);
                } else if (step === 2) {
                  if (val.length === 0) {
                    setStep(1);
                  }
                }
              }
            }}
          />{" "}
        </div>
      }
      content={
        stations.length !== 0 ? stationstring : "Wählen Sie einen Abflughafen"
      }
      position={"right center"}
    ></InfoPopupHover>
  );
}
