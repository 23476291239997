import React from "react";

export default function Parken(props) {
  let { fill, width, height } = props;

  if (!fill) fill = "black";
  if (!width) width = 32;
  if (!height) height = 32;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24">
      <path
        fill={fill}
        d="m 0.24353324,24.146239 c -4.3e-4,-0.03859 -0.001,-5.3948 -0.002,-11.902683 l -8e-4,-11.83251397 11.32574376,-0.03551 c 11.294064,-0.03541 11.326885,-0.0347 11.73315,0.254585 0.224074,0.159554 0.531285,0.53291997 0.682691,0.82970197 0.268621,0.52654 0.274426,0.807649 0.239754,11.612628 l -0.03553,11.073025 -11.971311,0.03547 c -6.5842178,0.01951 -11.97166776,0.0039 -11.97210776,-0.0347 z m 10.65614476,-5.93881 0.03732,-3.162084 0.656672,0.08616 c 1.006801,0.132094 3.413344,-0.237534 4.228833,-0.649519 0.132557,-0.06697 0.335549,-0.121759 0.451092,-0.121759 0.115544,0 0.210081,-0.06246 0.210081,-0.138798 0,-0.07634 0.121044,-0.138798 0.268987,-0.138798 0.147945,0 0.728003,-0.452828 1.289021,-1.006284 1.31343,-1.295728 1.634341,-2.130914 1.634341,-4.253437 0,-0.773487 -0.05261,-1.514796 -0.116909,-1.647354 C 19.494816,7.042999 19.295024,6.630821 19.115134,6.259605 18.451336,4.889813 16.841974,3.720966 15.102181,3.345079 14.282973,3.168087 8.7691412,3.157633 7.6006132,3.330859 l -0.90219,0.133741 -0.036,8.813662 c -0.0198,4.847513 -0.005,8.893411 0.0318,8.990885 0.0517,0.135171 0.55425,0.168106 2.117948,0.138798 l 2.0501928,-0.03843 z m 0.136181,-6.561446 C 10.978609,11.586593 10.93176,10.392919 10.93176,8.993374 V 6.448747 l 1.145082,0.0021 c 0.629795,0.0012 1.389431,0.08714 1.688079,0.1911 1.142165,0.397573 1.871937,1.572295 1.680536,2.705182 -0.249507,1.476821 -1.263457,2.222331 -3.1807,2.338621 -0.61864,0.03752 -1.171644,0.01963 -1.228898,-0.03976 z"
      />
    </svg>
  );
}
