import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { PRICELIMIT } from "../config";
import { setSearchOption } from "amadeusredux/lib/searchSlice";
import { getFilters } from "amadeusredux/lib/api";
import { unwrapResult } from "@reduxjs/toolkit";

export default function PriceFilter(props) {
  const { changeFilterAndSearch } = props;

  const loading = useSelector((state) => state.system.loading);

  const price = useSelector((state) => state.search.price);

  const [highPrice, setHighPrice] = React.useState(0);

  React.useEffect(() => {
    setHighPrice(price);
  }, [price]);

  const dispatch = useDispatch();

  let domain = [0, PRICELIMIT];

  return (
    <div>
      <label htmlFor="priceFilter" className="form-label">
        <span className="fw-semibold">Gesamtbudget</span> {highPrice}
        {highPrice === PRICELIMIT ? "+" : ""} EUR
      </label>

      <input
        value={highPrice ? highPrice * 1 : 0}
        onChange={(evt) => {
          //if(evt.target.value * 1 === highPrice * 1) return;
          dispatch(
            setSearchOption({
              property: "price",
              data: evt.target.value * 1,
            })
          );

          getFilters(dispatch, false);
        }}
        type="range"
        className="form-range"
        min="0"
        max={PRICELIMIT}
        step="250"
        id="priceFilter"
      />
    </div>
  );
}
