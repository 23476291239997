import React from "react";
import { roomViews } from "../utils";

import { useDispatch, useSelector } from "react-redux";
import { setSearchOption } from "amadeusredux/lib/searchSlice";
import { getFilters } from "amadeusredux/lib/api";

export default function RoomViewFilter(props) {
  const { changeFilterAndSearch } = props;
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.system.loading);
  const roomView = useSelector((state) => state.search.roomView);
  let filteredViews = roomViews.filter((e) => e.filter);
  return (
    <div>
      <span className="fw-semibold">Aussicht</span>

      {filteredViews.map((itm) => {
        let disabled = false;

        if (loading > 0) disabled = true;
        return (
          <div key={itm.value}>
            <div style={{ display: "flex" }} className="mb-2">
              &nbsp;
              <input
                checked={roomView.includes(itm.value)}


                
                onChange={() => {
                  let changed = [...roomView];
                  if (changed.includes(itm.value)) {
                    changed = changed.filter((e) => e !== itm.value);
                  } else {
                    changed.push(itm.value);
                  }

                  dispatch(
                    setSearchOption({
                      property: "roomView",
                      data: changed,
                    })
                  );

                  getFilters(dispatch, false);
                }}
                disabled={disabled}
                type="checkbox"
              />
              &nbsp;
              <div
                dangerouslySetInnerHTML={{
                  __html: "" + itm.label,
                }}
              ></div>{" "}
            </div>
          </div>
        );
      })}
    </div>
  );
}
