import React from "react";
import { MdCheck } from "react-icons/md";
import { cancellationOptions, getTexts, propertyFromArray } from "./utils";

export default function CancellationOptions(props) {
  const { offer, verbose, flexbooked, mode } = props;
  let txts = getTexts(offer.OfferProperties?.OfferFeatures, [
    "Storno:",
    "Storno mit Gebühr:",
    "Umb:",
    "Opt Flex:",
    "Sonst:",
  ]);
  let cancellationoption = "";
  if (offer?.OfferProperties?.CancellationOption) {
    cancellationoption = offer?.OfferProperties?.CancellationOption;
  }

  let flextext = "";
  let flexprice = 0;
  //  if (cancellationoption === "Addable") {
  //console.log(offer.MarketingActions[0])
  if (
    Array.isArray(offer?.MarketingActions) &&
    offer.MarketingActions.length > 0 &&
    !isNaN(
      offer.MarketingActions[0].flexconditions?.flexoptions?.flexoptionprice
    )
  ) {
    if (offer.MarketingActions[0].flexconditions?.flexoptions?.flexoptiontext)
      flextext =
        offer.MarketingActions[0].flexconditions.flexoptions.flexoptiontext;
    if (offer.MarketingActions[0].flexconditions?.price)
      flexprice =
        offer.MarketingActions[0].flexconditions?.flexoptions?.flexoptionprice;
  }
  //}

  //console.log(flextext, flexprice);
  return (
    <>
      {/*Object.keys(txts).length > 1 && <span>{JSON.stringify(txts)}</span>*/}
      {/*!verbose && cancellationoption && (
        <>
          <div>
            <MdCheck size={18} />
          </div>
          <div>
            <span className="mb-0 text-success">
              {cancellationoption
                ? propertyFromArray(
                  cancellationOptions,
                  cancellationoption,
                  "value",
                  "label"
                )
                : ""}
            </span>
          </div>
        </>
                )*/}
      {verbose && (
        <>
          {/*!flextext && txts.storno && (
            <p className="mb-0 text-success">
              Stornooption: {txts.storno}

            </p>
          )}
          {txts.stornomitgebühr && (
            <p className="mb-0 text-warning">
              Storno mit Gebühr-Option: {txts.stornomitgebühr}

            </p>
          )}
          {txts.umb && (
            <p className="mb-0 text-success">
              Umbuchungsoption: {txts.umb}

            </p>
          )*/}
          {(mode !== "booking" || flexbooked) && (/*txts.optflex ||*/ flextext) && (
            <p className="mb-0 text-success">
              Flexoption:{" "}
              {flextext && flexprice ? (
                <>
                  {flextext} {/*{flexprice} €*/}
                </>
              ) : (
                <>{/*txts.optflex*/}</>
              )}
            </p>
          )}
          {/*txts.sonst && (
            <p className="mb-0 text-warning">
              Sonstiges: {txts.sonst}

            </p>
          )*/}
        </>
      )}
    </>
  );
}
