import React, { useState } from "react";
//import AsyncSelect from "react-select/async";
import Select from "../components/Select";
import axios from "axios";
import {
  propertyFromArray,
  getHost,
  reevaluateAirlines,
  updateairlines,
} from "../utils";
import { useSelector, useDispatch } from "react-redux";
import {
  setSearchOption,
  setSelectedDestination,
} from "amadeusredux/lib/searchSlice";
import { MdOutlineLaptopWindows } from "react-icons/md";
import { unwrapResult } from "@reduxjs/toolkit";

import { getFilters, suggestiontosearch } from "amadeusredux/lib/api";
import {
  FaCity,
  FaExclamation,
  FaHome,
  FaMapMarked,
  FaMapMarker,
  FaMapMarkerAlt,
  FaMapPin,
} from "react-icons/fa";
import InfoPopupHover from "../components/InfoPopupHover";

export default function DestinationSelect(props) {
  const dispatch = useDispatch();
  const { nextRef, currentInput, step, setStep } = props;
  const regionGroups = useSelector((state) => state.properties.regionGroups);
  const selectedDestination = useSelector(
    (state) => state.search.selectedDestination
  );
  const airlines = useSelector((state) => state.properties.airlines);

  const holidayType = useSelector((state) => state.search.holidayType);
  const suggestions = useSelector((state) => state.properties.suggestions);
  const nolowcost = useSelector((state) => state.search.nolowcost);
  const linie = useSelector((state) => state.search.linie);
  const airlinerank = useSelector((state) => state.search.airlinerank);

  //console.log(selectedDestination);
  const reduce = (groups) => {
    if (selectedDestination.length > 0) {
      for (let i = 0; i < groups.length; i++) {
        let options = groups[i].options;
        let opts = [];
        for (let j = 0; j < options.length; j++) {
          if (
            selectedDestination[0].RegionGroupID &&
            options[j].RegionGroupID
          ) {
            opts.push(options[j]);
          }
          if (selectedDestination[0].RegionID && options[j].RegionID) {
            opts.push(options[j]);
          }
          if (selectedDestination[0].CityID && options[j].CityID) {
            opts.push(options[j]);
          }
          if (
            selectedDestination[0].HotelCodes?.HotelGiataID &&
            options[j].HotelCodes?.HotelGiataID
          ) {
            opts.push(options[j]);
          }
        }
        //console.log(selectedDestination[0]);
        /*if (selectedDestination[0].HotelCodes?.HotelGiataID) {
          groups[i].options = opts;
        } else {
          for (let j = 0; j < options.length; j++) {
            if (!options[j].HotelCodes?.HotelGiataID) {
              opts.push(options[j]);
            }
          }
        }*/
        groups[i].options = opts;
      }
    }
    return groups;
  };

  //console.log(selectedDestination);
  const [destinationFilter, setDestinationFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [geoDictionary, setGeoDictionary] = useState({});

  let destinationRegions = [];
  let destinationSuggestions = [];

  React.useEffect(() => {
    if (regionGroups.length > 0) setIsLoading(false);
    else setIsLoading(true);
  }, [regionGroups]);

  let dgroups = [];

  if (Array.isArray(regionGroups) && regionGroups.length > 0) {
    for (let i = 0; i < regionGroups.length; i++) {
      let rg = regionGroups[i];
      dgroups.push({ value: rg.Name, label: rg.Name, ...rg });
    }
  }

  if (Array.isArray(regionGroups) && regionGroups.length > 0) {
    for (let i = 0; i < regionGroups.length; i++) {
      let rg = regionGroups[i];
      for (let j = 0; j < rg.Region.length; j++) {
        let r = rg.Region[j];
        destinationRegions.push({ value: r.Name, label: r.Name, ...r });
      }
    }
  }

  destinationRegions.sort(
    (a, b) => a.Name.toLocaleLowerCase() > b.Name.toLocaleLowerCase()
  );

  destinationRegions = [...dgroups, ...destinationRegions];
  let groups = [];

  if (Array.isArray(suggestions) && suggestions.length > 0) {
    destinationSuggestions = [
      ...suggestions.filter((e) => {
        return holidayType.flight
          ? e.typ === "package"
          : e.typ === "accommodation";
      }),
    ];
  }

  if (destinationSuggestions.length > 0) {
    groups.push({
      label: "Beliebte Urlaubsziele",
      options: destinationSuggestions,
    });
  }

  if (destinationRegions.length > 0) {
    groups.push({ label: "Regionen", options: destinationRegions });
  }

  const handleInputChange = (newValue) => {
    //const inputValue = newValue.replace(/\W/g, '');
    setDestinationFilter(newValue);
    return newValue;
  };
  const loadOptions = (destinationFilter, callback) => {
    if (
      selectedDestination.length > 0 &&
      (selectedDestination[0].HotelCodes?.HotelGiataID ||
        selectedDestination[0].type === "suggestion")
    ) {
      callback([]);
      return;
    }

    let destinationRegions = [];
    let destinationCities = [];
    let destinationSuggestions = [];

    let dgroups = [];
    if (Array.isArray(regionGroups) && regionGroups.length > 0) {
      for (let i = 0; i < regionGroups.length; i++) {
        let rg = regionGroups[i];
        dgroups.push({ value: rg.Name, label: rg.Name, ...rg });
      }
    }

    if (Array.isArray(regionGroups) && regionGroups.length > 0) {
      for (let i = 0; i < regionGroups.length; i++) {
        let rg = regionGroups[i];
        for (let j = 0; j < rg.Region.length; j++) {
          let r = rg.Region[j];
          destinationRegions.push({ value: r.Name, label: r.Name, ...r });
          for (let k = 0; k < r.City.length; k++) {
            let c = r.City[k];
            destinationCities.push({
              value: c.Name,
              CityID: c.CityID,
              label: c.Name,
              ...c,
            });
          }
        }
      }
    }

    destinationRegions.sort(
      (a, b) => a.Name.toLocaleLowerCase() > b.Name.toLocaleLowerCase()
    );

    destinationRegions = [...dgroups, ...destinationRegions];
    if (Array.isArray(suggestions) && suggestions.length > 0) {
      destinationSuggestions = [...suggestions];
    }

    if (destinationFilter.length === 0) {
      destinationRegions = destinationRegions.filter(
        (reg) => reg.RegionID !== undefined
      );
    } else {
      destinationRegions = destinationRegions.filter(
        (reg) =>
          reg.value
            .toLowerCase()
            .indexOf(destinationFilter.toLocaleLowerCase()) > -1
      );
      destinationCities = destinationCities.filter(
        (reg) =>
          reg.value
            .toLowerCase()
            .indexOf(destinationFilter.toLocaleLowerCase()) > -1
      );
      destinationSuggestions = destinationSuggestions.filter(
        (reg) =>
          (holidayType.flight
            ? reg.typ === "package"
            : reg.typ === "accommodation") &&
          reg.label
            .toLowerCase()
            .indexOf(destinationFilter.toLocaleLowerCase()) > -1
      );
    }

    let groups = [];

    if (destinationSuggestions.length > 0) {
      groups.push({
        label: "Unsere Empfehlungen",
        options: destinationSuggestions,
      });
    }

    if (destinationRegions.length > 0) {
      groups.push({ label: "Regionen", options: destinationRegions });
    }
    if (destinationCities.length > 0) {
      groups.push({ label: "Ferienorte", options: destinationCities });
    }

    if (destinationFilter.length > 3) {
      setIsLoading(true);
      axios
        .get(getHost() + "/hotels/" + encodeURIComponent(destinationFilter))
        .then((response) => {
          setIsLoading(false);
          let destinationHotels = [];
          setGeoDictionary(response.data.GeoDictionary);
          if (
            response.data.HotelDictionary &&
            Array.isArray(response.data.HotelDictionary.Hotel)
          ) {
            for (
              let i = 0;
              i < response.data.HotelDictionary.Hotel.length;
              i++
            ) {
              let hotel = response.data.HotelDictionary.Hotel[i];
              if (!hotel.HotelCodes.HotelGiataID) continue;
              destinationHotels.push({
                value: hotel.Name,
                label: hotel.Name,
                ...hotel,
                cityName: propertyFromArray(
                  response.data.GeoDictionary.Cities.City,
                  hotel.HotelLocationRef.CityID,
                  "CityID",
                  "Name"
                ),
              });
            }
          }
          if (destinationHotels.length > 0) {
            groups.push({ label: "Hotels", options: destinationHotels });
          }
          groups = reduce(groups);

          callback(groups);
        })
        .catch((e) => {
          groups = reduce(groups);

          callback(groups);
          setIsLoading(false);
          //console.log(e);
        });
    } else {
      groups = reduce(groups);

      callback(groups);
    }
  };

  groups = reduce(groups);

  let destinationstring = "";
  if (selectedDestination.length > 0) {
    for (let i = 0; i < selectedDestination.length; i++) {
      destinationstring =
        destinationstring +
        "<span>" +
        selectedDestination[i].label +
        ",<br/></span>";
    }
  }
  let stls = {
    valueContainer: (base, state) => ({
      ...base,
      textOverflow: "ellipsis",
      maxWidth: "90%",
      flexWrap: "wrap",
      whiteSpace: "nowrap",
      //overflow: "hidden",
    }),
    multiValue: (base, state) => ({
      ...base, //minWidth: "50%"
    }),
  };

  //stls = {};

  return (
    <InfoPopupHover
      trigger={
        <div>
          <Select
            async={true}
            closeMenuOnSelect={false}
            styles={stls}
            placeholder={
              "Land oder Region oder Hotelname (Mehrauswahl möglich)"
            }
            isMulti
            value={selectedDestination}
            onChange={(val) => {
              //
              dispatch(
                setSearchOption({
                  property: "destinationStations",
                  data: [],
                })
              );
              if (val) {
                if (
                  Array.isArray(val) &&
                  val.length > 0 &&
                  val[0].type === "suggestion"
                ) {
                  //console.log("choose suggestion");
                  suggestiontosearch(val[0], dispatch);
                  getFilters(dispatch, false)
                    .then(unwrapResult)
                    .then((originalPromiseResult) => {
                      updateairlines(
                        dispatch,
                        airlines,
                        originalPromiseResult,
                        airlinerank,
                        nolowcost,
                        linie
                      );
                    });

                  if (setStep) setStep(1);
                  return;
                }
                dispatch(setSelectedDestination(val));

                getFilters(dispatch, false)
                  .then(unwrapResult)
                  .then((originalPromiseResult) => {
                    updateairlines(
                      dispatch,
                      airlines,
                      originalPromiseResult,
                      airlinerank,
                      nolowcost,
                      linie
                    );
                  });
              }
              if (step === 0) {
                if (val.length === 0) {
                  if (setStep) setStep(-1);
                } else {
                  if (setStep) setStep(1);
                }
              } else if (step === 1) {
                if (val.length === 0) {
                  if (setStep) setStep(0);
                }
              }
            }}
            defaultOptions={groups}
            isLoading={isLoading}
            isClearable={true}
            loadOptions={loadOptions}
            onInputChange={handleInputChange}
            formatOptionLabel={(opt) => {
              //console.log(opt);
              return (
                <div>
                  {opt.type === "suggestion" ? (
                    <FaMapPin />
                  ) : opt.RegionGroupID ? (
                    <FaMapPin />
                  ) : opt.RegionID ? (
                    <FaMapMarkerAlt />
                  ) : opt.CityID ? (
                    <FaCity />
                  ) : (
                    <FaHome />
                  )}
                  &nbsp;
                  <b>{opt.label}</b>
                  <span>
                    {opt.HotelCodes && ", "}
                    {opt.HotelCodes &&
                      geoDictionary &&
                      geoDictionary.Cities &&
                      propertyFromArray(
                        geoDictionary.Cities.City,
                        opt.HotelLocationRef.CityID,
                        "CityID",
                        "Name"
                      )}
                  </span>
                  <span>
                    {opt.HotelCodes && ", "}
                    {opt.HotelCodes &&
                      geoDictionary &&
                      geoDictionary.Countries &&
                      propertyFromArray(
                        geoDictionary.Countries.Country,
                        opt.HotelLocationRef.CountryCode,
                        "CountryCode",
                        "Name"
                      )}
                  </span>
                  {opt.HotelCount && <span> ({opt.HotelCount} Hotels)</span>}
                </div>
              );
            }}
            formatGroupLabel={(grp) => <div>{grp.label}</div>}
          />
        </div>
      }
      content={
        selectedDestination.length !== 0
          ? destinationstring
          : "Wählen Sie Ihr Reiseziel"
      }
      position={"right center"}
    ></InfoPopupHover>
  );
}
