import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { ScrollSpy } from "bootstrap";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import FlightLocations from "../components/FlightLocations";
import OfferFlight from "../components/OfferFlight";
import { setView } from "amadeusredux/lib/systemSlice";
import ShareOptions from "../components/ShareOptions";
import { views } from "amadeusredux/lib/utils";
import MobileOfferFlight from "../components/MobileOfferFlight";
import CancellationOptions from "../CancellationOptions";
import {
  checkOfferAction,
  searchAlternativeTransports,
  setHotelOfferInfos,
  setOfferFlexbooked,
  setOfferId,
} from "amadeusredux/lib/hotelSlice";
import InfoPopupHover from "../components/InfoPopupHover";
import { FormattedDate } from "react-intl";
import { AiOutlineMail } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";

import {
  FaHeart,
  FaInfoCircle,
  FaPlane,
  FaShareAlt,
  FaLongArrowAltLeft,
  FaLongArrowAltRight,
  FaCaretLeft,
  FaBed,
} from "react-icons/fa";
import { HiOutlineRefresh } from "react-icons/hi";

import { MdContentCopy } from "react-icons/md";
import {
  airportcity,
  boardTypes,
  propertyFromArray,
  roomViews,
  currencySymbol,
  paymentOptions,
  roomTypes,
  stars,
} from "../utils";
import MobileVoucherRooms from "./MobileVoucherRooms";
import { getElementError } from "@testing-library/react";
import { availabilityAndPriceCheck } from "amadeusredux/lib/api";
import AvailabilityButton from "../AvailabilityButton";
import MobileOfferVoucher from "../components/MobileOfferVoucher";

const nf1 = new Intl.NumberFormat("de-DE", {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});
const nf = new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2 });

function MobileOfferView(props) {
  const {
    offers,
    redrawVoucher,
    //setredrawVoucher,
    roomTypes,
    offer,
    mode,
    otherRoomOffers,
    flexbooked,
    reservationselected,
    betterOffersButton,
    outboundStations,
    inboundStations,
    fullinfo,
    selectedTOMarketingAction,
    selectedTAMarketingAction,
    otherCancelOffers,
    otherRailOffer,
    otherTransferOffer,
    otherBoardOffers,
    otherViewOffers,
    outboundFlighttime,
    setOutboundFlighttime,
  } = props;
  const dispatch = useDispatch();
  const hotelOfferInfos = useSelector((state) => state.hotel.hotelOfferInfos);
  const stations = hotelOfferInfos.StationDictionary;
  const holidayType = useSelector((state) => state.search.holidayType);
  const offerdiv = React.useRef(null);
  const infos = useSelector((state) => state.properties.infos);
  const sessionId = useSelector((state) => state.system.sessionId);
  const checkids = useSelector((state) => state.hotel.checkids);
  const intl = useIntl();

  const df = new Intl.DateTimeFormat(intl.locale, {
    weekday: "short",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const alternativeTransports = useSelector((state) =>
    state.hotel.alternativeTransports[offer?.OfferID]
      ? state.hotel.alternativeTransports[offer.OfferID]
      : {}
  );

  const [flexSelected, setFlexSelected] = React.useState(false);

  const [showBetterOffers, setShowBetterOffers] = React.useState(false);

  let hotels = hotelOfferInfos.HotelDictionary;

  const hotel = hotels
    ? hotels.Hotel.find(
        (e) =>
          e.HotelID ===
          offer?.OfferServices?.Package?.Accommodation?.HotelRef?.HotelID
      )
    : {};
  let geodata = hotelOfferInfos.GeoDictionary;
  let transferincl = false;

  let offerrealprice = 0;

  if (offer?.bookinginfo?.PriceInformation?.TotalPrice?.Amount?._) {
    offerrealprice =
      offer?.bookinginfo?.PriceInformation?.TotalPrice?.Amount?._ * 1;
  } else {
    offerrealprice =
      offer.PriceInfo.Price.value * (offer.adults * 1 + offer.children * 1);
  }

  let transferofferrealprice = 0;

  if (otherTransferOffer.OfferID) {
    if (
      otherTransferOffer?.bookinginfo?.PriceInformation?.TotalPrice?.Amount?._
    ) {
      transferofferrealprice =
        otherTransferOffer?.bookinginfo?.PriceInformation?.TotalPrice?.Amount
          ?._ * 1;
    } else {
      transferofferrealprice =
        otherTransferOffer.PriceInfo.Price.value *
        (offer.adults * 1 + offer.children * 1);
    }
    if (
      Array.isArray(otherTransferOffer?.MarketingActions) &&
      otherTransferOffer.MarketingActions.length > 0 &&
      otherTransferOffer.MarketingActions[0].flexconditions?.flexoptions
        ?.flexoptionprice &&
      flexSelected
    ) {
      transferofferrealprice +=
        otherTransferOffer.MarketingActions[0].flexconditions?.flexoptions
          ?.flexoptionprice * 1;
    }
  }

  let railofferrealprice = 0;

  if (otherRailOffer.OfferID) {
    if (otherRailOffer?.bookinginfo?.PriceInformation?.TotalPrice?.Amount?._) {
      railofferrealprice =
        otherRailOffer?.bookinginfo?.PriceInformation?.TotalPrice?.Amount?._ *
        1;
    } else {
      railofferrealprice =
        otherRailOffer.PriceInfo.Price.value *
        (offer.adults * 1 + offer.children * 1);
    }
    if (
      Array.isArray(otherRailOffer?.MarketingActions) &&
      otherRailOffer.MarketingActions.length > 0 &&
      otherRailOffer.MarketingActions[0].flexconditions?.flexoptions
        ?.flexoptionprice &&
      flexSelected
    ) {
      railofferrealprice +=
        otherRailOffer.MarketingActions[0].flexconditions?.flexoptions
          ?.flexoptionprice * 1;
    }
  }

  if (offer?.OfferProperties?.TransferInfos) {
    if (
      Array.isArray(offer.OfferProperties.TransferInfos) &&
      offer.OfferProperties.TransferInfos.includes("TransferIncluded")
    ) {
      transferincl = true;
    }
  }
  let railandfly = false;
  if (offer?.OfferProperties?.OfferIncludes) {
    if (
      Array.isArray(offer.OfferProperties.OfferIncludes) &&
      offer.OfferProperties.OfferIncludes.includes("RailAndFly")
    ) {
      railandfly = true;
    }
  }

  let rentalCar = false;
  if (offer?.OfferProperties?.OfferIncludes) {
    if (
      Array.isArray(offer.OfferProperties.OfferIncludes) &&
      offer.OfferProperties.OfferIncludes.includes("RentalCar")
    ) {
      rentalCar = true;
    }
  }
  let freeRebook = false;
  if (offer?.OfferProperties?.OfferIncludes) {
    if (
      Array.isArray(offer.OfferProperties.OfferIncludes) &&
      offer.OfferProperties.OfferIncludes.includes("FreeRebooking")
    ) {
      freeRebook = true;
    }
  }

  let cancellationoption = "";
  if (offer?.OfferProperties?.CancellationOption) {
    cancellationoption = offer?.OfferProperties?.CancellationOption;
  }

  let realprice = 0;

  if (offer?.bookinginfo?.PriceInformation?.TotalPrice?.Amount?._) {
    realprice = offer?.bookinginfo?.PriceInformation?.TotalPrice?.Amount?._ * 1;
  }

  if (realprice === 0 && offer.PriceInfo?.Price.value) {
    realprice =
      offer.PriceInfo?.Price.value * (offer.adults * 1 + offer.children * 1);
  }

  let reducedprice = realprice;
  let flexprice = -1;

  if (mode !== "booking" && realprice && offer.flexbooked) {
    if (
      Array.isArray(offer.MarketingActions) &&
      offer.MarketingActions.length > 0 &&
      !isNaN(
        offer.MarketingActions[0].flexconditions?.flexoptions?.flexoptionprice
      ) &&
      offer.MarketingActions[0].flexconditions?.flexoptions?.flexoptionprice
    )
      realprice =
        realprice +
        offer.MarketingActions[0].flexconditions.flexoptions.flexoptionprice *
          1;
    flexprice =
      offer.MarketingActions[0].flexconditions.flexoptions.flexoptionprice * 1;
  }

  if (mode === "booking" && realprice && flexbooked) {
    if (
      Array.isArray(offer.MarketingActions) &&
      offer.MarketingActions.length > 0 &&
      !isNaN(
        offer.MarketingActions[0].flexconditions?.flexoptions?.flexoptionprice
      ) &&
      offer.MarketingActions[0].flexconditions?.flexoptions?.flexoptionprice
    )
      realprice =
        realprice +
        offer.MarketingActions[0].flexconditions.flexoptions.flexoptionprice *
          1;
    flexprice =
      offer.MarketingActions[0].flexconditions.flexoptions.flexoptionprice * 1;
  }

  let offerstatus = 0;

  if (offer?.bookinginfo) {
    if (
      offer?.bookinginfo?.Status?.Status === "Available" ||
      offer?.bookinginfo?.Status?.Status === "ToConfirm" ||
      offer?.bookinginfo?.Status?.Status === "OnRequest"
    ) {
      offerstatus = 1;
    } else {
      offerstatus = -1;
    }
  }
  let columnclass = fullinfo
    ? "col-sm-12  col-md-12 col-lg-12 "
    : "col-sm-12  col-md-12 col-lg-12 ";
  //console.log(offer);
  let curdate = "";

  let curBoardType = propertyFromArray(
    boardTypes,
    offer.OfferServices.Package.Accommodation.BoardType,
    "value",
    "short"
  );
  let curFullBoardType = propertyFromArray(
    boardTypes,
    offer.OfferServices.Package.Accommodation.BoardType,
    "value",
    "label"
  );
  let allRoomOffers = [...roomTypes].sort(
    (a, b) =>
      a.offer?.PriceInfo?.Price.value * 1 > b.offer?.PriceInfo?.Price.value * 1
  );

  for (let i = 0; i < allRoomOffers.length; i++) {
    let off = allRoomOffers[i].offer;
    if (
      off &&
      !off?.bookinginfo &&
      Array.isArray(checkids) &&
      !checkids.includes(off.OfferID)
    )
      availabilityAndPriceCheck(
        dispatch,
        sessionId,
        off.OfferID,
        off.adults,
        off.children,
        undefined
      );
  }
  if (redrawVoucher && 1 == 0) {
    //console.log("REDRAW");
    let outbound = document.getElementById(
      "outbound" +
        outboundFlighttime?.outboundairport +
        outboundFlighttime?.time +
        outboundFlighttime?.arrivaltime
    );
    //console.log(outbound);
    if (outbound)
      outbound.scrollIntoView({ behavior: "smooth", block: "center" });
    let inbound = document.getElementById("inbound" + offer?.OfferID);
    //console.log(inbound);
    if (inbound)
      inbound.scrollIntoView({ behavior: "smooth", block: "center" });
    let room = document.getElementById("room" + offer?.OfferID);
    //console.log(room);
    if (room) room.scrollIntoView({ behavior: "smooth", block: "center" });
    //setredrawVoucher(false);
    //console.log(outboundStations);
  }

  return (
    <>
      <div
        ref={offerdiv}
        className={
          "card mb-2 mt-3 border border-5 " +
          (offerstatus == 0
            ? "border-warning "
            : offerstatus == 1
            ? "border-success "
            : "border-danger ")
        }
        style={{ borderRadius: 10 }}
      >
        <div
          style={{
            width: 250,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "-15px",
            textAlign: "center",
            backgroundColor: "white",
          }}
        >
          {offerstatus == 0
            ? "Flug/Angebot wird geprüft."
            : offerstatus == 1
            ? "Diese Reise ist verfügbar"
            : "Diese Reise ist nicht verfügbar"}
        </div>
        <div className="row no-gutters"></div>
        <div className="mt-2 d-flex justify-content-center">
          <ShareOptions
            offer={offer}
            sessionId={sessionId}
            offerInfos={hotelOfferInfos}
            hotel={hotel}
          />
        </div>
        <div className="container mt-2 text-left ">
          <MobileOfferVoucher
            offer={offer}
            mode={"planning"}
            stations={stations}
            flexbooked={flexbooked}
            selectedTAMarketingAction={selectedTAMarketingAction}
            selectedTOMarketingAction={selectedTOMarketingAction}
            reservationselected={reservationselected}
          />

          {(otherBoardOffers.length > 0 ||
            otherRoomOffers.length > 0 ||
            otherViewOffers.length > 0 ||
            otherTransferOffer.OfferID ||
            otherRailOffer.OfferID ||
            otherCancelOffers.length > 0) && (
            <div
              style={{ width: 280 }}
              className="text-body font-weight-bold mt-3"
              onClick={() => setShowBetterOffers(!showBetterOffers)}
            >
              <span>
                {" "}
                <i class="bi bi-trophy"></i>&nbsp;&nbsp;
              </span>
              <span className="companybutton">
                 Wählen Sie bessere Leistungen
              </span>
            </div>
          )}

          {(otherBoardOffers.length > 0 ||
            otherViewOffers.length > 0 ||
            otherTransferOffer.OfferID ||
            otherRoomOffers.length > 0 ||
            otherRailOffer.OfferID ||
            otherCancelOffers.length > 0 ||
            (Array.isArray(offer.MarketingActions) &&
              offer.MarketingActions.length > 0 &&
              !isNaN(
                offer.MarketingActions[0].flexconditions?.flexoptions
                  ?.flexoptionprice
              ) &&
              offer.MarketingActions[0].flexconditions?.flexoptions
                ?.flexoption)) &&
            showBetterOffers && (
              <div className="mb-2">
                {otherViewOffers.length > 0 && (
                  <div className="mb-2">
                    {otherViewOffers.length > 0 && (
                      <p className="mt-3 fw-semibold">
                        <small>Aussicht</small>
                      </p>
                    )}
                    {otherViewOffers.map((e, i) => {
                      let erealprice = 0;

                      if (
                        e?.offer?.bookinginfo?.PriceInformation?.TotalPrice
                          ?.Amount?._
                      ) {
                        erealprice =
                          e?.offer?.bookinginfo?.PriceInformation?.TotalPrice
                            ?.Amount?._ * 1;
                      } else {
                        erealprice =
                          e.offer.PriceInfo.Price.value *
                          (offer.adults * 1 + offer.children * 1);
                      }
                      if (
                        Array.isArray(e?.offer?.MarketingActions) &&
                        e.offer.MarketingActions.length > 0 &&
                        e.offer.MarketingActions[0].flexconditions?.flexoptions
                          ?.flexoptionprice &&
                        flexSelected
                      ) {
                        erealprice +=
                          e.offer.MarketingActions[0].flexconditions
                            ?.flexoptions?.flexoptionprice * 1;
                      }

                      return (
                        <div className="row">
                          <div className="col-12">
                            {" "}
                            <small>{e.label}</small>
                          </div>
                          <div
                            className="col-5"
                            onClick={() => {
                              //window.scrollTo(0, offsetTop);
                              //dispatch(setOfferId(e.offer.OfferID));
                            }}
                          >
                            <small>
                              {erealprice > offerrealprice ? (
                                <>+&nbsp;</>
                              ) : (
                                <>-&nbsp;</>
                              )}
                              {nf.format(
                                Math.abs(erealprice - offerrealprice).toFixed(0)
                              )}{" "}
                              {currencySymbol(
                                e.offer.PriceInfo.Price.CurrencyCode
                              )}
                              &nbsp;&nbsp;(
                              {erealprice}{" "}
                              {currencySymbol(
                                e.offer.PriceInfo.Price.CurrencyCode
                              )}
                              )
                            </small>
                          </div>
                          <div className="col-7">
                            <small>
                              <AvailabilityButton
                                offer={e.offer}
                                hotel={hotel}
                                betterOption={true}
                                inboundView={views.hotelcrossview}
                                availableAction={(off) => {
                                  dispatch(
                                    setOfferFlexbooked({
                                      offerId: e.offer.OfferID,
                                      flexbooked: flexSelected,
                                    })
                                  );
                                  //window.scrollTo(0, offsetTop);
                                  dispatch(setOfferId(e.offer.OfferID));
                                }}
                                availableText={"übernehmen"}
                              />
                            </small>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {otherTransferOffer.OfferID && (
                  <div className="mb-2">
                    {otherTransferOffer.OfferID && (
                      <p className="mt-3 fw-semibold">
                        <small>Transfer</small>
                      </p>
                    )}
                    {otherTransferOffer.OfferID && (
                      <div className="row">
                        <div className="col-12">
                          <small>
                            {transferincl
                              ? "Hoteltransfer im Zielgebiet entfernen "
                              : "Hoteltransfer im Zielgebiet zubuchen"}
                          </small>
                        </div>
                        <div
                          className="col-5"
                          onClick={() => {
                            //window.scrollTo(0, offsetTop);
                            //dispatch(setOfferId(otherTransferOffer.OfferID));
                          }}
                        >
                          {" "}
                          <small>
                            {transferofferrealprice > offerrealprice ? (
                              <>+&nbsp;</>
                            ) : (
                              <>-&nbsp;</>
                            )}
                            {nf.format(
                              Math.abs(
                                transferofferrealprice - offerrealprice
                              ).toFixed(0)
                            )}{" "}
                            {currencySymbol(
                              otherTransferOffer.PriceInfo.Price.CurrencyCode
                            )}
                            &nbsp;&nbsp;(
                            {transferofferrealprice}{" "}
                            {currencySymbol(
                              otherTransferOffer.PriceInfo.Price.CurrencyCode
                            )}
                            )
                          </small>
                        </div>
                        <div className="col-7">
                          <small>
                            <AvailabilityButton
                              offer={otherTransferOffer}
                              hotel={hotel}
                              betterOption={true}
                              inboundView={views.hotelcrossview}
                              availableAction={(off) => {
                                dispatch(
                                  setOfferFlexbooked({
                                    offerId: otherTransferOffer.OfferID,
                                    flexbooked: flexSelected,
                                  })
                                );
                                // window.scrollTo(0, offsetTop);
                                dispatch(
                                  setOfferId(otherTransferOffer.OfferID)
                                );
                              }}
                              availableText={"übernehmen"}
                            />{" "}
                          </small>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {otherRailOffer.OfferID && (
                  <div className="mb-2">
                    {otherRailOffer.OfferID && (
                      <p className="mt-3 fw-semibold">
                        <small>Zug zum Flug</small>
                      </p>
                    )}
                    {otherRailOffer.OfferID && (
                      <div className="row">
                        <div className="col-12">
                          {railandfly
                            ? "Zug zum Flug entfernen"
                            : "Zug zum Flug zubuchen"}
                        </div>
                        <div
                          className="col-5"
                          onClick={() => {
                            //window.scrollTo(0, offsetTop);
                            //dispatch(setOfferId(otherRailOffer.OfferID));
                          }}
                        >
                          <small>
                            {" "}
                            {railofferrealprice > offerrealprice ? (
                              <>+&nbsp;</>
                            ) : (
                              <>-&nbsp;</>
                            )}
                            {nf.format(
                              Math.abs(
                                railofferrealprice - offerrealprice
                              ).toFixed(0)
                            )}{" "}
                            {currencySymbol(
                              otherRailOffer.PriceInfo.Price.CurrencyCode
                            )}
                            &nbsp;&nbsp;(
                            {railofferrealprice}{" "}
                            {currencySymbol(
                              otherRailOffer.PriceInfo.Price.CurrencyCode
                            )}
                            )
                          </small>
                        </div>
                        <div className="col-7">
                          <small>
                            <AvailabilityButton
                              offer={otherRailOffer}
                              hotel={hotel}
                              betterOption={true}
                              inboundView={views.hotelcrossview}
                              availableAction={(off) => {
                                dispatch(
                                  setOfferFlexbooked({
                                    offerId: otherRailOffer.OfferID,
                                    flexbooked: flexSelected,
                                  })
                                );
                                // window.scrollTo(0, offsetTop);
                                dispatch(setOfferId(otherRailOffer.OfferID));
                              }}
                              availableText={"übernehmen"}
                            />
                          </small>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {otherCancelOffers.length > 0 && (
                  <div className="mb-2">
                    {otherCancelOffers.length > 0 && (
                      <p className="mt-3 fw-semibold">
                        <small>Stornooptionen</small>
                      </p>
                    )}
                    {otherCancelOffers.map((e, i) => {
                      let erealprice = 0;

                      if (
                        e?.offer?.bookinginfo?.PriceInformation?.TotalPrice
                          ?.Amount?._
                      ) {
                        erealprice =
                          e?.offer?.bookinginfo?.PriceInformation?.TotalPrice
                            ?.Amount?._ * 1;
                      } else {
                        erealprice =
                          e.offer.PriceInfo.Price.value *
                          (offer.adults * 1 + offer.children * 1);
                      }

                      if (
                        Array.isArray(e?.offer?.MarketingActions) &&
                        e.offer.MarketingActions.length > 0 &&
                        e.offer.MarketingActions[0].flexconditions?.flexoptions
                          ?.flexoptionprice &&
                        flexSelected
                      ) {
                        erealprice +=
                          e.offer.MarketingActions[0].flexconditions
                            ?.flexoptions?.flexoptionprice * 1;
                      }
                      return (
                        <div className="row">
                          <div className="col-12">
                            <small>{e.label}</small>
                          </div>
                          <div
                            className="col-5"
                            onClick={() => {
                              //window.scrollTo(0, offsetTop);
                              //dispatch(setOfferId(e.offer.OfferID));
                            }}
                          >
                            <small>
                              {erealprice > offerrealprice ? (
                                <>+&nbsp;</>
                              ) : (
                                <>-&nbsp;</>
                              )}
                              {nf.format(
                                Math.abs(erealprice - offerrealprice).toFixed(0)
                              )}{" "}
                              {currencySymbol(
                                e.offer.PriceInfo.Price.CurrencyCode
                              )}
                              &nbsp;&nbsp;(
                              {erealprice}{" "}
                              {currencySymbol(
                                e.offer.PriceInfo.Price.CurrencyCode
                              )}
                              )
                            </small>
                          </div>
                          <div className="col-7">
                            <small>
                              <AvailabilityButton
                                offer={e.offer}
                                hotel={hotel}
                                betterOption={true}
                                inboundView={views.hotelcrossview}
                                availableAction={(off) => {
                                  dispatch(
                                    setOfferFlexbooked({
                                      offerId: e.offer.OfferID,
                                      flexbooked: flexSelected,
                                    })
                                  );
                                  //window.scrollTo(0, offsetTop);
                                  dispatch(setOfferId(e.offer.OfferID));
                                }}
                                availableText={"übernehmen"}
                              />
                            </small>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {otherBoardOffers.length > 0 && (
                  <div>
                    {otherBoardOffers.length > 0 && (
                      <p className="mt-3  fw-semibold">
                        <small>Verpflegung</small>
                      </p>
                    )}
                    {otherBoardOffers.map((e, i) => {
                      let erealprice = 0;

                      if (
                        e?.offer?.bookinginfo?.PriceInformation?.TotalPrice
                          ?.Amount?._
                      ) {
                        erealprice =
                          e?.offer?.bookinginfo?.PriceInformation?.TotalPrice
                            ?.Amount?._ * 1;
                      } else {
                        erealprice =
                          e.offer.PriceInfo.Price.value *
                          (offer.adults * 1 + offer.children * 1);
                      }

                      if (
                        Array.isArray(e?.offer?.MarketingActions) &&
                        e.offer.MarketingActions.length > 0 &&
                        e.offer.MarketingActions[0].flexconditions?.flexoptions
                          ?.flexoptionprice &&
                        flexSelected
                      ) {
                        erealprice +=
                          e.offer.MarketingActions[0].flexconditions
                            ?.flexoptions?.flexoptionprice * 1;
                      }

                      return (
                        <div className="row">
                          <div className="col-12">
                            <small>{e.label}</small>
                          </div>
                          <div
                            className="col-5"
                            onClick={() => {
                              //window.scrollTo(0, offsetTop);
                              //dispatch(setOfferId(e.offer.OfferID));
                            }}
                          >
                            <small>
                              {erealprice > offerrealprice ? (
                                <>+&nbsp;</>
                              ) : (
                                <>-&nbsp;</>
                              )}
                              {nf.format(
                                Math.abs(erealprice - offerrealprice).toFixed(0)
                              )}{" "}
                              {currencySymbol(
                                e.offer.PriceInfo.Price.CurrencyCode
                              )}
                              &nbsp;&nbsp;(
                              {erealprice}{" "}
                              {currencySymbol(
                                e.offer.PriceInfo.Price.CurrencyCode
                              )}
                              )
                            </small>
                          </div>
                          <div className="col-7">
                            <small>
                              <AvailabilityButton
                                offer={e.offer}
                                hotel={hotel}
                                betterOption={true}
                                availableAction={(off) => {
                                  dispatch(
                                    setOfferFlexbooked({
                                      offerId: e.offer.OfferID,
                                      flexbooked: flexSelected,
                                    })
                                  );
                                  //window.scrollTo(0, offsetTop);
                                  dispatch(setOfferId(e.offer.OfferID));
                                }}
                                availableText={"übernehmen"}
                              />
                            </small>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {otherRoomOffers.length > 0 && (
                  <div>
                    {otherRoomOffers.length > 0 && (
                      <p className="mt-3  fw-semibold">
                        <small>Zimmerart</small>
                      </p>
                    )}
                    {otherRoomOffers.map((e, i) => {
                      let erealprice = 0;

                      if (
                        e?.offer?.bookinginfo?.PriceInformation?.TotalPrice
                          ?.Amount?._
                      ) {
                        erealprice =
                          e?.offer?.bookinginfo?.PriceInformation?.TotalPrice
                            ?.Amount?._ * 1;
                      } else {
                        erealprice =
                          e.offer.PriceInfo.Price.value *
                          (offer.adults * 1 + offer.children * 1);
                      }

                      if (
                        Array.isArray(e?.offer?.MarketingActions) &&
                        e.offer.MarketingActions.length > 0 &&
                        e.offer.MarketingActions[0].flexconditions?.flexoptions
                          ?.flexoptionprice &&
                        flexSelected
                      ) {
                        erealprice +=
                          e.offer.MarketingActions[0].flexconditions
                            ?.flexoptions?.flexoptionprice * 1;
                      }

                      return (
                        <div className="row">
                          <div className="col-12">
                            <small>{e.label}</small>
                          </div>
                          <div
                            className="col-5"
                            onClick={() => {
                              //window.scrollTo(0, offsetTop);
                              //dispatch(setOfferId(e.offer.OfferID));
                            }}
                          >
                            <small>
                              {erealprice > offerrealprice ? (
                                <>+&nbsp;</>
                              ) : (
                                <>-&nbsp;</>
                              )}
                              {nf.format(
                                Math.abs(erealprice - offerrealprice).toFixed(0)
                              )}{" "}
                              {currencySymbol(
                                e.offer.PriceInfo.Price.CurrencyCode
                              )}
                              &nbsp;&nbsp;(
                              {erealprice}{" "}
                              {currencySymbol(
                                e.offer.PriceInfo.Price.CurrencyCode
                              )}
                              )
                            </small>
                          </div>
                          <div className="col-7">
                            <small>
                              <AvailabilityButton
                                offer={e.offer}
                                hotel={hotel}
                                betterOption={true}
                                availableAction={(off) => {
                                  dispatch(
                                    setOfferFlexbooked({
                                      offerId: e.offer.OfferID,
                                      flexbooked: flexSelected,
                                    })
                                  );
                                  //window.scrollTo(0, offsetTop);
                                  dispatch(setOfferId(e.offer.OfferID));
                                }}
                                availableText={"übernehmen"}
                              />
                            </small>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
        </div>
      </div>
    </>
  );
}

export default MobileOfferView;
