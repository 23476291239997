import { configureStore } from "@reduxjs/toolkit";
import systemReducer from "amadeusredux/lib/systemSlice";
import propertiesReducer from "amadeusredux/lib/propertiesSlice";
import searchReducer from "amadeusredux/lib/searchSlice";
import hotelReducer from "amadeusredux/lib/hotelSlice";
import offersReducer from "amadeusredux/lib/offersSlice";
import filterReducer from "amadeusredux/lib/filterSlice";
import bookingReducer from "amadeusredux/lib/bookingSlice";
import flightReducer from "amadeusredux/lib/flightSlice";

//import logger from "redux-logger";

export const store = configureStore({
  reducer: {
    system: systemReducer,
    properties: propertiesReducer,
    search: searchReducer,
    hotel: hotelReducer,
    offers: offersReducer,
    filter: filterReducer,
    booking: bookingReducer,
    flight: flightReducer,
  },
  //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  //devTools: process.env.NODE_ENV !== "production",
});
