import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PriceChange from "./PriceChange.js";
import { setOfferFlexbooked, setOfferId } from "amadeusredux/lib/hotelSlice.js";
import { currencySymbol } from "../utils.js";
import { EMPTY_ELEMENT } from "amadeusredux/lib/utils.js";
export default function BoardtypeTable(props) {
  const {
    offer,
    openSubSelect,
    tourOperators,
    allBoards,
    travellerfactor,
    showalltouroperators,
    setFlexSelected,
    setCookie,
    //setredrawVoucher,
  } = props;
  let tableheight = Math.min(Math.max(tourOperators.length * 80, 80), 400);

  const dispatch = useDispatch();
  const [toSort, setTosort] = React.useState(false);
  const sessionId = useSelector((state) => state.system.sessionId);
  return (
    <div
      className="overflow-auto"
      style={{
        maxHeight: window.innerHeight / 3,
        height: (showalltouroperators ? tableheight : 50) + "px",
      }}
    >
      {tourOperators.length > 5 && (
        <p>
          <small>Scrollen Sie durch die Veranstalterangebote</small>
        </p>
      )}
      <table
        className="table table-striped"
        style={{
          fontFamily: "arial, sans-serif",
          fontWeight: 400,
          fontSize: "10pt",
          borderCollapse: "collapse",
          height: "100%",
          width: "100%",
          scrollbarWidth: "thin",
        }}
      >
        <thead className="bg-light sticky-top">
          <tr>
            <th
              style={{
                position: "sticky",
                left: "0px",
                minWidth: "100px",
                backgroundColor: "white",
              }}
              onClick={() => setTosort(!toSort)}
            >
              Veranstalter
            </th>
            {allBoards.map((board, idx) => {
              return (
                <>
                  <th style={{ whiteSpace: "nowrap" }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;{board.label}
                  </th>
                </>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {[...tourOperators]
            .sort((a, b) => (toSort ? a.label.localeCompare(b.label) : 0))
            .map((tourOperator, idx) => {
              return (
                <>
                  <tr style={{ lineHeight: "35px" }}>
                    <td
                      className="mb-0 text"
                      style={{
                        position: "sticky",
                        left: "0px",
                        minWidth: "100px",
                        backgroundColor: "white",
                      }}
                    >
                      {tourOperator.label}
                    </td>
                    {allBoards.map((bt, i) => {
                      let cheapest = tourOperator.offers.find(
                        (e) => e.boardtype === bt.value
                      );
                      return (
                        <>
                          <td
                            className={
                              "mb-0 text text-nowrap " +
                              (bt.value ===
                                offer?.OfferServices?.Package?.Accommodation
                                  ?.BoardType &&
                              tourOperator.code ===
                                offer?.TourOperator.TourOperatorCode
                                ? " smallbutton  "
                                : "")
                            }
                            style={{ textAlign: "left" }}
                          >
                            <span
                              className="text-nowrap"
                              onClick={() => {
                                setFlexSelected(false);

                                dispatch(
                                  setOfferFlexbooked({
                                    offerId: cheapest.offerid,
                                    flexbooked: false,
                                  })
                                );

                                dispatch(setOfferId(cheapest.offerid));
                                if (sessionId != EMPTY_ELEMENT) {
                                  setCookie("firstTimeOncrosscheck", "0", {
                                    expires: new Date(2099, 1, 1),
                                    path: "/",
                                  });
                                }
                                openSubSelect("touroperators");
                                //setredrawVoucher(true);
                                //dispatch(checkOfferAction({ offerId: cheapest.offerid, offer: offers.find((e) => e.OfferID === cheapest.offerid) }));
                                //setOperator(cheapest.value);
                              }}
                            >
                              {cheapest && cheapest.price > 0 ? (
                                <>
                                  <span
                                    className={
                                      " " +
                                      (cheapest.checked === -1
                                        ? bt.value ===
                                            offer?.OfferServices?.Package
                                              ?.Accommodation?.BoardType &&
                                          tourOperator.code ===
                                            offer?.TourOperator.TourOperatorCode
                                          ? " selectedbutton  "
                                          : " optionbutton"
                                        : bt.value ===
                                            offer?.OfferServices?.Package
                                              ?.Accommodation?.BoardType &&
                                          tourOperator.code ===
                                            offer?.TourOperator.TourOperatorCode
                                        ? " selectedbutton  "
                                        : " smallbutton")
                                    }
                                  >
                                    <span
                                      className={
                                        cheapest.checked === -1
                                          ? "text-decoration-line-through"
                                          : ""
                                      }
                                    >
                                      ab&nbsp;
                                      {Math.round(
                                        cheapest?.price * travellerfactor
                                      )}
                                      &nbsp;
                                      {currencySymbol(
                                        offer?.PriceInfo?.Price?.CurrencyCode
                                      )}
                                    </span>
                                  </span>
                                  &nbsp;
                                  <PriceChange
                                    price={cheapest.price}
                                    checked={cheapest.checked}
                                    cacheprice={cheapest.cacheprice}
                                    travellerfactor={travellerfactor}
                                    currencyCode={"EUR"}
                                  />
                                  &nbsp;
                                </>
                              ) : (
                                ""
                              )}
                            </span>
                          </td>
                        </>
                      );
                    })}
                  </tr>
                </>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
