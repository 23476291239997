import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setView } from "amadeusredux/lib/systemSlice";
import { nationalities, views } from "../utils";
import { FaHackerrank } from "react-icons/fa";
import { FormattedDate } from "react-intl";

const getGender = (gen) => {
  if (gen === "MALE") return "Herr";
  if (gen === "FEMALE") return "Frau";
  if (gen === "UNKNOWN") return "";
  return "";
};

const getNationality = (code) => {
  if (!nationalities.find((e) => e.code === code)) return "";
  return nationalities.find((e) => e.code === code).name_de;
};

export default function MobileConfirmationView(props) {
  const dispatch = useDispatch();
  const hotelOfferInfos = useSelector((state) => state.hotel.hotelOfferInfos);

  const offerId = useSelector((state) => state.hotel.offerId);
  const offers = hotelOfferInfos.Offers?.Offer
    ? [...hotelOfferInfos.Offers?.Offer]
    : [];
  const offer = offers.find((e) => e.OfferID === offerId)
    ? offers.find((e) => e.OfferID === offerId)
    : {};
  let adultCount = 0;
  let childCount = 0;

  const bookedData = useSelector((state) => state.booking.bookedData);

  return (
    <div className="row">
      <div className="col-12">
        Vielen Dank für{" "}
        {bookedData.onlyReservation && <span>die Reservierung</span>}
        {!bookedData.onlyReservation && <span>die verbindliche Buchung</span>} .
        Wir haben Ihnen per Email den Eingang Ihrer Buchung bereits bestätigt.
        Unser Service Team wird sich in Kürze bei Ihnen melden.
      </div>
      <div className="col-12">Ihre Buchung zum ausdrucken</div>

      {bookedData.insuranceRequest && (
        <div className="col-12">
          {bookedData.insurance?.Description?.Title}&nbsp;&nbsp;
          {bookedData.insurance?.OfferCost?._}{" "}
          {bookedData.insurance?.OfferCost?.$?.Currency}
        </div>
      )}

      {bookedData.couponcode && (
        <div className="col-12" style={{ display: "flex" }}>
          <div >Gutscheincode</div>
          <div>{bookedData.couponcode}</div>
        </div>
      )}
      {bookedData.couponvalue?.text && (
        
          <div
            className="col-12"
            style={{ display: "flex" }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: bookedData.couponvalue.text }}
            ></div>
          </div>
       
      )}
      {Array.isArray(bookedData.selectedTOMarketingAction) &&
        bookedData.selectedTOMarketingAction.map((e) => {
          return (
            <div className="col-12">
              Sie haben die Aktion {e.MarketingProperties.text} zugebucht
            </div>
          );
        })}

      {Array.isArray(bookedData.selectedTAMarketingAction) &&
        bookedData.selectedTAMarketingAction.map((e) => {
          return (
            <div className="col-12">
              Sie haben die Aktion {e.MarketingProperties.text} zugebucht
            </div>
          );
        })}

      <div className="col-12">Reiseteilnehmer</div>
      <div className="col-12">
        <div>Reiseanmelder</div>
        <div>
          {getGender(bookedData.bookingData.customerGender)}&nbsp;
          {bookedData.bookingData.customerFirstName}&nbsp;
          {bookedData.bookingData.customerLastName}&nbsp; geb.&nbsp;
          <FormattedDate value={bookedData.bookingData.customerBirthDate} />
          &nbsp;StA.:&nbsp;
          {getNationality(bookedData.bookingData.customerCountryCode)}
        </div>
      </div>

      <div className="col-12">
        <div>Adresse</div>
        <div>
          {bookedData.bookingData.customerStreet},&nbsp;
          {bookedData.bookingData.customerPostalCode}&nbsp;
          {bookedData.bookingData.customerCity}
        </div>
      </div>

      <div className="col-12">
        <div>Kontakt</div>
        <div>
          Festnetz:&nbsp;{bookedData.bookingData.customerPhone}
          &nbsp;&nbsp;Mobil:&nbsp;{bookedData.bookingData.customerMobile}
          &nbsp;&nbsp;E-Mail:&nbsp;{bookedData.bookingData.customerEmail}
        </div>
      </div>
      {Array.isArray(bookedData.bookingData.travellers) &&
        bookedData.bookingData.travellers.map((traveller) => {
          adultCount =
            adultCount + (traveller.ageQualifier === "ADULT" ? 1 : 0);
          childCount =
            childCount + (traveller.ageQualifier === "ADULT" ? 0 : 1);
          return (
            <div className="col-12">
              <div>
                {traveller.ageQualifier === "ADULT" ? (
                  <span>{adultCount}. Erwachsener</span>
                ) : (
                  <span>{childCount}. Kind</span>
                )}
              </div>
              <div>
                {getGender(traveller.gender)}&nbsp;{traveller.firstName}&nbsp;
                {traveller.lastName}&nbsp; geb.&nbsp;
                <FormattedDate value={traveller.birthDate} />
                &nbsp;StA.:&nbsp;{getNationality(traveller.countryCode)}
              </div>
            </div>
          );
        })}
      {/* bookedData.binding */}
      <div className="col-12">
        {bookedData.onlyReservation && (
          <span>Sie haben unverbindlich reserviert.</span>
        )}
        {!bookedData.onlyReservation && (
          <span>Sie haben kostenpflichtig gebucht.</span>
        )}
      </div>
      <br />
      <br />
      <br />
      <br />
      {/*<div
        onClick={() => {
          dispatch(setView(views.bookingview));
        }}
      >
        Book
      </div>*/}

      <div className="col-12">
        <div className="growbox" />
        <div>
          {!bookedData.pdf && (
            <button onClick={() => window.print()}>Drucken</button>
          )}
          {bookedData.pdf && (
            <a
              href={bookedData.pdf}
              target="pdfdownload"
              download="booking.pdf"
            >
              Download
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
