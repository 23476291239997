import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import axios from "axios";
import { getHost, views } from "./utils";

axios.defaults.headers.common["AgencyID"] = window.configData.AGENCYID;
axios.defaults.headers.common["Version"] = process.env.REACT_APP_VERSION;
axios.defaults.headers.common["App"] = window.configData.APP;

export const availableOfferData = createAsyncThunk(
  "booking/availableOfferData",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(
        getHost() +
          "/availableOfferData?SessionID=" +
          encodeURIComponent(payload.SessionID)
      );
      //console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getInsurance = createAsyncThunk(
  "booking/getInsurance",
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    //console.log("+++++++++++++++++++++++++", state.booking.offerData);
    let prvid = state.booking.offerData.Allowed3PProviders.Insurance.ProviderID;
    //  console.log(".....#######################", prvid);
    /*if(prvid.indexOf(","))
    {
      privid = prvid.split(",")[0];
    }*/
    if (Array.isArray(prvid)) {
      prvid = prvid.join(",");
    }
    let d = {};
    try {
      let prvs = prvid.split(",");
      for (let i = 0; i < prvs.length; i++) {
        let response = await axios.get(
          getHost() +
            "/insurancelist?ProviderID=" +
            encodeURIComponent(prvs[i]) +
            "&SessionID=" +
            encodeURIComponent(payload.SessionID)
        );

        if (
          response.data["ns2:GetInsuranceOfferListRS"]?.Offers?.Offer?.OfferID
        ) {
          response.data["ns2:GetInsuranceOfferListRS"].Offers.Offer = [
            response.data["ns2:GetInsuranceOfferListRS"].Offers.Offer,
          ];
        }
        if (i === 0) d = response.data;
        else {
          if (
            Array.isArray(
              response.data["ns2:GetInsuranceOfferListRS"]?.Offers?.Offer
            )
          ) {
            if (
              Array.isArray(d["ns2:GetInsuranceOfferListRS"]?.Offers?.Offer)
            ) {
              for (
                let k = 0;
                k <
                response.data["ns2:GetInsuranceOfferListRS"]?.Offers?.Offer
                  .length;
                k++
              ) {
                d["ns2:GetInsuranceOfferListRS"]?.Offers?.Offer.push(
                  response.data["ns2:GetInsuranceOfferListRS"]?.Offers?.Offer[k]
                );
              }
            } else {
              d = response.data;
            }
          }
        }
      }
      return d;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const sendBookedData = createAsyncThunk(
  "booking/sendBooked",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post(getHost() + "/sendbookeddata", {
        ...payload,
      });
      //console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const sendLink2Agency = createAsyncThunk(
  "booking/sendlink2agency",
  async (payload, thunkAPI) => {
    //console.log("hier");
    try {
      const response = await axios.post(getHost() + "/sendlink2agency", {
        ...payload,
      });
      //console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getTermsAndConditions = createAsyncThunk(
  "booking/termsAndConditions",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(
        getHost() +
          "/termsandconditions?touroperator=" +
          payload.touroperator +
          "&startdate=" +
          payload.startdate
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const checkCouponcode = createAsyncThunk(
  "booking/checkcouponcode",
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    try {
      const response = await axios.get(
        getHost() +
          "/checkcouponcode?offerid=" +
          payload.offerid +
          "&couponcode=" +
          state.search.couponcode +
          "&sessionid=" +
          state.system.sessionId
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getPassinfo = createAsyncThunk(
  "booking/passinfo",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(
        getHost() +
          "/passinfo?nat=" +
          payload.nat +
          "&countries=" +
          payload.countrycode
      );
      //console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const initialState = {
  offerData: {},
  insuranceData: {},
  bookedData: {},
  termsAndConditions: {},
  passinfo: [],
  theOffer: {},
  couponvalue: {},
  inboundView: views.hotelclassicview,
};

export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setTheOffer: (state, action) => {
      state.theOffer = action.payload;
    },
    setBookedData: (state, action) => {
      state.bookedData = action.payload;
    },
    setInboundView: (state, action) => {
      state.inboundView = action.payload;
    },
    setPassinfo: (state, action) => {
      state.passinfo = action.payload;
    },
    setCouponvalue: (state, action) => {
      state.couponvalue = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(checkCouponcode.pending, (state, action) => {
      state.couponvalue = {};
    });
    builder.addCase(checkCouponcode.fulfilled, (state, action) => {
      state.couponvalue = action.payload.response;
    });
    builder.addCase(checkCouponcode.rejected, (state, action) => {
      state.couponvalue = {};
    });
    builder.addCase(availableOfferData.pending, (state, action) => {});
    builder.addCase(availableOfferData.fulfilled, (state, action) => {
      let d = action.payload["ns2:GetAvailableOfferDataRS"];

      state.offerData = d;
    });
    builder.addCase(availableOfferData.rejected, (state, action) => {});
    builder.addCase(getInsurance.pending, (state, action) => {});
    builder.addCase(getInsurance.fulfilled, (state, action) => {
      let d = action.payload["ns2:GetInsuranceOfferListRS"];

      state.insuranceData = d;
    });
    builder.addCase(getInsurance.rejected, (state, action) => {});
    builder.addCase(getTermsAndConditions.pending, (state, action) => {});
    builder.addCase(getTermsAndConditions.fulfilled, (state, action) => {
      let d = action.payload["ns2:GetTermsAndConditionsRS"];
      state.termsAndConditions = d;
    });
    builder.addCase(getTermsAndConditions.rejected, (state, action) => {});
    builder.addCase(getPassinfo.pending, (state, action) => {});
    builder.addCase(getPassinfo.fulfilled, (state, { payload, meta }) => {
      //let d = action.payload["ns2:GetTermsAndConditionsRS"];
      let pos = meta.arg.pos;

      let ps = [...state.passinfo];
      while (ps.length - 1 < pos) {
        ps.push({});
      }
      ps[pos] = payload;
      state.passinfo = ps;
    });
    builder.addCase(getPassinfo.rejected, (state, action) => {});
  },
});
export const { setTheOffer, setInboundView, setPassinfo, setBookedData, setCouponvalue } =
  bookingSlice.actions;

export default bookingSlice.reducer;
