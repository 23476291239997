import React from "react";

export default function Wlan(props) {
  let { fill, width, height } = props;

  if (!fill) fill = "black";
  if (!width) width = 32;
  if (!height) height = 32;

  return (
    <svg width={width} height={height} viewBox="0 0 32 33">
      <path
        fill={fill}
        d="m 14.976246,24.661762 c -0.71441,-0.356347 -1.408535,-1.028474 -1.782708,-1.726212 -0.232501,-0.433556 -0.298256,-0.798464 -0.300132,-1.665574 -0.0021,-0.958385 0.0488,-1.19747 0.371611,-1.746582 0.744994,-1.267242 2.061377,-2.070358 3.393513,-2.070358 0.746768,0 1.928177,0.523443 2.522267,1.117533 0.74725,0.747252 1.042607,1.416789 1.109557,2.515239 0.04665,0.765334 -0.0024,1.054787 -0.282424,1.666419 -0.681044,1.487596 -1.809467,2.199485 -3.471971,2.19036 -0.718208,-0.0039 -1.16639,-0.08464 -1.559713,-0.280825 z M 7.4523689,17.183288 C 6.1593479,16.596237 5.6827746,14.855696 6.5108287,13.744592 7.0080547,13.077403 8.5199854,11.614649 9.4210578,10.929021 13.89728,7.5230534 20.208833,7.6418321 24.684151,11.216261 c 1.169392,0.93399 2.247363,2.068715 2.435609,2.563838 0.527753,1.388099 -0.3877,2.972555 -1.855035,3.21067 -0.791676,0.128471 -1.435389,-0.196316 -2.707558,-1.366108 -2.851846,-2.622345 -5.955272,-3.163584 -9.167468,-1.59881 -0.599407,0.291992 -1.336292,0.856315 -2.260621,1.731234 -1.8367068,1.738522 -2.4579905,1.979519 -3.6767091,1.426203 z M 1.2999737,11.172435 C 0.6315961,10.717541 0.30873376,10.212376 0.22787238,9.4949757 0.16962652,8.9782201 0.22466593,8.7451192 0.52656361,8.2299698 0.95459307,7.4995928 3.4980872,5.1602872 4.9540099,4.1579534 8.3720567,1.8047901 12.463794,0.52489829 16.569146,0.52474961 c 4.135342,-1.499e-4 7.925167,1.06196289 11.208078,3.14110609 2.006893,1.271012 4.57802,3.5912623 4.890595,4.4133983 0.616907,1.6225868 -0.872838,3.287488 -2.539947,2.838582 C 29.746443,10.815128 29.224034,10.4311 28.383993,9.635889 24.929882,6.366121 21.189008,4.8241231 16.707669,4.8228783 14.001487,4.8221266 11.473973,5.4459791 9.0894258,6.7032469 7.543209,7.5184996 6.4174797,8.3624054 4.8407296,9.8882929 4.1536804,10.553179 3.3905634,11.184377 3.1449143,11.290954 2.4964142,11.572311 1.8240501,11.529118 1.2999737,11.172435 Z"
      />
    </svg>
  );
}
