import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { airportsIata } from "../airports";
/*const airlines = [
  { label: "Lufthansa", value: "LH" },
  { label: "Air France", value: "AF" },
  { label: "Easyjet", value: "U2" },
  { label: "Eurowings", value: "EW" },
];*/
import { getFilters } from "amadeusredux/lib/api";
import { setSearchOption } from "amadeusredux/lib/searchSlice";

export default function AirportsFilter(props) {
  const dispatch = useDispatch();
  const { changeFilterAndSearch } = props;
  const [showAll, setShowAll] = React.useState(false);
  const view = useSelector((state) => state.system.view);
  const loading = useSelector((state) => state.system.loading);
  const destinationAirports = useSelector(
    (state) => state.filter.destinationAirports
  );

  const destinationStations = useSelector(
    (state) => state.search.destinationStations
  );

  let ct = 0;

  return (
    <div>
      <div className="fw-semibold">Zielflughafen</div>

      {[...destinationAirports].map((itm, kidx) => {
        ct = ct + 1;

        if (ct > 5 && !showAll) return null;

        let label = "";

        let keys = Object.keys(airportsIata);
        for (let i = 0; i < keys.length; i++) {
          if (airportsIata[keys[i]].iata === itm) {
            label = airportsIata[keys[i]].name;
            break;
          }
        }

        return (
          <React.Fragment key={kidx}>
            <div style={{ display: "flex" }} key={itm.value} className="mb-2">
              &nbsp;
              <input
                checked={destinationStations.includes(itm)}
                onChange={() => {
                  let changed = [...destinationStations];
                  if (changed.includes(itm)) {
                    changed = changed.filter((e) => e !== itm);
                  } else {
                    changed.push(itm);
                  }

                  dispatch(
                    setSearchOption({
                      property: "destinationStations",
                      data: changed,
                    })
                  );

                  getFilters(dispatch, false);
                }}
                type="checkbox"
              />
              &nbsp;
              <div
                className="filtertext"
                dangerouslySetInnerHTML={{
                  __html: "" + itm + " - " + label,
                }}
              ></div>
            </div>
          </React.Fragment>
        );
      })}
      {!showAll && ct > 5 && (
        <div style={{ cursor: "pointer" }} onClick={() => setShowAll(true)}>
          alle anzeigen
        </div>
      )}
      {showAll && (
        <div style={{ cursor: "pointer" }} onClick={() => setShowAll(false)}>
          weniger anzeigen
        </div>
      )}
    </div>
  );
}
