import {
  createSlice,
  createSelector,
  createAsyncThunk,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import axios from "axios";
import { OFFERSATFIRSTREQUEST, OFFERSPERREQUEST } from "./config";
import {
  boardTypes,
  getHost,
  hotelSortOptions,
  populateAlternativeFlightsPost,
  populateOfferPost,
  populatePost,
} from "./utils";
import { format } from "date-fns";
import { setOutbounddate } from "./searchSlice";

axios.defaults.headers.common["AgencyID"] = window.configData.AGENCYID;
axios.defaults.headers.common["Version"] = process.env.REACT_APP_VERSION;
axios.defaults.headers.common["App"] = window.configData.APP;

export const getRegionInfo = createAsyncThunk(
  "hotel/getRegionInfo",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(
        getHost() + "/getregioninfo?id=" + payload.id
      );
      return response.data.ret;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getCityInfo = createAsyncThunk(
  "hotel/getCityInfo",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(
        getHost() + "/getcityinfo?id=" + payload.id
      );
      return response.data.ret;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const searchHotelOffers = createAsyncThunk(
  "hotel/searchHotelOffers",
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    //console.log(JSON.stringify(state.hotel.selectedHotel));

    let apicall = state.search.apicall;

    if (
      apicall === "packageOffers" &&
      state.search.holidayType.accommodation &&
      !state.search.holidayType.flight
    ) {
      apicall = "accommodationOffers";
    }

    if (
      apicall === "touroperatoroffers" &&
      state.search.holidayType.accommodation &&
      !state.search.holidayType.flight
    ) {
      apicall = "touroperatoraccommodationoffers";
    }

    /*if (
      state.system.saveSearch &&
      window.setCookie &&
      (apicall === "packageOffers" || apicall === "accommodationOffers")
    ) {
      
      window.setCookie(
        "lastsearch",
        JSON.stringify({
          ...{
            names: state.search.selectedDestination.map((e) => e.label),
            request: populatePost(
              state.search.selectedDestination,
              {
                ...state.search,
                promosession: state.system.sessionId,
                offset: payload.offset,

                amount:
                  payload.offset > 0 ? OFFERSPERREQUEST : OFFERSATFIRSTREQUEST,
              },
              state.properties.departureStations
            ),
          },
          selectedDestination: [state.hotel.selectedHotel],
        }),
        {
          expires: new Date(2099, 1, 1),
          path: "/",
        }
      );
      console.log(
        JSON.stringify({
          ...{
            names: state.search.selectedDestination.map((e) => e.label),
            request: populatePost(
              state.search.selectedDestination,
              {
                ...state.search,
                promosession: state.system.sessionId,
                offset: payload.offset,

                amount:
                  payload.offset > 0 ? OFFERSPERREQUEST : OFFERSATFIRSTREQUEST,
              },
              state.properties.departureStations
            ),
          },
          selectedDestination: [state.hotel.selectedHotel],
        })
      );
    
    }*/

    let rownumber = 0;
    let topseller = false;
    let actionid = "";

    if (Array.isArray(state.offers.queryorder)) {
      for (let i = 0; i < state.offers.queryorder.length; i++) {
        //console.log(state.offers.queryorder[i]);
        if (
          state.hotel.selectedHotel?.HotelCodes?.HotelGiataID &&
          state.hotel.selectedHotel?.HotelCodes?.HotelGiataID ===
            state.offers.queryorder[i].giataid
        ) {
          rownumber = state.offers.queryorder[i].rownumber;
          topseller = state.offers.queryorder[i].topseller;
          actionid = state.offers.queryorder[i].actionid;
          break;
        }
      }
    }

    try {
      let response = await axios.post(getHost() + "/" + apicall, {
        ...populatePost(
          [state.hotel.selectedHotel],
          {
            ...state.search,
            promosession: state.system.sessionId,
            sorting: state.hotel.hotelSort,
            offset: payload.offset,

            amount:
              payload.offset > 0 ? OFFERSPERREQUEST : OFFERSATFIRSTREQUEST,
          },
          state.properties.departureStations
        ),
        rownumber: rownumber,
        topseller: topseller,
        actionid: actionid,
        ratingcount: "0",
        recommendationrate: "0",
        category: "",
        mode: state.system.mode,
        couponcode: state.search.couponcode,
        //hotelAttribute: [],
      });

      if (
        !state.search.outbounddate &&
        payload.offset === 0 &&
        response.data.Offers.OfferCount > 0 &&
        (apicall === "packageOffers" || apicall === "accommodationOffers")
      ) {
        let offer = response.data.Offers.Offer[0];

        let outbounddate = offer.OfferServices.Package?.Flight?.OutboundFlight
          ?.FlightDeparture?.DepartureDate
          ? offer.OfferServices.Package?.Flight?.OutboundFlight?.FlightDeparture
              ?.DepartureDate
          : offer.OfferServices.Accommodation.CheckInDate;
        thunkAPI.dispatch(setOutbounddate(outbounddate));

        response = await axios.post(getHost() + "/" + apicall, {
          ...populatePost(
            [state.hotel.selectedHotel],
            {
              ...state.search,
              promosession: state.system.sessionId,
              sorting: state.hotel.hotelSort,
              offset: payload.offset,

              amount:
                payload.offset > 0 ? OFFERSPERREQUEST : OFFERSATFIRSTREQUEST,
            },
            state.properties.departureStations
          ),
          rownumber: rownumber,
          topseller: topseller,
          actionid: actionid,
          ratingcount: "0",
          recommendationrate: "0",
          category: "",
          outbounddate: outbounddate,
          mode: state.system.mode,
          couponcode: state.search.couponcode,
          //hotelAttribute: [],
        });
      }

      if (response.data.Offers.OfferCount > 0) {
        response.data.Offers.Offer = response.data.Offers.Offer.map((e) => {
          return {
            ...e,
            adults: state.search.adults.value,
            children: state.search.children.value,
            duration: state.search.duration.label,
            stations: state.search.stations,
            travelDate: state.search.travelDate.from,
            destination: { ...state.hotel.selectedHotel },
            displaydestination: [...state.search.selectedDestination],
          };
        });

        response.data.Offers.Offer = response.data.Offers.Offer.map((e) => {
          return !e.OfferServices.Package
            ? {
                ...e,
                OfferServices: {
                  Package: { Accommodation: e.OfferServices.Accommodation },
                },
              }
            : e;
        });
        for (let i = 0; i < response.data.Offers.Offer.length; i++) {
          let offer = response.data.Offers.Offer[i];
          if (offer?.OfferServices?.Package?.Accommodation?.Room?.RoomName) {
            try {
              offer.OfferServices.Package.Accommodation.Room.RoomName =
                offer.OfferServices.Package.Accommodation.Room.RoomName.replace(
                  /\,/g,
                  ", "
                );
              offer.OfferServices.Package.Accommodation.Room.RoomName =
                offer.OfferServices.Package.Accommodation.Room.RoomName.replace(
                  /\//g,
                  "/ "
                );
              offer.OfferServices.Package.Accommodation.Room.RoomName =
                offer.OfferServices.Package.Accommodation.Room.RoomName.replace(
                  /\-/g,
                  "- "
                );
              offer.OfferServices.Package.Accommodation.Room.RoomName =
                offer.OfferServices.Package.Accommodation.Room.RoomName.replace(
                  /\./g,
                  ". "
                );
            } catch (e) {}
          }
          if (offer?.OfferServices?.Package?.Accommodation?.BoardType) {
            let bt = boardTypes.find((e) =>
              e.searchKeys.includes(
                offer?.OfferServices?.Package?.Accommodation?.BoardType
              )
            );
            if (bt) {
              offer.OfferServices.Package.Accommodation.BoardType = bt.value;
            }
          }
        }
      }
      response.data.version = 1;
      if (apicall === "packageOffers" || apicall === "accommodationOffers")
        response.data.version = 2;

      if (Array.isArray(response.data.Offers.Offer)) {
        if (
          state.search.durationkind &&
          state.search.durationkind.value === "Stay"
        ) {
          let dur = state.search.duration.value;

          if (dur === "0.1") {
            let differenceInTime =
              new Date(
                state.search.travelDate.to.split('"').join("")
              ).getTime() -
              new Date(
                state.search.travelDate.from.split('"').join("")
              ).getTime();

            dur = Math.floor(differenceInTime / (1000 * 3600 * 24)) + "";
            if (state.search.durationkind.value === "Stay")
              dur = Math.floor(differenceInTime / (1000 * 3600 * 24)) + "";
          }
          if ((dur + "").indexOf("-") > -1) {
            let durmin = dur.split("-")[0] * 1;
            let durmax = dur.split("-")[1] * 1;
            response.data.Offers.Offer = response.data.Offers.Offer.filter(
              (e) =>
                e.TravelDateInfo &&
                e.TravelDateInfo.OvernightStays >= durmin &&
                e.TravelDateInfo.OvernightStays <= durmax
            );
          } else {
            dur = dur * 1;
            response.data.Offers.Offer = response.data.Offers.Offer.filter(
              (e) => e.TravelDateInfo && e.TravelDateInfo.OvernightStays === dur
            );
          }
        }

        //response.data.Offers.OfferCount = response.data.Offers.Offer.length;
      }

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const searchDepartureDates = createAsyncThunk(
  "hotel/searchDepartureDates",
  async (payload, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      let msFrom =
        new Date(state.search.travelDate.from.split('"').join("")).getTime() -
        7 * 86400000;
      let earlier = new Date(msFrom);
      let msTo =
        new Date(state.search.travelDate.to.split('"').join("")).getTime() +
        7 * 86400000;
      let later = new Date(msTo);

      let travelDate = {
        from: format(earlier, "yyyy-MM-dd"),
        to: format(later, "yyyy-MM-dd"),
      };

      let duration = { ...state.search.duration };

      if (duration.value === "0.1") {
        let differenceInTime =
          new Date(state.search.travelDate.to.split('"').join("")).getTime() -
          new Date(state.search.travelDate.from.split('"').join("")).getTime();

        duration.value =
          Math.floor(differenceInTime / (1000 * 3600 * 24) + 1) + "";
        if (state.search.durationkind.value === "Stay")
          duration.value =
            Math.floor(differenceInTime / (1000 * 3600 * 24)) + "";
      }

      //console.log(travelDate);

      let url = "/bestPackageOfferForDepartureDate";
      if (
        state.search.holidayType.accommodation &&
        !state.search.holidayType.flight
      )
        url = "/bestAccommodationOfferForCheckInDate";

      const response = await axios.post(getHost() + url, {
        ...populatePost(
          [state.hotel.selectedHotel],
          {
            ...{ ...state.search, travelDate: travelDate, duration: duration },
            promosession: state.system.sessionId,
            sorting: state.hotel.hotelSort,
          },
          state.properties.departureStations
        ),
        mode: state.system.mode,
        couponcode: state.search.couponcode,
      });

      //console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const searchDepartureDatesOriginal = createAsyncThunk(
  "hotel/searchDepartureDatesOriginal",
  async (payload, thunkAPI) => {
    try {
      const state = thunkAPI.getState();

      let url = "/bestPackageOfferForDepartureDate";
      if (
        state.search.holidayType.accommodation &&
        !state.search.holidayType.flight
      )
        url = "/bestAccommodationOfferForCheckInDate";

      const response = await axios.post(getHost() + url, {
        ...populatePost(
          [state.hotel.selectedHotel],
          {
            ...{ ...state.search },
            promosession: state.system.sessionId,
            sorting: state.hotel.hotelSort,
          },
          state.properties.departureStations
        ),
        mode: state.system.mode,
        couponcode: state.search.couponcode,
      });

      //console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const searchBetterOffers = createAsyncThunk(
  "hotel/searchBetterOffers",
  async (payload, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      if (!payload.cross === true) {
        if (!state.hotel.subload) {
          return thunkAPI.rejectWithValue({ error: "subload to early" });
        }
      }
      let apicall = "otherPackageOffers";
      if (!state.search.holidayType.flight) {
        apicall = "otherAccommodationOffers";
      }

      const response = await axios.post(getHost() + "/" + apicall, {
        ...populateOfferPost(
          payload.fromDate,
          payload.untilDate,
          payload.station,
          state.search.destinationStations.length > 0
            ? state.search.destinationStations
            : [],
          payload.searchOptions,
          payload.selectedDestination,
          payload.touroperator,
          payload.inboundtime,
          payload.outboundtime
        ),
        mode: state.system.mode,
        withActions: payload.withActions === false ? false : true,
        couponcode: state.search.couponcode,

        promosession: state.system.sessionId,
      });
      if (response.data.Offers.OfferCount > 0) {
        response.data.Offers.Offer = response.data.Offers.Offer.map((e) => {
          return {
            ...e,

            adults: state.search.adults.value,
            children: state.search.children.value,
            duration: state.search.duration.label,
            stations: state.search.stations,
            travelDate: state.search.travelDate.from,
            destination: { ...state.hotel.selectedHotel },
            displaydestination: [...state.search.selectedDestination],
          };
        });
        response.data.Offers.Offer = response.data.Offers.Offer.map((e) => {
          return !e.OfferServices.Package
            ? {
                ...e,
                OfferServices: {
                  Package: { Accommodation: e.OfferServices.Accommodation },
                },
              }
            : e;
        });
        for (let i = 0; i < response.data.Offers.Offer.length; i++) {
          let offer = response.data.Offers.Offer[i];
          offer.betterOffer = true;
          if (offer?.OfferServices?.Package?.Accommodation?.Room?.RoomName) {
            try {
              offer.OfferServices.Package.Accommodation.Room.RoomName =
                offer.OfferServices.Package.Accommodation.Room.RoomName.replace(
                  /\,/g,
                  ", "
                );
              offer.OfferServices.Package.Accommodation.Room.RoomName =
                offer.OfferServices.Package.Accommodation.Room.RoomName.replace(
                  /\//g,
                  "/ "
                );
              offer.OfferServices.Package.Accommodation.Room.RoomName =
                offer.OfferServices.Package.Accommodation.Room.RoomName.replace(
                  /\-/g,
                  "- "
                );
              offer.OfferServices.Package.Accommodation.Room.RoomName =
                offer.OfferServices.Package.Accommodation.Room.RoomName.replace(
                  /\./g,
                  ". "
                );
            } catch (e) {}
          }

          if (offer?.OfferServices?.Package?.Accommodation?.BoardType) {
            let bt = boardTypes.find((e) =>
              e.searchKeys.includes(
                offer?.OfferServices?.Package?.Accommodation?.BoardType
              )
            );
            if (bt) {
              offer.OfferServices.Package.Accommodation.BoardType = bt.value;
            }
          }
        }
      }

      if (Array.isArray(response.data.Offers.Offer)) {
        if (
          state.search.durationkind &&
          state.search.durationkind.value === "Stay"
        ) {
          let dur = state.search.duration.value;

          if (dur === "0.1") {
            let differenceInTime =
              new Date(
                state.search.travelDate.to.split('"').join("")
              ).getTime() -
              new Date(
                state.search.travelDate.from.split('"').join("")
              ).getTime();

            dur = Math.floor(differenceInTime / (1000 * 3600 * 24)) + "";
            if (state.search.durationkind.value === "Stay")
              dur = Math.floor(differenceInTime / (1000 * 3600 * 24)) + "";
          }
          if ((dur + "").indexOf("-") > -1) {
            let durmin = dur.split("-")[0] * 1;
            let durmax = dur.split("-")[1] * 1;
            response.data.Offers.Offer = response.data.Offers.Offer.filter(
              (e) =>
                e.TravelDateInfo &&
                e.TravelDateInfo.OvernightStays >= durmin &&
                e.TravelDateInfo.OvernightStays <= durmax
            );
          } else {
            dur = dur * 1;
            response.data.Offers.Offer = response.data.Offers.Offer.filter(
              (e) => e.TravelDateInfo && e.TravelDateInfo.OvernightStays === dur
            );
          }
        }

        //response.data.Offers.OfferCount = response.data.Offers.Offer.length;
      }

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

/*export const searchAlternativeFlights = createAsyncThunk(
  "hotel/searchAlternativeFlights",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(
        getHost() + "/alternativeFlights?SessionID=" + payload.SessionID
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);*/

export const availabilityAndPriceCheck = createAsyncThunk(
  "hotel/availabilityAndPriceCheck",
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();

    let param = "flight=" + (state.search.holidayType.flight ? "1" : "0");
    //if (payload.FlightID) {
    //  param = "FlightID=" + encodeURIComponent(payload.FlightID);
    //} else {
    param = param + "&OfferID=" + encodeURIComponent(payload.OfferID);
    //}
    payload.HotelGiataID = state.hotel.selectedHotel.HotelCodes.HotelGiataID;
    try {
      const response = await axios.get(
        getHost() +
          "/availabilityAndPriceCheck?" +
          param +
          (payload.adults ? "&adults=" + payload.adults : "") +
          "&multiroomdivider=" +
          state.search.multiroomdivider +
          (payload.children ? "&children=" + payload.children : "") +
          (payload.children
            ? "&childrenages=" +
              state.search.childrenages.map((e) => e.value).toString()
            : "")
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getCatalogData = createAsyncThunk(
  "hotel/getCatalogData",
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    try {
      const response = await axios.get(
        getHost() +
          "/giatamedia/" +
          encodeURIComponent(state.hotel.selectedHotel.HotelCodes.HotelGiataID)
      );

      if (Array.isArray(response.data.result.data)) {
        for (let i = 0; i < response.data.result.data.length; i++)
          if (
            response.data.result.data &&
            Array.isArray(response.data.result.data[i].Bildfile)
          ) {
            for (
              let j = 0;
              j < response.data.result.data[i].Bildfile.length;
              j++
            ) {
              //              console.log(response.data.result.data[i].Bildfile[j]);
              response.data.result.data[i].Bildfile[j]._ =
                getHost() +
                "/giataimage?size=" +
                response.data.result.data[i].Bildfile[j].$.size +
                "&image=" +
                encodeURIComponent(response.data.result.data[i].Bildfile[j]._);
            }
          }
      }
      return response.data.result.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const checkOfferAction = createAsyncThunk(
  "hotel/checkOfferAction",
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    try {
      const response = await axios.post(getHost() + "/checkofferaction", {
        offerid: payload.offerId,
        sessionid: state.system.sessionId,
        agencyid: window.configData.AGENCYID,
        hotel: state.hotel.selectedHotel,
        typ: state.search?.holidayType?.flight ? "package" : "accommodation",
        offer: { ...payload.offer, bookinginfo: {} },
        bookinginfo: payload.offer.bookinginfo,
        couponcode: state.search.couponcode,
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const searchAlternativeTransports = createAsyncThunk(
  "hotel/alternativeTransportForPackageOffer",
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    //console.log("alteranet", payload);
    try {
      if (!payload.cross === true) {
        if (!state.hotel.subload) {
          return thunkAPI.rejectWithValue({ error: "subload to early" });
        }
      }
      const response = await axios.post(
        getHost() + "/alternativeTransportForPackageOffer",
        {
          ...populateAlternativeFlightsPost(
            payload.offer,
            {
              adults: state.search.adults,
              children: state.search.children,
              childrenages: state.search.childrenages,
            },
            state.search.stations,
            state.search.destinationStations.length > 0
              ? state.search.destinationStations
              : state.filter.destinationAirports,
            state.search.durationkind,
            state.search.outboundtime,
            state.search.inboundtime,
            state.search.operatingbrand,
            payload.offset ? payload.offset : 0,
            state.search.multiroomdivider
          ),

          couponcode: state.search.couponcode,
          mode: state.system.mode,
          withActions: payload.withActions === false ? false : true,
        }
      );
      //console.log("rdd", response.data);
      if (response.data.Offers.OfferCount > 0) {
        response.data.Offers.Offer = response.data.Offers.Offer.map((e) => {
          return {
            ...e,
            adults: state.search.adults.value,
            children: state.search.children.value,
            destination: { ...state.hotel.selectedHotel },
            displaydestination: [...state.search.selectedDestination],
            alternativeflight: true,
          };
        });

        if (payload?.offer?.TourOperator?.TourOperatorCode)
          response.data.Offers.Offer = response.data.Offers.Offer.filter(
            (e) =>
              payload.offer.TourOperator.TourOperatorCode ===
              e.TourOperator.TourOperatorCode
          );

        for (let i = 0; i < response.data.Offers.Offer.length; i++) {
          let offer = response.data.Offers.Offer[i];
          offer.alternativeTransport = true;
          if (offer?.OfferServices?.Package?.Accommodation?.Room?.RoomName) {
            try {
              offer.OfferServices.Package.Accommodation.Room.RoomName =
                offer.OfferServices.Package.Accommodation.Room.RoomName.replace(
                  /\,/g,
                  ", "
                );
              offer.OfferServices.Package.Accommodation.Room.RoomName =
                offer.OfferServices.Package.Accommodation.Room.RoomName.replace(
                  /\//g,
                  "/ "
                );
              offer.OfferServices.Package.Accommodation.Room.RoomName =
                offer.OfferServices.Package.Accommodation.Room.RoomName.replace(
                  /\-/g,
                  "- "
                );
              offer.OfferServices.Package.Accommodation.Room.RoomName =
                offer.OfferServices.Package.Accommodation.Room.RoomName.replace(
                  /\./g,
                  ". "
                );
            } catch (e) {}
          }
          if (offer?.OfferServices?.Package?.Accommodation?.BoardType) {
            let bt = boardTypes.find((e) =>
              e.searchKeys.includes(
                offer?.OfferServices?.Package?.Accommodation?.BoardType
              )
            );
            if (bt) {
              offer.OfferServices.Package.Accommodation.BoardType = bt.value;
            }
          }
        }
      }

      if (Array.isArray(response.data.Offers.Offer)) {
        if (
          state.search.durationkind &&
          state.search.durationkind.value === "Stay"
        ) {
          let dur = state.search.duration.value;

          if (dur === "0.1") {
            let differenceInTime =
              new Date(
                state.search.travelDate.to.split('"').join("")
              ).getTime() -
              new Date(
                state.search.travelDate.from.split('"').join("")
              ).getTime();

            dur = Math.floor(differenceInTime / (1000 * 3600 * 24)) + "";
            if (state.search.durationkind.value === "Stay")
              dur = Math.floor(differenceInTime / (1000 * 3600 * 24)) + "";
          }
          if ((dur + "").indexOf("-") > -1) {
            let durmin = dur.split("-")[0] * 1;
            let durmax = dur.split("-")[1] * 1;
            response.data.Offers.Offer = response.data.Offers.Offer.filter(
              (e) =>
                e.TravelDateInfo &&
                e.TravelDateInfo.OvernightStays >= durmin &&
                e.TravelDateInfo.OvernightStays <= durmax
            );
          } else {
            dur = dur * 1;
            response.data.Offers.Offer = response.data.Offers.Offer.filter(
              (e) => e.TravelDateInfo && e.TravelDateInfo.OvernightStays === dur
            );
          }
        }

        //response.data.Offers.OfferCount = response.data.Offers.Offer.length;
      }

      if (
        Array.isArray(response.data.Offers.Offer) &&
        Array.isArray(state.search.operatingbrand) &&
        state.search.operatingbrand.length > 0
      ) {
        response.data.Offers.Offer = response.data.Offers.Offer.filter(
          (e) =>
            state.search.operatingbrand.includes(
              e.OfferServices?.Package?.Flight?.OutboundFlight?.Airline
                ?.AirlineCode
            ) &&
            state.search.operatingbrand.includes(
              e.OfferServices?.Package?.Flight?.InboundFlight?.Airline
                ?.AirlineCode
            )
        );
        //console.log("altf", response.data.Offers.Offer);
        //response.data.Offers.OfferCount = response.data.Offers.Offer.length;
      }

      //console.log("rddnb", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const initialState = {
  checkids: [],
  hotelOffersFound: true,
  alternativeids: [],
  otherids: [],
  selectedHotel: {},
  hotelOfferInfos: {},
  departureDates: {},
  departureDatesOriginal: {},
  catalogData: [],
  offerId: "",
  hotelData: {},
  infoHotel: {},
  alternativeTransports: {},
  hotelSpecialAmount: [],
  hotelSpecialRoomTypes: [],
  subload: false,
  betterOffers: {},
  hotelPage: 1,
  hotelSort: hotelSortOptions[0],
  cityInfo: [],
};

export const hotelSlice = createSlice({
  name: "hotel",
  initialState,
  reducers: {
    setHotelOffersFound: (state, action) => {
      state.infoHotel = action.payload;
    },
    setInfoHotel: (state, action) => {
      state.infoHotel = action.payload;
    },
    setSelectedHotel: (state, action) => {
      state.selectedHotel = action.payload;
    },
    setHotelData: (state, action) => {
      state.hotelData = action.payload;
    },
    setHotelOfferInfos: (state, action) => {
      state.hotelOfferInfos = action.payload;
    },
    setHotelPage: (state, action) => {
      state.hotelPage = action.payload;
    },
    setHotelSort: (state, action) => {
      state.hotelSort = action.payload;
    },
    setOfferId: (state, action) => {
      state.offerId = action.payload;
    },
    setHotelSpecialRoomTypes: (state, action) => {
      state.hotelSpecialRoomTypes = action.payload;
    },

    clearMarketingActions: (state, action) => {
      let infos = { ...state.hotelOfferInfos };
      //console.log(action.payload);
      for (let i = 0; i < infos.Offers.Offer.length; i++) {
        if (infos.Offers.Offer[i].OfferID === action.payload) {
          if (
            Array.isArray(infos.Offers.Offer[i].MarketingActions) &&
            infos.Offers.Offer[i].MarketingActions.length > 0
          ) {
            infos.Offers.Offer[i].MarketingActions = [
              infos.Offers.Offer[i].MarketingActions[0],
            ];
            infos.Offers.Offer[i].MarketingActions[0].MarketingProperties = [];
          }
        }
      }

      state.hotelOfferInfos = { ...infos };
    },

    setOfferFlexbooked: (state, action) => {
      let infos = { ...state.hotelOfferInfos };
      //console.log(infos);
      for (let i = 0; i < infos.Offers.Offer.length; i++) {
        //console.log(infos.Offers.Offer[i]);
        if (infos.Offers.Offer[i].OfferID === action.payload.offerId) {
          infos.Offers.Offer[i].flexbooked = action.payload.flexbooked;
        }
      }

      state.hotelOfferInfos = { ...infos };
    },
    setMarked: (state, action) => {
      let infos = { ...state.hotelOfferInfos };
      //console.log(infos);
      for (let i = 0; i < infos.Offers.Offer.length; i++) {
        //console.log(infos.Offers.Offer[i]);
        if (infos.Offers.Offer[i].OfferID === action.payload.offerId) {
          //console.log(infos.Offers.Offer[i].OfferID);
          if (
            Array.isArray(infos.Offers.Offer[i].MarketingActions) &&
            infos.Offers.Offer[i].MarketingActions.length > 0
          ) {
            infos.Offers.Offer[i].MarketingActions[0].marked =
              action.payload.marked;
          } else {
            infos.Offers.Offer[i].MarketingActions = [
              {
                marked: action.payload.marked,
              },
            ];
          }
        }
      }

      state.hotelOfferInfos = { ...infos };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getCityInfo.pending, (state) => {
      state.cityInfo = [];
    });
    builder.addCase(getCityInfo.fulfilled, (state, { payload }) => {
      state.cityInfo = payload;
    });
    builder.addCase(getCityInfo.rejected, (state) => {
      state.cityInfo = [];
    });
    builder.addCase(getRegionInfo.pending, (state) => {
      state.cityInfo = [];
    });
    builder.addCase(getRegionInfo.fulfilled, (state, { payload }) => {
      state.cityInfo = payload;
    });
    builder.addCase(getRegionInfo.rejected, (state) => {
      state.cityInfo = [];
    });
    builder.addCase(getCatalogData.pending, (state) => {
      state.catalogData = [];
    });
    builder.addCase(getCatalogData.fulfilled, (state, { payload }) => {
      state.catalogData = payload;
    });
    builder.addCase(getCatalogData.rejected, (state) => {
      state.catalogData = [];
    });

    builder.addCase(checkOfferAction.fulfilled, (state, action) => {
      //console.log("ation", action);

      let infos = { ...state.hotelOfferInfos };
      let offers = [];

      //console.log(1);
      if (Array.isArray(infos?.Offers?.Offer)) {
        for (let i = 0; i < infos.Offers.Offer.length; i++) {
          let off = infos.Offers.Offer[i];
          //console.log(2);

          let dat = action.payload.data.find((e) => e.OfferID === off.OfferID);
          if (!dat) {
            offers.push(off);
            continue;
          }
          //console.log(3);
          try {
            let ma = off.MarketingActions;
            //console.log("############+++++++++++++", JSON.stringify(ma));
            //console.log("############+++++++++++++", JSON.stringify(dat));
            if (!Array.isArray(ma) && dat) {
              off.MarketingActions = [{ ...dat }];
              if (
                Array.isArray(off?.MarketingActions[0]?.MarketingProperties) &&
                off.MarketingActions[0].MarketingProperties.length > 0
              )
                off.MarketingActions[0].MarketingProperties =
                  off.MarketingActions[0].MarketingProperties[0];

              ma = off.MarketingActions;
            }

            if (Array.isArray(ma) && ma.length === 0 && dat) {
              off.MarketingActions = [{ ...dat }];
              if (
                Array.isArray(off?.MarketingActions[0]?.MarketingProperties) &&
                off.MarketingActions[0].MarketingProperties.length > 0
              )
                off.MarketingActions[0].MarketingProperties =
                  off.MarketingActions[0].MarketingProperties[0];

              ma = off.MarketingActions;
            }

            if (Array.isArray(ma) && ma.length > 1) {
              ma = [ma[0]];
            }

            if (Array.isArray(ma) && ma.length === 1) {
              ma[0].MarketingProperties = {};
            }

            if (
              Array.isArray(ma) &&
              ma.length === 1 &&
              Array.isArray(dat.MarketingProperties) &&
              dat.MarketingProperties.length > 0
            ) {
              let nma = [];
              for (let j = 0; j < dat.MarketingProperties.length; j++) {
                nma.push({
                  ...ma[0],
                  typ_id: dat.MarketingProperties[j].typ_id,
                  onpage: dat.MarketingProperties[j].onpage,

                  MarketingProperties: {
                    ...dat.MarketingProperties[j],
                    offeraction: true,
                  },
                });
              }

              off.MarketingActions = nma;
            } else {
              off.MarketingActions = [
                {
                  ...ma[0],

                  MarketingProperties: {
                    offeraction: true,
                  },
                },
              ];
            }
            offers.push(off);
          } catch (e) {
            offers.push(off);
          }
        }
      }
      infos.Offers.Offer = offers;
      state.hotelOfferInfos = { ...infos };
    });

    builder.addCase(searchAlternativeTransports.pending, (state, action) => {
      state.alternativeids = [
        ...state.alternativeids,
        action.meta.arg.offer.OfferID,
      ];
      state.alternativeTransports = {
        ...state.alternativeTransports,
        [action.meta.arg.offer.OfferID]: action.meta.arg.offset
          ? action.meta.arg.offset > 0
            ? { ...state.alternativeTransports[action.meta.arg.offer.OfferID] }
            : {}
          : {},
      };
    });
    builder.addCase(searchAlternativeTransports.fulfilled, (state, action) => {
      let tmp = {
        ...state.alternativeTransports[action.meta.arg.offer.OfferID],
      };

      if (Array.isArray(tmp?.Offers?.Offer)) {
        tmp.Offers.Offer = [
          ...tmp.Offers.Offer,
          ...action.payload.Offers.Offer,
        ];
      }
      state.alternativeTransports = {
        ...state.alternativeTransports,
        [action.meta.arg.offer.OfferID]: action.meta.arg.offset
          ? action.meta.arg.offset > 0
            ? { ...tmp }
            : action.payload
          : action.payload,
      };
      state.alternativeids = state.alternativeids.filter(
        (e) => e !== action.meta.arg.offer.OfferID
      );
    });
    builder.addCase(searchAlternativeTransports.rejected, (state, action) => {
      state.alternativeids = state.alternativeids.filter(
        (e) => e !== action.meta.arg.offer.OfferID
      );
      state.alternativeTransports = {
        ...state.alternativeTransports,
        [action.meta.arg.offer.OfferID]: {},
      };
    });

    builder.addCase(availabilityAndPriceCheck.pending, (state, action) => {
      state.checkids = [
        ...state.checkids,
        action.meta.arg.OfferID
          ? action.meta.arg.OfferID
          : action.meta.arg.FlightID,
      ];
    });
    builder.addCase(availabilityAndPriceCheck.fulfilled, (state, action) => {
      //console.log(action.meta.arg);
      state.checkids = state.checkids.filter(
        (e) =>
          e !==
          (action.meta.arg.OfferID
            ? action.meta.arg.OfferID
            : action.meta.arg.FlightID)
      );
      let d = action.payload["ns2:AvailabilityAndPriceCheckRS"];

      try {
        if (d?.Offer) {
          if (d.Offer?.Cautions?.Caution) {
            /*axios.post(getHost() + "/sethotelproperties", {
              HotelGiataID: action.meta.arg.HotelGiataID,
              keyproperty: "cautions",
              properties: {
                ["date" + new Date().getTime()]: {
                  TourOperator: d.Offer.TourOperator.Code,
                  cautions: d.Offer.Cautions.Caution,
                },
              },
            });*/
          }
        }
      } catch (e) {
        console.log(e);
      }

      let tmp = {};
      if (action.meta.arg.FlightID) {
        tmp = { ...state.alternativeTransports[action.meta.arg.parentid] };
      } else {
        if (action.meta.arg.parentid)
          tmp = { ...state.betterOffers[action.meta.arg.parentid] };
        else tmp = { ...state.hotelOfferInfos };
      }
      //console.log(Array.isArray(tmp?.Offers?.Offer),d.Offer);
      if (Array.isArray(tmp?.Offers?.Offer) && d.Offer) {
        let tmpArray = tmp.Offers.Offer.map((element) => {
          return element.OfferID === action.meta.arg.OfferID
            ? { ...element, bookinginfo: d }
            : element;
        });
        tmp.Offers.Offer = tmpArray;
      } else {
        if (Array.isArray(tmp?.Offers?.Offer)) {
          let tmpArray = tmp.Offers.Offer.map((element) => {
            return element.OfferID === action.meta.arg.OfferID
              ? { ...element, bookinginfo: d }
              : element;
          });
          tmp.Offers.Offer = tmpArray;
        }
      }

      if (
        Array.isArray(state.departureDates?.Offers?.Offer) &&
        action.meta.arg.OfferID
      ) {
        let t = { ...state.departureDates };
        t.Offers.Offer = [...state.departureDates.Offers.Offer].map(
          (element) => {
            return element.OfferID === action.meta.arg.OfferID
              ? { ...element, bookinginfo: d }
              : element;
          }
        );
        state.departureDates = t;
      }

      if (
        Array.isArray(state.departureDatesOriginal?.Offers?.Offer) &&
        action.meta.arg.OfferID
      ) {
        let t = { ...state.departureDatesOriginal };
        t.Offers.Offer = [...state.departureDatesOriginal.Offers.Offer].map(
          (element) => {
            return element.OfferID === action.meta.arg.OfferID
              ? { ...element, bookinginfo: d }
              : element;
          }
        );
        state.departureDatesOriginal = t;
      }

      if (action.meta.arg.FlightID) {
        state.alternativeTransports = {
          ...state.alternativeTransports,
          [action.meta.arg.parentid]: tmp,
        };
      } else {
        if (action.meta.arg.parentid) {
          //console.log(1);
          state.betterOffers = {
            ...state.betterOffers,
            [action.meta.arg.parentid]: tmp,
          };
        } else state.hotelOfferInfos = tmp;
      }
    });
    builder.addCase(availabilityAndPriceCheck.rejected, (state, action) => {
      state.checkids = state.checkids.filter(
        (e) =>
          e !==
          (action.meta.arg.OfferID
            ? action.meta.arg.OfferID
            : action.meta.arg.FlightID)
      );
    });
    builder.addCase(searchDepartureDates.pending, (state) => {
      //state.hotelOfferInfos = {};
    });
    builder.addCase(
      searchDepartureDates.fulfilled,
      (state, { payload, meta }) => {
        state.departureDates = payload;
      }
    );
    builder.addCase(searchDepartureDatesOriginal.pending, (state) => {
      //state.hotelOfferInfos = {};
    });
    builder.addCase(
      searchDepartureDatesOriginal.fulfilled,
      (state, { payload, meta }) => {
        state.departureDatesOriginal = payload;
      }
    );
    builder.addCase(searchHotelOffers.pending, (state) => {
      //state.hotelOfferInfos = {};
    });
    builder.addCase(searchHotelOffers.fulfilled, (state, { payload, meta }) => {
      state.subload = false;
      if (
        !state?.selectedHotel?.HotelID &&
        payload.HotelDictionary?.Hotel?.length > 0
      ) {
        state.selectedHotel = payload.HotelDictionary.Hotel[0];
      }
      if (meta.arg.offset === 0) {
        if (payload.Offers.OfferCount > 0) {
          state.hotelOfferInfos = payload;
        } else {
          state.hotelOfferInfos = {};

          if (!payload.Offers.MoreOffersAvailable) {
            state.hotelOffersFound = false;
          }
        }
      } else {
        if (payload.Offers.OfferCount > 0) {
          let infos = { ...state.hotelOfferInfos };
          infos.Offers.OfferCount += payload.Offers.OfferCount;
          infos.Offers.Offer = [...infos.Offers.Offer, ...payload.Offers.Offer];
          if (infos.StationDictionary?.Station)
            infos.StationDictionary.Station = [
              ...infos.StationDictionary.Station,
              ...payload.StationDictionary.Station,
            ];

          infos.HotelDictionary.Hotel = [
            ...infos.HotelDictionary.Hotel,
            ...payload.HotelDictionary.Hotel,
          ];
          infos.GeoDictionary.Cities.City = [
            ...infos.GeoDictionary.Cities.City,
            ...payload.GeoDictionary.Cities.City,
          ];
          infos.GeoDictionary.Continents.Continent = [
            ...infos.GeoDictionary.Continents.Continent,
            ...payload.GeoDictionary.Continents.Continent,
          ];
          infos.GeoDictionary.Countries.Country = [
            ...infos.GeoDictionary.Countries.Country,
            ...payload.GeoDictionary.Countries.Country,
          ];
          infos.GeoDictionary.Provinces.Province = [
            ...infos.GeoDictionary.Provinces.Province,
            ...payload.GeoDictionary.Provinces.Province,
          ];
          infos.GeoDictionary.RegionGroups.RegionGroup = [
            ...infos.GeoDictionary.RegionGroups.RegionGroup,
            ...payload.GeoDictionary.RegionGroups.RegionGroup,
          ];
          infos.GeoDictionary.Regions.Region = [
            ...infos.GeoDictionary.Regions.Region,
            ...payload.GeoDictionary.Regions.Region,
          ];

          state.hotelOfferInfos = infos;
        }
      }
      let infos = { ...state.hotelOfferInfos };
      let roomtypes = [...state.hotelSpecialRoomTypes];
      let spaArray = [];
      //console.log(payload.Offers);
      if (!payload.Offers.MoreOffersAvailable && infos?.Offers) {
        state.subload = true;
        //console.log(infos.Offers.Offer);
        for (let i = 0; i < infos.Offers.Offer.length; i++) {
          if (
            Array.isArray(infos.Offers.Offer[i].MarketingActions) &&
            infos.Offers.Offer[i].MarketingActions.length > 0 &&
            infos.Offers.Offer[i].MarketingActions[0].RoomMouseover
          ) {
            let roomnames =
              infos.Offers.Offer[i].MarketingActions[0].RoomMouseover;
            //console.log(roomnames);

            for (let j = 0; j < roomtypes.length; j++) {
              if (roomnames.indexOf(roomtypes[j]) > -1) {
                let spa = spaArray.find((e) => {
                  return e.value === roomtypes[j];
                });
                if (spa) {
                  spa.amount = spa.amount + 1;
                } else {
                  spaArray.push({ value: roomtypes[j], amount: 1 });
                }
              }
            }
          }
        }
      }

      state.hotelSpecialAmount = spaArray;
    });
    builder.addCase(searchHotelOffers.rejected, (state) => {
      state.hotelOfferInfos = {};
    });

    builder.addCase(searchBetterOffers.pending, (state, action) => {
      //state.hotelOfferInfos = {};
      state.otherids = [...state.otherids, action.meta.arg.parentid];
    });
    builder.addCase(searchBetterOffers.fulfilled, (state, action) => {
      if (action.payload.forceClear === true) state.betterOffers = {};
      if (action.payload.Offers.OfferCount > 0) {
        state.betterOffers = {
          ...state.betterOffers,
          [action.meta.arg.parentid]: action.payload,
        };
      } else {
        state.betterOffers = {
          ...state.betterOffers,
          [action.meta.arg.parentid]: {},
        };
      }

      state.otherids = state.otherids.filter(
        (e) => e !== action.meta.arg.parentid
      );
    });
    builder.addCase(searchBetterOffers.rejected, (state, action) => {
      //state.hotelOfferInfos = {};
      state.otherids = state.otherids.filter(
        (e) => e !== action.meta.arg.parentid
      );
    });
  },
});

export const {
  setSelectedHotel,
  setHotelOfferInfos,
  setHotelPage,
  setHotelSort,
  setInfoHotel,
  setOfferId,
  setHotelData,
  setMarked,
  setHotelSpecialRoomTypes,
  setOfferFlexbooked,
  clearMarketingActions,
  setHotelOffersFound,
} = hotelSlice.actions;

export default hotelSlice.reducer;
