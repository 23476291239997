import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  checkOfferAction,
  searchAlternativeTransports,
  setOfferFlexbooked,
  setOfferId,
} from "amadeusredux/lib/hotelSlice";

import { availabilityAndPriceCheck } from "amadeusredux/lib/api";
import { roomTypesForTourOperator } from "../offerutils";

const nf1 = new Intl.NumberFormat("de-DE", {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});
const nf = new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2 });

function RoomTypeSelect(props) {
  const {
    offer, //setredrawVoucher,
    allRoomOffers,
    openSubSelect,
  } = props;
  const sessionId = useSelector((state) => state.system.sessionId);
  const checkids = useSelector((state) => state.hotel.checkids);
  const dispatch = useDispatch();
  const hotelOfferInfos = useSelector((state) => state.hotel.hotelOfferInfos);
  const stations = hotelOfferInfos.StationDictionary;
  const holidayType = useSelector((state) => state.search.holidayType);
  const offerdiv = React.useRef(null);
  const infos = useSelector((state) => state.properties.infos);
  const intl = useIntl();
  const df = new Intl.DateTimeFormat(intl.locale, {
    weekday: "short",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  let hotels = hotelOfferInfos.HotelDictionary;
  const [packageInfo, setPackageInfo] = React.useState({
    outbound: "",
    inbound: "",
    offerId: "",
  });
  const hotel = hotels
    ? hotels.Hotel.find(
        (e) =>
          e.HotelID ===
          offer?.OfferServices?.Package?.Accommodation?.HotelRef?.HotelID
      )
    : {};

  let offerstatus = 0;

  if (offer?.bookinginfo) {
    if (
      offer?.bookinginfo?.Status?.Status === "Available" ||
      offer?.bookinginfo?.Status?.Status === "ToConfirm" ||
      offer?.bookinginfo?.Status?.Status === "OnRequest"
    ) {
      offerstatus = 1;
    } else {
      offerstatus = -1;
    }
  }

  let reason = "";
  reason = offer?.bookinginfo?.Status?.StatusNo;

  return (
    <>
      {allRoomOffers.length > 3 && (
        <small>Scrollen Sie durch die Zimmerarten</small>
      )}
      <div
        style={{ display: "flex", overflowY: "scroll" }}
        data-bs-spy="scroll"
        data-bs-target="#rooms"
      >
        {allRoomOffers.map((e, i) => {
          let realprice = 0;

          if (e.offer?.bookinginfo?.PriceInformation?.TotalPrice?.Amount?._) {
            realprice =
              e.offer?.bookinginfo?.PriceInformation?.TotalPrice?.Amount?._ * 1;
          }

          if (realprice === 0 && e.offer.PriceInfo?.Price.value) {
            realprice =
              e.offer.PriceInfo?.Price.value *
              (e.offer.adults * 1 + e.offer.children * 1);
          }

          return (
            <>
              <div
                style={{ padding: "10px", minWidth: 150 }}
                id={"room" + e.offer.OfferID}
                className={
                  "me-2 list-group-item " +
                  (e.offer?.OfferServices?.Package?.Accommodation?.Room
                    ?.RoomName ===
                  offer.OfferServices?.Package?.Accommodation?.Room?.RoomName
                    ? " selectedbutton "
                    : " smallbutton  ")
                }
              >
                <div
                  className="col-sm-12 col-md-12 col-lg-12 "
                  onClick={() => {
                    //setFlexSelected(false);
                    dispatch(
                      setOfferFlexbooked({
                        offerId: e.offer.OfferID,
                        flexbooked: false,
                      })
                    );
                    dispatch(setOfferId(e.offer.OfferID));
                    openSubSelect("rooms");
                    //setredrawVoucher(true);
                  }}
                >
                  <div>
                    <p
                      className="text-body font-weight-bold mb-0"
                      title={
                        e.offer.OfferServices?.Package.Accommodation.Room
                          .RoomName
                      }
                    >
                      {
                        e.offer.OfferServices?.Package.Accommodation.Room
                          .RoomName
                      }
                    </p>

                    {offerstatus === 0 && (
                      <>
                        <p className="text-warning mb-2 small font-weight-bold">
                          Zimmerverfügbarkeit wird geprüft
                        </p>
                      </>
                    )}
                    {offerstatus === 1 && (
                      <>
                        <p className="text-body mb-2  font-weight-bold">
                          ab {realprice} €
                        </p>
                      </>
                    )}
                    {offerstatus === -1 && (
                      <>
                        <p className="mb-2 small font-weight-bold text-danger  ">
                          {!reason && <>leider nicht verfügbar.</>}
                          {reason &&
                            (reason.startsWith("41") ? (
                              <>Flug leider nicht verfügbar.</>
                            ) : reason.startsWith("42") ? (
                              <>Zimmer leider nicht verfügbar.</>
                            ) : (
                              <>leider nicht verfügbar.</>
                            ))}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}

export default RoomTypeSelect;
