import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { availabilityAndPriceCheck } from "amadeusredux/lib/api";
import {
  availableOfferData,
  getInsurance,
  getPassinfo,
  getTermsAndConditions,
  setInboundView,
  setTheOffer,
} from "amadeusredux/lib/bookingSlice";
import { setOfferId } from "amadeusredux/lib/hotelSlice";
import { setView } from "amadeusredux/lib/systemSlice";
import { views } from "amadeusredux/lib/utils";

export default function AvailabilityButton(props) {
  const dispatch = useDispatch();
  const {
    offer,
    hotel,
    inboundView,
    parentid,
    availableAction,
    availableText,
    isVoucher,
    mainOffer,
    flightOffer,
    betterOption,
  } = props;
  const checkids = useSelector((state) => state.hotel.checkids);
  const sessionId = useSelector((state) => state.system.sessionId);
  let status = 0;

  if (offer?.bookinginfo) {
    if (
      offer?.bookinginfo?.Status?.Status === "Available" ||
      offer?.bookinginfo?.Status?.Status === "ToConfirm" ||
      offer?.bookinginfo?.Status?.Status === "OnRequest"
    ) {
      status = 1;
    } else {
      status = -1;
    }
  }
  if (betterOption && status === -1) {
    return <small>Angebot nicht verfügbar</small>;
  }

  return (
    <>
      <button
        className={
          "btn " +
          (Array.isArray(checkids) && checkids.includes(offer.OfferID)
            ? "btn-primary"
            : status === 0
            ? "btn-primary"
            : status === 1
            ? isVoucher
              ? "btn-success "
              : "btn-success"
            : isVoucher
            ? "btn-danger"
            : "btn-warning")
        }
        style={{
          display: "inline-block",
          cursor:
            (Array.isArray(checkids) && checkids.includes(offer.OfferID)) ||
            status === -1
              ? ""
              : "pointer",
        }}
        onClick={() => {
          if (status === 1 && availableAction) {
            availableAction(offer);
            return;
          }
          if (status === 1) {
            dispatch(setTheOffer(offer));
            dispatch(setOfferId(offer.OfferID));
            dispatch(
              availableOfferData({
                SessionID: offer?.bookinginfo.$.SessionID,
              })
            ).then((data) =>
              dispatch(
                getTermsAndConditions({
                  touroperator: offer?.bookinginfo?.Offer?.TourOperator?.Code,
                  startdate:
                    offer?.bookinginfo?.Offer?.OfferDateSpan?.$?.Start.substring(
                      0,
                      10
                    ),
                })
              ).then((data) => {
                dispatch(
                  getPassinfo({
                    nat: "de",
                    countrycode:
                      hotel.HotelLocationRef.CountryCode.toLowerCase(),
                    pos: 0,
                  })
                );

                dispatch(
                  getInsurance({
                    SessionID: offer?.bookinginfo.$.SessionID,
                  })
                );
                dispatch(setInboundView(inboundView));
                dispatch(setView(views.bookingview));
              })
            );
            return;
          }
          if (
            status === 0 &&
            !(Array.isArray(checkids) && checkids.includes(offer.OfferID))
          ) {
            //console.log(offer);
            availabilityAndPriceCheck(
              dispatch,
              sessionId,
              offer.OfferID,
              offer.adults,
              offer.children,
              mainOffer ? undefined : parentid,
              flightOffer
            );
          } else {
          }
        }}
      >
        {Array.isArray(checkids) && checkids.includes(offer.OfferID) ? (
          <>
            Verfügbarkeit prüfen
            <svg
              className={"cls"}
              width={15}
              height={15}
              viewBox="0 0 46 46"
              xmlns="http://www.w3.org/2000/svg"
              stroke={"white"}
              aria-label={"check"}
            >
              <g fill="none" fillRule="evenodd">
                <g transform="translate(5 5)" strokeWidth="10">
                  <circle strokeOpacity=".7" cx="18" cy="18" r="18" />
                  <path d="M36 18c0-9.94-8.06-18-18-18">
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      from="0 18 18"
                      to="360 18 18"
                      dur="1s"
                      repeatCount="indefinite"
                    />
                  </path>
                </g>
              </g>
            </svg>
          </>
        ) : status === 0 ? (
          "Verfügbarkeit prüfen"
        ) : status === 1 ? (
          availableText ? (
            availableText
          ) : (
            "zum Buchungsformular"
          )
        ) : (
          "Angebot nicht verfügbar"
        )}
      </button>
    </>
  );
}
