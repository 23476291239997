import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "../components/Select";
import { selectSearch, setSearchOption } from "amadeusredux/lib/searchSlice";

export default function FlighttimeFilter(props) {
  const { outbound } = props;

  const dispatch = useDispatch();

  const searchOptions = useSelector(selectSearch);

  const holidayType = useSelector((state) => state.search.holidayType);

  const times = [
    { value: "", label: "Keine Auswahl" },
    { value: "0-5", label: "00-06 Uhr" },
    { value: "6-11", label: "06-12 Uhr" },
    { value: "12-17", label: "12-18 Uhr" },
    { value: "18-23", label: "18-00 Uhr" },
  ];

  return (
    <div>
      <div className="fw-semibold">
        {outbound ? "Abflugzeit" : "Rückflugzeit"}
      </div>
      <div>
        <select
          className="form-select"
          onChange={(e) => {
            dispatch(
              setSearchOption({
                property: outbound ? "outboundtime" : "inboundtime",
                data: e.target.value,
              })
            );
          }}
          value={
            outbound ? searchOptions.outboundtime : searchOptions.inboundtime
          }
        >
          {times.map((o, i) => {
            return <option key={o.value} value={o.value}>{o.label}</option>;
          })}
        </select>
      </div>
    </div>
  );
}
