import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FormattedDate, FormattedMessage } from "react-intl";
import {
  FaHeart,
  FaInfoCircle,
  FaPlane,
  FaShareAlt,
  FaLongArrowAltLeft,
  FaLongArrowAltRight,
  FaCaretLeft,
  FaPlus,
  FaBed,
} from "react-icons/fa";
import {
  propertyFromArray,
  roomViews,
  boardTypes,
  roomAttributes,
} from "../utils";
import InfoPopupHover from "./InfoPopupHover";
import MobileOfferFlight from "../components/MobileOfferFlight";
import CancellationOptions from "../CancellationOptions";

export default function MobileOfferVoucher(props) {
  const {
    mode,
    offer,
    stations,
    flexbooked,
    selectedTAMarketingAction,
    selectedTOMarketingAction,
    reservationselected,
  } = props;
  const holidayType = useSelector((state) => state.search.holidayType);
  const multiroomdivider = useSelector(
    (state) => state.search.multiroomdivider
  );
  const [packageInfo, setPackageInfo] = React.useState({
    outbound: "",
    inbound: "",
    offerId: "",
  });
  let offerstatus = 0;
  if (offer?.bookinginfo) {
    if (
      offer?.bookinginfo?.Status?.Status === "Available" ||
      offer?.bookinginfo?.Status?.Status === "ToConfirm" ||
      offer?.bookinginfo?.Status?.Status === "OnRequest"
    ) {
      offerstatus = 1;
    } else {
      offerstatus = -1;
    }
  }

  let transferincl = false;
  if (offer?.OfferProperties?.TransferInfos) {
    if (
      Array.isArray(offer.OfferProperties.TransferInfos) &&
      offer.OfferProperties.TransferInfos.includes("TransferIncluded")
    ) {
      transferincl = true;
    }
  }
  let railandfly = false;
  if (offer?.OfferProperties?.OfferIncludes) {
    if (
      Array.isArray(offer.OfferProperties.OfferIncludes) &&
      offer.OfferProperties.OfferIncludes.includes("RailAndFly")
    ) {
      railandfly = true;
    }
  }

  let rentalCar = false;
  if (offer?.OfferProperties?.OfferIncludes) {
    if (
      Array.isArray(offer.OfferProperties.OfferIncludes) &&
      offer.OfferProperties.OfferIncludes.includes("RentalCar")
    ) {
      rentalCar = true;
    }
  }
  let freeRebook = false;
  if (offer?.OfferProperties?.OfferIncludes) {
    if (
      Array.isArray(offer.OfferProperties.OfferIncludes) &&
      offer.OfferProperties.OfferIncludes.includes("FreeRebooking")
    ) {
      freeRebook = true;
    }
  }

  let marketingGroup = "";
  return (
    <>
      <div className="row justify-content-md-left small me-1">
        <div
          className="col-sm-9 col-md-9 col-lg-9 col-9"
          onClick={() => {
            console.log(offer);
            console.log(JSON.stringify(offer, null, 10));
          }}
        >
          {offer?.TourOperator?.TourOperatorName}
        </div>
        <div className="col-sm-3 col-md-3 col-lg-3 col-3 text-end">
          <img src={offer.TourOperator.TourOperatorImage} />
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 col-6 mt-2">
          {offer?.adults * 1 + offer?.children * 1} Personen
          <br />
          <span className="mb-0 text">
            {
              <FormattedDate
                value={
                  holidayType.flight
                    ? offer?.TravelDateInfo?.DepartureDate
                    : offer?.TravelDateInfo?.CheckInDate
                }
                month="2-digit"
                day="2-digit"
              />
            }{" "}
            -{" "}
            {
              <FormattedDate
                value={
                  holidayType.flight
                    ? offer?.TravelDateInfo?.ReturnDate
                    : offer?.TravelDateInfo?.CheckOutDate
                }
                month="2-digit"
                day="2-digit"
                year="2-digit"
              />
            }
          </span>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 col-6 mt-2">
          {holidayType.flight && (
            <>
              {offer?.TravelDateInfo?.TripDuration
                ? offer.TravelDateInfo?.TripDuration
                : offer.TravelDateInfo?.OvernightStays}{" "}
              Tage
              <br />
            </>
          )}
          {offer.TravelDateInfo?.OvernightStays} Übernachtungen
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 col-12 mt-2">
          <p className="text mb-0 ">
            <span style={{ width: 19 }}>
              <FaBed></FaBed>
            </span>
            &nbsp;{multiroomdivider > 1 ? multiroomdivider + " x " : ""}
            {offer.OfferServices.Package.Accommodation.Room.RoomName}
            &nbsp;&nbsp;
            {offer.OfferServices.Package.Accommodation.Room.RoomView !==
              "NothingSpecial" && (
              <>
                <span className="vouchericontext">
                  {propertyFromArray(
                    roomViews,
                    offer.OfferServices.Package.Accommodation.Room.RoomView,
                    "value",
                    "label"
                  )}
                </span>
              </>
            )}
          </p>
        </div>
        <div style={{ whiteSpace: "nowrap" }}>
          {offer.OfferServices?.Package.Accommodation.Room.RoomView !==
            "NothingSpecial" && (
            <div>
              <img
                src="https://holiday.placelogg.com/holidaymedia/Auge.png"
                width="16px"
              />
              &nbsp;
              <span className="mb-0 text">
                {propertyFromArray(
                  roomViews,
                  offer.OfferServices?.Package.Accommodation.Room.RoomView,
                  "value",
                  "label"
                )}
              </span>
            </div>
          )}
          <img
            src="https://holiday.placelogg.com/holidaymedia/Restaurant.png"
            width="16px"
          />
          &nbsp;
          <span className="mb-0 text">
            {propertyFromArray(
              boardTypes,
              offer.OfferServices?.Package.Accommodation.BoardType,
              "value",
              "short"
            )}
            <span style={{ fontSize: "1em" }}>
              &nbsp;&nbsp;|&nbsp; ZC:{" "}
              {offer.OfferServices?.Package.Accommodation.Room.RoomCode}
              &nbsp;|&nbsp; HC:{" "}
              {offer.OfferServices?.Package.Accommodation.ServiceCode}
            </span>

            {Array.isArray(
              offer.OfferServices?.Package?.Accommodation?.Room?.RoomAttributes
            ) &&
              offer.OfferServices?.Package?.Accommodation?.Room?.RoomAttributes.map(
                (att) => {
                  return roomAttributes[att] ? (
                    <span>
                      <br />
                      <FaPlus />
                      &nbsp;
                      {roomAttributes[att]}
                    </span>
                  ) : (
                    <></>
                  );
                }
              )}
          </span>
          <div>
            {transferincl ? (
              <>
                <div>
                  <img
                    src="https://holiday.placelogg.com/holidaymedia/Bus.png"
                    width="16px"
                  />
                  &nbsp;
                  <span className="mb-0 text">
                    <FormattedMessage id="transferincluded" />
                  </span>
                </div>
              </>
            ) : (
              <>
                <div>
                  <img
                    src="https://holiday.placelogg.com/holidaymedia/Bus.png"
                    width="16px"
                  />
                  &nbsp;
                  <span className="mb-0 text">
                    <FormattedMessage id="transfernotincluded" />
                  </span>
                </div>
              </>
            )}
            {railandfly ? (
              <div>
                <div>
                  <img
                    src="https://holiday.placelogg.com/holidaymedia/train.png"
                    width="16px"
                  />
                  &nbsp;
                  <span className="mb-0 text">Zug zum Flug</span>
                </div>
              </div>
            ) : null}{" "}
            {rentalCar ? (
              <div>
                <div>
                  <img
                    src="https://holiday.placelogg.com/holidaymedia/Mietwagen.png"
                    width="16px"
                  />
                  &nbsp;
                  <span className="mb-0 text">Mietwagen</span>
                </div>
              </div>
            ) : null}{" "}
            {packageInfo.outbound ? (
              <div className="hoteloffervoucher18">
                <div>
                  <img
                    src="https://holiday.placelogg.com/holidaymedia/koffer.png"
                    width="16px"
                  />
                  &nbsp;
                  <span className="mb-0 text">
                    <small>
                      <span title="Hinflug">H:</span> {packageInfo.outbound} +
                      Handgepäck
                      <InfoPopupHover
                        trigger={
                          <span>
                            <FaInfoCircle className="filterinfo" />
                          </span>
                        }
                        content={
                          "Zusätzlich zum angegebenen Gepäck dürfen Sie ein Handgepäck bis 8 kg mitnehmen. Bei Fragen wenden Sie sich an uns."
                        }
                        position={"right center"}
                      ></InfoPopupHover>
                    </small>
                  </span>
                </div>
              </div>
            ) : null}
            {packageInfo.inbound ? (
              <div className="hoteloffervoucher18">
                <div>
                  <img
                    src="https://holiday.placelogg.com/holidaymedia/koffer.png"
                    width="16px"
                  />
                  &nbsp;
                  <span className="mb-0 text">
                    <small>
                      <span title="Rückflug">R:</span> {packageInfo.inbound} +
                      Handgepäck
                      <InfoPopupHover
                        trigger={
                          <span>
                            <FaInfoCircle className="filterinfo" />
                          </span>
                        }
                        content={
                          "Zusätzlich zum angegebenen Gepäck dürfen Sie ein Handgepäck bis 8 kg mitnehmen. Bei Fragen wenden Sie sich an uns."
                        }
                        position={"right center"}
                      ></InfoPopupHover>
                    </small>
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {offerstatus == 1 && (
        <>
          {holidayType.flight && (
            <div className="small">
              <MobileOfferFlight
                offer={offer}
                stations={stations}
                verbose={true}
              ></MobileOfferFlight>
            </div>
          )}
          <div>&nbsp;</div>
          {mode !== "booking" || reservationselected ? (
            <p
              className={
                "mb-0 text small mb-1 " +
                (Array.isArray(offer?.MarketingActions) &&
                offer?.MarketingActions.length > 0 &&
                offer?.MarketingActions[0].reservation
                  ? "text-success"
                  : "text-danger")
              }
            >
              {Array.isArray(offer?.MarketingActions) &&
                offer?.MarketingActions.length > 0 &&
                offer?.MarketingActions[0].reservationtext}
            </p>
          ) : (
            ""
          )}
          <p className="mb-0 text small">
            {
              <CancellationOptions
                mode={mode}
                flexbooked={flexbooked}
                offer={offer}
                verbose={true}
              />
            }
          </p>

          {mode !== "booking" &&
            Array.isArray(offer.MarketingActions) &&
            offer.MarketingActions.filter((e) => e.typ_id === "0018-0001")
              .length > 0 && (
              <div>
                {" "}
                {Array.isArray(offer?.MarketingActions) &&
                  offer.MarketingActions.filter(
                    (e) => e.typ_id === "0018-0001"
                  ).map((e, i) => (
                    <InfoPopupHover
                      trigger={
                        <div title={e.reason}>
                          {e.MarketingProperties && (
                            <span>
                              {i > 0 && <span>oder</span>}
                              {e.MarketingProperties?.actiontext}
                            </span>
                          )}
                        </div>
                      }
                      content={
                        e.MarketingProperties?.typ === "0022-0002"
                          ? "Der Cashback-Betrag wird von Ihrem Reisepreis abgezogen"
                          : e.MarketingProperties?.mouseover
                      }
                      position={"right center"}
                    ></InfoPopupHover>
                  ))}
              </div>
            )}

          {mode === "booking" && (
            <div>
              {Array.isArray(selectedTOMarketingAction) &&
                selectedTOMarketingAction
                  .filter((e) => e.typ_id === "0018-0001")
                  .map((e, i) => (
                    <div
                      title={e.reason}
                      style={{
                        display: "flex",
                      }}
                    >
                      {e.MarketingProperties && (
                        <span>{e.MarketingProperties?.actiontext}</span>
                      )}
                    </div>
                  ))}
            </div>
          )}

          {mode !== "booking" &&
            Array.isArray(offer?.MarketingActions) &&
            offer.MarketingActions.filter((e) => e.typ_id === "0018-0002")
              .sort((a, b) => {
                return a.MarketingProperties.actiongroup <
                  b.MarketingProperties.actiongroup
                  ? -1
                  : a.MarketingProperties.actiongroup ==
                    b.MarketingProperties.actiongroup
                  ? 0
                  : 1;
              })
              .map((e, i) => {
                let tmp = (
                  <InfoPopupHover
                    trigger={
                      <div title={e.reason}>
                        {e.MarketingProperties && (
                          <>
                            {i > 0 &&
                              marketingGroup ===
                                e.MarketingProperties.actiongroup && (
                                <div>
                                  <span>oder</span>
                                </div>
                              )}
                            {i > 0 &&
                              marketingGroup !==
                                e.MarketingProperties.actiongroup && (
                                <>
                                  <div>
                                    <span>und</span>
                                  </div>
                                </>
                              )}
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    e.MarketingProperties?.typ === "0022-0002"
                                      ? "Der Cashback-Betrag wird vom Reisebüro erstattet."
                                      : "" +
                                        (e.MarketingProperties?.text
                                          ? e.MarketingProperties?.text
                                          : ""),
                                }}
                              ></div>
                            </div>
                          </>
                        )}
                      </div>
                    }
                    content={e.MarketingProperties?.mouseover}
                    position={"right center"}
                  ></InfoPopupHover>
                );
                if (marketingGroup !== e.MarketingProperties.actiongroup) {
                  marketingGroup = e.MarketingProperties.actiongroup;
                  if (i != 0) {
                    return (
                      <>
                        <div></div>
                        {tmp}
                      </>
                    );
                  }
                }
                return <>{tmp}</>;
              })}

          {mode === "booking" &&
            Array.isArray(selectedTAMarketingAction) &&
            selectedTAMarketingAction
              .filter((e) => e.typ_id === "0018-0002")
              .map((e, i) => (
                <div title={e.reason} style={{ display: "flex" }}>
                  {e.MarketingProperties && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: "" + e.MarketingProperties?.text,
                      }}
                    ></div>
                  )}
                </div>
              ))}
        </>
      )}
    </>
  );
}
