import { io } from "socket.io-client";

const URL =
  process.env.NODE_ENV === `development` ||
  document.location.href.indexOf("localhost") > -1 ||
  document.location.href.indexOf("tstsrv") > -1
    ? "wss://www.tstsrv.de"
    : "wss://www.woistmeinauto.jetzt";
//wss://www.woistmeinauto.jetzt
export const socket = io(URL, { path: "/wimasocket/" });
