import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import axios from "axios";
import { getHost, populatePost } from "./utils";
import { searchHotelOffers } from "./hotelSlice";
import { queryOrderPlus, searchOffers } from "./offersSlice";

axios.defaults.headers.common["AgencyID"] = window.configData.AGENCYID;
axios.defaults.headers.common["Version"] = process.env.REACT_APP_VERSION;
axios.defaults.headers.common["App"] = window.configData.APP;

export const getDepartures = createAsyncThunk(
  "filter/getDepartures",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    try {
      const response = await axios.get(
        getHost() +
          "/checkairports?zipcode=" +
          encodeURIComponent(payload.zipcode) +
          "&info=airports"
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAllActions = createAsyncThunk(
  "filter/getAllActions",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();
    //console.log(payload);

    try {
      const response = await axios.get(
        getHost() + "/getallactions?agencyid=" + window.configData.AGENCYID
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const setSessionOptions = createAsyncThunk(
  "filter/setsessionoptions",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();
    //console.log(payload);
    //console.log({ ...payload, sessionid: state.system.sessionId });
    try {
      const response = await axios.post(
        getHost() + "/setsessionoptions",

        { ...payload, sessionid: state.system.sessionId }
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getSuggestions = createAsyncThunk(
  "filter/getSuggestions",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();
    //console.log(payload);
    let req = {};
    try {
      req = {
        ...populatePost(
          payload.hotel
            ? [state.hotel.selectedHotel]
            : [...state.search.selectedDestination],
          { ...state.search, ...payload.update },
          state.properties.departureStations
        ),
      };
    } catch (e) {
      req = {};
    }
    try {
      const response = await axios.post(getHost() + "/getholidaysuggestions", {
        request: req,
        text: payload.text,
        zipcode: payload.zipcode,
        agencyid: window.configData.AGENCYID,
        sessionid: state.system.sessionid,
      });

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const propertiesCheck = createAsyncThunk(
  "filter/filteredProperties",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    let giataids = [];

    let parms = {
      ...populatePost(
        payload.hotel
          ? [state.hotel.selectedHotel]
          : [...state.search.selectedDestination],
        { ...state.search, ...payload.update },
        state.properties.departureStations
      ),

      property: payload.property,
    };

    try {
      const response = await axios.post(
        getHost() + "/inventoryDataForPackageCheck",
        parms
      );
      //console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const filteredProperties = createAsyncThunk(
  "filter/filteredProperties",
  async (payload, thunkAPI) => {
    let state = thunkAPI.getState();

    let giataids = [];
    if (payload.property === "TourOperatorsForHotel") {
      if (Array.isArray(state?.offers?.offerInfos?.HotelDictionary?.Hotel)) {
        /*console.log(
          "TourOperatorsForHotel",
          Array.isArray(state?.offers?.offerInfos?.HotelDictionary?.Hotel)
        );*/
        //console.log("touroperatorsForHotel");

        let ct = 0;
        let iffs = state.filter.touroperatorsForHotel.map(
          (e) => e.HotelIffCode
        );
        //console.log(iffs);
        for (
          let i = 0;
          i < state.offers.offerInfos.HotelDictionary.Hotel.length;
          i++
        ) {
          if (ct >= 500) {
            //console.log(state.offers.offerInfos.HotelDictionary.Hotel[i]);

            break;
          }
          let hotel = state.offers.offerInfos.HotelDictionary.Hotel[i];
          if (!iffs.includes(hotel.HotelCodes.HotelIffCode)) {
            ct = ct + 1;
            giataids.push(hotel.HotelCodes.HotelGiataID);
          }
        }
      }
    }

    let parms = {
      ...populatePost(
        payload.hotel
          ? [state.hotel.selectedHotel]
          : [...state.search.selectedDestination],
        { ...state.search, ...payload.update },
        state.properties.departureStations
      ),

      property: payload.property,
    };

    if (payload.property === "TourOperatorsForHotel") {
      if (giataids.length === 0) {
        return {};
      }
      parms = {
        ...populatePost(
          payload.hotel
            ? [state.hotel.selectedHotel]
            : [...state.search.selectedDestination],
          { ...state.search, ...payload.update },
          state.properties.departureStations
        ),
        HotelGiataID: giataids,
        property: payload.property,
      };
    }
    try {
      const response = await axios.post(
        getHost() +
          (state?.search?.holidayType?.flight === false
            ? "/inventoryDataForAccommodationFiltered"
            : "/inventoryDataForPackageFiltered"),
        parms
      );
      //console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const initialState = {
  boardTypes: [],
  roomTypes: [],
  roomViews: [],
  cabinClasses: [],
  tourOperators: [],
  hotelTourOperators: [],
  touroperatorsForHotel: [],
  hotelAttributes: [],
  hotelCount: 0,
  allActions: [],
  destinationAirports: [],
  marketingActions: [],
  hotelMarketingActions: [],
  optionOperators: [],
  transportOperators: [],
  holidaySuggestions: [],
  selectedRoomAttributes: [],
  roomAttributesFilter: {},
  departures: [],
  sessionOptions: {},
  offerlistfilter: "",
};

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setOfferlistfilter: (state, action) => {
      state.offerlistfilter = action.payload;
    },
    setOptionOperators: (state, action) => {
      state.optionOperators = action.payload;
    },

    setTouroperatorsForHotel: (state, action) => {
      state.touroperatorsForHotel = action.payload;
    },

    setSelectedRoomAttributes: (state, action) => {
      state.selectedRoomAttributes = action.payload;
    },

    setRoomAttributesFilter: (state, action) => {
      state.roomAttributesFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSessionOptions.fulfilled, (state, action) => {
      state.sessionOptions = action.payload;
    });

    builder.addCase(getSuggestions.fulfilled, (state, action) => {
      state.holidaySuggestions = action.payload.ret;
    });

    builder.addCase(getDepartures.fulfilled, (state, action) => {
      state.departures = action.payload.ret;
    });

    builder.addCase(filteredProperties.pending, (state) => {
      //state.hotelOfferInfos = {};
    });

    builder.addCase(getAllActions.fulfilled, (state, action) => {
      state.allActions = action.payload.ret;
    });

    builder.addCase(filteredProperties.fulfilled, (state, action) => {
      if (
        action.meta.arg.property === "TouristicRegions" &&
        Array.isArray(action.payload.RegionGroups?.RegionGroup)
      ) {
        let ct = 0;
        for (
          let i = 0;
          i < action.payload.RegionGroups.RegionGroup.length;
          i++
        ) {
          ct += action.payload.RegionGroups.RegionGroup[i].HotelCount;
        }

        state.hotelCount = ct;
      }

      if (
        action.meta.arg.property === "RoomType" &&
        action.payload.RoomTypes?.RoomType
      )
        state.roomTypes = action.payload.RoomTypes.RoomType;
      if (
        action.meta.arg.property === "BoardTypes" &&
        action.payload.BoardTypes?.BoardType
      )
        state.boardTypes = action.payload.BoardTypes.BoardType;
      if (
        action.meta.arg.property === "RoomView" &&
        action.payload.RoomViews?.RoomView
      )
        state.roomViews = action.payload.RoomViews.RoomView;
      if (
        action.meta.arg.property === "DestinationStations" &&
        action.payload.Stations?.DestinationStations
      ) {
        //console.log("DestinationStations.DestinationStation",action.payload.Stations?.DestinationStations);
        state.destinationAirports =
          action.payload.Stations?.DestinationStations;
      }
      if (
        action.meta.arg.property === "TourOperatorsForHotel" &&
        action.payload.TourOperatorsForHotels &&
        Array.isArray(
          action?.payload?.TourOperatorsForHotels?.TourOperatorsForHotel
        )
      ) {
        if (action.meta.arg.firstcall) {
          state.touroperatorsForHotel =
            action.payload.TourOperatorsForHotels.TourOperatorsForHotel;
        } else {
          state.touroperatorsForHotel = [
            ...state.touroperatorsForHotel,
            ...action.payload.TourOperatorsForHotels.TourOperatorsForHotel,
          ];
        }
      }

      if (
        Array.isArray(
          action?.payload?.TransportOperatingBrands?.TransportOperatingBrand
        )
      ) {
        let oplines = [];
        /*console.log(
          "TransportOperatingBrand",
          action.payload.TransportOperatingBrands.TransportOperatingBrand.length
        );*/
        for (
          let i = 0;
          i <
          action.payload.TransportOperatingBrands.TransportOperatingBrand
            .length;
          i++
        ) {
          oplines.push({
            value:
              action.payload.TransportOperatingBrands.TransportOperatingBrand[i]
                .Code,
            label:
              action.payload.TransportOperatingBrands.TransportOperatingBrand[i]
                .Name,
          });
        }
        state.transportOperators = oplines;
      }

      if (
        action.meta.arg.property === "TourOperator" &&
        action.payload.TourOperators?.TourOperator
      ) {
        if (action.meta.arg.hotel)
          state.hotelTourOperators = action.payload.TourOperators.TourOperator;
        else state.tourOperators = action.payload.TourOperators.TourOperator;
      }
      if (
        action.meta.arg.property === "HotelAttributes" &&
        action.payload.HotelAttributes?.HotelAttribute
      )
        state.hotelAttributes = action.payload.HotelAttributes.HotelAttribute;
    });
    builder.addCase(filteredProperties.rejected, (state, action) => {
      //console.log(action);
      //state.hotelOfferInfos = {};
    });

    builder.addCase(queryOrderPlus.pending, (state) => {
      //state.marketingActions = [];
    });
    builder.addCase(queryOrderPlus.fulfilled, (state, { payload, meta }) => {
      //console.log("payload", payload);
      if (payload.data.length > 0) {
        let maArray = [];
        //console.log(maArray)
        for (let i = 0; i < payload.data.length; i++) {
          let dat = payload.data[i];
          if (
            Array.isArray(dat.MarketingProperties) &&
            dat.MarketingProperties.length > 0
          ) {
            for (let j = 0; j < dat.MarketingProperties.length; j++) {
              let ma = dat.MarketingProperties[j];

              if (ma.typ_id === "0018-0001" || ma.typ_id === "0018-0002") {
                if (!maArray.find((e) => e.label === ma.label)) {
                  maArray.push({
                    label: ma.label,
                    typ_id: ma.typ_id,
                    count: 1,
                  });
                } else {
                  let tmpAction = maArray.find((e) => e.label === ma.label);
                  tmpAction.count += 1;
                }
              }
            }
          }
        }
        state.marketingActions = [...maArray];
      }
    });
    builder.addCase(searchHotelOffers.fulfilled, (state, { payload, meta }) => {
      if (payload.Offers.OfferCount > 0) {
        let infos = { ...payload };

        let maArray =
          meta.arg.offset === 0 ? [] : [...state.hotelMarketingActions];
        //console.log(maArray)
        for (let i = 0; i < infos.Offers.Offer.length; i++) {
          let off = infos.Offers.Offer[i];
          if (Array.isArray(off?.MarketingActions)) {
            for (let j = 0; j < off.MarketingActions.length; j++) {
              let ma = off.MarketingActions[j];

              if (ma.typ_id === "0018-0001" || ma.typ_id === "0018-0002") {
                //console.log("LAB: " + ma.MarketingProperties.label);
                if (
                  !maArray.find((e) => e.label === ma.MarketingProperties.label)
                ) {
                  maArray.push({
                    label: ma.MarketingProperties.label,
                    typ_id: ma.typ_id,
                    count: 1,
                  });
                } else {
                  let tmpAction = maArray.find(
                    (e) => e.label === ma.MarketingProperties.label
                  );
                  tmpAction.count += 1;
                }
              }
            }
          }
        }
        state.hotelMarketingActions = [...maArray];
      }
    });
  },
});

export const {
  setOptionOperators,
  setTouroperatorsForHotel,
  setOfferlistfilter,
  setSelectedRoomAttributes,
  setRoomAttributesFilter,
} = filterSlice.actions;

export default filterSlice.reducer;
