import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "../components/Select";
import { selectSearch, setSearchOption } from "amadeusredux/lib/searchSlice";
import { durationOptions } from "amadeusredux/lib/utils";

export default function DurationSelect() {
  const dispatch = useDispatch();
  const [lastDuration, setLastDuration] = React.useState({
    value: "7",
    label: "1 Woche",
  });
  const searchOptions = useSelector(selectSearch);
  const [choice, setChoice] = React.useState("flex");
  const holidayType = useSelector((state) => state.search.holidayType);
  const durationkind = useSelector((state) => state.search.durationkind);

  const [endView, setEndView] = React.useState(
    searchOptions?.travelDate?.to
      ? new Date(searchOptions.travelDate.to.split('"').join(""))
      : new Date()
  );

  const times = [];
  for (let i = 0; i < 30; i++) {
    times.push(i + 1);
  }

  return (
    <select
      className="form-select"
      disabled={choice === "exact"}
      onChange={(e) => {
        if (e.target.value === "0.1") {
          setLastDuration(searchOptions.duration);
          setChoice("exact");
        }

        let tmpdate = new Date(
          new Date(searchOptions.travelDate.from.split('"').join(""))
        );
        let tmpdate2 = new Date(
          tmpdate.setDate(
            tmpdate.getDate() + durationOptions[e.target.value].value
          )
        );

        dispatch(
          setSearchOption({
            property: "duration",
            data: {
              value: e.target.value,
              label: durationOptions[e.target.value].label,
            },
          })
        );
        let tmpdateUntil = new Date(
          new Date(searchOptions.travelDate.to.split('"').join(""))
        );

        if (tmpdateUntil.getTime() < tmpdate2.getTime()) {
          setEndView(tmpdate2);

          dispatch(
            setSearchOption({
              property: "travelDate",
              data: {
                from: searchOptions.travelDate.from,
                to: JSON.stringify(tmpdate2),
              },
            })
          );
        }
      }}
      value={searchOptions?.duration?.value}
    >
      {holidayType.flight && (
        <>
          <option value={0.1}>exakt wie angegeben</option>
          <option value={0.2}>beliebig</option>
          <option value={7}>1 Woche</option>
          <option value={14}>2 Wochen</option>
          <option value={21}>3 Wochen</option>
        </>
      )}

      <option value={"3-4"}>
        3-4{" "}
        {(holidayType.flight && durationkind.value === "Stay") ||
        (!holidayType.flight && durationkind.value === "OvernightStays")
          ? " Nächte"
          : " Tage"}
      </option>
      <option value={"5-7"}>
        5-7{" "}
        {(holidayType.flight && durationkind.value === "Stay") ||
        (!holidayType.flight && durationkind.value === "OvernightStays")
          ? " Nächte"
          : " Tage"}
      </option>
      <option value={"8-10"}>
        8-10{" "}
        {(holidayType.flight && durationkind.value === "Stay") ||
        (!holidayType.flight && durationkind.value === "OvernightStays")
          ? " Nächte"
          : " Tage"}
      </option>
      <option value={"11-13"}>
        11-13{" "}
        {(holidayType.flight && durationkind.value === "Stay") ||
        (!holidayType.flight && durationkind.value === "OvernightStays")
          ? " Nächte"
          : " Tage"}
      </option>
      <option value={"14-16"}>
        14-16
        {(holidayType.flight && durationkind.value === "Stay") ||
        (!holidayType.flight && durationkind.value === "OvernightStays")
          ? " Nächte"
          : " Tage"}
      </option>
      {times.map((o, i) => {
        return (
          <option key={o} value={o}>
            {!holidayType.flight
              ? o === 1
                ? "" + o + " Übernachtung"
                : "" + o + " Übernachtungen"
              : o === 1
              ? "" +
                o +
                (holidayType.flight && durationkind.value === "Stay"
                  ? " Nacht"
                  : " Tag")
              : "" +
                o +
                (holidayType.flight && durationkind.value === "Stay"
                  ? " Nächte"
                  : " Tage")}
          </option>
        );
      })}
    </select>
  );
}
