import {
  selectSystem,
  setSearchEnded,
  setView,
} from "amadeusredux/lib/systemSlice";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hotelAttributes, roomAttributes } from "../utils";
import { views } from "amadeusredux/lib/utils";
import {
  checkOfferAction,
  searchAlternativeTransports,
  searchDepartureDates,
  searchDepartureDatesOriginal,
  setHotelData,
  setHotelOffersFound,
  setHotelPage,
  setInfoHotel,
  setOfferFlexbooked,
  setOfferId,
} from "amadeusredux/lib/hotelSlice";
import { unwrapResult } from "@reduxjs/toolkit";

import { FaBed, FaCaretLeft, FaPlane, FaPlus } from "react-icons/fa";
import { FaPeopleLine } from "react-icons/fa6";
import {
  comparableOffersForOffer,
  getOtherRailOffer,
  getOtherTransferOffer,
  roomTypesForTourOperator,
  roomTypesForTourOperatorCond,
  sortOffersByFlight,
} from "../offerutils";
import {
  airportcity,
  boardTypes,
  boardTypes as utilBoardTypes,
  propertyFromArray,
  stars,
} from "../utils";
import ShareOptions from "../components/ShareOptions";
import { availabilityAndPriceCheck, search } from "amadeusredux/lib/api";
import MobileOfferView from "./MobileOfferView";
import PriceChange from "../components/PriceChange.js";
import BoardTypeTable from "../components/BoardTypeTable.js";
import RoomTypeSelect from "../components/RoomTypeSelect.js";
import FlightSelect from "../components/FlightSelect.js";
import DepartureDateSelect from "../components/DepartureDateSelect.js";

import { useCookies } from "react-cookie";
import { FormattedDate } from "react-intl";
import {
  setInbounddate,
  setOutbounddate,
  selectSearch,
  setSelectedDestination,
} from "amadeusredux/lib/searchSlice";
import AvailabilityButton from "../components/AvailabilityButton";
import { setInboundView } from "amadeusredux/lib/bookingSlice";
import { EMPTY_ELEMENT } from "amadeusredux/lib/utils";
import { setOfferPage } from "amadeusredux/lib/offersSlice.js";
import {
  setRoomAttributesFilter,
  setSelectedRoomAttributes,
} from "amadeusredux/lib/filterSlice.js";

const nf = new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2 });

function MobileCrossView() {
  const [cookies, setCookie] = useCookies(["amadeussession"]);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const minSwipeDistance = 50;
  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };
  const sessionId = useSelector((state) => state.system.sessionId);
  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);
  const [outboundFlighttime, setOutboundFlighttime] = React.useState({});
  const onTouchEnd = (offer) => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    //if (isRightSwipe) dispatch(setView(views.offerview));
    //if (isLeftSwipe) dispatch(setView(views.hotelview));
    // add your conditional logic here
  };
  if (sessionId !== EMPTY_ELEMENT)
    if (!cookies.firstTimeOncrosscheck) {
      setCookie("firstTimeOncrosscheck", "1", {
        expires: new Date(2099, 1, 1),
        path: "/",
      });
    }

  const {
    travelDate,
    operatingbrand,
    category,
    recommendationrate,
    ratingcount,
  } = useSelector(selectSearch);

  let firstTimeOncrosscheck = cookies.firstTimeOncrosscheck;
  const [selectedOutboundAirport, setSelectedOutboundAirport] = React.useState({
    value: "",
    label: "Bitte Flughafen auswählen",
  });
  const [selectedDeparture, setSelectedDeparture] = React.useState({
    value: "",
    label: "Bitte Abflugzeit auswählen",
  });

  const [selectedArrival, setSelectedArrival] = React.useState({
    value: "",
    label: "Bitte Rückflugzeit auswählen",
  });

  const df = new Intl.DateTimeFormat("de-DE", {
    weekday: "short",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  const df2 = new Intl.DateTimeFormat("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const checkids = useSelector((state) => state.hotel.checkids);
  const [flexSelected, setFlexSelected] = React.useState(false);
  const [roomTouroperators, setRoomTouroperators] = React.useState({
    value: "",
    tos: [],
  });

  const selectedRoomAttributes = useSelector(
    (state) => state.filter.selectedRoomAttributes
  );

  const selectedHotel = useSelector((state) => state.hotel.selectedHotel);
  const searchEnded = useSelector((state) => state.system.searchEnded);
  const hotelOffersFound = useSelector((state) => state.hotel.hotelOffersFound);

  const specialRoomType = useSelector((state) => state.search.specialRoomType);
  const [showalltouroperators, setshowalltouroperators] = React.useState(true);
  const [showRoomAttributes, setShowRoomAttributes] = React.useState(true);

  const [doScrollToCheapestDay, setdoScrollToCheapestDay] =
    React.useState(false);
  const roomdivRef = React.useRef(null);

  const dispatch = useDispatch();
  const scrollRef = React.useRef();
  const hotelOfferInfos = useSelector((state) => state.hotel.hotelOfferInfos);
  const departureDates = useSelector((state) => state.hotel.departureDates);
  const departureDatesOriginal = useSelector(
    (state) => state.hotel.departureDatesOriginal
  );

  let geodata = hotelOfferInfos.GeoDictionary;
  const stations = hotelOfferInfos.StationDictionary;
  const offerId = useSelector((state) => state.hotel.offerId);
  let hotels = hotelOfferInfos.HotelDictionary;
  const stateoffers = hotelOfferInfos.Offers?.Offer
    ? [...hotelOfferInfos.Offers?.Offer]
    : [];
  const { offsetTop } = scrollRef?.current ? scrollRef.current : 0;
  let offers = [...stateoffers];
  const hotelSpecialAmount = useSelector(
    (state) => state.hotel.hotelSpecialAmount
  );
  const selectedMarketingActions = useSelector(
    (state) => state.search.selectedMarketingActions
  );

  const holidayType = useSelector((state) => state.search.holidayType);
  const hotelMarketingActions = useSelector(
    (state) => state.filter.hotelMarketingActions
  );

  const reservationoperatorsonly = useSelector(
    (state) => state.search.reservationoperatorsonly
  );

  const inboundtime = useSelector((state) => state.search.inboundtime);
  const outboundtime = useSelector((state) => state.search.outboundtime);
  const optionOperators = useSelector((state) => state.filter.optionOperators);

  offers.sort((a, b) =>
    a.PriceInfo.Price.value < b.PriceInfo.Price.value ? -1 : 1
  );

  if (reservationoperatorsonly) {
    offers = offers.filter((e) => {
      return (
        Array.isArray(optionOperators) &&
        optionOperators.find(
          (o) => o.touroperator === e.TourOperator?.TourOperatorCode
        )
      );
    });
  }

  const offersRoomAttributes = {};
  const filteredAttributes = {};

  for (let i = 0; i < offers.length; i++) {
    if (
      Array.isArray(
        offers[i].OfferServices?.Package?.Accommodation?.Room?.RoomAttributes
      )
    ) {
      for (
        let j = 0;
        j <
        offers[i].OfferServices.Package.Accommodation.Room.RoomAttributes
          .length;
        j++
      ) {
        if (
          roomAttributes[
            offers[i].OfferServices.Package.Accommodation.Room.RoomAttributes[j]
          ]
        ) {
          offersRoomAttributes[
            offers[i].OfferServices.Package.Accommodation.Room.RoomAttributes[j]
          ] = {
            label:
              roomAttributes[
                offers[i].OfferServices.Package.Accommodation.Room
                  .RoomAttributes[j]
              ],
          };
          if (
            selectedRoomAttributes.includes(
              offers[i].OfferServices.Package.Accommodation.Room.RoomAttributes[
                j
              ]
            )
          ) {
            filteredAttributes[
              offers[i].OfferServices.Package.Accommodation.Room.RoomAttributes[
                j
              ]
            ] = { selected: 1 };
          }
        } else {
          console.log(
            ">>>>>>>>>>>>>>>>>>>>>>>>>>>>> unknon roomattributre",
            offers[i].OfferServices.Package.Accommodation.Room.RoomAttributes[j]
          );
        }
      }
    }
  }

  //console.log(offersRoomAttributes);

  let allma = hotelMarketingActions.map((e) => e.label).join();

  let selma = [...selectedMarketingActions];
  //console.log([...selma], allma,hotelMarketingActions);

  selma = selectedMarketingActions.filter((e) => allma.indexOf(e) > -1);
  //console.log([...selma]);
  if (selma.length > 0) {
    offers = offers.filter((e) => {
      let found = false;
      if (!Array.isArray(e.MarketingActions)) return true;
      for (let i = 0; i < e.MarketingActions.length; i++) {
        if (e.MarketingActions[i].MarketingProperties === null) continue;
        if (selma.includes(e.MarketingActions[i].MarketingProperties.label)) {
          //console.log(e.MarketingActions[i].MarketingProperties.text);
          found = true;
        }
      }
      return found;
    });
  }

  if (Object.keys(filteredAttributes).length > 0) {
    let neededAttributes = Object.keys(filteredAttributes).length;
    offers = offers.filter((e) => {
      let propsfound = 0;
      if (
        !Array.isArray(
          e.OfferServices?.Package?.Accommodation?.Room?.RoomAttributes
        )
      )
        return false;
      for (
        let i = 0;
        i <
        e.OfferServices?.Package?.Accommodation?.Room?.RoomAttributes.length;
        i++
      ) {
        if (
          filteredAttributes[
            e.OfferServices?.Package?.Accommodation?.Room?.RoomAttributes[i]
          ]
        ) {
          propsfound = propsfound + 1;
        }
      }
      return propsfound === neededAttributes;
    });
  }

  const offersRoomAttributesAfterFilter = {};

  for (let i = 0; i < offers.length; i++) {
    if (
      Array.isArray(
        offers[i].OfferServices?.Package?.Accommodation?.Room?.RoomAttributes
      )
    ) {
      for (
        let j = 0;
        j <
        offers[i].OfferServices.Package.Accommodation.Room.RoomAttributes
          .length;
        j++
      ) {
        let attribute =
          offers[i].OfferServices.Package.Accommodation.Room.RoomAttributes[j];
        if (roomAttributes[attribute]) {
          if (!offersRoomAttributesAfterFilter[attribute]) {
            offersRoomAttributesAfterFilter[attribute] = {
              count: 1,
            };
          } else {
            offersRoomAttributesAfterFilter[attribute].count =
              offersRoomAttributesAfterFilter[attribute].count + 1;
          }
        }
      }
    }
  }

  React.useEffect(() => {
    dispatch(
      setRoomAttributesFilter({
        offersRoomAttributesAfterFilter,
        offersRoomAttributes,
        filteredAttributes,
      })
    );
  }, [hotelOfferInfos, selectedRoomAttributes]);

  let rnames = hotelSpecialAmount.map((e) => e.value).join();

  let sprt = [...specialRoomType];

  sprt = sprt.filter((e) => rnames.indexOf(e) > -1);

  //console.log("###########",rnames);
  if (sprt.length > 0) {
    offers = offers.filter((e) => {
      let found = true;
      if (!Array.isArray(e.MarketingActions)) return false;
      if (e.MarketingActions.length < 1) return false;

      let roomnames = e.MarketingActions[0].RoomMouseover;

      if (!roomnames) return false;

      for (let j = 0; j < sprt.length; j++) {
        if (roomnames.indexOf(sprt[j]) === -1) {
          found = false;
        }
      }

      return found;
    });
  }

  //console.log(offers.length);

  const offer = offers.find((e) => e.OfferID === offerId)
    ? offers.find((e) => e.OfferID === offerId)
    : {};

  if (!offer.OfferID && offers.length > 0) {
    dispatch(setOfferId(offers[0].OfferID));
  }

  if (!offer.OfferID && offerId && offers.length > 0) {
    dispatch(setOfferId(offers[0].OfferID));
  }

  //console.log(offer);

  const alternativeTransports = useSelector((state) =>
    state.hotel.alternativeTransports[offer?.OfferID]
      ? state.hotel.alternativeTransports[offer.OfferID]
      : {}
  );
  //console.log(alternativeTransports);
  const [showAlternativeTransports, setShowAlternativeTransports] =
    React.useState(false);

  const [cheapestOfferInPeriod, setcheapestOfferInPeriod] = React.useState("");

  let hotel = hotels
    ? hotels.Hotel.find(
        (e) =>
          e.HotelID ===
          offer?.OfferServices?.Package?.Accommodation?.HotelRef?.HotelID
      )
    : {};

  if (!hotel) {
    hotel = {};
  }
  const { loading } = useSelector(selectSystem);

  let tourOperator = "";
  if (offer) {
    tourOperator = offer.TourOperator?.TourOperatorCode;
  }

  const getAlternativeTransports = (off, offset) => {
    if (off?.OfferServices?.Package?.Flight)
      dispatch(
        searchAlternativeTransports({
          offer: off,
          offset: offset,
          cross: true,
        })
      )
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          //console.log(originalPromiseResult);
          if (originalPromiseResult.Offers.MoreOffersAvailable) {
            getAlternativeTransports(
              off,
              offset + originalPromiseResult.Offers.OfferCount
            );
          }
        });
  };

  React.useEffect(() => {
    let tmp = document.getElementById("offerupgradeheadline");
    //console.log(tmp);

    tmp?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }, [showAlternativeTransports]);

  React.useEffect(() => {
    dispatch(searchDepartureDates());
    dispatch(searchDepartureDatesOriginal());
  }, []);

  React.useEffect(() => {
    if (selectedArrival.value != "")
      setSelectedDeparture({
        value: "",
        label: "Bitte Abflugzeit auswählen",
      });
  }, [selectedArrival]);

  React.useEffect(() => {
    if (selectedDeparture.value != "")
      setSelectedArrival({
        value: "",
        label: "Bitte Rückflugzeit auswählen",
      });
  }, [selectedDeparture]);

  React.useEffect(() => {
    setSelectedDeparture({ value: "", label: "Bitte Abflugzeit auswählen" });
    setSelectedArrival({ value: "", label: "Bitte Rückflugzeit auswählen" });
  }, [selectedOutboundAirport]);

  React.useEffect(() => {
    getAlternativeTransports(offer, 0);
    setSelectedOutboundAirport({
      value: "",
      label: "Bitte Flughafen auswählen",
    });
    setSelectedDeparture({ value: "", label: "Bitte Abflugzeit auswählen" });
    setSelectedArrival({ value: "", label: "Bitte Rückflugzeit auswählen" });
  }, [offer.OfferID, inboundtime, outboundtime]);

  React.useEffect(() => {
    if (offer?.bookinginfo?.Status?.Status === "Available" && offerId) {
      if (
        !(
          Array.isArray(offer?.MarketingActions) &&
          offer.MarketingActions.length > 0 &&
          offer.MarketingActions[0].MarketingProperties.offeraction === true
        )
      )
        dispatch(checkOfferAction({ offerId: offerId, offer: offer }));
    }
  }, [offer, offer?.bookinginfo, offerId]);

  React.useEffect(() => {
    if (
      offer.OfferID &&
      !offer?.bookinginfo &&
      Array.isArray(checkids) &&
      !checkids.includes(offer.OfferID)
    ) {
      availabilityAndPriceCheck(
        dispatch,
        sessionId,
        offer.OfferID,
        offer.adults,
        offer.children,
        undefined
      );
      //setredrawVoucher(true);
    }
  }, [checkids, offer]);

  //const boardTypes = boardTypesForTourOperator(tourOperator, offers);
  //const [redrawVoucher, setredrawVoucher] = React.useState(false);
  const [showalldeparturedates, setShowAllDeparturedates] =
    React.useState(false);
  const [showallRooms, setShowAllRooms] = React.useState(false);
  const [showallflights, setShowAllFlights] = React.useState(false);

  const comparableOffers = comparableOffersForOffer(offer, offers);
  //console.log(offer);
  if (offer.OfferServices?.Package?.Flight)
    sortOffersByFlight(comparableOffers);

  const allBoards = [];
  if (Array.isArray(offers)) {
    for (let i = 0; i < offers.length; i++) {
      let offer = offers[i];
      if (
        allBoards.find(
          (e) =>
            e.value === offer.OfferServices?.Package?.Accommodation?.BoardType
        )
      ) {
        continue;
      }
      allBoards.push({
        label: propertyFromArray(
          boardTypes,
          offer.OfferServices?.Package?.Accommodation?.BoardType,
          "value",
          "short"
        ),
        value: offer.OfferServices?.Package?.Accommodation?.BoardType,
        order: utilBoardTypes.find(
          (e) => offer.OfferServices.Package.Accommodation.BoardType === e.value
        ).order,
      });
    }
  }

  allBoards.sort((a, b) => a.order - b.order);

  const allRoomNames = [];
  if (Array.isArray(offers)) {
    for (let i = 0; i < offers.length; i++) {
      let offer = offers[i];
      let rn = offer.OfferServices?.Package?.Accommodation?.Room?.SLBRoomName;
      if (!rn) rn = offer.OfferServices?.Package?.Accommodation?.Room?.RoomName;
      if (!rn) continue;

      let ar = allRoomNames.find((e) => e.value === rn);
      if (ar) {
        if (!ar.tos.includes(offer.TourOperator.TourOperatorCode)) {
          ar.tos.push(offer.TourOperator.TourOperatorCode);
        }
      } else {
        allRoomNames.push({
          label: rn,
          value: rn,
          tos: [offer.TourOperator.TourOperatorCode],
          offer: offer,
        });
      }
    }
  }

  allRoomNames.sort((a, b) => a.label.localeCompare(b.label));

  const tourOperators = [];
  if (Array.isArray(offers)) {
    for (let i = 0; i < offers.length; i++) {
      let offer = offers[i];

      let to = tourOperators.find(
        (e) => e.label === offer.TourOperator.TourOperatorName
      );

      if (to) {
        let offerrealprice = 0;
        let cacheprice = offer.PriceInfo?.Price.value * 1;
        let checked = 0;
        if (offer?.bookinginfo) {
          checked = -1;
        }
        if (offer?.bookinginfo?.PriceInformation?.TotalPrice?.Amount?._) {
          checked = 1;
          offerrealprice =
            (offer?.bookinginfo?.PriceInformation?.TotalPrice?.Amount?._ * 1) /
            (offer.adults * 1 + offer.children * 1);
        } else {
          offerrealprice = offer.PriceInfo?.Price.value * 1;
        }

        to.offers.push({
          offerid: offer.OfferID,
          price: offerrealprice,
          cacheprice: cacheprice,
          checked: checked,
          boardtype: offer.OfferServices.Package.Accommodation.BoardType,
          value: offer.TourOperator.TourOperatorCode,
        });
      } else {
        let offerrealprice = 0;
        let cacheprice = offer.PriceInfo?.Price.value * 1;
        let checked = 0;
        if (offer?.bookinginfo) {
          checked = -1;
        }
        if (offer?.bookinginfo?.PriceInformation?.TotalPrice?.Amount?._) {
          checked = 1;
          offerrealprice =
            (offer?.bookinginfo?.PriceInformation?.TotalPrice?.Amount?._ * 1) /
            (offer.adults * 1 + offer.children * 1);
        } else {
          offerrealprice = offer.PriceInfo?.Price.value * 1;
        }

        let mp = {};
        if (
          Array.isArray(offer.MarketingActions) &&
          offer.MarketingActions.length > 0
        ) {
          mp = offer.MarketingActions.find(
            (e) => e.MarketingProperties?.typ === "0022-0002"
          );
          if (mp) mp = mp.MarketingProperties;
        }
        if (!mp) mp = {};

        tourOperators.push({
          offers: [
            {
              offerid: offer.OfferID,
              price: offerrealprice,
              cacheprice: cacheprice,
              checked: checked,
              value: offer.TourOperator.TourOperatorCode,
              boardtype: offer.OfferServices.Package.Accommodation.BoardType,
            },
          ],
          image: offer.TourOperator.TourOperatorImage,
          label: offer.TourOperator.TourOperatorName,
          code: offer.TourOperator.TourOperatorCode,

          flexoption:
            Array.isArray(offer.MarketingActions) &&
            offer.MarketingActions.length > 0
              ? offer.MarketingActions[0].flexoption
              : "",

          flexoptiontext:
            Array.isArray(offer.MarketingActions) &&
            offer.MarketingActions.length > 0
              ? offer.MarketingActions[0].flexoptiontext
              : "",

          reservation:
            Array.isArray(offer.MarketingActions) &&
            offer.MarketingActions.length > 0
              ? offer.MarketingActions[0].reservation
              : "",
          reservationtext:
            Array.isArray(offer.MarketingActions) &&
            offer.MarketingActions.length > 0
              ? offer.MarketingActions[0].reservationtext
              : "",
          touroperatoraction:
            Array.isArray(offer.MarketingActions) &&
            offer.MarketingActions.length > 0
              ? offer.MarketingActions[0].touroperatoraction
              : "",
          marketingproperties:
            Array.isArray(offer.MarketingActions) &&
            offer.MarketingActions.length > 0
              ? mp
              : {},
        });
      }
    }
  }

  let transferincl = false;
  if (offer?.OfferProperties?.TransferInfos) {
    if (
      Array.isArray(offer.OfferProperties.TransferInfos) &&
      offer.OfferProperties.TransferInfos.includes("TransferIncluded")
    ) {
      transferincl = true;
    }
  }
  let railandfly = false;
  if (offer?.OfferProperties?.OfferIncludes) {
    if (
      Array.isArray(offer.OfferProperties.OfferIncludes) &&
      offer.OfferProperties.OfferIncludes.includes("RailAndFly")
    ) {
      railandfly = true;
    }
  }
  let cancellationoption = "";
  if (offer?.OfferProperties?.CancellationOption) {
    cancellationoption = offer?.OfferProperties?.CancellationOption;
  }

  let otherTransferOffer = getOtherTransferOffer(
    offers,
    offer,
    transferincl,
    railandfly,
    cancellationoption
  );

  let otherRailOffer = getOtherRailOffer(
    offers,
    offer,
    transferincl,
    railandfly,
    cancellationoption
  );

  const roomTypes = roomTypesForTourOperator(tourOperator, offers);
  //console.log(JSON.stringify(roomTypes.map(e=>e.label)));

  let otherRoomOffers = [];

  for (let i = 0; i < offers.length; i++) {
    if (offer.OfferID === offers[i].OfferID) continue;
    let trans =
      offers[i].OfferProperties?.TransferInfos?.includes("TransferIncluded");
    let rf = offers[i].OfferProperties?.OfferIncludes?.includes("RailAndFly");
    rf = rf ? true : false;
    let co = offers[i]?.OfferProperties?.CancellationOption;
    co = co ? co : "";
    if (
      offers[i].TourOperator?.TourOperatorCode ===
        offer.TourOperator?.TourOperatorCode &&
      offers[i].OfferServices.Package.Flight?.OutboundFlight.FlightDeparture
        .DepartureAirportRef.AirportCode ===
        offer.OfferServices.Package.Flight?.OutboundFlight.FlightDeparture
          .DepartureAirportRef.AirportCode &&
      offers[i].OfferServices.Package.Accommodation.Room.RoomName !==
        offer.OfferServices.Package.Accommodation.Room.RoomName &&
      offers[i].OfferServices.Package.Accommodation.BoardType ===
        offer.OfferServices.Package.Accommodation.BoardType &&
      rf === railandfly &&
      trans === transferincl &&
      cancellationoption === co
    ) {
      if (
        !otherRoomOffers.find(
          (e) =>
            e.label ===
            offers[i].OfferServices.Package.Accommodation.Room.RoomName
        )
      )
        otherRoomOffers.push({
          label: offers[i].OfferServices.Package.Accommodation.Room.RoomName,
          offer: offers[i],
        });
    }
  }

  let otherViewOffers = [];

  for (let i = 0; i < offers.length; i++) {
    if (offer.OfferID === offers[i].OfferID) continue;
    let trans =
      offers[i].OfferProperties?.TransferInfos?.includes("TransferIncluded");
    let rf = offers[i].OfferProperties?.OfferIncludes?.includes("RailAndFly");
    rf = rf ? true : false;
    let co = offers[i]?.OfferProperties?.CancellationOption;
    co = co ? co : "";
    if (
      offers[i].TourOperator?.TourOperatorCode ===
        offer.TourOperator?.TourOperatorCode &&
      offers[i].OfferServices.Package?.Flight?.OutboundFlight.FlightDeparture
        .DepartureAirportRef.AirportCode ===
        offer.OfferServices.Package?.Flight?.OutboundFlight.FlightDeparture
          .DepartureAirportRef.AirportCode &&
      offers[i].OfferServices.Package.Accommodation.Room.RoomName ===
        offer.OfferServices.Package.Accommodation.Room.RoomName &&
      offers[i].OfferServices.Package.Accommodation.Room.RoomView !==
        offer.OfferServices.Package.Accommodation.Room.RoomView &&
      offers[i].OfferServices.Package.Accommodation.BoardType ===
        offer.OfferServices.Package.Accommodation.BoardType &&
      rf === railandfly &&
      trans === transferincl &&
      cancellationoption === co
    ) {
      if (
        !otherViewOffers.find(
          (e) =>
            e.label ===
            offers[i].OfferServices.Package.Accommodation.Room.RoomView
        )
      )
        otherViewOffers.push({
          label: offers[i].OfferServices.Package.Accommodation.Room.RoomView,
          offer: offers[i],
        });
    }
  }

  let otherBoardOffers = [];

  for (let i = 0; i < offers.length; i++) {
    if (offer.OfferID === offers[i].OfferID) continue;
    let trans =
      offers[i].OfferProperties?.TransferInfos?.includes("TransferIncluded");
    let rf = offers[i].OfferProperties?.OfferIncludes?.includes("RailAndFly");
    rf = rf ? true : false;
    let co = offers[i]?.OfferProperties?.CancellationOption;
    co = co ? co : "";
    if (
      offers[i].TourOperator?.TourOperatorCode ===
        offer.TourOperator?.TourOperatorCode &&
      offers[i].OfferServices.Package.Flight?.OutboundFlight.FlightDeparture
        .DepartureAirportRef.AirportCode ===
        offer.OfferServices.Package.Flight?.OutboundFlight.FlightDeparture
          .DepartureAirportRef.AirportCode &&
      offers[i].OfferServices.Package.Accommodation.Room.RoomName ===
        offer.OfferServices.Package.Accommodation.Room.RoomName &&
      offers[i].OfferServices.Package.Accommodation.BoardType !==
        offer.OfferServices.Package.Accommodation.BoardType &&
      rf === railandfly &&
      trans === transferincl &&
      cancellationoption === co
    ) {
      if (
        !otherBoardOffers.find(
          (e) =>
            e.label ===
            propertyFromArray(
              boardTypes,
              offers[i].OfferServices.Package.Accommodation.BoardType,
              "value",
              "short"
            )
        )
      )
        otherBoardOffers.push({
          label: propertyFromArray(
            boardTypes,
            offers[i].OfferServices.Package.Accommodation.BoardType,
            "value",
            "short"
          ),
          offer: offers[i],
        });
    }
  }

  let otherCancelOffers = [];

  for (let i = 0; i < offers.length; i++) {
    if (offer.OfferID === offers[i].OfferID) continue;
    let trans =
      offers[i].OfferProperties?.TransferInfos?.includes("TransferIncluded");
    let rf = offers[i].OfferProperties?.OfferIncludes?.includes("RailAndFly");
    rf = rf ? true : false;
    let co = offers[i]?.OfferProperties?.CancellationOption;
    co = co ? co : "";

    if (
      offers[i].TourOperator?.TourOperatorCode ===
        offer.TourOperator?.TourOperatorCode &&
      offers[i].OfferServices.Package.Flight?.OutboundFlight.FlightDeparture
        .DepartureAirportRef.AirportCode ===
        offer.OfferServices.Package.Flight?.OutboundFlight.FlightDeparture
          .DepartureAirportRef.AirportCode &&
      offers[i].OfferServices.Package.Accommodation.Room.RoomName ===
        offer.OfferServices.Package.Accommodation.Room.RoomName &&
      offers[i].OfferServices.Package.Accommodation.BoardType !==
        offer.OfferServices.Package.Accommodation.BoardType &&
      rf === railandfly &&
      trans === transferincl &&
      cancellationoption !== co
    ) {
      if (!otherCancelOffers.find((e) => e.label === co))
        otherCancelOffers.push({
          label: co,
          offer: offers[i],
        });
    }
  }

  //console.log(
  //  offer?.OfferServices?.Package?.Flight?.OutboundFlight?.FlightDeparture
  //);

  let outboundStations = [];
  let inboundStations = [];
  let cheapestOfferId = "";
  const airports = stations?.Station;

  if (Array.isArray(alternativeTransports?.Offers?.Offer)) {
    for (let i = 0; i < alternativeTransports.Offers.Offer.length; i++) {
      if (
        alternativeTransports?.Offers?.Offer[i].OfferServices?.Package?.Flight
          ?.OutboundFlight?.FlightDeparture?.DepartureAirportRef?.AirportCode &&
        alternativeTransports?.Offers?.Offer[i].OfferServices?.Package?.Flight
          ?.OutboundFlight?.FlightDeparture?.DepartureDate &&
        alternativeTransports?.Offers?.Offer[i].OfferServices?.Package?.Flight
          ?.OutboundFlight?.FlightDeparture?.DepartureTime
      ) {
        let outboundairport =
          alternativeTransports?.Offers?.Offer[i].OfferServices.Package.Flight
            .OutboundFlight.FlightDeparture.DepartureAirportRef.AirportCode;
        let date =
          alternativeTransports?.Offers?.Offer[i].OfferServices.Package.Flight
            .OutboundFlight.FlightDeparture.DepartureDate;
        let time =
          alternativeTransports?.Offers?.Offer[i].OfferServices.Package.Flight
            .OutboundFlight.FlightDeparture.DepartureTime;
        let arrivaldate =
          alternativeTransports?.Offers?.Offer[i].OfferServices.Package.Flight
            .OutboundFlight.FlightArrival.ArrivalDate;
        let arrivaltime =
          alternativeTransports?.Offers?.Offer[i].OfferServices.Package.Flight
            .OutboundFlight.FlightArrival.ArrivalTime;
        let row = outboundStations.find(
          (e) =>
            e.value ===
            outboundairport + time + date + arrivaltime + arrivaldate
        );
        let outboundFlight =
          alternativeTransports?.Offers?.Offer[i].OfferServices?.Package?.Flight
            ?.OutboundFlight;

        let outboundairline = "";
        if (outboundFlight?.Airline?.AirlineName) {
          outboundairline = outboundFlight.Airline.AirlineName;
        }

        if (!row) {
          outboundStations.push({
            value: outboundairport + time + date + arrivaltime + arrivaldate,
            label:
              Array.isArray(airports) &&
              airports.find((e) => e.StationCode === outboundairport) !==
                undefined
                ? airports.find((e) => e.StationCode === outboundairport).Name
                : outboundairport,
            times: [
              {
                value:
                  outboundairport + time + date + arrivaltime + arrivaldate,
                airline: outboundairline ? " " + outboundairline : "n.A.",
                departure: df2.format(new Date(date)) + " " + time,
                label:
                  df2.format(new Date(date)) +
                  " " +
                  time +
                  (outboundairline ? " " + outboundairline : ""),
                outboundairport: outboundairport,
                price: alternativeTransports?.Offers?.Offer[i].bookinginfo
                  ?.PriceInformation?.TotalPrice?.Amount?._
                  ? alternativeTransports?.Offers?.Offer[i].bookinginfo
                      ?.PriceInformation?.TotalPrice?.Amount?._ * 1
                  : alternativeTransports?.Offers?.Offer[i].PriceInfo.Price
                      .value *
                    (offer.adults * 1 + offer.children * 1),
                time: time,
                date: date,
                arrivaltime: arrivaltime,
                arrivaldate: arrivaldate,
              },
            ],
          });
        } else {
          let rt = row.times.find(
            (e) =>
              e.outboundairport === outboundairport &&
              e.time === time &&
              e.date === date &&
              e.arrivaltime === arrivaltime &&
              e.arrivaldate === arrivaldate
          );
          if (!rt) {
            row.times.push({
              value: outboundairport + time + date + arrivaltime + arrivaldate,
              airline: outboundairline ? " " + outboundairline : "n.A.",
              departure: df2.format(new Date(date)) + " " + time,
              label:
                df2.format(new Date(date)) +
                " " +
                time +
                (outboundairline ? " " + outboundairline : ""),
              outboundairport: outboundairport,
              price:
                alternativeTransports?.Offers?.Offer[i].PriceInfo.Price.value *
                (offer.adults * 1 + offer.children * 1),
              time: time,
              date: date,
              arrivaltime: arrivaltime,
              arrivaldate: arrivaldate,
            });
          }
        }
      }

      if (
        alternativeTransports?.Offers?.Offer[i].OfferServices?.Package?.Flight
          ?.OutboundFlight?.FlightDeparture?.DepartureAirportRef?.AirportCode &&
        alternativeTransports?.Offers?.Offer[i].OfferServices?.Package?.Flight
          ?.InboundFlight?.FlightDeparture?.DepartureDate &&
        alternativeTransports?.Offers?.Offer[i].OfferServices?.Package?.Flight
          ?.InboundFlight?.FlightDeparture?.DepartureTime
      ) {
        let inboundairport =
          alternativeTransports?.Offers?.Offer[i].OfferServices.Package.Flight
            .OutboundFlight.FlightDeparture.DepartureAirportRef.AirportCode;
        let date =
          alternativeTransports?.Offers?.Offer[i].OfferServices.Package.Flight
            .InboundFlight.FlightDeparture.DepartureDate;
        let time =
          alternativeTransports?.Offers?.Offer[i].OfferServices.Package.Flight
            .InboundFlight.FlightDeparture.DepartureTime;
        let row = inboundStations.find((e) => e.value === inboundairport);
        let inboundFlight =
          alternativeTransports?.Offers?.Offer[i].OfferServices?.Package?.Flight
            ?.InboundFlight;
        let inboundairline = "";

        if (inboundFlight?.Airline?.AirlineName) {
          inboundairline = inboundFlight.Airline.AirlineName;
        }
        if (!row) {
          inboundStations.push({
            value: inboundairport,
            label:
              Array.isArray(airports) &&
              airports.find((e) => e.StationCode === inboundairport) !==
                undefined
                ? airports.find((e) => e.StationCode === inboundairport).Name
                : inboundairport,
            times: [
              {
                airline: inboundairline ? " " + inboundairline : "",
                departure: df2.format(new Date(date)) + " " + time,
                value: inboundairport + time + date,
                label:
                  df2.format(new Date(date)) +
                  " " +
                  time +
                  (inboundairline ? " " + inboundairline : ""),
                inboundairport: inboundairport,
                time: time,
                date: date,
              },
            ],
          });
        } else {
          let rt = row.times.find((e) => e.time === time && e.date === date);
          if (!rt) {
            row.times.push({
              airline: inboundairline ? " " + inboundairline : "",
              departure: df2.format(new Date(date)) + " " + time,
              value: inboundairport + time + date,
              label:
                df2.format(new Date(date)) +
                " " +
                time +
                (inboundairline ? " " + inboundairline : ""),
              inboundairport: inboundairport,
              time: time,
              date: date,
            });
          }
        }
      }
    }
  }

  for (let i = 0; i < outboundStations.length; i++) {
    outboundStations[i].times = outboundStations[i].times
      .sort((a, b) => a.date.localeCompare(b.date))
      .sort((a, b) => a.time.localeCompare(b.time));
  }

  for (let i = 0; i < inboundStations.length; i++) {
    inboundStations[i].times = inboundStations[i].times
      .sort((a, b) => a.date.localeCompare(b.date))
      .sort((a, b) => a.time.localeCompare(b.time));
  }

  let roomnamesoverview = false;

  if (
    Array.isArray(offers) &&
    offers.length > 0 &&
    Array.isArray(offers[0].MarketingActions) &&
    offers[0].MarketingActions.length > 0 &&
    offers[0].MarketingActions[0].roomnamesoverview
  ) {
    roomnamesoverview = offers[0].MarketingActions[0].roomnamesoverview;
  }

  if (!Array.isArray(allBoards)) allBoards = [];
  if (!Array.isArray(tourOperators)) tourOperators = [];

  let cheapestprice = 99999999999;
  let travellerfactor = offer.adults * 1 + offer.children * 1;

  for (let i = 0; i < tourOperators.length; i++) {
    for (let j = 0; j < allBoards.length; j++) {
      let bt = allBoards[j];
      let cheapest = tourOperators[i].offers.find(
        (e) => e.boardtype === bt.value
      );

      if (cheapest) {
        if (
          cheapestprice > cheapest.price * travellerfactor &&
          cheapest.checked !== -1
        ) {
          cheapestprice = cheapest.price * travellerfactor;
        }
      }
    }
  }
  for (let i = 0; i < tourOperators.length; i++) {
    for (let j = 0; j < allBoards.length; j++) {
      let bt = allBoards[j];
      let cheapest = tourOperators[i].offers.find(
        (e) => e.boardtype === bt.value
      );

      if (cheapest) {
        if (
          cheapestprice === cheapest.price * travellerfactor &&
          cheapest.checked !== -1
        ) {
          tourOperators[i].isCheapest = true;
        }
      }
    }
  }
  let cheapest = {};
  for (let i = 0; i < tourOperators.length; i++) {
    for (let j = 0; j < allBoards.length; j++) {
      let bt = allBoards[j];
      cheapest = tourOperators[i].offers.find((e) => e.boardtype === bt.value);

      if (cheapest) {
        let off = offers.find((e) => e.OfferID === cheapest.offerid);
        if (
          off &&
          !off?.bookinginfo &&
          Array.isArray(checkids) &&
          !checkids.includes(off.OfferID)
        )
          availabilityAndPriceCheck(
            dispatch,
            sessionId,
            off.OfferID,
            off.adults,
            off.children,
            undefined
          );
      }
    }
  }

  let cheapestOriginalInPeriod = 999999;
  let expensiestOriginalInPeriod = 0;
  let vcheapestOriginalOfferInPeriod;
  if (Array.isArray(departureDatesOriginal?.Offers?.Offer)) {
    for (let i = 0; i < departureDatesOriginal?.Offers?.Offer.length; i++) {
      let depd = departureDatesOriginal?.Offers?.Offer[i];
      if (depd.PriceInfo.Price.value * 1 < cheapestOriginalInPeriod)
        cheapestOriginalInPeriod = depd.PriceInfo.Price.value * 1;
      vcheapestOriginalOfferInPeriod =
        departureDatesOriginal?.Offers?.Offer[i].OfferID;
      if (depd.PriceInfo.Price.value * 1 > expensiestOriginalInPeriod)
        expensiestOriginalInPeriod = depd.PriceInfo.Price.value * 1;
    }
  }

  if (cheapestOfferInPeriod !== vcheapestOriginalOfferInPeriod)
    setcheapestOfferInPeriod(vcheapestOriginalOfferInPeriod);

  let cheapestInPeriod = 999999;
  let expensiestInPeriod = 0;
  let vcheapestOfferInPeriod;
  if (Array.isArray(departureDates?.Offers?.Offer)) {
    for (let i = 0; i < departureDates?.Offers?.Offer.length; i++) {
      let depd = departureDates?.Offers?.Offer[i];
      if (depd.PriceInfo.Price.value * 1 < cheapestInPeriod)
        cheapestInPeriod = depd.PriceInfo.Price.value * 1;
      vcheapestOfferInPeriod = departureDates?.Offers?.Offer[i].OfferID;
      if (depd.PriceInfo.Price.value * 1 > expensiestInPeriod)
        expensiestInPeriod = depd.PriceInfo.Price.value * 1;
    }
  }

  const scrollToCheapestDay = (id) => {
    //console.log("date_" + id);
    let tmp = document.getElementById("date_" + id);
    //console.log(tmp);

    tmp?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  /*
  React.useEffect(() => {
    scrollToCheapestDay(cheapestOfferInPeriod);
  }, [cheapestOfferInPeriod]);
*/

  //const allroomTypes = roomTypesForTourOperator(tourOperator, offers);

  const allroomTypes = roomTypesForTourOperatorCond(
    tourOperator,
    offers,
    offer
  );

  let allRoomOffers = [...allroomTypes].sort(
    (a, b) =>
      a.offer?.PriceInfo?.Price.value * 1 > b.offer?.PriceInfo?.Price.value * 1
  );

  for (let i = 0; i < allRoomOffers.length; i++) {
    let off = allRoomOffers[i].offer;
    if (
      off &&
      !off?.bookinginfo &&
      Array.isArray(checkids) &&
      !checkids.includes(off.OfferID)
    )
      availabilityAndPriceCheck(
        dispatch,
        sessionId,
        off.OfferID,
        off.adults,
        off.children,
        undefined
      );
  }

  let offerstatus = 0;

  if (offer?.bookinginfo) {
    if (
      offer?.bookinginfo?.Status?.Status === "Available" ||
      offer?.bookinginfo?.Status?.Status === "ToConfirm" ||
      offer?.bookinginfo?.Status?.Status === "OnRequest"
    ) {
      offerstatus = 1;
    } else {
      offerstatus = -1;
    }
  }

  let reason = "";
  reason = offer?.bookinginfo?.Status?.StatusNo;

  if (doScrollToCheapestDay) {
    setTimeout(() => {
      scrollToCheapestDay(cheapestOfferInPeriod);
      setdoScrollToCheapestDay(false);
    }, 100);
  }

  const openSubSelect = (currentselect) => {
    if (currentselect == "touroperators") {
      setShowAllRooms(true);
      setshowalltouroperators(false);
    }
    if (currentselect == "rooms") {
      setShowAllFlights(true);
      setShowAllRooms(false);
    }
  };

  let nooffers = true;
  if (Array.isArray(offers)) {
    if (offers.length > 0) nooffers = false;
  }

  if (selectedHotel && searchEnded && !hotelOffersFound && nooffers) {
    return (
      <>
        Es wurden keine Angebote im gewählten Hotel gefunden.
        {selectedHotel.HotelLocationRef && (
          <>
            &nbsp;Wollen Sie andere Hotels in{" "}
            {selectedHotel.cityName
              ? selectedHotel.cityName
              : "der gleichen Stadt"}{" "}
            <span
              style={{ textDecoration: "underline", color: "blue" }}
              onClick={() => {
                dispatch(
                  setSelectedDestination([
                    {
                      CityID: selectedHotel.HotelLocationRef.CityID,
                      value: selectedHotel.cityName
                        ? selectedHotel.cityName
                        : "City",
                      label: selectedHotel.cityName
                        ? selectedHotel.cityName
                        : "City",
                      Name: selectedHotel.cityName
                        ? selectedHotel.cityName
                        : "City",
                    },
                  ])
                );
                dispatch(setSearchEnded(false));

                dispatch(setHotelOffersFound(true));

                dispatch(setOutbounddate(""));
                dispatch(setInbounddate(""));
                dispatch(setOfferPage(1));
                search(dispatch, false, false);
                dispatch(setView(views.offerview));

                // dispatch(setOutbounddate(""));
                // search(dispatch, false, false);
                // dispatch(setView(views.offerview));
              }}
            >
              SUCHEN
            </span>
            ?
          </>
        )}
      </>
    );
  }

  return (
    <>
      <div
        style={{
          position: "fixed",
          zIndex: 1000,
          bottom: 0,
          left: 0,
          height: 80,
          width: "100%",
        }}
      >
        <div
          style={{
            backgroundColor: "#ffffff",
            height: 80,
          }}
        >
          {offerstatus == 0 && (
            <div
              style={{ height: "100%", width: "100%" }}
              className="border border-warning rounded small bg-warning bg-opacity-10 text-center mt-3"
            >
              <span className="fw-semibold fs-6 text-center ">
                Flug/Angebot wird geprüft.
              </span>
            </div>
          )}
          {offerstatus == -1 && (
            <>
              <div
                style={{ height: "100%", width: "100%" }}
                className="border border-danger rounded small bg-danger bg-opacity-10 text-center mt-3"
              >
                <span className="fw-semibold fs-6 text-center ">
                  {!reason && <>Flug/Angebot leider nicht verfügbar.</>}
                  {reason &&
                    (reason.startsWith("41") ? (
                      <>Flug leider nicht verfügbar.</>
                    ) : reason.startsWith("42") ? (
                      <>Zimmer leider nicht verfügbar.</>
                    ) : (
                      <>Flug/Angebot leider nicht verfügbar.</>
                    ))}
                </span>
              </div>
            </>
          )}
          {(offer.PriceInfo?.Price.value > 0 ||
            offer?.bookinginfo?.PriceInformation?.TotalPrice?.Amount?._ * 1 >
              0) &&
            offerstatus == 1 && (
              <>
                <p
                  style={{ height: "100%", width: "100%" }}
                  className="border border-secondary rounded text   bg-success bg-opacity-10 "
                >
                  <div style={{ width: "100%" }} className="text-center">
                    <span className="fw-semibold fs-6 text-center">
                      mein Preis &nbsp;
                      {nf.format(
                        offer?.bookinginfo?.PriceInformation?.TotalPrice?.Amount
                          ?._
                          ? offer?.bookinginfo?.PriceInformation?.TotalPrice
                              ?.Amount?._ * 1
                          : offer.PriceInfo?.Price.value *
                              (offer.adults * 1 + offer.children * 1)
                      )}
                      &nbsp;
                      {offer.PriceInfo.Price.CurrencyCode}
                    </span>
                    <span className=" small text-center">
                      &nbsp;(
                      {nf.format(
                        offer?.bookinginfo?.PriceInformation?.TotalPrice?.Amount
                          ?._
                          ? Math.floor(
                              (offer?.bookinginfo?.PriceInformation?.TotalPrice
                                ?.Amount?._ *
                                1) /
                                (offer.children * 1 + offer.adults * 1)
                            )
                          : offer.PriceInfo?.Price.value * 1
                      )}
                      &nbsp;
                      {offer.PriceInfo.Price.CurrencyCode} p.P.)
                    </span>
                  </div>
                  <div
                    style={{ width: "300px", margin: "auto" }}
                    className="text-center fw-semibold"
                  >
                    <AvailabilityButton
                      isVoucher={true}
                      offer={offer}
                      hotel={hotel}
                    />
                  </div>
                </p>
              </>
            )}
        </div>
      </div>
      <div
        onTouchStart={(e) => onTouchStart(e)}
        onTouchMove={(e) => onTouchMove(e)}
        onTouchEnd={() => onTouchEnd(offer)}
        className="card mb-2"
        style={{ borderRadius: 10 }}
      >
        {Array.isArray(offers) &&
          offers.length > 0 &&
          firstTimeOncrosscheck === "1" && (
            <span className="mb-0 text-left small bg-danger bg-opacity-10 ">
              Erstes mal hier? eine Übersicht der günstigsten Preise pro
              Veranstalter und Verpflegungsart. Einfach auf den Preis tippen und
              Ihr Voucher ändert sich.
            </span>
          )}

        <div className="card-title overflow-hidden mb-0 text ms-2">
          <div
            onClick={() => {
              dispatch(setInboundView(views.hotelcrossview));
              dispatch(setInfoHotel(hotel));
              dispatch(
                setHotelData({
                  ...hotelOfferInfos,
                  ...{ Offers: { Offer: { ...offer } } },
                })
              );
              dispatch(setView(views.hotelview));
            }}
            className="mb-0 text "
            title={hotel.Name}
          >
            <p className="text-body fw-semibold mb-0 fs-4">{hotel.Name}</p>

            <p className="text-warning mb-0 fs-4" style={{ marginTop: -10 }}>
              {stars(
                offer?.OfferServices?.Package.Accommodation.HotelOfferCategory *
                  1
              )}
            </p>

            <p className="text-body mb-0" style={{ marginTop: 0 }}>
              in&nbsp;
              {hotel &&
                hotel.HotelLocationRef &&
                Array.isArray(geodata?.Cities?.City) &&
                geodata.Cities.City.find(
                  (c) => c.CityID === hotel.HotelLocationRef.CityID
                ) !== undefined &&
                geodata.Cities.City.find(
                  (c) => c.CityID === hotel.HotelLocationRef.CityID
                ).Name}{" "}
            </p>
            <p className="text-info mb-0 fs-6" style={{ marginTop: -5 }}>
              mehr erfahren...
            </p>
          </div>
        </div>
        <div className="card-body">
          <div className="col-12 text-body mt-1" style={{ marginTop: 10 }}>
            <div
              style={{ display: "flex" }}
              onClick={() => {
                setShowAllDeparturedates(!showalldeparturedates);
                setdoScrollToCheapestDay(true);
              }}
            >
              <i class="bi bi-calendar-date"></i>&nbsp;
              <div className="companybutton  mb-2" style={{ width: 270 }}>
                Wählen Sie aus&nbsp;
                {Array.isArray(departureDates?.Offers?.Offer)
                  ? departureDates?.Offers?.Offer.length
                  : ""}
                &nbsp;Reisetagen{" "}
              </div>
            </div>
            {showalldeparturedates && (
              <>
                <DepartureDateSelect
                  offer={offer}
                  departureDates={departureDates}
                  departureDatesOriginal={departureDatesOriginal}
                  expensiestOriginalInPeriod={expensiestOriginalInPeriod}
                  cheapestInPeriod={cheapestInPeriod}
                  cheapestOriginalInPeriod={cheapestOriginalInPeriod}
                  expensiestInPeriod={expensiestInPeriod}
                ></DepartureDateSelect>
              </>
            )}
          </div>

          <div className="col-12 text-secondary  ">
            Wählen Sie schnell und einfach aus {offers?.length} Angeboten für
            den&nbsp;
            <FormattedDate
              value={
                holidayType.flight
                  ? offer.OfferServices?.Package?.Flight?.OutboundFlight
                      ?.FlightDeparture?.DepartureDate
                  : offer.TravelDateInfo?.CheckInDate
              }
              month="2-digit"
              day="2-digit"
            />
          </div>

          <div className="text-body mt-1 mb-2">
            <div
              className="text-body mt-1"
              style={{ display: "flex" }}
              onClick={() => setshowalltouroperators(!showalltouroperators)}
            >
              <FaPeopleLine></FaPeopleLine>&nbsp;
              <div style={{ width: 270 }} className="companybutton ">
                Wählen Sie aus {tourOperators.length} Veranstaltern
              </div>
            </div>
          </div>
          {showalltouroperators && (
            <BoardTypeTable
              offer={offer}
              openSubSelect={openSubSelect}
              tourOperators={tourOperators}
              allBoards={allBoards}
              travellerfactor={travellerfactor}
              showalltouroperators={showalltouroperators}
              setFlexSelected={setFlexSelected}
              setCookie={setCookie}
              //setredrawVoucher={setredrawVoucher}
            ></BoardTypeTable>
          )}

          {allRoomOffers.length > 1 && (
            <div
              className="text-body mt-1 mb-2"
              style={{ display: "flex" }}
              onClick={() => setShowAllRooms(!showallRooms)}
            >
              <FaBed />
              &nbsp;
              <div style={{ width: 270 }} className="companybutton mb-2 ">
                Wählen Sie aus {allRoomOffers.length} Zimmerarten.
              </div>
            </div>
          )}
          {allRoomOffers.length > 1 && showallRooms && (
            <RoomTypeSelect
              offer={offer}
              openSubSelect={openSubSelect}
              allRoomOffers={allRoomOffers}
              travellerfactor={travellerfactor}
              //setredrawVoucher={setredrawVoucher}
            ></RoomTypeSelect>
          )}

          {Array.isArray(alternativeTransports?.Offers?.Offer) &&
            alternativeTransports.Offers.Offer.length > 1 && (
              <div
                className="text-body mt-1 mb-2"
                style={{ display: "flex" }}
                onClick={() => setShowAllFlights(!showallflights)}
              >
                <FaPlane />
                &nbsp;
                <div style={{ width: 270 }} className="companybutton ">
                  Wählen Sie aus {alternativeTransports.Offers.Offer.length}{" "}
                  Flügen.
                </div>
              </div>
            )}
          {showallflights && (
            <FlightSelect
              offer={offer}
              openSubSelect={openSubSelect}
              outboundFlighttime={outboundFlighttime}
              setOutboundFlighttime={setOutboundFlighttime}
              alternativeTransports={alternativeTransports}
              outboundStations={outboundStations}
              travellerfactor={travellerfactor}
              //setredrawVoucher={setredrawVoucher}
            ></FlightSelect>
          )}

          {Object.keys(offersRoomAttributes).length > 0 && (
            <div className="text-body mt-1 mb-2" style={{ display: "flex" }}>
              <FaPlus />
              &nbsp;
              <div
                style={{ width: 270 }}
                onClick={() => setShowRoomAttributes(!showRoomAttributes)}
                className="companybutton "
              >
                Wählen Sie aus{" "}
                {Object.keys(offersRoomAttributesAfterFilter).length}{" "}
                Zimmerattributen.
              </div>
            </div>
          )}
          {showRoomAttributes && (
            <div className="row">
              {Object.keys(offersRoomAttributes).map((e) => {
                let disabled = false;

                if (!Object.keys(offersRoomAttributesAfterFilter).includes(e))
                  disabled = true;

                if (selectedRoomAttributes.includes(e)) disabled = false;

                if (loading) disabled = true;

                return (
                  <div className="col-12 col-sm-6 col-lg-4">
                    <input
                      disabled={disabled}
                      type="checkbox"
                      checked={selectedRoomAttributes.includes(e)}
                      onChange={() => {
                        if (selectedRoomAttributes.includes(e)) {
                          let tmpAtt = [];
                          for (
                            let i = 0;
                            i < selectedRoomAttributes.length;
                            i++
                          ) {
                            if (selectedRoomAttributes[i] !== e) {
                              tmpAtt.push(selectedRoomAttributes[i]);
                            }
                          }
                          dispatch(setSelectedRoomAttributes(tmpAtt));
                        } else {
                          dispatch(
                            setSelectedRoomAttributes([
                              ...selectedRoomAttributes,
                              e,
                            ])
                          );
                        }
                      }}
                    />
                    &nbsp;
                    {offersRoomAttributes[e].label} (
                    {offersRoomAttributesAfterFilter[e]
                      ? offersRoomAttributesAfterFilter[e].count
                      : 0}
                    )
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {offer.OfferID && (
        <div style={{ height: "30%", backgroundColor: "#fff", color: "#bbb" }}>
          <MobileOfferView
            mode={"planning"}
            fullinfo={false}
            flexbooked={offer.flexbooked}
            outboundStations={outboundStations}
            inboundStations={inboundStations}
            offers={offers}
            roomTypes={roomTypes}
            otherRoomOffers={otherRoomOffers}
            //redrawVoucher={redrawVoucher}
            //setredrawVoucher={setredrawVoucher}
            offer={offer}
            otherCancelOffers={otherCancelOffers}
            otherRailOffer={otherRailOffer}
            otherTransferOffer={otherTransferOffer}
            otherBoardOffers={otherBoardOffers}
            otherViewOffers={otherViewOffers}
            outboundFlighttime={outboundFlighttime}
            setOutboundFlighttime={setOutboundFlighttime}
          ></MobileOfferView>
        </div>
      )}

      <div style={{ height: 80 }}></div>
    </>
  );
}

export default MobileCrossView;
