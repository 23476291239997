import React, { useState } from "react";
import { components } from "react-select";

import { useSelector, useDispatch } from "react-redux";
import { selectSearch, setSearchOption } from "amadeusredux/lib/searchSlice";

export default function Options(props) {
  const { stations } = useSelector(selectSearch);
  const dispatch = useDispatch();

  const departureStations = useSelector(
    (state) => state.properties.departureStations
  );

  return (
    <div>
      <components.Option {...props}>
        <input type="checkbox" checked={props.isSelected} readOnly></input>{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
}
