import React, { useState } from "react";
import SearchView from "./SearchView";
import { useDispatch, useSelector } from "react-redux";
import SearchInfoView from "./SearchInfoView";

function Header() {
  const dispatch = useDispatch();

  return (
    <div
      className="bg-body-tertiary "
      style={{
        position: "fixed",
        zIndex: 2000,
        top: 56,
        left: 0,
        width: "100%",
      }}
    >
      <div className="container">
        <div className=" row mb-2 mt-1 ps-3 pe-3">
          {/*
          //view === views.offerview ||
           (view === views.hotelcrossview ||
            view === views.hotelview) &&
            Array.isArray(offerInfos?.Offers?.Offer) &&
            offerInfos.Offers.Offer.length > 0 && (
              <div
                className="col-1"
                onClick={() => {
                  if (view === views.hotelcrossview)
                    dispatch(setView(views.offerview));
                  if (view === views.hotelview) dispatch(setView(inboundView));
                }}
              >
                <FaChevronCircleLeft />
              </div>
            )
        */}

          <SearchInfoView />
        </div>
      </div>
    </div>
  );
}

export default Header;
