import React from "react";
//import { GiConsoleController } from "react-icons/gi";
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  FormattedTime,
} from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { parse } from "iso8601-duration";
import { verifyFare } from "amadeusredux/lib/flightSlice";

import MobileOfferFlight from "../components/MobileOfferFlight";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { setInboundView, setTheOffer } from "amadeusredux/lib/bookingSlice";
import { setOfferId } from "amadeusredux/lib/hotelSlice";
import { setView } from "amadeusredux/lib/systemSlice";
import { views } from "amadeusredux/lib/utils";

function MobileFlightsView() {
  const dispatch = useDispatch();
  //const fares = useSelector((state) => state.flight.fares);
  const offers = useSelector((state) => state.flight.offers);

  const [order, setOrder] = React.useState("1");

  const [currentOfferID, setCurrentOfferID] = React.useState([]);

  //console.log(fares);

  /*let itineraries = [];

  if (Array.isArray(fares)) {
    fares.map((fare) => {
      let sellPrice = 0;
      let agencyMargin = 0;
      let currencyIsoCode = "";

      if (
        Array.isArray(fare?.farePassengerTypePriceList?.farePassengerTypePrice)
      ) {
        for (let price of fare.farePassengerTypePriceList
          .farePassengerTypePrice) {
          currencyIsoCode = price.purchasePrice.currencyIsoCode;
          sellPrice =
            sellPrice +
            (price.agencyMargin.value * 1 +
              price.agencyPurchasePrice.value * 1 +
              price.totalTax.value * 1) *
              (price.count * 1);
          agencyMargin =
            agencyMargin + price.agencyMargin.value * 1 * (price.count * 1);
        }

        sellPrice = Math.round(sellPrice * 100) / 100;
        agencyMargin = Math.round(agencyMargin * 100) / 100;
      }
      return (
        Array.isArray(fare?.availableSubFareList?.availableSubFare) &&
        fare.availableSubFareList.availableSubFare.map(
          (availableSubFare) =>
            Array.isArray(
              availableSubFare?.availableSubFareLegList?.availableSubFareLeg
            ) &&
            availableSubFare.availableSubFareLegList.availableSubFareLeg.map(
              (availableSubFareLeg) =>
                Array.isArray(availableSubFareLeg?.itineraryList?.itinerary) &&
                availableSubFareLeg.itineraryList.itinerary.map((itinerary) =>
                  itineraries.push({
                    ...itinerary,
                    fare: { ...fare },
                    price: { sellPrice, agencyMargin, currencyIsoCode },
                  })
                )
            )
        )
      );
    });
  }
  itineraries.sort((a, b) => {
    //console.log(a.flyingTimeInMinutes * 1, b.flyingTimeInMinutes * 1);
    return (
      (a.flyingTimeInMinutes ? a.flyingTimeInMinutes * 1 : 99999) -
      (b.flyingTimeInMinutes ? b.flyingTimeInMinutes * 1 : 99999)
    );
  });*/

  let pageOffers = [...offers];

  if (order === "1" || order === "2") {
    pageOffers.sort((a, b) => {
      return a.PriceInfo.Price.value > b.PriceInfo.Price.value;
    });
    if (order === "2") {
      pageOffers.reverse();
    }
  }

  if (order === "3" || order === "4") {
    pageOffers.sort((a, b) => {
      return (
        a.OfferServices.Package.Flight.OutboundFlight.FlightDuration * 1 >
        b.OfferServices.Package.Flight.OutboundFlight.FlightDuration * 1
      );
    });
    if (order === "4") {
      pageOffers.reverse();
    }
  }

  //console.log(itineraries);

  return (
    <>
      <select
        className="form-select"
        onChange={(e) => {
          setOrder(e.target.value);
        }}
      >
        <option value="1" selected={order === "1"}>
          Preis aufsteigend
        </option>
        <option value="2" selected={order === "2"}>
          Preis absteigend
        </option>
        <option value="3" selected={order === "3"}>
          Dauer aufsteigend
        </option>
        <option value="4" selected={order === "4"}>
          Dauer absteigend
        </option>
      </select>
      <br />
      <br />
      {/*Array.isArray(fares) && fares.length > 0 && (
        <div>
          {fares.map((fare) => {
            return (
              <Fare
                fare={fare}
                currentFareId={currentFareId}
                setCurrentFareId={(id) => setCurrentFareId(id)}
              />
            );
          })}
        </div>
      )*/}

      {/*itineraries.map((it) => (
        <Itinerary itinerary={it} />
      ))*/}

      {/*JSON.stringify(fares)*/}

      {Array.isArray(pageOffers) && pageOffers.length > 0 && (
        <div>
          {pageOffers.map((offer) => {
            return (
              <div
                className="card p-2 mb-2"
                onClick={() => {
                  console.log(offer);
                }}
              >
                {/*offer.OfferID*/}
                {/*{offer.adults + offer.children} Personen*/}
                <div className="text-end">
                  {!currentOfferID.includes(offer.OfferID) ? (
                    <FaPlusCircle
                      onClick={() => {
                        setCurrentOfferID([...currentOfferID, offer.OfferID]);
                      }}
                    />
                  ) : (
                    <FaMinusCircle
                      onClick={() => {
                        setCurrentOfferID(
                          currentOfferID.filter((e) => e !== offer.OfferID)
                        );
                      }}
                    />
                  )}
                </div>
                <div>
                  <MobileOfferFlight
                    offer={offer}
                    verbose={currentOfferID.includes(offer.OfferID)}
                  ></MobileOfferFlight>

                  <div className="text-end h3">
                    {!offer?.verifiedFare?.availableFareTotal?.value &&
                      offer?.PriceInfo?.Price?.value && (
                        <FormattedNumber
                          value={
                            offer.PriceInfo.Price.value *
                            (offer.adults * 1 + offer.children * 1)
                          }
                          minimumFractionDigits={2}
                          maximumFractionDigits={2}
                        />
                      )}
                    {offer?.verifiedFare?.availableFareTotal?.value && (
                      <FormattedNumber
                        value={
                          offer?.verifiedFare?.availableFareTotal?.value * 1
                        }
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    )}
                    &nbsp;
                    {offer?.PriceInfo?.Price?.CurrencyCode
                      ? offer.PriceInfo.Price.CurrencyCode
                      : ""}{" "}
                  </div>
                  {!offer?.verifiedFare?.availableFareTotal?.value &&
                    offer.adults * 1 + offer.children * 1 > 1 && (
                      <div className="text-end text-muted">
                        <small>
                          (
                          {offer?.PriceInfo?.Price?.value && (
                            <FormattedNumber
                              value={offer.PriceInfo.Price.value}
                              minimumFractionDigits={2}
                              maximumFractionDigits={2}
                            />
                          )}
                          &nbsp;
                          {offer?.PriceInfo?.Price?.CurrencyCode
                            ? offer.PriceInfo.Price.CurrencyCode
                            : ""}{" "}
                          pro Person)
                        </small>
                      </div>
                    )}
                  {offer?.verifiedFare?.availableFareTotal?.value &&
                    offer.adults * 1 + offer.children * 1 > 1 && (
                      <div className="text-end text-muted">
                        <small>
                          (
                          {offer?.verifiedFare?.availableFareTotal?.value && (
                            <FormattedNumber
                              value={
                                offer?.verifiedFare?.availableFareTotal?.value /
                                (offer.adults * 1 + offer.children * 1)
                              }
                              minimumFractionDigits={2}
                              maximumFractionDigits={2}
                            />
                          )}
                          &nbsp;
                          {offer?.PriceInfo?.Price?.CurrencyCode
                            ? offer.PriceInfo.Price.CurrencyCode
                            : ""}{" "}
                          pro Person)
                        </small>
                      </div>
                    )}
                  {/*<AmadeusFlight
                      flight={offer.OfferServices.Package.Flight.OutboundFlight}
                      flightSegments={
                        offer?.bookinginfo?.Offer?.Package?.Flight
                          ?.OutboundFlightSegments
                      }
                    />*/}
                </div>
                {/*offer?.OfferServices?.Package?.Flight?.InboundFlight && (
                  <div>
                    {" "}
                    Rückflug
                    <br />
                    <AmadeusFlight
                      flight={offer.OfferServices.Package.Flight.InboundFlight}
                      flightSegments={
                        offer?.bookinginfo?.Offer?.Package?.Flight
                          ?.InboundFlightSegments
                      }
                    />
                  </div>
                )*/}
                <div className="mb-4"></div>
                {!offer.verifiedFare && (
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      dispatch(verifyFare({ offer }));
                    }}
                  >
                    Angebot verifizieren
                  </button>
                )}
                {offer.verifiedFare &&
                  offer.verifiedFare?.featureList
                    ?.verifiedAvailableSubFareFeature?.id ===
                    "OPTION_BOOKING" && (
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        //dispatch(verifyFare({ offer }));

                        dispatch(setTheOffer(offer));
                        dispatch(setOfferId(offer.OfferID));
                        dispatch(setInboundView(views.flightsview));

                        dispatch(setView(views.bookingview));
                      }}
                    >
                      zur Buchung
                    </button>
                  )}
                {offer.verifiedFare &&
                  offer.verifiedFare?.featureList
                    ?.verifiedAvailableSubFareFeature?.id !==
                    "OPTION_BOOKING" && (
                    <button
                      className="btn btn-primary"
                      disabled={true}
                      onClick={() => {
                        //dispatch(verifyFare({ offer }));
                      }}
                    >
                      nicht mehr verfügbar
                    </button>
                  )}
              </div>
            );
          })}
        </div>
      )}
      {/*<pre>{JSON.stringify(offers, null, 4)}</pre>*/}
    </>
  );
}

function AmadeusFlight(props) {
  const { flight, flightSegments } = props;

  return (
    <div>
      {Array.isArray(flightSegments?.FlightSegment) &&
        flightSegments?.FlightSegment.length > 1 && (
          <>
            <FormattedDate
              year="numeric"
              month="2-digit"
              day="2-digit"
              value={
                flight.FlightDeparture.DepartureDate +
                "T" +
                flight.FlightDeparture.DepartureTime
              }
            />
            &nbsp;
            <FormattedTime
              value={
                flight.FlightDeparture.DepartureDate +
                "T" +
                flight.FlightDeparture.DepartureTime
              }
            />
            &nbsp;
            {flight.FlightDeparture.DepartureAirportRef.AirportCode}
            &nbsp;-&nbsp;
            <FormattedDate
              year="numeric"
              month="2-digit"
              day="2-digit"
              value={
                flight.FlightArrival.ArrivalDate +
                "T" +
                flight.FlightArrival.ArrivalTime
              }
            />
            &nbsp;
            <FormattedTime
              value={
                flight.FlightArrival.ArrivalDate +
                "T" +
                flight.FlightArrival.ArrivalTime
              }
            />
            &nbsp;
            {flight.FlightArrival.ArrivalAirportRef.AirportCode}&nbsp;&nbsp;
            Flugdauer:{" "}
            {flight.FlightDuration * 1 > 60 && (
              <>
                {(flight.FlightDuration / 60).toFixed()} Std.{" "}
                {flight.FlightDuration % 60 > 0 && (
                  <>{flight.FlightDuration % 60} Min.</>
                )}
              </>
            )}
            {flight.FlightDuration * 1 <= 60 && (
              <>{flight.FlightDuration} Min.</>
            )}
            &nbsp;&nbsp;
          </>
        )}
      {/*flightSegments ? JSON.stringify(flightSegments) : ""*/}
      {Array.isArray(flightSegments?.FlightSegment) &&
        flightSegments.FlightSegment.map((fs, idx) => {
          let stopover = {};
          if (Array.isArray(flightSegments.Stopover)) {
            stopover = flightSegments.Stopover.find(
              (e) => e.$.flightSegmentRunNumberFrom * 1 === idx
            );
          }
          let waitTime = {};

          if (stopover?.StopoverDuration) {
            waitTime = parse(stopover.StopoverDuration);
          }

          return (
            <div>
              <FormattedDate
                year="numeric"
                month="2-digit"
                day="2-digit"
                value={fs.FlightDateTimeSpan.$.Start}
              />
              &nbsp;
              <FormattedTime value={fs.FlightDateTimeSpan.$.Start} /> &nbsp;
              {fs.DepartureAirport._}
              &nbsp;-&nbsp;
              <FormattedDate
                year="numeric"
                month="2-digit"
                day="2-digit"
                value={fs.FlightDateTimeSpan.$.End}
              />
              &nbsp;
              <FormattedTime value={fs.FlightDateTimeSpan.$.End} />
              &nbsp;{fs.DestinationAirport._}
              &nbsp;&nbsp;
              {fs.OperatingAirline._}&nbsp;&nbsp;
              {flight.DirectFlightAssumed && "Direktflug"}
              {flight.DirectFlightAssumed && (
                <>
                  &nbsp;&nbsp;Flugdauer: {flight.FlightDuration} Minuten
                  &nbsp;&nbsp;
                </>
              )}
              {stopover && (
                <div>
                  Zwischenstopp:&nbsp;
                  {/*<FormattedDate
                    year="numeric"
                    month="2-digit"
                    day="2-digit"
                    value={stopover.StopoverStart}
                  />
                  &nbsp;
                  <FormattedTime value={stopover.StopoverStart} />
                  &nbsp;-&nbsp;{" "}
                  <FormattedDate
                    year="numeric"
                    month="2-digit"
                    day="2-digit"
                    value={stopover.StopoverEnd}
                  />
                  &nbsp;
                  <FormattedTime value={stopover.StopoverEnd} />
                  &nbsp;&nbsp;*/}
                  {waitTime.days > 0 && (
                    <>
                      <FormattedMessage
                        id="days"
                        values={{ num: waitTime.days }}
                      />
                      &nbsp;
                    </>
                  )}
                  {waitTime.hours > 0 && (
                    <>
                      <FormattedMessage
                        id="hoursabb"
                        values={{ num: waitTime.hours }}
                      />
                      &nbsp;
                    </>
                  )}
                  {waitTime.minutes > 0 && (
                    <FormattedMessage
                      id="minutesabb"
                      values={{ num: waitTime.minutes }}
                    />
                  )}
                  &nbsp;&nbsp;{stopover.StopoverAirport._}
                </div>
              )}
            </div>
          );
        })}
      <br />
    </div>
  );
}

function AirSegment(props) {
  const { airSegment } = props;
  return (
    <div>
      {/*airSegment?.departureTimestamp*/}
      <FormattedDate
        year="2-digit"
        month="2-digit"
        day="2-digit"
        value={airSegment?.departureTimestamp}
      />{" "}
      <FormattedTime value={airSegment?.departureTimestamp} />
      &nbsp;-&nbsp;
      {/*airSegment?.arrivalTimestamp*/}
      <FormattedDate
        year="2-digit"
        month="2-digit"
        day="2-digit"
        value={airSegment?.arrivalTimestamp}
      />{" "}
      <FormattedTime value={airSegment?.arrivalTimestamp} />
      &nbsp;&nbsp;
      {airSegment?.departure?.name} ({airSegment?.departure?.code})
      &nbsp;-&nbsp;
      {airSegment?.destination?.name} ({airSegment?.destination?.code})
      &nbsp;&nbsp;
      {airSegment?.operatingAirline?.name}
      &nbsp;&nbsp;
      {airSegment?.equipmentType?.name}
      &nbsp;&nbsp;
      {airSegment?.baggageAllowance?.value}
    </div>
  );
}

function Itinerary(props) {
  const { itinerary } = props;

  return (
    <div
      onClick={() => {
        if (itinerary?.fare) {
          console.log(itinerary.fare);
          console.log(JSON.stringify(itinerary.fare, null, 10));
        }
      }}
    >
      {itinerary?.id} &nbsp;&nbsp;&nbsp;
      {itinerary?.flyingTimeInMinutes} Min{" "}
      {itinerary.price && (
        <FormattedNumber
          value={itinerary.price.sellPrice}
          minimumFractionDigits={2}
          maximumFractionDigits={2}
        />
      )}
      &nbsp;
      {itinerary.price && itinerary.price.currencyIsoCode}&nbsp;
      {Array.isArray(itinerary?.segmentList?.airSegment) &&
        itinerary.segmentList.airSegment.length === 1 &&
        "Direktflug"}
      {Array.isArray(itinerary?.segmentList?.airSegment) &&
        itinerary.segmentList.airSegment.length > 1 &&
        itinerary.segmentList.airSegment.length - 1 + " Umstieg(e)"}
      {Array.isArray(itinerary?.segmentList?.airSegment) &&
        itinerary.segmentList.airSegment.map((airSegment) => {
          //console.log(airSegment);
          return <AirSegment airSegment={airSegment} />;
        })}
      <br />
    </div>
  );
}

function AvailableSubFareLeg(props) {
  const { availableSubFareLeg } = props;
  return (
    <div>
      <br />
      {availableSubFareLeg?.direction?.name}
      {Array.isArray(availableSubFareLeg?.itineraryList?.itinerary) &&
        availableSubFareLeg.itineraryList.itinerary.map((itinerary) => {
          console.log(itinerary);
          return <Itinerary itinerary={itinerary} />;
        })}
    </div>
  );
}

function AvailableSubFare(props) {
  const { availableSubFare } = props;
  return (
    <div>
      {/*availableSubFare.rulesKey*/}
      {Array.isArray(
        availableSubFare?.availableSubFareLegList?.availableSubFareLeg
      ) &&
        availableSubFare.availableSubFareLegList.availableSubFareLeg.map(
          (availableSubFareLeg) => {
            console.log(availableSubFareLeg);
            return (
              <AvailableSubFareLeg availableSubFareLeg={availableSubFareLeg} />
            );
          }
        )}
    </div>
  );
}

function Fare(props) {
  const { fare, currentFareId, setCurrentFareId } = props;

  let sellPrice = 0;
  let agencyMargin = 0;
  let currencyIsoCode = "";

  if (Array.isArray(fare.farePassengerTypePriceList.farePassengerTypePrice)) {
    for (let price of fare.farePassengerTypePriceList.farePassengerTypePrice) {
      currencyIsoCode = price.purchasePrice.currencyIsoCode;
      sellPrice =
        sellPrice +
        (price.agencyMargin.value * 1 +
          price.agencyPurchasePrice.value * 1 +
          price.totalTax.value * 1) *
          (price.count * 1);
      agencyMargin =
        agencyMargin + price.agencyMargin.value * 1 * (price.count * 1);
    }

    sellPrice = Math.round(sellPrice * 100) / 100;
    agencyMargin = Math.round(agencyMargin * 100) / 100;
  }
  return (
    <div
      style={{ fontWeight: fare.id === currentFareId ? 600 : undefined }}
      onClick={() => {
        console.log(fare);
        setCurrentFareId(fare.id);
      }}
    >
      {fare.id === currentFareId && <br />}
      <FormattedNumber
        value={sellPrice}
        minimumFractionDigits={2}
        maximumFractionDigits={2}
      />{" "}
      {currencyIsoCode} -{" "}
      <FormattedNumber
        value={agencyMargin}
        minimumFractionDigits={2}
        maximumFractionDigits={2}
      />{" "}
      {currencyIsoCode}
      {fare.id === currentFareId &&
        Array.isArray(fare?.availableSubFareList?.availableSubFare) &&
        fare.availableSubFareList.availableSubFare.map((availableSubFare) => {
          console.log(availableSubFare);
          return <AvailableSubFare availableSubFare={availableSubFare} />;
        })}
    </div>
  );
}

export default MobileFlightsView;
