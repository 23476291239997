import React, { useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  getHost,
  boardTypes,
  propertyFromArray,
  roomTypes,
  stars,
  giataOperators,
  hotelAttributes,
  touroperatorMappings,
} from "../utils";

import { setView } from "amadeusredux/lib/systemSlice";

import { MdCheck } from "react-icons/md";
import {
  FaRegSquare,
  FaSquare,
  FaThumbsDown,
  FaThumbsUp,
} from "react-icons/fa";
import Rating from "react-rating";

import { hotelRatings, hotelRatingsText } from "../utils";

import {
  setHotelOfferInfos,
  setHotelPage,
  setOfferId,
  setSelectedHotel,
  getCatalogData,
  getCityInfo,
} from "amadeusredux/lib/hotelSlice";
import { setSelectedOfferId } from "amadeusredux/lib/offersSlice";
import {
  setApicall,
  setInbounddate,
  setOutbounddate,
  setSearchOption,
} from "amadeusredux/lib/searchSlice";
import { search } from "amadeusredux/lib/api";
import axios from "axios";

const nf1 = new Intl.NumberFormat("de-DE", {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

const nf = new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2 });

function MobileHotelView() {
  const hotel = useSelector((state) => state.hotel.infoHotel);
  const dispatch = useDispatch();
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [panelControl, setPanelControl] = useState({
    beschreibung: false,
    ausstattung: false,
    bewertungen: false,
  });
  const inboundView = useSelector((state) => state.booking.inboundView);
  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = (hotel) => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isRightSwipe || isLeftSwipe) dispatch(setView(inboundView));

    // add your conditional logic here
  };

  const cityInfo = useSelector((state) => state.hotel.cityInfo);
  const catalogData = useSelector((state) => state.hotel.catalogData);
  const selectedDestination = useSelector(
    (state) => state.search.selectedDestination
  );

  const sidebar = useSelector((state) => state.system.sidebar);
  const [clientFilters, setClientFilters] = React.useState({
    excludeTourOperators: [],
  });

  const [thumbsSwiper, setThumbsSwiper] = React.useState(null);

  const [operators, setOperators] = React.useState([]);

  const hotelData = useSelector((state) => state.hotel.hotelData);
  //const searchOptions = useSelector(selectSearch);
  const [hotelRegion, setHotelRegion] = React.useState({});

  const [showInfo, setShowInfo] = React.useState();
  const [catIdx, setCatIdx] = React.useState({ value: 0 });

  const [tourOperators, setTouroperators] = React.useState([]);

  const [images, setImages] = React.useState([]);

  const [images800, setImages800] = React.useState([]);
  const [logoImage, setLogoImage] = React.useState("");
  const [heroImage, setHeroImage] = React.useState("");
  const [catalogText, setCatalogText] = React.useState("");
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [popupInfoOpen, setPopupInfoOpen] = React.useState(false);
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [previewPicture, setPreviewPicture] = React.useState(null);
  const [previewIndex, setPreviewIndex] = React.useState(0);
  const [agbText, setAgbText] = React.useState("");

  const [nav1, setNav1] = React.useState(null);
  const [nav2, setNav2] = React.useState(null);

  const infos = useSelector((state) => state.properties.infos);
  const view = useSelector((state) => state.system.view);

  let geodata = hotelData.GeoDictionary;

  const holidayType = useSelector((state) => state.search.holidayType);

  let odd = true;

  React.useEffect(() => {
    let images = [];

    if (
      catalogData &&
      catalogData[catIdx.value] &&
      Array.isArray(catalogData[catIdx.value].Bildfile)
    ) {
      let size800 = catalogData[catIdx.value].Bildfile.filter(
        (e) => e.$.size === "800"
      );
      let size150 = catalogData[catIdx.value].Bildfile.filter(
        (e) => e.$.size === "150"
      );

      for (let i = 0; i < size800.length && i < size150.length; i++) {
        images.push({
          original: size800[i]._,
          thumbnail: size150[i]._,
          originalWidth: 800,
          originalHeight: 600,
        });
      }
    }

    setImages([...images]);
  }, [catalogData, catIdx]);

  React.useEffect(() => {
    dispatch(getCatalogData({}));
    if (hotel?.HotelLocationRef?.CityID)
      dispatch(getCityInfo({ id: hotel?.HotelLocationRef?.CityID }));
  }, []);

  React.useEffect(() => {
    let images800 = [];

    if (
      catalogData &&
      catalogData[catIdx.value] &&
      Array.isArray(catalogData[catIdx.value].Bildfile)
    ) {
      let size800 = catalogData[catIdx.value].Bildfile.filter(
        (e) => e.$.size === "800"
      );
      let amount = size800.length;
      if (amount > 10) amount = 10;

      for (let i = 0; i < amount; i++) {
        images800.push({
          original: size800[i]._,
          thumbnail: size800[i]._,
          originalWidth: 640,
          originalHeight: 480,
        });
      }
    }

    setImages800([...images800]);
  }, [catalogData, catIdx]);

  React.useEffect(() => {
    if (Array.isArray(catalogData) && catalogData.length > catIdx.value) {
      if (
        catalogData[catIdx.value] &&
        Array.isArray(catalogData[catIdx.value].Textfile) &&
        catalogData[catIdx.value].Textfile.length > 0
      ) {
        axios
          .post(getHost() + "/giatadata/", {
            url: catalogData[catIdx.value].Textfile[0],
          })
          .then((response) => {
            //console.log(response);
            setCatalogText(response.data);
          });
      } else {
        setCatalogText("");
      }
      if (
        catalogData[catIdx.value] &&
        Array.isArray(catalogData[catIdx.value].AGB) &&
        catalogData[catIdx.value].AGB.length > 0
      ) {
        axios
          .post(getHost() + "/giatadata/", {
            url: catalogData[catIdx.value].AGB[0],
          })
          .then((response) => {
            //console.log(response);
            setAgbText(response.data);
          });
      } else {
        setAgbText("");
      }
    } else {
      setCatalogText("");
      setAgbText("");
    }
  }, [catalogData, catIdx]);

  React.useEffect(() => {
    if (
      hotel &&
      hotel.HotelLocationRef &&
      hotel.HotelLocationRef.RegionID &&
      hotelData &&
      hotelData.GeoDictionary &&
      hotelData.GeoDictionary.Regions &&
      Array.isArray(hotelData.GeoDictionary.Regions.Region)
    ) {
      if (
        hotelData.GeoDictionary.Regions.Region.find(
          (e) => hotel?.HotelLocationRef.RegionID === e.RegionID
        )
      ) {
        setHotelRegion({
          ...hotelData.GeoDictionary.Regions.Region.find(
            (e) => hotel?.HotelLocationRef.RegionID === e.RegionID
          ),
        });
      }
    }
  }, [hotel, hotelData]);

  React.useEffect(() => {
    let hero = "";
    let logo = "";

    if (
      Array.isArray(catalogData) &&
      catIdx &&
      catIdx.value &&
      catalogData[catIdx.value]
    ) {
      //console.log(catIdx.value);
      let catalogRow = catalogData[catIdx.value];
      if (Array.isArray(catalogRow.Bildfile)) {
        for (let j = 0; j < catalogRow.Bildfile.length; j++) {
          let file = catalogRow.Bildfile[j];
          //console.log(file.$.size + ":" + file.$.typ);
          if (!hero && file.$.size === "800" && file.$.typ === "MOD") {
            hero = file._;
          }
          if (!logo && file.$.typ === "LG") {
            logo = file._;
          }
          if (hero && logo) {
            break;
          }
        }
      }
    }

    if (
      !hero &&
      Array.isArray(catalogData) &&
      catIdx &&
      catIdx.value &&
      catalogData[catIdx.value]
    ) {
      // console.log(catIdx.value);
      let catalogRow = catalogData[catIdx.value];
      if (Array.isArray(catalogRow.Bildfile)) {
        for (let j = 0; j < catalogRow.Bildfile.length; j++) {
          let file = catalogRow.Bildfile[j];
          //console.log(file.$.size + ":" + file.$.typ);
          if (!hero && file.$.size === "800" && file.$.typ === "A") {
            hero = file._;
          }

          if (hero) {
            break;
          }
        }
      }
    }

    if (Array.isArray(catalogData)) {
      for (let i = 0; i < catalogData.length; i++) {
        let catalogRow = catalogData[i];
        if (Array.isArray(catalogRow.Bildfile)) {
          for (let j = 0; j < catalogRow.Bildfile.length; j++) {
            let file = catalogRow.Bildfile[j];
            if (!hero && file.$.size === "800" && file.$.typ === "A") {
              hero = file._;
            }
            if (!logo && file.$.typ === "LG") {
              logo = file._;
            }
            if (hero && logo) {
              break;
            }
          }
        }
        if (hero && logo) {
          break;
        }
      }
    }
    if (!hero) {
      if (Array.isArray(catalogData)) {
        for (let i = 0; i < catalogData.length; i++) {
          let catalogRow = catalogData[i];
          if (Array.isArray(catalogRow.Bildfile)) {
            for (let j = 0; j < catalogRow.Bildfile.length; j++) {
              let file = catalogRow.Bildfile[j];
              if (!hero && file.$.size === "800" && file.$.typ === "W") {
                hero = file._;
              }
              if (hero) {
                break;
              }
            }
          }
          if (hero) {
            break;
          }
        }
      }
    }
    setLogoImage(logo);
    setHeroImage(hero);
  }, [catalogData, catIdx]);

  React.useEffect(() => {
    if (operators && operators.length > 0) {
      //console.log("operators", operators);
      //console.log("HD", hotelData.Offers, operators.length);
      let bfound = false;
      let idx = operators[0];
      for (let i = 0; i < operators.length; i++) {
        if (
          operators[i].amadeusid ===
          hotelData.Offers.Offer.TourOperator.TourOperatorCode
        ) {
          idx = operators[i];
          bfound = true;
          break;
        }
      }

      if (
        !bfound &&
        Array.isArray(
          touroperatorMappings[
            hotelData.Offers.Offer.TourOperator.TourOperatorCode
          ]
        ) &&
        touroperatorMappings[
          hotelData.Offers.Offer.TourOperator.TourOperatorCode
        ].length > 0
      ) {
        for (let i = 0; i < operators.length; i++) {
          for (
            let j = 0;
            j <
            touroperatorMappings[
              hotelData.Offers.Offer.TourOperator.TourOperatorCode
            ].length;
            j++
          ) {
            if (
              operators[i].amadeusid ===
              touroperatorMappings[
                hotelData.Offers.Offer.TourOperator.TourOperatorCode
              ][j]
            ) {
              idx = operators[i];
              bfound = true;
              break;
            }
          }
          if (bfound) break;
        }
      }

      setCatIdx(idx);
    }
  }, [operators, hotelData]);

  React.useEffect(() => {
    let opps = [];
    if (Array.isArray(catalogData)) {
      for (let i = 0; i < catalogData.length; i++) {
        let bFound = false;
        let bOperator = false;
        for (let j = 0; j < opps.length; j++) {
          if (opps[j].id === catalogData[i].VeranstalterID[0]) {
            bFound = true;
            break;
          }
        }
        let giata = giataOperators.find(
          (e) => e.vi === catalogData[i].VeranstalterID[0]
        );
        //console.log(giata.vc);
        /*if (
                  giata &&
                  (giata.vn.indexOf("Schweiz") > -1 ||
                    giata.vn.indexOf("Österreich") > -1)
                ) {
                  continue;
                }*/
        /*if (!bFound) {
                  for (let j = 0; j < tourOperators.length; j++) {
                    //console.log(tourOperators[j].TourOperatorCode);
                    if (tourOperators[j].TourOperatorCode === giata.vc) {
                      bOperator = true;
                      break;
                    }
                  }
                }*/
        //if (!bFound && bOperator) {
        if (!bFound && !bOperator) {
          opps.push({
            value: i,
            id: catalogData[i].VeranstalterID[0],
            amadeusid: giata ? giata.vc : "",
            label: giata ? giata.vn : catalogData[i].VeranstalterID[0],
          });
        }
      }
    }
    if (Array.isArray(opps))
      opps = opps.sort((a, b) => ("" + a.label).localeCompare(b.label));
    //setCatIdx(opps.length > 0 ? opps[0] : { value: 0 });
    setOperators([...opps]);
  }, [catalogData, tourOperators]);

  let hotelText = catalogText;
  hotelText = hotelText.substring(0, 400);
  hotelText = hotelText.substring(0, hotelText.lastIndexOf(" ") + 1) + " ...";
  const slick_settings_main = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    //fade: true,
    variableWidth: true,
    focusOnSelect: true,
  };

  const slick_settings_popup = {
    dots: false,
    lazyLoad: true,
    centerMode: true,
    centerPadding: "120px",
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 1,
    swipeToSlide: true,
    autoplay: false,
    speed: 200,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    //fade: true,
    variableWidth: true,
    focusOnSelect: true,
  };

  const slick_settings_navigation = {
    dots: true,
    lazyLoad: true,
    infinite: false,
    slidesToShow: 10,
    slidesToScroll: 5,
    swipeToSlide: true,
    initialSlide: 1,
    autoplay: false,
    speed: 2000,
    autoplaySpeed: 50000,
    pauseOnHover: true,
    //fade: true,
    variableWidth: true,
    focusOnSelect: true,
  };
  let hotelAttributesFiltered = [];
  if (Array.isArray(hotelAttributes))
    hotelAttributesFiltered = hotelAttributes.filter((a) => a.hide !== true);

  let ct = 0;

  if (hotel && Array.isArray(hotel?.HotelAttributes)) {
    hotel.HotelAttributes.map((e, i) => {
      let fnd = hotelAttributesFiltered.find((a) => i < 10 && e === a.value);
      if (fnd) ct = ct + 1;
    });
  }

  let attributesfill = [];
  if (Array.isArray(hotel?.HotelAttributes))
    for (let i = 0; i < 10 - ct; i++) {
      attributesfill.push(0);
    }

  //console.log(hotelData);

  return (
    <>
      <div className="row">
        <div
          onClick={() => {
            //dispatch(setView(views.hotelcrossview));
            dispatch(setView(inboundView));
            //search(dispatch, true, false);
          }}
          className=" col-12  text-end"
        >
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              dispatch(setView(inboundView));
            }}
          >
            Zurück
          </button>
        </div>
      </div>
      <div className="row">
        {hotel && (
          <>
            <div
              id="carouselExampleDark"
              style={{ height: 300, marginTop: "10px", marginBottom: "10px" }}
              className="carousel slide"
              data-bs-ride="carousel"
              data-bs-touch="true"
            >
              <div className="carousel-inner" style={{ height: 300 }}>
                {images.map((image, i) => {
                  return (
                    <>
                      <div
                        style={{ height: 300 }}
                        className={"carousel-item " + (i == 0 ? "active" : "")}
                      >
                        <img
                          src={image.original}
                          height="100%"
                          className="d-block w-100"
                          alt={image.original}
                        />
                      </div>
                    </>
                  );
                })}
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
            <div
              onTouchStart={(e) => onTouchStart(e)}
              onTouchMove={(e) => onTouchMove(e)}
              onTouchEnd={() => onTouchEnd(hotel)}
              onClick={() => {
                //dispatch(setView(views.hotelcrossview));
                dispatch(setView(inboundView));
                //search(dispatch, true, false);
              }}
              className="col-sm-12 col-md-6 col-lg-6 align-items-stretch"
            >
              <div
                className={
                  "card mb-2 " /*+
                  (offer.topseller ? "border-3 border-warning" : "")*/
                }
                style={{ borderRadius: 10 }}
              >
                <div className="column no-gutters">
                  <div className="col" style={{ paddingLeft: 0 }}>
                    {/*offer.topseller && (
                      <div
                        style={{ marginTop: -8, zIndex:"2",position:"relative"}}
                        className="row mb-0  text-warning"
                      >
                        <div className="col-8 ps-3">
                          <span style={{ backgroundColor: "white" }}>
                            &nbsp;Unsere Empfehlung&nbsp;
                          </span>
                        </div>
                        <div
                          className="col-4 text-end"
                          style={{ marginTop: "8px" }}
                        >
                          <img src={offer.topsellerlogo} width="40px"></img>
                        </div>
                      </div>
                    )*/}
                    {/*<div className="col-auto" style={{ paddingRight: 0,marginTop:offer?.topseller?"-16px":"0px" }}>
                    <img
                      style={{
                        width:"100%",
                        height:"200px",
                        objectFit: "cover",
                      }}
                      src={hotel?.HotelImages?.LargeImage}
                      className="img-fluid"
                      alt={hotel?.Name}
                    />
                    </div>*/}

                    <div className="card-block px-2">
                      <p
                        className="card-title fw-semibold overflow-hidden mb-0 fs-5 text"
                        title={hotel?.Name}
                      >
                        {hotel?.Name}
                      </p>
                      <div className="d-flex justify-content-between">
                        <div>
                          <p className="text-warning mb-0">
                            {stars(hotel.HotelCategory * 1)}
                          </p>
                        </div>
                        <div className="mt-1" style={{ minWidth: "130px" }}>
                          {hotel.HotelReview.MeanRecommendationRate && (
                            <p className="mb-0">
                              <small>
                                {nf1.format(
                                  hotel.HotelReview.MeanRecommendationRate
                                )}{" "}
                                %
                              </small>
                              <small className="fw-semibold">
                                {" "}
                                {hotel.HotelReview.MeanRatingOverall >= 5 ? (
                                  "Sehr Gut"
                                ) : hotel.HotelReview.MeanRatingOverall >= 4 ? (
                                  "Gut"
                                ) : hotel.HotelReview.MeanRatingOverall >= 3 ? (
                                  "Befriedigend"
                                ) : (
                                  <span>&nbsp;</span>
                                )}
                              </small>{" "}
                              <small
                                className="bg-primary text-white p-1 ms-1"
                                style={{ borderRadius: "5px 5px 5px 5px" }}
                              >
                                {nf1.format(
                                  hotel.HotelReview.MeanRatingOverall
                                )}
                                &nbsp;/&nbsp;6
                              </small>{" "}
                            </p>
                          )}
                        </div>
                      </div>
                      <p className="mb-3">
                        <small>
                          in{" "}
                          {hotel &&
                            hotel.HotelLocationRef &&
                            Array.isArray(geodata?.Cities?.City) &&
                            geodata.Cities.City.find(
                              (c) => c.CityID === hotel.HotelLocationRef.CityID
                            ) !== undefined &&
                            geodata.Cities.City.find(
                              (c) => c.CityID === hotel.HotelLocationRef.CityID
                            ).Name}{" "}
                        </small>
                      </p>
                      {/*<p className="text-warning mb-0">
                        {stars(
                          offer.OfferServices.Package.Accommodation
                            .HotelOfferCategory * 1
                        )}
                      </p>*/}
                      {/*<p className="mb-3">
                        <small>
                          in{" "}
                          {hotel &&
                            hotel.HotelLocationRef &&
                            Array.isArray(geodata?.Cities?.City) &&
                            geodata.Cities.City.find(
                              (c) => c.CityID === hotel.HotelLocationRef.CityID
                            ) !== undefined &&
                            geodata.Cities.City.find(
                              (c) => c.CityID === hotel.HotelLocationRef.CityID
                            ).Name}{" "}
                        </small>
                            </p>*/}
                      {/*<div className="card-text">
                        {hotel.HotelReview.MeanRecommendationRate && (
                          <p>
                            <small
                              className="bg-primary text-white p-1"
                              style={{ borderRadius: 5 }}
                            >
                              {nf1.format(hotel.HotelReview.MeanRatingOverall)}
                            </small>
                            <small>&nbsp;/&nbsp;6</small>{" "}
                            <small>
                              {nf1.format(
                                hotel.HotelReview.MeanRecommendationRate
                              )}{" "}
                              %
                            </small>
                            <small className="fw-semibold">
                              {" "}
                              {hotel.HotelReview.MeanRatingOverall >= 5 ? (
                                "Sehr Gut"
                              ) : hotel.HotelReview.MeanRatingOverall >= 4 ? (
                                "Gut"
                              ) : hotel.HotelReview.MeanRatingOverall >= 3 ? (
                                "Befriedigend"
                              ) : (
                                <span>&nbsp;</span>
                              )}
                            </small>
                          </p>
                        )}
                        <p className="mb-0 text-end">
                          {holidayType.flight && (
                            <p className="mb-0">
                              <small>
                                {offer.TravelDateInfo.OvernightStays}{" "}
                                Übernachtungen |{" "}
                                {1 +
                                  (offer.TravelDateInfo.TripDuration
                                    ? offer.TravelDateInfo.TripDuration
                                    : offer.TravelDateInfo.OvernightStays)}{" "}
                                Tage
                              </small>
                            </p>
                          )}
                          {!holidayType.flight && (
                            <p className="mb-0">
                              <small>
                                {offer.TravelDateInfo.OvernightStays}
                                Übernachtungen
                              </small>
                            </p>
                          )}
                        </p>
                        <p className="mb-0 text-end">
                          <small>
                            {propertyFromArray(
                              roomTypes,
                              offer.OfferServices.Package.Accommodation.Room
                                .RoomType,
                              "value",
                              "label"
                            )}
                          </small>
                        </p>
                        <p className="mb-0 text-end">
                          <small>
                            {propertyFromArray(
                              boardTypes,
                              offer.OfferServices.Package.Accommodation
                                .BoardType,
                              "value",
                              "label"
                            )}
                          </small>
                        </p>
                        {offer.OfferServices.Package.Flight && (
                          <p className="mb-0 text-end">
                            <small>
                              inkl. Flug{" "}
                              {offer?.OfferServices?.Package?.Flight
                                ?.OutboundFlight?.FlightDeparture
                                ?.DepartureAirportRef?.AirportCode && (
                                <>
                                  (von{" "}
                                  {
                                    offer.OfferServices.Package.Flight
                                      .OutboundFlight.FlightDeparture
                                      .DepartureAirportRef.AirportCode
                                  }
                                  )
                                </>
                              )}
                            </small>
                          </p>
                        )}
                        <p className="mb-0 text-end fw-semibold">
                          ab{" "}
                          <span className="fw-semibold fs-3 text">
                            {nf.format(
                              offer.PriceInfo.Price.value *
                                (offer.adults * 1 + offer.children * 1)
                            )}
                          </span>
                          &nbsp;
                          <span className="fw-semibold">
                            {offer.PriceInfo.Price.CurrencyCode}
                          </span>
                        </p>
                        <p className="mb-0 text-end">
                              <a href="#" className="btn btn-primary mb-1">
                                Go somewhere
                              </a>
                                    </p>
                      </div>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion" id="accordion_info">
              <Select
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                value={catIdx}
                onChange={(val) => {
                  setCatIdx(val);
                }}
                isSearchable={false}
                options={Array.isArray(operators) ? operators : []}
              />

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class={
                      "accordion-button " +
                      (panelControl.beschreibung ? " " : "collapsed")
                    }
                    type="button"
                    aria-expanded={panelControl.beschreibung}
                    aria-controls="collapseOne"
                    onClick={() => {
                      setPanelControl({
                        ...panelControl,
                        beschreibung: !panelControl.beschreibung,
                      });
                    }}
                  >
                    Beschreibung
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class={
                    "accordion-collapse collapse " +
                    (panelControl.beschreibung ? "show" : "")
                  }
                  data-bs-parent="#accordion_info"
                >
                  <div class="accordion-body">
                    {catalogText && (
                      <div dangerouslySetInnerHTML={{ __html: catalogText }} />
                    )}
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class={
                      "accordion-button " +
                      (panelControl.ausstattung ? " " : "collapsed")
                    }
                    type="button"
                    aria-expanded={panelControl.ausstattung}
                    aria-controls="collapseTwo"
                    onClick={() => {
                      setPanelControl({
                        ...panelControl,
                        ausstattung: !panelControl.ausstattung,
                      });
                    }}
                  >
                    Ausstattung
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class={
                    "accordion-collapse collapse " +
                    (panelControl.ausstattung ? "show" : "")
                  }
                  data-bs-parent="#accordion_info"
                >
                  <div class="accordion-body">
                    {hotel &&
                      Array.isArray(hotel.HotelAttributes) &&
                      hotel.HotelAttributes.map((e, i) => (
                        <>
                          {hotelAttributes.find(
                            (a) => i < 100 && e === a.value
                          ) && (
                            <div
                              style={{ flex: "25%", paddingBottom: 16 }}
                              key={e}
                            >
                              <div style={{ display: "flex" }}>
                                <div style={{ width: 30 }}>
                                  {hotelAttributes.find(
                                    (a) => e === a.value
                                  ) ? (
                                    hotelAttributes.find((a) => e === a.value)
                                      .icon ? (
                                      hotelAttributes
                                        .find((a) => e === a.value)
                                        .icon()
                                    ) : (
                                      <MdCheck
                                        style={{ verticalAlign: "bottom" }}
                                      />
                                    )
                                  ) : (
                                    <MdCheck
                                      style={{ verticalAlign: "bottom" }}
                                    />
                                  )}
                                </div>
                                <div style={{}}>
                                  {hotelAttributes.find((a) => e === a.value)
                                    ? hotelAttributes.find((a) => e === a.value)
                                        .label
                                    : e}
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class={
                      "accordion-button " +
                      (panelControl.bewertungen ? " " : "collapsed")
                    }
                    type="button"
                    aria-expanded={panelControl.bewertungen}
                    aria-controls="collapseThree"
                    onClick={() => {
                      setPanelControl({
                        ...panelControl,
                        bewertungen: !panelControl.bewertungen,
                      });
                    }}
                  >
                    Bewertungen
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class={
                    "accordion-collapse collapse " +
                    (panelControl.bewertungen ? "show" : "")
                  }
                  data-bs-parent="#accordion_info"
                >
                  <div class="accordion-body">
                    {hotel &&
                      hotel.HotelReview &&
                      hotel.HotelReview.RatingsCount > 0 &&
                      hotel.HotelReview.MeanRatings && (
                        <>
                          <div
                            className="hotelratings"
                            style={{
                              backgroundColor: odd ? "#ffffff" : "#f0f0f0",
                            }}
                          >
                            <div className="hotelratings1">Gesamtbewertung</div>
                            <div className="hotelratings2">
                              <Rating
                                start={0}
                                stop={6}
                                emptySymbol={
                                  <FaRegSquare className="ratings" />
                                }
                                fullSymbol={<FaSquare className="ratings" />}
                                initialRating={
                                  hotel.HotelReview.MeanRatingOverall * 1
                                }
                                readonly
                              />
                              &nbsp;
                              <span style={{ fontSize: "0.7em" }}>
                                (
                                {nf.format(hotel.HotelReview.MeanRatingOverall)}{" "}
                                von 6,0)
                              </span>
                              /{hotel.HotelReview.RatingsCount} Bewertungen
                            </div>
                          </div>
                          <br />
                          {hotel &&
                            hotel.HotelReview &&
                            hotel.HotelReview.MeanRatings &&
                            Array.isArray(
                              hotel.HotelReview.MeanRatings.MeanRating
                            ) &&
                            hotel.HotelReview.MeanRatings.MeanRating.map(
                              (e) => {
                                odd = !odd;
                                return (
                                  <div
                                    className="hotelratings3"
                                    style={{
                                      backgroundColor: odd
                                        ? "#ffffff"
                                        : "#f0f0f0",
                                    }}
                                    key={e.RatingAttribute}
                                  >
                                    <div className="hotelratings4">
                                      {hotelRatings.find(
                                        (a) => e.RatingAttribute === a.value
                                      )
                                        ? hotelRatings.find(
                                            (a) => e.RatingAttribute === a.value
                                          ).label
                                        : e.RatingAttribute}
                                    </div>
                                    <div>
                                      <Rating
                                        start={0}
                                        stop={6}
                                        emptySymbol={
                                          <FaRegSquare className="ratings" />
                                        }
                                        fullSymbol={
                                          <FaSquare className="ratings" />
                                        }
                                        initialRating={e.value * 1}
                                        readonly
                                      />
                                      &nbsp;
                                      <span style={{ fontSize: "0.7em" }}>
                                        ({nf.format(e.value)} von 6,0)
                                      </span>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default MobileHotelView;
