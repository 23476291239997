import { search } from "amadeusredux/lib/api";
import { setHotelPage, setOfferId } from "amadeusredux/lib/hotelSlice";
import { setInbounddate, setOutbounddate } from "amadeusredux/lib/searchSlice";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from "react-intl";
import { useDispatch, useSelector } from "react-redux";

const nf1 = new Intl.NumberFormat("de-DE", {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});
const nf = new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2 });

function DepartureDateSelect(props) {
  const {
    offer,
    departureDates,
    departureDatesOriginal,
    cheapestInPeriod,
    expensiestInPeriod,
    expensiestOriginalInPeriod,
    cheapestOriginalInPeriod,
  } = props;
  const sessionId = useSelector((state) => state.system.sessionId);
  const checkids = useSelector((state) => state.hotel.checkids);
  const dispatch = useDispatch();
  const hotelOfferInfos = useSelector((state) => state.hotel.hotelOfferInfos);
  const stations = hotelOfferInfos.StationDictionary;
  const holidayType = useSelector((state) => state.search.holidayType);
  const offerdiv = React.useRef(null);
  const infos = useSelector((state) => state.properties.infos);
  const intl = useIntl();
  const df = new Intl.DateTimeFormat(intl.locale, {
    weekday: "short",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  let hotels = hotelOfferInfos.HotelDictionary;
  const [packageInfo, setPackageInfo] = React.useState({
    outbound: "",
    inbound: "",
    offerId: "",
  });
  const hotel = hotels
    ? hotels.Hotel.find(
        (e) =>
          e.HotelID ===
          offer?.OfferServices?.Package?.Accommodation?.HotelRef?.HotelID
      )
    : {};

  let offerstatus = 0;

  if (offer?.bookinginfo) {
    if (
      offer?.bookinginfo?.Status?.Status === "Available" ||
      offer?.bookinginfo?.Status?.Status === "ToConfirm" ||
      offer?.bookinginfo?.Status?.Status === "OnRequest"
    ) {
      offerstatus = 1;
    } else {
      offerstatus = -1;
    }
  }

  let reason = "";
  reason = offer?.bookinginfo?.Status?.StatusNo;

  return (
    <>
      <div>
        {cheapestOriginalInPeriod * (offer.adults * 1 + offer.children * 1)}€ -{" "}
        {expensiestOriginalInPeriod * (offer.adults * 1 + offer.children * 1)}€
      </div>
      <div
        id="departureDates"
        className="overflow-auto"
        style={{
          display: "flex",
          overflowY: "scroll",
          height: "90px",
        }}
      >
        {Array.isArray(departureDates?.Offers?.Offer) &&
          [...departureDates?.Offers?.Offer]
            .sort((a, b) =>
              (holidayType.flight
                ? a.TravelDateInfo.DepartureDate
                : a.TravelDateInfo.CheckInDate
              ).localeCompare(
                holidayType.flight
                  ? b.TravelDateInfo.DepartureDate
                  : b.TravelDateInfo.CheckInDate
              )
            )
            .map((dd, i) => {
              return (
                <>
                  <div
                    id={"date_" + dd.OfferID}
                    onClick={() => {
                      dispatch(setHotelPage(1));
                      dispatch(setOfferId(dd.OfferID));

                      dispatch(
                        setOutbounddate(
                          dd.TravelDateInfo.DepartureDate
                            ? dd.TravelDateInfo.DepartureDate
                            : dd.TravelDateInfo.CheckInDate
                        )
                      );

                      dispatch(
                        setInbounddate(
                          dd.TravelDateInfo.ReturnDate
                            ? dd.TravelDateInfo.ReturnDate
                            : dd.TravelDateInfo.CheckOutDate
                        )
                      );
                      search(dispatch, true, false, 0, true);
                      //window.scrollTo(0, 0);
                    }}
                    style={{ paddingLeft: 5 }}
                  >
                    {/* (dd.TravelDateInfo.DepartureDate >= startdate && dd.TravelDateInfo.DepartureDate <= enddate)*/}

                    <div
                      className={
                        departureDatesOriginal?.Offers?.Offer.map(
                          (e) => e.OfferID
                        ).includes(dd.OfferID)
                          ? "bg-secondary"
                          : "bg-danger bg-opacity-10"
                      }
                      style={{ minHeight: "50px", maxHeight: "50px" }}
                    >
                      <div
                        className="p-1"
                        style={{
                          height:
                            45 -
                            45 *
                              ((dd.PriceInfo.Price.value - cheapestInPeriod) /
                                (expensiestInPeriod - cheapestInPeriod)),
                          backgroundColor: "white",
                        }}
                      >
                        &nbsp;
                        {/*50/ (dd.PriceInfo.Price.value / cheapestInPeriod)*/}
                      </div>
                    </div>

                    <div
                      className="text-secondary p-1"
                      style={{ fontSize: "0.5em" }}
                    >
                      {dd.PriceInfo.Price.value *
                        (offer.adults * 1 + offer.children * 1)}
                      <br />
                      <FormattedDate
                        value={
                          dd.TravelDateInfo.DepartureDate
                            ? dd.TravelDateInfo.DepartureDate
                            : dd.TravelDateInfo.CheckInDate
                        }
                        month="2-digit"
                        day="2-digit"
                      />
                    </div>
                  </div>
                </>
              );
            })}
      </div>
    </>
  );
}

export default DepartureDateSelect;
